import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Select,
  InputNumber,
  Spin,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { ButtonFooter } from '../WorkPage/PopupWorkForm/PopupWorkFormStyled'
const { Option } = Select

const ManagementAssetWarehouseUpdate = props => {
  const [form] = Form.useForm()
  const {
    managementAssetWarehouseStore,
    checkImportStore,
    setSelectedRowKeys,
  } = props
  const [isCreating, setIsCreating] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [duplicateWarning, setDuplicateWarning] = useState('')
  const [initialAssetName, setInitialAssetName] = useState('')
  const [changeTax, setChangeTax] = useState(0)
  const [changePrice, setChangePrice] = useState(0)
  const { managementAssetWarehouseDetail } = managementAssetWarehouseStore

  const checkDuplicateAsset = (name, typeId) => {
    const normalizedDescription = name?.trim().toLowerCase()

    const existingAssetType =
      initialAssetName.trim().toLowerCase() !== normalizedDescription &&
      managementAssetWarehouseStore.managementAssetWarehouseList?.some(
        type =>
          type.asset_type?.id === typeId &&
          type.asset_name.toLowerCase() === normalizedDescription
      )

    return !!existingAssetType
  }

  useEffect(() => {
    ;(async () => {
      await getManagementAssetWarehousetDetail(props.templateId)
    })()
    ;(async () => {
      await managementAssetWarehouseStore.getManagementAssetWarehouseAssetTypes()
    })()
    ;(async () => {
      await managementAssetWarehouseStore.getManagementAssetWarehouseUnit()
    })()
  }, [props.templateId])

  const getManagementAssetWarehousetDetail = async id => {
    try {
      setIsLoading(true)
      await managementAssetWarehouseStore.getManagementAssetWarehousetDetail(id)
      setInitialAssetName(
        managementAssetWarehouseStore.managementAssetWarehouseDetail.asset_name
      )
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    form.setFieldsValue({
      amount: checkImportStore ? null : managementAssetWarehouseDetail.amount,
      asset_name: managementAssetWarehouseDetail.asset_name,
      asset_type_id: managementAssetWarehouseDetail.asset_type.id,
      asset_unit_name: managementAssetWarehouseDetail.asset_unit_name,
      property: managementAssetWarehouseDetail.property,
      tax: checkImportStore ? null : managementAssetWarehouseDetail.tax,
      price: checkImportStore ? null : managementAssetWarehouseDetail.price,
      total_money: checkImportStore
        ? null
        : managementAssetWarehouseDetail.total_money,
    })
    setChangeTax(Number(managementAssetWarehouseDetail.tax))
    setChangePrice(Number(managementAssetWarehouseDetail.price))
  }, [managementAssetWarehouseDetail])

  useEffect(() => {
    const total_money = ((changeTax / 100) * changePrice + changePrice).toFixed(
      2
    )
    form.setFieldsValue({
      total_money: total_money,
    })
  }, [changeTax, changePrice, managementAssetWarehouseDetail, form])

  useEffect(() => {
    if (managementAssetWarehouseDetail?.asset_type?.is_deleted === true) {
      form.setFieldsValue({
        asset_type_id: null,
      })
    }
  }, [managementAssetWarehouseDetail])

  const onFinish = async value => {
    try {
      setIsCreating(true)

      const quantity = value.amount + managementAssetWarehouseDetail.amount

      const assetData = {
        amount: quantity,
        asset_name: value.asset_name,
        asset_type_id: value.asset_type_id,
        asset_unit_name: value.asset_unit_name,
        property: value.property,
        tax: value.tax,
        price: value.price,
        total_money: value.total_money,
      }

      const trimmedDescription = value?.asset_name?.trim()

      // Check for duplicates with normalized and trimmed description
      if (
        checkDuplicateAsset(assetData?.asset_name, assetData?.asset_type_id)
      ) {
        setDuplicateWarning(
          `Tên văn phòng phẩm ${value?.asset_name} đã tồn tại`
        )
        setIsCreating(false)
        return
      }
      setDuplicateWarning('')

      const assetHistoryData = {
        asset_id: props.templateId,
        tax: value.tax,
        price: value.price,
        total_money: value.total_money,
        quantity: value.amount,
        type: 'UPDATE',
      }
      if (checkImportStore) {
        await managementAssetWarehouseStore.updateManagementAssetWarehouse(
          props.templateId,
          {
            amount: quantity,
            asset_name: managementAssetWarehouseDetail.asset_name,
            asset_type_id: managementAssetWarehouseDetail.asset_type.id,
            asset_unit_name: managementAssetWarehouseDetail.asset_unit_name,
            property: managementAssetWarehouseDetail.property,
            tax: value.tax,
            price: value.price,
            total_money: value.total_money,
          }
        )
        await managementAssetWarehouseStore.createManagementAssetHistoryWarehouse(
          assetHistoryData
        )
        setSelectedRowKeys(null)
      } else {
        await managementAssetWarehouseStore.updateManagementAssetWarehouse(
          props.templateId,
          {
            amount: value.amount,
            asset_name: trimmedDescription,
            asset_type_id: value.asset_type_id,
            asset_unit_name: value.asset_unit_name,
            property: value.property,
            tax: value.tax,
            price: value.price,
            total_money: value.total_money,
          }
        )
      }
      message.success('Chỉnh sửa tài sản thành công')
      props.onCancel()
      await managementAssetWarehouseStore.getManagementAssetWarehousetList()
    } catch (error) {
      console.log(error)
      message.error('Chỉnh sửa tài sản thất bại!!')
      setSelectedRowKeys(null)
      props.onCancel()
    } finally {
      setIsCreating(false)
    }
  }

  const validateAssetName = async (_, value) => {
    if (value.length > 100) {
      return Promise.reject('Tên văn phòng phẩm không được dài quá 100 ký tự')
    }
    const typeId = form.getFieldValue('asset_type_id')
    // Check for duplicates with normalized and trimmed description
    if (typeId && checkDuplicateAsset(value.trim(), typeId)) {
      return Promise.reject(`Tên văn phòng phẩm ${value} đã tồn tại.`)
    }
    return Promise.resolve()
  }

  /* asset_type_id */
  const sortedTypes = managementAssetWarehouseStore.managementAssetWarehouseTypes?.content?.sort(
    (a, b) => {
      return a.description.localeCompare(b.description)
    }
  )

  return (
    <>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Spin spinning={isLoading}>
          {!checkImportStore ? (
            <>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label="Loại văn phòng phẩm"
                    name="asset_type_id"
                    validateStatus="success"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn loại văn phòng phẩm',
                      },
                    ]}>
                    <Select
                      showSearch
                      allowClear
                      placeholder={'Loại văn phòng phẩm'}
                      filterOption={true}
                      optionFilterProp={'name'}>
                      {sortedTypes.map(type => (
                        <Option
                          key={type.id}
                          name={type.description}
                          value={type.id}>
                          {type.description}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={'Tên văn phòng phẩm:'}
                    name={'asset_name'}
                    validateStatus={duplicateWarning ? 'error' : ''}
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập tên văn phòng phẩm',
                      },
                      {
                        validator: validateAssetName,
                      },
                    ]}>
                    <Input placeholder={'Nhập tên văn phòng phẩm'} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label={'Đặc tính:'}
                    name={'property'}
                    validateStatus="success"
                    rules={[
                      {
                        max: 100,
                        message: 'Đặc tính không được dài quá 100 ký tự',
                      },
                    ]}>
                    <Input placeholder={'Nhập tên đặc tính'} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={'Đơn vị tính:'}
                    name={'asset_unit_name'}
                    validateStatus="success"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập đơn vị tính',
                      },
                      {
                        max: 100,
                        message: 'Đơn vị tính không được dài quá 100 ký tự',
                      },
                    ]}>
                    <Input placeholder={'Nhập tên đơn vị tính'} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : null}
          <Row gutter={[16, 16]}>
            <div style={{ display: 'flex', width: '100%' }}>
              <Col span={4}>
                <Form.Item
                  label={'Thuế:'}
                  name={'tax'}
                  validateStatus="success">
                  <InputNumber
                    readOnly={!checkImportStore}
                    onChange={value => setChangeTax(value)}
                    style={{ width: '100%' }}
                    min={0}
                    placeholder={'Nhập thuế'}
                  />
                </Form.Item>
              </Col>
              <span style={{ marginTop: '35px' }}>%</span>
            </div>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={'Đơn giá trước thuế:'}
                name={'price'}
                validateStatus="success"
                rules={
                  checkImportStore
                    ? [
                        {
                          validator: (_, value) => {
                            if (value < 0) {
                              return Promise.reject(
                                'Đơn giá trước thuế không thể là số âm'
                              )
                            }
                            return Promise.resolve()
                          },
                        },
                      ]
                    : []
                }>
                <InputNumber
                  readOnly={!checkImportStore}
                  onChange={value => setChangePrice(value)}
                  formatter={value =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  precision={2}
                  style={{
                    width: '100%',
                  }}
                  min={0}
                  placeholder={'Nhập đơn giá trước thuế'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={'Đơn giá sau thuế:'}
                name={'total_money'}
                validateStatus="success">
                <InputNumber
                  readOnly
                  formatter={value =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  style={{
                    width: '100%',
                  }}
                  min={0}
                  placeholder={'Đơn giá sau thuế'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label={'Số lượng trong kho:'}
                name={'amount'}
                validateStatus="success"
                rules={
                  checkImportStore
                    ? [
                        {
                          required: true,
                          message: 'Vui lòng nhập số lượng',
                        },
                        {
                          validator: (_, value) => {
                            if (value < 0) {
                              return Promise.reject(
                                'Số lượng không thể là số âm'
                              )
                            } else if (value === 0) {
                              return Promise.reject('Số lượng phải lớn hơn 0')
                            }
                            return Promise.resolve()
                          },
                        },
                      ]
                    : []
                }>
                <InputNumber
                  readOnly={!checkImportStore}
                  style={{
                    width: '100%',
                  }}
                  placeholder={'Nhập số lượng'}
                  onChange={value => {
                    if (value && !Number.isInteger(value)) {
                      const roundedValue = Math.round(value)
                      form.setFieldsValue({ amount: roundedValue })
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Spin>
        <ButtonFooter>
          <Space>
            <Button icon={<CloseOutlined />} danger onClick={props.onCancel}>
              Đóng
            </Button>
            <Button
              icon={<EditOutlined />}
              type={'primary'}
              htmlType={'submit'}
              loading={isCreating}>
              Lưu
            </Button>
          </Space>
        </ButtonFooter>
      </Form>
    </>
  )
}
export default inject(
  'managementAssetWarehouseStore',
  'selectPeopleStore'
)(observer(ManagementAssetWarehouseUpdate))
