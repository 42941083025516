import { CaretDownOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Empty,
  Select,
  Spin,
  Pagination,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { ButtonFooter } from '../WorkPage/PopupWorkForm/PopupWorkFormStyled'
import TableComponent from '../../components/Common/TableComponent'
import { TableBottomPaginationBlock } from '../../components/Common/Table'

const { Option } = Select

const DistributionVotesListSelect = props => {
  const [form] = Form.useForm()
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const {
    managementAssetWarehouseStore,
    selectPeopleStore,
    onUpdateTable,
    dataTable,
  } = props
  const [isCreating, setIsCreating] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedData, setSelectedData] = useState([])
  const [enter, setEnter] = useState(true)
  const [filteredAssets, setFilteredAssets] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const { assetName } = managementAssetWarehouseStore

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      await managementAssetWarehouseStore.clearFilter()
      await managementAssetWarehouseStore.getManagementAssetWarehousetLists()
      setIsLoading(false)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (enter) {
        setIsLoading(true)
        await managementAssetWarehouseStore.clearManagementAssetWarehousetList()
        await managementAssetWarehouseStore.getManagementAssetWarehousetLists()
        setIsLoading(false)
        setEnter(false)
      }
    })()
  }, [enter, assetName])

  const onFinish = async () => {
    onUpdateTable([...selectedData])
    props.onCancel()
  }

  const handleInputChange = e => {
    const value = e.target.value
    setSearch(value)
    setCurrentPage(1)
  }

  const removeVietnameseTones = str => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
  }

  useEffect(() => {
    const assets = managementAssetWarehouseStore.managementAssetWarehouseLists
    const filtered = assets.filter(asset =>
      removeVietnameseTones(asset.asset_name)
        .toLowerCase()
        .includes(removeVietnameseTones(search).toLowerCase())
    )
    setFilteredAssets(filtered)
  }, [search, managementAssetWarehouseStore.managementAssetWarehouseLists])

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      await loadManagerInventoryList()
      if (dataTable.length > 0) {
        const initialSelectedRowKeys = dataTable.map(
          item => item.id || item.asset_id
        )
        setSelectedRowKeys(initialSelectedRowKeys)

        const selectedData = managementAssetWarehouseStore.managementAssetWarehouseLists.filter(
          item => {
            return dataTable.some(dataItem => dataItem.id === item.id)
          }
        )
        const selectedDataWithTitle = selectedData.map(item => {
          const foundDetail = dataTable.find(detail => detail.id === item.id)
          if (foundDetail) {
            return {
              ...item,
              amount: foundDetail?.amount || item.amount,
              purpose: foundDetail.purpose,
            }
          } else {
            return {
              ...item,
              amount: foundDetail?.amount || item.amount,
              purpose: '',
            }
          }
        })
        setSelectedData(selectedDataWithTitle)
      } else {
        setSelectedRowKeys([])
      }
      setIsLoading(false)
    })()
    ;(async () => {
      await managementAssetWarehouseStore.getManagementAssetWarehouseAssetTypes()
    })()
  }, [])

  const loadManagerInventoryList = async () => {
    try {
      await managementAssetWarehouseStore.getManagementAssetWarehousetLists()
    } finally {
      setIsLoading(false)
    }
  }

  const tableColumns = [
    {
      title: 'STT',
      width: 20,
      render: (id, record, index) => {
        return (currentPage - 1) * pageSize + index + 1
      },
    },
    {
      title: 'Loại văn phòng phẩm',
      width: 150,
      render: record => {
        if (!record.asset_type.is_deleted) {
          return <p>{record.asset_type?.description}</p>
        }
        return null
      },
    },
    {
      title: 'Tên văn phòng phẩm',
      width: 200,
      render: record => <p>{record.asset_name}</p>,
    },
    {
      title: 'Đặc tính',
      align: 'center',
      width: 100,
      render: record => <p>{record?.property}</p>,
    },
    {
      title: 'Đơn vị tính',
      align: 'center',
      width: 70,
      render: record => <p>{record?.asset_unit_name}</p>,
    },
    {
      title: 'Số lượng',
      align: 'center',
      width: 70,
      render: record => <p>{record?.amount}</p>,
    },
  ]

  // Sort options alphabetically by description
  const sortedOptions = managementAssetWarehouseStore.managementAssetWarehouseTypes?.content?.sort(
    (a, b) => {
      return a.description.localeCompare(b.description)
    }
  )

  // Xử lý thay đổi trang
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page)
    setPageSize(pageSize)
  }

  // Lấy dữ liệu hiển thị cho trang hiện tại
  const currentData = filteredAssets.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  )

  return (
    <>
      <Row>
        <Col span={8}>
          <Form layout="vertical">
            <Form.Item
              label={
                <span style={{ fontSize: '13px' }}>
                  Tìm kiếm theo tên văn phòng phẩm:
                </span>
              }
              name="search">
              <Input
                allowClear
                placeholder="Tìm kiếm theo tên văn phòng phẩm"
                onChange={handleInputChange}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Spin spinning={isLoading}>
            {!isLoading && (
              <>
                <TableComponent
                  rowKey={record => record.id}
                  dataSource={currentData.length > 0 ? currentData : null}
                  columns={tableColumns}
                  rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedRowKeys.filter(key =>
                      currentData.some(data => data.id === key)
                    ),
                    onChange: (newSelectedRowKeys, selectedRows) => {
                      const newSelectedData = selectedData
                        .filter(
                          item => !currentData.some(data => data.id === item.id)
                        )
                        .concat(selectedRows)

                      const newSelectedKeys = selectedRowKeys
                        .filter(
                          key => !currentData.some(data => data.id === key)
                        )
                        .concat(newSelectedRowKeys)

                      setSelectedRowKeys(newSelectedKeys)
                      setSelectedData(newSelectedData)
                    },
                    onSelectAll: (selected, selectedRows) => {
                      const newSelectedData = selected
                        ? selectedData.concat(selectedRows)
                        : selectedData.filter(
                            item =>
                              !currentData.some(data => data.id === item.id)
                          )

                      const newSelectedKeys = selected
                        ? selectedRowKeys.concat(
                            currentData.map(item => item.id)
                          )
                        : selectedRowKeys.filter(
                            key => !currentData.some(data => data.id === key)
                          )

                      setSelectedRowKeys(newSelectedKeys)
                      setSelectedData(newSelectedData)
                    },
                  }}
                  pagination={false}
                  locale={{
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={'Không có dữ liệu'}
                      />
                    ),
                  }}
                  loading={isLoading}
                />
                <TableBottomPaginationBlock>
                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={filteredAssets.length}
                    onChange={handlePageChange}
                    style={{ textAlign: 'right', marginTop: '16px' }}
                  />
                </TableBottomPaginationBlock>
              </>
            )}
          </Spin>
        </Col>
      </Row>
      <ButtonFooter>
        <Space>
          <Button danger onClick={props.onCancel}>
            Đóng
          </Button>
          <Button
            type={'primary'}
            htmlType={'submit'}
            onClick={onFinish}
            loading={isCreating}>
            Chọn
          </Button>
        </Space>
      </ButtonFooter>
    </>
  )
}

export default inject(
  'managementAssetWarehouseStore',
  'selectPeopleStore'
)(observer(DistributionVotesListSelect))
