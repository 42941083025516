import { Tabs } from 'antd'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import RiskTemplateList from '../RiskTemplate/RiskTemplateList'
import RiskAriseList from '../RiskTemplate/RiskAriseList'
import DashboardLayout from '../../../layouts/DashboardLayout/DashboardLayout'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { useHistory } from 'react-router-dom'
import { useFilters } from '../../../hooks/useFilters'
import { inject, observer } from 'mobx-react'

const RiskTemplate = props => {
  const { riskTemplateStore, riskAriseStore } = props
  const [filter, setFilter] = useState({
    type: null,
    keyword: null,
    page: 0,
    pageSize: 10,
    createdStart: null,
    createdEnd: null,
  })
  const [currentFilters, setCurrentFilters] = useFilters({
    type: null,
    keyword: null,
    page: 0,
    pageSize: 10,
    createdStart: null,
    createdEnd: null,
  })
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('rui_ro_nhan_dien')
  const handleTabChange = key => {
    setActiveTab(key)

    history.replace(`/risk-template`)
    if (key === 'rui_ro_nhan_dien') {
      setFilter({
        ...filter,
        type: null,
        keyword: null,
        page: 0,
        pageSize: 10,
        createdStart: null,
        createdEnd: null,
      })
      riskTemplateStore.getRiskTemplateList(filter)
    } else {
      setCurrentFilters({
        ...currentFilters,
        type: null,
        keyword: null,
        page: 0,
        pageSize: 10,
        createdStart: null,
        createdEnd: null,
      })
      riskAriseStore.getRiskAriseList(currentFilters)
    }
  }

  const tabs = [
    {
      key: 'rui_ro_nhan_dien',
      name: 'Rủi ro nhận diện',
      component: <RiskTemplateList filter={filter} setFilter={setFilter} />,
    },
    {
      key: 'rui_ro_nhan_dien_phat_sinh',
      name: 'Rủi ro nhận diện phát sinh',
      component: (
        <RiskAriseList
          currentFilters={currentFilters}
          setCurrentFilters={setCurrentFilters}
        />
      ),
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản trị loại rủi ro | Phiếu kiểm soát rủi ro</title>
      </Helmet>
      <PageTitle location={props.location}></PageTitle>
      <ContentBlockWrapper>
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          {tabs.map(tab => (
            <Tabs.TabPane tab={tab.name} key={tab.key}>
              <div style={{ flexGrow: 1 }}>{tab.component}</div>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject(
  'riskTemplateStore',
  'riskAriseStore'
)(observer(RiskTemplate))
