import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Upload,
} from 'antd'
import RichEditor from '../../../components/RichEditor/RichEditor'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_LIST,
  DOCUMENT_TYPE,
  WORK_STATUS,
} from '../../../constants'
import {
  SelectList,
  SelectTitle,
} from '../../MissionCreatePage/MissionCreatePageStyled'
import { blue, yellowPrimary } from '../../../color'
import {
  BankOutlined,
  CloseOutlined,
  DeleteOutlined,
  FileTextOutlined,
  SendOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { AvatarHasName } from '../../../components/Common/Avatar'
import {
  ButtonFooter,
  UploadFileListItem,
  UploadFileListWrapper,
} from '../../WorkPage/PopupWorkForm/PopupWorkFormStyled'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../../components/SelectGroupPopup/SelectGroupPopup'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import utils from '../../../utils'
import validator from '../../../validator'

const { Option } = Select

const PopupPhanPhatDocument = props => {
  const {
    isVisiblePopupPhanPhatDocument,
    handleClosePopupPhanPhatDocument,
    fileStore,
    workStore,
    loadingAnimationStore,
    selectPeopleStore,
    internalDocumentStore,
    commentLeaderList,
    selectedInternalDocument,
    typeDocument,
  } = props
  const {
    userAssignList,
    departmentAssignList,
    groupAssignList,
  } = internalDocumentStore
  const {
    incoming_number,
    code,
    document_status,
    title,
    attachments,
  } = selectedInternalDocument

  const start_date_origin = selectedInternalDocument.start_date
  const end_date_origin = selectedInternalDocument.end_date

  const { workTypeList } = workStore

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
  } = selectPeopleStore

  const [form] = Form.useForm()
  const EDITOR_REF = useRef()

  const [editorContent, setEditorContent] = useState()
  const [handlerList, setHandlerList] = useState([])
  const [followerList, setFollowerList] = useState([])
  const [fileList, setFileList] = useState([])
  const [documentList, setDocumentList] = useState([])

  useEffect(() => {
    setHandlerList([
      ...selectUserData[ASSIGNEE_TYPE.COMBINER],
      ...selectDepartmentData[ASSIGNEE_TYPE.COMBINER],
      ...selectGroupData[ASSIGNEE_TYPE.COMBINER],
    ])
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }, [])

  const onFinish = async value => {
    const { title, start_date, due_date, work_type } = value
    if (handlerList.length === 0)
      return message.warning('Bạn chưa chọn người xử lý!')
    const dataSubmitHandleDocument = {
      new_items: [],
      remove_items: [],
      start_date:
        !start_date_origin || moment(start_date).isBefore(start_date_origin)
          ? start_date
          : start_date_origin,
      end_date:
        !end_date_origin || moment(due_date).isAfter(end_date_origin)
          ? due_date
          : end_date_origin,
      document_status: document_status,
    }
    // Tạo công việc
    const assigneesList = [
      ...followerList,
      ...handlerList.map(handler => ({
        ...handler,
        permission: ASSIGNEE_TYPE.HANDLER,
      })),
    ].map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    const batchUploadArr = []
    fileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file.originFileObj)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    try {
      loadingAnimationStore.showSpinner(true)
      await internalDocumentStore.updateAssignees(
        code,
        dataSubmitHandleDocument
      )
      const response = await Promise.all(batchUploadArr)
      const dataSubmitWork = {
        title: title,
        start_date: start_date,
        related_documents:
          code && typeDocument
            ? [
                {
                  code: code,
                  type: typeDocument,
                },
              ]
            : [],
        related_tasks: [],
        due_date: due_date,
        content: EDITOR_REF.current.editor.getData(),
        attachments: response
          .map(response => response.data.file_id)
          .concat(documentList.map(file => file.id)),
        assignees: assigneesList,
        parent_id: null,
        work_type: work_type,
      }
      await workStore.createWork(dataSubmitWork)
      if (typeDocument === DOCUMENT_TYPE.INCOMING) {
        await internalDocumentStore.getInternalDocumentIncomingById(code)
      }
      if (typeDocument === DOCUMENT_TYPE.OUTGOING) {
        await internalDocumentStore.getInternalDocumentOutgoingById(code)
      }
      selectPeopleStore.clearSelected()
      handleClosePopupPhanPhatDocument()
      message.success('Phân phát văn bản thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleCancel = () => {
    form.resetFields()
    setFileList([])
    handleClosePopupPhanPhatDocument()
  }

  useEffect(() => {
    selectPeopleStore.clearSelected()
    setDocumentList(attachments || [])
    const comments_leader =
      commentLeaderList && commentLeaderList.length > 0
        ? commentLeaderList.map(cmt => cmt.content)
        : ''
    const leader_name =
      commentLeaderList && commentLeaderList.length > 0
        ? utils.getNameInCapitalize(commentLeaderList[0].name_uppercase)
        : ''
    form.setFieldsValue({
      work_type: WORK_STATUS.DOCUMENT,
      title: `Xử lý văn bản ${
        incoming_number ? 'đến ' + incoming_number : 'đi'
      }: ${title}`,
      content: setEditorContent(
        `Nội dung: ${title + '. \n'}${leader_name} ${comments_leader}`
      ),
      start_date: start_date_origin && moment(start_date_origin),
      due_date: end_date_origin && moment(end_date_origin),
    })
  }, [
    selectedInternalDocument,
    commentLeaderList,
    isVisiblePopupPhanPhatDocument,
  ])

  return (
    <Modal
      width={800}
      style={{ top: 40, zIndex: 9999 }}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <span style={{ color: blue, fontSize: 16 }}>Phân phát văn bản</span>
          <Button
            icon={<CloseOutlined />}
            type={'text'}
            onClick={handleCancel}
          />
        </div>
      }
      visible={isVisiblePopupPhanPhatDocument}
      closable={false}
      footer={null}>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Form.Item
          label={'Loại công việc'}
          name={'work_type'}
          rules={[{ required: true, message: 'Vui lòng chọn loại công việc' }]}>
          <Select placeholder={'Chọn loại công việc'}>
            {workTypeList.map(workType => (
              <Option
                key={workType.id}
                value={workType.id}
                name={workType.name}>
                {workType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={'Tiêu đề:'}
          name={'title'}
          rules={[
            { required: true, message: 'Vui lòng nhập tiêu đề' },
            { max: 500, message: 'Tiêu đề không được dài quá 500 ký tự!' },
            { validator: validator.validateInputString },
          ]}>
          <Input placeholder={'Nhập tiêu đề...'} />
        </Form.Item>

        <Form.Item label={'Nội dung:'} name={'content'}>
          <RichEditor
            EDITOR_REF={EDITOR_REF}
            placeholder={'Nhập nội dung...'}
            editorContent={editorContent}
          />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label={'Ngày bắt đầu:'}
              rules={[
                { required: true, message: 'Vui lòng chọn ngày bắt đầu!' },
              ]}
              name={'start_date'}>
              <DatePicker format={DATE_FORMAT_LIST} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={'Ngày kết thúc:'} name={'due_date'}>
              <DatePicker format={DATE_FORMAT_LIST} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <SelectTitle>
              <span>
                <span
                  style={{
                    color: '#ff4d4f',
                    fontFamily: 'SimSun, sans-serif',
                    marginRight: '-4px',
                  }}>
                  *{' '}
                </span>
                <span style={{ marginRight: '10px' }}>Người xử lý:</span>
              </span>
              <Space>
                <Tooltip title="Chọn người dùng" color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() =>
                      handleOpenSelectUserDepartmentPopup(
                        ASSIGNEE_TYPE.COMBINER
                      )
                    }
                  />
                </Tooltip>
                <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                  <Button
                    icon={<TeamOutlined />}
                    style={{ color: yellowPrimary }}
                    onClick={() =>
                      handleOpenSelectGroupPopup(ASSIGNEE_TYPE.COMBINER)
                    }
                  />
                </Tooltip>
              </Space>
            </SelectTitle>
            <SelectList>
              {handlerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </SelectList>
          </Col>

          <Col span={12}>
            <SelectTitle>
              <span>
                <span style={{ marginRight: '10px' }}>Người theo dõi:</span>
              </span>
              <Space>
                <Tooltip title="Chọn người dùng" color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() =>
                      handleOpenSelectUserDepartmentPopup(
                        ASSIGNEE_TYPE.FOLLOWER
                      )
                    }
                  />
                </Tooltip>
                <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                  <Button
                    icon={<TeamOutlined />}
                    style={{ color: yellowPrimary }}
                    onClick={() =>
                      handleOpenSelectGroupPopup(ASSIGNEE_TYPE.FOLLOWER)
                    }
                  />
                </Tooltip>
              </Space>
            </SelectTitle>
            <SelectList>
              {followerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </SelectList>
          </Col>
        </Row>
        <Form.Item label={'Tài liệu đính kèm:'} name={'id_file_dinh_kem'}>
          <Upload
            valuePropName={'fileList'}
            fileList={fileList}
            multiple={true}
            onChange={handleChangeFile}
            beforeUpload={() => false}
            showUploadList={false}>
            <Button htmlType={'button'}>
              <UploadOutlined />
              Chọn tài liệu đính kèm
            </Button>
          </Upload>
        </Form.Item>
        {fileList.length !== 0 && (
          <UploadFileListWrapper>
            {fileList.map(file => (
              <UploadFileListItem key={file.uid}>
                <FileTextOutlined />
                <span>{file.name}</span>
                <Tooltip title={'Xoá tập tin'}>
                  <DeleteOutlined
                    onClick={() =>
                      setFileList(fileList.filter(el => el.uid !== file.uid))
                    }
                  />
                </Tooltip>
              </UploadFileListItem>
            ))}
          </UploadFileListWrapper>
        )}
        {documentList.length !== 0 && (
          <UploadFileListWrapper>
            {documentList.map(file => (
              <UploadFileListItem key={file.id}>
                <FileTextOutlined style={{ color: blue }} />
                <span
                  onClick={() =>
                    fileStore.handleDownloadFile(file.id, file.name)
                  }
                  style={{
                    color: blue,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}>
                  {file.name}
                </span>
                <Tooltip title={'Xoá tập tin'}>
                  <DeleteOutlined
                    onClick={() =>
                      setDocumentList(
                        documentList.filter(doc => doc.id !== file.id)
                      )
                    }
                  />
                </Tooltip>
              </UploadFileListItem>
            ))}
          </UploadFileListWrapper>
        )}
        <ButtonFooter>
          <Space>
            <Button icon={<CloseOutlined />} danger onClick={handleCancel}>
              Hủy
            </Button>
            <Button
              icon={<SendOutlined />}
              type={'primary'}
              htmlType={'submit'}>
              Phân phát
            </Button>
          </Space>
        </ButtonFooter>
      </Form>
      <SelectPeoplePopup
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
    </Modal>
  )
}

PopupPhanPhatDocument.propTypes = {}
export default inject(
  'selectPeopleStore',
  'fileStore',
  'workStore',
  'loadingAnimationStore',
  'internalDocumentStore'
)(observer(PopupPhanPhatDocument))
