import {
  Empty,
  message,
  Modal,
  Select,
  Input,
  DatePicker,
  Pagination,
  Row,
  Col,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState, useCallback } from 'react'
import TableComponent from '../../components/Common/TableComponent'
import {
  QuestionCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  TYPE_STATUS,
  PROPOSAL_STATUS,
  YYYY_MM_DD,
  ASSIGNEE_TYPE,
  ACL_ACTION_TYPE,
} from '../../constants'
import { TableBottomPaginationBlock } from '../../components/Common/Table'
import { StatusTag } from '../../components/Common/StatusTag'
import { AvatarHasName } from '../../components/Common/Avatar'
import moment from 'moment'
import DistributionVotesUpdate from './DistributionVotesUpdate'
import debounce from 'lodash/debounce'
import DistributionVotesUpdateDetails from './DistributionVotesDetail/DistributionVotesUpdateDetails'
const { Option } = Select
const stylePagination = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 20,
  marginBottom: 10,
}

const { confirm } = Modal

const DistributionVotesList = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false)
  const [keyword1, setKeyword] = useState(null)
  const [createUser1, setCreateUser1] = useState(null)
  const [enter, setEnter] = useState(true)
  const [date, setDate] = useState(null)

  const {
    selectPeopleStore,
    authenticationStore,
    isVisibleCreateModal,
    history,
    aclStore,
    DistributionVotesStore,
    proposalAdvanceStore,
  } = props
  const { originDepartmentWithUserList, companyCode } = selectPeopleStore
  const { isAccountAdmin } = authenticationStore
  const {
    pageIndex,
    pageSize,
    createdAt,
    keyword,
    proposalStatus,
    proposalPermission,
    createUser,
    totalCountProposal,
    checkVotes,
  } = DistributionVotesStore

  const [selectedTemplateId, setSelectedTemplateId] = useState({})

  useEffect(() => {
    ;async () => {
      await DistributionVotesStore.getDistributionVotesDetail(
        selectedTemplateId
      )
    }
    ;async () => {
      await proposalAdvanceStore.getProposalAdvanceList()
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (checkVotes) {
        await setKeyword(keyword)
        await setCreateUser1(createUser)
        await DistributionVotesStore.setFilter('checkVotes', false)
      } else {
        await DistributionVotesStore.clearFilterProposal()
      }
      // await loadDistributionVotesList()
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (companyCode) {
        try {
          await selectPeopleStore.getDepartmentWithUsers()
        } catch (err) {
          console.log(err)
          message.error(err.vi || 'Đã có lỗi xảy ra!')
        }
      }
    })()
  }, [companyCode])

  useEffect(() => {
    if (authenticationStore.currentUser) {
      selectPeopleStore.companyCode = authenticationStore.currentCompanyCode()
    }
  }, [authenticationStore.currentUser])
  useEffect(() => {
    ;(async () => {
      if (enter) {
        await DistributionVotesStore.clearDistributionVotestList()
        await loadDistributionVotesList()
        await setEnter(false)
      }
      // const delayFiltertime = setTimeout(async () => {
      //     await loadDistributionVotesList()
      // }, 500)
      // return () => {
      //     clearTimeout(delayFiltertime)
      // }
      // await loadDistributionVotesList()
    })()
  }, [
    enter,
    pageIndex,
    pageSize,
    createdAt,
    keyword,
    proposalStatus,
    proposalPermission,
    createUser,
  ])

  const loadDistributionVotesList = async () => {
    try {
      setIsLoading(true)
      await DistributionVotesStore.getDistributionVotestList()
    } finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async code => {
    try {
      setIsLoading(true)
      await DistributionVotesStore.delete(code)
      await loadDistributionVotesList()
      if (DistributionVotesStore.distributionVotesList.length > 0) {
        message.success('Đã xóa!')
      } else {
        if (pageIndex > 0) {
          DistributionVotesStore.setFilter('pageIndex', pageIndex - 1)
          await loadDistributionVotesList()
          message.success('Đã xóa!')
        }
      }
    } catch (err) {
      console.log(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  const handleToggleCreateModal = bool => event => {
    setIsVisibleUpdateModal(bool)
    loadDistributionVotesList()
  }

  const showConfirm = code => {
    confirm({
      title: 'Bạn có chắc chắn muốn xóa không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Xóa',
      cancelText: 'Không',
      okType: 'danger',
      onOk: async () => {
        await handleDelete(code)
      },
    })
  }

  const handleOpenUpdateModal = id => event => {
    // props.managementAssetWarehouseStore.clearManagementAssetWarehouseDetail()
    setIsVisibleUpdateModal(true)
    setSelectedTemplateId(id)
  }

  const tableColumns = [
    {
      title: 'Nội dung',
      render: record => <p>{record.title}</p>,
      width: 450,
      onCell: (record, rowIndex) => {
        return {
          onClick: ev => {
            history.push(
              `/distribution-votes/other/view/${record.proposal_code}`
            )
          },
        }
      },
    },
    {
      title: 'Ngày tạo',
      width: 100,
      align: 'center',
      render: record => {
        const date = new Date(record?.create_at || 0)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        const seconds = String(date.getSeconds()).padStart(2, '0')

        const formattedDate = `${day}/${month}/${year}`
        return <p style={{ marginBottom: '0px' }}>{formattedDate}</p>
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: ev => {
            history.push(
              `/distribution-votes/other/view/${record.proposal_code}`
            )
          },
        }
      },
    },
    {
      title: () => <div style={{}}>Người tạo</div>,
      // align: 'center',
      width: 150,
      render: record => (
        <div style={{ display: 'flex' }}>
          <AvatarHasName
            imgId={record?.image}
            name={record?.create_user?.name_lowercase}
          />
        </div>
      ),
      onCell: (record, rowIndex) => {
        return {
          onClick: ev => {
            history.push(
              `/distribution-votes/other/view/${record.proposal_code}`
            )
          },
        }
      },
    },
    {
      title: 'Trạng thái',
      align: 'center',
      render: record => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {StatusTag(TYPE_STATUS.PROPOSAL, record.proposal_status)}
        </div>
      ),
      width: 150,
      onCell: (record, rowIndex) => {
        return {
          onClick: ev => {
            history.push(
              `/distribution-votes/other/view/${record.proposal_code}`
            )
          },
        }
      },
    },
  ]
  const tableColumnsTi = [
    {
      title: 'Nội dung',
      render: record => {
        return (
          <div style={{ display: 'flex', alignContent: 'center' }}>
            {record.title}
          </div>
        )
      },
      width: 450,

      onCell: (record, rowIndex) => {
        return {
          onClick: ev => {
            history.push(
              `/distribution-votes/other/view/${record.proposal_code}`
            )
          },
        }
      },
    },
    {
      title: 'Ngày tạo',
      width: 100,
      align: 'center',
      render: record => {
        const date = new Date(record?.created_at || 0)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        const seconds = String(date.getSeconds()).padStart(2, '0')

        const formattedDate = `${day}/${month}/${year}`
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}>
            {formattedDate}
          </div>
        )
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: ev => {
            history.push(
              `/distribution-votes/other/view/${record.proposal_code}`
            )
          },
        }
      },
    },
    {
      title: () => <div style={{}}>Người tạo</div>,
      // align: 'center',
      width: 150,
      render: record => (
        <div style={{ display: 'flex' }}>
          <AvatarHasName
            imgId={record?.image}
            name={record?.create_user?.name_lowercase}
          />
        </div>
      ),
      onCell: (record, rowIndex) => {
        return {
          onClick: ev => {
            history.push(
              `/distribution-votes/other/view/${record.proposal_code}`
            )
          },
        }
      },
    },
    {
      title: 'Trạng thái',
      align: 'center',
      render: record => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {StatusTag(TYPE_STATUS.PROPOSAL, record.proposal_status)}
        </div>
      ),
      width: 150,
      onCell: (record, rowIndex) => {
        return {
          onClick: ev => {
            history.push(
              `/distribution-votes/other/view/${record.proposal_code}`
            )
          },
        }
      },
    },
    // {record &&
    //     (aclStore.checkAccessControlAction(ACL_ACTION_TYPE.edit__PROPOSAL__ASSET) ||
    //         isAccountAdmin ||
    //         checkAccessControlUpdateAction())
    // }
    // },
    {
      title: 'Thao tác',
      align: 'center',
      width: 100,
      render: record => (
        <div style={{ display: 'flex', placeContent: 'center' }}>
          {record &&
            (aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.edit__PROPOSAL__ASSET
            ) ||
              isAccountAdmin ||
              checkAccessControlUpdateAction()) && (
              <EditOutlined
                style={{ marginRight: '4px' }}
                type={'vertical'}
                onClick={handleOpenUpdateModal(record.proposal_code)}
              />
            )}
          {record &&
            (aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.delete__PROPOSAL__ASSET
            ) ||
              isAccountAdmin ||
              checkAccessControlUpdateAction()) && (
              <DeleteOutlined
                style={{ color: '#fc0000', marginLeft: '4px' }}
                onClick={() => showConfirm(record.proposal_code)}
              />
            )}
        </div>
      ),
    },
  ]

  const checkAccessControlUpdateActionV2 = record => {
    return record?.approval_levels?.some(
      item => item.username === authenticationStore?.currentUser?.username
    )
  }
  const checkAccessControlUpdateAction = record => {
    return (
      authenticationStore?.currentUser?.username ===
      record?.create_user?.user_name
    )
  }

  const handleChangePageProposalLeave = useCallback(page => {
    const pageIndex = page - 1
    DistributionVotesStore.setFilter('pageIndex', pageIndex)
    setEnter(true)
  }, [])

  const handleDateChange = async (date, dateString) => {
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : null
    await DistributionVotesStore.setFilter('pageIndex', 0)
    await DistributionVotesStore.setFilter('createdAt', formattedDate)
    await loadDistributionVotesList()
  }

  const handleSelectChange = value => {
    if (value) {
      DistributionVotesStore.setFilter('pageIndex', 0)
      DistributionVotesStore.setFilter('proposalStatus', value)
      setEnter(true)
    } else {
      DistributionVotesStore.setFilter('pageIndex', 0)
      DistributionVotesStore.setFilter('proposalStatus', undefined)
      setEnter(true)
    }
  }

  const handleChangeStatus = value => {
    if (value) {
      setEnter(true)
      DistributionVotesStore.setFilter('pageIndex', 0)
      DistributionVotesStore.setFilter('proposalPermission', value)
    } else {
      setEnter(true)
      DistributionVotesStore.setFilter('pageIndex', 0)
      DistributionVotesStore.setFilter('proposalPermission', undefined)
    }
  }

  const debouncedSetFilter = debounce(value => {
    DistributionVotesStore.setFilter('pageIndex', 0)
    DistributionVotesStore.setFilter('keyword', value)
  }, 300)
  const debouncedSetFilterName = debounce(value => {
    DistributionVotesStore.setFilter('pageIndex', 0)
    DistributionVotesStore.setFilter('createUser', value)
  }, 300)

  return (
    <>
      <Row type={'flex'} gutter={15}>
        <Col xs={24} sm={12} md={8}>
          <Input
            allowClear
            name="keyword"
            onChange={e => {
              if (e.target.value === '') {
                setKeyword('')
                DistributionVotesStore.setFilter('pageIndex', 0)
                DistributionVotesStore.setFilter('keyword', '')
                setEnter(true)
              } else {
                setKeyword(e.target.value)
              }
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                DistributionVotesStore.setFilter('pageIndex', 0)
                DistributionVotesStore.setFilter('keyword', keyword1)
                setEnter(true)
              }
            }}
            // value={keyword1}
            suffix={<SearchOutlined />}
            placeholder={'Nội dung'}
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Input
            allowClear
            name="create_user"
            onChange={e => {
              if (e.target.value === '') {
                setCreateUser1('')
                DistributionVotesStore.setFilter('pageIndex', 0)
                DistributionVotesStore.setFilter('createUser', '')
                setEnter(true)
              } else {
                setCreateUser1(e.target.value)
              }
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                DistributionVotesStore.setFilter('pageIndex', 0)
                DistributionVotesStore.setFilter('createUser', createUser1)
                setEnter(true)
              }
            }}
            // value={createUser1}
            suffix={<SearchOutlined />}
            placeholder={'Người gửi'}
          />
        </Col>

        <Col xs={24} sm={12} md={8}>
          <DatePicker
            style={{ marginRight: '16px', minWidth: 200 }}
            allowClear
            style={{ width: '100%' }}
            placeholder={'Ngày tạo'}
            format={'DD-MM-YYYY'}
            // value={date ? moment(date) : null}
            onChange={handleDateChange}
          />
        </Col>
      </Row>
      <Row type={'flex'} gutter={15}>
        <Col xs={24} sm={12} md={8}>
          <Select
            // value={proposalAdvanceStore.filterProposalStatus}
            name={'proposal_status'}
            style={{ width: '100%' }}
            placeholder={'Chọn trạng thái'}
            allowClear={proposalStatus?.length > 0 ? true : false}
            // value={proposalStatus}
            onChange={handleSelectChange}>
            <Option value={PROPOSAL_STATUS.PENDING}>Chờ phê duyệt</Option>
            <Option value={PROPOSAL_STATUS.APPROVAL}>Đã phê duyệt</Option>
            <Option value={PROPOSAL_STATUS.REJECT}>Từ chối</Option>
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            // value={proposalAdvanceStore.filterProposalStatus}
            name={'proposal_permission'}
            style={{ width: '100%' }}
            placeholder={'Chọn loại'}
            allowClear={proposalPermission?.length > 0 ? true : false}
            // value={proposalPermission}
            onChange={handleChangeStatus}>
            <Option value={ASSIGNEE_TYPE.CREATE}>Đã gửi</Option>
            <Option value={ASSIGNEE_TYPE.HANDLER}>Xử lý</Option>
            <Option value={ASSIGNEE_TYPE.FOLLOWER}>Theo dõi</Option>
          </Select>
        </Col>
      </Row>
      <TableComponent
        rowKey={record => record.code}
        dataSource={DistributionVotesStore.distributionVotesList}
        columns={
          !(
            aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.edit__PROPOSAL__ASSET
            ) ||
            isAccountAdmin ||
            checkAccessControlUpdateAction()
          ) &&
          !(
            aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.delete__PROPOSAL__ASSET
            ) ||
            isAccountAdmin ||
            checkAccessControlUpdateAction()
          )
            ? tableColumns
            : tableColumnsTi
        }
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={isLoading}
      />
      <TableBottomPaginationBlock>
        <Pagination
          current={pageIndex + 1}
          pageSize={pageSize}
          total={totalCountProposal}
          hideOnSinglePage={true}
          showSizeChanger={false}
          onChange={handleChangePageProposalLeave}
        />
      </TableBottomPaginationBlock>
      <Modal
        width={1000}
        title={'Chỉnh sửa'}
        visible={isVisibleUpdateModal}
        maskClosable={false}
        onCancel={handleToggleCreateModal(false)}
        footer={null}
        destroyOnClose>
        {isVisibleUpdateModal && (
          <DistributionVotesUpdateDetails
            templateId={selectedTemplateId}
            onCancel={handleToggleCreateModal(false)}
            btn="Lưu"
            check={false}
          />
        )}
      </Modal>
    </>
  )
}

export default inject(
  'aclStore',
  'DistributionVotesStore',
  'authenticationStore',
  'selectPeopleStore',
  'managementAssetWarehouseStore'
)(observer(DistributionVotesList))
