import { action, observable } from 'mobx'
import DistributionVotesRequest from '../requests/DistributionVotesRequest'
import CommandStore from './commandStore'

class DistributionVotesStore {
  @observable distributionVotesList = []
  @observable distributionVotesListCount = []

  @observable distributionVotesTotal = 0

  // filter
  @observable pageIndex = 0
  @observable pageSize = 10
  @observable createdAt = undefined
  @observable keyword = undefined
  @observable proposalStatus = undefined
  @observable proposalPermission = undefined
  @observable createUser = undefined
  @observable totalCountProposal = 0
  @observable checkVotes = false
  @observable otherCommentList = []
  @observable distributionVotesDetail = {
    id: null,
    proposal_code: null,
    proposal_number: null,
    reason: null,
    title: null,
    followers: [
      {
        name_lowercase: null,
        name_uppercase: null,
        last_date_read: null,
        user_name: null,
      },
    ],
    approval_levels: [
      {
        code: null,
        name: null,
        level: null,
        allow_edit: null,
        username: null,
        status: null,
        comment: null,
        created_at: null,
        updated_at: null,
      },
    ],
    proposal_asset_details: [
      {
        asset_id: null,
        amount: null,
        purpose: null,
      },
    ],
  }
  @observable distributionVotesDetails = {
    id: null,
    proposal_code: null,
    proposal_number: null,
    reason: null,
    title: null,
    followers: [
      {
        name_lowercase: null,
        name_uppercase: null,
        last_date_read: null,
        user_name: null,
      },
    ],
    approval_levels: [
      {
        code: null,
        name: null,
        level: null,
        allow_edit: null,
        username: null,
        status: null,
        comment: null,
        created_at: null,
        updated_at: null,
      },
    ],
    proposal_asset_details: [
      {
        asset_id: null,
        amount: null,
        purpose: null,
      },
    ],
  }

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }
  @action clearFilterProposal = () => {
    this.pageIndex = 0
    this.pageSize = 10
    this.totalCountProposal = 0
    this.createdAt = undefined
    this.keyword = undefined
    this.proposalStatus = undefined
    this.proposalPermission = undefined
    this.createUser = undefined
  }

  @action clearDistributionVotesDetail = () => {
    this.distributionVotesDetail = {
      id: null,
      proposal_code: null,
      proposal_number: null,
      reason: null,
      title: null,
      followers: [
        {
          name_lowercase: null,
          name_uppercase: null,
          last_date_read: null,
          user_name: null,
        },
      ],
      approval_levels: [
        {
          code: null,
          name: null,
          level: null,
          allow_edit: null,
          username: null,
          status: null,
          comment: null,
          created_at: null,
          updated_at: null,
        },
      ],
      proposal_asset_details: [
        {
          asset_id: null,
          amount: null,
          purpose: null,
        },
      ],
    }
  }
  @observable distributionVotestCommentList = []

  @action getDistributionVotestList = param => {
    return new Promise((resolve, reject) => {
      DistributionVotesRequest.getDistributionVotesList(
        `page=${this.pageIndex}&size=${this.pageSize}${
          this.keyword ? `&keyword=${this.keyword}` : ''
        }${
          this.proposalStatus ? `&proposal_status=${this.proposalStatus}` : ''
        }${
          this.proposalPermission
            ? `&proposal_permission=${this.proposalPermission}`
            : ''
        }${this.createUser ? `&create_user=${this.createUser}` : ''}${
          this.createdAt ? `&created_at=${this.createdAt}` : ''
        }`
      )
        .then(response => {
          this.distributionVotesList = response.data.data
          this.totalCountProposal = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getDistributionVotesListToCountPending = async () => {
    try {
      const response = await DistributionVotesRequest.getDistributionVotesList(
        `page=0&size=1000000${this.keyword ? `&keyword=${this.keyword}` : ''}${
          this.proposalStatus ? `&proposal_status=${this.proposalStatus}` : ''
        }${
          this.proposalPermission
            ? `&proposal_permission=${this.proposalPermission}`
            : ''
        }${this.createUser ? `&create_user=${this.createUser}` : ''}${
          this.createdAt ? `&created_at=${this.createdAt}` : ''
        }`
      )
      this.distributionVotesListCount = response.data.data
      this.totalCountProposal = response.data.total_count
    } catch (error) {
      console.error('Error fetching distribution votes list:', error)
    }
  }

  @action clearDistributionVotestList = () => {
    this.distributionVotesList.length = 0
    this.distributionVotesTotal = 0
  }

  @action getDistributionVotesDetail = code => {
    return new Promise((resolve, reject) => {
      DistributionVotesRequest.getDistributionVotesDetail(code)
        .then(response => {
          this.distributionVotesDetail = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getDistributionVotesAllDetail = code => {
    return new Promise((resolve, reject) => {
      DistributionVotesRequest.getDistributionVotesAllDetail(code)
        .then(response => {
          this.distributionVotesDetails = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createDistributionVotes = payload => {
    return new Promise((resolve, reject) => {
      DistributionVotesRequest.createDistributionVotes(payload)
        .then(response => {
          if (response.data.proposal_status === 'PENDING') {
            // Cập nhật số đếm phiếu chưa duyệt
            CommandStore.incrementUnapprovedDistributeVote()
          }
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action updateDistributionVotes = (code, payload) => {
    return new Promise((resolve, reject) => {
      DistributionVotesRequest.updateDistributionVotes(code, payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action delete = code => {
    return DistributionVotesRequest.deleteDistributionVotes(code)
  }

  @action setQueryParams = (param, value) => {
    this[param] = value
  }

  @action DistributionVotesOther = data => {
    return new Promise((resolve, reject) => {
      DistributionVotesRequest.distributionVotesOther(data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action getDistributionVotesComment = proposalId => {
    return new Promise((resolve, reject) => {
      DistributionVotesRequest.getDistributionVotesComment(proposalId)
        .then(response => {
          this.distributionVotestCommentList = response.data.reverse()
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action deleteDistributionVotesComments = (proposal_code, comment_id) => {
    return new Promise((resolve, reject) => {
      DistributionVotesRequest.deleteDistributionVotesComment(
        proposal_code,
        comment_id
      )
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
  @action createOtherComment = (proposalId, commentData) => {
    return new Promise((resolve, reject) => {
      DistributionVotesRequest.createOtherComment(proposalId, commentData)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  @action clearOtherCommentList = () => {
    this.otherCommentList = []
  }
  @action approveProposal = (proposalCode, payload) => {
    return new Promise((resolve, reject) => {
      DistributionVotesRequest.approveProposal(proposalCode, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new DistributionVotesStore()
