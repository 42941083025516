import {
  CloseCircleOutlined,
  EditOutlined,
  FileAddOutlined,
  FilterOutlined,
  InboxOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import {
  Avatar,
  Button,
  Col,
  Comment,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Switch,
  Tooltip,
  Upload,
  message,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { withRouter } from 'react-router-dom'
import AddUserForm from '../../components/AddUserForm'
import { EmptyText } from '../../components/Common/CellText'
import {
  CellEclipseBox,
  TableBottomPaginationBlock,
} from '../../components/Common/Table'
import TableComponent from '../../components/Common/TableComponent'
import EmptyContent from '../../components/EmptyContent'
import PageTitle from '../../components/PageTitle'
import UserAccountEditForm from '../../components/UserAccountEditForm'
import UserListFilterBlock from '../../components/UserListFilterBlock'
import DocumentsLayout from '../UserManagementPage/DocumentsLayout'
import ModalPreView from './ModalPreView'
import { FilterButton, SearchBar } from './UserAcountManagementPageStyled'

const { Search } = Input
const { Dragger } = Upload
const { Option } = Select

const UserAccountManagementPage = props => {
  const {
    userStore,
    loadingAnimationStore,
    companyStore,
    authenticationStore,
    history,
    positionStore,
    departmentStore,
    roleStore,
    fileStore,
  } = props

  const {
    userListPageIndex,
    userListPageSize,
    userListKeyword,
    userListStatus,
    userListDepartmentCode,
    userListSortDirection,
    userListSortBy,
  } = userStore

  const { companyList } = companyStore

  const [showUserInfo, setShowUserInfo] = useState(false)
  const [showAddUser, setShowAddUser] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [fileExcel, setFileExcel] = useState(null)
  const [importVisible, setImportVisible] = useState(false)
  const [dataExcel, setDataExcel] = useState(null)
  const [preViewVisible, setPreViewVisible] = useState(false)

  const tableColumns = useMemo(
    () => [
      {
        title: 'Họ tên',
        width: 240,
        key: 'fullname',
        render: record => (
          <Comment
            author={
              <span style={{ textTransform: 'capitalize' }}>
                {record.name_lowercase}
              </span>
            }
            avatar={
              <Avatar
                size={36}
                style={{ backgroundColor: '#1890ff' }}
                src={
                  record?.image &&
                  `url("data:image/gif;base64,${record?.image}")`
                }>
                {record?.name_uppercase?.split('')[0].toString()?.toUpperCase()}
              </Avatar>
            }
          />
        ),
      },
      {
        title: 'ID',
        key: 'username',
        width: 100,
        render: record => record?.username,
      },
      {
        title: 'Mã nhân viên',
        key: 'ma_nv',
        width: 120,
        render: record =>
          record?.ma_nv ? record?.ma_nv : <EmptyText>Không rõ</EmptyText>,
      },
      {
        title: 'Email',
        key: 'email',
        render: record => record?.email,
      },
      {
        title: 'Số ĐT',
        key: 'phone',
        render: record => <strong>{record?.phone}</strong>,
      },
      {
        title: 'Số máy nội bộ',
        key: 'home_phone',
        render: record => (
          <strong>
            {record?.home_phone ? (
              record?.home_phone
            ) : (
              <EmptyText>Không rõ.</EmptyText>
            )}
          </strong>
        ),
      },
      {
        title: 'Trạng thái',
        key: 'status',
        width: 100,
        render: record => (
          <Switch
            onChange={status => handleUpdateUserStatus(record?.code, status)}
            checked={record?.status}
          />
        ),
      },
      {
        title: 'Công ty',
        key: 'companyName',
        render: record => (
          <Tooltip title={record?.company && record?.company.name}>
            <CellEclipseBox>
              {record?.company && record?.company.name}
            </CellEclipseBox>
          </Tooltip>
        ),
      },
      {
        title: 'Tác vụ',
        key: 'action',
        fixed: 'right',
        width: 130,
        render: record => (
          <Button onClick={() => handleViewUserInfo(record)} type="primary">
            <EditOutlined />
            Chỉnh sửa
          </Button>
        ),
      },
    ],
    []
  )

  const handleViewUserInfo = useCallback(
    userInfo => {
      const companyCode = userInfo?.company?.code
      userStore.setSelectedUser(userInfo)
      loadingAnimationStore.showSpinner(true)
      departmentStore
        .getDepartmentListFromCompanyCode(companyCode)
        .then(() => setShowUserInfo(true))
        .catch(error => message.error(error.vi))
        .finally(() => loadingAnimationStore.showSpinner(false))
    },
    [departmentStore, loadingAnimationStore, userStore]
  )

  const handleCancelViewUserInfo = useCallback(() => {
    userStore.clearSelectedUser()
    setShowUserInfo(false)
  }, [userStore])

  const handleChangePageIndex = useCallback(
    page => {
      userStore.changeUserListPageIndex(page - 1)
    },
    [userStore]
  )

  const handleShowAdvancedFilter = useCallback(
    status => {
      setShowFilter(status)
      if (!status) {
        userStore.clearUserListFilter()
      }
    },
    [userStore]
  )

  const handleUpdateUserStatus = useCallback(
    (userCode, status) => {
      loadingAnimationStore.showSpinner(true)
      userStore
        .updateUserStatus(userCode, status)
        .then(() => {
          return new Promise((resolve, reject) => {
            userStore
              .getUserList()
              .then(() => resolve())
              .catch(() => reject())
          })
        })
        .then(() => {
          message.success(`Đã ${status ? 'kích hoạt' : 'tạm dừng'} người dùng!`)
          loadingAnimationStore.showSpinner(false)
        })
        .catch(error => {
          message.error(error.vi)
          loadingAnimationStore.showSpinner(false)
        })
    },
    [loadingAnimationStore, userStore]
  )
  const handleCancelAddUser = useCallback(() => {
    setShowAddUser(false)
  }, [])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    Promise.all([
      companyStore.getCompanyList(),
      positionStore.getPositionList(userStore.companyCodeCurrent),
      roleStore.getRoles(),
    ])
      .then(() => loadingAnimationStore.showSpinner(false))
      .catch(() => loadingAnimationStore.showSpinner(false))
    return () => {
      userStore.clearUserListFilter()
      userStore.changeUserListKeyword(undefined)
      userStore.changeUserListPageIndex(0)
    }
  }, [
    companyStore,
    loadingAnimationStore,
    positionStore,
    roleStore,
    userStore,
    userStore.companyCodeCurrent,
  ])

  useEffect(() => {
    if (authenticationStore.currentUser) {
      loadingAnimationStore.showSpinner(true)
      // userStore.setFilter('userListHasAdmin', true)
      userStore.setFilter(
        'companyCode',
        userStore.companyCodeCurrent || authenticationStore.currentCompanyCode()
      )
      userStore
        .getUserList()
        .finally(() => loadingAnimationStore.showSpinner(false))
    }
  }, [
    userListPageIndex,
    userListKeyword,
    userListStatus,
    userListDepartmentCode,
    userListSortDirection,
    userListSortBy,
    loadingAnimationStore,
    userStore,
    userStore.companyCodeCurrent,
    authenticationStore.currentUser,
  ])

  const handleGetDataExcel = async () => {
    if (!fileExcel) {
      message.error('Bạn chưa chọn file Excel.')
      return
    }
    const fileExtension = fileExcel.name.split('.').pop()

    if (fileExtension !== 'xlsx' && fileExtension !== 'xls') {
      message.error('Định dạng file không đúng.')
      return
    }
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', fileExcel)
    try {
      const response = await fileStore.sendDataExcel(formData)
      setDataExcel(response)
      setPreViewVisible(true)
      loadingAnimationStore.showSpinner(false)
    } catch (error) {
      console.error('Error importing Excel:', error)
      message.error('Đã xảy ra lỗi khi import file Excel.')
      loadingAnimationStore.showSpinner(false)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  useEffect(() => {
    if (fileExcel == null) return
    handleGetDataExcel()
  }, [fileExcel])

  useEffect(() => {
    if (!authenticationStore.currentUser) return
    // if (
    //   authenticationStore.currentUser &&
    //   authenticationStore.currentUser.roles.includes('SUPER_ADMIN')
    // )
    //   return
    // history.push('/')
    // message.error(
    //   'Bạn không có quyền truy cập trang này, vui lòng liên hệ admin'
    // )
  }, [authenticationStore.currentUser, history])

  useEffect(() => {
    return () => {
      userStore.clearStore()
    }
  }, [])

  const handleCancelImport = () => {
    setImportVisible(false)
    setFileExcel(null)
  }

  const handleRemoveFile = () => {
    setFileExcel(null)
  }

  const handleDownLoadTemplate = async () => {
    try {
      console.log('A1')
      loadingAnimationStore.showSpinner(true)
      const response = await fileStore.downloadTemplateAccount()
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'template.xlsx')
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading template:', error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const onCompanyChange = value => {
    userStore.setFilter('companyCode', value)
    return () => {
      userStore.companyCode = null
    }
  }

  return (
    <>
      {!props.isTab ? (
        <DocumentsLayout
          title={
            <PageTitle location={props.location} title={'Quản lý Tài khoản'}>
              <Button type={'primary'} onClick={() => setShowAddUser(true)}>
                <PlusCircleOutlined />
                Thêm mới người dùng
              </Button>
            </PageTitle>
          }
          selectedKey={'user-account-management'}>
          <Helmet>
            <title>Quản lý tài khoản</title>
          </Helmet>
          <Row style={{ marginBottom: 16 }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={userStore.changeUserListKeyword}
                  placeholder={'Tìm kiếm theo tên hoặc username'}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <FilterButton
                onClick={() => handleShowAdvancedFilter(!showFilter)}
                type={showFilter ? 'primary' : 'default'}
                ghost={showFilter}>
                {showFilter ? (
                  <Fragment>
                    <CloseCircleOutlined />
                    &nbsp; Tắt tìm kiếm nâng cao
                  </Fragment>
                ) : (
                  <Fragment>
                    <FilterOutlined />
                    &nbsp; Tìm kiếm nâng cao
                  </Fragment>
                )}
              </FilterButton>
              <Button
                type="primary"
                style={{ marginLeft: '10px' }}
                onClick={() => setImportVisible(true)}>
                <FileAddOutlined />
                Nhập file excel
              </Button>
            </Col>
          </Row>
          {showFilter ? <UserListFilterBlock /> : null}
          <TableComponent
            rowKey={record => record?.code}
            dataSource={userStore.userHasAdminList}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: 1000 }}
            locale={{
              emptyText: <EmptyContent />,
            }}
          />
          <TableBottomPaginationBlock>
            <Pagination
              onChange={handleChangePageIndex}
              pageSize={userListPageSize}
              showSizeChanger={false}
              showLessItems
              current={userListPageIndex + 1}
              total={userStore.userListTotalCount}
              hideOnSinglePage={true}
            />
          </TableBottomPaginationBlock>
        </DocumentsLayout>
      ) : (
        <>
          <Row style={{ marginBottom: 16 }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={userStore.changeUserListKeyword}
                  placeholder={'Tìm kiếm theo tên hoặc username'}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <FilterButton
                onClick={() => handleShowAdvancedFilter(!showFilter)}
                type={showFilter ? 'primary' : 'default'}
                ghost={showFilter}>
                {showFilter ? (
                  <Fragment>
                    <CloseCircleOutlined />
                    &nbsp; Tắt tìm kiếm nâng cao
                  </Fragment>
                ) : (
                  <Fragment>
                    <FilterOutlined />
                    &nbsp; Tìm kiếm nâng cao
                  </Fragment>
                )}
              </FilterButton>
            </Col>
          </Row>
          {showFilter ? <UserListFilterBlock /> : null}
          <TableComponent
            rowKey={record => record?.code}
            dataSource={userStore.userHasAdminList}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: 1000 }}
            locale={{
              emptyText: <EmptyContent />,
            }}
          />
          <TableBottomPaginationBlock>
            <Pagination
              onChange={handleChangePageIndex}
              pageSize={userListPageSize}
              showSizeChanger={false}
              showLessItems
              current={userListPageIndex + 1}
              total={userStore.userListTotalCount}
              hideOnSinglePage={true}
            />
          </TableBottomPaginationBlock>
        </>
      )}
      <UserAccountEditForm
        modalVisible={showUserInfo}
        onCancel={handleCancelViewUserInfo}
      />

      <AddUserForm modalVisible={showAddUser} onCancel={handleCancelAddUser} />
      <Modal
        title="Nhập file excel"
        visible={importVisible}
        onCancel={handleCancelImport}
        footer={[
          <Button danger ghost onClick={handleCancelImport}>
            Hủy
          </Button>,
          <Button onClick={handleDownLoadTemplate}>Tải mẫu nhập</Button>,
        ]}>
        <Dragger
          fileList={fileExcel ? [fileExcel] : []}
          onRemove={handleRemoveFile}
          beforeUpload={file => {
            setFileExcel(file)
            return false
          }}
          multiple={false}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Kéo thả hoặc nhấn chọn file excel</p>
        </Dragger>
      </Modal>
      {preViewVisible && (
        <ModalPreView
          data={dataExcel}
          setPreViewVisible={setPreViewVisible}
          onCancel={() => setPreViewVisible(false)}
        />
      )}
    </>
  )
}

export default withRouter(
  inject(
    'userStore',
    'loadingAnimationStore',
    'companyStore',
    'authenticationStore',
    'positionStore',
    'departmentStore',
    'roleStore',
    'fileStore'
  )(observer(UserAccountManagementPage))
)
