import { DatePicker, Spin, Timeline } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

const ManagementAssetWarehouseHistory = props => {
  const {
    selectedHistoryId,
    historyAssetName,
    managementAssetWarehouseStore,
  } = props
  const { managementAssetWarehouseHistoryList } = managementAssetWarehouseStore
  console.log(
    '🚀 ~ ManagementAssetWarehouseHistory ~ managementAssetWarehouseHistoryList:',
    toJS(managementAssetWarehouseHistoryList)
  )

  const [changeYear, setChangeYear] = useState(moment().year())
  console.log('🚀 ~ ManagementAssetWarehouseHistory ~ changeYear:', changeYear)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true) // Bắt đầu tải
      try {
        await managementAssetWarehouseStore.getManagementAssetWareHouseHistoryList(
          {
            assetId: selectedHistoryId,
            year: changeYear,
          }
        )
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setIsLoading(false) // Kết thúc tải
      }
    }

    fetchData()
  }, [changeYear, selectedHistoryId])

  const onChange = (date, dateString) => {
    setChangeYear(parseInt(dateString))
  }

  const capitalizeFirstLetterOfEachWord = text => {
    if (!text) return text // Kiểm tra nếu chuỗi rỗng
    return text
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }
  const formatNumberWithCommas = str => {
    const [integerPart, decimalPart] = str.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: '30px' }}>
          Tên văn phòng phẩm:{' '}
          <span style={{ fontWeight: 600, fontSize: '16px' }}>
            {historyAssetName}
          </span>
        </div>

        <DatePicker onChange={onChange} picker="year" defaultValue={moment()} />
      </div>
      {isLoading ? (
        <div
          style={{
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ marginTop: '10px' }}>
          {managementAssetWarehouseHistoryList.map((item, index) => {
            return (
              <Timeline key={index}>
                <Timeline.Item>
                  <span style={{ fontSize: 16 }}>
                    Tháng: <span style={{ fontWeight: 600 }}>{item.month}</span>
                  </span>
                  <p>
                    {item.histories?.map(his => {
                      return (
                        <div style={{ marginLeft: '10px' }}>
                          <span>
                            -{' '}
                            <span style={{ fontWeight: 600 }}>
                              {capitalizeFirstLetterOfEachWord(his.user_name)}
                            </span>{' '}
                            đã{' '}
                            <span>
                              {his.type === 'CREATE'
                                ? 'tạo mới'
                                : his.type === 'UPDATE'
                                ? 'nhập kho'
                                : null}
                            </span>{' '}
                            ngày{' '}
                            <span>
                              {moment(his.create_at).format('DD/MM/YYYY')}
                            </span>{' '}
                            với:
                            <ul>
                              <li>
                                Thuế: <span>{his.tax}%</span>
                              </li>
                              <li>
                                Đơn giá trước thuế:{' '}
                                <span>{formatNumberWithCommas(his.price)}</span>
                              </li>
                              <li>
                                Đơn giá sau thuế:{' '}
                                <span>
                                  {formatNumberWithCommas(his.total_money)}
                                </span>
                              </li>
                              <li>
                                Số lượng: <span>{his.quantity}</span>
                              </li>
                            </ul>
                          </span>
                        </div>
                      )
                    })}
                  </p>
                </Timeline.Item>
              </Timeline>
            )
          })}
        </div>
      )}
    </>
  )
}

export default inject('managementAssetWarehouseStore')(
  observer(ManagementAssetWarehouseHistory)
)
