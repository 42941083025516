import { action, observable } from 'mobx'
import { RiskRequest } from '../../requests/RiskRequest'
// @ts-nocheck
class RiskUnitUserStore {
  @observable riskUnitUsers = {
    NDDPV: [],
    DVPT: [],
    CQVP: [],
    ALL: [],
  }

  @observable selectedRiskExecutors = {
    tier1: [],
    tier2: [],
    tier3: [],
  }
  @observable selectedNDDPVgeneral = {
    tier1: [],
    tier2: [],
    tier3: [],
    tier4: [],
  }

  @observable selectedDVPTprofessional = {
    tier1: [],
    tier2: [],
    tier3: [],
    tier4: [],
  }

  @observable selectedTier1NDDPVgeneral = {
    tier1: [],
  }

  @observable selectedUnitLeaders = {
    tier1: [],
  }

  @observable customRiskExecutorFullname = {
    tier1: undefined,
  }

  @action async getNDDPVUsers() {
    const { data: riskUnits } = await RiskRequest.getRiskUnitsV2('NDDPV')
    const riskUnitUserLists = riskUnits.map(unit => ({
      ...unit.unit_users.map(user => ({
        ...user,
        unit: {
          code: unit.code,
          name: unit.name,
        },
      })),
    }))

    const riskUnitUsers = []
    for (const list of riskUnitUserLists) {
      riskUnitUsers.push(...Object.values(list))
    }

    return (this.riskUnitUsers['NDDPV'] = riskUnitUsers)
  }

  @action async getDVPTUsers() {
    const { data: riskUnits } = await RiskRequest.getRiskUnitsV2('DVPT')
    const riskUnitUserLists = riskUnits.map(unit => ({
      ...unit.unit_users.map(user => ({
        ...user,
        unit: {
          code: unit.code,
          name: unit.name,
        },
      })),
    }))

    const riskUnitUsers = []
    for (const list of riskUnitUserLists) {
      riskUnitUsers.push(...Object.values(list))
    }

    return (this.riskUnitUsers['DVPT'] = riskUnitUsers)
  }

  @action setSelectedTier1RiskExecutors(usernames) {
    this.selectedRiskExecutors.tier1 = this.riskUnitUsers[
      'NDDPV'
    ].filter(user => usernames.includes(user.username))
  }

  @action setSelectedTier2RiskExecutors(usernames) {
    this.selectedRiskExecutors.tier2 = this.riskUnitUsers[
      'NDDPV'
    ].filter(user => usernames.includes(user.username))
  }
  @action setSelectedTier1NDDPVgeneral(usernames) {
    this.selectedTier1NDDPVgeneral.tier1 = this.riskUnitUsers[
      'NDDPV'
    ].filter(user => usernames.includes(user.username))
  }

  /**
   * @param {string[]} usernames
   * @param {'NDDPV' | 'DVPT' | 'CQVP'} type
   */
  @action setSelectedTier3RiskExecutors(usernames, type) {
    this.selectedRiskExecutors.tier3 = this.riskUnitUsers[type].filter(user =>
      usernames.includes(user.username)
    )
  }

  @action setSelectedTier2NDDPVgeneral(usernames) {
    this.selectedNDDPVgeneral.tier2 = this.riskUnitUsers['NDDPV'].filter(user =>
      usernames.includes(user.username)
    )
  }

  @action setSelectedTier2DVPTprofessional(usernames) {
    this.selectedDVPTprofessional.tier2 = this.riskUnitUsers[
      'DVPT'
    ].filter(user => usernames.includes(user.username))
  }

  @action setCustomTier1RiskExecutorFullname(fullname) {
    this.customRiskExecutorFullname.tier1 = fullname
  }

  @action setSelectedTier1UnitLeaders(usernames) {
    this.selectedUnitLeaders.tier1 = this.riskUnitUsers['DVPT'].filter(user =>
      usernames.includes(user.username)
    )
  }

  @action clearStore() {
    for (const key of Object.keys(this.riskUnitUsers)) {
      this.riskUnitUsers[key] = []
    }

    for (const key of Object.keys(this.selectedRiskExecutors)) {
      this.selectedRiskExecutors[key] = []
    }

    for (const key of Object.keys(this.customRiskExecutorFullname)) {
      this.customRiskExecutorFullname[key] = undefined
    }

    for (const key of Object.keys(this.selectedUnitLeaders)) {
      this.selectedUnitLeaders[key] = []
    }

    for (const key of Object.keys(this.selectedNDDPVgeneral)) {
      this.selectedNDDPVgeneral[key] = []
    }

    for (const key of Object.keys(this.selectedDVPTprofessional)) {
      this.selectedDVPTprofessional[key] = []
    }

    for (const key of Object.keys(this.selectedTier1NDDPVgeneral)) {
      this.selectedTier1NDDPVgeneral[key] = []
    }
  }
}

export default new RiskUnitUserStore()
