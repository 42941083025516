import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  TreeSelect,
} from 'antd'
import { inject, observer } from 'mobx-react'
import validator from '../../validator'
import { FormButtonGroup } from './ReportPageStyled'
import { ASSIGNEE_TYPE, REPORT_TYPE } from '../../constants'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import RichEditor from '../../components/RichEditor/RichEditor'
import { blue } from '../../color'
import RuiRoNhanDienTemplate from '../RiskCreatePage/RuiRoNhanDienTemplate'
import utils from '../../utils'
import { handleFilterSelect } from '../../utils/filterSelect'

const { Option } = Select
const { RangePicker } = DatePicker
const { SHOW_ALL, SHOW_CHILD } = TreeSelect

const ReportCreateModal = props => {
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = useState(false)
  const [selectedField, setSelectedField] = useState(null)
  const {
    modalVisible,
    onCancel,
    editReport,
    loadingAnimationStore,
    onRefresh,
    reportStore,
    riskStore,
    companyStore,
    roleStore,
    positionStore,
    departmentStore,
    selectPeopleStore,
    departmentWithUserMasterList,
  } = props
  const { type: reportType, createReport } = reportStore
  const { riskUnits, riskUnitsV2 } = riskStore
  const { getCompanyList, companyList } = companyStore
  const { departmentList } = departmentStore
  const {
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    isVisibleSelectUserDepartmentPopup,
    setSelectUserData,
    selectUserData,
    departmentWithUserList,
    getDepartmentWithUsers,
    getDepartmentWithUsersLive,
  } = selectPeopleStore
  const [getData, setGetData] = useState([])
  const [dataRiskUnitsByType, setRiskUnitsByType] = useState([])
  const [dataRiskUnitsByTypeNDDPV, setRiskUnitsByTypeNDDPV] = useState([])

  const getRiskUnitsByTypeList = async type => {
    await riskStore.getRiskUnitsV2(type).then(response => {
      setRiskUnitsByType(response.data)
    })
  }
  const getRiskUnitsByTypeListNDDPV = async type => {
    await riskStore.getRiskUnitsV2(type).then(response => {
      setRiskUnitsByTypeNDDPV(response.data)
    })
  }
  useEffect(() => {
    getRiskUnitsByTypeList('DVPT')
    getRiskUnitsByTypeListNDDPV('NDDPV')
  }, [])
  useEffect(() => {
    ;(async () => {
      await positionStore.getPositionList()
      await roleStore.getRoles()
      if (!companyList) {
        getCompanyList()
      }
    })()
  }, [])
  const [depattData, setDepattData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDepartmentWithUsers('CPN7451091748209')
        setDepattData(response.data)
      } catch (error) {
        console.log(error)
        message.error(error.vi || 'Đã có lỗi xảy ra!')
      }
    }

    fetchData()
  }, [])

  const treeData = Array.isArray(riskUnitsV2)
    ? riskUnitsV2.map(item => ({
        title: item.name || '',
        value: item.code || '',
        key: item.code || '',
        disabled: true,
        children: Array.isArray(item.unit_users)
          ? item.unit_users.map(user => ({
              title:
                utils && utils.getNameInCapitalize
                  ? utils.getNameInCapitalize(user.full_name)
                  : user.full_name || '',
              value: user.user_code || '',
              key: user.user_code || '',
              position_name: user.position_name || '',
            }))
          : [],
      }))
    : []

  const treeData1 = dataRiskUnitsByType?.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.unit_users.map(user => ({
      title: user.full_name,
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))
  const treeData2 = depattData?.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    disabled: true,
    children: item.users.map(user => ({
      title: utils.getNameInCapitalize(user.name_uppercase),
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))
  const treeData3 = dataRiskUnitsByTypeNDDPV?.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    disabled: true,
    children: item?.unit_users?.map(user => ({
      title: user.full_name,
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))

  const treeDataDp = departmentWithUserList.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    disabled: true,
    children: item.users.map(user => ({
      title: utils.getNameInCapitalize(user.name_uppercase),
      value: user.user_code,
      key: user.user_code,
    })),
  }))

  const combinedTreeData = [
    ...treeData,
    ...treeData1,
    ...treeData2,
    ...treeData3,
    ...treeDataDp,
  ]

  const [treeData_BPDX, setTreeData] = useState([])
  const [treeData_BP, setTreeDataBP] = useState([])
  const [treeData_LD, setTreeDataLD] = useState([])
  const [listChildren, setListChildren] = useState([])
  useEffect(() => {
    const updatedTreeData = treeData2?.concat(treeData1)
    setTreeData(updatedTreeData)
    const updatedTreeDataBP = treeData2?.concat(treeData)
    setTreeDataBP(updatedTreeDataBP)
    const updatedTreeDataLD = treeData2?.concat(treeData3)
    setTreeDataLD(updatedTreeDataLD)
  }, [riskUnitsV2, departmentWithUserList])

  function removeDuplicates(data) {
    const seenTitles = new Set() // Để lưu các tiêu đề đã thấy
    const uniqueData = []

    for (const item of data) {
      if (!seenTitles.has(item.title)) {
        seenTitles.add(item.title)
        uniqueData.push(item)
      }
    }
    // Đệ quy để xử lý các phần tử con
    for (const item of uniqueData) {
      if (item.children && item.children.length > 0) {
        item.children = removeDuplicates(item.children) // Gọi đệ quy cho children
      }
    }
    return uniqueData
  }

  const finalTreeData = Array.from(
    new Set(combinedTreeData.map(JSON.stringify))
  ).map(JSON.parse)
  const [disabled, setDisabled] = useState(true)
  const [checked_lddv, setChecked_lddv] = useState(false)

  const toggleDisable = e => {
    if (e?.length > 0) {
      setDisabled(false)
    }
    if (e?.length < 1) {
      setChecked_lddv(false)
      setDisabled(true)
    }
  }
  const [checked_ptc, setChecked_ptc] = useState(false)
  const [disabled_ptc, setDisabled_ptc] = useState(true)
  const onChangeCheckedPtc = e => {
    setChecked_ptc(e.target.checked)
  }

  useEffect(() => {
    ;(async () => {
      try {
        await departmentStore.getDepartmentList()
      } catch (err) {
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
  }, [])

  const handleSelectChange = (field, value) => {
    if (value) {
      setSelectedField(field)
    } else {
      setSelectedField(null)
    }
  }

  const arrUnit = riskUnits.concat(companyList)
  const [isRenderRichText, setIsRenderRichText] = useState(false)
  const [section2Content, setSection2Content] = useState(
    editReport?.section2 || ''
  )
  const [section3Content, setSection3Content] = useState(
    editReport?.section3 || ''
  )

  const [
    isVisibleRuiRoNhanDienTemplate,
    setIsVisibleRuiRoNhanDienTemplate,
  ] = useState(false)
  const [ruiRoNhanDien, setRuiRoNhanDien] = useState({
    section2: [],
    section3: [],
  })
  const [section, setSection] = useState()
  const SECTION1_REF = useRef()
  const SECTION2_REF = useRef()
  const SECTION3_REF = useRef()
  const SECTION4_REF = useRef()
  useEffect(() => {
    if (!editReport) {
      return
    }
    form.setFieldsValue({
      title: editReport.title,
    })
  }, [editReport])
  useEffect(() => {
    if (modalVisible) {
      setTimeout(() => {
        setIsRenderRichText(true)
      }, 600)
    } else {
      setIsRenderRichText(false)
    }
  }, [modalVisible])

  const handleEditorChange = (section, content) => {
    if (section === 'section2') {
      setSection2Content(content)
    } else if (section === 'section3') {
      setSection3Content(content)
    }
  }

  useEffect(() => {
    if (ruiRoNhanDien) {
      SECTION2_REF.current?.editor.setData(
        `${ruiRoNhanDien.section2
          .map(
            item =>
              `${
                item.rui_ro_nhan_dien
                  ? item.rui_ro_nhan_dien
                  : item.identification_risk
              }`
          )
          .join('<br/>')}`
      )
      SECTION3_REF.current?.editor.setData(
        `${ruiRoNhanDien.section3
          .map(
            item =>
              `${
                item.rui_ro_nhan_dien
                  ? item.rui_ro_nhan_dien
                  : item.identification_risk
              }`
          )
          .join('<br/>')}`
      )
    }
  }, [ruiRoNhanDien])

  const onFinish = values => {
    setSelectedField(null)
    if (submitting) return
    setSubmitting(true)
    const fields = [
      'risk_unit_code',
      'risk_dependent_code',
      'cqvp_code',
      'nddpv_code',
      'dvpt_code',
    ]
    const {
      dvpt_code,
      nddpv_code,
      cqvp_code,
      dateRange,
      report_submit_date,
    } = values
    const [startDate, endDate] = dateRange
    if (
      (!dvpt_code || dvpt_code.length === 0) &&
      (!nddpv_code || nddpv_code.length === 0) &&
      (!cqvp_code || cqvp_code.length === 0)
    ) {
      message.error('Vui lòng chọn một đơn vị', 1)
      setSubmitting(false)
      return
    }

    const submitData = {
      type: reportType,
      title: values.title,
      risk_unit_code: values.risk_unit_code,
      risk_dependent_code: values.risk_dependent_code,
      cqvp_code: values.cqvp_code,
      dvpt_code: values.dvpt_code,
      nddpv_code: values.nddpv_code,
      section1: SECTION1_REF.current.editor.getData(),
      section2: SECTION2_REF.current.editor.getData(),
      section3: SECTION3_REF.current.editor.getData(),
      section4: SECTION4_REF.current.editor.getData(),
      report_submit_date: report_submit_date.format('DD-MM-YYYY'),
      report_period_start: startDate.format('DD-MM-YYYY'),
      report_period_end: endDate.format('DD-MM-YYYY'),
      sign_user: values.truong_ban,
      is_sign: values.truong_ban__signed,
      position_sign: values.chuc_vu,
    }

    loadingAnimationStore.showSpinner(true)
    createReport(submitData)
      .then(() => {
        handleCancelAdd()
        loadingAnimationStore.showSpinner(false)
        message.success('Tạo báo cáo thành công', 3)
        if (onRefresh) {
          onRefresh()
          props.onClearFilters()
        }
      })
      .finally(() => {
        setSubmitting(false)
        loadingAnimationStore.showSpinner(false)
      })
  }

  const onReset = useCallback(() => {
    form.resetFields()
    SECTION1_REF.current?.editor.setData('')
    SECTION2_REF.current?.editor.setData('')
    SECTION3_REF.current?.editor.setData('')
    SECTION4_REF.current?.editor.setData('')
    setRuiRoNhanDien({ section2: [], section3: [] })
    setIsChecked(false)
  }, [form])

  const handleCancelAdd = useCallback(() => {
    onCancel()
    onReset()
  }, [onReset, onCancel])

  const findNodeByValue = (nodes, value) => {
    for (const node of nodes) {
      if (node.value === value) {
        return node
      }
      if (node.children) {
        const foundNode = findNodeByValue(node.children, value)
        if (foundNode) {
          return foundNode
        }
      }
    }
    return null
  }

  const handleTreeChange = value => {
    const selectedNode = findNodeByValue(finalTreeData, value)
    if (selectedNode && selectedNode.children) {
      setValue(null)
      return
    }
    setValue(value)
  }

  const positions = [
    { value: 'Trưởng ban', label: 'Trưởng ban' },
    { value: 'Phó Trưởng ban', label: 'Phó Trưởng ban' },
    { value: 'Giám Đốc TT CNTT', label: 'Giám Đốc TT CNTT' },
    { value: 'Phó Giám đốc TT CNTT', label: 'Phó Giám Đốc TT CNTT' },
    { value: 'Giám Đốc chi nhánh', label: 'Giám Đốc chi nhánh' },
    { value: 'Phó Giám Đốc chi nhánh', label: 'Phó Giám Đốc chi nhánh' },
    { value: 'Giám Đốc công ty', label: 'Giám Đốc công ty' },
    { value: 'Phó Giám Đốc công ty', label: 'Phó Giám Đốc công ty' },
    { value: 'Chánh Văn phòng cơ quan', label: 'Chánh Văn phòng cơ quan' },
    {
      value: 'Phó chánh Văn phòng cơ quan',
      label: 'Phó chánh Văn phòng cơ quan',
    },
  ]
  const [selectedValue, setSelectedValue] = useState(null)
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true)
  const [isChecked, setIsChecked] = useState(false)
  const handleTreeSelectChange = value => {
    setSelectedValue(value)
    if (value) {
      setIsCheckboxDisabled(false)
    } else {
      setIsCheckboxDisabled(true)
      setIsChecked(false)
    }
  }

  const handleCheckboxChange = e => {
    setIsChecked(e.target.checked)
  }
  return (
    <>
      <Modal
        width={800}
        destroyOnClose={true}
        afterClose={() => {
          setIsRenderRichText(false)
        }}
        zIndex={500}
        style={{ top: 40 }}
        title={'Tạo báo cáo'}
        visible={modalVisible}
        scrollToFirstError
        maskClosable={false}
        // transitionName=""
        // maskTransitionName=""
        onCancel={handleCancelAdd}
        footer={null}>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={onFinish}
          preserve={false}
          scrollToFirstError={true}>
          <h2>
            Loại:{' '}
            {reportType === REPORT_TYPE.PERIODICAL_REPORT
              ? 'Báo cáo định kỳ'
              : 'Báo cáo đột xuất'}
          </h2>
          <Form.Item
            name={'title'}
            label={'Tiêu đề:'}
            rules={[
              { required: true, message: 'Vui lòng nhập tiêu đề!' },
              {
                validator: validator.validateInputString,
              },
            ]}>
            <Input placeholder={'Tiêu đề báo cáo'} />
          </Form.Item>
          <Form.Item
            name={'cqvp_code'}
            label={'Đơn vị thuộc Cơ quan Văn phòng Tổng công ty'}>
            <Select
              allowClear
              showSearch
              onChange={value => handleSelectChange('cqvp_code', value)}
              value={selectedField === 'cqvp_code' ? undefined : null}
              disabled={selectedField && selectedField !== 'cqvp_code'}
              filterOption={handleFilterSelect}
              placeholder={'Chọn đơn vị'}
              tokenSeparators={[',']}>
              {departmentList?.map(unit => (
                <Option key={unit.code} name={'cqvp_code'} value={unit.code}>
                  {unit.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'dvpt_code'} label={'Đơn vị phụ thuộc VIMC'}>
            <Select
              allowClear
              showSearch
              onChange={value => handleSelectChange('dvpt_code', value)}
              value={selectedField === 'dvpt_code' ? undefined : null}
              disabled={selectedField && selectedField !== 'dvpt_code'}
              filterOption={handleFilterSelect}
              placeholder={'Chọn đơn vị'}
              tokenSeparators={[',']}>
              {dataRiskUnitsByType.map(item => (
                <Option key={item.code} name={'dvpt_code'} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'nddpv_code'} label={'DNTV của VIMC'}>
            <Select
              allowClear
              showSearch
              onChange={value => handleSelectChange('nddpv_code', value)}
              value={selectedField === 'nddpv_code' ? undefined : null}
              disabled={selectedField && selectedField !== 'nddpv_code'}
              filterOption={handleFilterSelect}
              placeholder={'Chọn đơn vị'}
              tokenSeparators={[',']}>
              {dataRiskUnitsByTypeNDDPV.map(item => (
                <Option key={item.code} name={'nddpv_code'} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', gap: '70px' }}>
            <Form.Item
              name="report_submit_date"
              label="Ngày nộp báo cáo"
              style={{ width: '300px' }}
              rules={[
                { required: true, message: 'Vui lòng nhập ngày nộp báo cáo' },
              ]}>
              <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="dateRange"
              label="Kỳ báo cáo"
              style={{ width: '400px' }}
              rules={[{ required: true, message: 'Vui lòng nhập Kỳ báo cáo' }]}>
              <RangePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
            </Form.Item>
          </div>
          {/* <Form.Item
            name={'risk_unit_code'}
            label={'Đơn vị:'}
            // rules={[{ required: true, message: 'Vui lòng chọn đơn vị!' }]}
          >
            <Select
              allowClear
              showSearch
              filterOption={handleFilterSelect}
              tokenSeparators={[',']}
              mode="multiple"
              placeholder={'Đơn vị'}>
              {riskUnits?.map(unit => (
                <Option
                  key={unit.code}
                  name={'risk_unit_code'}
                  value={unit.code}>
                  {unit.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={'risk_dependent_code'}
            label={'Đơn vị phụ thuộc:'}
            // rules={[{ required: true, message: 'Vui lòng chọn đơn vị phụ thuộc!' }]}
          >
            <Select
              allowClear
              showSearch
              filterOption={handleFilterSelect}
              tokenSeparators={[',']}
              mode="multiple"
              placeholder={'Đơn vị phụ thuộc'}>
              {Array.isArray(riskUnitsV2) &&
                riskUnitsV2?.map(unit => (
                  <Option
                    key={unit.code}
                    name={'risk_dependent_code'}
                    value={unit.code}>
                    {unit.name}
                  </Option>
                ))}
            </Select>
          </Form.Item> */}
          <p style={{ marginTop: 16 }}>
            1. Báo cáo kết quả thực hiện công tác Quản trị rủi ro: (Áp dụng các
            nguyên tắc QTRR một cách có hệ thống trong tất cả các hoạt động của
            Văn phòng Tổng công ty/Ban chuyên môn/TTCNTT. Tuân thủ Chính sách,
            Quy trình quản lý rủi ro đã được phê duyệt và các quy định pháp luật
            khác)
          </p>
          <p>
            BIỆN PHÁP XỬ LÝ/TÌNH HÌNH KHẮC PHỤC/GIẢI PHÁP PHÒNG NGỪA/KIẾN NGHỊ
          </p>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={SECTION1_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={editReport?.section1}
            />
          )}
          <p style={{ marginTop: 16 }}>
            2. Các rủi ro xảy ra trong kỳ báo cáo đã được xử lý{' '}
            <Button
              style={{ marginTop: 8, marginLeft: 8, color: blue }}
              onClick={() => {
                setIsVisibleRuiRoNhanDienTemplate(true)
                setSection('section2')
              }}>
              Chọn
            </Button>
          </p>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={SECTION2_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={editReport?.section2}
              // onChange={content => handleEditorChange('section2', content)}
            />
          )}
          <p style={{ marginTop: 16 }}>
            3. Các rủi ro xảy ra trong kỳ báo cáo chưa được xử lý{' '}
            <Button
              style={{ marginTop: 8, marginLeft: 8, color: blue }}
              onClick={() => {
                setIsVisibleRuiRoNhanDienTemplate(true)
                setSection('section3')
              }}>
              Chọn
            </Button>
          </p>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={SECTION3_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={editReport?.section3}
              // onChange={content => handleEditorChange('section3', content)}
            />
          )}
          <p style={{ marginTop: 16 }}>
            4. Nội dung phát hiện trong kỳ báo cáo: Có phát sinh rủi ro so với
            Nhận diện rủi ro đầu năm
          </p>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={SECTION4_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={editReport?.section2}
            />
          )}
          <div style={{ marginTop: 16 }}></div>
          <Form.Item name="chuc_vu" label="Chức vụ">
            <Select
              placeholder="Chọn chức vụ"
              allowClear
              showSearch
              style={{ width: '100%' }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {positions?.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'truong_ban'}>
            <TreeSelect
              treeData={treeData_BP}
              style={{ width: '100%' }}
              showArrow
              allowClear
              showSearch
              treeNodeFilterProp={'title'}
              filterTreeNode
              value={selectedValue}
              onChange={handleTreeSelectChange}
              treeDefaultExpandAll
              multiple={false}
              placeholder={'Chọn người'}
            />
          </Form.Item>

          <Form.Item name="truong_ban__signed" valuePropName="checked">
            <Space direction="vertical">
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled={isCheckboxDisabled}>
                Xác nhận ký
              </Checkbox>
            </Space>
          </Form.Item>
          <FormButtonGroup>
            <Button type={'danger'} ghost onClick={handleCancelAdd}>
              Huỷ bỏ
            </Button>
            <Space size={'middle'}>
              <Button onClick={onReset}>Nhập lại</Button>
              <Button type={'primary'} htmlType={'submit'}>
                Tạo mới
              </Button>
            </Space>
          </FormButtonGroup>
        </Form>
      </Modal>
      <RuiRoNhanDienTemplate
        isVisible={isVisibleRuiRoNhanDienTemplate}
        handleCancelModal={() => {
          setIsVisibleRuiRoNhanDienTemplate(false)
        }}
        riskStore={riskStore}
        setRuiRoNhanDien={setRuiRoNhanDien}
        multiple={true}
        section={section}
        ruiRoNhanDien={ruiRoNhanDien}
        setGetData={setGetData}
      />
    </>
  )
}

ReportCreateModal.propTypes = {}

export default inject(
  'reportStore',
  'riskStore',
  'loadingAnimationStore',
  'companyStore',
  'positionStore',
  'roleStore',
  'departmentStore',
  'selectPeopleStore',
  'approvalTemplateStore',
  'selectPeopleStore'
)(observer(ReportCreateModal))
