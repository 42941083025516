import { action, observable } from 'mobx'
import { WorkRequest } from '../requests/WorkRequest'
import { ISO_DATE_FORMAT, WORK_STATUS } from '../constants'
import * as moment from 'moment'
import { UserGroupRequest } from '../requests/UserGroupRequest'
import { clone } from 'lodash'
import ManagerInventoryRequest from '../requests/ManagerInventoryRequest'

class managerInventoryStore {
  @observable managerInventoryListLevels = []
  @observable managerInventoryList = []
  @observable managerInventoryLists = []

  @observable managerInventoryDetail = {
    code: null,
    name: null,
    description: null,
    approval_levels: [],
    template_type: null,
  }

  @observable pageIndex = 0
  @observable pageSize = 10
  @observable totalCountInvent = 0

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }

  @action clearFilter = () => {
    this.pageIndex = 0
    this.pageSize = 10
    this.totalCountInvent = 0
  }

  @action getManagerInventorytList = () => {
    return new Promise((resolve, reject) => {
      ManagerInventoryRequest.getManagerInventoryList()
        .then(response => {
          this.managerInventoryList = response.data

          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getManagerInventoryLists = param => {
    return new Promise((resolve, reject) => {
      ManagerInventoryRequest.getManagerInventoryLists(
        `page=${this.pageIndex}&size=${this.pageSize}`
      )
        .then(response => {
          this.managerInventoryLists = response.data.data
          this.totalCountInvent = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getManagerInventoryDetail = code => {
    return new Promise((resolve, reject) => {
      ManagerInventoryRequest.getManagerInventoryDetail(code)
        .then(response => {
          this.managerInventoryDetail = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createManagerInventory = payload => {
    return new Promise((resolve, reject) => {
      ManagerInventoryRequest.createManagerInventory(payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action updateManagerInventory = (code, payload) => {
    return new Promise((resolve, reject) => {
      ManagerInventoryRequest.updateManagerInventory(code, payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action delete = code => {
    return ManagerInventoryRequest.deleteManagerInventory(code)
  }

  @action clearManagerInventoryDetail = () => {
    this.approvalTemplateDetail = {
      code: null,
      name: null,
      description: null,
      approval_levels: [],
    }
  }

  @action setManagerInventoryListLevels = payload => {
    this.managerInventoryListLevels = payload
  }

  @action clearManagerInventoryListLevels = () => {
    this.managerInventoryListLevels = []
  }
}

export default new managerInventoryStore()
