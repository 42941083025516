import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Button, Form, Switch, message } from 'antd'
import DashboardLayout from '../../layouts/DashboardLayout'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { FormButtonManagement } from './ReportPageStyled'

const ReportManagement = props => {
  const { documentAccessibilityStore, loadingAnimationStore } = props

  const [form] = Form.useForm()
  const [docAccess, setDocAccess] = useState(null)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    const fetchDocumentAccessibilities = async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        const response = await documentAccessibilityStore.getDocumentAccessibilityByTypeAndAccess(
          'RISK_REPORT',
          'REPORT'
        )
        if (response.data) {
          setDocAccess(response.data)
          const status = response.data[0]?.status ? true : false
          setChecked(status)
          form.setFieldsValue({ status })
        }
      } catch (error) {
        console.log(error)
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    }

    fetchDocumentAccessibilities()
  }, [documentAccessibilityStore, loadingAnimationStore, form])

  const onChange = checked => {
    setChecked(checked)
    form.setFieldsValue({ status: checked })
  }

  const onFinish = async values => {
    try {
      await documentAccessibilityStore.updateDocumentAccessibilitiesV3({
        type: 'RISK_REPORT',
        assignee_code: 'admin_vpdt',
        status: values.status,
      })
      message.success('Cập nhật quyền thao tác thành công!')
    } catch (error) {
      message.error(error.vi || 'An error occurred')
      console.log(error)
    }
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản lý phiếu kiểm soát rủi ro | Phiếu kiểm soát rủi ro</title>
      </Helmet>
      <PageTitle location={props.location} />
      <ContentBlockWrapper>
        <Form
          style={{ paddingLeft: '16px' }}
          onFinish={onFinish}
          scrollToFirstError={true}
          name={'document-accessibilities'}
          layout={'vertical'}
          form={form}>
          <p>
            Quyền được cấp chỉ có tác dụng với dữ liệu tính từ thời điểm cấu
            hình được áp dụng trở về sau
          </p>
          <Form.Item
            name="status"
            valuePropName="checked"
            initialValue={checked}>
            {' '}
            {/* Initial value should be updated correctly */}
            <p style={{ display: 'inline-block', margin: '15px 8px 25px 0' }}>
              Cấp quyền tạo báo cáo:
            </p>
            <Switch
              onChange={onChange}
              checked={checked}
              checkedChildren=""
              unCheckedChildren=""
            />
          </Form.Item>

          <FormButtonManagement>
            <Button type={'primary'} htmlType={'submit'}>
              Lưu
            </Button>
          </FormButtonManagement>
        </Form>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject(
  'documentAccessibilityStore',
  'loadingAnimationStore'
)(observer(ReportManagement))
