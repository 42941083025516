import { action } from 'mobx'
import RiskAriseRequest from '../requests/RiskAriseRequest'

class riskAriseStore {
  @action getRiskAriseList = params => {
    return new Promise((resolve, reject) => {
      RiskAriseRequest.getRiskAriseList(params)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getRiskAriseTypes = () => {
    return RiskAriseRequest.getRiskAriseTypes()
  }
  @action getRiskAriseDetail = code => {
    return RiskAriseRequest.getRiskAriseDetail(code)
  }

  @action createRiskArise = payload => {
    return RiskAriseRequest.createRiskArise(payload)
  }

  @action updateRiskArise = (code, payload) => {
    return RiskAriseRequest.updateRiskArise(code, payload)
  }

  @action delete = id => {
    return RiskAriseRequest.delete(id)
  }

  @action export = filter => {
    return RiskAriseRequest.export(filter)
  }
}

export default new riskAriseStore()
