import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Modal, Row, Select, Space } from 'antd'
import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import RichEditor from '../../../components/RichEditor/RichEditor'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent'
import commonStore from '../../../stores/commonStore'
import validator from '../../../validator'

const { TextArea } = Input

const RiskTemplateCreateModal = ({
  showCreateModal,
  handleCancelCreateModal,
  type,
  riskTemplateStore,
  loadRiskTemplateList,
  handleCancelEditModal,
  risk,
  setEditRisk,
}) => {
  const { Option } = Select
  const { createRiskTemplate, updateRiskTemplate } = riskTemplateStore
  const [form] = Form.useForm()
  const CAUSE_REF = useRef()
  const MEASURE_REF = useRef()
  const [isRenderRichText, setIsRenderRichText] = useState(false)
  useEffect(() => {
    if (!risk) {
      return
    }
    form.setFieldsValue({
      rui_ro_nhan_dien: risk.rui_ro_nhan_dien,
      linh_vuc_hoat_dong: risk?.linh_vuc_hoat_dong
        ? risk?.linh_vuc_hoat_dong
        : null,
      type: risk.type,
    })
  }, [risk])

  const [buttonBlock, setButtonBlock] = useState(false)

  useEffect(() => {
    if (showCreateModal) {
      setTimeout(() => {
        setIsRenderRichText(true)
      }, 400)
    } else {
      setIsRenderRichText(false)
    }
  }, [showCreateModal])
  const handleCreateRisk = async value => {
    setButtonBlock(true)
    const submitData = {
      linh_vuc_hoat_dong: value.linh_vuc_hoat_dong,
      rui_ro_nhan_dien: value.rui_ro_nhan_dien,
      nguyen_nhan: CAUSE_REF.current.editor.getData(),
      bien_phap_phong_ngua_giam_thieu: MEASURE_REF.current.editor.getData(),
      type: value.type,
    }
    try {
      risk
        ? await updateRiskTemplate(risk.id, submitData)
        : await createRiskTemplate(submitData)
      await loadRiskTemplateList()
      risk
        ? message.success('Cập nhật thành công')
        : message.success('Tạo mới thành công')
      handleCancelCreateModal()
      setEditRisk(null)
    } catch {
      message.error('Đã có lỗi xảy ra')
    }
  }
  const { riskTemplateTypes } = riskTemplateStore

  return (
    <Modal
      visible={showCreateModal}
      footer={null}
      destroyOnClose={true}
      afterClose={() => {
        setButtonBlock(false)
        setIsRenderRichText(false)
      }}
      width={600}
      onCancel={risk ? handleCancelEditModal : handleCancelCreateModal}
      title={
        <>
          <ExclamationCircleOutlined
            style={{
              color: commonStore.appTheme.solidColor,
              marginRight: 10,
            }}
          />
          {risk ? 'Cập nhật rủi ro nhận diện' : 'Tạo mới rủi ro nhận diện'}
        </>
      }>
      <Form
        layout={'vertical'}
        onFinish={handleCreateRisk}
        preserve={false}
        form={form}>
        <Form.Item
          label={'Loại'}
          name={'type'}
          rules={[{ required: true, message: 'Vui lòng chọn !' }]}>
          <Select
            style={{ minWidth: '250px' }}
            placeholder={'Chọn loại'}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={risk ? true : false}>
            {riskTemplateTypes?.length > 0 &&
              riskTemplateTypes.map(type => (
                <Option key={type.code} value={type.code}>
                  {type.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={'Lĩnh vực hoạt động'}
          name={'linh_vuc_hoat_dong'}
          rules={[
            { required: true, message: 'Vui lòng nhập !' },
            { validator: validator.validateInputString },
          ]}>
          <Input placeholder={'Nhập lĩnh vực hoạt động'} />
        </Form.Item>
        <Form.Item
          label={'Rủi ro nhận diện'}
          name={'rui_ro_nhan_dien'}
          rules={[
            { required: true, message: 'Vui lòng nhập !' },
            { validator: validator.validateInputString },
          ]}>
          <TextArea rows={3} placeholder={'Nhập rủi ro nhận diện'} />
        </Form.Item>
        <Form.Item label={'Nguyên nhân'} name={'nguyen_nhan'}>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={CAUSE_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={risk?.nguyen_nhan}
            />
          )}
        </Form.Item>
        <Form.Item
          label={'Biện pháp phòng ngừa giảm thiểu'}
          name={'bien_phap_phong_ngua_giam_thieu'}>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={MEASURE_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={risk?.bien_phap_phong_ngua_giam_thieu}
            />
          )}
        </Form.Item>
        <Row justify={'end'}>
          <Space>
            <Button
              onClick={risk ? handleCancelEditModal : handleCancelCreateModal}>
              Hủy
            </Button>
            <Button
              disabled={buttonBlock ? true : false}
              type={'primary'}
              htmlType={'submit'}>
              {risk ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default RiskTemplateCreateModal
