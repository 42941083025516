import { Checkbox, Col, DatePicker, Input, Row, Select, Space } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import EmptyContent from '../../../components/EmptyContent'
import { DATE_FORMAT_LIST, ISO_DATE_FORMAT } from '../../../constants'
import utils from '../../../utils'

const { RangePicker } = DatePicker
const { Option } = Select

const AdvanceFilterBlock = props => {
  const { authorityStore, handleChangeParams, queryParamsIncoming } = props

  const { authorityIssuedsList } = authorityStore
  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)

  const [valueAuthorityIssued, setValueAuthorityIssued] = useState([])

  useEffect(() => {
    if (
      queryStringParse.authority_name &&
      queryStringParse.authority_name?.trim() !== ''
    ) {
      setValueAuthorityIssued([queryStringParse.authority_name])
    }
  }, [queryStringParse.authority_name])

  const onSearchTitle = title => {
    handleChangeParams({ title: title ? title.trim() : null })
  }
  // const onSearchDocumentNumber = document_number => {
  //   internalDocumentStore.setQueryParamsIncoming({
  //     ...queryParamsIncoming,
  //     page: 0,
  //     document_number: document_number && document_number.trim(),
  //   })
  //   const queryStringStringify = queryString.stringify(
  //     {
  //       ...queryStringParse,
  //       page: 0,
  //       size: 10,
  //       document_number: document_number ? document_number.trim() : null,
  //     },
  //     {
  //       skipNull: true,
  //     }
  //   )
  //   history.replace(
  //     `/internal-document/incoming-document?${queryStringStringify}`
  //   )
  // }
  const onChangeIncomingDate = value => {
    handleChangeParams({
      from_incoming_date: value
        ? moment(value[0]).format(ISO_DATE_FORMAT)
        : null,
      to_incoming_date: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    })
  }

  const onChangeDateIssued = value => {
    handleChangeParams({
      from_date_issued: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_date_issued: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    })
  }
  const onSearchSigner = signer => {
    handleChangeParams({
      signer: signer ? signer.trim() : null,
    })
  }

  const onSearchAuthorityIssued = value => {
    setValueAuthorityIssued(value.length > 0 ? [value[value.length - 1]] : [])
    handleChangeParams({
      authority_name: value.length > 0 ? value[value.length - 1] : null,
    })
  }

  const handleChangeUnread = event => {
    let only_unread = event?.target?.checked ? true : false
    handleChangeParams({ only_unread: only_unread })
  }

  const filterSelectOption = (input, option) => {
    return (
      utils
        .nonAccentVietnamese(option.name)
        .indexOf(utils.nonAccentVietnamese(input)) >= 0
    )
  }

  return (
    <div style={{ marginBottom: 16 }}>
      <Row type={'flex'} gutter={15}>
        <Col xs={24} sm={12} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Trích yếu / Số hiệu / Số đến</span>
            <Input.Search
              onSearch={value => onSearchTitle(value)}
              placeholder={'Nhập từ khóa tìm kiếm'}
              allowClear
              defaultValue={queryStringParse.title}
            />
          </Space>
        </Col>
        {/*<Col xs={24} sm={12} md={8}>*/}
        {/*  <Space direction='vertical' style={{ width: '100%', marginBottom: 10 }}>*/}
        {/*    <span>Số hiệu</span>*/}
        {/*    <Input.Search*/}
        {/*      placeholder={'Nhập số hiệu văn bản'}*/}
        {/*      allowClear*/}
        {/*      defaultValue={queryStringParse.document_number}*/}
        {/*      onSearch={value => onSearchDocumentNumber(value)}*/}
        {/*    />*/}
        {/*  </Space>*/}
        {/*</Col>*/}
        <Col xs={24} sm={12} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Ngày đến</span>
            <RangePicker
              onChange={onChangeIncomingDate}
              defaultValue={
                queryStringParse.from_incoming_date &&
                queryStringParse.to_incoming_date
                  ? [
                      moment(queryStringParse.from_incoming_date),
                      moment(queryStringParse.to_incoming_date),
                    ]
                  : null
              }
              placeholder={['Từ ngày', 'Đến ngày']}
              format={DATE_FORMAT_LIST}
              style={{ width: '100%' }}
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Ngày ban hành</span>
            <RangePicker
              onChange={onChangeDateIssued}
              defaultValue={
                queryStringParse.from_date_issued &&
                queryStringParse.to_date_issued
                  ? [
                      moment(queryStringParse.from_date_issued),
                      moment(queryStringParse.to_date_issued),
                    ]
                  : null
              }
              placeholder={['Từ ngày', 'Đến ngày']}
              format={DATE_FORMAT_LIST}
              style={{ width: '100%' }}
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Người ký</span>
            <Input.Search
              placeholder={'Người ký'}
              onSearch={value => onSearchSigner(value)}
              defaultValue={queryStringParse.signer}
              allowClear
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Cơ quan ban hành</span>
            <Select
              style={{ width: '100%' }}
              mode="tags"
              value={valueAuthorityIssued}
              onInputKeyDown={() => setValueAuthorityIssued([])}
              onChange={onSearchAuthorityIssued}
              filterOption={filterSelectOption}
              allowClear
              showSearch
              showArrow
              optionFilterProp={'name'}
              notFoundContent={<EmptyContent />}
              placeholder={'Chọn hoặc nhập cơ quan ban hành'}>
              {authorityIssuedsList.map(authorityIssued => (
                <Option
                  name={authorityIssued.name}
                  value={authorityIssued.name}
                  key={authorityIssued.id}>
                  {authorityIssued.name}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Trạng thái văn bản</span>
            <Checkbox
              // checked={queryParamsIncoming.only_unread}
              checked={queryParamsIncoming?.only_unread === 'true' || queryParamsIncoming?.only_unread === true}
              onChange={handleChangeUnread}>
              Hiển thị văn bản chưa đọc
            </Checkbox>
          </Space>
        </Col>
      </Row>
    </div>
  )
}

AdvanceFilterBlock.propTypes = {}

export default inject('authorityStore')(observer(AdvanceFilterBlock))
