import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { systemName } from '../../config'
import utils from '../../utils'
import './RiskActionDetails.css'
import { RiskActionStyle } from './RiskActionDetailsStyled'
import { useLocation } from 'react-router-dom/'

const displayResult = value => {
  return value === '1'
    ? 'a/ Rủi ro ở mức không cần xử lý (theo dõi)'
    : value === '2'
    ? 'b/ Rủi ro đã được xử lý'
    : value === '3'
    ? 'c/ Rủi ro đã xảy ra chưa được xử lý'
    : ''
}

const RiskActionDetails4 = ({ data, actionStore }) => {
  const labelBold = { fontWeight: 600 }
  const textValue = { fontStyle: 'italic', fontSize: '14px', marginLeft: 8 }

  const location = useLocation()
  const type = location?.search?.split('=')[1]

  const styleTable = {
    width: '100%',
    backgroundColor: 'white',
    margin: '16px 0 50px',
  }
  const [dataPtt4, setDataPtt4] = useState(null)
  useEffect(() => {
    if (data.length > 0) {
      setDataPtt4(data[0])
    }
  }, [data])
  const nguoi_dang_y_kien = dataPtt4?.nguoi_dang_y_kien
  const cac_y_kien_lien_quan = dataPtt4?.cac_y_kien_lien_quan
  const ban_pc_qtrr_ban_kt_ktnb_vimc = dataPtt4?.ban_pc_qtrr_ban_kt_ktnb_vimc
  const ban_pc_qtrr_ban_kt_ktnb_vimc_ptd =
    dataPtt4?.ban_pc_qtrr_ban_kt_ktnb_vimc?.length > 0
      ? dataPtt4?.ban_pc_qtrr_ban_kt_ktnb_vimc?.[0]
      : null

  const ban_dieu_hanh_hdqt_vimc = dataPtt4?.ban_dieu_hanh_hdqt_vimc
  const ban_dieu_hanh_hdqt_vimc_ptd =
    dataPtt4?.ban_dieu_hanh_hdqt_vimc?.length > 0
      ? dataPtt4?.ban_dieu_hanh_hdqt_vimc?.[0]
      : null

  const don_vi_thuc_hien_xu_ly_rui_ro =
    actionStore?.dataActions?.tier1.length > 0
      ? actionStore?.dataActions?.tier1[0]?.II_xu_ly_rui_ro
          .don_vi_thuc_hien_xu_ly_rui_ro
      : null

  const don_vi_thuc_hien_xu_ly_rui_ro__signed =
    actionStore?.dataActions?.tier1.length > 0
      ? actionStore?.dataActions?.tier1[0]?.II_xu_ly_rui_ro
          .don_vi_thuc_hien_xu_ly_rui_ro__signed
      : null

  const ban_pc_qtrr_ban_kt_ktnb_vimc_signed =
    dataPtt4?.ban_pc_qtrr_ban_kt_ktnb_vimc_signed

  const ban_dieu_hanh_hdqt_vimc_signed =
    dataPtt4?.ban_dieu_hanh_hdqt_vimc_signed
  const ket_qua = dataPtt4?.ket_qua
  return (
    <RiskActionStyle>
      {type === 'DVPT' ? (
        <h6 style={{ marginTop: 16 }}>VI. KẾT QUẢ KIỂM SOÁT RỦI RO</h6>
      ) : (
        <h6 style={{ marginTop: 16 }}>V. KẾT QUẢ KIỂM SOÁT RỦI RO</h6>
      )}
      <h6 style={{ display: 'inline-block' }}>1. Kết quả:&nbsp;</h6>
      <span style={{ ...textValue, ...labelBold }}>
        {displayResult(ket_qua)}
      </span>
      <h6 style={{ marginTop: 15 }}>2. Các ý kiến liên quan</h6>

      {/* <ActionLabel label="Ý KIẾN:&nbsp;" /> */}
      <div>
        <span>
          {nguoi_dang_y_kien?.length > 0 ? 'Ý kiến của: ' : 'Ý kiến: '}
        </span>
        {nguoi_dang_y_kien?.map((item, index, array) => (
          <span key={index}>
            {utils.getNameInCapitalize(item?.label)}
            {index !== array.length - 1 && ', '}
          </span>
        ))}
      </div>

      <div style={{ ...textValue, ...labelBold }}>
        {/* <RichText htmlText={data[0]?.cac_y_kien_lien_quan || ''}></RichText> */}
        <div
          style={{ ...textValue, ...labelBold }}
          dangerouslySetInnerHTML={{
            __html: `${cac_y_kien_lien_quan || ''}`,
          }}></div>
      </div>
      <>
        <table style={styleTable}>
          <tbody>
            <tr style={{ backgroundColor: 'white' }}>
              <td
                style={{
                  border: 'none',
                  margin: '0 20px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div style={{ margin: '0 20px' }}>
                  <b>ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO</b>
                </div>
              </td>
              <td
                style={{
                  border: 'none',
                  margin: '0 20px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div style={{ margin: '0 20px' }}>
                  {Array.isArray(ban_pc_qtrr_ban_kt_ktnb_vimc) &&
                  ban_pc_qtrr_ban_kt_ktnb_vimc?.some(
                    item => item.department_code === 'BPC&QTRR'
                  ) ? (
                    <b>BAN PC&QTRR</b>
                  ) : Array.isArray(ban_pc_qtrr_ban_kt_ktnb_vimc) &&
                    ban_pc_qtrr_ban_kt_ktnb_vimc?.some(
                      item => item.department_code === 'BKTKTNB'
                    ) ? (
                    <b>BAN KTNB {systemName}</b>
                  ) : (
                    <b>BAN PC&QTRR/BAN KTNB {systemName}</b>
                  )}
                </div>
              </td>
              <td
                style={{
                  border: 'none',
                  margin: '0 20px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div style={{ margin: '0 20px' }}>
                  {Array.isArray(ban_dieu_hanh_hdqt_vimc) &&
                  ban_dieu_hanh_hdqt_vimc?.some(
                    item => item.department_code === 'BDH'
                  ) ? (
                    <b>BAN ĐIỀU HÀNH</b>
                  ) : Array.isArray(ban_dieu_hanh_hdqt_vimc) &&
                    ban_dieu_hanh_hdqt_vimc?.some(
                      item => item.department_code === 'HDQT'
                    ) ? (
                    <b>BAN HĐQT {systemName}</b>
                  ) : (
                    <b>BAN ĐIỀU HÀNH/HĐQT {systemName}</b>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: 'none',
                  margin: '0 20px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div className="textValue">
                  {don_vi_thuc_hien_xu_ly_rui_ro
                    ?.filter(el => el.value.startsWith('CPN'))
                    .map(el => (
                      <div>{utils.getNameInCapitalize(el.label)}</div>
                    ))}
                  {don_vi_thuc_hien_xu_ly_rui_ro
                    ?.filter(el => !el.value.startsWith('CPN'))
                    .map(el => {
                      return (
                        <>
                          {el.value.slice(0, 3) === 'USR' ? (
                            <>{don_vi_thuc_hien_xu_ly_rui_ro__signed}</>
                          ) : null}
                          <div
                            style={{
                              marginTop: don_vi_thuc_hien_xu_ly_rui_ro__signed
                                ? '5px'
                                : el.value.slice(0, 3) === 'USR'
                                ? '65px'
                                : '0',
                            }}>
                            {utils.getNameInCapitalize(el.label)}
                          </div>
                        </>
                      )
                    })}
                  {/*
                      tạm thời comment
                      {data[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro?.map(
                        item => (
                          <>
                            {departmentList
                              .filter(el => el.code === item.value)
                              ?.map(unit => (
                                <div style={{ marginTop: '5px' }}>
                                  {unit.name}
                                </div>
                              ))}
                          </>
                        )
                      )}
                      {
                        actionStore?.dataActions?.tier1[0]?.II_xu_ly_rui_ro
                          .don_vi_thuc_hien_xu_ly_rui_ro__signed
                      }

                      {actionStore?.dataActions?.tier1[0]?.II_xu_ly_rui_ro.don_vi_thuc_hien_xu_ly_rui_ro
                        ?.filter(
                          el =>
                            !el.value.startsWith('CPN') &&
                            !codeDepartmentList.includes(el.value)
                        )
                        .map(el => (
                          <div
                            style={{
                              marginTop: actionStore?.dataActions?.tier1[0]
                                ?.II_xu_ly_rui_ro
                                .don_vi_thuc_hien_xu_ly_rui_ro__signed
                                ? '5px'
                                : '65px',
                            }}>
                            {el.label}
                          </div>
                        ))} */}
                </div>
              </td>
              <td
                style={{
                  border: 'none',
                  margin: '0 20px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div className="textValue">
                  <div>
                    {ban_pc_qtrr_ban_kt_ktnb_vimc_signed}
                    <div
                      style={{
                        fontSize: '13px',
                        marginTop: ban_pc_qtrr_ban_kt_ktnb_vimc_signed
                          ? '5px'
                          : '60px',
                      }}>
                      {ban_pc_qtrr_ban_kt_ktnb_vimc_ptd?.position_name}
                    </div>

                    <div>
                      {utils.getNameInCapitalize(
                        ban_pc_qtrr_ban_kt_ktnb_vimc_ptd?.full_name
                      )}
                    </div>
                  </div>
                </div>
              </td>
              <td
                style={{
                  border: 'none',
                  margin: '0 20px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div className="textValue">
                  <div>
                    {ban_dieu_hanh_hdqt_vimc_signed}
                    <div
                      style={{
                        fontSize: '13px',
                        marginTop: ban_dieu_hanh_hdqt_vimc_signed
                          ? '5px'
                          : '60px',
                      }}>
                      {ban_dieu_hanh_hdqt_vimc_ptd?.position_name}
                    </div>

                    <div>
                      {utils.getNameInCapitalize(
                        ban_dieu_hanh_hdqt_vimc_ptd?.full_name
                      )}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </RiskActionStyle>
  )
}

export default inject('actionStore')(observer(RiskActionDetails4))
