import { Divider } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { systemName } from '../../config'
import utils from '../../utils'
import RichText from '../RichEditor/RichText'
import './RiskActionDetails.css'
import { RiskActionStyle } from './RiskActionDetailsStyled'
import { toJS } from 'mobx'

const labelBold = { fontWeight: 600 }
const textValue = { fontStyle: 'italic', fontSize: '14px', marginLeft: 8 }

const ActionLabel = ({
  label = '',
  text = '',
  title = '',
  labelStyle = {},
  fontSizeStyle = { fontSize: '14px' },
  marginStyle = {},
}) => {
  return (
    <p style={{ marginLeft: labelStyle.marginLeft || '6px !important' }}>
      <span style={marginStyle}>{label}&nbsp;</span>
      <span style={{ ...textValue, ...labelBold, ...fontSizeStyle }}>
        {text}
      </span>
      <span
        style={{
          ...textValue,
          ...labelBold,
          ...fontSizeStyle,
          display: 'flex',
          marginRight: '5px',
        }}>
        {title}
      </span>
    </p>
  )
}
const ActionLabels = ({ label = '', title = '', labelStyle = {} }) => {
  return (
    <div
      style={{
        marginLeft: labelStyle.marginLeft || '6px !important',
        maxHeight: '25px',
        marginTop: 12,
        marginBottom: 12,
      }}>
      <span>{label}&nbsp;</span>
      <span
        style={{
          ...textValue,
          ...labelBold,
          display: 'flex',
          marginLeft: '2px',
        }}>
        {title}
      </span>
    </div>
  )
}

const TableHasCheckbox = ({ title, checked, styleCustom }) => {
  return (
    <table style={{ margin: '0 ' }}>
      <tbody>
        <tr style={{ backgroundColor: 'white', margin: '0 ' }}>
          <td
            style={{
              border: 'none',
              margin: '0',
              backgroundColor: 'white',
              textAlign: 'center',
              fontStyle: styleCustom ? 'italic' : 'normal',
              fontWeight: styleCustom ? 600 : 400,
            }}>
            <div>{title}</div>
          </td>
          <td
            style={{
              border: 'none',
              margin: '0 ',
              backgroundColor: 'white',
              textAlign: 'center',
            }}>
            {checked ? (
              <img
                id="logo"
                style={{ width: '20px', height: '16px' }}
                src={`${window.location.protocol}//${window.location.host}/assets/icons/checkedbox.PNG`}
              />
            ) : (
              <img
                id="logo"
                style={{ width: '20px', height: '16px' }}
                src={`${window.location.protocol}//${window.location.host}/assets/icons/uncheckedbox.PNG`}
              />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const displayRiskAssessment = value => {
  return value === '1'
    ? '1 -> 5 - Nguy cơ rủi ro thấp (Được phép thực hiện)'
    : value === '2'
    ? ' 6 -> 15 - Nguy cơ rủi ro trung bình (Hoạt động chỉ được phép tiến hành với sự quản lý, kiểm soát thích hợp)'
    : value === '3'
    ? ' 16 -> 25 - Nguy cơ rủi ro cao (Hoạt động không được phép tiến hành. Cần phải thiết lập các biện pháp kiểm soát chặt chẽ hơn để giảm thiểu rủi ro)'
    : ''
}

const renderNameLanhDaoBanT2 = names => {
  if (names == null || names.length == 0) return
  if (names.length == 1) return names[0].label
  return names.map(name => name.label).join(', ')
}

const displayRiskAssessmentCombined = value => {
  if (typeof value === 'number' || typeof value === 'string') {
    return displayRiskAssessment(value.toString())
  } else if (
    Array.isArray(value) &&
    value.length > 0 &&
    typeof value[0]?.value === 'number'
  ) {
    return displayRiskAssessment(value[0].value.toString())
  } else {
    return ''
  }
}
const RiskAssessmentItem = ({ label, data, textValue, labelBold }) => (
  <p>
    <span>{label}</span>
    <span style={{ ...textValue, ...labelBold }}>
      {data?.dong_y === 'Đồng ý'
        ? 'Đồng ý'
        : data?.dong_y === 'Không đồng ý'
        ? 'Không đồng ý'
        : ''}
    </span>{' '}
    <span style={{ ...textValue, ...labelBold }}>
      {data?.dieu_chinh_bo_sung}
    </span>
  </p>
)

const RiskAssessmentItem2 = ({ label, data, textValue, labelBold }) => (
  <p>
    <span>{label}</span>
    <span style={{ ...textValue, ...labelBold }}>{data}</span>
  </p>
)

const RiskActionDetails1 = ({
  data,
  type,
  isPartOfPdf = false,
  departmentStore,
  riskUnitsV2,
  departmentWithUserMasterList,
  BAN_CMNV_VIMC,
}) => {
  let dataPtt1_I = {}
  if (data != null && data?.length > 0) {
    dataPtt1_I = data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
  }

  let cong_viec_theo = dataPtt1_I?.cong_viec_linh_vuc_hoat_dong?.cong_viec_theo

  let noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren =
    dataPtt1_I?.cong_viec_linh_vuc_hoat_dong
      ?.noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren

  let noi_dung_cong_viec = dataPtt1_I?.cong_viec_thuc_hien?.noi_dung_cong_viec

  let nguoi_thuc_hien = dataPtt1_I?.cong_viec_thuc_hien?.nguoi_thuc_hien

  let thoi_gian_hoan_thanh =
    dataPtt1_I?.cong_viec_thuc_hien?.thoi_gian_hoan_thanh

  let nhan_dien_rui_ro =
    dataPtt1_I?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.nhan_dien_rui_ro

  let xay_dung_giai_phap_giam_thieu_phong_ngua =
    dataPtt1_I?.nhan_dien_rui_ro_va_danh_gia_rui_ro
      ?.xay_dung_giai_phap_giam_thieu_phong_ngua

  let nguoi_thuc_hien_sign = dataPtt1_I?.nguoi_thuc_hien_sign
  let lanh_dao_phong_dvpt = dataPtt1_I?.lanh_dao_phong_dvpt
  let lanh_dao_phong_dvpt__signed = dataPtt1_I?.lanh_dao_phong_dvpt__signed

  let nguoi_thuc_hien_signed = dataPtt1_I?.nguoi_thuc_hien_signed

  let nguoi_thuc_hien_sign_khac = dataPtt1_I?.nguoi_thuc_hien_sign_khac

  let nguoi_thuc_hien_dvpt_y_kien = dataPtt1_I?.nguoi_thuc_hien_dvpt_y_kien
  let lanh_dao_dvpt_y_kien = dataPtt1_I?.lanh_dao_dvpt_y_kien

  let nddpv_phu_trach_chung = dataPtt1_I?.nddpv_phu_trach_chung

  let nddpv_phu_trach_chung__signed = dataPtt1_I?.nddpv_phu_trach_chung__signed

  let lanh_dao_don_vi_phu_thuoc = dataPtt1_I?.lanh_dao_don_vi_phu_thuoc

  let lanh_dao_don_vi_phu_thuoc_cqvp =
    dataPtt1_I?.lanh_dao_don_vi_phu_thuoc_cqvp

  let nhanDienRuiRo =
    dataPtt1_I?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.nhan_dien_rui_ro
  let r = dataPtt1_I?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.r

  let dataPtt1_II = {}
  if (data != null && data?.length > 0) {
    dataPtt1_II = data[0]?.II_xu_ly_rui_ro
  }

  let loai_rui_ro_de_xuat =
    dataPtt1_II?.de_xuat_loai_rui_ro_can_xu_ly?.loai_rui_ro_de_xuat

  let loai_rui_ro_de_xuat_khac =
    dataPtt1_II?.de_xuat_loai_rui_ro_can_xu_ly?.loai_rui_ro_de_xuat_khac

  let bo_phan_de_xuat_khac =
    dataPtt1_II?.de_xuat_loai_rui_ro_can_xu_ly?.bo_phan_de_xuat_khac

  let bo_phan_de_xuat =
    dataPtt1_II?.de_xuat_loai_rui_ro_can_xu_ly?.bo_phan_de_xuat

  let bien_phap_xu_ly =
    dataPtt1_II?.de_xuat_loai_rui_ro_can_xu_ly?.bien_phap_xu_ly

  let nguoi_thuc_hien__de_xuat_khac =
    dataPtt1_II?.de_xuat_loai_rui_ro_can_xu_ly?.nguoi_thuc_hien__de_xuat_khac

  let nguoi_thuc_hien_II =
    dataPtt1_II?.de_xuat_loai_rui_ro_can_xu_ly?.nguoi_thuc_hien

  let don_vi_thuc_hien_xu_ly_rui_ro = dataPtt1_II?.don_vi_thuc_hien_xu_ly_rui_ro

  let don_vi_thuc_hien_xu_ly_rui_ro__signed =
    dataPtt1_II?.don_vi_thuc_hien_xu_ly_rui_ro__signed

  let don_vi_thuc_hien_xu_ly_rui_ro_khac =
    dataPtt1_II?.don_vi_thuc_hien_xu_ly_rui_ro_khac

  let pho_giam_doc_phu_trach = dataPtt1_II?.pho_giam_doc_phu_trach

  let pho_giam_doc_phu_trach__signed =
    dataPtt1_II?.pho_giam_doc_phu_trach__signed

  let nguoi_dai_dien_phan_von_phu_trach_chung =
    dataPtt1_II?.nguoi_dai_dien_phan_von_phu_trach_chung

  let nguoi_dai_dien_phan_von_phu_trach_chung__signed =
    dataPtt1_II?.nguoi_dai_dien_phan_von_phu_trach_chung__signed

  let bo_phan_quan_ly_rui_ro_cua_don_vi =
    dataPtt1_II?.bo_phan_quan_ly_rui_ro_cua_don_vi

  let lanh_dao_vimc = {}
  if (dataPtt1_II?.lanh_dao_vimc?.length > 0) {
    lanh_dao_vimc = dataPtt1_II?.lanh_dao_vimc[0]
  }

  let III_kiem_soat_rui_ro = {}

  if (data !== null && data.length > 0) {
    III_kiem_soat_rui_ro = data[0]?.III_kiem_soat_rui_ro
  }

  const doi_voi_cac_loai_rui_ro_da_nhan_dien =
    III_kiem_soat_rui_ro?.doi_voi_cac_loai_rui_ro_da_nhan_dien
  const doi_voi_danh_gia_muc_rui_ro =
    III_kiem_soat_rui_ro?.doi_voi_danh_gia_muc_rui_ro
  const loai_rui_ro_da_de_xuat_xu_ly =
    III_kiem_soat_rui_ro?.loai_rui_ro_da_de_xuat_xu_ly
  const danh_gia_bien_phap_xu_ly_da_de_xuat =
    III_kiem_soat_rui_ro?.danh_gia_bien_phap_xu_ly_da_de_xuat
  const thoi_gian_hoan_thanh_cong_viec_duoc_giao =
    III_kiem_soat_rui_ro?.thoi_gian_hoan_thanh_cong_viec_duoc_giao
  const bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc =
    III_kiem_soat_rui_ro?.bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc
  const y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc =
    III_kiem_soat_rui_ro?.y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc
  // const phong_ban_nddpv = III_kiem_soat_rui_ro?.phong_ban_nddpv
  const chuyen_vien_thuc_hien_nddpv_signed =
    III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv_signed
  const lanh_dao_ban_signed = III_kiem_soat_rui_ro?.lanh_dao_ban_signed
  const lanh_dao_phong_pcathh__signed =
    III_kiem_soat_rui_ro?.lanh_dao_phong_pcathh__signed
  const chuyen_vien_thuc_hien = III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien
  const lanh_dao_ban_nddpv = III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv
  const styleSigned = {
    marginTop: lanh_dao_ban_signed ? '5px' : '60px',
  }

  const nhanDienRuiRoArr = useMemo(() => {
    if (type !== 'NDDPV' && Array.isArray(r) && r?.length > 0) {
      // Sử dụng regex để tách thành mảng các đoạn văn bản

      const extractedStrings = nhanDienRuiRo?.match(/[^<>]+?(?=&nbsp;)/g)
      const cleanedStrings = extractedStrings?.map(str => str.trim())

      return cleanedStrings
    }
    return []
  }, [nhanDienRuiRo, type])

  const danhGiaRuiRoArr = useMemo(() => {
    if (
      type !== 'NDDPV' &&
      Array.isArray(r) &&
      r?.length > 0 &&
      Array.isArray(nhanDienRuiRoArr) &&
      nhanDienRuiRoArr?.length > 0
    ) {
      return r?.map(el => displayRiskAssessment(el?.value?.toString()))
    }
    return []
  }, [r, type])

  const lanh_dao_ban_nddpv2 = useMemo(() => {
    if (III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv2) {
      return III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv2?.map(item =>
        BAN_CMNV_VIMC?.users?.map(user => {
          if (user?.user_code === item) {
            return utils.getNameInCapitalize(user.name_uppercase)
          }
        })
      )
    } else {
      return []
    }
  }, [III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv2, BAN_CMNV_VIMC])

  const renderData = useMemo(() => {
    if (
      type !== 'NDDPV' &&
      Array.isArray(nhanDienRuiRoArr) &&
      nhanDienRuiRoArr?.length > 0
    ) {
      return danhGiaRuiRoArr
        .map((result, index) => {
          const nhanDienRuiRo = nhanDienRuiRoArr[index]
          if (
            nhanDienRuiRo !== undefined &&
            nhanDienRuiRo !== null &&
            nhanDienRuiRo !== '' &&
            result !== undefined &&
            result !== null &&
            result !== ''
          ) {
            return `${nhanDienRuiRo} : ${result}`
          }
          return null
        })
        .filter(item => item !== null)
    }
    return []
  }, [danhGiaRuiRoArr, nhanDienRuiRoArr, type])

  const lanh_dao_ban = useMemo(() => {
    if (III_kiem_soat_rui_ro?.lanh_dao_ban) {
      Array.from(
        new Set(
          III_kiem_soat_rui_ro.lanh_dao_ban?.map(item => item?.department_name)
        )
      ).map(departmentName => departmentName)
    } else {
      return []
    }
  }, [III_kiem_soat_rui_ro?.lanh_dao_ban])

  const lanh_dao_ban_cqvp = useMemo(() => {
    if (III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp) {
      const filteredItems = III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp?.filter(
        el => !el.value.startsWith('CPN')
      )
      const usrItems = filteredItems
        ?.filter(item => item.value.startsWith('USR'))
        ?.map(item => utils.getNameInCapitalize(item.label))
      return usrItems
    } else {
      return []
    }
  }, [III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp])

  const lanh_dao_phong_pcathh = useMemo(() => {
    if (III_kiem_soat_rui_ro?.lanh_dao_phong_pcathh) {
      const filteredItems = III_kiem_soat_rui_ro?.lanh_dao_phong_pcathh?.filter(
        el => !el.value.startsWith('CPN')
      )
      const usrItems = filteredItems
        ?.filter(item => item.value.startsWith('USR'))
        ?.map(item => utils.getNameInCapitalize(item.label))
      return usrItems
    } else {
      return []
    }
  }, [III_kiem_soat_rui_ro?.lanh_dao_phong_pcathh])

  const chuyen_vien_thuc_hien_nddpv = useMemo(() => {
    if (III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv) {
      return III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv
        ?.map(item =>
          BAN_CMNV_VIMC?.users.map(user => {
            if (user.user_code === item) {
              return utils.getNameInCapitalize(user.name_uppercase)
            }
          })
        )
        .flat()
        .filter(Boolean)
    } else {
      return []
    }
  }, [III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv, BAN_CMNV_VIMC])

  const checkArr = Array.isArray(
    dataPtt1_II?.de_xuat_loai_rui_ro_can_xu_ly?.loai_rui_ro_de_xuat
  )
  const { departmentList } = departmentStore
  const labelBold = { fontWeight: 600 }
  const textValue = { fontStyle: 'italic', fontSize: '14px', marginLeft: 8 }
  const position = {
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translateX(-50%)',
  }

  const [codeDepartmentList, setCodeDepartmentList] = useState([])
  useEffect(() => {
    if (departmentWithUserMasterList) {
      const codes = departmentWithUserMasterList.map(dp => dp.code)
      setCodeDepartmentList(codes)
    }
  }, [departmentWithUserMasterList])
  const styleTable = isPartOfPdf
    ? {
        border: 'none',
        backgroundColor: 'white',
        margin: '16px 0 16px 20px',
      }
    : {
        margin: 0,
        width: '100%',
      }

  const webTableCellStylePDF = totalColumn => {
    return {
      width: `${100 / totalColumn}%`,
      textAlign: 'center',
      border: 'none',
      margin: '0 36px ',
      backgroundColor: 'white',
      color: 'black',
    }
  }
  const getTitleForCongViec = congViec => {
    if (congViec == 'cong_viec_thuoc_chuc_nang_nhiem_vu')
      return 'a/ Công việc thuộc chức năng, nhiệm vụ:'
    if (congViec == 'cong_viec_theo_kpi_kri')
      return 'b/ Công việc theo KPI/KRI:'
    if (congViec == 'cong_viec_theo_muc_tieu_chien_luoc')
      return 'c/ Công việc theo mục tiêu, chiến lược:'
  }

  const phong_lanh_dao_ban_cqvp = useMemo(() => {
    if (III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp) {
      const filteredItems = III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp?.filter(
        el => !el.value.startsWith('CPN')
      )
      const department = filteredItems
        ?.filter(item => !item.value.startsWith('USR'))
        ?.map(item => utils.getNameInCapitalize(item.label))

      return department
    } else {
      return []
    }
  }, [III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp])

  let dataPtt1_doi_tuong_thuc_hien = {}
  if (data != null && data?.length > 0) {
    dataPtt1_doi_tuong_thuc_hien = data[0]?.doi_tuong_thuc_hien
  }
  const department_name_nddpv_cua_vimc_tai_dntv =
    Array.isArray(riskUnitsV2) &&
    riskUnitsV2?.find(
      e =>
        e.code ===
        dataPtt1_doi_tuong_thuc_hien?.department_code_nddpv_cua_vimc_tai_dntv
    )
  const department_name_DVPY_cua_VIMC =
    Array.isArray(riskUnitsV2) &&
    riskUnitsV2?.find(
      e => e.code === dataPtt1_doi_tuong_thuc_hien?.dvpt_cua_vimc
    )

  const findDepartmentByCode = unit_code => {
    let department = departmentList?.find(dept => dept.code === unit_code)
    if (department) {
      return department
    }
    return departmentWithUserMasterList?.find(dept => dept.code === unit_code)
  }

  return (
    <RiskActionStyle>
      <h6
        style={{
          marginLeft: 24,
          fontStyle: 'italic ',
          marginTop: 8,
        }}>
        Đối tượng thực hiện:&nbsp;
      </h6>
      {utils.checkRichType(
        type,
        <ActionLabel
          label={`- NĐDPV của ${systemName} tại DNTV (trong trường hợp giao NĐDPV thực hiện công việc):`}
          text={utils.getNameInCapitalize(
            department_name_nddpv_cua_vimc_tai_dntv?.name
          )}
          labelStyle={labelBold}
        />,
        <ActionLabel
          label={`- ĐVPT của ${systemName} (trong trường hợp ĐVPT thực hiện công việc):`}
          text={utils.getNameInCapitalize(department_name_DVPY_cua_VIMC?.name)}
          labelStyle={labelBold}
        />,
        <ActionLabel
          label={`- Các Đơn vị thuộc cơ quan Văn phòng của ${systemName} (trong trường hợp Văn phòng cơ quan/ các Ban CMNV/TT CNTT thực hiện công việc):`}
          text={
            findDepartmentByCode(
              dataPtt1_doi_tuong_thuc_hien?.cac_don_vi_thuoc_cq_vp_cua_vimc
            )?.name
          }
          labelStyle={labelBold}
        />
      )}
      <Divider />
      <h6>I. NHẬN DIỆN RỦI RO VÀ ĐÁNH GIÁ RỦI RO</h6>
      <h6>1. Công việc/lĩnh vực hoạt động</h6>
      <>
        {[
          'cong_viec_thuoc_chuc_nang_nhiem_vu',
          'cong_viec_theo_kpi_kri',
          'cong_viec_theo_muc_tieu_chien_luoc',
        ].map(congViec => (
          <TableHasCheckbox
            key={congViec}
            styleCustom={cong_viec_theo?.includes(congViec) ? true : false}
            title={getTitleForCongViec(congViec)} // Hàm getTitleForCongViec() trả về tiêu đề tương ứng cho công việc
            checked={cong_viec_theo?.includes(congViec)}
          />
        ))}
      </>

      <ActionLabel
        label="d/ Nội dung công việc khác không thuộc các nhóm trên:&nbsp;"
        text={
          <span
            className="customStyleText customStyle"
            style={{ fontStyle: isPartOfPdf ? 'italic' : '' }}>
            {noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren}
          </span>
        }
        labelStyle={{ marginLeft: isPartOfPdf ? '6px' : null }}
      />
      <h6>2. Công việc thực hiện</h6>
      <ActionLabel
        label="a/ Nội dung công việc:&nbsp;"
        text={
          <span style={{ marginLeft: isPartOfPdf ? '6px' : 0 }}>
            <RichText
              htmlText={`<div style="${
                isPartOfPdf ? 'font-style: italic;' : ''
              }">${noi_dung_cong_viec || ''}</div>`}></RichText>
          </span>
        }
        labelStyle={{ marginLeft: '6px' }}
      />
      <ActionLabel
        label="b/ Người thực hiện:&nbsp;"
        text={nguoi_thuc_hien}
        labelStyle={{ marginLeft: '6px' }}
      />
      <ActionLabel
        label="c/ Thời gian hoàn thành:&nbsp;"
        text={thoi_gian_hoan_thanh}
        labelStyle={{ marginLeft: '6px' }}
      />

      <h6>3. Nhận diện rủi ro và đánh giá rủi ro</h6>
      <ActionLabel
        label="a/ Nhận diện rủi ro:&nbsp;"
        text={
          <RichText
            htmlText={`<div style="${
              isPartOfPdf ? 'font-style: italic;padding-left:6px;' : ''
            }">${nhan_dien_rui_ro || ''}</div>`}></RichText>
        }
        labelStyle={{ marginLeft: '6px' }}
      />
      <ActionLabel
        label="b/ Xây dựng giải pháp giảm thiểu, phòng ngừa:&nbsp;"
        text={
          <RichText
            htmlText={`<div style="${
              isPartOfPdf ? 'font-style: italic;' : ''
            }">${
              xay_dung_giai_phap_giam_thieu_phong_ngua || ''
            }</div>`}></RichText>
        }
        labelStyle={{ marginLeft: '6px' }}
      />
      {renderData?.map((data, index) => (
        <ActionLabel
          key={index}
          text={data}
          label={index === 0 ? 'c/ Đánh giá rủi ro:' : ''}
          fontSizeStyle={{ fontSize: isPartOfPdf ? '14px' : '12px' }}
          marginStyle={{ marginLeft: '7px' }}
        />
      ))}
      {!renderData?.length && (
        <ActionLabel
          label="c/ Đánh giá rủi ro:&nbsp;"
          text={displayRiskAssessmentCombined(r || '')}
          labelStyle={{ marginLeft: '6px' }}
          fontSizeStyle={{ fontSize: isPartOfPdf ? '14px' : '12px' }}
        />
      )}
      <table style={styleTable}>
        <tbody>
          <tr style={{ backgroundColor: 'white' }}>
            <td style={webTableCellStylePDF(3)}>
              <div>
                <b>Người thực hiện </b>
              </div>
            </td>
            <td style={webTableCellStylePDF(3)}></td>
            <td style={webTableCellStylePDF(3)}>
              <div>
                {type === 'NDDPV' ? (
                  <div>
                    <b>NĐDPV phụ trách chung </b>
                  </div>
                ) : type === 'DVPT' ? (
                  <b>Lãnh đạo Phòng</b>
                ) : (
                  <b>Lãnh đạo Đơn vị/NĐDPV phụ trách chung </b>
                )}
              </div>
            </td>
          </tr>
          <tr style={{ backgroundColor: 'white' }}>
            <td style={webTableCellStylePDF(3)}>
              {/* // Nếu có đã ký thì không cần cách nữa - người thực hiện - NDDPV */}
              {nguoi_thuc_hien_sign?.map(item => (
                <>
                  {departmentWithUserMasterList
                    ?.filter(el => el.code === item.value)
                    ?.map(unit => (
                      <div
                      // style={{
                      //   marginTop: nguoi_thuc_hien_signed ? '5px' : '60px',
                      // }}
                      >
                        {unit.name}
                      </div>
                    ))}
                </>
              ))}
              <div style={{ marginTop: '5px' }}>
                {nguoi_thuc_hien_sign
                  ?.filter(el => el.value.startsWith('CPN'))
                  .map(item => (
                    <span>{utils.getNameInCapitalize(item.label)}</span>
                  ))}
              </div>

              {/* Đã ký */}
              <div style={{ marginTop: '5px' }}>{nguoi_thuc_hien_signed}</div>
              {nguoi_thuc_hien_sign
                ?.filter(
                  el =>
                    !el.value.startsWith('CPN') &&
                    !codeDepartmentList.includes(el.value)
                )
                .map(item => (
                  <div
                    style={{
                      marginTop: nguoi_thuc_hien_signed ? '5px' : '60px',
                    }}>
                    {utils.getNameInCapitalize(item.label)}
                  </div>
                ))}
              <div
                style={{
                  marginTop: nguoi_thuc_hien_signed ? '5px' : '60px',
                }}>
                {nguoi_thuc_hien_sign_khac}
              </div>
              {/* Ý kiến người thực hiện phần In pdf */}
              {type === 'DVPT' ? (
                <>
                  {nguoi_thuc_hien_dvpt_y_kien ? (
                    <span
                      style={{
                        fontStyle: 'italic',
                        textAlign: 'start',
                      }}>
                      - Ý kiến: {nguoi_thuc_hien_dvpt_y_kien}
                    </span>
                  ) : null}
                </>
              ) : null}
            </td>
            <td style={webTableCellStylePDF(3)}></td>
            {/* //NDDPV */}
            <td
              style={{
                ...webTableCellStylePDF(3),
                position: 'relative',
              }}>
              {nddpv_phu_trach_chung && type === 'NDDPV' ? (
                <div>
                  <div>
                    {nddpv_phu_trach_chung
                      ?.filter(el => el.value.startsWith('CPN'))
                      .map(item => (
                        <div>{utils.getNameInCapitalize(item.label)}</div>
                      ))}
                  </div>
                  <span>{nddpv_phu_trach_chung__signed}</span>
                  <div style={{ marginTop: '5px' }}>
                    {nddpv_phu_trach_chung?.map(item => (
                      <>
                        {departmentWithUserMasterList
                          ?.filter(el => el.code === item.value)
                          ?.map(unit => (
                            <span>{unit.name}</span>
                          ))}
                      </>
                    ))}
                  </div>

                  {/* // Nếu không có đã ký thì không cần cách - NDDPV phụ trách chung - NDDPV */}

                  {nddpv_phu_trach_chung
                    ?.filter(
                      el =>
                        !el.value.startsWith('CPN') &&
                        !codeDepartmentList.includes(el.value)
                    )
                    .map(item => (
                      <div
                        style={{
                          marginTop: nddpv_phu_trach_chung__signed
                            ? '5px'
                            : '60px',
                        }}>
                        {utils.getNameInCapitalize(item.label)}
                      </div>
                    ))}
                </div>
              ) : //DVPT
              type === 'DVPT' ? (
                <>
                  {lanh_dao_phong_dvpt?.map(item => (
                    <div style={{ marginTop: '5px' }}>
                      {departmentWithUserMasterList
                        ?.filter(el => el.code === item.value)
                        ?.map(unit => (
                          <div
                          // style={{
                          //   marginTop: nguoi_thuc_hien_signed ? '5px' : '60px',
                          // }}
                          >
                            {unit.name}
                          </div>
                        ))}
                    </div>
                  ))}
                  <div style={{ marginTop: '5px' }}>
                    {lanh_dao_phong_dvpt
                      ?.filter(el => el.value.startsWith('CPN'))
                      .map(item => (
                        <span>{utils.getNameInCapitalize(item.label)}</span>
                      ))}
                  </div>
                  {/* Đã ký */}
                  {lanh_dao_phong_dvpt__signed}
                  {lanh_dao_phong_dvpt
                    ?.filter(
                      el =>
                        !el.value.startsWith('CPN') &&
                        !codeDepartmentList.includes(el.value)
                    )
                    .map(item => (
                      <div
                        style={{
                          marginTop: lanh_dao_phong_dvpt__signed
                            ? '5px'
                            : '60px',
                        }}>
                        {utils.getNameInCapitalize(item.label)}
                      </div>
                    ))}
                  <div
                    style={{
                      marginTop: lanh_dao_phong_dvpt__signed ? '5px' : '60px',
                    }}></div>
                  {type === 'DVPT' ? (
                    <>
                      {lanh_dao_dvpt_y_kien ? (
                        <span
                          style={{
                            fontStyle: 'italic',
                            textAlign: 'start',
                          }}>
                          - Ý kiến: {lanh_dao_dvpt_y_kien}
                        </span>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <div style={{ marginTop: '5px' }}>
                    {lanh_dao_don_vi_phu_thuoc_cqvp
                      ?.filter(
                        el =>
                          !el.value.startsWith('CPN') &&
                          !el.value.startsWith('USR')
                      )
                      .map(item => {
                        return (
                          <span>{utils.getNameInCapitalize(item.label)}</span>
                        )
                      })}
                  </div>

                  {/* Đã ký-CQVP */}
                  <div> {nddpv_phu_trach_chung__signed}</div>

                  {lanh_dao_don_vi_phu_thuoc_cqvp
                    ?.filter(el => el.value.startsWith('USR'))
                    .map(item => {
                      return (
                        <div
                          style={{
                            marginTop: nddpv_phu_trach_chung__signed
                              ? '5px'
                              : '60px',
                          }}>
                          {utils.getNameInCapitalize(item.label)}
                        </div>
                      )
                    })}
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <Divider />

      <h6 style={{ marginTop: 20 }}>II. XỬ LÝ RỦI RO</h6>
      <h6>1. Đề xuất loại rủi ro cần xử lý </h6>
      {checkArr === false ? (
        <>
          <ActionLabel
            label="a/ Loại rủi ro đề xuất:&nbsp;"
            text={
              <>
                <RichText
                  htmlText={`<div style="${
                    isPartOfPdf ? 'font-style: italic;' : ''
                  }">${loai_rui_ro_de_xuat || ''}</div>`}></RichText>
              </>
            }
            labelStyle={{ marginLeft: '6px' }}
          />
        </>
      ) : (
        <ActionLabel
          label="a/ Loại rủi ro đề xuất:&nbsp;"
          text={
            <>
              {displayRiskAssessment(loai_rui_ro_de_xuat)}
              {type === 'NDDPV' ? (
                <RichText
                  htmlText={`<div style="${
                    isPartOfPdf ? 'font-style: italic;' : ''
                  }">${loai_rui_ro_de_xuat_khac || ''}</div>`}></RichText>
              ) : (
                <span style={{ fontStyle: 'italic' }}>
                  {loai_rui_ro_de_xuat_khac || ''}
                </span>
              )}
            </>
          }
          // labelStyle={labelBold}
          labelStyle={{ marginLeft: isPartOfPdf ? '6px' : null }}
        />
      )}
      {type === 'NDDPV' || type === 'DVPT' ? (
        <>
          <ActionLabel
            label="b/ Bộ phận đề xuất:&nbsp;"
            text={<>{bo_phan_de_xuat_khac}</>}
            labelStyle={{ marginLeft: '6px' }}
          />
        </>
      ) : (
        <>
          <ActionLabel
            label="b/ Bộ phận đề xuất:&nbsp;"
            text={<>{bo_phan_de_xuat}</>}
            labelStyle={{ marginLeft: '6px' }}
          />
        </>
      )}

      <ActionLabel
        label="c/ Biện pháp xử lý:&nbsp;"
        text={
          <RichText
            htmlText={`<div style="${
              isPartOfPdf ? 'font-style: italic; marginLeft: "12px";' : ''
            }">${bien_phap_xu_ly || ''}</div>`}
          />
        }
        labelStyle={{ marginLeft: '6px' }}
      />
      <div style={{ paddingBottom: '40px' }}>
        {type === 'NDDPV' ? (
          <ActionLabels
            label="d/ Người thực hiện:&nbsp;"
            title={
              <div>
                <div>
                  {nguoi_thuc_hien__de_xuat_khac?.map((item, index) => (
                    <div key={index}>{item?.label}</div>
                  ))}
                </div>
                <div>{nguoi_thuc_hien_II}</div>
              </div>
            }
            labelStyle={{ marginLeft: '6px' }}
          />
        ) : (
          <ActionLabels
            label="d/ Người thực hiện:&nbsp;"
            title={nguoi_thuc_hien_II}
            labelStyle={{ marginLeft: '6px' }}
          />
        )}
      </div>

      <Divider />

      <h6 style={{ marginTop: isPartOfPdf ? 20 : 0 }}>
        {type == 'NDDPV' ? (
          <>2. Lãnh đạo quyết định biện pháp xử lý</>
        ) : (
          <>
            {type === 'DVPT' ? (
              <>2. Phó giám đốc phụ trách quyết định biện pháp xử lý</>
            ) : (
              <>2. Lãnh đạo quyết định biện pháp xử lý</>
            )}
          </>
        )}
      </h6>

      <table style={styleTable}>
        <tbody>
          <tr>
            <td style={{ ...webTableCellStylePDF(3), position: 'relative' }}>
              <div>
                <div>
                  {type === 'NDDPV' ? (
                    <b>NGƯỜI ĐẠI DIỆN PHẦN VỐN THỰC HIỆN XỬ LÝ RỦI RO</b>
                  ) : type === 'DVPT' ? (
                    <b>LÃNH ĐẠO PHÒNG</b>
                  ) : (
                    <b
                      style={{
                        position: 'absolute',
                        top: '0px',
                        left: '50px',
                      }}>
                      LÃNH ĐẠO ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO
                    </b>
                  )}
                </div>

                <div>
                  <div style={{ marginTop: '10px' }}>
                    {don_vi_thuc_hien_xu_ly_rui_ro
                      ?.filter(el => el.value.startsWith('CPN'))
                      .map(el => (
                        <div>{utils.getNameInCapitalize(el.label)}</div>
                      ))}
                  </div>
                  <div style={{ marginTop: '0px' }}>
                    {don_vi_thuc_hien_xu_ly_rui_ro?.map(item => (
                      <div>
                        {departmentWithUserMasterList
                          ?.filter(el => el.code === item.value)
                          ?.map(unit => (
                            <span>{unit.name}</span>
                          ))}
                      </div>
                    ))}
                  </div>
                  <span>{don_vi_thuc_hien_xu_ly_rui_ro__signed}</span>
                  {
                    // Nếu có đã ký thì không cách để ký nữa - người đại diện phần vốn thực hiện xử lý rủi ro - NDDPV
                    don_vi_thuc_hien_xu_ly_rui_ro__signed ? (
                      <>
                        <div style={{ marginTop: '5px' }}>
                          {don_vi_thuc_hien_xu_ly_rui_ro
                            ?.filter(
                              el =>
                                !el.value.startsWith('CPN') &&
                                !codeDepartmentList.includes(el.value)
                            )
                            .map(el => (
                              <div>{utils.getNameInCapitalize(el.label)}</div>
                            ))}
                        </div>
                        <div>
                          {utils.getNameInCapitalize(
                            don_vi_thuc_hien_xu_ly_rui_ro_khac?.full_name
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        {don_vi_thuc_hien_xu_ly_rui_ro
                          ?.filter(
                            el =>
                              !el.value.startsWith('CPN') &&
                              !codeDepartmentList.includes(el.value)
                          )
                          .map(el => (
                            <div style={{ marginTop: '65px' }}>
                              {utils.getNameInCapitalize(el.label)}
                            </div>
                          ))}
                        {/* <div style={{ marginTop: '65px' }}>
                          {utils.getNameInCapitalize(
                            don_vi_thuc_hien_xu_ly_rui_ro_khac?.full_name
                          )}
                        </div> */}
                      </>
                    )
                  }
                </div>
              </div>
            </td>
            <td
              style={{ ...webTableCellStylePDF(3), position: 'relative' }}></td>
            <td style={{ ...webTableCellStylePDF(3), position: 'relative' }}>
              {type === 'NDDPV' ? (
                <div>
                  <b>NGƯỜI ĐẠI DIỆN PHẦN VỐN PHỤ TRÁCH CHUNG</b>
                  <div style={{ marginTop: '0px' }}>
                    {nguoi_dai_dien_phan_von_phu_trach_chung
                      ?.filter(el => el.value.startsWith('CPN'))
                      .map(el => (
                        <div style={{ marginTop: '0px' }}>
                          {utils.getNameInCapitalize(el.label)}
                        </div>
                      ))}
                    {nguoi_dai_dien_phan_von_phu_trach_chung?.map(item => (
                      <div>
                        {departmentWithUserMasterList
                          ?.filter(el => el.code === item.value)
                          ?.map(unit => (
                            <div style={{ marginTop: '0px' }}>{unit.name}</div>
                          ))}
                      </div>
                    ))}
                    <span>
                      {nguoi_dai_dien_phan_von_phu_trach_chung__signed}
                    </span>

                    {
                      //Nếu có chữ đã ký thì không cần cách để ký nữa - người đại diện phần vốn phụ trách chung - NDDPV
                      nguoi_dai_dien_phan_von_phu_trach_chung__signed ? (
                        <>
                          {nguoi_dai_dien_phan_von_phu_trach_chung
                            ?.filter(
                              el =>
                                !el.value.startsWith('CPN') &&
                                !codeDepartmentList.includes(el.value)
                            )
                            .map(el => (
                              <div style={{ marginTop: '5px' }}>
                                {utils.getNameInCapitalize(el.label)}
                              </div>
                            ))}
                        </>
                      ) : (
                        <>
                          {nguoi_dai_dien_phan_von_phu_trach_chung
                            ?.filter(
                              el =>
                                !el.value.startsWith('CPN') &&
                                !codeDepartmentList.includes(el.value)
                            )
                            .map(el => (
                              <div style={{ marginTop: '65px' }}>
                                {utils.getNameInCapitalize(el.label)}
                              </div>
                            ))}
                        </>
                      )
                    }
                  </div>
                </div>
              ) : (
                <div style={{}}>
                  {type === 'DVPT' ? (
                    <b>PHÓ GIÁM ĐỐC PHỤ TRÁCH</b>
                  ) : (
                    <b>
                      LÃNH ĐẠO {systemName}
                      <br />
                      <span style={{ width: '100%' }}>
                        (theo phân công trách nhiệm quản lý rủi ro)
                      </span>
                    </b>
                  )}
                  {pho_giam_doc_phu_trach
                    ?.filter(el => el.value.startsWith('CPN'))
                    .map(el => (
                      <div key={el.value}>
                        {utils.getNameInCapitalize(el.label)}
                      </div>
                    ))}
                  {pho_giam_doc_phu_trach?.map(item => (
                    <div key={item.value}>
                      {departmentWithUserMasterList
                        ?.filter(el => el.code === item.value)
                        ?.map(unit => (
                          <div style={{ marginTop: '5px' }} key={unit.code}>
                            {unit.name}
                          </div>
                        ))}
                    </div>
                  ))}
                  <span>{pho_giam_doc_phu_trach__signed}</span>
                  {pho_giam_doc_phu_trach__signed ? (
                    <>
                      {pho_giam_doc_phu_trach
                        ?.filter(
                          el =>
                            !el.value.startsWith('CPN') &&
                            !codeDepartmentList.includes(el.value)
                        )
                        .map(el => (
                          <>
                            {lanh_dao_vimc?.position_name}
                            <div style={{ marginTop: '5px' }}>
                              {utils.getNameInCapitalize(el.label)}
                            </div>
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      {pho_giam_doc_phu_trach
                        ?.filter(
                          el =>
                            !el.value.startsWith('CPN') &&
                            !codeDepartmentList.includes(el.value)
                        )
                        .map(el => (
                          <>
                            {lanh_dao_vimc?.position_name}
                            <div style={{ marginTop: '65px' }}>
                              {utils.getNameInCapitalize(el.label)}
                            </div>
                          </>
                        ))}
                    </>
                  )}

                  {type !== 'DVPT' && (
                    <div>
                      <span>
                        {nguoi_dai_dien_phan_von_phu_trach_chung__signed}
                      </span>
                      <div
                        style={{
                          fontSize: '12px',
                          marginTop: nguoi_dai_dien_phan_von_phu_trach_chung__signed
                            ? '0px'
                            : '65px',
                        }}>
                        {lanh_dao_vimc?.position_name}
                      </div>
                      {utils.getNameInCapitalize(lanh_dao_vimc?.full_name)}
                    </div>
                  )}
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      {/* {type === 'CQVP' && <div style={{ height: '40px' }}></div>} */}
      {type === 'DVPT' && (
        <>
          <Divider />
          <h6>III. KIỂM SOÁT RỦI RO</h6>
          <RiskAssessmentItem
            label={'1. Đối với các loại rủi ro đã nhận diện: '}
            data={doi_voi_cac_loai_rui_ro_da_nhan_dien}
            textValue={textValue}
            labelBold={labelBold}
          />
          <RiskAssessmentItem
            label={'2. Đối với đánh giá mức rủi ro: '}
            data={doi_voi_danh_gia_muc_rui_ro}
            textValue={textValue}
            labelBold={labelBold}
          />
          <RiskAssessmentItem2
            label={'3. Loại rủi ro đã đề xuất xử lý: '}
            data={loai_rui_ro_da_de_xuat_xu_ly}
            textValue={textValue}
            labelBold={labelBold}
          />
          <RiskAssessmentItem2
            label={'4. Đánh giá biện pháp xử lý đã đề xuất: '}
            data={danh_gia_bien_phap_xu_ly_da_de_xuat}
            textValue={textValue}
            labelBold={labelBold}
          />
          <RiskAssessmentItem2
            label={'5. Thời gian hoàn thành công việc được giao: '}
            data={thoi_gian_hoan_thanh_cong_viec_duoc_giao}
            textValue={textValue}
            labelBold={labelBold}
          />
          <p>
            <span>6. Ý kiến khác: &nbsp;</span>
            <span style={{ ...textValue, ...labelBold }}>
              {bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc}
              {bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc
                ? ': '
                : ''}
              {y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc}
            </span>
          </p>
          <>
            <table style={{ ...styleTable }}>
              <tbody>
                <tr style={{ backgroundColor: 'white' }}>
                  <td style={webTableCellStylePDF(3)}>
                    <div>
                      <>
                        <b>LÃNH ĐẠO PHÒNG PCATHH</b>
                        <br />
                        {renderNameLanhDaoBanT2(
                          III_kiem_soat_rui_ro?.lanh_dao_phong_pcathh?.filter(
                            el => el.value.startsWith('CPN')
                          )
                        )}
                      </>
                    </div>
                  </td>
                  <td style={webTableCellStylePDF(3)}></td>
                  <td style={webTableCellStylePDF(3)}>
                    <div>
                      <>
                        <b>GIÁM ĐỐC</b> <br />
                        {/* {lanh_dao_ban?.map((departmentName, index, array) => (
                          <span key={index}>
                            {departmentName}
                            {index < array?.length - 1 ? ',' : ''}
                          </span>
                        ))} */}
                        {renderNameLanhDaoBanT2(
                          III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp?.filter(el =>
                            el.value.startsWith('CPN')
                          )
                        )}
                      </>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={webTableCellStylePDF(3)}>
                    {lanh_dao_phong_pcathh__signed}
                    <div>
                      <div>
                        {type === 'NDDPV' ? (
                          <></>
                        ) : (
                          lanh_dao_phong_pcathh?.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                marginTop: lanh_dao_phong_pcathh__signed
                                  ? '0px'
                                  : '60px',
                              }}>
                              {item}
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </td>
                  <td style={webTableCellStylePDF(3)}></td>
                  <td style={webTableCellStylePDF(3)}>
                    {lanh_dao_ban_signed}
                    <div>
                      <div>
                        {type === 'NDDPV' ? (
                          <></>
                        ) : (
                          lanh_dao_ban_cqvp?.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                marginTop: lanh_dao_ban_signed ? '0px' : '60px',
                              }}>
                              <span> {item}</span>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        </>
      )}
    </RiskActionStyle>
  )
}

export default inject('departmentStore')(observer(RiskActionDetails1))
