import React, { Fragment, memo, useCallback, useEffect } from 'react'
import { Col, Form, message, Row, Select, Input } from 'antd'
import { inject, observer } from 'mobx-react'
import { FilterBoxWrapper } from './AdvanceFilterStyled'
import { SearchOutlined } from '@ant-design/icons'

const { Search } = Input
const { Option } = Select
const styleIconSearch = {
  opacity: '0.5',
  position: 'absolute',
  right: 5,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 9,
}

const AdvanceFilter = props => {
  const { filter, setFilter } = props
  const [form] = Form.useForm()

  useEffect(() => form.resetFields(), [])
  const onSearchUsername = async title => {
    setFilter({
      page: 1,
      size: 10,
      keyword: title ? title.trim() : null,
    })
  }
  const onSearchAccount = async title => {
    setFilter({
      page: 1,
      size: 10,
      accountKeyword: title ? title.trim() : null,
    })
  }
  const handleFilterByType = async type => {
    setFilter({
      page: 1,
      size: 10,
      type: type,
    })
  }
  return (
    <Fragment>
      <FilterBoxWrapper>
        <Form
          form={form}
          scrollToFirstError={true}
          name={'user-filter-form'}
          layout={'vertical'}>
          <Row type={'flex'} gutter={16}>
            <Col xs={24} md={6}>
              <Form.Item label={'Người dùng'} name={'username'}>
                <Search
                  allowClear
                  onSearch={value => onSearchUsername(value)}
                  placeholder={'Nhập tên người dùng'}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item label={'Tài khoản'} name={'account'}>
                <Search
                  allowClear
                  onSearch={value => onSearchAccount(value)}
                  placeholder={'Nhập tài khoản'}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item label={'Chọn loại'} name="type">
                <Select
                  allowClear
                  showSearch
                  onChange={handleFilterByType}
                  placeholder={'Chọn loại'}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp={'name'}>
                  <Option value={'USER'}>USER</Option>
                  <Option value={'SYSTEM'}>SYSTEM</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </FilterBoxWrapper>
      <div style={{ marginBottom: 15 }} />
    </Fragment>
  )
}

AdvanceFilter.propTypes = {}

export default inject(
  'authorityStore',
  'connectedCommitteeDocumentStore',
  'loadingAnimationStore'
)(observer(AdvanceFilter))
