import React from 'react'
import { Button, Empty, Input, Pagination, Select, Tabs, Tag } from 'antd'
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../../components/PageTitle'
import { PlusCircleOutlined } from '@ant-design/icons'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import { TableBottomPaginationBlock } from '../../../components/Common/Table'
import { Text } from '../../../components/Common/CellText'
import { SearchBar } from '../Proposal/ProposalPage/ProposalPageStyled'
import TableComponent from '../../../components/Common/TableComponent'

const { TabPane } = Tabs
const { Search } = Input
const { Option } = Select

const AdvancePaymentPage = props => {
  const { history } = props

  const AdvancePaymentList = [
    {
      id: '01',
      content: 'File 1',
      file_name: 'file1.pdf',
      create_user: 'Lê Đình Thọ',
      proposal_status: 'file1.pdf',
    },
  ]
  const tableColumns = [
    {
      title: 'Số thứ tự',
      width: 150,
      render: record => <Text>{record.id}</Text>,
    },
    {
      title: 'Thông tin tài liệu',
      width: 400,
      render: record => <Text>{record.content}</Text>,
    },
    {
      title: 'Tệp đính kèm',
      width: 400,
      render: record => <Text>{record.file_name}</Text>,
    },
    {
      title: 'Người tạo',
      width: 150,
      render: record => <Text>{record.create_user}</Text>,
    },
    {
      title: 'Trạng thái',
      width: 150,
      render: record => (
        <Tag color={'geekblue'} style={{ borderRadius: '10px' }}>
          Chờ phê duyệt
        </Tag>
      ),
    },
  ]
  const headingList = (
    <SearchBar style={{ maxWidth: '500px' }}>
      <Search
        style={{ marginRight: '16px' }}
        allowClear
        placeholder={'Tìm kiếm theo nội dung'}
      />
      <Select
        style={{ marginRight: '16px' }}
        name={'proposal_status'}
        placeholder={'Lọc theo trạng thái'}
        showSearch={true}
        allowClear>
        <Option value={'200'}>Cần phê duyệt</Option>
        <Option value={'201'}>Hủy phiếu</Option>
        <Option value={'202'}>Đã phê duyệt</Option>
        <Option value={'203'}>Chờ phê duyệt</Option>
      </Select>
    </SearchBar>
  )
  return (
    <DashboardLayout>
      <Helmet>
        <title>Phiếu trình | Danh sách phiếu trình</title>
      </Helmet>
      <PageTitle
        location={props.location}
        hiddenGoBack
        title="Danh sách hồ sơ giao nhận với KBNN">
        <Button
          onClick={() => history.push('/administrative/advance-payment/create')}
          type={'primary'}>
          <PlusCircleOutlined /> Tạo mới
        </Button>
      </PageTitle>
      <ContentBlockWrapper>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Tất cả" key="1">
            {headingList}
            <TableComponent
              rowKey={record => record.id}
              dataSource={AdvancePaymentList}
              columns={tableColumns}
              pagination={false}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={'Không có dữ liệu'}
                  />
                ),
              }}
            />
            <TableBottomPaginationBlock>
              <Pagination current={1} pageSize={10} total={10} />
            </TableBottomPaginationBlock>
          </TabPane>
          <TabPane tab="Của tôi" key="2">
            {headingList}
            Content of Tab Pane 2
          </TabPane>
          <TabPane tab="Tôi theo dõi" key="3">
            {headingList}
            Content of Tab Pane 3
          </TabPane>
          <TabPane tab="Tôi duyệt" key="4">
            {headingList}
            Content of Tab Pane 4
          </TabPane>
        </Tabs>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

AdvancePaymentPage.propTypes = {}

export default AdvancePaymentPage
