import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const ShoppingCouponRequest = {
  getShoppingCouponList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/procurement/search`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),
  getShoppingCouponListV2: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/procurement/all`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),
  getShoppingAllCouponList: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/procurement/getAll`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  createShoppingCoupon: payload =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/procurement`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: payload,
    }),

  getShoppingCouponDetail: sCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/procurement/${sCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  updateShoppingCoupon: (sCode, data) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/procurement/${sCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),

  downloadProcurementAttachment: file_code =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/procurement/download/${file_code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    }),

    deleteAttachment: sCode =>
      axios({
        method: 'delete',
        url: `${apiUrl}/api/v1/procurement/delete-attachment/${sCode}`,
        headers: {
          Authorization: `Bearer ${
            JSON.parse(authenticationStore.appToken).access_token
          }`,
          'Content-Type': 'application/json',
        },
      }),

  changeType: aprrover_code =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/procurement/changeType/${aprrover_code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  deleteShoppingCoupon: sCode =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/procurement/${sCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  rejectShoppingCoupon: sCode => 
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/procurement/reject/${sCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  createFeedback: payload =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/procurement/feedback`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: payload,
    }),

  getFeedbackList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/procurement/feedback`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: params,
    }),

  deleteFeedback: id =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/procurement/feedback/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

    updateWork: (payload, approveCode, procurementCode) =>
      axios({
        method: 'put',
        url: `${apiUrl}/api/v1/procurement/works/${approveCode}/${procurementCode}`,
        headers: {
          Authorization: `Bearer ${JSON.parse(authenticationStore.appToken).access_token
            }`,
          'Content-Type': 'application/json',
        },
        data: payload,
        
      }),

    sendNotificationFollower: (procurementCode, data) => 
      axios({
        method: 'post',
        url: `${apiUrl}/api/v1/procurement/send-notification-follower/${procurementCode}`,
        headers: {
          Authorization: `Bearer ${JSON.parse(authenticationStore.appToken).access_token
            }`,
          'Content-Type': 'application/json',
        },
        data: data,
        
      }),

    sendNotification: data => 
      axios({
        method: 'post',
        url: `${apiUrl}/api/v1/procurement/send-notification`,
        headers: {
          Authorization: `Bearer ${JSON.parse(authenticationStore.appToken).access_token
            }`,
          'Content-Type': 'application/json',
        },
        data: data,
      }),
    
}
