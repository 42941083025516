import {
  Empty,
  message,
  Modal,
  Col,
  Input,
  Row,
  Select,
  DatePicker,
  Pagination,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import TableComponent from '../../components/Common/TableComponent'
import {
  QuestionCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  HistoryOutlined,
} from '@ant-design/icons'
import ManagementAssetWarehouseUpdate from './ManagementAssetWarehouseUpdate'
import { YYYY_MM_DD, DATE_FORMAT_DEFAULT } from '../../constants'
import moment from 'moment'
import { TableBottomPaginationBlock } from '../../components/Common/Table'
import ManagementAssetWarehouseHistory from './ManagementAssetWarehouseHistory'

const { Option } = Select

const { confirm } = Modal

const ManagementAssetWarehouseTable = props => {
  const {
    selectPeopleStore,
    authenticationStore,
    managementAssetWarehouseStore,
    selectedRowKeys,
    setSelectedRowKeys,
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false)
  const [isVisibleHistoryModal, setIsVisibleHistoryModal] = useState(false)
  const [enter, setEnter] = useState(true)
  const [assetName1, setAssetName1] = useState(null)
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [selectedHistoryId, setSelectedHistoryId] = useState(null)
  const [historyAssetName, setHistoryAssetName] = useState('')

  const { companyCode } = selectPeopleStore
  const { managementAssetWarehouseList } = managementAssetWarehouseStore
  const {
    assetName,
    assetTypeId,
    createdAt,
    pageIndex,
    sizeIndex,
    totalCount,
    managementAssetWarehouseTypes,
  } = managementAssetWarehouseStore

  useEffect(() => {
    ;(async () => {
      await managementAssetWarehouseStore.clearFilter()
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (enter) {
        await managementAssetWarehouseStore.setFilter('pageIndex', 0)
        await managementAssetWarehouseStore.clearManagementAssetWarehousetList()
        await managementAssetWarehouseStore.getManagementAssetWarehousetList()
        await setEnter(false)
      }
    })()
  }, [enter, assetName, assetTypeId, createdAt])

  useEffect(() => {
    ;(async () => {
      await loadManagerInventoryList()
    })()
    ;(async () => {
      await setAssetName1(assetName)
      await managementAssetWarehouseStore.getManagementAssetWarehouseAssetTypes()
    })()
  }, [pageIndex, sizeIndex])

  useEffect(() => {
    ;(async () => {
      if (companyCode) {
        try {
          await selectPeopleStore.getDepartmentWithUsers()
        } catch (err) {
          console.log(err)
          message.error(err.vi || 'Đã có lỗi xảy ra!')
        }
      }
    })()
  }, [companyCode])

  useEffect(() => {
    if (authenticationStore.currentUser) {
      selectPeopleStore.companyCode = authenticationStore.currentCompanyCode()
    }
  }, [authenticationStore.currentUser])

  const loadManagerInventoryList = async () => {
    try {
      setIsLoading(true)
      await managementAssetWarehouseStore.getManagementAssetWarehousetList()
    } finally {
      setIsLoading(false)
    }
  }

  const handleOpenUpdateModal = id => {
    setIsVisibleUpdateModal(true)
    setSelectedTemplateId(id)
  }

  const handleCancelUpdateModal = () => {
    setIsVisibleUpdateModal(false)
  }

  const handleOpenHistoryModal = record => {
    setIsVisibleHistoryModal(true)
    setSelectedHistoryId(record.id)
    setHistoryAssetName(record.asset_name)
  }

  const handleCancelHistoryModal = () => {
    setIsVisibleHistoryModal(false)
  }

  const handleDelete = async code => {
    try {
      setIsLoading(true)
      await managementAssetWarehouseStore.delete(code)
      await loadManagerInventoryList()
      message.success('Đã xóa!')
    } catch (err) {
      console.log(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  const showConfirm = (e, code) => {
    e.stopPropagation()
    setIsVisibleUpdateModal(false)
    confirm({
      title: 'Bạn có chắc chắn muốn xóa Tài sản này ?',
      icon: (
        <QuestionCircleOutlined
          style={{
            color: 'red',
          }}
        />
      ),
      okText: 'Xóa',
      cancelText: 'Không',
      okType: 'danger',
      onOk: async () => {
        await handleDelete(code)
      },
    })
  }

  const handleSelectChange = value => {
    if (value) {
      managementAssetWarehouseStore.setFilter('pageIndex', 0)
      managementAssetWarehouseStore.setQueryParams('assetTypeId', value)
      setEnter(true)
    } else {
      managementAssetWarehouseStore.setFilter('pageIndex', 0)
      managementAssetWarehouseStore.setQueryParams('assetTypeId', undefined)
      setEnter(true)
    }
  }

  const handleDateChange = async date => {
    await managementAssetWarehouseStore.setFilter('pageIndex', 0)
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : null
    await managementAssetWarehouseStore.setQueryParams(
      'createdAt',
      formattedDate
    )
    await loadManagerInventoryList()
  }

  const handleChangePageInventoryAssetLeave = useCallback(
    p => {
      const pageIndex = p - 1
      managementAssetWarehouseStore.setFilter('pageIndex', pageIndex)
      setSelectedRowKeys(null)
    },
    [managementAssetWarehouseStore]
  )

  const tableColumns = [
    {
      title: 'STT',
      width: 30,
      render: (id, record, index) => (
        <span title={pageIndex * sizeIndex + index + 1}>
          {pageIndex * sizeIndex + index + 1}
        </span>
      ),
    },
    {
      title: 'Loại văn phòng phẩm',
      width: 100,
      render: record => (
        <p
          style={{
            marginBottom: '0px',
          }}>
          {record?.asset_type.is_deleted === false
            ? record.asset_type?.description
            : null}
        </p>
      ),
    },
    {
      title: 'Tên văn phòng phẩm',
      width: 200,
      render: record => (
        <p
          style={{
            marginBottom: '0px',
          }}>
          {record.asset_name}
        </p>
      ),
    },
    {
      title: 'Đặc tính',
      width: 70,
      render: record => (
        <p
          style={{
            marginBottom: '0px',
          }}>
          {record?.property ? record?.property : '----'}
        </p>
      ),
    },
    {
      title: 'Đơn vị tính',
      width: 100,
      render: record => (
        <p
          style={{
            marginBottom: '0px',
          }}>
          {record?.asset_unit_name}
        </p>
      ),
    },
    {
      title: 'Thuế',
      width: 60,
      render: record => (
        <p
          style={{
            marginBottom: '0px',
          }}>
          {record?.tax ? `${record?.tax}%` : '----'}
        </p>
      ),
    },
    {
      title: 'Đơn giá trước thuế',
      width: 100,
      render: record => (
        <p
          style={{
            marginBottom: '0px',
          }}>
          {record?.price
            ? parseFloat(record?.price).toLocaleString('vi-VN')
            : '----'}
        </p>
      ),
    },
    {
      title: 'Đơn giá sau thuế',
      width: 100,
      render: record => (
        <p
          style={{
            marginBottom: '0px',
          }}>
          {record?.total_money
            ? parseFloat(record?.total_money).toLocaleString('vi-VN')
            : '----'}
        </p>
      ),
    },
    {
      title: 'Số lượng trong kho',
      width: 90,
      render: record => (
        <p
          style={{
            marginBottom: '0px',
          }}>
          {record?.amount}
        </p>
      ),
    },
    {
      title: 'Ngày tạo',
      width: 70,
      render: record => {
        const date = new Date(record?.create_at || 0)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        const seconds = String(date.getSeconds()).padStart(2, '0')

        const formattedDate = `${day}/${month}/${year}`
        return (
          <p
            style={{
              marginBottom: '0px',
            }}>
            {formattedDate}
          </p>
        )
      },
    },
    {
      title: 'Thao tác',
      width: 50,
      align: 'center',
      //ignore onRow event
      onCell: record => {
        return {
          onClick: event => {
            event.stopPropagation()
          },
        }
      },
      render: record => (
        <div>
          <HistoryOutlined
            title="Lịch sử"
            style={{
              marginRight: '8px',
            }}
            type={'vertical'}
            onClick={() => handleOpenHistoryModal(record)}
          />
          <EditOutlined
            title="Chỉnh sửa"
            style={{
              marginRight: '8px',
            }}
            type={'vertical'}
            onClick={() => handleOpenUpdateModal(record.id)}
          />
          <DeleteOutlined
            title="Xóa"
            style={{
              color: '#fc0000',
            }}
            onClick={e => showConfirm(e, record.id)}
          />
        </div>
      ),
    },
  ]
  /* asset_type_id */
  const sortedTypes = managementAssetWarehouseTypes?.content?.sort((a, b) => {
    return a.description.localeCompare(b.description)
  })

  const onSelectChange = newSelectedRowKeys => {
    if (newSelectedRowKeys.length > 1) {
      setSelectedRowKeys([newSelectedRowKeys[1]])
    } else {
      setSelectedRowKeys(newSelectedRowKeys)
    }
  }

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <Select
            style={{
              width: '100%',
            }}
            showSearch
            placeholder={'Loại văn phòng phẩm'}
            filterOption={true}
            onChange={handleSelectChange}
            value={assetTypeId}
            allowClear
            optionFilterProp={'name'}>
            {sortedTypes?.map(type => (
              <Option key={type.id} name={type.description} value={type.id}>
                {type.description}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <Input
            allowClear
            name="asset_name"
            onChange={e => {
              if (e.target.value === '') {
                setAssetName1('')
                managementAssetWarehouseStore.setQueryParams('assetName', null)
                setEnter(true)
              } else {
                setAssetName1(e.target.value)
              }
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                managementAssetWarehouseStore.setQueryParams(
                  'assetName',
                  assetName1
                )
                setEnter(true)
              }
            }}
            placeholder={'Tên văn phòng phẩm'}
          />
        </Col>
        <Col span={8}>
          <DatePicker
            style={{
              marginRight: '16px',
              minWidth: 200,
            }}
            allowClear
            style={{
              width: '100%',
            }}
            placeholder={'Ngày tạo'}
            format={'DD-MM-YYYY'}
            onChange={handleDateChange}
          />
        </Col>
      </Row>
      <TableComponent
        // @ts-ignore
        rowSelection={rowSelection}
        rowKey={record => record.id}
        dataSource={managementAssetWarehouseList}
        // @ts-ignore
        columns={tableColumns}
        pagination={false}
        onRow={record => ({
          onClick: () => handleOpenUpdateModal(record.id),
        })}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={isLoading}
      />
      <TableBottomPaginationBlock>
        <Pagination
          current={pageIndex + 1}
          pageSize={sizeIndex}
          total={totalCount}
          showSizeChanger={false}
          onChange={handleChangePageInventoryAssetLeave}
        />
      </TableBottomPaginationBlock>
      <Modal
        width={700}
        title={'Chỉnh sửa tài sản'}
        visible={isVisibleUpdateModal}
        maskClosable={false}
        onCancel={handleCancelUpdateModal}
        footer={null}
        destroyOnClose>
        {isVisibleUpdateModal && (
          <ManagementAssetWarehouseUpdate
            onCancel={handleCancelUpdateModal}
            templateId={selectedTemplateId}
          />
        )}
      </Modal>
      <Modal
        width={700}
        title={'Lịch sử'}
        visible={isVisibleHistoryModal}
        maskClosable={false}
        onCancel={handleCancelHistoryModal}
        footer={null}
        destroyOnClose>
        {isVisibleHistoryModal && (
          <ManagementAssetWarehouseHistory
            selectedHistoryId={selectedHistoryId}
            historyAssetName={historyAssetName}
            onCancel={handleCancelHistoryModal}
          />
        )}
      </Modal>
    </>
  )
}

export default inject(
  'managementAssetWarehouseStore',
  'authenticationStore',
  'selectPeopleStore'
)(observer(ManagementAssetWarehouseTable))
