import { action, observable } from 'mobx'
import { WorkRequest } from '../requests/WorkRequest'
import { ISO_DATE_FORMAT, WORK_STATUS } from '../constants'
import * as moment from 'moment'
import { UserGroupRequest } from '../requests/UserGroupRequest'
import { clone } from 'lodash'

class WorkStore {
  @observable workList = []
  @observable incompleteWorkList = []
  @observable currentViewingDetailWorkCode = ''
  @observable permission = undefined
  @observable totalWork = 0
  @observable workDetail = {}
  @observable groupVanThu = null
  @observable commentWorkList = undefined
  @observable workPendingCount = 0
  @observable workCompleteCount = 0

  defaultQueryParams = {
    page: 0,
    size: 10,
    title: null,
    from_date: null,
    to_date: null,
    status: undefined,
    work_type: undefined,
    permission: undefined,
    parent_id: null,
    is_important: null,
    is_unread: null,
    assignee: null,
  }

  @observable queryParams = clone(this.defaultQueryParams)

  @observable setIsReadTask = payload => {
    if (!payload.parent_code) {
      return (this.workList = this.workList.map(el =>
        el.code === payload.code ? { ...el, is_read: true } : el
      ))
    }
    this.workList = this.workList.map(el =>
      el.code !== payload.parent_code
        ? el
        : {
            ...el,
            subwork: el.subwork.map(item =>
              item.code !== payload.code
                ? item
                : {
                    ...item,
                    is_read: true,
                  }
            ),
          }
    )
  }

  @observable workTypeList = [
    {
      id: 'INTERNAL',
      name: 'Nội bộ trong Ban',
    },
    {
      id: 'CONSULT',
      name: 'Xin ý kiến các Ban',
    },
    {
      id: 'DOCUMENT',
      name: 'Từ văn bản',
    },
    {
      id: 'ELECTRONIC',
      name: 'Trình ký văn bản',
    },
    {
      id: 'DEPLOY',
      name: 'Từ nhiệm vụ',
    },
    {
      id: 'POLICY',
      name: 'Xin chủ trương',
    },
    {
      id: 'OTHER',
      name: 'Khác',
    },
  ]

  @observable workTypeListForSearch = [
    {
      id: 'INTERNAL,CONSULT,POLICY,OTHER,ELECTRONIC',
      name: 'Công việc chung',
    },
    {
      id: 'INTERNAL',
      name: 'Nội bộ trong Ban',
    },
    {
      id: 'CONSULT',
      name: 'Xin ý kiến các Ban',
    },
    {
      id: 'ELECTRONIC',
      name: 'Trình ký văn bản',
    },
    {
      id: 'POLICY',
      name: 'Xin chủ trương',
    },
    {
      id: 'OTHER',
      name: 'Khác',
    },
  ]

  @action clearQueryParams = () => {
    this.queryParams = clone(this.defaultQueryParams)
  }

  @action setQueryParams = payload => {
    this.queryParams = payload
  }

  @action getWorkList = cancelToken => {
    return new Promise((resolve, reject) => {
      let params = clone(this.queryParams)
      WorkRequest.getWorkList(params, cancelToken)
        .then(response => {
          if (!response) {
            return resolve(null)
          }
          this.clearWorkList()
          resolve(response.data)
          this.totalWork = response.data.total_count

          //tạm thời hotfix lỗi gọi get list 2 lần làm duplicate list
          const isDiff = this.workList[0]?.code !== response.data.data[0]?.code
          if (isDiff) {
            this.workList = [...this.workList, ...response.data.data]
          } else {
            this.workList = response.data.data
          }

          this.workCompleteCount = response.data.complete_task
          this.workPendingCount = response.data.pending_task

          // console.log('perform getWorkList', toJS(this.workList))
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getAllWorkList = cancelToken => {
    return new Promise((resolve, reject) => {
      let params = {
        page: 0,
        size: 10000,
      }
      WorkRequest.getWorkList(params, cancelToken)
        .then(response => {
          if (!response) {
            return resolve(null)
          }
          this.clearWorkList()
          resolve(response.data)
          this.totalWork = response.data.total_count

          //tạm thời hotfix lỗi gọi get list 2 lần làm duplicate list
          const isDiff = this.workList[0]?.code !== response.data.data[0]?.code
          if (isDiff) {
            this.workList = [...this.workList, ...response.data.data]
          } else {
            this.workList = response.data.data
          }

          this.workCompleteCount = response.data.complete_task
          this.workPendingCount = response.data.pending_task

          // console.log('perform getWorkList', toJS(this.workList))
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getWorkList = cancelToken => {
    return new Promise((resolve, reject) => {
      let params = clone(this.queryParams)
      WorkRequest.getWorkList(params, cancelToken)
        .then(response => {
          if (!response) {
            return resolve(null)
          }
          this.clearWorkList()
          resolve(response.data)
          this.totalWork = response.data.total_count

          //tạm thời hotfix lỗi gọi get list 2 lần làm duplicate list
          const isDiff = this.workList[0]?.code !== response.data.data[0]?.code
          if (isDiff) {
            this.workList = [...this.workList, ...response.data.data]
          } else {
            this.workList = response.data.data
          }

          this.workCompleteCount = response.data.complete_task
          this.workPendingCount = response.data.pending_task

          // console.log('perform getWorkList', toJS(this.workList))
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getWorkListResponse = (cancelToken, params) => {
    return new Promise((resolve, reject) => {
      WorkRequest.getWorkList(params, cancelToken)
        .then(response => {
          if (!response) {
            return resolve(null)
          }
          this.totalWork = response.data.total_count
          this.workCompleteCount = response.data.complete_task
          this.workPendingCount = response.data.pending_task
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getIncompleteWorkList = () => {
    return new Promise((resolve, reject) => {
      WorkRequest.getWorkList(this.queryParams)
        .then(response => {
          this.incompleteWorkList = response.data.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  /** Create user */
  @action createWork = data => {
    return new Promise((resolve, reject) => {
      WorkRequest.createWork(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  @action uniqBy(a, key) {
    let seen = new Set()
    return a.filter(item => {
      let k = key(item)
      return seen.has(k) ? false : seen.add(k)
    })
  }

  // Click collapse get list subwork
  @action getListSubWork = work_code => {
    return new Promise((resolve, reject) => {
      WorkRequest.getWorkDetail(work_code)
        .then(response => {
          const indexWorkTarget = this.workList.findIndex(
            work => work.code === work_code
          )
          const newWorkList = [...this.workList]
          if (newWorkList[indexWorkTarget]) {
            newWorkList[indexWorkTarget].subwork = response.data.subwork
          }
          this.workList = newWorkList
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getWorkDetailResponse = (work_code, isProcedure) => {
    let params = { is_procedure: isProcedure ? true : false }
    return new Promise((resolve, reject) => {
      WorkRequest.getWorkDetail(work_code, params)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getWorkDetail = (work_code, isProcedure) => {
    let params = { is_procedure: isProcedure ? true : false }
    return new Promise((resolve, reject) => {
      WorkRequest.getWorkDetail(work_code, params)
        .then(response => {
          let workDetailData = response.data
          if (
            response.data.code !== work_code &&
            response.data.subwork.length > 0
          ) {
            workDetailData = response.data.subwork[0]
          }
          const complete_date = moment(workDetailData.complete_date).format(
            ISO_DATE_FORMAT
          )
          const due_date = moment(workDetailData.due_date).format(
            ISO_DATE_FORMAT
          )
          if (
            workDetailData.due_date &&
            workDetailData.status === WORK_STATUS.COMPLETE &&
            moment(complete_date).isAfter(due_date)
          ) {
            workDetailData = {
              ...workDetailData,
              overdue: true,
            }
          }
          this.workDetail = workDetailData
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action updateStatusWork = (work_code, status) => {
    return new Promise((resolve, reject) => {
      WorkRequest.updateStatusWork(work_code, status)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action setWorkList = newList => {
    this.workList = newList
  }

  @action deleteWork = work_code => {
    return new Promise((resolve, reject) => {
      WorkRequest.deleteWork(work_code)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateWork = (work_code, data) => {
    return new Promise((resolve, reject) => {
      WorkRequest.updateWork(work_code, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updatePeopleInvole = (work_code, data) => {
    return new Promise((resolve, reject) => {
      WorkRequest.updatePeopleInvole(work_code, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @action getCommentWorkList = work_code => {
    return new Promise((resolve, reject) => {
      WorkRequest.getCommentWorkList(work_code)
        .then(response => {
          this.commentWorkList = response.data.reverse()
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getCommentWorkListByCode = work_code => {
    return new Promise((resolve, reject) => {
      WorkRequest.getCommentWorkList(work_code)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createCommentWork = (work_code, data) => {
    return new Promise((resolve, reject) => {
      WorkRequest.createCommentWork(work_code, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteCommentWork = (work_code, id_comment) => {
    return new Promise((resolve, reject) => {
      WorkRequest.deleteCommentWork(work_code, id_comment)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getGroupVanThu = async () => {
    let response = await UserGroupRequest.getGroupVanThu()
    this.groupVanThu = response.data
  }

  @action clearWorkDetail = () => {
    this.workDetail = {}
  }

  @action setWorkDetail = workDetail => {
    this.workDetail = workDetail
  }

  @action setCurrentViewingDetailWorkCode = code => {
    this.currentViewingDetailWorkCode = code
  }

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }

  @action clearWorkStore = () => {
    this.workList.length = 0
    this.totalWork = 0
    this.workDetail = {}
    this.commentWorkList = undefined
    this.workCompleteCount = 0
    this.workPendingCount = 0
    this.groupVanThu = null
  }

  @action clearWorkList = () => {
    this.workList.length = 0
    this.totalWork = 0
  }
}

export default new WorkStore()
