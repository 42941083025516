import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, message, Row, Space, Spin } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { ButtonFooter } from '../WorkPage/PopupWorkForm/PopupWorkFormStyled'
import validator from '../../validator'

const ManagementAssetWarehouseUnitUpdate = props => {
  const [form] = Form.useForm()
  const { managementAssetWarehouseStore } = props
  const [isCreating, setIsCreating] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [warningMessage, setWarningMessage] = useState(null)
  const { managementAssetWarehouseType } = managementAssetWarehouseStore

  useEffect(() => {
    ;(async () => {
      await managementAssetWarehouseStore.getManagementAssetWarehouseAssetTypes()
    })()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await managementAssetWarehouseStore.getAssetTypeById(props.templateId)
      } catch (error) {
        console.error('Failed to fetch asset type:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [managementAssetWarehouseStore, props.templateId])

  useEffect(() => {
    form.setFieldsValue({
      description: managementAssetWarehouseType.description,
    })
  }, [managementAssetWarehouseType, form])

  const checkDuplicateAsset = name => {
    if (!name) {
      setWarningMessage('Vui lòng nhập tên loại văn phòng phẩm')
    } else if (name.trim() === '') {
      setWarningMessage('Vui lòng nhập ký tự, không được bỏ trống')
    } else {
      const normalizedDescription = name?.trim().toLowerCase()

      const existingAssetType =
        managementAssetWarehouseType?.description?.toLowerCase() !==
          normalizedDescription &&
        managementAssetWarehouseStore.managementAssetWarehouseTypes?.content?.find(
          type => type.description.toLowerCase() === normalizedDescription
        )
      if (existingAssetType) {
        setWarningMessage(`Loại văn phòng phẩm ${name} đã tồn tại`)
      } else {
        setWarningMessage('')
      }
    }
  }

  const onAssetNameChange = e => {
    const value = e.target.value
    form.setFieldsValue({ asset_name: value })
    const typeId = form.getFieldValue('asset_type_id')
    checkDuplicateAsset(value, typeId)
  }

  const validateAssetName = async (_, value) => {
    if (!value) {
      return Promise.reject('Vui lòng nhập tên loại văn phòng phẩm')
    }
    if (value.length > 500) {
      return Promise.reject('Tên văn phòng phẩm không được dài quá 500 ký tự')
    }
    if (!/^[A-Za-z0-9].*$/.test(value)) {
      return Promise.reject('Tên loại văn phòng phẩm không đúng')
    }
    const normalizedDescription = value.trim().toLowerCase()
    const existingAssetType =
      managementAssetWarehouseType?.description?.toLowerCase() !==
        normalizedDescription &&
      managementAssetWarehouseStore.managementAssetWarehouseTypes?.content?.find(
        type => type.description.toLowerCase() === normalizedDescription
      )
    if (existingAssetType) {
      return Promise.reject(`Loại văn phòng phẩm ${value} đã tồn tại.`)
    }
    return Promise.resolve()
  }

  const onFinish = useCallback(
    async value => {
      setIsCreating(true)
      try {
        const trimmedDescription = value.description.trim()
        const normalizedDescription = trimmedDescription.toLowerCase()
        const initialDescription = managementAssetWarehouseType.description
          .trim()
          .toLowerCase()

        const existingAssetType =
          managementAssetWarehouseType?.description?.toLowerCase() !==
            normalizedDescription &&
          managementAssetWarehouseStore.managementAssetWarehouseTypes?.content?.find(
            type => type.description.toLowerCase() === normalizedDescription
          )

        if (existingAssetType) {
          setWarningMessage(
            `Loại văn phòng phẩm ${value.description} đã tồn tại`
          )
          setIsCreating(false)
          return
        } else {
          setWarningMessage(null)
        }

        const payload = { description: trimmedDescription }
        await managementAssetWarehouseStore.updateAssetType(
          props.templateId,
          payload
        )
        message.success('Cập nhật loại văn phòng phẩm thành công')
        props.onCancel()
        await managementAssetWarehouseStore.getManagementAssetWarehouseAssetTypes()
        await managementAssetWarehouseStore.fetchManagementAssetTypeList()
      } catch (error) {
        console.error('Update failed:', error)
        message.error('Cập nhật thất bại')
        props.onCancel()
      } finally {
        setIsCreating(false)
      }
    },
    [
      managementAssetWarehouseStore,
      props,
      managementAssetWarehouseType.description,
    ]
  )

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Spin spinning={isLoading}>
        {!isLoading && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Loại văn phòng phẩm:"
                name="description"
                validateStatus={warningMessage ? 'error' : ''}
                rules={[
                  {
                    required: true,
                    validator: validateAssetName,
                  },
                  {
                    max: 100,
                    message: 'Loại văn phòng phẩm không được dài quá 100 ký tự',
                  },
                ]}>
                <Input
                  placeholder="Nhập tên loại văn phòng phẩm"
                  onChange={onAssetNameChange}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Spin>
      <ButtonFooter>
        <Space>
          <Button icon={<CloseOutlined />} danger onClick={props.onCancel}>
            Đóng
          </Button>
          <Button
            icon={<EditOutlined />}
            type="primary"
            htmlType="submit"
            loading={isCreating}>
            Lưu
          </Button>
        </Space>
      </ButtonFooter>
    </Form>
  )
}

export default inject(
  'managementAssetWarehouseStore',
  'selectPeopleStore'
)(observer(ManagementAssetWarehouseUnitUpdate))
