import {
  DeleteTwoTone,
  OrderedListOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Avatar,
  Button,
  Comment,
  Pagination,
  Popconfirm,
  Space,
  Tabs,
  message,
} from 'antd'
import { find } from 'lodash-es'
import { inject, observer } from 'mobx-react'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { blue } from '../../color'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'
import AddUserForm from '../../components/AddUserForm/AddUserForm'
import {
  TextWrap,
  trimOverlengthString,
} from '../../components/Common/CellText'
import TableComponent from '../../components/Common/TableComponent'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import UserAccountEditForm from '../../components/UserAccountEditForm'
import { apiUrl } from '../../config'
import { ACL_ACTION_TYPE } from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import authenticationStore from '../../stores/authenticationStore'
import positionStore from '../../stores/positionStore'
import roleStore from '../../stores/roleStore'
import { MODAL_MODE } from '../../ui_constants'
import {
  ListDepartmentContent,
  TabsDepartmentWrapper,
} from '../Contacts/ListDepartment/ListDepartmentStyled'
import {
  ButtonItem,
  ButtonItemWrapper,
} from '../UserGroupPage/ListUserGroup/ListUserGroupStyled'
import CreateDepartmentModal from './CreateDepartmentModal'
import SortDepartmentModal from './SortDepartmentModal/SortDepartmentModal'
import './index.css'

const { TabPane } = Tabs

const ManageDepartmentPage = props => {
  const { departmentStore, userStore, loadingAnimationStore } = props

  const { departmentList } = departmentStore

  const {
    userOfDepartmentListTotalCount,
    userOfDepartmentListPageIndex,
    userOfDepartmentListPageSize,
  } = userStore

  const [userOfDepartmentData, setUserOfDepartmentData] = useState([])
  const [currentDepartment, setCurrentDepartment] = useState()
  const [checkUpdate, setCheckUpdate] = useState(false)
  const [checkDel, setCheckDel] = useState(false)

  //create/edit department modal
  const [
    isVisibleCreateDepartmentModal,
    setIsVisibleCreateDepartmentModal,
  ] = useState(false)
  const [mode, setMode] = useState(null)

  //add user
  const [isVisibleAddUser, setIsVisibleAddUser] = useState(false)
  //edit user
  const [isVisibleEditUser, setIsVisibleEditUser] = useState(false)
  //sort department
  const [
    isVisibleSortDepartmentModal,
    setIsVisibleSortDepartmentModal,
  ] = useState(false)

  const tableColumn = [
    {
      title: 'Thông tin',
      width: 248,
      render: record => (
        <Comment
          author={
            <span style={{ textTransform: 'capitalize' }}>
              {record.name_lowercase}
            </span>
          }
          avatar={
            <Avatar
              style={{ backgroundColor: '#1890ff' }}
              src={
                record.imageId && `${apiUrl}/api/v1/images/${record.imageId}`
              }>
              {record?.name_uppercase.split('')[0].toString().toUpperCase()}
            </Avatar>
          }
        />
      ),
    },
    {
      title: 'ID',
      width: 100,
      render: record => record?.username,
    },
    // {
    //   title: 'Phân quyền',
    //   width: 160,
    //   render: record => record?.acl_group?.name ?? 'Mặc định',
    // },
    {
      title: 'Chức vụ',
      render: record => (record.position ? record?.position.name : 'Chưa rõ'),
    },
    {
      fixed: 'right',
      align: 'center',
      title: (
        <ButtonItemWrapper>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.create_employee__QUAN_LY_PHONG_BAN}>
            <ButtonItem title={'Thêm nhân viên'}>
              <UserOutlined
                style={{ color: blue }}
                onClick={() => showAddUser()}
              />
            </ButtonItem>
          </AccessControlAction>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.edit__QUAN_LY_PHONG_BAN}>
            <ButtonItem title={'Chỉnh sửa phòng ban'}>
              <SettingOutlined
                style={{ color: '#40A9FF' }}
                onClick={() => {
                  setIsVisibleCreateDepartmentModal(true)
                  setMode(MODAL_MODE.UPDATE)
                }}
              />
            </ButtonItem>
          </AccessControlAction>

          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.delete__QUAN_LY_PHONG_BAN}>
            <Popconfirm
              onConfirm={() => handleDelete()}
              okText={'Đồng ý'}
              cancelText={'Không'}
              okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xóa phòng ban này không?'}>
              <ButtonItem title={'Xóa'}>
                <DeleteTwoTone
                  twoToneColor={'#e74c3c'}
                  onClick={event => event.stopPropagation()}
                />
              </ButtonItem>
            </Popconfirm>
          </AccessControlAction>
        </ButtonItemWrapper>
      ),
      render: record => <></>,
    },
  ]

  const rowClassName = record => (record.status ? '' : 'disabled-row-table')

  const handleViewUserInfo = useCallback(
    userInfo => {
      userStore.setSelectedUser(userInfo)
      setIsVisibleEditUser(true)
    },
    [departmentStore, loadingAnimationStore, userStore]
  )

  const onChangeTabDepartment = async key => {
    try {
      loadingAnimationStore.setTableLoading(true)

      userStore.setFilter('userOfDepartmentListByDepartmentCode', key)
      userStore.setFilter('userOfDepartmentListPageIndex', 0)

      await loadUserList()

      setCurrentDepartment(find(departmentList, { code: key }))
    } catch (e) {
      message.error(e.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  const loadUserList = async () => {
    let response = await userStore.getUserListByDepartment()
    setUserOfDepartmentData(response.data)
  }

  const onChangePagination = async (pageIndex, pageSize) => {
    try {
      loadingAnimationStore.setTableLoading(true)

      userStore.setFilter('userOfDepartmentListPageIndex', pageIndex - 1)
      userStore.setFilter('userOfDepartmentListPageSize', pageSize)

      let response = await userStore.getUserListByDepartment()
      setUserOfDepartmentData(response.data)
    } catch (e) {
      message.warning('Lỗi lấy thông tin người dùng trong phòng ban!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  const handleDelete = async () => {
    if (userOfDepartmentListTotalCount === 0) {
      try {
        loadingAnimationStore.showSpinner(true)

        await departmentStore.delete(currentDepartment.code)

        await departmentStore.getAllDepartmentList(
          userStore.companyCodeCurrent ||
            authenticationStore.currentCompanyCode()
        )
        // if (departmentList.length > 0) {
        //   onChangeTabDepartment(departmentList[0].code)
        // }
        setCheckDel(true)
      } catch (e) {
        message.error(e.vi || 'Đã có sự cố xảy ra')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    } else {
      message.warning('Không thể xóa phòng ban đã có nhân viên')
    }
  }

  const showCreateDepartmentModal = () => {
    setIsVisibleCreateDepartmentModal(true)
    setMode(MODAL_MODE.CREATE)
  }

  const handleCloseCreateDepartmentModal = () => {
    setIsVisibleCreateDepartmentModal(false)
    setMode(null)
  }

  const showAddUser = () => {
    setIsVisibleAddUser(true)
  }

  const handleCancelAddUser = () => {
    setIsVisibleAddUser(false)
  }

  const handleCancelEditUser = () => {
    setIsVisibleEditUser(false)
  }

  const onRefreshListUser = () => {
    // console.log('onRefreshListUser')
    loadUserList()
  }

  const showSortDepartmentModal = () => {
    setIsVisibleSortDepartmentModal(true)
  }

  const handleCancelSortDepartmentModal = () => {
    setIsVisibleSortDepartmentModal(false)
  }

  const onRefreshListDepartment = async () => {
    loadingAnimationStore.showSpinner(true)
    await departmentStore.getAllDepartmentList(
      userStore.companyCodeCurrent || authenticationStore.currentCompanyCode()
    )
    loadingAnimationStore.showSpinner(false)
  }

  useEffect(() => {
    if (authenticationStore.currentUser) {
      loadingAnimationStore.showSpinner(true)

      Promise.all([
        departmentStore.getAllDepartmentList(
          userStore.companyCodeCurrent ||
            authenticationStore.currentCompanyCode()
        ),
        //for create user account
        positionStore.getPositionList(),
        roleStore.getRoles(),
      ])
        .then(async responses => {
          let firstDept = responses[0].data[0]
          setCurrentDepartment(firstDept)

          userStore.setFilter(
            'userOfDepartmentListByDepartmentCode',
            firstDept?.code
          )
          let response = await userStore.getUserListByDepartment()
          setUserOfDepartmentData(response.data)
        })
        .catch(e => {
          console.log(e)
          message.warning('Lỗi lấy thông tin phòng ban!').then()
        })
        .finally(() => {
          loadingAnimationStore.showSpinner(false)
        })
    }

    return () => {
      departmentStore.clearDepartmentList()
    }
  }, [
    authenticationStore.currentUser,
    userStore.companyCodeCurrent,
    checkUpdate,
    checkDel,
  ])

  // giống với cái useEffect ngay trên, nhưng không rõ tại sao viết,do cái useEffect này nên bị lấy sai data => tạm thời cmt
  // useEffect(() => {
  //   Promise.all([
  //     departmentStore.getAllDepartmentList(
  //       authenticationStore.currentCompanyCode()
  //     ),
  //     //for create user account
  //     positionStore.getPositionList(),
  //     roleStore.getRoles(),
  //   ])
  //     .then(async responses => {
  //       let firstDept = responses[0].data[0]
  //       setCurrentDepartment(firstDept)

  //       userStore.setFilter(
  //         'userOfDepartmentListByDepartmentCode',
  //         firstDept.code
  //       )
  //       let response = await userStore.getUserListByDepartment()
  //       setUserOfDepartmentData(response.data)
  //       setCheckDel(false)
  //     })
  //     .catch(e => {
  //       console.log(e)
  //       message.warning('Lỗi lấy thông tin phòng ban!').then()
  //     })
  //     .finally(() => {
  //       loadingAnimationStore.showSpinner(false)
  //     })
  // }, [checkUpdate || checkDel])

  const customCssRow = record => (record.status ? '' : 'customCssRow')

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Quản lý phòng ban</title>
        </Helmet>
        <PageTitle
          location={props.location}
          title={'Quản lý phòng ban'}
          hiddenGoBack>
          <Space direction={'horizontal'}>
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.sort__QUAN_LY_PHONG_BAN}>
              <Button
                icon={<OrderedListOutlined />}
                onClick={showSortDepartmentModal}>
                Sắp xếp
              </Button>
            </AccessControlAction>
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.create__QUAN_LY_PHONG_BAN}>
              <Button
                icon={<PlusOutlined />}
                onClick={showCreateDepartmentModal}
                type="primary">
                Tạo phòng ban
              </Button>
            </AccessControlAction>
          </Space>
        </PageTitle>
        <ContentBlockWrapper>
          <ListDepartmentContent>
            <TabsDepartmentWrapper
              tabPosition={'left'}
              activeKey={currentDepartment?.code}
              onChange={onChangeTabDepartment}>
              {departmentList.length !== 0 &&
                departmentList.map(department => {
                  return (
                    <TabPane
                      tab={
                        <>
                          <TextWrap>
                            {trimOverlengthString(department.name, 180)}
                          </TextWrap>
                        </>
                      }
                      key={department.code}>
                      <TableComponent
                        rowKey={record => record.code}
                        columns={tableColumn}
                        dataSource={userOfDepartmentData}
                        loading={loadingAnimationStore.tableLoading}
                        pagination={false}
                        rowClassName={customCssRow}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          padding: '15px 0',
                        }}>
                        <Pagination
                          onChange={onChangePagination}
                          hideOnSinglePage={true}
                          total={userOfDepartmentListTotalCount}
                          pageSize={userOfDepartmentListPageSize}
                          current={userOfDepartmentListPageIndex + 1}
                          showSizeChanger={false}
                          showLessItems
                        />
                      </div>
                    </TabPane>
                  )
                })}
            </TabsDepartmentWrapper>
          </ListDepartmentContent>
        </ContentBlockWrapper>
      </DashboardLayout>

      <CreateDepartmentModal
        setCheckUpdate={setCheckUpdate}
        isVisibleCreateDepartmentModal={isVisibleCreateDepartmentModal}
        mode={mode}
        handleCloseCreateDepartmentModal={handleCloseCreateDepartmentModal}
        currentDepartment={currentDepartment}
      />

      <AddUserForm
        currentDepartment={currentDepartment}
        modalVisible={isVisibleAddUser}
        onCancel={handleCancelAddUser}
        onRefreshListUser={onRefreshListUser}
      />

      <UserAccountEditForm
        modalVisible={isVisibleEditUser}
        onCancel={handleCancelEditUser}
        onRefreshListUser={onRefreshListUser}
      />

      <SortDepartmentModal
        departmentList={departmentList}
        isVisibleSortDepartmentModal={isVisibleSortDepartmentModal}
        onCancel={handleCancelSortDepartmentModal}
        handleCancelSortDepartmentModal={handleCancelSortDepartmentModal}
        onRefreshListDepartment={onRefreshListDepartment}
      />
    </>
  )
}

ManageDepartmentPage.propTypes = {}

export default memo(
  inject(
    'departmentStore',
    'userStore',
    'loadingAnimationStore'
  )(observer(ManageDepartmentPage))
)
