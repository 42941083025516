import React, { useEffect, useState } from 'react'
import { Button, Form, message, Modal, Space, TreeSelect } from 'antd'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { toJS } from 'mobx'
import { ASSIGNEE_TYPE } from '../../../../constants'
import utils from '../../../../utils'

const { SHOW_CHILD } = TreeSelect

const PopupUpdateAssignees = props => {
  const {
    isVisiblePopupUpdateAssignees,
    handleClosePopupUpdateAssignees,
    departmentStore,
    consultStore,
    chuTruongStore,
    loadingAnimationStore,
    authenticationStore,
    type,
  } = props

  const [form] = Form.useForm()

  const { currentUser } = authenticationStore
  const { departmentList } = departmentStore
  const { selectedConsult } = consultStore
  const { selectedPolicy } = chuTruongStore

  let handlerConsultList = []
  let followerConsultList = []
  let combinerPolicyList = []
  let followerPolicyList = []
  let createConsultList = []
  let createPolicyList = []
  let leaderPolicyList = []
  let filterCreateUserDepartmentList = []

  if (type === 'consult') {
    handlerConsultList = toJS(selectedConsult?.assignees).filter(
      el => el.permission === ASSIGNEE_TYPE.HANDLER
    )
    followerConsultList = toJS(selectedConsult?.assignees).filter(
      el => el.permission === ASSIGNEE_TYPE.FOLLOWER
    )
    createConsultList = toJS(selectedConsult?.assignees).filter(
      el => el.permission === ASSIGNEE_TYPE.CREATE
    )
    filterCreateUserDepartmentList = toJS(departmentList).filter(
      el => el.value !== createConsultList[0].assignee_code
    )
  }

  if (type === 'policy') {
    combinerPolicyList = toJS(selectedPolicy?.assignees).filter(
      el => el.permission === ASSIGNEE_TYPE.COMBINER
    )
    followerPolicyList = toJS(selectedPolicy?.assignees).filter(
      el => el.permission === ASSIGNEE_TYPE.FOLLOWER
    )
    createPolicyList = toJS(selectedPolicy?.assignees).filter(
      el => el.permission === ASSIGNEE_TYPE.CREATE
    )
    leaderPolicyList = toJS(selectedPolicy?.assignees).filter(
      el => el.permission === ASSIGNEE_TYPE.LEADER
    )
    filterCreateUserDepartmentList = toJS(departmentList).filter(
      el =>
        el.value !== createPolicyList[0].assignee_code &&
        el.value !== leaderPolicyList[0].assignee_code
    )
  }

  const [fieldsUpdateAssignees, setFieldsUpdateAssignees] = useState([
    {
      name: ['person_in_charge'],
      value: [],
    },
    {
      name: ['followers'],
      value: [],
    },
  ])

  useEffect(() => {
    if (type === 'consult') {
      setFieldsUpdateAssignees([
        {
          name: ['person_in_charge'],
          value: handlerConsultList.map(user => user.assignee_code),
        },
        {
          name: ['followers'],
          value: followerConsultList.map(user => user.assignee_code),
        },
      ])
    }
    if (type === 'policy') {
      setFieldsUpdateAssignees([
        {
          name: ['person_in_charge'],
          value: combinerPolicyList.map(user => user.assignee_code),
        },
        {
          name: ['followers'],
          value: followerPolicyList.map(user => user.assignee_code),
        },
      ])
    }
  }, [
    selectedConsult.assignees,
    isVisiblePopupUpdateAssignees,
    selectedPolicy.assignees,
  ])

  useEffect(() => {
    ;(async () => {
      loadingAnimationStore.setShowSpinInline(true)
      try {
        await departmentStore.getDepartmentWithUsers()
      } catch (err) {
        console.log(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setShowSpinInline(false)
      }
    })()
  }, [])

  const onFinish = async value => {
    const new_items = []
    const remove_items = []
    let listOrigin
    if (type === 'consult') {
      listOrigin = [...handlerConsultList, ...followerConsultList]
    }
    if (type === 'policy') {
      listOrigin = [...combinerPolicyList, ...followerPolicyList]
    }
    const listAfter = [
      ...value.person_in_charge.map(username => {
        if (type === 'consult') {
          return {
            assignee_code: username,
            assignee_type: ASSIGNEE_TYPE.USER,
            permission: ASSIGNEE_TYPE.HANDLER,
          }
        }
        if (type === 'policy') {
          return {
            assignee_code: username,
            assignee_type: ASSIGNEE_TYPE.USER,
            permission: ASSIGNEE_TYPE.COMBINER,
          }
        }
      }),
      ...value.followers.map(username => ({
        assignee_code: username,
        assignee_type: ASSIGNEE_TYPE.USER,
        permission: ASSIGNEE_TYPE.FOLLOWER,
      })),
    ]
    const checkListDuplicates = [...value.followers, ...value.person_in_charge]
    if (utils.checkForDuplicates(checkListDuplicates)) {
      return message.warning('Bạn đã chọn trùng người tham gia!')
    }
    listAfter.forEach(user => {
      if (
        !listOrigin
          .map(el => el.assignee_code + el.permission)
          .includes(user.assignee_code + user.permission)
      ) {
        // push new_items
        new_items.push({
          assignee_code: user.assignee_code,
          assignee_type: user.assignee_type,
          permission: user.permission,
        })
      }
    })
    listOrigin.forEach(user => {
      if (
        !listAfter
          .map(el => el.assignee_code + el.permission)
          .includes(user.assignee_code + user.permission)
      ) {
        // push remove_items
        remove_items.push({
          assignee_code: user.assignee_code,
          assignee_type: user.assignee_type,
          permission: user.permission,
        })
      }
    })
    const submitData = {
      new_items: new_items.filter(
        el => el.assignee_code !== currentUser?.username
      ),
      remove_items: remove_items.filter(
        el => el.assignee_code !== currentUser?.username
      ),
    }
    try {
      loadingAnimationStore.showSpinner(true)
      if (type === 'consult') {
        await consultStore.updateConsultAssignees(
          selectedConsult.code,
          submitData
        )
        await consultStore.getConsultDetail(selectedConsult.code)
      }
      if (type === 'policy') {
        await chuTruongStore.updatePolicyAssignees(
          selectedPolicy.code,
          submitData
        )
        await chuTruongStore.getPolicyDetail(selectedPolicy.code)
      }
      handleClosePopupUpdateAssignees()
      message.success('Cập nhật thành công!')
    } catch (err) {
      console.log(err.vi || 'Đã có lỗi xảy ra!')
      message.error(err.vi || 'Cập nhật thất bại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <Modal
      title="Chỉnh sửa người tham gia"
      footer={null}
      closable={false}
      visible={isVisiblePopupUpdateAssignees}>
      <Form
        layout={'vertical'}
        scrollToFirstError={true}
        form={form}
        fields={fieldsUpdateAssignees}
        onFinish={onFinish}>
        {type === 'consult' ? (
          <Form.Item
            name={'person_in_charge'}
            label={'Người cho ý kiến'}
            rules={[
              { required: true, message: 'Vui lòng chọn người cho ý kiến!' },
            ]}>
            <TreeSelect
              treeDataSimpleMode
              treeData={filterCreateUserDepartmentList}
              style={{ width: '100%' }}
              showArrow
              allowClear
              showSearch
              filterTreeNode
              treeNodeFilterProp={'name'}
              treeCheckable={true}
              showCheckedStrategy={SHOW_CHILD}
              placeholder={'Chọn người cho ý kiến'}
            />
          </Form.Item>
        ) : (
          <Form.Item name={'person_in_charge'} label={'Người cho phối hợp'}>
            <TreeSelect
              treeDataSimpleMode
              treeData={filterCreateUserDepartmentList}
              style={{ width: '100%' }}
              showArrow
              allowClear
              showSearch
              filterTreeNode
              treeNodeFilterProp={'name'}
              treeCheckable={true}
              showCheckedStrategy={SHOW_CHILD}
              placeholder={'Chọn người phối hợp'}
            />
          </Form.Item>
        )}

        <Form.Item label={'Người theo dõi'} name={'followers'}>
          <TreeSelect
            treeDataSimpleMode
            treeData={filterCreateUserDepartmentList}
            style={{ width: '100%' }}
            showArrow
            allowClear
            showSearch
            filterTreeNode
            treeNodeFilterProp={'name'}
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={'Chọn người theo dõi'}
          />
        </Form.Item>
        <Space
          style={{
            marginTop: 20,
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Button
            icon={<CloseOutlined />}
            onClick={handleClosePopupUpdateAssignees}
            danger>
            Huỷ bỏ
          </Button>
          <Button icon={<CheckOutlined />} htmlType={'submit'} type={'primary'}>
            Đồng ý
          </Button>
        </Space>
      </Form>
    </Modal>
  )
}

PopupUpdateAssignees.propTypes = {}

export default withRouter(
  inject(
    'loadingAnimationStore',
    'departmentStore',
    'consultStore',
    'authenticationStore',
    'chuTruongStore',
  )(observer(PopupUpdateAssignees)),
)
