import {
  Checkbox,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  message,
} from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import EmptyContent from '../../../components/EmptyContent'
import { DATE_FORMAT_LIST, ISO_DATE_FORMAT } from '../../../constants'
import utils from '../../../utils'

const { RangePicker } = DatePicker
const { Option } = Select

const AdvanceFilterBlock = props => {
  const {
    bookStore,
    loadingAnimationStore,
    authorityStore,
    handleChangeParams,
    internalDocumentStore,
  } = props

  const { queryParamsOutgoing } = internalDocumentStore
  const { bookGroupList, bookListOfGroup } = bookStore
  const { authorityIssuedsList } = authorityStore

  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)

  const [loadingBook, setLoadingBook] = useState(false)
  const [valueAuthorityIssued, setValueAuthorityIssued] = useState([])
  useEffect(() => {
    if (
      queryStringParse.authority_name &&
      queryStringParse.authority_name?.trim() !== ''
    ) {
      setValueAuthorityIssued([queryStringParse.authority_name])
    }
  }, [])

  const onSearchTitle = title => {
    handleChangeParams({ title: title ? title.trim() : null })
  }

  const onChangeDateIssued = value => {
    handleChangeParams({
      from_date_issued: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_date_issued: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    })
  }
  const onChangeDocumentType = document_type => {
    handleChangeParams({
      document_type: document_type,
    })
  }
  const onChangeUrgencyLevel = urgency_level => {
    handleChangeParams({
      urgency_level: urgency_level,
    })
  }

  const onSearchAuthorityIssued = value => {
    setValueAuthorityIssued(value.length > 0 ? [value[value.length - 1]] : [])
    handleChangeParams({
      authority_name: value.length > 0 ? value[value.length - 1] : null,
    })
  }

  // const onSearchDocumentNumber = document_number => {
  //   internalDocumentStore.setQueryParamsOutgoing({
  //     ...queryParamsOutgoing,
  //     page: 0,
  //     document_number: document_number && document_number.trim(),
  //   })
  //   const queryStringStringify = queryString.stringify(
  //     {
  //       ...queryStringParse,
  //       page: 0,
  //       size: 10,
  //       document_number: document_number ? document_number.trim() : null,
  //     },
  //     {
  //       skipNull: true,
  //     }
  //   )
  //   history.replace(
  //     `/internal-document/outgoing-document?${queryStringStringify}`
  //   )
  // }

  const onSearchSigner = signer => {
    handleChangeParams({
      signer: signer ? signer.trim() : null,
    })
  }

  const handleChangeUnread = event => {
    let only_unread = event?.target?.checked ? true : false
    handleChangeParams({ only_unread: only_unread })
  }

  useEffect(() => {
    if (!queryStringParse.book_group_id) return
    ;(async () => {
      try {
        await bookStore.getBookByBookGroupId(queryStringParse.book_group_id)
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      }
    })()
  }, [])

  const onChangeBookGroupId = async book_group_id => {
    if (!book_group_id) {
      bookStore.clearBookListOfGroup()
      handleChangeParams({ book_group_id: null, book_id: null })
      return
    }
    setLoadingBook(true)
    handleChangeParams({ book_id: null, book_group_id: book_group_id })
    try {
      await bookStore.getBookByBookGroupId(book_group_id)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setLoadingBook(false)
    }
  }

  const onChangeBookId = book_id => {
    handleChangeParams({ book_id: book_id })
  }

  const filterSelectOption = (input, option) => {
    return (
      utils
        .nonAccentVietnamese(option.name)
        .indexOf(utils.nonAccentVietnamese(input)) >= 0
    )
  }

  return (
    <div style={{ marginBottom: 16 }}>
      <Row type={'flex'} gutter={15}>
        <Col xs={24} sm={24} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Trích yếu / Số hiệu</span>
            <Input.Search
              onSearch={value => onSearchTitle(value)}
              placeholder={'Nhập từ khóa tìm kiếm'}
              allowClear
              defaultValue={queryStringParse.title}
            />
          </Space>
        </Col>

        <Col xs={24} sm={24} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Nhóm sổ văn bản</span>
            <Select
              style={{ width: '100%' }}
              // loading={loadingAnimationStore.tableLoading}
              allowClear
              onChange={onChangeBookGroupId}
              defaultValue={
                queryStringParse.book_group_id &&
                parseInt(queryStringParse.book_group_id)
              }
              showSearch
              notFoundContent={<EmptyContent />}
              optionFilterProp={'name'}
              placeholder={'Chọn nhóm sổ văn bản'}>
              {bookGroupList.map(book => (
                <Option key={book.id} value={book.id} name={book.name}>
                  {book.name}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Sổ văn bản</span>
            <Select
              style={{ width: '100%' }}
              loading={loadingBook}
              allowClear
              value={
                queryParamsOutgoing.book_id &&
                parseInt(queryParamsOutgoing.book_id)
              }
              onChange={onChangeBookId}
              showSearch
              notFoundContent={<EmptyContent />}
              filterOption={true}
              optionFilterProp={'name'}
              disabled={!queryStringParse.book_group_id}
              placeholder={'Chọn sổ văn bản'}>
              {bookListOfGroup.map(book => (
                <Option key={book.id} value={book.id} name={book.name}>
                  {book.name}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Ngày ban hành</span>
            <RangePicker
              onChange={onChangeDateIssued}
              defaultValue={
                queryStringParse.from_date_issued &&
                queryStringParse.to_date_issued
                  ? [
                      moment(queryStringParse.from_date_issued),
                      moment(queryStringParse.to_date_issued),
                    ]
                  : null
              }
              placeholder={['Từ ngày', 'Đến ngày']}
              format={DATE_FORMAT_LIST}
              style={{ width: '100%' }}
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Loại văn bản</span>
            <Select
              style={{ width: '100%' }}
              allowClear
              onChange={onChangeDocumentType}
              defaultValue={queryStringParse.document_type}
              notFoundContent={<EmptyContent />}
              placeholder={'Chọn loại văn bản'}>
              <Option value={'020'}>Báo cáo</Option>
              <Option value={'021'}>Công văn</Option>
              <Option value={'022'}>Quyết định</Option>
              <Option value={'023'}>Thông báo - Giấy mời</Option>
              <Option value={'024'}>Tờ trình</Option>
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Mức độ xử lý</span>
            <Select
              style={{ width: '100%' }}
              allowClear
              onChange={onChangeUrgencyLevel}
              defaultValue={queryStringParse.urgency_level}
              notFoundContent={<EmptyContent />}
              placeholder={'Chọn độ khẩn'}>
              <Option value={'001'}>Thường</Option>
              <Option value={'002'}>Quan trọng</Option>
              <Option value={'003'}>Rất quan trọng</Option>
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Bộ phận dự thảo</span>
            <Select
              style={{ width: '100%' }}
              mode="tags"
              value={valueAuthorityIssued}
              onInputKeyDown={() => setValueAuthorityIssued([])}
              onChange={onSearchAuthorityIssued}
              filterOption={filterSelectOption}
              allowClear
              showSearch
              showArrow
              optionFilterProp={'name'}
              notFoundContent={<EmptyContent />}
              placeholder={'Chọn hoặc nhập bộ phận dự thảo'}>
              {authorityIssuedsList.map(authorityIssued => (
                <Option
                  name={authorityIssued.name}
                  value={authorityIssued.name}
                  key={authorityIssued.id}>
                  {authorityIssued.name}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Người ký</span>
            <Input.Search
              placeholder={'Người ký'}
              onSearch={value => onSearchSigner(value)}
              defaultValue={queryStringParse.signer}
              allowClear
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Trạng thái văn bản</span>
            <Checkbox
              // checked={queryStringParse.only_unread == 'true' ? true : false}
              checked={queryParamsOutgoing?.only_unread === 'true' || queryParamsOutgoing?.only_unread === true}
              onChange={handleChangeUnread}>
              Hiển thị văn bản chưa đọc
            </Checkbox>
          </Space>
        </Col>
      </Row>
    </div>
  )
}

AdvanceFilterBlock.propTypes = {}

export default inject(
  'bookStore',
  'loadingAnimationStore',
  'authorityStore',
  'internalDocumentStore'
)(observer(AdvanceFilterBlock))
