import {
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Select,
  InputNumber,
  Spin,
  Tooltip,
  Tag,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { ButtonFooter } from '../WorkPage/PopupWorkForm/PopupWorkFormStyled'
import { SelectList } from './TextShoppingCouponStylePage'
import TextArea from 'antd/es/input/TextArea'
import Title from 'antd/lib/skeleton/Title'
import { ASSIGNEE_TYPE } from '../../constants'
import { blue } from '../../color'
const { Option } = Select
import { AvatarHasName } from '../../components/Common/Avatar'
import SelectPeoplePopup from '../../components/SelectPeoplePopup'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import validator from '../../validator'
import TinyMCE from '../../components/TinyMCE'

const ShoppingCouponUpdate = props => {
  const {
    loadingAnimationStore,
    internalDocumentStore,
    selectPeopleStore,
    approvalTemplateStore,
    proposalStore,
    taskStore,
    ShoppingCouponStore,
    shoppingId,
    userStore,
    handleChangeTitle,
  } = props
  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleRemoveSelect,
    clearSelected,
    clearSelectUserData,
  } = selectPeopleStore

  const { getShoppingCouponDetail } = ShoppingCouponStore

  const [textareaValue, setTextareaValue] = useState('')
  const [formValues, setFormValues] = useState({})
  const [followerList, setFollowerList] = useState([])
  const [approvalTemplateList, setApprovalTemplateList] = useState(null)
  const [detailShopping, setDetailShopping] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [allUserr, setAllUserr] = useState([])
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const finalUserData = [...allUsers, ...allUserr]

  useEffect(() => {
    ;(async () => {
      await clearSelectUserData()
      await setFollowerList([])
      await clearSelected()
      await loadApprovalTemplateList()
      setApprovalTemplateList(approvalTemplateStore.approvalTemplateList)
    })()
  }, [])
  const loadApprovalTemplateList = async () => {
    try {
      await approvalTemplateStore.getApprovalTemplateListSearch(
        'template_type=asset'
      )
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
    }
  }

  useEffect(() => {
    userStore
      .getMentionUserList(true)
      .then(response => {
        setAllUsers(
          response.data.userDetails.map(user => {
            return {
              id: user.userCode,
              fullName: user.fullName,
              username: user.user_name,
              image: user.image,
            }
          })
        )
      })
    return () => userStore.clearStore()
  }, [userStore])

  useEffect(() => {
    userStore
      .getMentionUserList()
      .then(response => {
        setAllUserr(
          response.data.userDetails.map(user => {
            return {
              id: user.userCode,
              fullName: user.fullName,
              username: user.user_name,
              image: user.image,
            }
          })
        )
      })
    return () => userStore.clearStore()
  }, [userStore])

  useEffect(() => {
    fetchData(shoppingId)
  }, [shoppingId]) 

  useEffect(() => {
    if (finalUserData.length > 0 && !isDataLoaded) {       
      selectedFollowerList(shoppingId)
        .then(() => {
          setIsDataLoaded(true); 
          setIsLoading(false)
        })
        .catch(error => {
          console.error('Lỗi khi tải danh sách follower:', error);
        });
    }
  }, [finalUserData, shoppingId, isDataLoaded]);

  useEffect(() => {
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER_PROCUREMENT],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER_PROCUREMENT],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER_PROCUREMENT],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const fetchData = async id => {
    setIsLoading(true)
    try {
      const response = await getShoppingCouponDetail(id)
      setDetailShopping(response.data)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const selectedFollowerList = async id=> {
      let followerList = []
      const response = await getShoppingCouponDetail(id)
      response.data.followerList.forEach(follower => {
          if (follower !== '') {
            followerList.push({
            assignee_type: ASSIGNEE_TYPE.USER,
            full_name: getUserByUsername(follower),
            id: follower,
            image_id: getUserByUsername(follower),
            permission: ASSIGNEE_TYPE.FOLLOWER_PROCUREMENT,
          })
        }        
      })
  
      if (followerList.length > 0) {
        selectPeopleStore.setSelectUserData({
          ...selectUserData,
          FOLLOWER_PROCUREMENT: followerList,
        })
      }
  
      return () => {
        selectPeopleStore.clearSelectUserData('FOLLOWER_PROCUREMENT')
      }
  }

  function getUserByUsername(username) {
    const user = finalUserData?.find(user => user.username === username)
    return user ? capitalizeFirstLetter(user.fullName) : null
  }

  function capitalizeFirstLetter(string) {
    return string
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const handleChangeDescription = (value) => {
    ShoppingCouponStore.setDescription(value)
  }

  const onFinish = async values => {
    setIsUpdating(true)
    const formData = new FormData()
    formData.append('title', formValues.title)
    formData.append('content', ShoppingCouponStore.description)
    formData.append(
      'followerList',
      followerList.map(follower => follower.id).join(',')
    )

    try {
      const response = await ShoppingCouponStore.getShoppingCouponDetail(shoppingId)
      const data = response.data.followerList
      await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
      const res = await ShoppingCouponStore.getShoppingCouponDetail(shoppingId)
      props.onCancel()
      message.success('Cập nhật thành công!')
      props.onUpdateSuccess()
      handleChangeTitle(res.data.title)
      await ShoppingCouponStore.sendNotificationFollower(shoppingId, data)
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    } finally {
      props.onUpdated()
      setIsUpdating(false)
    }
  }

  return (
    <>
    {detailShopping && (
      <Form
        onFinish={onFinish}
        layout="vertical"
        onValuesChange={changedValues =>
          setFormValues(prevValues => ({ ...prevValues, ...changedValues }))
        }
        initialValues={{
          title: detailShopping.title, 
          'content-editor': detailShopping.content 
        }}>
        
          <>
            <Form.Item
              label="Tiêu đề"
              name="title"
              rules={[
                { required: true, message: 'Vui lòng nhập tiêu đề' },
                { max: 500, message: 'Tiêu đề không được dài quá 500 kí tự!' },
                { validator: validator.validateInputString },
              ]}>
              <Input
                onChange={e =>
                  setFormValues({ ...formValues, title: e.target.value })
                }
              />
            </Form.Item>

            <Form.Item
              label="Nội dung"
              name="content-editor"
              rules={[
                { required: true, message: 'Vui lòng nhập nội dung' },
                { validator: validator.validateInputString },
              ]}>
              <TinyMCE
                valueChange={detailShopping.content}
                onChange={handleChangeDescription}
              />
            </Form.Item>

            <Form.Item name="followers">
              <label
                style={{
                  display: 'inline-block',
                  margin: '15px 8px 25px 0',
                }}>
                Người theo dõi:
              </label>

              <Space>
                <Tooltip title="Chọn người dùng" color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() =>
                      handleOpenSelectUserDepartmentPopup(
                        ASSIGNEE_TYPE.FOLLOWER_PROCUREMENT
                      )
                    }
                  />
                </Tooltip>
              </Space>
              
              <SelectList>
                {followerList.map(el => (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'}
                    key={el.id}
                    closable
                    onClose={() => handleRemoveSelect(el)}>
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.full_name}
                      icon={
                        el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT ? (
                          <BankOutlined />
                        ) : el.assignee_type === ASSIGNEE_TYPE.GROUP ? (
                          <TeamOutlined />
                        ) : null
                      }
                    />
                  </Tag>
                ))}
              </SelectList>
            </Form.Item>

            <ButtonFooter>
              <Space>
                <Button
                  icon={<CloseOutlined />}
                  danger
                  onClick={props.onCancel}>
                  Hủy bỏ
                </Button>
                <Button
                  icon={<EditOutlined />}
                  type={'primary'}
                  htmlType={'submit'}
                  loading={isUpdating}>
                  Cập nhật
                </Button>
              </Space>
            </ButtonFooter>
          </>
       
      </Form> 
    )}
      <SpinnerInlineComponent
        sizeSpin={'small'}
        tip="Đang tải dữ liệu..."
        isLoading={isLoading}
        backGround={'#ffffffc7'}
      />
      <SelectPeoplePopup
        noLoadSelectData={false}
        disableSelectDepartment
        isOpenCollapse={true}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        isUserDisableList={false}
      />
    </>
  )
}
export default inject(
  'managementAssetWarehouseStore',
  'selectPeopleStore',
  'approvalTemplateStore',
  'userStore'
)(observer(ShoppingCouponUpdate))
