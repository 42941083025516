import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import DashboardLayout from '../../layouts/DashboardLayout'
import TabHeader from '../../components/reports/TabHeader'
import {
  Button,
  Divider,
  Empty,
  message,
  Popconfirm,
  Tooltip,
  Pagination,
  Select,
  DatePicker,
  Row,
  Col,
  Input,
  Spin,
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import ReportCreateModal from './ReportCreateModal'
import TableComponent from '../../components/Common/TableComponent'
import ReportUpdateModal from './ReportUpdateModal'
import { TableBottomPaginationBlock } from '../../components/Common/Table'
import { render } from 'less'
import { AvatarHasName } from '../../components/Common/Avatar'
import moment from 'moment'
import { debounce } from 'lodash'
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'

const { Option } = Select

const ReportPage = ({
  history,
  reportStore,
  departmentStore,
  riskStore,
  userStore,
  loadingAnimationStore,
  accountStore,
  documentAccessibilityStore,
  authenticationStore,
}) => {
  const {
    type,
    getReportList,
    reportList,
    deleteReport,
    reportLists,
    total,
    getReportLists,
  } = reportStore
  const [isLoading, setIsLoading] = useState(false)
  const [selectedField, setSelectedField] = useState(null)
  const [showAddReport, setShowAddReport] = useState(false)
  const [showUpdateReport, setShowUpdateReport] = useState(false)
  const [editReport, setEditReport] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalReports, setTotalReports] = useState(0)
  const { departmentList } = departmentStore
  const { getMentionUserList, mentionUserList } = userStore
  const [allUsers, setAllUsers] = useState([])
  const [allUserr, setAllUserr] = useState([])

  useEffect(() => {
    // Check initial values and set selectedField accordingly
    if (editReport?.cqvp_code) {
      setSelectedField('cqvp_code')
    } else if (editReport?.dvpt_code) {
      setSelectedField('dvpt_code')
    } else if (editReport?.nddpv_code) {
      setSelectedField('nddpv_code')
    }
  }, [editReport])
  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    setIsLoading(true)
    const fetchMentionUserList1 = userStore
      .getMentionUserList(true)
      .then(response => {
        return response.data.userDetails.map(user => ({
          id: user.userCode,
          fullName: user.fullName,
          username: user.user_name,
          image: user.image,
        }))
      })

    const fetchMentionUserList2 = userStore
      .getMentionUserList()
      .then(response => {
        return response.data.userDetails.map(user => ({
          id: user.userCode,
          fullName: user.fullName,
          username: user.user_name,
          image: user.image,
        }))
      })

    Promise.all([fetchMentionUserList1, fetchMentionUserList2])
      .then(([userList1, userList2]) => {
        setAllUsers(userList1)
        setAllUserr(userList2)
      })
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
        setIsLoading(false)
      })

    return () => userStore.clearStore()
  }, [userStore, reportLists])

  // useEffect(() => {
  //   ;(async () => {
  //     setIsLoading(true)
  //     try {
  //       await departmentStore.getDepartmentList()
  //     } catch (err) {
  //       message.error(err.vi || 'Đã có lỗi xảy ra!')
  //     }
  //     setIsLoading(false)
  //   })()
  // }, [])

  const [dataRiskUnitsByType, setRiskUnitsByType] = useState([])
  const [dataRiskUnitsByTypeNDDPV, setRiskUnitsByTypeNDDPV] = useState([])
  const [isDataComplete, setIsDataComplete] = useState(false)
  const [searchParams, setSearchParams] = useState({
    title: '',
    code: null,
    createdAt: null,
  })
  const getRiskUnitsByTypeList = async type => {
    await riskStore.getRiskUnitsV2(type).then(response => {
      setRiskUnitsByType(response.data)
    })
  }
  const getRiskUnitsByTypeListNDDPV = async type => {
    await riskStore.getRiskUnitsV2(type).then(response => {
      setRiskUnitsByTypeNDDPV(response.data)
    })
  }
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(true)
  //     try {
  //       await Promise.all([
  //         getRiskUnitsByTypeList('DVPT'),
  //         getRiskUnitsByTypeListNDDPV('NDDPV'),
  //         departmentStore.getDepartmentList(),
  //       ])
  //     } catch (error) {
  //       console.log(error)
  //       message.error(error.vi || 'Đã có lỗi xảy ra!')
  //     }
  //     setIsLoading(false)
  //   }

  //   fetchData()
  // }, [])

  const [unitData, setUnitData] = useState([])
  const [departData, setDepartData] = useState([])

  useEffect(() => {
    setIsLoading(true)
    riskStore
      .getRiskUnitsV3()
      .then(responseUnit => {
        setUnitData(responseUnit.data)
        return departmentStore.getAllDepartmentListV2()
      })
      .then(responseDepart => {
        setDepartData(responseDepart.data)
      })
      .catch(error => {
        console.log(error)
        message.error(error.vi || 'Đã có lỗi xảy ra!')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const finalData2 = [...unitData, ...departData]

  // const finalData1 = [
  //   ...departmentList,
  //   ...dataRiskUnitsByType,
  //   ...dataRiskUnitsByTypeNDDPV,
  // ]

  const [finalData, setFinalData] = useState([])

  useEffect(() => {
    const processAndCombineData = () => {
      const uniqueData = new Map()
      ;[dataRiskUnitsByType, dataRiskUnitsByTypeNDDPV].forEach(array => {
        array.forEach(item => uniqueData.set(item.code, item))
      })
      departmentList.forEach(item => uniqueData.set(item.code, item))
      setFinalData(Array.from(uniqueData.values()))
    }

    processAndCombineData()
  }, [dataRiskUnitsByType, dataRiskUnitsByTypeNDDPV, departmentList])

  const finalUserData = [...allUsers, ...allUserr]

  useEffect(() => {
    const processReportLists = async () => {
      setIsLoading(true)

      const finalDataMap = finalData2.reduce((acc, item) => {
        acc[item.code] = item.name
        return acc
      }, {})

      const finalUserDataMap = finalUserData.reduce((acc, item) => {
        acc[item.username] = { image: item.image, fullName: item.fullName }
        return acc
      }, {})

      let allDataComplete = true

      const updatedReportLists = reportLists.map(record => {
        record.name =
          finalDataMap[record.nddpv_code] ||
          finalDataMap[record.dvpt_code] ||
          finalDataMap[record.cqvp_code] ||
          record.name

        if (record.create_user in finalUserDataMap) {
          const userMatch = finalUserDataMap[record.create_user]
          record.image = userMatch.image
          record.fullName = userMatch.fullName
        } else {
          allDataComplete = false
        }

        if (!record.name || !record.image || !record.fullName) {
          allDataComplete = false
        }

        return record
      })

      setIsDataComplete(allDataComplete)
      setIsLoading(false)
    }

    processReportLists()
  }, [finalUserData, reportLists])

  const handleCancelAddReport = useCallback(() => {
    setShowAddReport(false)
  }, [])

  const handleCancelUpdateReport = useCallback(() => {
    setShowUpdateReport(false)
    setEditReport(null)
  }, [])

  const loadReportList = async (page, size, params) => {
    try {
      setIsLoading(true)
      const response = await getReportLists({
        ...params,
        page: page - 1,
        size: size,
        type,
      })
      setTotalReports(response.data.totalPages)
    } finally {
      setIsLoading(false)
    }
  }

  const handleClearAllFilters = () => {
    setSearchParams({
      title: '',
      code: null,
      createdAt: null,
    })
    handleClearInput()
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const pageParam = urlParams.get('page')
    const sizeParam = urlParams.get('size')

    const initialPage = pageParam ? parseInt(pageParam, 10) : currentPage
    const initialSize = sizeParam ? parseInt(sizeParam, 10) : pageSize

    setCurrentPage(initialPage)
    setPageSize(initialSize)

    const params = new URLSearchParams({
      type: type,
      page: String(initialPage),
      size: String(initialSize),
    })
    history.replace(`/report?${params.toString()}`)
    ;(async () => {
      await loadReportList(initialPage, initialSize, searchParams)
    })()
  }, [history])

  useEffect(() => {
    const params = new URLSearchParams({
      type: type,
      page: String(currentPage),
      size: String(pageSize),
      ...searchParams,
    })
    history.replace(`/report?${params.toString()}`)
    loadReportList(currentPage, pageSize, searchParams)
  }, [currentPage, pageSize, searchParams, history, type])

  const fetchReportList = async (page, size) => {
    try {
      setIsLoading(true)
      const params = { page: page - 1, size: size, type }
      const response = await getReportLists(params)
      return response.data.content
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteReport = async code => {
    try {
      setIsLoading(true)
      await deleteReport(code)

      const reports = await fetchReportList(currentPage, pageSize)

      if (reports.length === 0) {
        if (currentPage > 1) {
          const previousPage = currentPage - 1
          setCurrentPage(previousPage)

          const params = new URLSearchParams({
            type,
            page: String(previousPage),
            size: String(pageSize),
          })
          history.replace(`/report?${params.toString()}`)

          await loadReportList(previousPage, pageSize)
        } else {
          await loadReportList(currentPage, pageSize)
        }
      } else {
        await loadReportList(currentPage, pageSize)
      }

      message.success('Đã xóa!')
    } catch (err) {
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  // useEffect(() => {
  //   const debouncedLoad = debounce(() => {
  //     setCurrentPage(1)
  //     loadReportList(1, pageSize, searchParams)
  //   }, 1000)

  //   debouncedLoad()

  //   return () => {
  //     debouncedLoad.cancel()
  //   }
  // }, [searchParams])

  const handleClickEditReport = record => {
    setEditReport(record)
    setShowUpdateReport(true)
  }

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page)
    setPageSize(pageSize)
    const params = new URLSearchParams({
      type,
      page: String(page),
      size: String(pageSize),
    })
    history.push(`/report?${params.toString()}`)
  }

  const debouncedLoadReportList = useCallback(
    debounce((page, size, params) => {
      loadReportList(page, size, params)
    }, 700),
    []
  )
  const [searchValue, setSearchValue] = useState('')
  const handleSearch = () => {
    setCurrentPage(1)
    setSearchParams(prevParams => ({
      ...prevParams,
      title: searchValue || null,
    }))
  }

  const handleClearInput = () => {
    setSearchValue('')
    setSearchParams(prevParams => ({
      ...prevParams,
      title: null,
    }))
    setCurrentPage(1)
  }

  const handleClearDate = () => {
    setSearchParams(prevParams => ({
      ...prevParams,
      createdAt: null,
    }))
    setCurrentPage(1)
  }

  const handleInputChange = e => {
    const { value } = e.target
    setSearchValue(value)
    if (value === '') {
      handleClearInput()
    }
  }

  const handleInputPressEnter = () => {
    handleSearch()
  }

  const handleSelectChange = value => {
    setCurrentPage(1)
    if (!value) {
      setSearchParams(prevParams => ({
        ...prevParams,
        code: null,
      }))
    } else {
      setSearchParams(prevParams => ({
        ...prevParams,
        code: value?.value,
      }))
    }
  }

  const handleDateChange = (date, dateString) => {
    setCurrentPage(1)
    setIsLoading(true)
    const formatDate = date ? date.format('YYYY-MM-DD') : null
    setSearchParams(prevParams => ({
      ...prevParams,
      createdAt: formatDate,
    }))
    setIsLoading(false)
    if (!date) {
      handleClearDate()
    }
  }
  const [dataDoc, setDataDoc] = useState([])
  useEffect(() => {
    const fetchDocumentAccessibilities = async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        const response = await documentAccessibilityStore.getDocumentAccessibilityByTypeAndAccess(
          'RISK_REPORT',
          'REPORT'
        )
        setDataDoc(response.data)
      } catch (error) {
        console.log(error)
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    }

    fetchDocumentAccessibilities()
  }, [documentAccessibilityStore, loadingAnimationStore])
  const tableColumns = [
    {
      title: 'Đơn vị',
      key: 'unit',
      render: record => <span>{record.name}</span>,
      width: '25%',
    },
    {
      title: 'Tiêu đề',
      key: 'title',
      render: record => <span>{record.title}</span>,
      width: '25%',
    },
    {
      title: 'Ngày tạo',
      width: '10%',
      align: 'center',
      render: record => {
        const date = new Date(record?.created_at || 0)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        const seconds = String(date.getSeconds()).padStart(2, '0')

        const formattedDate = `${day}/${month}/${year}`
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}>
            {formattedDate}
          </div>
        )
      },
    },
    {
      title: () => <div style={{}}>Người tạo</div>,
      width: '15%',
      render: record => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
          }}>
          <AvatarHasName imgId={record?.image} name={record?.fullName} />
        </div>
      ),
    },
    // {
    //   title: '',
    //   key: 'action',
    //   align: 'right',
    //   width: '8%',
    //   onCell: record => {
    //     return {
    //       onClick: event => {
    //         event.stopPropagation()
    //       },
    //     }
    //   },
    //   render: record => (
    //     <div>
    //       {authenticationStore.currentUser?.username === record.create_user && (
    //         <Tooltip title={'Sửa thông tin'}>
    //           <EditOutlined
    //             className={'anticon-color'}
    //             onClick={() => handleClickEditReport(record)}
    //           />
    //         </Tooltip>
    //       )}
    //       <Divider type={'vertical'} />
    //       <AccessControlAction
    //         aclActionType={ACL_ACTION_TYPE.delete__baocao__PKS_RUI_RO}>
    //         <div
    //           style={{ display: 'inline-block' }}
    //           onClick={e => e.stopPropagation()}>
    //           <Tooltip title={'Xoá'}>
    //             <Popconfirm
    //               onConfirm={() => handleDeleteReport(record.code)}
    //               okText={'Đồng ý'}
    //               cancelText={'Không'}
    //               okType={'danger'}
    //               icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
    //               title={'Bạn có muốn xoá báo cáo này Không?'}>
    //               <DeleteOutlined style={{ color: '#fc0000' }} />
    //             </Popconfirm>
    //           </Tooltip>
    //         </div>
    //       </AccessControlAction>
    //     </div>
    //   ),
    // },
  ]
  return (
    <DashboardLayout>
      <Helmet>
        <title>Báo cáo | Danh sách Báo cáo</title>
      </Helmet>
      <PageTitle location={location} title="Danh sách Báo cáo" hiddenGoBack>
        {dataDoc[0]?.status === true && (
          <Button
            onClick={() => {
              setShowAddReport(true)
            }}
            type={'primary'}
            icon={<PlusOutlined />}>
            Tạo mới
          </Button>
        )}
      </PageTitle>
      <ContentBlockWrapper style={{ minHeight: 600 }}>
        <TabHeader />
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <Input
              name="title"
              allowClear
              placeholder="Tìm kiếm theo tiêu đề"
              // value={searchParams.title}
              value={searchValue}
              onChange={handleInputChange}
              onPressEnter={handleInputPressEnter}
              onClear
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={6}>
            <Select
              name="code"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Tìm kiếm theo đơn vị"
              value={
                searchParams.code ? { value: searchParams.code } : undefined
              }
              onChange={handleSelectChange}
              style={{ width: '100%' }}
              labelInValue>
              {finalData2.map(item => (
                <Option key={item.code} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <DatePicker
              name="date"
              allowClear
              style={{ width: '100%' }}
              value={
                searchParams.createdAt
                  ? moment(searchParams.createdAt, 'YYYY-MM-DD')
                  : null
              }
              format={'DD/MM/YYYY'}
              onChange={handleDateChange}
              placeholder="Tìm kiếm theo ngày tạo"
            />
          </Col>
        </Row>
        <Spin tip="Đang tải dữ liệu..." spinning={isLoading}>
          <TableComponent
            onRow={record => {
              return {
                onClick: () =>
                  history.push(`/report/view/${record.code}?type=${type}`),
              }
            }}
            rowKey={record => record.code}
            dataSource={reportLists ? reportLists : []}
            columns={tableColumns}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={'Không có dữ liệu'}
                />
              ),
            }}
            loading={isLoading}
          />
        </Spin>
        <TableBottomPaginationBlock>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={total}
            onChange={handlePageChange}
          />
        </TableBottomPaginationBlock>
        {showAddReport && (
          <ReportCreateModal
            modalVisible={showAddReport}
            onCancel={handleCancelAddReport}
            isAdmin={true}
            typeCompany={type}
            loadAdminsList={''}
            onRefresh={() => loadReportList(currentPage, pageSize)}
            onClearFilters={handleClearAllFilters}
            editReport={null}
          />
        )}
        {showUpdateReport && (
          <ReportUpdateModal
            modalVisible={showUpdateReport}
            onCancel={handleCancelUpdateReport}
            editReport={editReport}
            onRefresh={() => loadReportList(currentPage, pageSize)}
            onClearFilters={handleClearAllFilters}
            finalUserData={finalUserData}
          />
        )}
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject(
  'reportStore',
  'departmentStore',
  'riskStore',
  'userStore',
  'loadingAnimationStore',
  'accountStore',
  'authenticationStore',
  'documentAccessibilityStore'
)(observer(ReportPage))
