import { Button, Col, Form, Input, Row, Select, message } from 'antd'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import RiskSteps from '../../components/RiskSteps'
import { systemName } from '../../config'
import { ASSIGNEE_TYPE } from '../../constants'
import { Container } from '../../layouts/Container/Container'
import DashboardLayout from '../../layouts/DashboardLayout'
import { handleFilterSelect } from '../../utils/filterSelect'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import { useUpdatingRiskExecutor } from '../RiskDetailPage/hooks/useUpdatingRiskExecutor'
import { useUpdatingUnitLeader } from '../RiskDetailPage/hooks/useUpdatingUnitLeader'
import { CreateTitle } from './RiskCreatePageStyled'
import { useCreatingRiskExecutor } from './hooks/useCreatingRiskExecutor'
import { useCreatingUnitLeader } from './hooks/useCreatingUnitLeader'

const { Option } = Select

const RiskCreatePage = props => {
  const {
    history,
    companyStore,
    selectPeopleStore,
    authenticationStore,
    loadingAnimationStore,
    riskStore,
    riskUnitUserStore,
    actionStore,
    departmentStore,
  } = props
  const [filter, setFilter] = useState({
    status: null,
    keyword: null,
    is_dvpt: null,
    is_nddpv: null,
  })
  useEffect(() => {
    loadCompanyList(filter)
  }, [filter])

  const loadCompanyList = async params => {
    try {
      await companyStore.getCompanyList(params || filter)
    } finally {
    }
  }
  const queryStringParse = queryString.parse(location.search)
  const { currentUser } = authenticationStore
  const { createRisk, riskUnitsV2 } = riskStore
  const { departmentList } = departmentStore
  const { dataActions, setIndexDetailAction } = actionStore
  const {
    selectUserData,
    assigneePopupType,
    clearSelectPeopleStore,
  } = selectPeopleStore

  const [selectUserList, setSelectUserList] = useState({
    unitLeader: [],
    VIMCLeader: [],
    KT_KTNB: [],
    HDQT: [],
    USER: [],
    lanh_dao_vimc: [],
    ban_kt_ktnb: [],
    ban_pc_qtrr_ban_kt_ktnb_vimc: [],
    ban_dieu_hanh_hdqt_vimc: [],
    tgd_ptgd: [],
    ban_kt_ktnb_vimc: [],
    hdqt_vimc: [],
    ban_dieu_hanh: [],
    ban_pc_qtrr_vimc: [],
    ban_cmnv_vimc: [],
    lanh_dao_ban: [],
    don_vi_thuc_hien_xu_ly_rui_ro_3: [],
    cqvp_I: [],
    chuyen_vien_thuc_hien_tuyen1: [],
    chuyen_vien_thuc_hien_cqvp_tuyen1: [],
  })
  const [current, setCurrent] = useState(0)

  const [form] = Form.useForm()

  useEffect(() => {
    ;(async () => {
      try {
        if (type !== 'CQVP') {
          await riskStore.getRiskUnitsV2(type)
        } else {
          await departmentStore.getDepartmentList()
        }
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
    return () => {
      riskStore.clearRiskStore()
      actionStore.clearActionStore()
      clearSelectPeopleStore()
    }
  }, [])

  useEffect(() => {
    if (!assigneePopupType) return
    setSelectUserList({
      ...selectUserList,
      unitLeader: [
        ...selectUserData[ASSIGNEE_TYPE.UNIT_LEADER].map(user => ({
          ...user,
          status: 'PENDING',
        })),
      ],
      VIMCLeader: [
        ...selectUserData[ASSIGNEE_TYPE.LEADER].map(user => ({
          ...user,
          status: 'PENDING',
        })),
      ],
      KT_KTNB: [
        ...selectUserData[ASSIGNEE_TYPE.KT_KTNB].map(user => ({
          ...user,
          status: 'PENDING',
        })),
      ],
      HDQT: [
        ...selectUserData[ASSIGNEE_TYPE.HDQT].map(user => ({
          ...user,
          status: 'PENDING',
        })),
      ],
      USER: [...selectUserData[ASSIGNEE_TYPE.USER]],
      lanh_dao_vimc: [...selectUserData[ASSIGNEE_TYPE.lanh_dao_vimc]],
      ban_kt_ktnb: [...selectUserData[ASSIGNEE_TYPE.ban_kt_ktnb]],
      ban_pc_qtrr_ban_kt_ktnb_vimc: [
        ...selectUserData[ASSIGNEE_TYPE.ban_pc_qtrr_ban_kt_ktnb_vimc],
      ],
      ban_dieu_hanh_hdqt_vimc: [
        ...selectUserData[ASSIGNEE_TYPE.ban_dieu_hanh_hdqt_vimc],
      ],
      tgd_ptgd: [...selectUserData[ASSIGNEE_TYPE.tgd_ptgd]],
      ban_kt_ktnb_vimc: [...selectUserData[ASSIGNEE_TYPE.ban_kt_ktnb_vimc]],
      hdqt_vimc: [...selectUserData[ASSIGNEE_TYPE.hdqt_vimc]],
      ban_dieu_hanh: [...selectUserData[ASSIGNEE_TYPE.ban_dieu_hanh]],
      ban_pc_qtrr_vimc: [...selectUserData[ASSIGNEE_TYPE.ban_pc_qtrr_vimc]],
      don_vi_thuc_hien_xu_ly_rui_ro_3: [
        ...selectUserData[ASSIGNEE_TYPE.don_vi_thuc_hien_xu_ly_rui_ro_3],
      ],
      lanh_dao_ban: [...selectUserData[ASSIGNEE_TYPE.lanh_dao_ban]],
      cqvp_I: [...selectUserData[ASSIGNEE_TYPE.cqvp_I]],
      // ban_cmnv_vimc: [...selectUserData[ASSIGNEE_TYPE.ban_cmnv_vimc]],
      chuyen_vien_thuc_hien_cqvp_tuyen1: [
        ...selectUserData[ASSIGNEE_TYPE.chuyen_vien_thuc_hien_cqvp_tuyen1],
      ],
    })
  }, [selectUserData])

  const onChangeSteps = useCallback(
    value => {
      setCurrent(value)
    },
    [setCurrent]
  )

  const [type, setType] = useState(queryStringParse.type)

  const {
    getTier1Executors,
    getTier2Executors,
    getTier3Executors,
    getTier1NDDPVgeneral,
  } = useCreatingRiskExecutor({
    type,
    currentUser,
    selectUserList,
    riskUnitUserStore,
  })
  const { getTier1UnitLeader } = useCreatingUnitLeader({
    type,
    selectUserList,
    riskUnitUserStore,
  })

  const getTier2NDDPVgeneral = () => {
    let tier2NDDPVgeneral
    if (type === 'NDDPV') {
      tier2NDDPVgeneral = [
        ...riskUnitUserStore.selectedNDDPVgeneral.tier2.map(executor => ({
          ...executor,
          status: 'CREATED',
        })),
      ]
    } else if (type === 'DVPT') {
      tier2NDDPVgeneral = [
        ...riskUnitUserStore.selectedDVPTprofessional.tier2.map(executor => ({
          ...executor,
          status: 'CREATED',
        })),
      ]
    } else if (type === 'CQVP') {
      tier2NDDPVgeneral = selectUserList.VIMCLeader.map(user => ({
        username: user.id,
        department_code: user.department_code,
        status: 'CREATED',
      }))
    }

    return tier2NDDPVgeneral
  }

  const { validateRiskExecutorInput } = useUpdatingRiskExecutor({
    loadingAnimationStore,
    riskStore,
    riskUnitUserStore,
    type,
  })
  const { validateUnitLeaderInput } = useUpdatingUnitLeader({
    type,
    loadingAnimationStore,
    riskStore,
    riskUnitUserStore,
  })

  const handleCreateRiskControl = async value => {
    // try {
    //   validateRiskExecutorInput()
    //   validateUnitLeaderInput()
    // } catch (error) {
    //   console.log(error)
    //   message.error(error.message)
    //   return
    // }

    const tier1RiskExecutors = getTier1Executors()
    const tier1UnitLeader = getTier1UnitLeader()
    const tier2NDDPVgeneral = getTier2NDDPVgeneral()
    const tier2RiskExecutors = getTier2Executors()
    const tier3RiskExecutors = getTier3Executors()
    const tier1NDDPVgeneral = getTier1NDDPVgeneral()

    const submitData = {
      ...value,
      type: type,
      tiers: [
        {
          name: 'Tuyến phòng vệ thứ nhất',
          level: 1,
          approvals: [
            {
              name:
                type === 'NDDPV'
                  ? 'Người đại diện phần vốn thực hiện phiếu'
                  : 'Chuyên viên thực hiện',
              level: 1,
              peoples: tier1RiskExecutors,
            },
            {
              name: 'Lãnh đạo đơn vị',
              level: 2,
              peoples: type === 'NDDPV' ? tier1NDDPVgeneral : tier1UnitLeader,
            },
          ],
          actions: dataActions.tier1.map(action => ({
            name: 'Rủi ro nhận diện 1',
            data: JSON.stringify(action),
          })),
        },
        {
          name: 'Tuyến phòng vệ thứ hai',
          level: 2,
          approvals: [
            {
              name: 'Đơn vị thực hiện xử lý rủi ro',
              level: 1,
              peoples: tier2RiskExecutors,
              // peoples: selectUserList.VIMCLeader?.map(user => ({
              //   username: user.id,
              //   department_code: user.department_code,
              //   status: 'CREATE',
              // })),
            },
            {
              name: `Lãnh đạo ${systemName}`,
              level: 2,
              peoples: tier2NDDPVgeneral,
            },
          ],
          actions: dataActions.tier2.map(action => ({
            name: 'Rủi ro nhận diện 2',
            data: JSON.stringify(action),
          })),
        },
        {
          name: 'Tuyến phòng vệ thứ ba',
          level: 3,
          approvals: [
            {
              name: 'Đơn vị thực hiện xử lý rủi ro',
              level: 1,
              peoples: tier3RiskExecutors,
            },
            {
              name: `Ban KTNB ${systemName}`,
              level: 2,
              peoples: selectUserList.KT_KTNB?.map(user => ({
                username: user.id,
                status: 'PENDING',
              })),
            },
            {
              name: 'Hội đồng quản trị',
              level: 3,
              peoples: selectUserList.HDQT?.map(user => ({
                username: user.id,
                status: 'PENDING',
              })),
            },
          ],
          actions: dataActions.tier3.map(action => ({
            name: 'Rủi ro nhận diện 3',
            data: JSON.stringify(action),
          })),
        },
        {
          name: 'Kết quả',
          level: 4,
          approvals: [],
          actions: dataActions.tier4.map(action => ({
            name: 'Rủi ro nhận diện 4',
            data: JSON.stringify(action),
          })),
        },
      ],
    }

    loadingAnimationStore.showSpinner(true)
    try {
      await createRisk(submitData)
      message.success(`Tạo phiếu rủi ro thành công`)
      clearSelectPeopleStore()
      // history.push(`/risk?type=${type}`)
      history.goBack()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạo mới phiếu rủi ro | {systemName} tạo mới phiếu rủi ro</title>
      </Helmet>
      <PageTitle location={props.location} title={'Thêm phiếu rủi ro'} />
      <ContentBlockWrapper>
        <Container maxWidth={1000}>
          <CreateTitle>Tạo mới phiếu kiểm soát rủi ro</CreateTitle>
          <div className={'btn-group-left'}>
            <Form
              scrollToFirstError={true}
              form={form}
              layout={'vertical'}
              onFinish={handleCreateRiskControl}>
              <Row type={'flex'} gutter={15}>
                <Col span={8}>
                  <Form.Item
                    name={'title'}
                    label={'Tiêu đề:'}
                    rules={[
                      { required: true, message: 'Vui lòng nhập tiêu đề!' },
                    ]}>
                    <Input placeholder={'Nhập tiêu đề'} />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    name={'risk_unit_code'}
                    label={'Đơn vị:'}
                    rules={[
                      { required: true, message: 'Vui lòng chọn đơn vị!' },
                    ]}>
                    <Select
                      allowClear
                      showSearch
                      filterOption={handleFilterSelect}
                      placeholder={'Chọn đơn vị'}>
                      {type !== 'CQVP'
                        ? Array.isArray(riskUnitsV2) &&
                          riskUnitsV2?.map(unit => (
                            <Option
                              key={unit.code}
                              name={'risk_unit_code'}
                              value={unit.code}>
                              {unit.name}
                            </Option>
                          ))
                        : Array.isArray(departmentList) &&
                          departmentList?.map(department => (
                            <Option
                              key={department.code}
                              name={'department_code'}
                              value={department.code}>
                              {department.name}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <RiskSteps
                current={current}
                type={type}
                setType={setType}
                onChangeSteps={onChangeSteps}
                createUser={currentUser}
                selectUserList={selectUserList}
              />
              <div style={{ textAlign: 'left' }}>
                <FormActionFooter>
                  <Button
                    onClick={() => {
                      history.goBack()
                    }}>
                    Huỷ bỏ
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    type={'primary'}
                    htmlType={'submit'}>
                    Lưu
                  </Button>
                </FormActionFooter>
              </div>
            </Form>
          </div>
        </Container>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject(
  'selectPeopleStore',
  'authenticationStore',
  'loadingAnimationStore',
  'riskStore',
  'riskUnitUserStore',
  'actionStore',
  'departmentStore',
  'companyStore'
)(observer(RiskCreatePage))
