import React, { memo } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'

const BiPage = props => {
  return (
    <DashboardLayout>
      <Helmet>
        <title>Màn hình báo cáo</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={'BI'}
        hiddenGoBack></PageTitle>
      <ContentBlockWrapper styles={{ height: '100%' }}></ContentBlockWrapper>
    </DashboardLayout>
  )
}

BiPage.propTypes = {}

export default memo(inject()(observer(BiPage)))
