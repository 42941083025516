import React, { useCallback, useEffect, useMemo } from 'react'

import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { Dropdown, Menu, Modal, Empty, message } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { blue, green, purple9, redPrimary } from '../../../color'
import { EmptyText, TitleContentBox } from '../../../components/Common/CellText'
import { SelectList } from '../../../pages/MissionCreatePage/MissionCreatePageStyled'
import FileItem from '../../../components/Common/FileItemComponent/FileItem'
import { StatusTag } from '../../../components/Common/StatusTag'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import PopoverWatchTime from '../../../components/PopoverWatchTime'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent'
import {
  ACL_ACTION_TYPE,
  DATE_FORMAT_DEFAULT,
  PROPOSAL_STATUS,
  PROPOSAL_TYPE,
  TYPE_STATUS,
  SHIFTS,
  MODES,
} from '../../../constants'
import TableComponent from '../../../components/Common/TableComponent'
import DashboardLayout from '../../../layouts/DashboardLayout'
import { DocumentInfoList } from '../../../layouts/ThongTinChungLayout/ThongTinChungLayoutStyled'
import loadingAnimationStore from '../../../stores/loadingAnimationStore'
import utils from '../../../utils'
import { HeadingButtonWrapper } from '../../MissionDetailPage/MissionDetailPageStyled'
import DistributionVotesAction from './DistributionVotesAction'
import {
  BlockLeft,
  BlockRight,
  ContentWrapper,
  ViewDetailContent,
} from './DistributionVotesDetailStyle'
import DistributionVotesComment from './DistributionVotesComment'
import DistributionVotesUpdate from '../DistributionVotesUpdate'

const { confirm } = Modal

const DistributionVotesDetailPage = props => {
  const {
    proposalStore,
    match,
    history,
    notificationStore,
    aclStore,
    commonStore,
    authenticationStore,
    proposalTypeStore,
    DistributionVotesStore,
    managementAssetWarehouseStore,
    loadingAnimationStore,
    assetTypeId = null,
  } = props
  const { proposalId, typePage } = match.params
  const { otherDetail } = proposalStore
  const {
    distributionVotesDetail,
    distributionVotesDetails,
  } = DistributionVotesStore
  const {
    managementAssetWarehouseLists,
    managementAssetWarehouseAllLists,
  } = managementAssetWarehouseStore

  const [selectedData, setSelectedData] = useState([])
  // Không xóa dòng khai báo aclActionsByUser (dù k dùng)
  const { aclActionsByUser } = aclStore
  const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false)
  const [
    isVisibleUpdateFollowersModalModal,
    setIsVisibleUpdateFollowersModalModal,
  ] = useState(false)
  const { isAccountAdmin } = authenticationStore
  const handleApprovalProposal = async data => {
    loadingAnimationStore.showSpinner(true)
    try {
      await DistributionVotesStore.DistributionVotesOther(data)
      await DistributionVotesStore.getDistributionVotesDetail(proposalId)
      await DistributionVotesStore.getDistributionVotesComment(proposalId)
      if (data.type === PROPOSAL_STATUS.REJECT) {
        return message.success('Hủy thành công!')
      }
      // window.location.reload();
      return message.success('Duyệt thành công!')
    } catch (e) {
      if (data.type === PROPOSAL_STATUS.REJECT) {
        return message.error('Hủy thất bại!')
      }
      return message.error('Duyệt thất bại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  // create function GetDiffAssets từ đoạn code dưới đây
  const isAllAssetSelected = () => {
    const selectedAssetCount = managementAssetWarehouseAllLists?.filter(item =>
      distributionVotesDetails?.proposal_asset_details.some(
        dataItem => dataItem.asset_id === item.id
      )
    ).length

    const availableAssetCount = managementAssetWarehouseLists?.filter(item =>
      distributionVotesDetail?.proposal_asset_details.some(
        dataItem => dataItem.asset_id === item.id
      )
    ).length

    return selectedAssetCount === availableAssetCount ? true : false
  }

  const getDiffAssets = () => {
    const selectedAssetCount = managementAssetWarehouseAllLists
      ?.filter(item =>
        distributionVotesDetails?.proposal_asset_details.some(
          dataItem => dataItem.asset_id === item.id
        )
      )
      .map(item => item)

    const availableAssetCount = managementAssetWarehouseLists
      ?.filter(item =>
        distributionVotesDetail?.proposal_asset_details.some(
          dataItem => dataItem.asset_id === item.id
        )
      )
      .map(item => item)

    const diff = selectedAssetCount.filter(
      item => !availableAssetCount.some(dataItem => dataItem.id === item.id)
    )

    return JSON.stringify(diff)
  }

  useEffect(() => {
    ;(async () => {
      await DistributionVotesStore.clearFilterProposal()
      await managementAssetWarehouseStore.getManagementAssetWarehousetLists()
      await managementAssetWarehouseStore.getManagementAssetWarehouseAllList()
    })()
  }, [])

  const handleDeleteProposal = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await DistributionVotesStore.delete(proposalId)
      history.push('/distribution-votes')
      message.success('Xóa thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra, vui lòng thử lại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showConfirmApproval = type => {
    confirm({
      title: `Bạn có muốn ${
        type === PROPOSAL_STATUS.APPROVAL ? 'duyệt' : 'hủy'
      } thông tin này không?`,
      icon:
        type === PROPOSAL_STATUS.APPROVAL ? (
          <CheckOutlined style={{ color: blue }} />
        ) : (
          <StopOutlined
            style={{
              color: 'red',
            }}
          />
        ),
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: type === PROPOSAL_STATUS.APPROVAL ? 'primary' : 'danger',
      onOk() {
        handleApprovalProposal({
          proposalCode: proposalId,
          type: type,
        }).then(() => Promise.resolve())
      },
    })
  }

  const showConfirmDelete = () => {
    confirm({
      title: 'Bạn có muốn xóa thông tin này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteProposal()
      },
    })
  }

  useEffect(() => {
    ;(async () => {
      loadingAnimationStore.showSpinner(true)
      await getDistributionVotesDetail(match?.params?.proposalId)
      await loadManagerInventoryList()
    })()
  }, [])

  useEffect(() => {
    const selectedData = managementAssetWarehouseAllLists?.filter(item => {
      return distributionVotesDetails?.proposal_asset_details.some(
        dataItem => dataItem.asset_id === item.id
      )
    })
    const selectedDataWithTitle = selectedData.map(item => {
      const foundDetail = distributionVotesDetails.proposal_asset_details.find(
        detail => detail.asset_id === item.id
      )
      if (foundDetail) {
        return {
          ...item,
          amount: foundDetail.amount,
          purpose: foundDetail.purpose,
        }
      } else {
        return {
          ...item,
          amount: foundDetail.amount,
          purpose: '',
        }
      }
    })
    setSelectedData(selectedDataWithTitle)
  }, [managementAssetWarehouseAllLists, distributionVotesDetails])

  // get detail
  const getDistributionVotesDetail = async id => {
    try {
      await DistributionVotesStore.clearDistributionVotesDetail()
      await DistributionVotesStore.getDistributionVotesDetail(id)
      await DistributionVotesStore.getDistributionVotesAllDetail(id)
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'Đã có lỗi xảy ra, vui lòng thử lại!')
      history.push('/distribution-votes')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  // get check
  const loadManagerInventoryList = async () => {
    try {
      await managementAssetWarehouseStore.getManagementAssetWarehousetLists()
      await managementAssetWarehouseStore.getManagementAssetWarehouseAllList()
    } finally {
    }
  }

  useEffect(() => {
    return () => {
      notificationStore.clearNotificationType()
      DistributionVotesStore.clearOtherCommentList()
    }
  }, [])

  const handleCancelUpdateModal = () => {
    setIsVisibleUpdateModal(false)
  }

  const handleOpenUpdateModal = () => {
    setIsVisibleUpdateModal(true)
  }
  const handleCancelUpdateFollowersModal = () => {
    setIsVisibleUpdateFollowersModalModal(false)
    proposalStore.clearFormProposalType()
  }
  const handleOpenUpdateFollowersModal = () => {
    setIsVisibleUpdateFollowersModalModal(true)
  }

  const checkShowHandleDocument = () => {
    return (
      (distributionVotesDetail.proposal_status === PROPOSAL_STATUS.PENDING &&
        distributionVotesDetail?.has_no_config_approval_levels) ||
      (distributionVotesDetail &&
        !distributionVotesDetail?.has_no_config_approval_levels)
    )
  }

  const checkAccessControlUpdateAction = () => {
    return (
      authenticationStore.currentUser?.username ===
      otherDetail.create_user?.user_name
    )
  }
  const checkAccessControlUpdateActionV2 = () => {
    return otherDetail?.approval_levels?.some(
      item => item.username === authenticationStore.currentUser?.username
    )
  }
  const checkHasNoArppovedLevel = () => {
    if (otherDetail.has_no_config_approval_levels) return true

    if (otherDetail.approval_levels)
      return otherDetail.approval_levels
        .slice(1)
        .every(level => level.status !== 'APPROVED')
  }

  const menuHandleDocument = (
    <Menu>
      {distributionVotesDetail.proposal_status === PROPOSAL_STATUS.PENDING &&
        distributionVotesDetail?.has_no_config_approval_levels && (
          <>
            {aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.approve__PROPOSAL__ASSET
            ) && (
              <Menu.Item
                onClick={() => showConfirmApproval(PROPOSAL_STATUS.APPROVAL)}
                style={{
                  color: blue,
                }}
                icon={<CheckOutlined />}>
                Duyệt phiếu
              </Menu.Item>
            )}
            {aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.approve__PROPOSAL__ASSET
            ) && (
              <Menu.Item
                onClick={() => showConfirmApproval(PROPOSAL_STATUS.REJECT)}
                style={{
                  color: 'red',
                }}
                icon={<StopOutlined />}>
                Từ chối
              </Menu.Item>
            )}
          </>
        )}

      {distributionVotesDetail &&
        (aclStore.checkAccessControlAction(
          ACL_ACTION_TYPE.edit__PROPOSAL__ASSET
        ) ||
          isAccountAdmin ||
          checkAccessControlUpdateAction()) && (
          <>
            <Menu.Item
              style={{
                color: blue,
              }}
              onClick={handleOpenUpdateModal}
              icon={<EditOutlined />}>
              Chỉnh sửa
            </Menu.Item>
          </>
        )}
      {distributionVotesDetail &&
        (aclStore.checkAccessControlAction(
          ACL_ACTION_TYPE.delete__PROPOSAL__ASSET
        ) ||
          isAccountAdmin ||
          checkAccessControlUpdateAction()) && (
          <Menu.Item
            danger
            onClick={showConfirmDelete}
            icon={<DeleteOutlined />}>
            Xóa
          </Menu.Item>
        )}
    </Menu>
  )

  const [proposalTypes, setProposalTypes] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const response = await proposalTypeStore.getAllProposalTypes()
      setProposalTypes(response.data)
    }
    fetchData()
  }, [])
  const renderTitlePage = useMemo(() => {
    for (const [value] of Object.entries(PROPOSAL_TYPE)) {
      if (otherDetail.proposal_type === value) {
        return PROPOSAL_TYPE[value].label
      }
    }
    const foundItem = proposalTypes.find(
      item => item.id == otherDetail.proposal_type
    )
    if (foundItem) {
      return foundItem.name
    }
  }, [otherDetail, proposalTypes])

  const renderAttachmentList = useCallback(() => {
    return otherDetail.attachments?.map(item => {
      return (
        <FileItem
          key={item.file_id}
          file_id={item.file_id}
          file_name={item.file_name}
          file_type={utils.getExtensionFile(item.file_name)}
        />
      )
    })
  }, [commonStore.appTheme, otherDetail])

  const [workingDaysList, setWorkingDaysList] = useState([])

  useEffect(() => {
    setWorkingDaysList(otherDetail?.proposalTimeList || [])
  }, [otherDetail?.proposalTimeList])

  const numberOfColumns =
    otherDetail?.proposal_type !== 'BUSINESS_TRIP' &&
    otherDetail?.proposal_type !== 'OVERTIME_NORMAL'
      ? 4
      : 3
  const columnWidth = `${100 / numberOfColumns}%`

  const tableColumns = [
    {
      title: 'Tên văn phòng phẩm',
      render: record => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          {record.assetName}
        </div>
      ),
      width: 40,
    },
    {
      title: 'Đặc tính',
      align: 'center',
      render: record => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {record?.property}
        </div>
      ),
      width: 10,
    },
    {
      title: 'Đơn vị tính',
      align: 'center',
      render: record => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {record?.assetUnitName}
        </div>
      ),
      width: 10,
    },
    {
      title: 'Số lượng đề nghị',
      align: 'center',
      render: record => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {record?.amount}
        </div>
      ),
      width: 20,
    },
    {
      title: 'Mục đích sử dụng',
      align: 'center',
      render: record => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {record?.purpose}
        </div>
      ),
      width: 30,
    },
  ]

  const sortDataTable = data => {
    return data.sort((a, b) => {
      let nameA = a.assetName.toLowerCase()
      let nameB = b.assetName.toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    })
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>
          Chi tiết Phiếu phân phát văn phòng phẩm | Phiếu phân phát văn phòng
          phẩm
        </title>
      </Helmet>
      <PageTitle
        location={props.location}
        showTitle={true}
        title={distributionVotesDetail.title}
        status={StatusTag(
          TYPE_STATUS.PROPOSAL_ASSEST,
          distributionVotesDetail.proposal_status
        )}
        check={true}
        DistributionVotesStore={DistributionVotesStore}>
        <HeadingButtonWrapper>
          {!(
            aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.edit__PROPOSAL__ASSET
            ) ||
            isAccountAdmin ||
            checkAccessControlUpdateAction()
          ) &&
          !(
            aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.delete__PROPOSAL__ASSET
            ) ||
            isAccountAdmin ||
            checkAccessControlUpdateAction()
          ) ? (
            <></>
          ) : checkShowHandleDocument() ? (
            <Dropdown overlay={menuHandleDocument} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined
                  style={{
                    color: blue,
                  }}
                />
              </span>
            </Dropdown>
          ) : (
            <></>
          )}
        </HeadingButtonWrapper>
      </PageTitle>
      {distributionVotesDetail &&
      !distributionVotesDetail?.has_no_config_approval_levels ? (
        <DistributionVotesAction
          isAllAssetSelected={isAllAssetSelected()}
          getDiffAssets={getDiffAssets()}
        />
      ) : (
        <></>
      )}
      <ViewDetailContent>
        <ContentWrapper>
          <ContentBlockWrapper>
            <div
              style={{
                display: 'flex',
                textAlign: 'center',
                alignItems: 'center',
              }}>
              <dt
                style={{
                  fontWeight: '800',
                  marginRight: '15px',
                }}>
                Người theo dõi :
              </dt>
              {distributionVotesDetail.followers &&
              distributionVotesDetail.followers.length > 0 ? (
                <PopoverWatchTime
                  userList={distributionVotesDetail.followers.map(item => {
                    return {
                      full_name: item.name_lowercase,
                      id: item.user_name,
                      assignee_type: 'USER',
                      last_date_read: item.last_date_read,
                    }
                  })}
                  title={'Danh sách người theo dõi'}
                />
              ) : (
                <EmptyText>Không có người theo dõi.</EmptyText>
              )}
            </div>
            <div>
              <p
                style={{
                  marginTop: '15px',
                  marginBottom: '15px',
                  fontWeight: '800',
                }}>
                Danh sách văn phòng phẩm :
              </p>
              <TableComponent
                rowKey={record => record.code}
                // dataSource={managerInventoryStore.managerInventoryList}
                dataSource={sortDataTable(selectedData)}
                columns={tableColumns}
                pagination={false}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={'Không có dữ liệu'}
                    />
                  ),
                }}
              />
            </div>
          </ContentBlockWrapper>
        </ContentWrapper>
      </ViewDetailContent>
      <div
        style={{
          marginTop: '10px',
        }}>
        <ViewDetailContent>
          <ContentWrapper>
            <ContentBlockWrapper
              style={{
                position: 'relative',
              }}>
              <DistributionVotesComment proposalId={proposalId} />
              <SpinnerInlineComponent sizeSpin={'small'} />
            </ContentBlockWrapper>
          </ContentWrapper>
        </ViewDetailContent>
      </div>

      <Modal
        title={'Chỉnh sửa'}
        onCancel={handleCancelUpdateModal}
        width={1000}
        footer={null}
        maskClosable={false}
        visible={isVisibleUpdateModal}
        destroyOnClose>
        {isVisibleUpdateModal && (
          <DistributionVotesUpdate
            onCancel={handleCancelUpdateModal}
            templateId={proposalId}
            btn="Đồng ý chỉnh sửa"
            check={true}
          />
        )}
      </Modal>
    </DashboardLayout>
  )
}

DistributionVotesDetailPage.propTypes = {}

export default inject(
  'managementAssetWarehouseStore',
  'DistributionVotesStore',
  'proposalStore',
  'notificationStore',
  'aclStore',
  'commonStore',
  'authenticationStore',
  'proposalTypeStore',
  'loadingAnimationStore'
)(observer(DistributionVotesDetailPage))
