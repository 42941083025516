import React, { useEffect, useState } from 'react'
//  Components
import { trimOverLengthString } from '../Common/CellText'
import ContentBlockWrapper from '../ContentBlockWrapper'
import EmptyContent from '../EmptyContent'
import SpinnerInlineComponent from '../SpinnerInlineComponent/SpinnerInlineComponent'
import RectangleBox from './RectangleBox'
// Styled component
import {
  DocumentController,
  DocumentWrapper,
  HeaderPopupSign,
  NameDocument,
  PopupSignWrapper,
  SignatureBox,
  SignatureList,
  ThumbnailsWrapper,
} from './PopupSignStyled'
// Ant design
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  FileTextOutlined,
  InfoCircleTwoTone,
  LeftOutlined,
  RightOutlined,
  SnippetsOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  Checkbox,
  Empty,
  Modal,
  Space,
  Spin,
  Tabs,
  Tag,
  Tooltip,
  Upload,
  message,
} from 'antd'
// PDF
import { Layer, Stage } from 'react-konva'
import { Document, Page } from 'react-pdf'
// Other
import { inject, observer } from 'mobx-react'
import { blue } from '../../color'
import { apiUrl } from '../../config'
import {
  DIGITAL_SIGN_PROVIDER,
  DIGITAL_SIGN_STATUS,
  DIGITAL_TYPE_SIGN_SYSTEM,
  DIGITAL_TYPE_SIGN_USER,
  SIGN_VISIBLE,
} from '../../constants'
import utils from '../../utils'

const { TabPane } = Tabs
const CheckboxGroup = Checkbox.Group
const { Meta } = Card
const { confirm } = Modal
const documentSize = { width: 595, height: 842 }

const PopupSign = props => {
  const {
    signedDocumentStore,
    loadingAnimationStore,
    fileStore,
    authenticationStore,
    isVisiblePopupSign,
    handleClosePopupSign,
    hiddenSelectDocument,
    handleAfterSign,
  } = props

  let { fileId } = props

  const { currentUser } = authenticationStore

  const { fileBlob, originFileObj, responseSign } = signedDocumentStore
  const [selectedSignature, setSelectedSignature] = useState()
  const [rectangles, setRectangles] = useState([])
  const [styleCanvas, setStyleCanvas] = useState({
    width: documentSize.width,
    height: documentSize.height,
  })

  const [idImg, setIdImg] = useState()
  const [numPages, setNumPages] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const [thumbnails, setThumbnails] = useState([])
  const [loadedPdf, setLoadedPdf] = useState()
  const [signatureList, setSignatureList] = useState([])

  const handleFinishSign = () => {
    setSelectedSignature(null)
    setRectangles(null)
    setIdImg(null)
    setPageNumber(1)
    handleClosePopupSign()
    signedDocumentStore.clearDataPopupSign()
    setStyleCanvas({ ...styleCanvas, border: null })
  }

  const [visibleSignature, setVisibleSignature] = useState(SIGN_VISIBLE.VISIBLE)

  const options = [
    {
      key: 1,
      label: 'Ẩn chữ ký',
      value: SIGN_VISIBLE.HIDE,
    },
    // {
    //   key: 2,
    //   label: 'Không ảnh', value: 0,
    // },
  ]

  useEffect(() => {
    if (isVisiblePopupSign && currentUser) {
      loadingAnimationStore.setShowSpinInline(true)
      signedDocumentStore
        .getUserSignatureList()
        .then(res => {
          setSignatureList(res.data)
        })
        .finally(() => {
          loadingAnimationStore.setShowSpinInline(false)
        })
    }
  }, [isVisiblePopupSign, currentUser])

  const scaleFactor =
    styleCanvas.width /
    (styleCanvas.width > styleCanvas.height
      ? documentSize.height
      : documentSize.width)
  const MIN_SIGNATURE_WIDTH = 50 // Minimum width for small images
  const MIN_SIGNATURE_HEIGHT = 36 // Minimum height for small images

  const handleSelectSignature = signId => {
    if (!signId) return
    setIdImg(signId)

    const img = document.getElementById(signId)
    const imgWidth = img.naturalWidth
    const imgHeight = img.naturalHeight

    // Calculate scaled width and height, with a minimum constraint for smaller images
    const calculatedWidth = Math.max(
      imgWidth * scaleFactor * 0.23,
      MIN_SIGNATURE_WIDTH
    )
    const calculatedHeight = Math.max(
      imgHeight * scaleFactor * 0.23,
      MIN_SIGNATURE_HEIGHT
    )
    // console.log(calculatedWidth)
    // console.log(calculatedHeight)
    if (calculatedWidth > calculatedHeight) {
      setRectangles([
        {
          x: 10, // Initial x-position; adjust if needed
          y: 10, // Adjust y-position to center vertically
          width: calculatedWidth * 0.1,
          height: calculatedWidth * 0.1,
          id: 'rect1',
        },
      ])
    } else if (calculatedWidth < calculatedHeight) {
      setRectangles([
        {
          x: 10, // Adjust x-position to center horizontally
          y: 10, // Initial y-position; adjust if needed
          width: calculatedHeight * 0.116,
          height: calculatedHeight * 0.116,
          id: 'rect1',
        },
      ])
    } else {
      setRectangles([
        {
          x: 10, // Initial x-position; adjust if needed
          y: 10, // Initial y-position; adjust if needed
          width: calculatedWidth * 0.558,
          height: calculatedHeight * 0.558,
          id: 'rect1',
        },
      ])
    }
  }

  const handleSign = async () => {
    loadingAnimationStore.showSpinner(true)
    const formData = new FormData()
    formData.append('file', originFileObj)
    const signId = selectedSignature.signId
    const img = document.getElementById(signId)
    const imgWidth = img.naturalWidth
    const imgHeight = img.naturalHeight
    const ratioImg = imgWidth / imgHeight

    // Tính toán tọa độ dựa trên tỷ lệ ảnh, không cần SIGN_TYPE
    const scale = ratioImg // Tùy chỉnh theo loại tỷ lệ nếu cần
    const newWidth = imgWidth * scale
    const newHeight = imgHeight * scale

    const fnLlx = rectangles[0].x * scaleFactor
    const fnLly =
      styleCanvas.height -
      (rectangles[0].y + rectangles[0].height * scaleFactor)
    const fnUrx = fnLlx + rectangles[0].width
    const fnUry = fnLly + rectangles[0].height

    try {
      if (!fileId) {
        const { data } = await fileStore.uploadFile(formData)
        fileId = data.file_id
      }

      const dataSubmit = {
        reason: 'Signed',
        location: 'VietNam',
        contactInfo: '',
        provider: DIGITAL_SIGN_PROVIDER,
        isVisible: visibleSignature,
        listSignFile: [{ fileId }],
        type:
          selectedSignature.type === DIGITAL_TYPE_SIGN_SYSTEM
            ? DIGITAL_TYPE_SIGN_SYSTEM
            : DIGITAL_TYPE_SIGN_USER,
        image: idImg,
        page: pageNumber,
        typeOfImageSignature: selectedSignature.typeSignature,
        llx: fnLlx,
        lly: fnLly,
        urx: fnUrx,
        ury: fnUry,
      }

      const res = await signedDocumentStore.signDocumentSavis(dataSubmit)
      // console.log("------ Sign datasubmit request is :", dataSubmit);
      signedDocumentStore.setResponseSign(res)

      if (handleAfterSign) {
        signedDocumentStore.setResponseSign(undefined)
        await handleAfterSign(res.data.listSignFile[0].fileSignId)
      }
      message.success('Ký số thành công!')
    } catch (err) {
      // console.log(err)
      message.error(err?.vi || 'Ký số thất bại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showSignConfirm = () => {
    if (utils.isNullish(selectedSignature)) {
      message.error('Bạn phải chọn chữ ký trước khi ký')
      return 0
    }
    if (
      (selectedSignature &&
        selectedSignature.status === DIGITAL_SIGN_STATUS.APPROVAL) ||
      selectedSignature == null
    ) {
      confirm({
        title: 'Bạn có chắc chắn muốn ký tài liệu này?',
        icon: <InfoCircleTwoTone />,
        content: '',
        okText: 'Đồng ý',
        okType: 'primary',
        cancelText: 'Hủy bỏ',
        onOk() {
          handleSign()
        },
        onCancel() {
          setStyleCanvas({ ...styleCanvas, border: null })
          console.log('Hủy ký')
        },
      })
    } else {
      message.info('Chữ ký này chưa được duyệt')
    }
  }

  const renderPage = async (pdf, pageNumber) => {
    const page = await pdf.getPage(pageNumber)
    const viewport = page.getViewport({ scale: 1 })
    const isLandscape = viewport.width > viewport.height
    setStyleCanvas({
      width: isLandscape ? viewport.width : documentSize.width,
      height: isLandscape ? viewport.height : documentSize.height,
      border: '1px solid #000',
    })
  }

  const onDocumentLoadSuccess = pdf => {
    const { numPages } = pdf
    // if (
    //   pdf
    //     .getPage(1)
    //     .then(
    //       page =>
    //         page.getViewport({ scale: 1 }).width >
    //         page.getViewport({ scale: 1 }).height
    //     )
    // ) {
    //   message.info('Tài liệu đang ở chế độ nằm ngang (Landscape).')
    // }

    setNumPages(numPages)
    const thumbnails = []
    for (let i = 0; i < numPages; i++) {
      thumbnails.push(i + 1)
    }
    setThumbnails(thumbnails)
    setLoadedPdf(pdf)
    renderPage(pdf, pageNumber)
  }

  const onSelectDocument = file => {
    const newFile = new Blob([file.file], { type: 'application/pdf' })
    signedDocumentStore.setFileBlob(newFile)
    signedDocumentStore.setOriginFileObj(file.file)
  }

  const onSignatureCheckChanged = signature => {
    if (signature === selectedSignature) {
      handleSelectSignature(null, null)
      setSelectedSignature(null)
      setIdImg(null)
      setRectangles(null)
      return
    }
    const signId = signature.signId
    const img = document.getElementById(signId)
    handleSelectSignature(signId, img.naturalWidth / img.naturalHeight)
    setSelectedSignature(signature)
  }

  const onSignVisibleChange = values => {
    if (values.length > 0) {
      setVisibleSignature(SIGN_VISIBLE.HIDE)
    } else {
      setVisibleSignature(SIGN_VISIBLE.VISIBLE)
    }
  }

  return (
    <PopupSignWrapper
      visible={isVisiblePopupSign}
      footer={null}
      width={1300}
      style={{ top: 20 }}
      title={
        <HeaderPopupSign>
          <span>Ký số tài liệu</span>
          <Space>
            {!hiddenSelectDocument && (
              <Upload
                accept={'.pdf'}
                beforeUpload={() => false}
                showUploadList={false}
                onChange={onSelectDocument}>
                <Button type="primary" icon={<SnippetsOutlined />}>
                  Chọn tài liệu ký số
                </Button>
              </Upload>
            )}
            {responseSign ? (
              <Button
                type={'primary'}
                icon={<CheckOutlined />}
                onClick={handleFinishSign}
                style={{
                  backgroundColor: '#389e0d',
                }}>
                Hoàn thành
              </Button>
            ) : (
              <Button
                danger
                type={'text'}
                icon={<CloseOutlined />}
                onClick={handleFinishSign}
              />
            )}
          </Space>
        </HeaderPopupSign>
      }>
      <ContentBlockWrapper>
        {
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 20,
            }}>
            <div style={{ flexGrow: 1 }}>
              <DocumentWrapper fileBlob={fileBlob}>
                {fileBlob && loadedPdf && (
                  <DocumentController maxWidth={styleCanvas.width}>
                    <Space>
                      <Button
                        type="primary"
                        danger
                        icon={<CloseOutlined />}
                        onClick={handleFinishSign}>
                        Hủy
                      </Button>
                    </Space>
                    {fileBlob && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Button
                          style={{ width: 42 }}
                          onClick={() => {
                            if (pageNumber === 1) return
                            setPageNumber(pageNumber - 1)
                            renderPage(loadedPdf, pageNumber - 1)
                          }}
                          icon={<LeftOutlined />}
                        />
                        <p style={{ margin: '0 10px' }}>
                          {pageNumber}/ {numPages}
                        </p>

                        <Button
                          style={{ width: 42 }}
                          onClick={() => {
                            if (pageNumber === numPages) return
                            setPageNumber(pageNumber + 1)
                            renderPage(loadedPdf, pageNumber + 1)
                          }}
                          icon={<RightOutlined />}
                        />
                      </div>
                    )}
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={showSignConfirm}>
                      Ký tài liệu
                    </Button>
                  </DocumentController>
                )}
                {originFileObj && loadedPdf && !responseSign && (
                  <NameDocument maxWidth={styleCanvas.width}>
                    <Tooltip>
                      <FileTextOutlined
                        style={{ color: blue, fontSize: 16, marginRight: 10 }}
                      />
                      <span style={{ lineHeight: '20px' }}>
                        {trimOverLengthString(originFileObj.name, 70)}
                      </span>
                    </Tooltip>
                  </NameDocument>
                )}
                {responseSign && loadedPdf && (
                  <NameDocument maxWidth={styleCanvas.width} signed={true}>
                    <Tooltip title={'Tải xuống'}>
                      <div
                        onClick={() =>
                          fileStore.handleDownloadFile(
                            responseSign.data.listSignFile[0].fileSignId,
                            responseSign.data.listSignFile[0].fileSignName
                          )
                        }>
                        <DownloadOutlined
                          style={{
                            color: '#27ae60',
                            fontSize: 16,
                            marginRight: 10,
                          }}
                        />
                        <span style={{ lineHeight: '20px' }}>
                          {trimOverLengthString(
                            responseSign.data.listSignFile[0].fileSignName,
                            70
                          )}
                        </span>
                        <Tag color={'green'} style={{ marginLeft: 8 }}>
                          Đã ký
                        </Tag>
                      </div>
                    </Tooltip>
                  </NameDocument>
                )}
                {fileBlob ? (
                  <div className={'document-wrapper'}>
                    <Document
                      style={{ display: 'flex', justifyContent: 'center' }}
                      file={fileBlob}
                      onLoadSuccess={onDocumentLoadSuccess}
                      loading={
                        <div className={'spin-box'}>
                          <Spin tip="Đang tải tài liệu" />
                        </div>
                      }>
                      <Page pageNumber={pageNumber} loading={null} />
                    </Document>
                  </div>
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={'Vui lòng chọn tài liệu ký số!'}
                  />
                )}
                <Stage
                  width={styleCanvas.width}
                  height={styleCanvas.height}
                  style={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    border: styleCanvas.border,
                    top: 92,
                    zIndex: 9,
                  }}
                  draggable={true}>
                  <Layer
                    style={{
                      width: 300,
                      height: 36,
                      border: '1px solid red',
                      backgroundColor: 'red',
                      display: 'block',
                    }}>
                    {rectangles &&
                      rectangles.map((rect, i) => (
                        <RectangleBox
                          key={i}
                          shapeProps={rect}
                          urlImg={`${apiUrl}/api/v1/images/${idImg}`}
                          isSelected={true}
                          onChange={newAttrs => {
                            const updatedRects = [...rectangles]
                            updatedRects[i] = newAttrs
                            setRectangles(updatedRects)
                          }}
                        />
                      ))}
                  </Layer>
                </Stage>
              </DocumentWrapper>
            </div>

            <div style={{ width: 300, minHeight: 'calc(100vh - 140px)' }}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Tùy chọn ký" key="1">
                  <div style={{ marginBottom: '1rem' }}>
                    <label style={{ fontWeight: 600, marginRight: 10 }}>
                      Loại ký:{' '}
                    </label>
                    <CheckboxGroup
                      options={options}
                      onChange={onSignVisibleChange}
                    />
                  </div>
                  <div style={{ position: 'relative' }}>
                    <SpinnerInlineComponent sizeSpin={'small'} />
                    <p style={{ fontWeight: 600 }}>Chữ ký:</p>
                    <SignatureList>
                      {signatureList ? (
                        signatureList
                          .filter(signature => signature.status !== 'DELETE')
                          .map(signature => (
                            <SignatureBox
                              key={signature.signId}
                              onClick={() =>
                                onSignatureCheckChanged(signature)
                              }>
                              <Card
                                hoverable
                                className={'card'}
                                cover={
                                  <div className={'img-box'}>
                                    <img
                                      alt="signature"
                                      src={`${apiUrl}/api/v1/images/${signature.signId}`}
                                      id={signature.signId}
                                    />
                                  </div>
                                }>
                                <Meta
                                  title={
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}>
                                      <span>{signature.signName}</span>
                                      <Checkbox
                                        checked={
                                          selectedSignature === signature
                                        }
                                      />
                                    </div>
                                  }></Meta>
                              </Card>
                            </SignatureBox>
                          ))
                      ) : (
                        <EmptyContent
                          description={
                            'Bạn chưa có chữ ký nào, hãy tạo chữ ký!'
                          }
                        />
                      )}
                    </SignatureList>
                  </div>
                </TabPane>
                <TabPane tab="Phụ lục tài liệu" key="2">
                  <ThumbnailsWrapper>
                    <Document
                      noData={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={'Hãy chọn tài liệu!'}
                        />
                      }
                      file={fileBlob}
                      loading={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: 170,
                          }}>
                          <Spin />
                        </div>
                      }>
                      {thumbnails.map(thumbnailNumber => {
                        return (
                          <div
                            className={
                              thumbnailNumber === pageNumber
                                ? 'thumbnail-box thumbnail-box-active'
                                : 'thumbnail-box'
                            }
                            onClick={() => setPageNumber(thumbnailNumber)}
                            key={thumbnailNumber}>
                            <Page
                              pageNumber={thumbnailNumber}
                              loading={null}
                              width={125}
                            />
                            <div style={{ fontWeight: 'bold' }}>
                              {thumbnailNumber}
                            </div>
                          </div>
                        )
                      })}
                    </Document>
                  </ThumbnailsWrapper>
                </TabPane>
              </Tabs>
            </div>
          </div>
        }
      </ContentBlockWrapper>
    </PopupSignWrapper>
  )
}

PopupSign.propTypes = {}

export default inject(
  'signedDocumentStore',
  'loadingAnimationStore',
  'authenticationStore',
  'fileStore'
)(observer(PopupSign))
