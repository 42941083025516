import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Checkbox,
} from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_DEFAULT,
  ISO_DATE_FORMAT,
  WORK_STATUS,
} from '../../../constants'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { blue, yellowPrimary } from '../../../color'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../../components/SelectGroupPopup/SelectGroupPopup'
import { AvatarHasName } from '../../../components/Common/Avatar'
import { SelectList } from '../../MissionCreatePage/MissionCreatePageStyled'
import { toJS } from 'mobx'

const { Option } = Select
const { RangePicker } = DatePicker
const { TabPane } = Tabs

const Search = props => {
  const {
    workStore,
    loadingAnimationStore,
    handleCloseCollapse,
    selectPeopleStore,
    dispatchUserFilter,
    authenticationStore,
    isUnread,
    handleChangeFilterImportant,
    onChangeUnReadStatus,
    selectedRowKeys,
    setSelectedRowKeys,
    markAsReadLoading,
    setMarkAsReadLoading,
  } = props
  const {
    selectUserData,
    selectGroupData,
    assigneePopupType,
    selectDepartmentData,
    isVisibleSelectGroupPopup,
    handleOpenSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleCancelSelectGroup,
    setIsVisibleSelectGroupPopup,
    setIsVisibleSelectUserDepartmentPopup,
  } = selectPeopleStore

  const { queryParams, workTypeListForSearch, workList } = workStore
  const [form] = Form.useForm()
  const location = useLocation()
  const history = useHistory()
  const queryStringParse = queryString.parse(location.search)
  const [createList, setCreateList] = useState([])
  const [handlerList, setHandlerList] = useState([])
  const [activeTab, setActiveTab] = useState('')
  const queryStringParsed = queryString.parse(location.search, {
    arrayFormat: 'index',
  })

  useEffect(() => {
    form.setFieldsValue({
      search: queryParams.title || null,
      date:
        queryParams.from_date && queryParams.to_date
          ? [moment(queryParams.from_date), moment(queryParams.to_date)]
          : null,
      status: queryParams.status || undefined,
    })
  }, [queryParams, form])

  useEffect(() => {
    setCreateList([
      ...(selectUserData[ASSIGNEE_TYPE.CREATE]
        ? selectUserData[ASSIGNEE_TYPE.CREATE]
        : []),
      ...(selectDepartmentData[ASSIGNEE_TYPE.CREATE]
        ? selectDepartmentData[ASSIGNEE_TYPE.CREATE]
        : []),
      ...(selectGroupData[ASSIGNEE_TYPE.CREATE]
        ? selectGroupData[ASSIGNEE_TYPE.CREATE]
        : []),
    ])

    setHandlerList([
      ...(selectUserData[ASSIGNEE_TYPE.COMBINER]
        ? selectUserData[ASSIGNEE_TYPE.COMBINER]
        : []),
      ...(selectDepartmentData[ASSIGNEE_TYPE.COMBINER]
        ? selectDepartmentData[ASSIGNEE_TYPE.COMBINER]
        : []),
      ...(selectGroupData[ASSIGNEE_TYPE.COMBINER]
        ? selectGroupData[ASSIGNEE_TYPE.COMBINER]
        : []),
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const handleChangeTabs = async tab => {
    selectPeopleStore.clearSelected()
    form.setFieldsValue({
      search: null,
      date: null,
      status: undefined,
    })

    handleCloseCollapse()
    workStore.setQueryParams({
      ...queryParams,
      page: 0,
      permission: tab,
      parent_id: null,
      title: null,
      from_date: null,
      to_date: null,
      status: undefined,
      assignee: null,
    })

    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        permission: tab,
        parent_id: null,
        title: null,
        from_date: null,
        to_date: null,
        status: undefined,
        assignee: null,
      },
      {
        skipNull: true,
      }
    )

    history.replace(`/works?${queryStringStringify}`)
  }

  const onSearchTitle = async title => {
    handleCloseCollapse()
    workStore.setQueryParams({
      ...queryParams,
      page: 0,
      title: title ? title.trim() : null,
      parent_id: null,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        title: title ? title.trim() : null,
        parent_id: null,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/works?${queryStringStringify}`)
    // await getWorkList()
  }

  const onChangePermission = async permission => {
    handleCloseCollapse()
    workStore.setQueryParams({
      ...queryParams,
      permission: permission,
      parent_id: null,
      page: 0,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        permission: permission,
        parent_id: null,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/works?${queryStringStringify}`)
    // await getWorkList()
  }

  const onChangeDate = async value => {
    handleCloseCollapse()
    workStore.setQueryParams({
      ...queryParams,
      from_date: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_date: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
      parent_id: null,
      page: 0,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        from_date: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
        to_date: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
        parent_id: null,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/works?${queryStringStringify}`)
    // await getWorkList()
  }
  const onChangeStatus = async status => {
    handleCloseCollapse()
    workStore.setQueryParams({
      ...queryParams,
      status: status,
      page: 0,
      parent_id: null,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        status: status,
        parent_id: null,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/works?${queryStringStringify}`)
    // await getWorkList()
  }

  const onChangeWorkType = async work_type => {
    handleCloseCollapse()
    workStore.setQueryParams({
      ...queryParams,
      page: 0,
      work_type: work_type,
      parent_id: null,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        work_type: work_type,
        parent_id: null,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/works?${queryStringStringify}`)
    // await getWorkList()
  }

  const renderValueWorkType = work_type => {
    if (workTypeListForSearch.map(type => type.id).includes(work_type))
      return work_type
    if (queryParams.work_type === WORK_STATUS.DOCUMENT) return 'Từ văn bản'
    if (queryParams.work_type === WORK_STATUS.DEPLOY) return 'Từ nhiệm vụ'
    return 'Tất cả loại công việc'
  }

  const handleSubmitSelect = async () => {
    setIsVisibleSelectGroupPopup(false)
    setIsVisibleSelectUserDepartmentPopup(false)

    if (selectGroupData[assigneePopupType].length > 0) {
      dispatchUserFilter({
        type: assigneePopupType,
        value: selectGroupData[assigneePopupType][0].id,
        fullName: selectGroupData[assigneePopupType][0].full_name,
        assigneeType: ASSIGNEE_TYPE.GROUP,
      })
      return
    }
    if (selectUserData[assigneePopupType].length > 0) {
      dispatchUserFilter({
        type: assigneePopupType,
        value: selectUserData[assigneePopupType][0].id,
        fullName: selectUserData[assigneePopupType][0].full_name,
        assigneeType: ASSIGNEE_TYPE.USER,
      })
      return
    }

    if (selectDepartmentData[assigneePopupType].length > 0) {
      dispatchUserFilter({
        type: assigneePopupType,
        value: selectDepartmentData[assigneePopupType][0].id,
        fullName: selectDepartmentData[assigneePopupType][0].full_name,
        assigneeType: ASSIGNEE_TYPE.DEPARTMENT,
      })
      return
    }

    dispatchUserFilter({
      type: assigneePopupType,
      value: null,
      fullName: null,
      assigneeType: ASSIGNEE_TYPE.USER,
    })
  }

  const markAsRead = () => {
    let batchMarkAsRead = []
    selectedRowKeys.forEach(docId => {
      batchMarkAsRead.push(workStore.getWorkDetail(docId, null))
    })
    setMarkAsReadLoading(true)
    Promise.all(batchMarkAsRead).finally(() => {
      setMarkAsReadLoading(false)
      setSelectedRowKeys([])
      workStore.getWorkList()
    })
  }

  return (
    <>
      <Tabs
        activeKey={queryParams.permission || ''}
        onChange={handleChangeTabs}>
        <TabPane tab="Tất cả" key={''} />
        <TabPane tab="Đã tạo" key={ASSIGNEE_TYPE.CREATE} />
        <TabPane tab="Tôi xử lý" key={ASSIGNEE_TYPE.HANDLER} />
        <TabPane tab="Tôi theo dõi" key={ASSIGNEE_TYPE.FOLLOWER} />
      </Tabs>
      <Form form={form}>
        <Row gutter={[14, 14]} style={{ marginBottom: 20 }}>
          <Col xl={8} lg={12} md={24} xs={24}>
            <Form.Item name={'search'}>
              <Input.Search
                allowClear
                onSearch={value => onSearchTitle(value)}
                placeholder={'Tìm kiếm theo tiêu đề ...'}
                style={{ width: '100%' }}
                defaultValue={queryStringParse.title}
                maxLength={500}
              />
            </Form.Item>
          </Col>
          <Col xl={8} lg={12} md={12} xs={24}>
            <Form.Item name={'date'}>
              <RangePicker
                placeholder={['Bắt đầu', 'Kết thúc']}
                style={{ width: '100%' }}
                onChange={onChangeDate}
                defaultValue={
                  queryParams.from_date && queryParams.to_date
                    ? [
                        moment(queryParams.from_date),
                        moment(queryParams.to_date),
                      ]
                    : null
                }
                format={[DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT]}
              />
            </Form.Item>
          </Col>
          <Col xl={8} lg={12} md={12} xs={24}>
            <Form.Item name={'status'}>
              <Select
                allowClear
                placeholder={'Trạng thái'}
                style={{ width: '100%' }}
                onChange={onChangeStatus}
                value={queryParams.status}>
                <Option value={WORK_STATUS.COMPLETE}>Hoàn thành</Option>
                <Option value={WORK_STATUS.PENDING}>Chưa hoàn thành</Option>
              </Select>
            </Form.Item>
          </Col>
          {/* <Col xl={4} lg={12} md={12} xs={24}>
            <Select
              allowClear
              placeholder={'Trạng thái đọc'}
              style={{ width: '100%' }}
              onChange={onChangeUnReadStatus}
              value={queryParams.is_unread}>
              <Option value={false}>Đã đọc</Option>
              <Option value={true}>Chưa đọc</Option>
            </Select>
          </Col> */}
          {/*<Col xl={5} lg={12} md={12} xs={24}>*/}
          {/*  <Select*/}
          {/*    allowClear*/}
          {/*    style={{ width: '100%', display: 'none' }}*/}
          {/*    onChange={onChangeWorkType}*/}
          {/*    value={renderValueWorkType(queryParams.work_type)}*/}
          {/*    disabled={*/}
          {/*      queryParams.work_type === WORK_STATUS.DOCUMENT ||*/}
          {/*      queryParams.work_type === WORK_STATUS.DEPLOY*/}
          {/*    }*/}
          {/*    placeholder={'Loại công việc'}>*/}
          {/*    {workTypeListForSearch.map(workType => (*/}
          {/*      <Option*/}
          {/*        key={workType.id}*/}
          {/*        value={workType.id}*/}
          {/*        name={workType.name}>*/}
          {/*        {workType.name}*/}
          {/*      </Option>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</Col>*/}
        </Row>
      </Form>
      <Row gutter={[14, 14]} style={{ marginBottom: 18 }}>
        <Col xl={8} lg={12} md={24} xs={24}>
          <Space
            style={{
              width: 180,
              verticalAlign: 'middle',
              display: 'inline-block',
            }}>
            {selectedRowKeys && selectedRowKeys.length > 0 ? (
              <Button
                loading={markAsReadLoading}
                onClick={markAsRead}
                disabled={!selectedRowKeys || selectedRowKeys.length === 0}>
                <CheckOutlined />
                Đánh dấu đã đọc
              </Button>
            ) : (
              <Tooltip placement="top" title={'Chưa chọn đối tượng'}>
                <Button disabled={true} style={{ marginLeft: '0px' }}>
                  <CheckOutlined />
                  Đánh dấu đã đọc
                </Button>
              </Tooltip>
            )}
          </Space>
        </Col>
        <Col xl={8} lg={12} md={24} xs={24}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <span>Người tạo:</span>
            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    selectPeopleStore.handleOpenSelectUserDepartmentPopup(
                      ASSIGNEE_TYPE.CREATE
                    )
                  }
                />
              </Tooltip>
            </Space>
          </div>

          <SelectList>
            <div>
              {createList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => {
                    dispatchUserFilter({
                      type: el.permission,
                      value: null,
                      fullName: null,
                      assigneeType: null,
                    })
                    selectPeopleStore.handleRemoveSelect(el)
                  }}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </div>
          </SelectList>
        </Col>

        <Col xl={8} lg={12} md={24} xs={24}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <span>Người xử lý:</span>
            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    selectPeopleStore.handleOpenSelectUserDepartmentPopup(
                      ASSIGNEE_TYPE.COMBINER
                    )
                  }
                />
              </Tooltip>
              <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                <Button
                  icon={<TeamOutlined />}
                  style={{ color: yellowPrimary }}
                  onClick={() =>
                    handleOpenSelectGroupPopup(ASSIGNEE_TYPE.COMBINER)
                  }
                />
              </Tooltip>
            </Space>
          </div>
          <SelectList>
            <div>
              {handlerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => {
                    dispatchUserFilter({
                      type: el.permission,
                      value: null,
                      fullName: null,
                      assigneeType: null,
                    })
                    selectPeopleStore.handleRemoveSelect(el)
                  }}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </div>
          </SelectList>
        </Col>
      </Row>

      <Row gutter={[14, 14]} style={{ marginBottom: 20 }}>
        {/*<Col xl={5} lg={12} md={24} xs={24}>*/}
        {/*  <Checkbox*/}
        {/*    onChange={handleChangeFilterImportant}*/}
        {/*    defaultChecked={!!queryStringParsed.is_important}>*/}
        {/*    <span*/}
        {/*      style={{*/}
        {/*        fontWeight: 'bold',*/}
        {/*        transform: 'translateY(-1px)',*/}
        {/*        display: 'inline-block',*/}
        {/*      }}>*/}
        {/*      Chọn việc ưu tiên*/}
        {/*    </span>*/}
        {/*  </Checkbox>*/}
        {/*</Col>*/}
        {/*<Col xl={5} lg={12} md={24} xs={24}>*/}
        {/*  <Checkbox*/}
        {/*    onChange={onChangeUnReadStatus}*/}
        {/*    defaultChecked={!!queryStringParsed.is_unread || isUnread}>*/}
        {/*    <span*/}
        {/*      style={{*/}
        {/*        fontWeight: 'bold',*/}
        {/*        transform: 'translateY(-1px)',*/}
        {/*        display: 'inline-block',*/}
        {/*      }}>*/}
        {/*      Chọn việc chưa đọc*/}
        {/*    </span>*/}
        {/*  </Checkbox>*/}
        {/*</Col>*/}
      </Row>

      <SelectPeoplePopup
        id={'list-work-select-people-popup'}
        onlySelectOne
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={handleSubmitSelect}
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />

      <SelectGroupPopup
        id={'list-work-select-group-popup'}
        onlySelectOne
        groupType={'cong_viec'}
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={handleSubmitSelect}
      />
    </>
  )
}

Search.propTypes = {}

export default inject(
  'workStore',
  'loadingAnimationStore',
  'selectPeopleStore',
  'authenticationStore'
)(observer(Search))
