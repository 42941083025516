import { requests } from './DefaultRequest'

export default {
  getManagementAssetWarehouseList: param => {
    if (param) {
      return requests.get(`/api/v1/asset?${param}`)
    } else {
      return requests.get(`/api/v1/asset?page=0&size=50000`)
    }
  },

  getManagementAssetWarehouseAllList: () =>
    requests.get(`/api/v1/asset/find-all`),

  getManagementAssetWarehouseDetail: code =>
    requests.get(`/api/v1/asset/${code}`),

  createManagementAssetWarehouse: payload =>
    requests.post(`/api/v1/asset`, payload),

  updateManagementAssetWarehouse: (code, payload) =>
    requests.put(`/api/v1/asset/${code}`, payload),

  deleteManagementAssetWarehouse: code =>
    requests.delete(`/api/v1/asset/${code}`),

  typeManagementAssetWarehouseList: param => {
    if (param) {
      return requests.get(`/api/v1/asset/type?${param}`)
    } else {
      return requests.get(`/api/v1/asset-type/list?page=0&size=50000`)
    }
  },
  unitManagementAssetWarehouseList: () => requests.get(`/api/v1/asset/unit`),

  // Tạo lịch sử quản lý kho - tài sản
  createManagementAssetHistoryWarehouse: payload =>
    requests.post(`/api/v1/asset/history`, payload),

  getManagementAssetWareHouseHistory: param =>
    requests.get(`/api/v1/asset/history?${param}`),
}
