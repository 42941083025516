import { Divider, message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { systemName } from '../../config'
import utils from '../../utils'
import './RiskActionDetails.css'
import { RiskActionStyle } from './RiskActionDetailsStyled'

const RiskAssessmentItem = ({ label, data, textValue, labelBold }) => (
  <p>
    <span>{label}</span>
    <span style={{ ...textValue, ...labelBold }}>
      {data?.dong_y === 'Đồng ý'
        ? 'Đồng ý'
        : data?.dong_y === 'Không đồng ý'
        ? 'Không đồng ý'
        : ''}
    </span>{' '}
    <span style={{ ...textValue, ...labelBold }}>
      {data?.dieu_chinh_bo_sung}
    </span>
  </p>
)

const RiskAssessmentItem2 = ({ label, data, textValue, labelBold }) => (
  <p>
    <span>{label}</span>
    <span style={{ ...textValue, ...labelBold }}>{data}</span>
  </p>
)
const RiskActionDetails2 = ({
  data,
  type,
  isPartOfPdf = false,
  departmentStore,
  riskStore,
  departmentWithUserMasterList,
  BAN_CMNV_VIMC,
}) => {
  const { departmentList } = departmentStore
  const labelBold = { fontWeight: 600 }
  const textValue = { fontStyle: 'italic', fontSize: '14px', marginLeft: 8 }
  const styleTable = { margin: 0, width: '100%' }
  const styleTable2 = {
    border: 'none',
    margin: '0 50px ',
    backgroundColor: 'white',
  }
  const styleDepartment = {
    marginTop: '5px',
    fontSize: '16px',
  }

  let doi_tuong_thuc_hien = {}
  let III_kiem_soat_rui_ro = {}

  if (data !== null && data.length > 0) {
    doi_tuong_thuc_hien = data[0]?.doi_tuong_thuc_hien
    III_kiem_soat_rui_ro = data[0]?.III_kiem_soat_rui_ro
  }

  const doi_voi_cac_loai_rui_ro_da_nhan_dien =
    III_kiem_soat_rui_ro?.doi_voi_cac_loai_rui_ro_da_nhan_dien
  const doi_voi_danh_gia_muc_rui_ro =
    III_kiem_soat_rui_ro?.doi_voi_danh_gia_muc_rui_ro
  const loai_rui_ro_da_de_xuat_xu_ly =
    III_kiem_soat_rui_ro?.loai_rui_ro_da_de_xuat_xu_ly
  const danh_gia_bien_phap_xu_ly_da_de_xuat =
    III_kiem_soat_rui_ro?.danh_gia_bien_phap_xu_ly_da_de_xuat
  const thoi_gian_hoan_thanh_cong_viec_duoc_giao =
    III_kiem_soat_rui_ro?.thoi_gian_hoan_thanh_cong_viec_duoc_giao
  const bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc =
    III_kiem_soat_rui_ro?.bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc
  const y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc =
    III_kiem_soat_rui_ro?.y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc
  const phong_ban_nddpv = III_kiem_soat_rui_ro?.phong_ban_nddpv
  const chuyen_vien_thuc_hien_nddpv_signed =
    III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv_signed
  const lanh_dao_ban_signed = III_kiem_soat_rui_ro?.lanh_dao_ban_signed
  const chuyen_vien_thuc_hien = III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien
  const lanh_dao_ban_nddpv = III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv
  const styleSigned = {
    marginTop: lanh_dao_ban_signed ? '5px' : '60px',
  }

  // const BAN_CMNV_VIMC = departmentWithUserMasterList?.find(
  //   depart => depart.name === III_kiem_soat_rui_ro?.phong_ban_nddpv
  // )

  const [dvptList, setDvptList] = useState([])

  const findDepartmentByCode = unit_code => {
    let department = departmentList?.find(dept => dept.code === unit_code)
    if (department) {
      return department
    }
    return departmentWithUserMasterList?.find(dept => dept.code === unit_code)
  }
  const findDVPTByCode = unit_code => {
    return dvptList?.find(dept => dept.code === unit_code)
  }

  const getUnitDVPT = type => {
    riskStore
      .getRiskUnitsByType(type)
      .then(response => {
        setDvptList(response.data)
      })
      .catch(error => {
        console.error(error)
        message.error('Đã có lỗi xảy ra')
      })
  }

  useEffect(() => {
    const fetchRiskUnits = async () => {
      try {
        await getUnitDVPT('DVPT')
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    }

    fetchRiskUnits()
  }, [])

  const renderNameLanhDaoBanT2 = names => {
    if (names == null || names.length == 0) return
    if (names.length == 1) return names[0].label
    return names.map(name => name.label).join(', ')
  }

  const ban_cmnv_vimc = doi_tuong_thuc_hien?.ban_cmnv_vimc
  const ban_cmnv_vimc_department = findDepartmentByCode(ban_cmnv_vimc)
  const ban_cmnv_vimc_code = findDVPTByCode(ban_cmnv_vimc)

  const ban_pc_qtrr_vimc = useMemo(() => {
    if (doi_tuong_thuc_hien?.ban_pc_qtrr_vimc) {
      return doi_tuong_thuc_hien.ban_pc_qtrr_vimc.map((item, index) => {
        const fullName = utils.getNameInCapitalize(item.full_name)
        return index === doi_tuong_thuc_hien?.ban_pc_qtrr_vimc?.length - 1
          ? fullName
          : fullName + ', '
      })
    } else {
      return []
    }
  }, [doi_tuong_thuc_hien?.ban_pc_qtrr_vimc])

  const chuyen_vien_thuc_hien_nddpv = useMemo(() => {
    if (III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv) {
      return III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv
        ?.map(item =>
          BAN_CMNV_VIMC?.users.map(user => {
            if (user.user_code === item) {
              return utils.getNameInCapitalize(user.name_uppercase)
            }
          })
        )
        .flat()
        .filter(Boolean)
    } else {
      return []
    }
  }, [III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv, BAN_CMNV_VIMC])

  const lanh_dao_ban_nddpv2 = useMemo(() => {
    if (III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv2) {
      return III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv2?.map(item =>
        BAN_CMNV_VIMC?.users?.map(user => {
          if (user?.user_code === item) {
            return utils.getNameInCapitalize(user.name_uppercase)
          }
        })
      )
    } else {
      return []
    }
  }, [III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv2, BAN_CMNV_VIMC])

  const lanh_dao_ban = useMemo(() => {
    if (III_kiem_soat_rui_ro?.lanh_dao_ban) {
      Array.from(
        new Set(
          III_kiem_soat_rui_ro.lanh_dao_ban?.map(item => item?.department_name)
        )
      ).map(departmentName => departmentName)
    } else {
      return []
    }
  }, [III_kiem_soat_rui_ro?.lanh_dao_ban])

  const lanh_dao_ban_cqvp = useMemo(() => {
    if (III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp) {
      const filteredItems = III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp?.filter(
        el => !el.value.startsWith('CPN')
      )
      const usrItems = filteredItems
        ?.filter(item => item.value.startsWith('USR'))
        ?.map(item => utils.getNameInCapitalize(item.label))
      return usrItems
    } else {
      return []
    }
  }, [III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp])

  const phong_lanh_dao_ban_cqvp = useMemo(() => {
    if (III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp) {
      const filteredItems = III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp?.filter(
        el => !el.value.startsWith('CPN')
      )
      const department = filteredItems
        ?.filter(item => !item.value.startsWith('USR'))
        ?.map(item => utils.getNameInCapitalize(item.label))

      return department
    } else {
      return []
    }
  }, [III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp])

  return (
    <RiskActionStyle>
      <h6 style={{ marginLeft: 24, fontStyle: 'italic ', marginTop: 8 }}>
        Đối tượng thực hiện:&nbsp;
      </h6>
      <>
        {type === 'NDDPV' ? (
          ''
        ) : (
          <>
            {ban_pc_qtrr_vimc.length > 0 ? (
              <span>
                - Ban PC&QTRR {systemName} (trong trường hợp các Ban CMNV thực
                hiện công việc):&nbsp;
              </span>
            ) : (
              ''
            )}

            <span style={{ ...textValue, ...labelBold }}>
              {ban_pc_qtrr_vimc?.map(item => item)}
            </span>
          </>
        )}
        <p>
          {ban_cmnv_vimc ? (
            <span>
              - Ban CMNV {systemName} (trong trường hợp giao NĐDPV, ĐVPT thực
              hiện công việc; các Ban cmnv chủ trì):&nbsp;
            </span>
          ) : (
            ''
          )}
          {ban_cmnv_vimc && (
            <span style={{ ...textValue, ...labelBold }}>
              {type === 'DVPT'
                ? ban_cmnv_vimc_code?.name
                : ban_cmnv_vimc_department?.name}
            </span>
          )}
        </p>
      </>

      <Divider />

      {type === 'DVPT' ? (
        <h6>IV. KIỂM SOÁT RỦI RO</h6>
      ) : (
        <h6>III. KIỂM SOÁT RỦI RO</h6>
      )}
      <RiskAssessmentItem
        label={'1. Đối với các loại rủi ro đã nhận diện: '}
        data={doi_voi_cac_loai_rui_ro_da_nhan_dien}
        textValue={textValue}
        labelBold={labelBold}
      />
      <RiskAssessmentItem
        label={'2. Đối với đánh giá mức rủi ro: '}
        data={doi_voi_danh_gia_muc_rui_ro}
        textValue={textValue}
        labelBold={labelBold}
      />
      <RiskAssessmentItem2
        label={'3. Loại rủi ro đã đề xuất xử lý: '}
        data={loai_rui_ro_da_de_xuat_xu_ly}
        textValue={textValue}
        labelBold={labelBold}
      />
      <RiskAssessmentItem2
        label={'4. Đánh giá biện pháp xử lý đã đề xuất: '}
        data={danh_gia_bien_phap_xu_ly_da_de_xuat}
        textValue={textValue}
        labelBold={labelBold}
      />
      <RiskAssessmentItem2
        label={'5. Thời gian hoàn thành công việc được giao: '}
        data={thoi_gian_hoan_thanh_cong_viec_duoc_giao}
        textValue={textValue}
        labelBold={labelBold}
      />
      <p>
        <span>6. Ý kiến khác của Ban CMNV {systemName}:&nbsp;</span>
        <span style={{ ...textValue, ...labelBold }}>
          {bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc}
          {bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc ? ': ' : ''}
          {y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc}
        </span>
      </p>
      <>
        <>
          {type === 'NDDPV' ? (
            <h6 style={{ textAlign: 'center' }}>
              BAN CMNV {systemName}{' '}
              {phong_ban_nddpv && <span>: {phong_ban_nddpv}</span>}
            </h6>
          ) : (
            <h6 style={{ textAlign: 'center' }}>
              BAN PC&QTRR {systemName}/ BAN CMNV {systemName}
            </h6>
          )}
          <table style={styleTable}>
            <tbody>
              <tr style={{ backgroundColor: 'white' }}>
                <td
                  style={{
                    ...styleTable2,
                    textAlign: 'center',
                    width: '50%',
                  }}>
                  <div style={{ margin: '0 20px' }}>
                    <b>Chuyên viên thực hiện</b>
                  </div>
                </td>
                <td
                  style={{
                    ...styleTable2,
                    textAlign: 'center',
                  }}>
                  <div style={{ margin: '0 20px' }}>
                    {type === 'NDDPV' ? (
                      <b>Lãnh đạo ban</b>
                    ) : (
                      <b>
                        {lanh_dao_ban?.length > 0
                          ? 'Lãnh đạo ban: '
                          : 'Lãnh đạo ban'}
                        {lanh_dao_ban?.map((departmentName, index, array) => (
                          <span key={index}>
                            {departmentName}
                            {index < array?.length - 1 ? ',' : ''}
                          </span>
                        ))}
                        {renderNameLanhDaoBanT2(
                          III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp?.filter(el =>
                            el.value.startsWith('CPN')
                          )
                        )}
                      </b>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    ...styleTable2,
                    textAlign: 'center',
                  }}>
                  <div>
                    {
                      // Đã ký của chuyên viên thực hiện tuyến 2 - CQVP
                      chuyen_vien_thuc_hien_nddpv_signed
                    }
                  </div>
                  <div
                    style={{
                      marginTop: chuyen_vien_thuc_hien_nddpv_signed
                        ? '5px'
                        : '60px',
                      fontSize: '16px',
                    }}>
                    {type === 'NDDPV'
                      ? chuyen_vien_thuc_hien_nddpv?.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              marginTop: III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv_signed
                                ? '5px'
                                : '60px',
                            }}>
                            {item}
                          </div>
                        ))
                      : chuyen_vien_thuc_hien}
                  </div>
                </td>
                <td
                  style={{
                    ...styleTable2,
                    textAlign: 'center',
                  }}>
                  {type !== 'NDDPV' && (
                    <div style={{ fontSize: '14px' }}>
                      {phong_lanh_dao_ban_cqvp?.map((item, index) => (
                        <div key={index}>{item}</div>
                      ))}
                    </div>
                  )}
                  {/* Đã ký - ban lãnh đạo - tuyến 2 -CQVP - in pdf */}
                  {lanh_dao_ban_signed}
                  <div style={styleDepartment}>
                    <div>
                      {/* Đã ký - lãnh đạo ban - tuyến 2 -DVPT */}
                      {type === 'NDDPV'
                        ? lanh_dao_ban_nddpv2?.map((item, index) => (
                            <div key={index} style={styleSigned}>
                              {item}
                            </div>
                          ))
                        : lanh_dao_ban_cqvp?.map((item, index) => (
                            <div key={index} style={styleSigned}>
                              {item}
                            </div>
                          ))}
                    </div>
                    {type !== 'NDDPV' && (
                      <>
                        <span>{lanh_dao_ban_nddpv}</span>
                        {III_kiem_soat_rui_ro.lanh_dao_ban?.length > 0 ? (
                          <div>
                            {III_kiem_soat_rui_ro.lanh_dao_ban?.map(
                              (item, index) => (
                                <>
                                  <div
                                    style={{
                                      fontSize: '13px',
                                      ...styleSigned,
                                    }}>
                                    {item.position_name}
                                  </div>
                                  <div>
                                    {utils.getNameInCapitalize(item.full_name)}
                                    {index !==
                                      III_kiem_soat_rui_ro.lanh_dao_ban.length -
                                        1 && ', '}
                                  </div>
                                </>
                              )
                            )}{' '}
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      </>
    </RiskActionStyle>
  )
}

export default inject(
  'departmentStore',
  'riskStore'
)(observer(RiskActionDetails2))
