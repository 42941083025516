import { CloseOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Empty,
  Form,
  message,
  Modal,
  Radio,
  Select,
  Tabs,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import TableComponent from '../../components/Common/TableComponent'
import RichText from '../../components/RichEditor/RichText'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import { TemplateTable } from './RiskCreatePageStyled'
import { toJS } from 'mobx'

import { useFilters } from '../../hooks/useFilters'

const RuiRoNhanDienTemplatePropose = ({
  isVisible,
  handleCancelModal,
  riskStore,
  setRuiRoNhanDienPropose,
  multiple,
  section,
  ruiRoNhanDien,
  riskAriseStore,
  dataActions,
  selectedRuiRoNhanDienPropose,
}) => {
  const {
    ruiRoNhanDienType,
    getRuiRoNhanDienType,
    getListTemplate,
    listTemplate,
  } = riskStore
  const [isLoading, setIsLoading] = useState(false)
  const [selectTemplate, setSelectTemplate] = useState([])
  const selectTemplateRef = useRef(selectTemplate)

  const [type, setType] = useState('van_phong_co_quan')
  const [currentFilters, setCurrentFilters] = useFilters({
    type: type,
    page: 0,
    pageSize: 100,
  })
  const [activeTab, setActiveTab] = useState('rui_ro_nhan_dien')
  const [riskAriseList, setRiskAriseList] = useState([])
  const handleTabChange = key => {
    setActiveTab(key)
    setType('van_phong_co_quan')
  }
  const [form] = Form.useForm()

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        await getRuiRoNhanDienType()
        type[0] && (await getListTemplate(type))
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoading(false)
      }
    })()

    return () => {
      // riskStore.clearRiskStore()
      // Không clear dữ liệu của riskUnitsV2, riskDetail
      riskStore.riskList = []
      riskStore.total_count = []
      riskStore.total_page = []
      riskStore.riskUnits = []
      riskStore.ruiRoNhanDienType = []
      riskStore.listTemplate = []
    }
  }, [isVisible])

  useEffect(() => {
    selectTemplateRef.current = selectTemplate
  }, [selectTemplate])

  useEffect(() => {
    setSelectTemplate(selectedRuiRoNhanDienPropose)
  }, [selectedRuiRoNhanDienPropose])
  useEffect(() => {
    section ? setSelectTemplate(ruiRoNhanDien[section] || []) : ''

    return () => {
      if (selectTemplateRef.current?.length === 0) {
        setType('van_phong_co_quan')
      }
    }
  }, [isVisible])
  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        await getListTemplate(type)
        const { data } = await riskAriseStore.getRiskAriseList({
          type: type,
          page: 0,
          pageSize: 100,
        })
        setRiskAriseList(data.data)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoading(false)
      }
    })()
  }, [type])

  const clickCheckBox = record => {
    const isChecked = checkedList[record.type]?.includes(record.id)
    const updatedCheckedList = isChecked
      ? checkedList[record.type].filter(id => id !== record.id)
      : [...(checkedList[record.type] || []), record.id]
    setCheckedList(prevState => ({
      ...prevState,
      [record.type]: updatedCheckedList,
    }))

    setSelectTemplate(prevState => {
      if (isChecked) {
        return prevState.filter(item => item.id !== record.id)
      } else {
        return [...prevState, record]
      }
    })
  }

  const clickCheckBox1 = record => {
    const isChecked = checkedList1[record.type]?.includes(record.id)
    const updatedCheckedList = isChecked
      ? checkedList1[record.type].filter(id => id !== record.id)
      : [...(checkedList1[record.type] || []), record.id]
    setCheckedList1(prevState => ({
      ...prevState,
      [record.type]: updatedCheckedList,
    }))

    setSelectTemplate(prevState => {
      if (isChecked) {
        return prevState.filter(item => item.id !== record.id)
      } else {
        return [...prevState, record]
      }
    })
  }

  const [checkedList, setCheckedList] = useState({})
  const [checkedList1, setCheckedList1] = useState({})
  const onCheckAllChange = e => {
    const allChecked = e.target.checked
    const newCheckedList = allChecked
      ? listTemplate.filter(item => item.type === type).map(item => item.id)
      : []
    setCheckedList(prevState => ({
      ...prevState,
      [type]: newCheckedList,
    }))
    setSelectTemplate(prevState => {
      const existingIds = prevState.map(item => item.id)
      const newItems = listTemplate.filter(
        item => item.type === type && !existingIds.includes(item.id)
      )
      return allChecked
        ? [...prevState, ...newItems]
        : prevState.filter(
            item =>
              !listTemplate.some(
                listItem => listItem.id === item.id && listItem.type === type
              )
          )
    })
  }

  const onCheckAllChange1 = e => {
    const allChecked = e.target.checked
    const newCheckedList = allChecked
      ? riskAriseList.filter(item => item.type === type).map(item => item.id)
      : []
    setCheckedList1(prevState => ({
      ...prevState,
      [type]: newCheckedList,
    }))
    setSelectTemplate(prevState => {
      const existingIds = prevState.map(item => item.id)
      const newItems = riskAriseList.filter(
        item => item.type === type && !existingIds.includes(item.id)
      )
      return allChecked
        ? [...prevState, ...newItems]
        : prevState.filter(
            item =>
              !riskAriseList.some(
                listItem => listItem.id === item.id && listItem.type === type
              )
          )
    })
  }

  const columns = [
    {
      title: (
        <Checkbox
          onChange={onCheckAllChange}
          checked={
            selectTemplate.filter(el => el.type === type && el.rui_ro_nhan_dien)
              .length ===
            listTemplate.filter(el => el.type === type && el.rui_ro_nhan_dien)
              .length
          }
          indeterminate={
            selectTemplate.filter(el => el.type === type && el.rui_ro_nhan_dien)
              .length > 0 &&
            selectTemplate.filter(el => el.type === type && el.rui_ro_nhan_dien)
              .length !==
              listTemplate.filter(el => el.type === type && el.rui_ro_nhan_dien)
                .length
          }
        />
      ),
      dataIndex: 'name',
      width: '5%',
      render: (text, record) => {
        //Điều kiện check cũ được giữ nguyên, chỉ đổi thành biến isChecked
        const isChecked = multiple
          ? selectTemplate?.some(item => record.id === item.id)
          : record.id === selectTemplate?.id

        return (
          <Checkbox checked={isChecked} onClick={() => clickCheckBox(record)} />
        )
      },
      // render: (text, record) => (
      //   <Radio
      //     checked={
      //       multiple
      //         ? selectTemplate?.some(item => record.id === item.id)
      //         : record.id === selectTemplate?.id
      //     }
      //     // onClick={() => handleCheckboxChange(record)}
      //   />
      // ),
    },
    {
      title: 'LĨNH VỰC HOẠT ĐỘNG',
      dataIndex: 'linh_vuc_hoat_dong',
      width: '25%',
      render: text => <RichText htmlText={text || ''}></RichText>,
    },
    {
      title: 'RỦI RO NHẬN DIỆN',
      dataIndex: 'rui_ro_nhan_dien',
      width: '25%',
      render: text => <RichText htmlText={text || ''}></RichText>,
    },
    {
      title: 'NGUYÊN NHÂN',
      dataIndex: 'nguyen_nhan',
      width: '25%',
      render: text => <RichText htmlText={text || ''}></RichText>,
    },
    {
      title: 'BIỆN PHÁP PHÒNG NGỪA, GIẢM THIỂU ',
      dataIndex: 'bien_phap_phong_ngua_giam_thieu',
      width: '55%',
      render: text => <RichText htmlText={text || ''}></RichText>,
    },
  ]

  const columnRiskArise = [
    {
      title: (
        <Checkbox
          onChange={onCheckAllChange1}
          checked={
            selectTemplate.filter(
              el => el.type === type && el.is_deleted === false
            ).length ===
            riskAriseList.filter(
              el => el.type === type && el.is_deleted === false
            ).length
          }
          indeterminate={
            selectTemplate.filter(
              el => el.type === type && el.is_deleted === false
            ).length > 0 &&
            selectTemplate.filter(
              el => el.type === type && el.is_deleted === false
            ).length !==
              riskAriseList.filter(
                el => el.type === type && el.is_deleted === false
              ).length
          }
        />
      ),
      dataIndex: 'name',
      width: '5%',
      render: (text, record) => {
        //Điều kiện check cũ được giữ nguyên, chỉ đổi thành biến isChecked
        const isChecked = multiple
          ? selectTemplate?.some(item => record.id === item.id)
          : record.id === selectTemplate?.id

        return (
          <Checkbox checked={isChecked} onClick={() => clickCheckBox(record)} />
        )
      },
      // render: (text, record) => (
      //   <Radio
      //     checked={
      //       multiple
      //         ? selectTemplate?.some(item => record.id === item.id)
      //         : record.id === selectTemplate?.id
      //     }
      //     // onClick={() => handleCheckboxChange(record)}
      //   />
      // ),
    },
    {
      title: 'CÔNG VIỆC ĐƯỢC GIAO',
      dataIndex: 'assigned_work',
      width: '25%',
      render: text => <RichText htmlText={text || ''}></RichText>,
    },
    {
      title: 'RỦI RO NHẬN DIỆN',
      dataIndex: 'identification_risk',
      width: '25%',
      render: text => <RichText htmlText={text || ''}></RichText>,
    },
    {
      title: 'THỜI GIAN PHÁT SINH',
      dataIndex: 'time_arises',
      width: '25%',
      render: text => <RichText htmlText={text || ''}></RichText>,
    },
    {
      title: 'NGUYÊN NHÂN',
      dataIndex: 'reason',
      width: '25%',
      render: text => <RichText htmlText={text || ''}></RichText>,
    },
  ]

  useEffect(() => {
    const loadList = async filters => {
      try {
        const { data } = await riskAriseStore.getRiskAriseList(currentFilters)
        setRiskAriseList(data.data)
      } catch (error) {
        console.log('🚀 error:', error)
        message.error('Đã có lỗi xảy ra')
      }
    }
    loadList()
  }, [activeTab])

  const tabs = [
    {
      key: 'rui_ro_nhan_dien',
      name: 'Rủi ro nhận diện theo chức năng nhiệm vụ',
    },
    {
      key: 'rui_ro_nhan_dien_phat_sinh',
      name: 'Rủi ro nhận diện theo hoạt động phát sinh',
    },
  ]

  return (
    <Modal
      style={{}}
      width={1000}
      destroyOnClose
      style={{ top: 60, zIndex: 500, position: 'relative' }}
      title={null}
      visible={isVisible}
      maskClosable={false}
      // transitionName=""
      // maskTransitionName=""
      onCancel={() => {
        handleCancelModal()
      }}
      footer={null}
      header={null}>
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        {tabs.map(tab => (
          <Tabs.TabPane tab={tab.name} key={tab.key}>
            <div style={{ flexGrow: 1 }}>{tab.component}</div>
          </Tabs.TabPane>
        ))}
      </Tabs>
      <Select
        style={{ width: '50%', marginBottom: 16 }}
        value={type}
        onChange={value => {
          setType(value)
        }}>
        {ruiRoNhanDienType?.map(truiRoNhanDienTypeype => (
          <Select.Option
            key={truiRoNhanDienTypeype.code}
            value={truiRoNhanDienTypeype.code}>
            {truiRoNhanDienTypeype.name}
          </Select.Option>
        ))}
      </Select>

      <TemplateTable>
        <TableComponent
          columns={activeTab === 'rui_ro_nhan_dien' ? columns : columnRiskArise}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                multiple
                  ? selectTemplate?.some(item => item.id === record.id)
                    ? setSelectTemplate(
                        selectTemplate?.filter(item => item.id !== record.id)
                      )
                    : setSelectTemplate([...selectTemplate, record])
                  : setSelectTemplate(record)
              },
            }
          }}
          dataSource={
            activeTab === 'rui_ro_nhan_dien' ? listTemplate : riskAriseList
          }
          size="small"
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ),
          }}
          // loading={loadingAnimationStore.tableLoading}
        />
      </TemplateTable>

      <FormActionFooter style={{ marginBottom: 0 }}>
        <Button icon={<CloseOutlined />} danger onClick={handleCancelModal}>
          Đóng
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          type={'primary'}
          htmlType={'submit'}
          onClick={() => {
            section
              ? setRuiRoNhanDienPropose({
                  ...ruiRoNhanDien,
                  [section]: selectTemplate,
                })
              : setRuiRoNhanDienPropose(selectTemplate)
            handleCancelModal()
          }}>
          Chọn
        </Button>
      </FormActionFooter>

      <SpinnerInlineComponent
        sizeSpin={'small'}
        tip="Đang tải dữ liệu..."
        isLoading={isLoading}
        backGround={'#ffffffc7'}
      />
    </Modal>
  )
}

export default inject('riskAriseStore')(observer(RuiRoNhanDienTemplatePropose))
