import React, { useState } from 'react'
import { Button, Col, Drawer, Form, message, Row, Switch, Table } from 'antd'
import { inject, observer } from 'mobx-react'
import utils from '../../utils'
import { toJS } from 'mobx'
import ConnectedTrucAccountUserConfigPanel from '../../pages/DocumentManagement/ConnectedDocument/ConnectedTrucAccount/ConnectedTrucAccountUserConfigPanel'
import { useEffect } from 'react'

const UserPermissionPanel = props => {
  const {
    drawerVisible,
    onClose,
    userStore,
    commandStore,
    accountEditStore,
    loadingAnimationStore,
    commonStore,
    authenticationStore,
    accountStore,
    aclStore,
  } = props

  const { aclGroupList } = aclStore
  const { selectedUser } = userStore
  const { commandList } = commandStore

  const [aclGroupCode, setAclGroupCode] = useState({ code: '' })

  const selectedUserCommandCodeArr =
    selectedUser &&
    selectedUser.commands &&
    selectedUser.commands.map(command => command.code)

  const [
    isVisibleConnectedTrucConfigPanel,
    setIsVisibleConnectedTrucConfigPanel,
  ] = useState(false)

  const [selectedCommandCode, setSelectedCommandCode] = useState()

  const handleCloseConnectedTrucConfigPanel = () => {
    setIsVisibleConnectedTrucConfigPanel(false)
  }

  const handleShowConnectedTrucConfigPanel = command => {
    setSelectedCommandCode(command.code)

    loadingAnimationStore.showSpinner(true)
    Promise.all([
      accountEditStore.getAccountsByUserCode(selectedUser.code),
      accountEditStore.getCommandsByUser(selectedUser.code),
    ])
      .then(() => {
        setIsVisibleConnectedTrucConfigPanel(true)
      })
      .finally(() => loadingAnimationStore.showSpinner(false))
  }

  const handleCancelEdit = () => {
    onClose()
  }

  useEffect(() => {
    setAclGroupCode(
      aclGroupList.find(item => item.name === 'Người dùng văn phòng điện tử')
    )
  }, [aclGroupList])

  useEffect(() => {
    ;(async () => {
      try {
        await aclStore.getACLGroupList()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
    return () => {
      aclStore.clearAclGroupList()
    }
  }, [])

  const handleChangeCommand = async (status, commandCode, commandName) => {
    let updatedStatusText
    const commandObj = {
      code: commandCode,
      name: commandName,
    }
    if (commandName === 'Văn phòng điện tử') {
      try {
        const res = await aclStore.getACLDetail(aclGroupCode?.code)
        let assignedUsers = res?.data?.assigned_users || []
        const selectedCommandArr = selectedUser && selectedUser.commands
        if (
          selectedCommandArr &&
          selectedCommandArr.findIndex(
            command => command.code === commandCode
          ) === -1
        ) {
          assignedUsers = [...assignedUsers, selectedUser?.username]

          await aclStore.assignACLGroup({
            users: assignedUsers,
            acl_group_code: aclGroupCode?.code,
          })

          userStore.updateSelectedUser('commands', [
            ...selectedCommandArr,
            commandObj,
          ])
          updatedStatusText = 'kích hoạt'
        } else {
          assignedUsers = assignedUsers.filter(
            username => username !== selectedUser?.username
          )

          await aclStore.assignACLGroup({
            users: assignedUsers,
            acl_group_code: aclGroupCode?.code,
          })

          userStore.updateSelectedUser(
            'commands',
            selectedCommandArr.filter(command => command.code !== commandCode)
          )
          updatedStatusText = 'tắt'
        }
      } catch (error) {
        console.log('Error:', error)
      }
    } else {
      const selectedCommandArr = selectedUser && selectedUser.commands
      if (
        selectedCommandArr &&
        selectedCommandArr.findIndex(
          command => command.code === commandCode
        ) === -1
      ) {
        userStore.updateSelectedUser('commands', [
          ...selectedCommandArr,
          commandObj,
        ])
        updatedStatusText = 'kích hoạt'
      } else {
        userStore.updateSelectedUser(
          'commands',
          selectedCommandArr.filter(command => command.code !== commandCode)
        )
        updatedStatusText = 'tắt'
      }
    }

    //
    loadingAnimationStore.showSpinner(true)
    userStore
      .updateUserCommands(
        selectedUser.code,
        selectedUser.commands.map(command => command.code)
      )
      .then(() => {
        authenticationStore
          .checkCurrentUser()
          .then(() => {
            loadingAnimationStore.showSpinner(false)
            message.success(`Đã ${updatedStatusText} dịch vụ ${commandName}!`)
          })
          .catch(() => {
            loadingAnimationStore.showSpinner(false)
          })
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  const [showAccountManager, setShowAccountManager] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(undefined)

  const handleCloseAccountManager = () => {
    setShowAccountManager(false)
  }

  const handleShowAccountManager = command => {
    setShowAccountManager(true)
    setSelectedAccount(command)
  }

  const commandTableColumns = [
    {
      title: 'Dịch vụ',
      render: record => record.name,
    },
    {
      title: 'Trạng thái',
      render: record => {
        return (
          <Switch
            onChange={status =>
              handleChangeCommand(status, record.code, record.name)
            }
            checked={
              selectedUserCommandCodeArr &&
              selectedUserCommandCodeArr.includes(record.code)
            }
          />
        )
      },
    },
    {
      title: 'Tác vụ',
      render: record => (
        <Button
          onClick={() => handleShowConnectedTrucConfigPanel(record)}
          disabled={
            selectedUserCommandCodeArr &&
            !selectedUserCommandCodeArr.includes(record.code)
          }>
          Quản lý
        </Button>
      ),
    },
  ]

  return (
    <Drawer
      title={'Phân quyền truy cập'}
      placement={'left'}
      visible={drawerVisible}
      width={400}
      onClose={handleCancelEdit}>
      <Form layout={'vertical'} scrollToFirstError>
        <Row type={'flex'} justify={'space-between'} gutter={15}>
          <Col span={12}>
            <Form.Item label="Tên đăng nhập">
              <span style={{ color: commonStore.appTheme.solidColor }}>
                {selectedUser.username}
              </span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Họ tên">
              <span style={{ color: commonStore.appTheme.solidColor }}>
                {selectedUser.name_lowercase &&
                  utils.getNameInCapitalize(selectedUser.name_lowercase)}
              </span>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        rowKey={record => record.code}
        dataSource={commandList}
        columns={commandTableColumns}
        pagination={{ hideOnSinglePage: true }}
      />

      <Row type={'flex'} justify={'end'} style={{ marginTop: 30 }}>
        <Col>
          <Button onClick={handleCancelEdit}>Đóng cửa sổ</Button>
        </Col>
      </Row>

      <ConnectedTrucAccountUserConfigPanel
        selectedCommandCode={selectedCommandCode}
        drawerVisible={isVisibleConnectedTrucConfigPanel}
        onClose={handleCloseConnectedTrucConfigPanel}
      />
    </Drawer>
  )
}

export default inject(
  'userStore',
  'commandStore',
  'loadingAnimationStore',
  'accountEditStore',
  'commonStore',
  'authenticationStore',
  'accountStore',
  'aclStore'
)(observer(UserPermissionPanel))
