import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Modal, Space, TreeSelect } from 'antd'
import {
  FormButtonGroup,
  ModalDescription,
  ModalTitle,
} from './ChuTruongDetailStyled'
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { POLICY_STATUS } from '../../constants'

const { SHOW_CHILD } = TreeSelect

const ChuTruongApprovalModal = props => {
  const [form] = Form.useForm()

  const {
    visible,
    onCancel,
    onOk,
    commonStore,
    chuTruongStore,
    authenticationStore,
    departmentStore,
  } = props

  const { departmentList } = departmentStore
  const { selectedPolicy } = chuTruongStore
  const { currentUser } = authenticationStore

  const [departmentListSelectUser, setDepartmentListSelectUser] = useState([])

  const handleCloseModal = useCallback(() => {
    onCancel()
    form.resetFields()
  }, [onCancel])

  const onFinish = values => {
    onOk(values.user_name)
    handleCloseModal()
  }

  const isPersonInCharge = () => {
    if (selectedPolicy.status === POLICY_STATUS.COMPLETE) return
    if (!currentUser) return
    return (
      selectedPolicy.assignees.find(assignee => assignee.permission === 'LEAD')
        ?.assignee_code === currentUser.username
    )
  }

  const checkIsLastStep = () => {
    switch (selectedPolicy.numOfStep) {
      case '2':
        return selectedPolicy.currentStep === '2'
      case '3':
        return selectedPolicy.currentStep === '3'
      default:
        return true
    }
  }

  useEffect(() => {
    setDepartmentListSelectUser(
      departmentList
        .filter(el => el.value !== currentUser.username)
        .map(user => {
          if (user.code) {
            return {
              ...user,
              disabled: true,
            }
          }
          return user
        })
    )
  }, [departmentList])

  return (
    <Modal visible={visible} onCancel={handleCloseModal} footer={null}>
      <ModalTitle>
        <ExclamationCircleOutlined
          style={{ color: commonStore.appTheme.solidColor, marginRight: 8 }}
        />
        {selectedPolicy.currentStep === selectedPolicy.numOfStep
          ? 'Duyệt chủ trương'
          : 'Chuyển giai đoạn'}
      </ModalTitle>
      <ModalDescription>
        {isPersonInCharge() && !checkIsLastStep()
          ? 'Chuyển lãnh đạo khác xử lý'
          : 'Bạn có muốn duyệt chủ trương này?'}
      </ModalDescription>
      <Form
        onFinish={onFinish}
        layout={'vertical'}
        style={{ paddingLeft: 25, marginTop: 15 }}
        scrollToFirstError
        form={form}>
        {isPersonInCharge() && !checkIsLastStep() ? (
          <Form.Item
            name={'user_name'}
            rules={[
              { required: true, message: 'Vui lòng chọn lãnh đạo phê duyệt!' },
            ]}>
            <TreeSelect
              treeDataSimpleMode
              treeData={departmentListSelectUser}
              style={{ width: '100%' }}
              showArrow
              allowClear
              showSearch
              filterTreeNode
              treeNodeFilterProp={'name'}
              showCheckedStrategy={SHOW_CHILD}
              placeholder={'Chọn lãnh đạo trực tiếp phê duyệt'}
            />
          </Form.Item>
        ) : null}
        <FormButtonGroup>
          <Space>
            <Button onClick={handleCloseModal} danger icon={<CloseOutlined />}>
              Huỷ
            </Button>
            <Button
              type={'primary'}
              htmlType={'submit'}
              icon={<CheckOutlined />}>
              {selectedPolicy.currentStep === selectedPolicy.numOfStep
                ? 'Duyệt'
                : 'Chuyển giai đoạn'}
            </Button>
          </Space>
        </FormButtonGroup>
      </Form>
    </Modal>
  )
}

ChuTruongApprovalModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  commonStore: PropTypes.object,
  chuTruongStore: PropTypes.object,
  authenticationStore: PropTypes.object,
}

export default memo(
  inject(
    'commonStore',
    'chuTruongStore',
    'authenticationStore',
    'departmentStore'
  )(observer(ChuTruongApprovalModal))
)
