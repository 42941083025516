import React, { useCallback, useEffect, useState } from 'react'
import { Button, message, Steps, Tag, Tooltip, Typography } from 'antd'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { blue, redPrimary } from '../../../color'
import { UserOutlined } from '@ant-design/icons'
import { AvatarHasName } from '../../../components/Common/Avatar'
import { ASSIGNEE_TYPE, PROPOSAL_STATUS } from '../../../constants'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup/SelectPeoplePopup'

const { Step } = Steps

const StepsWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  position: relative;
  .steps {
    transform: translateX(30px);
    .ant-steps-item-title,
    .ant-steps-item-description {
      color: #000000d9 !important;
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
      color: #000000d9 !important;
      font-weight: 500;
    }

    .ant-steps-item-content {
      width: 185px !important;
      transform: translateX(-30px);
    }
    .tag-selected {
      border-radius: 35px;
      padding: 2px 8px 2px 3px;
      display: flex;
      margin-right: 0;
      align-items: center;
    }
  }
`

const getInfoUser = (username, departmentWithUserList) => {
  const user = {
    full_name: username,
    image_id: null,
    username: username,
  }
  departmentWithUserList.forEach(department => {
    const userTarget = department.users.find(
      user => user.user_name === username
    )
    if (userTarget) {
      user.full_name = userTarget.name_uppercase || username
      user.image_id = userTarget.image_id
    }
  })
  return user
}

const renderStatus = (status, isRejected) => {
  const styleTag = {
    marginTop: 8,
    borderRadius: 10,
  }

  if (status === PROPOSAL_STATUS.PENDING) {
    return (
      <Tag
        color={isRejected ? null : 'geekblue'}
        style={{
          ...styleTag,
          opacity: isRejected ? 0.5 : 1,
        }}>
        Đến lượt duyệt
      </Tag>
    )
  }
  if (status === PROPOSAL_STATUS.WAITING) {
    return (
      <Tag
        color={isRejected ? null : 'orange'}
        style={{
          ...styleTag,
          opacity: isRejected ? 0.5 : 1,
        }}>
        Chờ duyệt
      </Tag>
    )
  }

  if (status === PROPOSAL_STATUS.CREATED) {
    return (
      <Tag color={'cyan'} style={styleTag}>
        Đã tạo
      </Tag>
    )
  }
  if (status === PROPOSAL_STATUS.APPROVED) {
    return (
      <Tag color={'green'} style={styleTag}>
        Đã duyệt
      </Tag>
    )
  }
  if (status === PROPOSAL_STATUS.REJECTED) {
    return (
      <Tag color={'red'} style={styleTag}>
        Đã từ chối
      </Tag>
    )
  }
}

const ASSIGNEE_TYPE_CONFIG = [
  ASSIGNEE_TYPE.CREATE,
  ASSIGNEE_TYPE.COMBINER,
  ASSIGNEE_TYPE.HANDLER,
  ASSIGNEE_TYPE.LEADER,
]

const ProposalAdvanceStepApprove = props => {
  const { proposalAdvanceStore, selectPeopleStore, approvalLevelList } = props

  const { originDepartmentWithUserList, selectUserData } = selectPeopleStore

  const [currentStep, setCurrentStep] = useState(0)
  const [approvalLevels, setApprovalLevels] = useState([])
  const [isVisibleSelectUserPopup, setIsVisibleSelectUserPopup] = useState(
    false
  )
  const [statusStep, setStatusStep] = useState('process')

  useEffect(() => {
    const length = approvalLevelList.length
    if (approvalLevelList.length === 0) return
    let step = 0
    const pendingStep = approvalLevelList.find(
      item => item.status === PROPOSAL_STATUS.PENDING
    )
    const endStep = approvalLevelList[length - 1]
    const stepRejected = approvalLevelList.find(
      item => item.status === PROPOSAL_STATUS.REJECTED
    )
    if (pendingStep) {
      step = pendingStep.level
    }
    if (endStep.status === PROPOSAL_STATUS.APPROVED) {
      step = length
    }
    if (stepRejected) {
      step = stepRejected.level
      setStatusStep('error')
    }
    setCurrentStep(step)
  }, [approvalLevelList])

  useEffect(() => {
    const approvalLevelsUpdate = approvalLevels.map(item => {
      if (!item.allow_edit) {
        return item
      }
      const assignTypeIndex = approvalLevels
        .filter(el => el.allow_edit)
        .findIndex(el => el.level === item.level)
      const getUser = index => {
        if (![0, 1, 2, 3].includes(index)) {
          return {}
        }
        return selectUserData[ASSIGNEE_TYPE_CONFIG[index]]?.length > 0
          ? selectUserData[ASSIGNEE_TYPE_CONFIG[index]][0]
          : {}
      }
      return {
        ...item,
        username: getUser(assignTypeIndex).id,
        user: {
          full_name: getUser(assignTypeIndex).full_name,
          image_id: getUser(assignTypeIndex).image_id,
          username: getUser(assignTypeIndex).id,
        },
      }
    })
    setApprovalLevels(approvalLevelsUpdate)
  }, [selectUserData])

  useEffect(() => {
    proposalAdvanceStore.setDefaultApprovalConfigUpdate(approvalLevels)
  }, [approvalLevels])

  useEffect(() => {
    const approvalLevelConvert = approvalLevelList.map(item => {
      return {
        ...item,
        user: getInfoUser(item.username, originDepartmentWithUserList),
      }
    })
    const levelAllowEditList = approvalLevelConvert.filter(
      item => item.allow_edit
    )
    let create = null
    let combiner = null
    let handler = null
    let leader = null
    levelAllowEditList.forEach((item, index) => {
      if (!item.username) return
      const user = getInfoUser(item.username, originDepartmentWithUserList)
      if (index === 0) {
        create = user
      }
      if (index === 1) {
        combiner = user
      }
      if (index === 2) {
        handler = user
      }
      if (index === 3) {
        leader = user
      }
    })
    selectPeopleStore.setSelectUserData({
      ...selectUserData,
      [ASSIGNEE_TYPE.CREATE]: create
        ? [
            {
              assignee_type: ASSIGNEE_TYPE.USER,
              full_name: create.full_name,
              id: create.username,
              image_id: create.image_id,
              permission: ASSIGNEE_TYPE.CREATE,
            },
          ]
        : [],
      [ASSIGNEE_TYPE.COMBINER]: combiner
        ? [
            {
              assignee_type: ASSIGNEE_TYPE.USER,
              full_name: combiner.full_name,
              id: combiner.username,
              image_id: combiner.image_id,
              permission: ASSIGNEE_TYPE.COMBINER,
            },
          ]
        : [],
      [ASSIGNEE_TYPE.HANDLER]: handler
        ? [
            {
              assignee_type: ASSIGNEE_TYPE.USER,
              full_name: handler.full_name,
              id: handler.username,
              image_id: handler.image_id,
              permission: ASSIGNEE_TYPE.HANDLER,
            },
          ]
        : [],
      [ASSIGNEE_TYPE.LEADER]: leader
        ? [
            {
              assignee_type: ASSIGNEE_TYPE.USER,
              full_name: leader.full_name,
              id: leader.username,
              image_id: leader.image_id,
              permission: ASSIGNEE_TYPE.LEADER,
            },
          ]
        : [],
    })
    setApprovalLevels(approvalLevelConvert)
  }, [approvalLevelList, originDepartmentWithUserList])

  const handleSubmitSelectUser = useCallback(() => {
    setIsVisibleSelectUserPopup(false)
  }, [])

  const handleOpenSelectUser = level => {
    let userType = null
    const levelAllowEditList = approvalLevelList.filter(item => item.allow_edit)
    const indexLevelAllowEdit = levelAllowEditList.findIndex(
      item => item.level === level
    )
    if (indexLevelAllowEdit !== -1) {
      userType = ASSIGNEE_TYPE_CONFIG[indexLevelAllowEdit]
    }
    if (userType) {
      selectPeopleStore.setAssigneePopupType(userType)
      setIsVisibleSelectUserPopup(true)
    } else {
      return message.warning('Không rõ chọn người loại nào!')
    }
  }

  const renderTitleStep = level => {
    return (
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <div>
          {(level.level === 1 || level.level === 2) && (
            <span style={{ color: redPrimary }}>* </span>
          )}
          <span>{level.name}</span>
        </div>
        {level.allow_edit && (
          <Tooltip title="Chọn người dùng" color={blue}>
            <Button
              icon={<UserOutlined style={{ color: blue }} />}
              onClick={() => handleOpenSelectUser(level.level)}
            />
          </Tooltip>
        )}
      </div>
    )
  }

  const renderDescStep = level => {
    const assignTypeIndex = approvalLevelList
      .filter(el => el.allow_edit)
      .findIndex(item => item.level === level.level)
    const handleDeleteUser = () => {
      if ([0, 1, 2, 3].includes(assignTypeIndex)) {
        selectPeopleStore.handleRemoveSelect({
          assignee_type: ASSIGNEE_TYPE.USER,
          id: level.user.username,
          permission: ASSIGNEE_TYPE_CONFIG[assignTypeIndex],
        })
      }
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: level.allow_edit ? 6 : 14,
        }}>
        {level.user.username ? (
          <Tag
            className={'tag-selected'}
            color={'blue'}
            closable={level.allow_edit}
            onClose={handleDeleteUser}>
            <AvatarHasName
              imgId={level.user?.image_id}
              size={22}
              name={level.user?.full_name}
            />
          </Tag>
        ) : (
          <Tag style={{ marginTop: 6, borderRadius: 10 }}>Chưa gán người!</Tag>
        )}
        {renderStatus(level.status, statusStep === 'error')}
        {level.comment && level.comment !== '' && (
          <div style={{ marginTop: 4, fontStyle: 'italic' }}>
            <Typography.Paragraph
              ellipsis={{
                rows: 15,
                expandable: true,
                onEllipsis: ellipsis => {
                  console.log('Ellipsis changed:', ellipsis)
                },
              }}>
              <span style={{ fontWeight: 500, marginRight: 6 }}>Lý do:</span>
              {level.comment}
            </Typography.Paragraph>
          </div>
        )}
      </div>
    )
  }
  return (
    <StepsWrapper>
      <Steps
        status={statusStep}
        className={'steps'}
        labelPlacement={'vertical'}
        current={currentStep}
        size={'small'}>
        {approvalLevels.map(level => {
          return (
            <Step
              key={level.level}
              title={renderTitleStep(level)}
              description={renderDescStep(level)}
            />
          )
        })}
      </Steps>
      <SelectPeoplePopup
        onlyOneUser
        handleCancelSelectUser={handleSubmitSelectUser}
        handleSubmitSelectUser={handleSubmitSelectUser}
        isVisibleSelectPeoplePopup={isVisibleSelectUserPopup}
      />
    </StepsWrapper>
  )
}

ProposalAdvanceStepApprove.propTypes = {}
export default inject(
  'authenticationStore',
  'proposalAdvanceStore',
  'selectPeopleStore',
)(observer(ProposalAdvanceStepApprove))
