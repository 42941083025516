// Other
import { inject, observer } from 'mobx-react'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Link, useHistory, withRouter } from 'react-router-dom'
import { WorkPageWrapper } from './TextDistributionProductsStylePage'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import ManagerInventoryTemplateList from './ManagerInventoryTemplateList'
import ManagerInventoryCreate from './ManagerInventoryCreate'
import { runInAction } from 'mobx'

// Ant design
import { Button, Menu, message, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// Component
import PageTitle from '../../components/PageTitle'

// Other
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'

const ManagerInventoryPage = props => {
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false)

  const handleToggleCreateModal = bool => event => {
    setIsVisibleCreateModal(bool)
    if (!bool) {
      runInAction(() => {
        props.managerInventoryStore.ManagerInventoryLevel = []
      })
    }
  }
  return (
    // isVisibleDrawerWork={isVisibleDrawerWork}
    <WorkPageWrapper>
      <DashboardLayout>
        <Helmet>
          <title>Văn phòng phẩm | Quản lý kho</title>
        </Helmet>
        <PageTitle location={props.location}>
          <AccessControlAction check={true}>
            <Button
              type={'primary'}
              icon={<PlusOutlined />}
              onClick={handleToggleCreateModal(true)}>
              Tạo mới
            </Button>
          </AccessControlAction>
        </PageTitle>
        <ContentBlockWrapper>
          <Menu
            mode="horizontal"
            selectedKeys={[props.location?.pathname]}
            style={{ marginBottom: 16 }}>
            {/* <Menu.Item key="/manager-inventory">
              <Link to={'/manager-inventory'}>Cấp duyệt</Link>
            </Menu.Item> */}
            {/* </AccessControlAction> */}
            <Menu.Item key="/manager-inventory/inventory-asset">
              <Link to={'/manager-inventory/inventory-asset'}>Tài sản</Link>
            </Menu.Item>

            <Menu.Item key="/manager-inventory/manage-inventory-asset">
              <Link to={'/manager-inventory/manage-inventory-asset'}>
                Loại văn phòng phẩm
              </Link>
            </Menu.Item>
          </Menu>
          <ManagerInventoryTemplateList />
        </ContentBlockWrapper>
      </DashboardLayout>
      <Modal
        width={1000}
        title={'Tạo mới cấp duyệt'}
        visible={isVisibleCreateModal}
        maskClosable={false}
        onCancel={handleToggleCreateModal(false)}
        footer={null}
        destroyOnClose>
        {isVisibleCreateModal && (
          <ManagerInventoryCreate onCancel={handleToggleCreateModal(false)} />
        )}
      </Modal>
    </WorkPageWrapper>
  )
}

export default inject(
  'managerInventoryStore',
  'loadingAnimationStore',
  'authenticationStore',
  'notificationStore',
  'selectPeopleStore'
)(observer(ManagerInventoryPage))
