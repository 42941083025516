import React, { Fragment, memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Container } from '../../layouts/Container/Container'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Switch,
  Table,
  Tooltip,
  Typography,
  Upload,
} from 'antd'
import {
  DeleteOutlined,
  FileTextOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import validator from '../../validator'
import { FormActionFooter } from './ConnectedCommitteeDocumentOutgoingDocCreatePageStyled'
import { inject, observer } from 'mobx-react'
import EmptyContent from '../../components/EmptyContent'
import moment from 'moment'
import {
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_LIST,
  ISO_DATE_FORMAT,
} from '../../constants'
import { TableFooterActionWrapper } from '../ConnectedCommitteeDocumentOutgoingDocPage/ConnectedCommitteeDocumentOutgoingDocPageStyled'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { toJS } from 'mobx'
import {
  UploadFileListItem,
  UploadFileListWrapper,
} from '../ConnectedDocumentOutgoingDocCreatePage/ConnectedDocumentOutgoingDocCreatePageStyled'

const { Option } = Select
const { TextArea } = Input
const { Paragraph } = Typography

const trimOverlengthString = (string, length) => {
  if (string.length >= length) {
    return (
      <Tooltip title={string}>
        {string.substring(0, length).concat('...')}
      </Tooltip>
    )
  }
  return string
}
const renderBussinessDocTypeName = typeId => {
  switch (typeId) {
    case '0':
      return 'Tạo văn bản mới'
    case '1':
      return 'Thu hồi văn bản đã gửi'
    case '2':
      return 'Cập nhật văn bản đã gửi'
    case '3':
      return 'Thay thế văn bản đã gửi'
    default:
      return ''
  }
}

const ConnectedCommitteeDocumentOutgoingDocCreatePage = props => {
  const {
    history,
    connectedCommitteeDocumentStore,
    fileStore,
    loadingAnimationStore,
    authenticationStore,
    organizationStore,
    connectedDocumentStore,
  } = props

  const { currentUser } = authenticationStore
  const { organizationList } = organizationStore
  const { setFilter } = connectedCommitteeDocumentStore

  const { dataReceiveDocument } = connectedDocumentStore

  const [form] = Form.useForm()
  const [formRenameFile] = Form.useForm()

  // For Rename file
  const [fileExt, setFileExt] = useState(undefined)
  const [fileTargetRename, setFileTargetRename] = useState(null)
  const [isRenameFileFromInternal, setIsRenameFileFromInternal] = useState(
    false
  )
  const [isModalVisibleRenameFile, setIsModalVisibleRenameFile] = useState(
    false
  )
  // List file nhận từ thiết bị
  const [fileList, setFileList] = useState([])
  // List file nhận từ trục
  const [fileFromInternal, setFileFromInternal] = useState([])

  const [showDocumentList, setShowDocumentList] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [showCompanyReceiver, setShowCompanyReceiver] = useState(true)

  const handleCreateOutgoingDocument = submitData => {
    connectedCommitteeDocumentStore
      .createOutgoingCommitteeDocument(submitData)
      .then(() => {
        handleCancelCreate()
        message.success(`Tạo văn bản thành công`)
      })
      .catch(error => {
        message.error(error.vi)
      })
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  const handleRevocationOutgoingDocument = submitData => {
    connectedCommitteeDocumentStore
      .revocationOutgoingCommitteeDocument(submitData)
      .then(() => {
        handleCancelCreate()
        message.success(`Gửi văn bản yêu cầu thu hồi thành công`)
      })
      .catch(error => {
        message.error(error.vi)
      })
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  const handleReplaceOutgoingDocument = submitData => {
    connectedCommitteeDocumentStore
      .replaceOutgoingCommitteeDocument(submitData)
      .then(() => {
        handleCancelCreate()
        message.success(`Thay thế văn bản thành công`)
      })
      .catch(error => {
        message.error(error.vi)
      })
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  const onFinish = async values => {
    const {
      appendixes,
      bussiness_doc_reason,
      bussiness_doc_type,
      code_notation,
      code_number,
      competence,
      content,
      direction,
      due_date,
      file_upload,
      full_name,
      page_amount,
      paper,
      place,
      position,
      priority,
      promulgation_amount,
      promulgation_date,
      sphere_of_promulgation,
      steering_type,
      subject,
      to_places,
      type,
      type_name,
      typer_notation,
      organizations,
    } = values
    loadingAnimationStore.showSpinner(true)
    // download -> upload file từ văn bản nội bộ vimc
    let fileBoldFormInternalList = []
    await Promise.all(
      fileFromInternal.map(file => fileStore.downloadAttachment(file.id))
    )
      .then(response => {
        fileBoldFormInternalList = response
      })
      .catch(err => {
        console.log(err)
        message.warning(
          'Download file từ trục liên thông có lỗi, bạn hãy thử nhận lại văn bản!'
        )
      })
    const uploadedFileFromInternal = await Promise.all(
      fileBoldFormInternalList.map((fileBold, index) => {
        const formData = new FormData()
        formData.append('file', fileBold.data, fileFromInternal[index].name)
        return fileStore.uploadFile(formData)
      })
    )
    const idFileUploadFromInternal = uploadedFileFromInternal.map(
      file => file.data.file_id
    )

    const batchUploadArr = []
    fileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file.originFileObj, file.name)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })

    Promise.all(batchUploadArr)
      .then(response => {
        // Tạo văn bản mới
        if (bussiness_doc_type === '0') {
          const submitValues = {
            attachments: response
              .map(response => response.data.file_id)
              .concat(idFileUploadFromInternal),
            bussiness: {
              bussiness_doc_reason: bussiness_doc_reason,
              document_id: '',
              paper: '0',
            },
            message_header: {
              code: {
                code_notation: code_notation,
                code_number: code_number,
              },
              content: content ? content : '',
              document_type: {
                type: type,
                type_name: type_name ? type_name : '',
              },
              due_date: due_date
                ? moment(due_date).format(ISO_DATE_FORMAT)
                : '',
              other_info: {
                appendixes: appendixes ? [appendixes] : [],
                direction: direction,
                page_amount: page_amount ? page_amount : '',
                priority: priority,
                promulgation_amount: promulgation_amount
                  ? promulgation_amount
                  : '',
                sphere_of_promulgation: sphere_of_promulgation
                  ? sphere_of_promulgation
                  : '',
                typer_notation: typer_notation ? typer_notation : '',
              },
              promulgation_info: {
                place: place ? place : '',
                promulgation_date: moment(promulgation_date).format(
                  ISO_DATE_FORMAT
                ),
              },
              signer_info: {
                competence: competence ? competence : '',
                full_name: full_name ? full_name : '',
                position: position ? position : '',
              },
              steering_type: steering_type,
              subject: subject,
              to_places: to_places ? [to_places] : [],
            },
            organ_id: organizations,
          }
          handleCreateOutgoingDocument(submitValues)
        }
        // Tạo văn bản mới để xin thu hồi các văn bản đã ban hành
        if (bussiness_doc_type === '1') {
          const submitValues = {
            attachments: response.map(response => response.data.file_id),
            bussiness: {
              bussiness_doc_reason: bussiness_doc_reason,
              document_id: '',
              paper: '0',
            },
            message_header: {
              code: {
                code_notation: code_notation,
                code_number: code_number,
              },
              content: content ? content : '',
              document_type: {
                type: type,
                type_name: type_name ? type_name : '',
              },
              due_date: due_date
                ? moment(due_date).format(ISO_DATE_FORMAT)
                : '',
              other_info: {
                appendixes: appendixes ? [appendixes] : [],
                page_amount: page_amount ? page_amount : '',
                priority: priority,
                promulgation_amount: promulgation_amount
                  ? promulgation_amount
                  : '',
                sphere_of_promulgation: sphere_of_promulgation
                  ? sphere_of_promulgation
                  : '',
                typer_notation: typer_notation ? typer_notation : '',
              },
              promulgation_info: {
                place: place ? place : '',
                promulgation_date: moment(promulgation_date).format(
                  ISO_DATE_FORMAT
                ),
              },
              signer_info: {
                competence: competence ? competence : '',
                full_name: full_name ? full_name : '',
                position: position ? position : '',
              },
              steering_type: steering_type,
              subject: subject,
              to_places: to_places ? [to_places] : [],
            },
            revocation_doc_id: selectedRowKeys[0],
          }
          handleRevocationOutgoingDocument(submitValues)
        }
        // Tạo văn bản mới thay thế cho các văn bản đã ban hành
        if (bussiness_doc_type === '3') {
          const submitValues = {
            attachments: response.map(response => response.data.file_id),
            bussiness: {
              bussiness_doc_reason: bussiness_doc_reason,
              document_id: '',
              paper: '0',
            },
            message_header: {
              code: {
                code_notation: code_notation,
                code_number: code_number,
              },
              content: content ? content : '',
              document_type: {
                type: type,
                type_name: type_name ? type_name : '',
              },
              due_date: due_date
                ? moment(due_date).format(ISO_DATE_FORMAT)
                : '',
              other_info: {
                appendixes: appendixes ? [appendixes] : [],
                direction: direction,
                page_amount: page_amount ? page_amount : '',
                priority: priority,
                promulgation_amount: promulgation_amount
                  ? promulgation_amount
                  : '',
                sphere_of_promulgation: sphere_of_promulgation
                  ? sphere_of_promulgation
                  : '',
                typer_notation: typer_notation ? typer_notation : '',
              },
              promulgation_info: {
                place: place ? place : '',
                promulgation_date: moment(promulgation_date).format(
                  ISO_DATE_FORMAT
                ),
              },
              signer_info: {
                competence: competence ? competence : '',
                full_name: full_name ? full_name : '',
                position: position ? position : '',
              },
              steering_type: steering_type,
              subject: subject,
              to_places: to_places ? [to_places] : [],
            },
            replacement_doc_id: selectedRowKeys[0],
          }
          handleReplaceOutgoingDocument(submitValues)
        }
      })
      .catch(error => {
        message.error(error.vi)
        loadingAnimationStore.showSpinner(false)
      })
  }
  const handleAddFileToUpload = () => false

  const handleChangeFile = info => {
    if (info.fileList.length === 0) {
      form.setFieldsValue({ file_upload: undefined })
      setFileList([])
      return
    }
    const filteredFileList = info.fileList.filter(
      (elem, index, fileList) =>
        fileList.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }

  const handleRemoveFileFromUploadList = useCallback(
    fileUID => {
      const newFileList = fileList.filter(file => file.uid !== fileUID)
      setFileList(newFileList)
      if (newFileList.length === 0) {
        form.setFieldsValue({
          attachments: [],
        })
      }
    },
    [fileList]
  )

  const handleCancelCreate = () => {
    form.resetFields()
    history.push('/connected-committee-document/outgoing-document')
  }
  const handleChangeBussinessDocType = value => {
    console.log(value)
    if (value === '1' || value === '3') {
      setShowDocumentList(true)
      setShowCompanyReceiver(false)
    } else {
      setSelectedRowKeys([])
      setShowDocumentList(false)
      setShowCompanyReceiver(true)
    }
  }

  const TextWrap = props => {
    return (
      <a
        style={{
          display: 'inline-block',
          color: 'rgba(0,0,0)',
          wordWrap: 'break-word',
          wordBreak: 'break-all',
        }}>
        {props.children}
      </a>
    )
  }
  const tableColumns = [
    {
      title: 'Ngày gửi',
      width: 200,
      render: record => (
        <TextWrap>
          {moment(record.sent_time).format(DATE_FORMAT_DEFAULT)}
        </TextWrap>
      ),
    },
    {
      title: 'Số văn bản',
      width: 200,
      render: record => (
        <TextWrap>
          {
            <Tooltip title={record.code}>
              <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2 }}>
                {record.code}
              </Paragraph>
            </Tooltip>
          }
        </TextWrap>
      ),
    },
    {
      title: 'Trích yếu',
      width: 250,
      render: record => (
        <TextWrap>
          <Tooltip title={record.title}>
            <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2 }}>
              {record.title}
            </Paragraph>
          </Tooltip>
        </TextWrap>
      ),
    },
    {
      title: 'Loại nghiệp vụ',
      width: 300,
      render: record => (
        <TextWrap>
          {record.bussiness_doc_type &&
            trimOverlengthString(
              renderBussinessDocTypeName(record.bussiness_doc_type),
              20
            )}
        </TextWrap>
      ),
    },
  ]
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    setFilter('outgoingCommitteeDocumentListFilterByBussinessDocType', 0)
    setFilter('outgoingCommitteeDocumentListFilterByStatus', [
      '01',
      '03',
      '04',
      '05',
      '00',
    ])
    connectedCommitteeDocumentStore
      .getOutgoingCommitteeDocumentList()
      .finally(() => loadingAnimationStore.showSpinner(false))
    return () => {
      connectedCommitteeDocumentStore.clearOutgoingCommitteeDocumentListFilter()
      connectedCommitteeDocumentStore.clearOutgoingCommitteeDocumentList()
    }
  }, [])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    organizationStore
      .getAllOrganization()
      .then(() => {
        loadingAnimationStore.showSpinner(false)
      })
      .catch(error => message.error(error.vi))
    return () => {
      organizationStore.clearOrganizationList()
    }
  }, [])

  const getTypeFile = nameFile => '.' + nameFile.split('.').pop()
  const removeFileExt = fileName => {
    return fileName.split('.').slice(0, -1).join('.')
  }

  const handleRemoveFileFromInternal = file_id => {
    setFileFromInternal(
      toJS([...fileFromInternal]).filter(file => file.id !== file_id)
    )
  }
  const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    })
  }

  const submitRenameFile = value => {
    const index = fileTargetRename?.index
    setIsModalVisibleRenameFile(false)
    // đổi tên file nhận từ trục
    if (isRenameFileFromInternal) {
      const fileFromInternalAfterRename = [...fileFromInternal]
      fileFromInternalAfterRename[index] = {
        id: fileTargetRename.id,
        name: value.rename + fileExt,
      }
      setFileFromInternal(fileFromInternalAfterRename)
      return
    }
    // đổi tên file nhận từ thiết bị
    const newFileList = [...fileList]
    newFileList[index].name = value.rename + fileExt
    newFileList[index].originFileObj = renameFile(
      newFileList[index].originFileObj,
      newFileList[index].name
    )
    setFileList(newFileList)
  }
  useEffect(() => {
    if (
      !dataReceiveDocument ||
      !dataReceiveDocument.attachments ||
      !dataReceiveDocument.attachments.length
    )
      return
    setFileFromInternal([...toJS(dataReceiveDocument.attachments)])
  }, [])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạo văn bản đi | Quản lý VB nội bộ</title>
      </Helmet>
      <PageTitle location={props.location} title={'Tạo văn bản đi'} />
      <ContentBlockWrapper>
        <Container maxWidth={820}>
          <Form
            form={form}
            scrollToFirstError={true}
            name={'create-outgoing-document'}
            layout={'vertical'}
            onFinish={onFinish}
            initialValues={{
              type: '2',
              priority: '0',
              type_name: 'Công văn',
              promulgation_amount: 1,
              page_amount: 1,
              direction: false,
              paper: true,
              steering_type: '0',
              full_name: dataReceiveDocument && dataReceiveDocument.signer,
              subject: dataReceiveDocument && dataReceiveDocument.title,
              promulgation_date:
                dataReceiveDocument &&
                dataReceiveDocument.date_issued &&
                moment(dataReceiveDocument.date_issued),
            }}>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Số văn bản'}
                  name={'code_number'}
                  rules={[
                    { required: true, message: 'Vui lòng nhập số văn bản!' },
                    { validator: validator.validateInputString },
                  ]}>
                  <Input maxLength={11} placeholder={'Nhập số văn bản đi'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Số hiệu'}
                  name={'code_notation'}
                  rules={[
                    {
                      required: true,
                      message: ' Vui lòng nhập số hiệu văn bản!',
                    },
                    { validator: validator.validateInputString },
                  ]}>
                  <Input maxLength={30} placeholder={'Nhập số hiệu văn bản'} />
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item label={'Ban hành'} name={'place'}>
                  <Input
                    maxLength={50}
                    placeholder={'Nhập cơ quan ban hành văn bản'}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Ngày ban hành'}
                  name={'promulgation_date'}
                  rules={[
                    {
                      required: true,
                      message: ' Vui lòng chọn ngày ban hành văn bản!',
                    },
                  ]}>
                  <DatePicker
                    style={{ width: '100%' }}
                    format={DATE_FORMAT_LIST}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label={'Loại văn bản'} name={'type'}>
              <Select
                notFoundContent={<EmptyContent />}
                placeholder={'Chọn loại văn bản'}>
                <Option value={'2'}>Văn bản hành chính</Option>
                <Option value={'1'}>Văn bản quy phạm pháp luật</Option>
              </Select>
            </Form.Item>

            <Form.Item label={'Tên loại văn bản'} name={'type_name'}>
              <Input maxLength={100} placeholder={'Nhập tên loại văn bản'} />
            </Form.Item>

            <Form.Item
              label={'Tiêu đề'}
              name={'subject'}
              rules={[
                { required: true, message: ' Vui lòng nhập tiêu đề văn bản!' },
                { validator: validator.validateInputString },
              ]}>
              <Input maxLength={500} placeholder={'Nhập tiêu đề văn bản'} />
            </Form.Item>

            <Form.Item label={'Nội dung văn bản'} name={'content'}>
              <TextArea
                rows={4}
                placeholder={
                  'Nhập nội dung văn bản, chỉ áp dụng đối với văn bản dạng phi cấu trúc có độ dài không quá 500 ký tự'
                }
              />
            </Form.Item>

            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item label={'Thẩm quyền'} name={'competence'}>
                  <Input maxLength={100} placeholder={'Nhập thẩm quyền'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label={'Người ký'} name={'full_name'}>
                  <Input maxLength={50} placeholder={'Nhập tên người ký'} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label={'Chức vụ người ký văn bản'} name={'position'}>
              <Input maxLength={500} placeholder={'Nhập chức danh người ký'} />
            </Form.Item>
            <Form.Item label={'Hạn trả lời văn bản'} name={'due_date'}>
              <DatePicker format={DATE_FORMAT_LIST} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label={'Độ khẩn'} name={'priority'}>
              <Select
                notFoundContent={<EmptyContent />}
                placeholder={'Chọn độ khẩn'}>
                <Option value={'0'}>Thường</Option>
                <Option value={'1'}>Khẩn</Option>
                <Option value={'2'}>Thượng khẩn</Option>
                <Option value={'3'}>Hỏa tốc</Option>
                <Option value={'4'}>Hỏa tốc hẹn giờ</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={'Lĩnh vực ban hành'}
              name={'sphere_of_promulgation'}>
              <Input maxLength={100} placeholder={'Nhập lĩnh vực ban hành'} />
            </Form.Item>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={24}>
                <Form.Item
                  label={
                    'Ký hiệu đơn vị, tổ chức, chữ viết tắt tên người soạn thảo văn bản'
                  }
                  name={'typer_notation'}>
                  <Input maxLength={100} placeholder={'Nhập ký hiệu'} />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label={'Số lượng ban hành'}
                  name={'promulgation_amount'}>
                  <InputNumber style={{ width: '100%' }} min={1} max={10000} />
                </Form.Item>
              </Col>
            </Row>
            <Row type={'flex'} gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Tổng số trang của văn bản'}
                  name={'page_amount'}>
                  <InputNumber style={{ width: '100%' }} min={1} max={10000} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={'Ban hành theo chỉ đạo'}
                  name={'direction'}
                  valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label={'Phụ lục'} name={'appendixes'}>
              <Input maxLength={200} placeholder={'Nhập thông tin phụ lục'} />
            </Form.Item>

            <Form.Item
              label={'Loại chỉ đạo'}
              name={'steering_type'}
              rules={[
                { required: true, message: ' Vui lòng chọn loại chỉ đạo!' },
                { validator: validator.validateEmptyString },
              ]}>
              <Select
                notFoundContent={<EmptyContent />}
                placeholder={'Chọn loại chỉ đạo'}>
                <Option value={'0'}>Không phải là chỉ đạo</Option>
                <Option value={'1'}>Chỉ đạo</Option>
                <Option value={'2'}>Báo cáo chỉ đạo</Option>
              </Select>
            </Form.Item>

            <Form.Item label={'Nơi nhận'} name={'to_places'}>
              <Input maxLength={1000} placeholder={'Nhập nơi nhận'} />
            </Form.Item>
            <Form.Item
              label={'Loại nghiệp vụ văn bản'}
              name={'bussiness_doc_type'}
              rules={[
                {
                  required: true,
                  message: ' Vui lòng chọn loại nghiệp vụ văn bản!',
                },
                { validator: validator.validateEmptyString },
              ]}>
              <Select
                allowClear
                notFoundContent={<EmptyContent />}
                onChange={handleChangeBussinessDocType}
                placeholder={'Chọn loại xử lý'}>
                <Option value={'0'}>Gửi văn bản mới</Option>
                <Option value={'1'}>Thu hồi văn bản đã gửi</Option>
                <Option value={'3'}>Thay thế văn bản đã gửi</Option>
              </Select>
            </Form.Item>

            {showDocumentList && (
              <Fragment>
                <Table
                  style={{ marginBottom: 15 }}
                  rowKey={record => record.doc_id}
                  rowSelection={{ type: 'radio', ...rowSelection }}
                  columns={tableColumns}
                  scroll={{ x: 950, y: 300 }}
                  dataSource={
                    connectedCommitteeDocumentStore.outgoingCommitteeDocumentList
                  }
                  pagination={false}
                />
                <TableFooterActionWrapper>
                  <Pagination
                    onChange={e =>
                      connectedCommitteeDocumentStore.changeOutgoingCommitteeDocumentListPageIndex(
                        e
                      )
                    }
                    pageSize={
                      connectedCommitteeDocumentStore.outgoingCommitteeDocumentListPageSize
                    }
                    showSizeChanger={false}
                    showLessItems
                    current={
                      connectedCommitteeDocumentStore.outgoingCommitteeDocumentListPageIndex +
                      1
                    }
                    total={
                      connectedCommitteeDocumentStore.outgoingCommitteeDocumentListTotalCount
                    }
                    hideOnSinglePage={true}
                  />
                </TableFooterActionWrapper>
              </Fragment>
            )}

            <Form.Item
              label={'Lý do ban hành'}
              name={'bussiness_doc_reason'}
              rules={[
                {
                  required: true,
                  message: ' Vui lòng nhập lí do ban hành văn bản!',
                },
                { validator: validator.validateInputString },
              ]}>
              <Input maxLength={200} placeholder={'Nhập lí do ban hành'} />
            </Form.Item>
            <Form.Item
              name={'file_upload'}
              label={
                fileFromInternal.length !== 0 ? (
                  <>
                    <span
                      style={{
                        color: '#ff4d4f',
                        fontFamily: 'SimSun, sans-serif',
                        marginRight: '2px',
                      }}>
                      *{' '}
                    </span>
                    Tài liệu đính kèm
                  </>
                ) : (
                  'Tài liệu đính kèm'
                )
              }
              rules={
                fileFromInternal.length !== 0
                  ? null
                  : [
                      {
                        required: true,
                        message: ' Vui lòng chọn tài liệu đính kèm!',
                      },
                    ]
              }>
              <Upload
                valuePropName={'fileList'}
                fileList={fileList}
                multiple={true}
                beforeUpload={handleAddFileToUpload}
                onChange={handleChangeFile}
                showUploadList={false}>
                <Button icon={<UploadOutlined />}>
                  Chọn tài liệu đính kèm
                </Button>
              </Upload>
            </Form.Item>
            {
              // Văn bản từ thiết bị
              fileList.length !== 0 && (
                <UploadFileListWrapper>
                  {fileList.map((file, index) => (
                    <UploadFileListItem key={file.uid}>
                      <FileTextOutlined />
                      <span>{file.name}</span>
                      {
                        <Button
                          className={'rename'}
                          type="text"
                          onClick={() => {
                            setIsRenameFileFromInternal(false)
                            setFileExt(getTypeFile(file.name))
                            setFileTargetRename({
                              name: file.name,
                              index: index,
                            })
                            setIsModalVisibleRenameFile(true)
                          }}>
                          Đổi tên
                        </Button>
                      }
                      <Tooltip title={'Xoá tập tin'}>
                        <DeleteOutlined
                          onClick={() =>
                            handleRemoveFileFromUploadList(file.uid)
                          }
                        />
                      </Tooltip>
                    </UploadFileListItem>
                  ))}
                </UploadFileListWrapper>
              )
            }
            {
              // Văn bản từ nội bộ VIMC
              fileFromInternal.map((file, index) => (
                <UploadFileListItem key={file.id}>
                  <FileTextOutlined style={{ color: '1890FF' }} />
                  <span style={{ color: '#1890FF' }}>{file.name}</span>
                  <Button
                    className={'rename'}
                    type="text"
                    onClick={() => {
                      setIsRenameFileFromInternal(true)
                      setFileExt(getTypeFile(file.name))
                      setFileTargetRename({
                        ...file,
                        index: index,
                      })
                      setIsModalVisibleRenameFile(true)
                    }}>
                    Đổi tên
                  </Button>
                  <Tooltip title={'Xoá tập tin'}>
                    <DeleteOutlined
                      onClick={() =>
                        handleRemoveFileFromInternal(file.id, index)
                      }
                    />
                  </Tooltip>
                </UploadFileListItem>
              ))
            }

            {showCompanyReceiver && (
              <Form.Item
                name={'organizations'}
                label={'Công ty nhận'}
                rules={[
                  { required: true, message: 'Vui lòng chọn công ty nhận!' },
                ]}>
                <Select
                  showSearch
                  notFoundContent={<EmptyContent />}
                  filterOption={true}
                  optionFilterProp={'name'}
                  placeholder={'Chọn công ty nhận văn bản'}>
                  {organizationList &&
                    organizationList.map(item => (
                      <Option
                        key={item.organ_id}
                        value={item.organ_id}
                        name={item.organ_name}>
                        {item.organ_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            )}

            <FormActionFooter>
              <Button onClick={handleCancelCreate}>Huỷ bỏ</Button>
              <Button
                style={{ marginLeft: 10 }}
                type={'primary'}
                htmlType={'submit'}>
                Tạo văn bản
              </Button>
            </FormActionFooter>
          </Form>
        </Container>
        {/*Modal remane file */}
        <Modal
          title="Đổi tên file Trục"
          visible={isModalVisibleRenameFile}
          onOk={() => setIsModalVisibleRenameFile(false)}
          onCancel={() => setIsModalVisibleRenameFile(false)}
          footer={null}>
          <Form
            form={formRenameFile}
            scrollToFirstError={true}
            onFinish={submitRenameFile}
            fields={[
              {
                name: ['rename'],
                value: fileTargetRename && removeFileExt(fileTargetRename.name),
              },
            ]}>
            <Form.Item
              name={'rename'}
              rules={[
                { required: true, message: ' Vui lòng nhập tên file!' },
                { validator: validator.validateCharactersCannotExceed },
              ]}>
              <Input
                placeholder={'Nhập tên file thay đổi'}
                addonAfter={fileExt}
              />
            </Form.Item>
            <FormActionFooter>
              <Button onClick={() => setIsModalVisibleRenameFile(false)}>
                Huỷ bỏ
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                type={'primary'}
                htmlType={'submit'}>
                Đổi tên
              </Button>
            </FormActionFooter>
          </Form>
        </Modal>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

ConnectedCommitteeDocumentOutgoingDocCreatePage.propTypes = {}

export default memo(
  inject(
    'commonStore',
    'connectedCommitteeDocumentStore',
    'fileStore',
    'authenticationStore',
    'loadingAnimationStore',
    'organizationStore',
    'connectedDocumentStore'
  )(observer(ConnectedCommitteeDocumentOutgoingDocCreatePage))
)
