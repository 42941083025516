import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  TreeSelect,
} from 'antd'
import { inject, observer } from 'mobx-react'
import validator from '../../validator'
import { FormButtonGroup } from './ReportPageStyled'
import { REPORT_TYPE } from '../../constants'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import RichEditor from '../../components/RichEditor/RichEditor'
import { blue } from '../../color'
import RuiRoNhanDienTemplate from '../RiskCreatePage/RuiRoNhanDienTemplate'
import utils from '../../utils'
import { handleFilterSelect } from '../../utils/filterSelect'
import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker
const { SHOW_ALL, SHOW_CHILD } = TreeSelect

const ReportUpdateModal = props => {
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = useState(false)
  const [selectedField, setSelectedField] = useState(null)
  const {
    modalVisible,
    onCancel,
    editReport,
    loadingAnimationStore,
    onRefresh,
    reportStore,
    riskStore,
    companyStore,
    roleStore,
    positionStore,
    departmentStore,
    selectPeopleStore,
  } = props
  const {
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    isVisibleSelectUserDepartmentPopup,
    setSelectUserData,
    selectUserData,
    departmentWithUserList,
    getDepartmentWithUsers,
    getDepartmentWithUsersLive,
  } = selectPeopleStore
  const {
    type: reportType,
    createReport,
    getReportList,
    updateRiskReport,
    getReportDetail,
    reportDetail,
  } = reportStore
  const { riskUnits, riskUnitsV2, getRiskUnits, getRiskUnitsV2 } = riskStore
  const { getCompanyList, companyList } = companyStore
  const { departmentList } = departmentStore

  useEffect(() => {
    ;(async () => {
      try {
        await departmentStore.getDepartmentList()
      } catch (err) {
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
  }, [])

  const arrUnit = riskUnits.concat(companyList)
  const [isRenderRichText, setIsRenderRichText] = useState(false)
  const [unitValue, setUnitValue] = useState([])
  const [unitV2Value, setUnitV2Value] = useState([])
  const [
    isVisibleRuiRoNhanDienTemplate,
    setIsVisibleRuiRoNhanDienTemplate,
  ] = useState(false)
  const [ruiRoNhanDien, setRuiRoNhanDien] = useState({
    section2: [],
    section3: [],
  })
  const [section, setSection] = useState()
  const SECTION1_REF = useRef()
  const SECTION2_REF = useRef()
  const SECTION3_REF = useRef()
  const SECTION4_REF = useRef()

  const getRiskDetail = () => {
    ;(async () => {
      if (!editReport?.code) return
      try {
        await getReportDetail(editReport?.code)
        await riskStore.getRiskUnits()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
  }
  useEffect(() => {
    getRiskDetail()
  }, [editReport?.code, reportType])

  useEffect(() => {
    if (modalVisible) {
      setTimeout(() => {
        setIsRenderRichText(true)
      }, 600)
    } else {
      setIsRenderRichText(false)
    }
  }, [modalVisible])

  useEffect(() => {
    if (modalVisible) {
      getRiskUnits()
      getRiskUnitsV2('DVPT')
    }
  }, [modalVisible, getRiskUnits, getRiskUnitsV2])

  useEffect(() => {
    ;(async () => {
      try {
        await riskStore.getRiskUnitsV2('DVPT')
        await riskStore.getRiskUnits()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
    return () => {}
  }, [])
  useEffect(() => {
    ;(async () => {
      await positionStore.getPositionList()
      await roleStore.getRoles()
      if (!companyList) {
        getCompanyList()
      }
    })()
  }, [])

  useEffect(() => {
    // Check initial values and set selectedField accordingly
    if (editReport?.cqvp_code) {
      setSelectedField('cqvp_code')
    } else if (editReport?.dvpt_code) {
      setSelectedField('dvpt_code')
    } else if (editReport?.nddpv_code) {
      setSelectedField('nddpv_code')
    }
  }, [editReport])

  const handleSelectChange = (field, value) => {
    if (value) {
      setSelectedField(field)
    } else {
      setSelectedField(null)
    }
  }
  const [dataRiskUnitsByType, setRiskUnitsByType] = useState([])
  const [dataRiskUnitsByTypeNDDPV, setRiskUnitsByTypeNDDPV] = useState([])
  const getRiskUnitsByTypeList = async type => {
    await riskStore.getRiskUnitsV2(type).then(response => {
      setRiskUnitsByType(response.data)
    })
  }
  const getRiskUnitsByTypeListNDDPV = async type => {
    await riskStore.getRiskUnitsV2(type).then(response => {
      setRiskUnitsByTypeNDDPV(response.data)
    })
  }

  useEffect(() => {
    getRiskUnitsByTypeList('DVPT')
    getRiskUnitsByTypeListNDDPV('NDDPV')
  }, [])
  const [section2Content, setSection2Content] = useState(
    editReport?.section2 || ''
  )
  const [section3Content, setSection3Content] = useState(
    editReport?.section3 || ''
  )
  const handleEditorChange = (section, content) => {
    if (section === 'section2') {
      setSection2Content(content)
    } else if (section === 'section3') {
      setSection3Content(content)
    }
  }

  const [depattData, setDepattData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDepartmentWithUsers('CPN7451091748209')
        setDepattData(response.data)
      } catch (error) {
        console.log(error)
        message.error(error.vi || 'Đã có lỗi xảy ra!')
      }
    }

    fetchData()
  }, [])

  const treeData = Array.isArray(riskUnitsV2)
    ? riskUnitsV2.map(item => ({
        title: item.name || '',
        value: item.code || '',
        key: item.code || '',
        children: Array.isArray(item.unit_users)
          ? item.unit_users.map(user => ({
              title:
                utils && utils.getNameInCapitalize
                  ? utils.getNameInCapitalize(user.full_name)
                  : user.full_name || '',
              value: user.user_code || '',
              key: user.user_code || '',
              position_name: user.position_name || '',
            }))
          : [],
      }))
    : []
  const treeData1 = dataRiskUnitsByType?.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.unit_users.map(user => ({
      title: user.full_name,
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))
  const treeData2 = depattData?.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.users.map(user => ({
      title: utils.getNameInCapitalize(user.name_uppercase),
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))
  const treeData3 = dataRiskUnitsByTypeNDDPV?.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item?.unit_users?.map(user => ({
      title: user.full_name,
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))

  const treeDataDp = departmentWithUserList.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.users.map(user => ({
      title: utils.getNameInCapitalize(user.name_uppercase),
      value: user.user_code,
      key: user.user_code,
    })),
  }))

  const combinedTreeData = [
    ...treeData,
    ...treeData1,
    ...treeData2,
    ...treeData3,
    ...treeDataDp,
  ]
  function removeDuplicates(data) {
    const seenTitles = new Set() // Để lưu các tiêu đề đã thấy
    const uniqueData = []

    for (const item of data) {
      if (!seenTitles.has(item.title)) {
        seenTitles.add(item.title)
        uniqueData.push(item)
      }
    }
    // Đệ quy để xử lý các phần tử con
    for (const item of uniqueData) {
      if (item.children && item.children.length > 0) {
        item.children = removeDuplicates(item.children) // Gọi đệ quy cho children
      }
    }
    return uniqueData
  }

  const finalTreeData = Array.from(
    new Set(combinedTreeData.map(JSON.stringify))
  ).map(JSON.parse)

  const [checked_ptc, setChecked_ptc] = useState(editReport?.is_sign || false)
  const [disabled_ptc, setDisabled_ptc] = useState(true)
  const [disabled, setDisabled] = useState(true)
  const [checked_lddv, setChecked_lddv] = useState(false)
  const onChangeCheckedPtc = e => {
    setChecked_ptc(e.target.checked)
  }
  const toggleDisable = e => {
    if (e?.length > 0) {
      setDisabled(false)
    }
    if (e?.length < 1) {
      setChecked_lddv(false)
      setDisabled(true)
    }
  }
  useEffect(() => {
    if (ruiRoNhanDien) {
      SECTION2_REF.current?.editor.setData(
        `${ruiRoNhanDien.section2
          .map(
            item =>
              `${
                item.rui_ro_nhan_dien
                  ? item.rui_ro_nhan_dien
                  : item.identification_risk
              }`
          )
          .join('<br/>')}`
      )
      SECTION3_REF.current?.editor.setData(
        `${ruiRoNhanDien.section3
          .map(
            item =>
              `${
                item.rui_ro_nhan_dien
                  ? item.rui_ro_nhan_dien
                  : item.identification_risk
              }`
          )
          .join('<br/>')}`
      )
    }
  }, [ruiRoNhanDien])

  useEffect(() => {
    if (editReport) {
      const submitDate = moment(editReport.report_submit_date, 'YYYY-MM-DD')
      const startDate = moment(editReport.report_period_start, 'YYYY-MM-DD')
      const endDate = moment(editReport.report_period_end, 'YYYY-MM-DD')
      form.setFieldsValue({
        title: editReport.title,
        dateRange: [startDate, endDate],
        report_submit_date: submitDate,
        dvpt_code: editReport.dvpt_code,
        nddpv_code: editReport.nddpv_code,
        cqvp_code: editReport.cqvp_code,
        risk_unit_code: editReport.risk_unit_code,
        risk_dependent_code: editReport.risk_dependent_code,
        section1: editReport.section1,
        section2: editReport.section2,
        section3: editReport.section3,
        section4: editReport.section4,
        truong_ban: editReport.sign_user,
        truong_ban__signed: editReport.is_sign,
      })
      setChecked_ptc(editReport.is_sign || false)
    }
  }, [editReport, form])

  const onFinish = async values => {
    if (submitting) return
    setSubmitting(true)
    const fields = [
      'risk_unit_code',
      'risk_dependent_code',
      'cqvp_code',
      'nddpv_code',
      'dvpt_code',
    ]
    const {
      dvpt_code,
      nddpv_code,
      cqvp_code,
      dateRange,
      report_submit_date,
    } = values
    const [startDate, endDate] = dateRange
    if (
      (!dvpt_code || dvpt_code.length === 0) &&
      (!nddpv_code || nddpv_code.length === 0) &&
      (!cqvp_code || cqvp_code.length === 0)
    ) {
      message.error('Vui lòng chọn một đơn vị', 1)
      setSubmitting(false)
      return
    }

    const submitData = {
      type: reportType,
      title: values.title,
      risk_unit_code: values.risk_unit_code,
      risk_dependent_code: values.risk_dependent_code,
      cqvp_code: values.cqvp_code,
      dvpt_code: values.dvpt_code,
      nddpv_code: values.nddpv_code,
      section1: SECTION1_REF.current.editor.getData(),
      section2: SECTION2_REF.current.editor.getData(),
      section3: SECTION3_REF.current.editor.getData(),
      section4: SECTION4_REF.current.editor.getData(),
      report_submit_date: report_submit_date.format('DD/MM/YYYY'),
      report_period_start: startDate.format('DD/MM/YYYY'),
      report_period_end: endDate.format('DD/MM/YYYY'),
      sign_user: values.truong_ban ? values.truong_ban : null,
      is_sign: checked_ptc,
    }

    loadingAnimationStore.showSpinner(true)
    updateRiskReport(reportDetail.code, submitData)
      .then(() => {
        handleCancelAdd()
        loadingAnimationStore.showSpinner(false)
        message.success('Cập nhật báo cáo thành công', 2)
        onRefresh()
      })
      .finally(() => {
        setSubmitting(false)
        loadingAnimationStore.showSpinner(false)
      })
  }

  const onReset = useCallback(() => {
    form.resetFields()
    SECTION1_REF.current?.editor.setData('')
    SECTION2_REF.current?.editor.setData('')
    SECTION3_REF.current?.editor.setData('')
    SECTION4_REF.current?.editor.setData('')
    setRuiRoNhanDien({ section2: [], section3: [] })
  }, [form])

  const handleCancelAdd = useCallback(() => {
    onCancel()
    onReset()
  }, [onReset, onCancel])

  const validateUnits = (_, value) => {
    const riskUnitCode = form.getFieldValue('risk_unit_code')
    const riskDependentCode = form.getFieldValue('risk_dependent_code')

    if (!riskUnitCode && !riskDependentCode) {
      return Promise.reject(
        new Error(
          'Vui lòng chọn ít nhất một trong hai trường: Đơn vị hoặc Đơn vị phụ thuộc!'
        )
      )
    }
    return Promise.resolve()
  }

  const handleUnitChange = value => {
    setUnitValue(value)
    form.setFieldsValue({ risk_unit_code: value })
    form.validateFields(['risk_unit_code', 'risk_dependent_code'])
  }

  const handleDependentUnitChange = value => {
    setUnitV2Value(value)
    form.setFieldsValue({ risk_dependent_code: value })
    form.validateFields(['risk_unit_code', 'risk_dependent_code'])
  }

  const handleUnitClear = () => {
    setUnitValue([])
    form.setFieldsValue({ risk_unit_code: [] })
    form.validateFields(['risk_unit_code'])
  }

  const handleDependentUnitClear = () => {
    setUnitV2Value([])
    form.setFieldsValue({ risk_dependent_code: [] })
    form.validateFields(['risk_dependent_code'])
  }

  const [selectedValues, setSelectedValues] = useState(unitValue)
  const [selectedValuesV2, setselectedValuesV2] = useState(unitV2Value)

  const handleChange = values => {
    setSelectedValues(values)
  }

  const handleChangeV2 = values => {
    setselectedValuesV2(values)
  }
  return (
    <>
      <Modal
        width={800}
        destroyOnClose={true}
        afterClose={() => {
          setIsRenderRichText(false)
        }}
        zIndex={500}
        style={{ top: 40 }}
        title={'Cập nhật báo cáo'}
        visible={modalVisible}
        scrollToFirstError
        maskClosable={false}
        // transitionName=""
        // maskTransitionName=""
        onCancel={handleCancelAdd}
        footer={null}>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={onFinish}
          preserve={false}
          scrollToFirstError={true}>
          <h2>
            Loại:{' '}
            {reportType === REPORT_TYPE.PERIODICAL_REPORT
              ? 'Báo cáo định kỳ'
              : 'Báo cáo đột xuất'}
          </h2>
          <Form.Item
            name={'title'}
            label={'Tiêu đề:'}
            rules={[
              { required: true, message: 'Vui lòng nhập tiêu đề!' },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Tiêu đề báo cáo'} />
          </Form.Item>
          <Form.Item
            name={'cqvp_code'}
            label={'Đơn vị thuộc Cơ quan Văn phòng Tổng công ty:'}>
            <Select
              allowClear
              showSearch
              onChange={value => handleSelectChange('cqvp_code', value)}
              value={selectedField === 'cqvp_code' ? undefined : null}
              disabled={selectedField && selectedField !== 'cqvp_code'}
              filterOption={handleFilterSelect}
              placeholder={'Chọn đơn vị'}
              tokenSeparators={[',']}>
              {departmentList?.map(unit => (
                <Option key={unit.code} name={'cqvp_code'} value={unit.code}>
                  {unit.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'dvpt_code'} label={'Đơn vị phụ thuộc VIMC:'}>
            <Select
              allowClear
              showSearch
              onChange={value => handleSelectChange('dvpt_code', value)}
              value={selectedField === 'dvpt_code' ? undefined : null}
              disabled={selectedField && selectedField !== 'dvpt_code'}
              filterOption={handleFilterSelect}
              placeholder={'Chọn đơn vị'}
              tokenSeparators={[',']}>
              {dataRiskUnitsByType.map(item => (
                <Option key={item.code} name={'dvpt_code'} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'nddpv_code'} label={'DNTV của VIMC:'}>
            <Select
              allowClear
              showSearch
              onChange={value => handleSelectChange('nddpv_code', value)}
              value={selectedField === 'nddpv_code' ? undefined : null}
              disabled={selectedField && selectedField !== 'nddpv_code'}
              filterOption={handleFilterSelect}
              placeholder={'Chọn đơn vị'}
              tokenSeparators={[',']}>
              {dataRiskUnitsByTypeNDDPV.map(item => (
                <Option key={item.code} name={'nddpv_code'} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', gap: '70px' }}>
            <Form.Item
              name="report_submit_date"
              label="Ngày nộp báo cáo"
              style={{ width: '300px' }}
              rules={[
                { required: true, message: 'Vui lòng nhập ngày nộp báo cáo' },
              ]}>
              <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="dateRange"
              label="Kỳ báo cáo"
              style={{ width: '400px' }}
              rules={[{ required: true, message: 'Vui lòng nhập Kỳ báo cáo' }]}>
              <RangePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
            </Form.Item>
          </div>
          {/* <Form.Item
            name={'risk_unit_code'}
            label={'Đơn vị:'}
            rules={[{ validator: validateUnits }]}>
            <Select
              allowClear
              showSearch
              filterOption={handleFilterSelect}
              defaultValue={unitValue}
              value={unitValue}
              tokenSeparators={[',']}
              mode="multiple"
              placeholder={'Đơn vị'}
              onChange={handleChange}
              onClear={handleUnitClear}>
              {riskUnits?.map(unit => (
                <Option key={unit.code} value={unit.code}>
                  {unit.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={'risk_dependent_code'}
            label={'Đơn vị phụ thuộc:'}
            rules={[{ validator: validateUnits }]}>
            <Select
              allowClear
              showSearch
              filterOption={handleFilterSelect}
              defaultValue={selectedValuesV2}
              value={unitV2Value}
              tokenSeparators={[',']}
              mode="multiple"
              placeholder={'Đơn vị phụ thuộc'}
              onChange={setselectedValuesV2}
              onClear={handleDependentUnitClear}>
              {Array.isArray(riskUnitsV2) &&
                riskUnitsV2.map(unit => (
                  <Option key={unit.code} value={unit.code}>
                    {unit.name}
                  </Option>
                ))}
            </Select>
          </Form.Item> */}

          <p style={{ marginTop: 16 }}>
            1. Báo cáo kết quả thực hiện công tác Quản trị rủi ro: (Áp dụng các
            nguyên tắc QTRR một cách có hệ thống trong tất cả các hoạt động của
            Văn phòng Tổng công ty/Ban chuyên môn/TTCNTT. Tuân thủ Chính sách,
            Quy trình quản lý rủi ro đã được phê duyệt và các quy định pháp luật
            khác)
          </p>
          <p>
            BIỆN PHÁP XỬ LÝ/TÌNH HÌNH KHẮC PHỤC/GIẢI PHÁP PHÒNG NGỪA/KIẾN NGHỊ
          </p>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={SECTION1_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={editReport?.section1}
            />
          )}
          <p style={{ marginTop: 16 }}>
            2. Các rủi ro xảy ra trong kỳ báo cáo đã được xử lý{' '}
            <Button
              style={{ marginTop: 8, marginLeft: 8, color: blue }}
              onClick={() => {
                setIsVisibleRuiRoNhanDienTemplate(true)
                setSection('section2')
              }}>
              Chọn
            </Button>
          </p>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={SECTION2_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={editReport?.section2}
              onChange={content => handleEditorChange('section2', content)}
            />
          )}
          <p style={{ marginTop: 16 }}>
            3. Các rủi ro xảy ra trong kỳ báo cáo chưa được xử lý{' '}
            <Button
              style={{ marginTop: 8, marginLeft: 8, color: blue }}
              onClick={() => {
                setIsVisibleRuiRoNhanDienTemplate(true)
                setSection('section3')
              }}>
              Chọn
            </Button>
          </p>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={SECTION3_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={editReport?.section3}
              onChange={content => handleEditorChange('section3', content)}
            />
          )}
          <p style={{ marginTop: 16 }}>
            4. Nội dung phát hiện trong kỳ báo cáo: Có phát sinh rủi ro so với
            Nhận diện rủi ro đầu năm
          </p>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={SECTION4_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={editReport?.section4}
            />
          )}
          <p style={{ marginTop: 16 }}>Trưởng ban:</p>
          <Form.Item name={'truong_ban'}>
            <TreeSelect
              treeData={removeDuplicates(finalTreeData)}
              style={{ width: '100%' }}
              showArrow
              allowClear
              showSearch
              treeNodeFilterProp={'title'}
              filterTreeNode
              treeDefaultExpandAll
              multiple={false}
              onChange={e => toggleDisable(e)}
              placeholder={'Chọn trưởng ban'}
            />
          </Form.Item>
          <Form.Item name="truong_ban__signed" valuePropName="checked">
            <Space direction="vertical">
              <Checkbox
                checked={checked_ptc}
                disabled={disabled}
                onChange={onChangeCheckedPtc}>
                Xác nhận ký
              </Checkbox>
            </Space>
          </Form.Item>
          <FormButtonGroup>
            <Button type={'danger'} ghost onClick={handleCancelAdd}>
              Huỷ bỏ
            </Button>
            <Space size={'middle'}>
              <Button type={'primary'} htmlType={'submit'}>
                Cập nhật
              </Button>
            </Space>
          </FormButtonGroup>
        </Form>
      </Modal>
      <RuiRoNhanDienTemplate
        isVisible={isVisibleRuiRoNhanDienTemplate}
        handleCancelModal={() => {
          setIsVisibleRuiRoNhanDienTemplate(false)
        }}
        riskStore={riskStore}
        setRuiRoNhanDien={setRuiRoNhanDien}
        multiple={true}
        section={section}
        ruiRoNhanDien={ruiRoNhanDien}
      />
    </>
  )
}
export default inject(
  'reportStore',
  'riskStore',
  'loadingAnimationStore',
  'companyStore',
  'positionStore',
  'roleStore',
  'departmentStore',
  'selectPeopleStore'
)(observer(ReportUpdateModal))
