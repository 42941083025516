import React, { useEffect, useState } from 'react'
import { ExportOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import date_format from '../../date_format'
import { HeadingButtonWrapper } from '../MissionDetailPage/MissionDetailPageStyled'
import { range } from 'lodash-es'
import { maxBy } from 'lodash-es/math'
import RichText from '../../components/RichEditor/RichText'
import { apiUrl } from '../../config'
import ExcelJS from 'exceljs'

const ExportTaskToExcel = props => {
  const { data } = props

  const [isVisibleModal, setVisibleModal] = useState(false)

  const [table, setTable] = useState({})

  useEffect(() => {
    if (data.length > 0) {
      data.forEach(item => {
        item.countVb = item.van_bans.length
        item.countTaiLieuDinhKem = item.ket_qua.files.length
      })

      let maxColVanBan = maxBy(data, 'countVb').countVb
      if (maxColVanBan === 0) maxColVanBan = 1

      let maxColTaiLieuDinhKem = maxBy(data, 'countTaiLieuDinhKem')
        .countTaiLieuDinhKem
      if (maxColTaiLieuDinhKem === 0) maxColTaiLieuDinhKem = 1

      const columns = (
        <tr>
          <td>STT</td>
          <td>Nội dung</td>
          <td>Ghi chú</td>
          <td>Ngày giao</td>
          <td>Ngày hoàn thành</td>
          <td colSpan={maxColVanBan}>Văn bản</td>
          <td>[Kết quả] Nội dung</td>
          <td>[Kết quả] Kiến nghị</td>
          <td colSpan={maxColTaiLieuDinhKem}>[Kết quả] Tài liệu đính kèm</td>
          <td>[Kết quả] Phần trăm thực hiện</td>
          <td>Cấp giao</td>
          <td>Hiển thị vào tháng</td>
          <td>Ngày tạo</td>
        </tr>
      )

      const rows = data.map(item => (
        <tr>
          <td>{item.stt}</td>
          <td>
            <RichText htmlText={item.noi_dung} />
          </td>
          <td>
            <RichText htmlText={item.ghi_chu} />
          </td>
          <td>{date_format.DEFAULT_DATE(item.ngay_bat_dau)}</td>
          <td>
            {item.ngay_ket_thuc && date_format.DEFAULT_DATE(item.ngay_ket_thuc)}
          </td>
          {range(maxColVanBan)
            .map(i => item.van_bans[i] ?? null)
            .map(vb => {
              if (vb) {
                let url =
                  vb.loai_van_ban === '001'
                    ? `${apiUrl}/internal-document/incoming-document/view/${vb.code_van_ban}`
                    : `${apiUrl}/internal-document/outgoing-document/view/${vb.code_van_ban}`
                return (
                  <td>
                    <a href={url}>
                      {vb.document_number} - {vb.ten_van_ban}
                    </a>
                  </td>
                )
              } else return <td></td>
            })}
          <td>
            <RichText htmlText={item.ket_qua.noi_dung_ket_qua_giao} />
          </td>
          <td>
            <RichText htmlText={item.ket_qua.kien_nghi} />
          </td>
          {range(maxColTaiLieuDinhKem)
            .map(i => item.ket_qua.files[i] ?? null)
            .map(file => {
              if (file) {
                return (
                  <td>
                    <a href={`${apiUrl}/attachments/${file.file_id}`}>
                      {file.file_title}
                    </a>
                  </td>
                )
              } else return <td></td>
            })}
          <td>{item.ket_qua.phan_tram_hoan_thanh}%</td>
          <td>{item.cap_giao.ten_cap_giao}</td>
          <td>{date_format.MMMM_YYYY(item.display_date)}</td>
          <td>{date_format.DEFAULT_DATE(item.created_at)}</td>
        </tr>
      ))

      setTable({
        columns: columns,
        rows: rows,
      })
    } else {
      setTable({})
    }
  }, [data])

  const htmlTableToExcel = async () => {
    var wb = XLSX.utils.table_to_book(document.getElementById('tableau'), {
      raw: true,
    })
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = 'export'
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  const removeHtmlTags = htmlString => {
    const div = document.createElement('div')
    div.innerHTML = htmlString
    return div.textContent || div.innerText || ''
  }

  const exportToExcel = async (
    tableData,
    fileName,
    fieldsToExport,
    headers,
    columnWidths
  ) => {
    const processField = (item, field) => {
      const fieldParts = field.split('.')
      let value = item
      fieldParts.forEach(part => {
        if (value && typeof value === 'object' && part in value) {
          value = value[part]
        } else {
          value = undefined
        }
      })
      if (value === undefined || value === null || value === '') {
        return 'Không có'
      }
      if (typeof value === 'object') {
        return JSON.stringify(value)
      }
      if (
        field.toLowerCase().includes('ngay') ||
        field.toLowerCase().includes('created_at')
      ) {
        value = date_format.DEFAULT_DATE(value)
      }
      if (field.toLowerCase().includes('display_date')) {
        value = date_format.MMMM_YYYY(value)
      }
      if (
        field.toLowerCase().includes('noi_dung') ||
        field.toLowerCase().includes('ghi_chu') ||
        field.toLowerCase().includes('ket_qua.noi_dung_ket_qua_giao') ||
        field.toLowerCase().includes('ket_qua.kien_nghi')
      ) {
        value = removeHtmlTags(value)
      }
      worksheet.getColumn('van_bans').eachCell((cell, rowNumber) => {
        let jsonStringOrText = cell?._value.model.value
        let jsonArray
        try {
          if (
            jsonStringOrText.startsWith('[') &&
            jsonStringOrText.endsWith(']')
          ) {
            jsonArray = JSON.parse(jsonStringOrText)
          }
        } catch (error) {
          console.error(error)
        }
        let cellValues = []
        if (rowNumber > 1 && jsonArray) {
          for (let i = 0; i < jsonArray.length; i++) {
            let url =
              jsonArray[i]?.loai_van_ban === '001'
                ? `${apiUrl}/internal-document/incoming-document/view/${jsonArray[i]?.code_van_ban}`
                : `${apiUrl}/internal-document/outgoing-document/view/${jsonArray[i]?.code_van_ban}`
            cellValues.push(
              `Tên file:${
                jsonArray[i]?.document_number
                  ? jsonArray[i]?.document_number
                  : ''
              } ${jsonArray[i]?.document_number ? '-' : ''} ${
                jsonArray[i]?.ten_van_ban ? jsonArray[i]?.ten_van_ban : ''
              } - link:${url}`
            )
          }
          cell.value = cellValues.join(', ')
        }
      })

      // worksheet.getColumn('file_dinh_kem_vb').eachCell((cell, rowNumber) => {
      //   let jsonStringOrText = cell?._value.value
      //   let jsonArray

      //   try {
      //     if (
      //       jsonStringOrText &&
      //       jsonStringOrText.startsWith('[') &&
      //       jsonStringOrText.endsWith(']')
      //     ) {
      //       jsonArray = JSON.parse(jsonStringOrText)
      //       let cellValues = []
      //       if (rowNumber > 1 && jsonArray) {
      //         for (let i = 0; i < jsonArray.length; i++) {
      //           cellValues.push(
      //             `Tên file:${jsonArray[i]?.file_title} - link:${apiUrl}/attachments/${jsonArray[i].file_id}`
      //           )
      //         }
      //       }

      //       cell.value = cellValues.join(', ')
      //     }
      //   } catch (error) {
      //     console.error(error)
      //   }
      // })

      worksheet.getColumn('ket_qua.files').eachCell((cell, rowNumber) => {
        let jsonStringOrText = cell?._value.value
        let jsonArray

        try {
          if (
            jsonStringOrText &&
            jsonStringOrText.startsWith('[') &&
            jsonStringOrText.endsWith(']')
          ) {
            jsonArray = JSON.parse(jsonStringOrText)
            let cellValues = []
            if (rowNumber > 1 && jsonArray) {
              for (let i = 0; i < jsonArray.length; i++) {
                cellValues.push(
                  `Tên file:${jsonArray[i]?.file_title} - link:${apiUrl}/attachments/${jsonArray[i].file_id}`
                )
              }
            }

            cell.value = cellValues.join(', ')
          }
        } catch (error) {
          console.error(error)
        }
      })

      return value
    }

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Sheet 1')

    const columns = fieldsToExport.map((field, index) => ({
      header: headers[index] || field,
      key: field,
      width: columnWidths[index] || 10,
      style: {
        font: { name: 'Arial', color: { argb: 'FFFFFFFF' }, bold: true },
      },
    }))

    worksheet.columns = columns

    tableData.forEach(item => {
      const row = {}
      fieldsToExport.forEach((field, index) => {
        row[field] = processField(item, field)
      })
      worksheet.addRow(row)
    })

    const lastRowData = tableData[tableData?.length - 1]
    if (lastRowData && lastRowData?.ket_qua && lastRowData?.ket_qua.files) {
      const jsonArray = lastRowData.ket_qua.files
      let cellValues = []

      for (let i = 0; i < jsonArray.length; i++) {
        cellValues.push(
          `Tên file:${jsonArray[i]?.file_title} - link:${apiUrl}/attachments/${jsonArray[i].file_id}`
        )
      }

      worksheet.getCell(`I${worksheet.rowCount}`).value = cellValues.join(', ')
    }

    if (lastRowData && lastRowData?.van_bans) {
      const jsonArray = lastRowData.van_bans
      let cellValues = []

      for (let i = 0; i < jsonArray.length; i++) {
        let url =
          jsonArray[i]?.loai_van_ban === '001'
            ? `${apiUrl}/internal-document/incoming-document/view/${jsonArray[i]?.code_van_ban}`
            : `${apiUrl}/internal-document/outgoing-document/view/${jsonArray[i]?.code_van_ban}`

        cellValues.push(
          `Tên file:${
            jsonArray[i]?.document_number ? jsonArray[i]?.document_number : ''
          } ${jsonArray[i]?.document_number ? '-' : ''} ${
            jsonArray[i]?.ten_van_ban ? jsonArray[i]?.ten_van_ban : ''
          } - link:${url}`
        )
      }

      worksheet.getCell(`F${worksheet.rowCount}`).value = cellValues.join(', ')
    }


    // style
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber === 1) {
        // Header row
        row.eachCell(cell => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF2C65AC' },
          }
          cell.font = {
            color: { argb: 'FFFFFFFF' },
            bold: true,
          }
        })
      } else {
        // Other rows
        const bgColor = rowNumber % 2 === 0 ? 'FFFFFFFF' : 'FFE9F0FD'
        row.eachCell(cell => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: bgColor },
          }
          cell.font = {
            color: { argb: 'FF000000' },
          }
        })
      }
    })

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName + '.xlsx'
    a.click()
  }

  return (
    <>
      <Button
        icon={<ExportOutlined />}
        onClick={
          // setVisibleModal(true)
          () =>
            exportToExcel(
              data,
              'danh-sach-nhiem-vu',
              [
                'stt',
                'noi_dung',
                'ghi_chu',
                'ngay_bat_dau',
                'ngay_ket_thuc',
                'van_bans',
                // 'file_dinh_kem_vb',
                'ket_qua.noi_dung_ket_qua_giao',
                'ket_qua.kien_nghi',
                'ket_qua.files',
                'ket_qua.phan_tram_hoan_thanh',
                'cap_giao.ten_cap_giao',
                'display_date',
                'created_at',
              ],
              [
                'STT',
                'Nội dung',
                'Ghi chú',
                'Ngày giao',
                'Ngày hoàn thành',
                'Văn bản',
                // '[Văn bản] Tài liệu đính kèm văn bản',
                '[Kết quả] Nội dung',
                '[Kết quả] Kiến nghị',
                '[Kết quả] Tài liệu đính kèm',
                '[Kết quả] Phần trăm thực hiện',
                'Cấp giao',
                'Hiển thị vào ngày',
                'Ngày tạo',
              ],
              [5, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30]
            )
        }>
        Xuất dữ liệu
      </Button>

      {/* <Modal
        width={1200}
        style={{ top: 40 }}
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div>Xem trước dữ liệu xuất ra</div>
            <div style={{ display: 'flex', gap: 10 }}>
              <Button onClick={() => setVisibleModal(false)} danger>
                Đóng
              </Button>
              <HeadingButtonWrapper>
                <Button
                  onClick={htmlTableToExcel}
                  type={'primary'}
                  icon={<ExportOutlined />}>
                  Thực hiện
                </Button>
              </HeadingButtonWrapper>
            </div>
          </div>
        }
        visible={isVisibleModal}
        maskClosable={false}
        closable={false}
        onCancel={() => {
          setVisibleModal(false)
        }}
        footer={null}>
        {isVisibleModal ? (
          <table id="tableau">
            {table.columns && table.columns}
            {table.rows && table.rows}
          </table>
        ) : (
          <></>
        )}
      </Modal> */}
    </>
  )
}

ExportTaskToExcel.propTypes = {}

export default ExportTaskToExcel
