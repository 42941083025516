import axios from 'axios'
// @ts-ignore
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const RiskRequest = {
  createRisk: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/risk`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  getRisks: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/risk`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params,
    }),
  getAllRisks: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/risks/all`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params,
    }),
  getRiskUnits: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/risk/unit`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  getRiskUnitsV2: type =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/risk/unit-v2`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        type: type,
      },
    }),
  getRiskUnitsV3: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/risk/all-unit`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  getAllRiskUnitUsers: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/risk/unit-users`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  getRiskDetail: riskCode =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/risk/${riskCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  deleteRisk: riskCode =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/risk/${riskCode}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  updateApproval: (code, data) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/risk/approval-people/${code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  updateTierApproval: (code, data) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/risk/tier-approval/${code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  updateRisk: (code, data) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/risk/${code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  createRiskAction: data =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/risk/action`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: data,
    }),
  getRuiRoNhanDienType: () =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/rui-ro-nhan-dien-type`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
  getListTemplate: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/rui-ro-nhan-dien-template`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { ...params, sort_by_created: 'DESC' },
    }),

  printListInYearAsPdf: ({ year, unit }) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/rui-ro-nhan-dien-template`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { year, unit },
    }),
}
