import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Link, useHistory, withRouter } from 'react-router-dom'
import { WorkPageWrapper } from './TextShoppingCouponStylePage'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { StatusTag } from '../../components/Common/StatusTag'
import { TYPE_STATUS } from '../../constants'
import { runInAction } from 'mobx'
import {
  Button,
  Menu,
  message,
  Modal,
  Col,
  Form,
  Input,
  Row,
  Select,
  Dropdown,
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  MoreOutlined,
  PlusOutlined,
  PrinterOutlined,
  ExclamationCircleOutlined ,
} from '@ant-design/icons'
import PageTitle from '../../components/PageTitle'
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'
import ShoppingCouponTable from './ShoppingCouponTable'
import ShoppingCouponCreate from './ShoppingCouponCreate'
import { blue } from '../../color'
import { HeadingButtonWrapper } from '../WorkPage/DrawerWork/DrawerWorkStyled'
import ShoppingCouponDetail from './ShoppingCouponDetail'
import ShoppingCouponUpdate from './ShoppingCouponUpdate'
import { SHOPPING_STATUS } from '../../constants'

const ShoppingCouponDetailPage = props => {
  const { ShoppingCouponStore, match, aclStore } = props
  const { getShoppingCouponDetail } = ShoppingCouponStore
  const { shoppingId, typePage } = match.params
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false)
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false)
  const [shoppingTitle, setShoppingTitle] = useState('')
  const [shoppingType, setShoppingType] = useState('')
  const [isDisableUpdate, setIsDisableUpdate] = useState(false)
  const [isDisableDelete, setIsDisableDelete] = useState(false)
  const [isDisableCancel, setIsDisableCancel] = useState(false);
  const [isReject, setIsReject] = useState(false)
  const [isVisibleDrawerWork, setIsVisiableDrawerWork] = useState(false)
  const history = useHistory()
  const handleToggleCreateModal = bool => () => {
    setIsVisibleCreateModal(bool)
  }

  const handleChangeType = (type) => {
    setShoppingType(type)
    if (type === SHOPPING_STATUS.REQUESTED || type === SHOPPING_STATUS.APPROVED || type === SHOPPING_STATUS.BOUGHT) {
      setIsDisableCancel(true)
    } else if (type === SHOPPING_STATUS.PAID || type === SHOPPING_STATUS.REJECT) {
      setIsDisableCancel(true)
      setIsDisableUpdate(true)
    } else {
      setIsDisableCancel(false)
      setIsDisableDelete(false)
      setIsDisableUpdate(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      const response = await getShoppingCouponDetail(shoppingId)
      setShoppingTitle(response.data.title)
      ShoppingCouponStore.setShoppingCouponType(response.data.type)
    })()
  }, [])

  const onUpdateSuccess = () => {
    setIsUpdateSuccess(true)
  }

  const onUpdated = () => {
    setIsUpdateSuccess(false)
  }

  const handleDelete = () => {
    Modal.confirm({
      title: 'Bạn có muốn xóa quy trình này không?',
      okText: 'Đồng ý',
      okType: 'primary',
      cancelText: 'Không',
      icon: <DeleteOutlined style={{ color: 'red' }} />,
      onOk: async () => {
        try {
          await ShoppingCouponStore.deleteShoppingCoupon(shoppingId)
          message.success('Xóa quy trình mua sắm thành công')
          history.push('/shopping-coupon')
        } catch (error) {
          console.log(error)
        }
      },
      onCancel() {
        console.log('Hủy xóa quy trình')
      },
    })
  }

  const handleReject = () => {
    Modal.confirm({
      title: 'Bạn có muốn hủy phiếu này không?',
      okText: 'Đồng ý',
      okType: 'primary',
      cancelText: 'Không',
      icon: <ExclamationCircleOutlined   style={{ color: 'red' }} />,
      onOk: async () => {
        try {
          await ShoppingCouponStore.rejectShoppingCoupon(shoppingId)
          message.success('Hủy phiếu mua sắm thành công')
          handleChangeType(SHOPPING_STATUS.REJECT)
          setIsReject(true)
        } catch (error) {
          console.log(error)
        }
      },
      onCancel() {
        console.log('Hủy phiếu không thành công')
      },
    })
  }

  const handleChangeTitle = (title) => {
    setShoppingTitle(title)
  }

  const menuHandleDocument = (
    <Menu>
      <AccessControlAction
        aclActionType={ACL_ACTION_TYPE.approve__QUY_TRINH_MUA_SAM}>
        <Menu.Item
          style={{ color: blue }}
          icon={<EditOutlined />}
          onClick={handleToggleCreateModal(true)}
          disabled={isDisableUpdate}>
          Chỉnh sửa
        </Menu.Item>
      </AccessControlAction>
      <Menu.Item style={{ color: blue }} icon={<HistoryOutlined />}>
        Lịch sử
      </Menu.Item>
      <Menu.Item 
        key="print" 
        icon={<ExclamationCircleOutlined 
        style={{ color: blue }} />} 
        disabled={isDisableCancel}
        onClick={handleReject}>
        <span style={{ color: blue }}>Hủy phiếu</span>
      </Menu.Item>

      <AccessControlAction
        aclActionType={ACL_ACTION_TYPE.delete__QUY_TRINH_MUA_SAM}>
        <Menu.Item
          danger
          icon={<DeleteOutlined />}
          onClick={() => handleDelete()}
          disabled={isDisableDelete}>
          Xóa
        </Menu.Item>
      </AccessControlAction>
    </Menu>
  )
  return (
    <>
      <WorkPageWrapper isVisibleDrawerWork={isVisibleDrawerWork}>
        <DashboardLayout>
          <Helmet>
            <title>Chi tiết Quy trình mua sắm | Công việc</title>
          </Helmet>
          <PageTitle
            location={props.location}
            showTitle={true}
            title={shoppingTitle}
            status={StatusTag(TYPE_STATUS.SHOPPING, shoppingType)}>
            <HeadingButtonWrapper>
              <Dropdown overlay={menuHandleDocument} trigger={['click']}>
                <span className={'btn-style'}>
                  <MoreOutlined style={{ color: blue }} />
                </span>
              </Dropdown>
            </HeadingButtonWrapper>
          </PageTitle>
          <ContentBlockWrapper>
            <ShoppingCouponDetail 
              sId={shoppingId} 
              key={isUpdateSuccess} 
              handleChangeType={handleChangeType}
              isReject={isReject}
              setIsVisiableDrawerWork={setIsVisiableDrawerWork}/>
          </ContentBlockWrapper>
        </DashboardLayout>
      </WorkPageWrapper>
      <Modal
        width={800}
        title={'Chỉnh sửa quy trình mua sắm'}
        visible={isVisibleCreateModal}
        maskClosable={false}
        onCancel={handleToggleCreateModal(false)}
        footer={null}
        destroyOnClose>
        {isVisibleCreateModal && (
          <ShoppingCouponUpdate
            onCancel={handleToggleCreateModal(false)}
            shoppingId={shoppingId}
            ShoppingCouponStore={ShoppingCouponStore}
            onUpdateSuccess={onUpdateSuccess}
            onUpdated={onUpdated}
            handleChangeTitle={handleChangeTitle}
          />
        )}
      </Modal>
    </>
  )
}

export default inject(
  'managementAssetWarehouseStore',
  'loadingAnimationStore',
  'authenticationStore',
  'notificationStore',
  'selectPeopleStore',
  'ShoppingCouponStore',
  'aclStore'
)(observer(ShoppingCouponDetailPage))
