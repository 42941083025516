import React, { useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Button, message, Upload } from 'antd'
import { toJS } from 'mobx'
import utils from '../../../utils'
import CommentComponent from '../../../components/CommentComponent'
import MentionTextInput from '../../../components/MentionTextInput/MentionTextInput'
import {
  FormButtonGroup,
  UploadFileListWrapper,
  UploadWrapper,
} from '../../../components/CommentComponent/CommentComponentStyled'
import { SendOutlined, UploadOutlined } from '@ant-design/icons'
import { PAGE_NAME, SORT_TYPE } from '../../../constants'
import UploadFileItem from '../../../components/Common/FileItemComponent/UploadFileItem'

const DistributionVotesComment = props => {
  const {
    proposalStore,
    fileStore,
    proposalId,
    loadingAnimationStore,
    notificationStore,
    DistributionVotesStore
  } = props

  const { distributionVotestCommentList } = DistributionVotesStore
  const { notificationType } = notificationStore

  const [fileList, setFileList] = useState([])
  const [sort, setSort] = useState(SORT_TYPE.DESCENDING)
  const [commentList, setCommentList] = useState([])
  const [comment, setComment] = useState('')
  const [isShowDelComments, setIsShowDelComments] = useState(false)

  const handleOnchangeComment = (
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    setComment(newValue)
  }
  const handleChangeSortComment = sort => {
    setSort(sort)
    const commentListReverse = commentList.reverse()
    setCommentList([...commentListReverse])
  }
  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
  }
  const handleChangeFile = info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }

  const handleCreateComment = async (proposalId, commentData) => {
    try {
      const scrollCommentWrapper = document.querySelector('.listCommentWrapper')
      let newCommentList = [...commentList]

      await DistributionVotesStore.createOtherComment(proposalId, commentData)
      const response = await DistributionVotesStore.getDistributionVotesComment(proposalId)
      const newComment = response.data[response.data.length - 1]
      if (sort === SORT_TYPE.DESCENDING) {
        newCommentList.push(newComment)
      }
      if (sort === SORT_TYPE.ASCENDING) {
        setSort(SORT_TYPE.DESCENDING)
        newCommentList.unshift(newComment)
        newCommentList = newCommentList.reverse()
      }

      setCommentList(newCommentList)
      handleShowDeletedComments(newCommentList, isShowDelComments)
      setComment('')
      scrollCommentWrapper.scrollTo(0, scrollCommentWrapper.scrollHeight)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Gửi ý kiến không thành công!')
    }
  }
  const sendComment = useCallback(async () => {
    if (utils.isNullish(comment.trim())) return
    if (comment.length > 500) {
      return message.error('Ý kiến không vượt quá 500 ký tự!')
    }
    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    try {
      loadingAnimationStore.setShowSpinInline(true)
      const response = await Promise.all(batchUploadArr)
      uploadedFiles = response.map(response => response.data.file_id)
      const commentData = {
        content: comment.replace(/\s+/g, ' ').trim(),
        file_ids: uploadedFiles,
      }
      await handleCreateComment(proposalId, commentData)
      setFileList([])
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }, [fileList, commentList, comment])

  const renderCommentForm = (
    <div className={'comment-form'}>
      <MentionTextInput
        value={comment}
        readonly={false}
        onChange={handleOnchangeComment}
        placeHolder={'Viết ý kiến...'}
      />
      {fileList.length !== 0 && (
        <UploadFileListWrapper>
          {fileList.map(file => (
            <UploadFileItem
              key={file.uid}
              file_id={file.uid}
              file_name={file.name}
              file_type={utils.getExtensionFile(file.name)}
              handleRemoveFileFromUploadList={() =>
                handleRemoveFileFromUploadList(file.uid)
              }
            />
          ))}
        </UploadFileListWrapper>
      )}
      <FormButtonGroup>
        <UploadWrapper>
          <Upload
            style={{ marginLeft: 4, marginRight: 4 }}
            valuePropName={'fileList'}
            fileList={fileList}
            multiple={true}
            onChange={handleChangeFile}
            beforeUpload={() => false}
            showUploadList={false}>
            <Button htmlType={'button'}>
              <UploadOutlined /> Tải file
            </Button>
          </Upload>
        </UploadWrapper>
        <Button onClick={sendComment} type={'primary'} icon={<SendOutlined />}>
          Đăng ý kiến
        </Button>
      </FormButtonGroup>
    </div>
  )
  const handleDeleteComment = async (comment_id, index) => {
    try {
      loadingAnimationStore.setShowSpinInline(true)
      await DistributionVotesStore.deleteDistributionVotesComments(proposalId, comment_id)

      const newCommentList = [...commentList]

      const delIndex = newCommentList.findIndex(
        comment => comment.comment_id === comment_id
      )
      newCommentList[delIndex] = {
        ...newCommentList[delIndex],
        deleted: true,
      }
      setCommentList(newCommentList)
      handleShowDeletedComments(newCommentList, isShowDelComments)
      message.success('Xóa ý kiến thành công!')
    } catch (err) {
      console.log(err)
      message.error('Xóa ý kiến thất bại!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }
  const handleShowDeletedComments = async (commentList, isShow) => {
    if (isShow) {
      const newCommentList = []
      commentList.forEach(comment => {
        if (comment.isHidden) {
          newCommentList.push(...comment.arrDeletedComment)
          comment.isHidden = !comment.isHidden
        } else newCommentList.push(comment)
      })
      setCommentList(newCommentList)
    } else {
      await getOtherComment()
    }
  }

  const handleToggleShowDelCommentsMenu = () => {
    handleShowDeletedComments(commentList, !isShowDelComments)
    setIsShowDelComments(!isShowDelComments)
  }

  useEffect(() => {
    /** Danh sách comment sau khi xóa chứa các comment đã xóa liền nhau gộp thành 1 mảng */

    let commentAfterDeleteList = []
    let key = -1
    distributionVotestCommentList.map((el, i) => {
      if (!el.deleted) {
        commentAfterDeleteList.push(toJS(el))
      }
      if (el.deleted && i > key) {
        const arrDeletedComment = []
        for (let index = i; index < distributionVotestCommentList.length; index++) {
          key = index
          if (distributionVotestCommentList[index].deleted === el.deleted) {
            arrDeletedComment.push(toJS(distributionVotestCommentList[index]))
          }
          if (
            distributionVotestCommentList[index].deleted === !el.deleted ||
            index === distributionVotestCommentList.length - 1
          )
            break
        }
        commentAfterDeleteList.push({
          comment_id: arrDeletedComment[0].comment_id,
          arrDeletedComment: arrDeletedComment,
          isHidden: true,
        })
      }
    })
    setCommentList(commentAfterDeleteList)
  }, [distributionVotestCommentList, notificationType])

  const getOtherComment = async () => {
    try {
      loadingAnimationStore.setShowSpinInline(true)
      const scrollCommentWrapper = document.querySelector('.listCommentWrapper')
      await DistributionVotesStore.getDistributionVotesComment(proposalId)
      await scrollCommentWrapper.scrollTo(0, scrollCommentWrapper.scrollHeight)
    } catch (e) {
      console.log(e)
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  useEffect(() => {
    if (!proposalId) return
    if (!utils.isIOSDevice()) {
      const channel4Broadcast = new BroadcastChannel('channel4')
      channel4Broadcast.onmessage = async even => {
        if (
          even.data.type === PAGE_NAME.BUSINESS_TRIP &&
          even.data.code === proposalId
        ) {
          await getOtherComment()
        }
      }
    }
  }, [proposalId])

  useEffect(() => {
    if (!proposalId) return /** Check thiết bị IOS */
    ;(async () => {
      if (
        utils.isIOSDevice() &&
        notificationType &&
        notificationType.type === PAGE_NAME.BUSINESS_TRIP
      ) {
        await getOtherComment()
      }
    })()
  }, [proposalId, notificationType])

  useEffect(() => {
    if (!proposalId) return
    ;(async () => {
      await getOtherComment()
    })()
    return () => DistributionVotesStore.clearOtherCommentList()
  }, [proposalId])

  return (
    <CommentComponent
      handleToggleShowDelCommentsMenu={handleToggleShowDelCommentsMenu}
      handleDeleteComment={handleDeleteComment}
      isShowDelComments={isShowDelComments}
      commentList={commentList}
      handleShowDeletedComment={handleShowDeletedComments}
      renderCommentForm={renderCommentForm}
      handleChangeSortComment={handleChangeSortComment}
      sortComment={sort}
    />
  )
}

DistributionVotesComment.propTypes = {}

export default inject(
  'DistributionVotesStore',
  'proposalStore',
  'fileStore',
  'loadingAnimationStore',
  'notificationStore'
)(observer(DistributionVotesComment))
