import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import { requests } from './DefaultRequest'

export const ReportRequest = {
  getReportList: filter =>
    requests.get(`/api/v1/risk-report`, true, {
      ...filter,
    }),

  getReportlists: param =>
    requests.get(`/api/v1/risk-report/search`, true, param),
  getReportDetail: code => requests.get(`/api/v1/risk-report/${code}`),

  createReport: payload => requests.post(`/api/v1/risk-report`, payload),

  updateReport: (code, payload) =>
    requests.put(`/api/v1/companies/${code}`, payload),

  updateRiskReport: (code, payload) =>
    requests.put(`/api/v1/risk-report/${code}`, payload),

  deleteReport: code => requests.delete(`/api/v1/risk-report/${code}`),
}
