import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Select,
  InputNumber,
  Modal,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { ButtonFooter } from '../WorkPage/PopupWorkForm/PopupWorkFormStyled'
import ManagementAssetWarehouseUnitCreate from './ManagementAssetWarehouseUnitCreate'
const { Option } = Select

const ManagementAssetWarehouseCreate = props => {
  const [form] = Form.useForm()
  const { managementAssetWarehouseStore, selectPeopleStore } = props
  const [isCreating, setIsCreating] = useState(false)
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false)
  const [duplicateWarning, setDuplicateWarning] = useState('')
  const [changeTax, setChangeTax] = useState(0)
  const [changePrice, setChangePrice] = useState(0)
  const handleToggleCreateModal = bool => event => {
    setIsVisibleCreateModal(bool)
  }

  const defaultApprovalLevel = {
    level: 0,
    name: 'Bộ phận đề nghị',
    allow_edit: false,
    username: null,
    is_deleted: false,
  }
  useEffect(() => {
    managementAssetWarehouseStore.getManagementAssetWarehouseUnit()
    managementAssetWarehouseStore.getManagementAssetWarehouseAssetTypes()
  }, [props])

  useEffect(() => {
    const total_money = ((changeTax / 100) * changePrice + changePrice).toFixed(
      2
    )
    form.setFieldsValue({
      total_money: total_money,
    })
  }, [changeTax, changePrice])

  const checkDuplicateAsset = (name, typeId) => {
    const normalizedDescription = name?.trim().toLowerCase()

    const existingAssetType = managementAssetWarehouseStore.managementAssetWarehouseList?.find(
      type =>
        type.asset_type?.id === typeId &&
        type.asset_name.toLowerCase() === normalizedDescription
    )

    return !!existingAssetType
  }

  const onFinish = async value => {
    try {
      setIsCreating(true)

      const assetData = {
        amount: value.amount,
        asset_name: value.asset_name.trim(),
        asset_type_id: value.asset_type_id,
        asset_unit_name: value.asset_unit_name,
        property: value.property,
        tax: value.tax,
        price: value.price,
        total_money: value.total_money,
      }

      if (checkDuplicateAsset(assetData.asset_name, assetData.asset_type_id)) {
        setDuplicateWarning(
          `Tên văn phòng phẩm ${assetData.asset_name} đã tồn tại.`
        )
        setIsCreating(false)
        return
      }

      setDuplicateWarning('')

      const resultCreate = await managementAssetWarehouseStore.createManagementAssetWarehouse(
        assetData
      )
      const assetHistoryData = {
        asset_id: resultCreate.id,
        tax: value.tax,
        price: value.price,
        total_money: value.total_money,
        quantity: value.amount,
        type: 'CREATE',
      }
      await managementAssetWarehouseStore.createManagementAssetHistoryWarehouse(
        assetHistoryData
      )
      message.success('Tạo tài sản thành công')
      props.onCancel()
      await managementAssetWarehouseStore.getManagementAssetWarehousetList()
    } catch (error) {
      console.log(error)
      message.error('Tạo tài sản Thất bại')
      props.onCancel()
    } finally {
      setIsCreating(false)
    }
  }

  const validateAssetName = (_, value) => {
    const typeId = form.getFieldValue('asset_type_id')
    if (typeId && checkDuplicateAsset(value, typeId)) {
      return Promise.reject(`Tên văn phòng phẩm ${value} đã tồn tại.`)
    }
    return Promise.resolve()
  }

  const sortedTypes = managementAssetWarehouseStore.managementAssetWarehouseTypes?.content?.sort(
    (a, b) => {
      return a.description.localeCompare(b.description)
    }
  )

  return (
    <>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={20}>
            <Form.Item
              label="Loại văn phòng phẩm"
              name="asset_type_id"
              validateStatus="success"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn loại văn phòng phẩm',
                },
              ]}>
              <Select
                showSearch
                placeholder={'Loại văn phòng phẩm'}
                filterOption={true}
                allowClear
                optionFilterProp={'name'}>
                {sortedTypes.map(type => (
                  <Option key={type.id} name={type.description} value={type.id}>
                    {type.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{
              display: 'flex',
              marginTop: '27px',
              padding: '0 5px',
              justifyContent: 'flex-end',
              width: '100%',
            }}>
            <Button
              icon={<PlusOutlined />}
              onClick={handleToggleCreateModal(true)}>
              Tạo mới
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label={'Tên văn phòng phẩm:'}
              name={'asset_name'}
              validateStatus={duplicateWarning ? 'error' : ''}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên văn phòng phẩm',
                },
                {
                  validator: validateAssetName,
                },
                {
                  max: 100,
                  message: 'Tên văn phòng phẩm không được dài quá 100 ký tự',
                },
              ]}>
              <Input placeholder={'Nhập tên văn phòng phẩm'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={'Đặc tính:'}
              name={'property'}
              validateStatus="success"
              rules={[
                {
                  max: 100,
                  message: 'Đặc tính không được dài quá 100 ký tự',
                },
              ]}>
              <Input placeholder={'Nhập tên đặc tính'} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item
              label={'Đơn vị tính:'}
              name={'asset_unit_name'}
              validateStatus="success"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập đơn vị tính',
                },
                {
                  max: 100,
                  message: 'Đơn vị tính không được dài quá 100 ký tự',
                },
              ]}>
              <Input placeholder={'Nhập tên đơn vị tính'} />
            </Form.Item>
          </Col>

          <Col span={5}>
            <div style={{ display: 'flex', width: '100%' }}>
              <Form.Item label={'Thuế:'} name={'tax'} validateStatus="success">
                <InputNumber
                  onChange={value => setChangeTax(value)}
                  min={0}
                  style={{
                    width: '100%',
                  }}
                  placeholder={'Nhập thuế'}
                />
              </Form.Item>
              <span style={{ marginTop: '35px', marginLeft: '5px' }}>%</span>
            </div>
          </Col>

          <Col span={12}>
            <Form.Item
              label={'Đơn giá trước thuế:'}
              name={'price'}
              validateStatus="success"
              rules={[
                {
                  validator: (_, value) => {
                    if (value < 0) {
                      return Promise.reject(
                        'Đơn giá trước thuế không thể là số âm'
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}>
              <InputNumber
                onChange={value => setChangePrice(value)}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                precision={2}
                style={{
                  width: '100%',
                }}
                placeholder={'Nhập đơn giá trước thuế'}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={'Đơn giá sau thuế:'}
              name={'total_money'}
              validateStatus="success">
              <InputNumber
                readOnly
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                style={{
                  width: '100%',
                }}
                placeholder={'Đơn giá sau thuế'}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={'Số lượng trong kho:'}
              name={'amount'}
              validateStatus="success"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập số lượng',
                },
                {
                  validator: (_, value) => {
                    if (value < 0) {
                      return Promise.reject('Số lượng không thể là số âm')
                    } else if (value === 0) {
                      return Promise.reject('Số lượng phải lớn hơn 0')
                    }
                    return Promise.resolve()
                  },
                },
              ]}>
              <InputNumber
                style={{
                  width: '100%',
                }}
                onChange={value => {
                  if (value && !Number.isInteger(value)) {
                    const roundedValue = Math.round(value)
                    form.setFieldsValue({ amount: roundedValue })
                  }
                }}
                placeholder={'Nhập số lượng'}
              />
            </Form.Item>
          </Col>
        </Row>
        <ButtonFooter>
          <Space>
            <Button icon={<CloseOutlined />} danger onClick={props.onCancel}>
              Đóng
            </Button>
            <Button
              icon={<EditOutlined />}
              type={'primary'}
              htmlType={'submit'}
              loading={isCreating}>
              Tạo mới
            </Button>
          </Space>
        </ButtonFooter>
      </Form>
      <Modal
        width={500}
        title={'Tạo mới loại văn phòng phẩm'}
        visible={isVisibleCreateModal}
        maskClosable={false}
        onCancel={handleToggleCreateModal(false)}
        footer={null}
        destroyOnClose>
        {isVisibleCreateModal && (
          <ManagementAssetWarehouseUnitCreate
            onCancel={handleToggleCreateModal(false)}
          />
        )}
      </Modal>
    </>
  )
}

export default inject(
  'managementAssetWarehouseStore',
  'selectPeopleStore'
)(observer(ManagementAssetWarehouseCreate))
