import { Button, Form, Input, Modal, message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import authenticationStore from '../../stores/authenticationStore'
import departmentStore from '../../stores/departmentStore'
import { MODAL_MODE } from '../../ui_constants'
import validator from '../../validator'

const CreateDepartmentModal = props => {
  const {
    setCheckUpdate,
    isVisibleCreateDepartmentModal,
    mode,
    handleCloseCreateDepartmentModal,
    currentDepartment,
    userStore,
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [initialValue, setInitialValue] = useState('')

  const [form] = Form.useForm()

  useEffect(() => {
    const fetchData = async () => {
      if (isVisibleCreateDepartmentModal && mode === MODAL_MODE.UPDATE) {
        setInitialValue(currentDepartment?.name)
        await form.setFieldsValue({
          name: currentDepartment?.name,
        })
      } else {
        form.setFieldsValue({
          name: '',
        })
      }
    }

    fetchData()
  }, [isVisibleCreateDepartmentModal, mode])

  const onFinish = async values => {
    try {
      setIsLoading(true)

      const { name } = values
      if (mode === MODAL_MODE.UPDATE) {
        if (name.trim() !== currentDepartment.name)
          await departmentStore.update(currentDepartment.code, name)
      } else {
        await departmentStore.create(name, userStore.companyCodeCurrent)
      }

      closeAndResetForm()

      message.success(
        `${
          mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`
        } phòng ban thành công`
      )
      setCheckUpdate(true)
      await departmentStore.getAllDepartmentList(
        userStore.companyCodeCurrent || authenticationStore.currentCompanyCode()
      )
    } catch (e) {
      if (
        (e.errorCode && e.errorCode === 'DEPARTMENT-102') ||
        (e.vi && e.vi === 'Đã có lỗi xảy ra trong quá trình kết nối!') ||
        (e.errorCode && e.errorCode === 'API-501')
      ) {
        message.error('Tên phòng ban đã tồn tại')
      } else {
        message.error('Đã có lỗi xảy ra!')
      }
    } finally {
      setIsLoading(false)
      setCheckUpdate(false)
    }
  }

  const closeAndResetForm = () => {
    form.resetFields()
    form.setFieldsValue({
      name: initialValue,
    })
    handleCloseCreateDepartmentModal()
  }

  return (
    <Modal
      title={`${
        mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`
      } phòng ban`}
      footer={null}
      closable={true}
      visible={isVisibleCreateDepartmentModal}
      onCancel={closeAndResetForm}>
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <Form.Item
          name={'name'}
          rules={[
            { required: true, message: 'Vui lòng nhập tên phòng ban!' },
            { validator: validator.validateInputString },
          ]}
          label={'Tên phòng ban:'}>
          <Input placeholder={'Nhập tên phòng ban'} />
        </Form.Item>

        <Form.Item>
          <Button loading={isLoading} htmlType={'submit'} type={'primary'}>
            {mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

CreateDepartmentModal.propTypes = {}

export default withRouter(inject('userStore')(observer(CreateDepartmentModal)))
