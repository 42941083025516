import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  FileTextOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Upload,
  message,
} from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import FileItem from '../../../../components/Common/FileItemComponent/FileItem'
import RichEditor from '../../../../components/RichEditor/RichEditor'
import SelectPeoplePopup from '../../../../components/SelectPeoplePopup'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_LIST,
  ISO_DATE_FORMAT,
  MODES,
  SHIFTS,
} from '../../../../constants'
import utils from '../../../../utils'
import ApprovalList from '../../../Administrative/LeaveForm/ApproverListModal/Modal'
import {
  FormActionFooter,
  OriginFilesList,
} from '../../../MissionEditPage/MissionEditPageStyled'
import { submissionMenu } from '../ProposalPage/ProposalPage'
import validator from './../../../../validator'

const { RangePicker } = DatePicker
const { Option } = Select

const ProposalDetailUpdate = props => {
  const {
    proposalStore,
    selectPeopleStore,
    fileStore,
    proposalType,
    proposalTypeStore,
    name,
  } = props

  const {
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    selectUserData,
    selectDepartmentData,
    selectGroupData,
  } = selectPeopleStore

  const [form] = Form.useForm()

  const CONTENT_REF = useRef()

  const [fileList, setFileList] = useState([])
  const [editorContent, setEditorContent] = useState()
  const [followerList, setFollowerList] = useState([])
  const [applyBieuMau, setApplyBieuMau] = useState(false)

  const { formInputTemplate, otherDetail, formProposalType } = proposalStore
  const [originFiles, setOriginFiles] = useState([])

  const [isUpdating, setIsUpdating] = useState(false)

  const defaultDateRange =
    otherDetail.from_date != null
      ? otherDetail.from_date === ''
        ? []
        : [
            moment(otherDetail.from_date, ISO_DATE_FORMAT),
            moment(otherDetail.to_date, ISO_DATE_FORMAT),
          ]
      : []
  // const defaultDateRange =
  //   otherDetail.from_date != null
  //     ? [
  //         moment(otherDetail.from_date, ISO_DATE_FORMAT),
  //         moment(otherDetail.to_date, ISO_DATE_FORMAT),
  //       ]
  //     : []

  useEffect(() => {
    if (!otherDetail.id) return

    form.setFieldsValue({
      title: otherDetail.title,
      proposal_type: name,
    })

    setEditorContent(otherDetail.reason)

    let followerList = []

    otherDetail.followers.forEach(follower => {
      if (follower?.user_name) {
        followerList.push({
          assignee_type: ASSIGNEE_TYPE.USER,
          full_name: follower.name_lowercase.toUpperCase(),
          id: follower.user_name,
          image_id: follower.image_id,
          permission: ASSIGNEE_TYPE.FOLLOWER,
        })
      }
    })

    if (followerList.length > 0) {
      selectPeopleStore.setSelectUserData({
        ...selectUserData,
        VIEW: followerList,
      })
    }

    setOriginFiles(otherDetail.attachments)
    ;(async () => {
      await proposalStore.getProposalTypeApprovalConfig(
        otherDetail.proposal_type
      )
    })()

    return () => {
      selectPeopleStore.clearSelectUserData('VIEW')
    }
  }, [])

  const handleChangeFile = useCallback(
    info => {
      const filteredFileList = info.fileList.filter(
        (elem, index, fileArray) =>
          fileArray.findIndex(file => file.name === elem.name) === index
      )
      setFileList(filteredFileList)
    },
    [fileList]
  )

  useEffect(() => {
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const onApplyBieuMauChange = value => {
    setApplyBieuMau(value)
    if (value) {
      if (formInputTemplate) {
        setEditorContent(formInputTemplate)
      }
    }
  }

  const onFinish = async values => {
    const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []

    const batchUploadArr = []

    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })

    const response = await Promise.all(batchUploadArr)

    const originFileList = (otherDetail.attachments || []).map(
      file => file.file_id
    )
    const afterFileList = [
      ...response.map(el => el.data.file_id),
      ...originFiles.map(file => file.file_id),
    ]

    let newAttachments = []
    let removeAttachments = []

    afterFileList.forEach(file => {
      if (!originFileList.includes(file)) {
        newAttachments.push(file)
      }
    })
    originFileList.forEach(file => {
      if (!afterFileList.includes(file)) {
        removeAttachments.push(file)
      }
    })

    const removeFollowers = otherDetail.followers.filter(follower => {
      if (!followerList.some(item => item.id === follower.user_name)) {
        return follower
      }
    })

    const newFollowers = followerList.filter(follower => {
      if (!otherDetail.followers.some(item => item.user_name === follower.id)) {
        return follower
      }
    })

    let fromDate =
      values?.date_picker && values?.date_picker.length == 2
        ? moment(values?.date_picker[0]).format(ISO_DATE_FORMAT)
        : defaultDateRange.length > 0
        ? moment(defaultDateRange[0]).format(ISO_DATE_FORMAT)
        : ''
    let toDate =
      values?.date_picker && values?.date_picker.length == 2
        ? moment(values?.date_picker[1]).format(ISO_DATE_FORMAT)
        : defaultDateRange.length > 0
        ? moment(defaultDateRange[1]).format(ISO_DATE_FORMAT)
        : ''
    let proposal = {
      ...values,
      code: otherDetail.proposal_code,
      title: values.title.replace(/\s+/g, ' ').trim(),
      proposal_type: otherDetail.proposal_type,
      attachments: {
        remove_items: removeAttachments,
        new_items: newAttachments,
      },
      assignees: {
        remove_items: removeFollowers.map(follower => ({
          assignee_code: follower.user_name,
          assignee_type: 'USER',
          permission: 'VIEW',
        })),
        new_items: newFollowers.map(follower => ({
          assignee_code: follower.id,
          assignee_type: 'USER',
          permission: 'VIEW',
        })),
      },
      reason_for_leaving: reasonForLeaving,
      // type_of_leave: values?.type_of_leave,
      from_date: fromDate,
      to_date: toDate,
      overtime: overtime,
      reason: CONTENT_REF.current.editor.getData(),
      proposalTimeList: proposalTimeList,
    }

    try {
      setIsUpdating(true)
      await proposalStore.updateProposal(proposal)
      await proposalStore.getOtherDetail(proposal.code)
      message.success('Chỉnh sửa thành công!')
      props.onCancel()
    } catch (error) {
      message.error(error.vi || 'Có lỗi xảy ra')
      console.log(error)
    } finally {
      setIsUpdating(false)
    }
  }
  const [arrSubmissionMenu, setArrSubmissionMenu] = useState([])
  useEffect(() => {
    ;(async () => {
      const response = await proposalTypeStore.getProposalTypes()
      const newProposalTypes = [
        {
          id_group: '3',
          name_group: 'Hành chính - Khác',
          child: response.data
            .filter(item => item.id.includes('OTHER_'))
            .map(item => ({
              id_child: item.id,
              name_child: item.name,
            })),
        },
      ]
      const updatedSubmissionMenu = submissionMenu.concat(newProposalTypes)
      setArrSubmissionMenu(updatedSubmissionMenu)
    })()
  }, [])
  const showSubmissionMenu = useMemo(() => {
    return arrSubmissionMenu?.length >= 3 ? (
      arrSubmissionMenu?.map((unit, index) => {
        return (
          <Select.OptGroup
            label={
              <b
                style={{
                  fontSize: 15,
                  fontWeight: 500,
                  color: '#000',
                }}>
                {unit.name_group}
              </b>
            }
            key={unit.id_group}>
            {unit?.child.map(child => {
              return (
                <Select.OptGroup value={child.id_child} key={child.id_child}>
                  {child.name_child}
                </Select.OptGroup>
              )
            })}
          </Select.OptGroup>
        )
      })
    ) : (
      <></>
    )
  }, [arrSubmissionMenu])
  const [reasonForLeaving, setReasonForLeaving] = useState(
    otherDetail.reason_for_leaving
  )

  const handleChangeReasonForLeaving = value => {
    setReasonForLeaving(value.target.checked ? value.target.value : null)
  }

  const [overtime, setOvertime] = useState(otherDetail?.overtime)

  const handleChangeOvertime = value => {
    if (value <= 12) {
      setOvertime(value)
    }
  }

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)

  const handleCheckboxChange = event => {
    setIsCheckboxChecked(event.target.checked)
  }
  const [selectedDates, setSelectedDates] = useState([])
  const [proposalTimeList, setProposalTimeList] = useState(
    otherDetail?.proposalTimeList || []
  )

  const handleShiftChange = (value, recordId) => {
    const updatedWorkDays = proposalTimeList.map(day =>
      day.id === recordId ? { ...day, shift: value } : day
    )
    setProposalTimeList(updatedWorkDays)
  }

  const handleModeChange = (value, recordId) => {
    let modeTypeValue = 0

    if (value === MODES.WOMEN.key) {
      modeTypeValue = 0.5
    } else if (value === MODES.CHILD_CARE.key) {
      modeTypeValue = 1
    }
    const updatedWorkDays = proposalTimeList.map(day =>
      day.id === recordId
        ? { ...day, mode: value, mode_type: modeTypeValue }
        : day
    )
    setProposalTimeList(updatedWorkDays)
  }

  const handleModeTypeChange = (value, recordKey) => {
    const updatedWorkDays = proposalTimeList.map(day =>
      day.id === recordKey ? { ...day, mode_type: value } : day
    )
    setProposalTimeList(updatedWorkDays)
  }

  const handleNoteChange = (value, recordKey) => {
    const updatedWorkDays = proposalTimeList.map(day =>
      day.id === recordKey ? { ...day, note: value } : day
    )
    setProposalTimeList(updatedWorkDays)
  }

  const handleDateChange = (value, dates) => {
    setSelectedDates(dates)
  }

  // Trả về mảng của các ngày trong khoảng startDate đến endDate
  function getRange(startDate, endDate) {
    let fromDate = moment(startDate, 'DD/MM/YYYY')
    let toDate = moment(endDate, 'DD/MM/YYYY')
    let diff = toDate.diff(fromDate, 'days')
    let range = []

    for (let i = 0; i <= diff; i++) {
      let currentDay = moment(startDate, 'DD/MM/YYYY').add(i, 'days')
      if (proposalType === 'OVERTIME_NORMAL') {
        range.push(currentDay)
      } else {
        // if (currentDay.day() !== 6 && currentDay.day() !== 0) {

        // }
        range.push(currentDay)
      }
    }

    return range
  }

  const generateWorkDays = () => {
    const workDays = []
    const dateRange = getRange(selectedDates[0], selectedDates[1])
    if (dateRange?.length > 0) {
      dateRange.forEach((date, index) => {
        const formattedDate = date

        const existingDay = otherDetail?.proposalTimeList?.find(
          day =>
            moment(day.date).format('DD/MM/YYYY') ===
            moment(formattedDate).format('DD/MM/YYYY')
        )

        if (existingDay) {
          workDays.push({ ...existingDay })
        } else {
          if (proposalType === 'LEAVE_ABNORMAL') {
            workDays.push({
              id: index,
              date: formattedDate,
              shift: 'morning',
              mode: 'normal',
              mode_type: 0,
              note: null,
            })
          } else {
            workDays.push({
              id: index,
              date: formattedDate,
              shift: 'allDay',
              mode: 'normal',
              note: null,
            })
          }
        }
      })
      setProposalTimeList(workDays)
    } else {
      setProposalTimeList([])
    }
  }

  useEffect(() => {
    selectedDates?.length > 0 && generateWorkDays()
  }, [selectedDates])

  const columns = [
    {
      title: 'Ngày làm việc',
      dataIndex: 'date',
      key: 'date',
      render: text => <span>{moment(text).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Buổi làm việc',
      dataIndex: 'shift',
      key: 'shift',
      render: (text, record) => (
        <Select
          style={{
            width: '120px',
          }}
          value={text}
          onChange={value => handleShiftChange(value, record.id)}>
          <Option value={SHIFTS.ALL_DAY.key}>{SHIFTS.ALL_DAY.value}</Option>
          <Option value={SHIFTS.MORNING.key}>{SHIFTS.MORNING.value}</Option>
          <Option value={SHIFTS.AFTERNOON.key}>{SHIFTS.AFTERNOON.value}</Option>
          <Option value={SHIFTS.EMPTY.key}>{SHIFTS.EMPTY.value}</Option>
        </Select>
      ),
    },
    ...(proposalType !== 'BUSINESS_TRIP' && proposalType !== 'OVERTIME_NORMAL'
      ? [
          {
            title: 'Loại nghỉ phép',
            dataIndex: 'mode',
            key: 'mode',
            render: (text, record) => (
              <Select
                style={{
                  width: '120px',
                }}
                value={text}
                onChange={value => handleModeChange(value, record.id)}>
                <Option value={MODES.NORMAL.key}>{MODES.NORMAL.value}</Option>
                <Option value={MODES.SPECIAL.key}>{MODES.SPECIAL.value}</Option>
              </Select>
            ),
          },
        ]
      : []),
    ...(proposalType === 'OVERTIME_NORMAL'
      ? [
          {
            title: 'Giờ',
            dataIndex: 'mode_type',
            key: 'mode_type',
            width: '160px',
            render: (text, record) => {
              return (
                <InputNumber
                  style={{ width: '90px' }}
                  defaultValue={record.mode_type}
                  min={0}
                  max={8}
                  onChange={value => handleModeTypeChange(value, record.id)}
                />
              )
            },
          },
        ]
      : []),
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      render: (text, record) => (
        <Input
          defaultValue={text}
          onChange={e => handleNoteChange(e.target.value, record.id)}
          placeholder="Nhập ghi chú"
        />
      ),
    },
  ]

  const leaveAbnormalColumns = [
    {
      title: 'Ngày làm việc',
      dataIndex: 'date',
      key: 'date',
      render: text => <span>{moment(text).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Buổi làm việc',
      dataIndex: 'shift',
      key: 'shift',
      render: (text, record) => (
        <Select
          style={{
            width: '120px',
          }}
          value={text}
          onChange={value => handleShiftChange(value, record.id)}>
          <Option value={SHIFTS.MORNING.key}>{SHIFTS.MORNING.value}</Option>
          <Option value={SHIFTS.AFTERNOON.key}>{SHIFTS.AFTERNOON.value}</Option>
          <Option value={SHIFTS.EMPTY.key}>{SHIFTS.EMPTY.value}</Option>
        </Select>
      ),
    },
    {
      title: 'Loại nghỉ phép',
      dataIndex: 'mode',
      key: 'mode',
      render: (text, record) => (
        <Select
          style={{
            width: '160px',
          }}
          value={text}
          onChange={value => handleModeChange(value, record.id)}>
          <Option value={MODES.NORMAL.key}>{MODES.NORMAL.value}</Option>
          <Option value={MODES.WOMEN.key}>{MODES.WOMEN.value}</Option>
          <Option value={MODES.CHILD_CARE.key}>{MODES.CHILD_CARE.value}</Option>
          <Option value={MODES.WORK_REASON.key}>
            {MODES.WORK_REASON.value}
          </Option>
          <Option value={MODES.BY_REGIME.key}>{MODES.BY_REGIME.value}</Option>
        </Select>
      ),
    },
    {
      title: 'Loại chế độ (giờ)',
      dataIndex: 'mode_type',
      key: 'mode_type',
      width: '160px',
      render: (text, record) => {
        switch (record.mode) {
          case MODES.NORMAL.key:
          case MODES.WORK_REASON.key:
          case MODES.BY_REGIME.key:
            return (
              <InputNumber
                style={{ width: '90px' }}
                defaultValue={record.mode_type}
                min={0}
                max={8}
                onChange={value => handleModeTypeChange(value, record.id)}
              />
            )
          case MODES.WOMEN.key:
            return <span>0.5</span>
          case MODES.CHILD_CARE.key:
            return <span>1</span>
          default:
            return null
        }
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      with: '250px',
      render: (text, record) => (
        <Input
          defaultValue={text}
          onChange={e => handleNoteChange(e.target.value, record.id)}
          placeholder="Nhập ghi chú"
        />
      ),
    },
  ]

  return (
    <>
      <Form
        scrollToFirstError={true}
        form={form}
        name={'phieu-trinh-khac-form'}
        layout={'vertical'}
        onFinish={onFinish}>
        <Row gutter={15}>
          <Col xs={24} lg={24}>
            <Form.Item name={'proposal_type'} label={'Loại:'}>
              <Input disabled value={name} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              name={'title'}
              label={'Tiêu đề:'}
              rules={[
                { required: true, message: 'Vui lòng nhập tiêu đề' },
                {
                  max: 500,
                  message: 'Tiêu đề không được dài quá 500 kí tự!',
                },
                { validator: validator.validateInputString },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={
                <div>
                  <p>Nội dung:</p>
                  {/* <Checkbox
                    checked={applyBieuMau}
                    onChange={e => onApplyBieuMauChange(e.target.checked)}>
                    <span>Áp dụng Văn bản cũ</span>
                  </Checkbox> */}
                </div>
              }>
              <RichEditor
                key={editorContent}
                EDITOR_REF={CONTENT_REF}
                placeholder={'Nhập nội dung'}
                editorContent={editorContent}
              />
            </Form.Item>
          </Col>
          {/* {proposalType === 'LEAVE_ABNORMAL' && (
            <Col xs={24} lg={24}>
              <label>Lý do:</label>
              <br />
              <Checkbox.Group
                style={{ marginTop: '8px', marginBottom: '8px' }}
                value={reasonForLeaving}>
                <Checkbox
                  value={'Theo chế độ'}
                  onChange={handleChangeReasonForLeaving}>
                  Theo chế độ
                </Checkbox>
                <Checkbox
                  value={'Lý do cá nhân'}
                  onChange={handleChangeReasonForLeaving}>
                  Lý do cá nhân
                </Checkbox>
                <Checkbox
                  value={'Lý do công việc'}
                  onChange={handleChangeReasonForLeaving}>
                  Lý do công việc
                </Checkbox>
              </Checkbox.Group>
            </Col>
          )} */}
          {/* {proposalType === 'LEAVE' && (
            <Col xs={24} lg={24}>
              <Form.Item
                label={'Loại nghỉ phép:'}
                name={'type_of_leave'}
                // rules={[
                //   { required: true, message: 'Vui lòng chọn loại nghỉ phép' },
                // ]}
                initialValue={otherDetail?.type_of_leave}>
                <Radio.Group style={{ marginTop: '8px', marginBottom: '8px' }}>
                  <Radio value={'Nghỉ phép 1/2 ngày công'}>
                    Nghỉ phép 1/2 ngày công
                  </Radio>
                  <Radio value={'Nghỉ phép theo chế độ'}>
                    Nghỉ phép theo chế độ
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          )} */}
          <Col xs={24} sm={12} md={10}>
            <Form.Item
              initialValue={defaultDateRange}
              name={'date_picker'}
              label={'Thời gian:'}
              //tạm thời khóa
              rules={[{ required: true, message: 'Vui lòng chọn thời gian' }]}>
              <RangePicker
                // defaultValue={defaultDateRange}
                format={DATE_FORMAT_LIST}
                style={{ width: '100%' }}
                onChange={handleDateChange}
              />
            </Form.Item>
          </Col>

          {(proposalType === 'LEAVE' ||
            proposalType === 'SICK' ||
            proposalType === 'BUSINESS_TRIP' ||
            proposalType === 'RETIMEKEEPING' ||
            proposalType === 'OVERTIME_NORMAL' ||
            proposalType === 'LEAVE_ABNORMAL') &&
            proposalTimeList?.length > 0 && (
              <Col xs={24} style={{ marginBottom: '25px' }}>
                <Table
                  pagination={false}
                  columns={
                    proposalType === 'LEAVE_ABNORMAL'
                      ? leaveAbnormalColumns
                      : columns
                  }
                  rowKey={record => record.key || record.id}
                  dataSource={proposalTimeList || []}
                />
              </Col>
            )}

          {/* {(proposalType === 'OVERTIME_NORMAL' ||
            proposalType === 'OVERTIME_DAYOFF' ||
            proposalType === 'OVERTIME_HOLIDAY') && (
            <>
              <Col xs={24} lg={24}>
                <label>Thêm số giờ làm việc:</label>
                <Checkbox
                  style={{ marginLeft: '5px', paddingTop: '5px' }}
                  checked={isCheckboxChecked}
                  onChange={handleCheckboxChange}></Checkbox>
                <br />
                <NumericInput
                  value={overtime}
                  onChange={handleChangeOvertime}
                  disabled={!isCheckboxChecked}
                />
              </Col>
            </>
          )} */}
          <Col xs={24} lg={24}>
            <Form.Item name={'file_ids'} label={'Tài liệu đính kèm:'}>
              <Upload
                valuePropName={'fileList'}
                fileList={fileList}
                multiple={true}
                onChange={handleChangeFile}
                beforeUpload={() => false}
                iconRender={() => <FileTextOutlined />}>
                <Button htmlType={'button'}>
                  <UploadOutlined />
                  Chọn tài liệu đính kèm
                </Button>
              </Upload>
            </Form.Item>

            <OriginFilesList>
              {originFiles.map(file => (
                <div className={'file'} key={file.file_id}>
                  <FileItem
                    file_id={file.file_id}
                    file_name={file.file_name}
                    file_type={utils.getExtensionFile(file.file_name)}
                  />
                  <span
                    className={'delete-icon'}
                    style={{ marginRight: '5px' }}
                    onClick={() =>
                      setOriginFiles(
                        originFiles.filter(el => el.file_id !== file.file_id)
                      )
                    }>
                    <DeleteOutlined />
                  </span>
                </div>
              ))}
            </OriginFilesList>
          </Col>
          {/* <Col xs={24} lg={12}>
            <label
              style={{ display: 'inline-block', margin: '15px 8px 25px 0' }}>
              Người theo dõi:
            </label>

            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)
                  }
                />
              </Tooltip>
            </Space>

            {followerList.length > 0 && (
              <SelectList>
                {followerList.map(el => (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'}
                    key={el.id}
                    closable
                    onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.full_name}
                      icon={
                        (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                          <BankOutlined />
                        )) ||
                        (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                          <TeamOutlined />
                        ))
                      }
                    />
                  </Tag>
                ))}
              </SelectList>
            )}
          </Col> */}
        </Row>

        <ApprovalList />
        <SelectPeoplePopup
          disableSelectDepartment
          handleCancelSelectUser={handleCancelSelectUserDepartment}
          handleSubmitSelectUser={() =>
            setIsVisibleSelectUserDepartmentPopup(false)
          }
          isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        />

        <FormActionFooter>
          <Button icon={<CloseOutlined />} danger onClick={props.onCancel}>
            Huỷ bỏ
          </Button>
          <Button
            loading={isUpdating}
            style={{ marginLeft: 10 }}
            icon={<CheckOutlined />}
            type={'primary'}
            htmlType={'submit'}>
            Đồng ý chỉnh sửa
          </Button>
        </FormActionFooter>
      </Form>
    </>
  )
}

ProposalDetailUpdate.propTypes = {
  onCancel: PropTypes.func.isRequired,
  // code: PropTypes.string.isRequired,
  showSubmissionMenu: PropTypes.func,
}
export default inject(
  'proposalStore',
  'selectPeopleStore',
  'fileStore',
  'proposalTypeStore'
)(observer(ProposalDetailUpdate))
