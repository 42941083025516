import {
  Button,
  Empty,
  message,
  Modal,
  Col,
  Input,
  Row,
  Select,
  DatePicker,
  Pagination,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import TableComponent from '../../components/Common/TableComponent'
import {
  QuestionCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import ManagementAssetWarehouseUnitUpdate from './ManagementAssetWarehouseUnitUpdate'
import { TableBottomPaginationBlock } from '../../components/Common/Table'

const { Option } = Select
const { confirm } = Modal

const ManagementAssetWarehouseUnitTable = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')

  const {
    selectPeopleStore,
    authenticationStore,
    managementAssetWarehouseStore,
  } = props
  const {
    managementAssetWarehouseTypes,
    managementAssetWarehouseTypesOrigin,
    managementAssetWarehouseList,
    assetName,
    managementAssetTypes,
    totalAssetTypes,
    pageIndex,
    sizeIndex,
  } = managementAssetWarehouseStore

  useEffect(() => {
    ;(async () => {
      await loadManagerAssetTypeList()
    })()
    ;(async () => {
      await managementAssetWarehouseStore.fetchManagementAssetTypeList()
    })()
  }, [pageIndex, sizeIndex, searchTerm])

  useEffect(() => {
    managementAssetWarehouseStore.fetchManagementAssetTypeList()
  }, [])

  const loadManagerAssetTypeList = async (search = '') => {
    try {
      setIsLoading(true)
      await managementAssetWarehouseStore.fetchManagementAssetTypeList(search)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangePageAssetTypeLeave = useCallback(
    p => {
      const pageIndex = p - 1
      managementAssetWarehouseStore.setFilter('pageIndex', pageIndex)
    },
    [managementAssetWarehouseStore]
  )

  const handleOpenUpdateModal = id => {
    setIsVisibleUpdateModal(true)
    setSelectedTemplateId(id)
  }

  const handleCancelUpdateModal = () => {
    setIsVisibleUpdateModal(false)
  }

  const handleDelete = async id => {
    setIsLoading(true)
    try {
      await managementAssetWarehouseStore.deleteAssetType(id)
      await loadManagerAssetTypeList()
      message.success('Đã xóa!')
    } catch (err) {
      console.error(err)
      console.log(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  const showConfirm = (e, id) => {
    e.stopPropagation()
    setIsVisibleUpdateModal(false)
    confirm({
      title: 'Bạn có chắc chắn muốn xóa loại văn phòng phẩm này ?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Xóa',
      cancelText: 'Không',
      okType: 'danger',
      onOk: () => handleDelete(id),
    })
  }

  const debouncedSearch = useCallback(
    debounce(async value => {
      setIsLoading(true)
      await loadManagerAssetTypeList(value)
      setIsLoading(false)
    }, 500),
    []
  )

  const handleSearch = async value => {
    setSearchTerm(value)
    managementAssetWarehouseStore.setFilter('pageIndex', 0)
    debouncedSearch(value)
  }

  const handleChange = e => {
    const { value } = e.target
    setSearchTerm(value.trimStart())
    handleSearch(value.trimStart())
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleSearch(searchTerm)
    }
  }

  const tableColumns = [
    {
      title: 'STT',
      width: 50,
      render: (id, record, index) => index + 1,
    },
    {
      title: 'Loại văn phòng phẩm',
      width: 200,
      render: record => (
        <p
          style={{
            marginBottom: '0px',
          }}>
          {record?.description}
        </p>
      ),
    },
    {
      title: 'Ngày tạo',
      width: 50,
      render: record => {
        const date = new Date(record?.createAt || 0)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        const seconds = String(date.getSeconds()).padStart(2, '0')

        const formattedDate = `${day}/${month}/${year}`
        return <p style={{ marginBottom: '0px' }}>{formattedDate}</p>
      },
    },
    {
      title: 'Thao tác',
      width: 50,
      align: 'center',
      //ignore onRow event
      onCell: record => {
        return {
          onClick: event => {
            event.stopPropagation()
          },
        }
      },
      render: record => (
        <div>
          <EditOutlined
            style={{ marginRight: '4px' }}
            onClick={() => handleOpenUpdateModal(record.id)}
          />
          <DeleteOutlined
            style={{ color: '#fc0000', marginLeft: '4px' }}
            onClick={e => showConfirm(e, record.id)}
          />
        </div>
      ),
    },
  ]

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Input
            style={{ borderRadius: '2px', padding: '5px 15px' }}
            value={searchTerm}
            allowClear
            name="description"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder={'Tìm kiếm theo loại văn phòng phẩm'}
          />
        </Col>
      </Row>
      <TableComponent
        rowKey={record => record.id}
        dataSource={managementAssetTypes}
        columns={tableColumns}
        onRow={record => ({
          onClick: () => handleOpenUpdateModal(record.id),
        })}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={isLoading}
      />
      <TableBottomPaginationBlock>
        <Pagination
          current={pageIndex + 1}
          pageSize={sizeIndex}
          total={totalAssetTypes}
          showSizeChanger={false}
          onChange={handleChangePageAssetTypeLeave}
        />
      </TableBottomPaginationBlock>

      <Modal
        width={500}
        title={'Chỉnh sửa loại văn phòng phẩm'}
        visible={isVisibleUpdateModal}
        maskClosable={false}
        onCancel={handleCancelUpdateModal}
        footer={null}
        destroyOnClose>
        {isVisibleUpdateModal && (
          <ManagementAssetWarehouseUnitUpdate
            onCancel={handleCancelUpdateModal}
            templateId={selectedTemplateId}
          />
        )}
      </Modal>
    </>
  )
}

export default inject(
  'managementAssetWarehouseStore',
  'authenticationStore',
  'selectPeopleStore'
)(observer(ManagementAssetWarehouseUnitTable))
