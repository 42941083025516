import React, { useEffect } from 'react'
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Space,
} from 'antd'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import { BankOutlined, CloseOutlined, TeamOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { useState } from 'react'
import { SelectPeopleWrapper } from '../WorkPage/PopupVote/PopupVoteStyled'
import SelectUserButton from '../../components/Common/SelectUserButton'
import { ASSIGNEE_TYPE } from '../../constants'
import utils from '../../utils'
import { AvatarHasName } from '../../components/Common/Avatar'
import SelectListComponentRisk from '../../components/Common/SelectListComponentRisk'
import { systemName } from '../../config'

const RiskWorkCreatePopup = ({
  handleCancel,
  isVisible,
  actionStore,
  riskStore,
  getRiskDetail,
  selectUserList,
  selectPeopleStore,
  handleVisibleSelectOneUserPopup,
  type,
}) => {
  const {
    indexDetailAction,
    dataActions,
    setDataActions,
    createAction,
    updateAction,
  } = actionStore
  const {
    handleOpenSelectUserDepartmentPopup,
    setSelectUserData,
    selectUserData,
    clearSelectedByType,
    originDepartmentWithUserList,
  } = selectPeopleStore

  const { riskDetail } = riskStore
  const [form] = Form.useForm()
  const [isRenderRichText, setIsRenderRichText] = useState(false)
  const [tier3, setTier3] = useState()
  useEffect(() => {
    if (dataActions?.tier3?.length > 0) {
      setTier3(dataActions?.tier3[0])
    }
  }, [dataActions])
  useEffect(() => {
    if (!tier3 || indexDetailAction?.tier !== '3') {
      return
    }
    setSelectUserData({
      ...selectUserData,
      [ASSIGNEE_TYPE.ban_kt_ktnb]: tier3?.doi_tuong_thuc_hien?.ban_kt_ktnb,
      [ASSIGNEE_TYPE.tgd_ptgd]: tier3?.doi_tuong_thuc_hien?.tgd_ptgd,
      [ASSIGNEE_TYPE.ban_kt_ktnb_vimc]:
        tier3?.IV_ket_qua_ra_soat_rui_ro?.ban_kt_ktnb_vimc,
      [ASSIGNEE_TYPE.hdqt_vimc]: tier3?.IV_ket_qua_ra_soat_rui_ro?.hdqt_vimc,
      [ASSIGNEE_TYPE.ban_dieu_hanh]:
        tier3?.IV_ket_qua_ra_soat_rui_ro?.ban_dieu_hanh,
    })
    form.setFieldsValue({
      tgd_ptgd: tier3?.doi_tuong_thuc_hien?.tgd_ptgd,
      // ban_kt_ktnb: tier3.doi_tuong_thuc_hien.ban_kt_ktnb,
      dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai:
        tier3?.IV_ket_qua_ra_soat_rui_ro
          ?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai,
      dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_ban_dh:
        tier3?.IV_ket_qua_ra_soat_rui_ro
          ?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_ban_dh,
      dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_hdqt:
        tier3?.IV_ket_qua_ra_soat_rui_ro
          ?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_hdqt,
      kien_nghi_giai_phap_khac_phuc:
        tier3?.IV_ket_qua_ra_soat_rui_ro?.kien_nghi_giai_phap_khac_phuc,
      kien_nghi_giai_phap_khac_phuc_ban_dh:
        tier3?.IV_ket_qua_ra_soat_rui_ro?.kien_nghi_giai_phap_khac_phuc_ban_dh,
      kien_nghi_giai_phap_khac_phuc_hdqt:
        tier3?.IV_ket_qua_ra_soat_rui_ro?.kien_nghi_giai_phap_khac_phuc_hdqt,
      ban_kt_ktnb_vimc: tier3?.IV_ket_qua_ra_soat_rui_ro?.ban_kt_ktnb_vimc,
      ban_dieu_hanh: tier3?.IV_ket_qua_ra_soat_rui_ro?.ban_dieu_hanh,
      hdqt_vimc: tier3?.IV_ket_qua_ra_soat_rui_ro?.hdqt_vimc,
      ban_ktnb_vimc_signed:
        tier3?.IV_ket_qua_ra_soat_rui_ro?.ban_ktnb_vimc_signed,
      ban_dieu_hanh_signed:
        tier3?.IV_ket_qua_ra_soat_rui_ro?.ban_dieu_hanh_signed,
      hdqt_vimc_signed: tier3?.IV_ket_qua_ra_soat_rui_ro?.hdqt_vimc_signed,
    })
  }, [indexDetailAction])
  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsRenderRichText(true)
      }, 100)
    } else {
      setIsRenderRichText(false)
    }
  }, [isVisible])

  const handleCancelModal = () => {
    handleCancel()
    // setTimeout(() => {
    //   setIndexDetailAction({ tiers: null, index: null })
    // }, 500)
    if (
      selectUserData.ban_kt_ktnb_vimc.length > 0 ||
      selectUserData.ban_kt_ktnb.length > 0 ||
      selectUserData.tgd_ptgd.length > 0 ||
      selectUserData.ban_dieu_hanh.length > 0 ||
      selectUserData.hdqt_vimc.length > 0
    ) {
      clearSelectedByType(ASSIGNEE_TYPE.ban_kt_ktnb_vimc)
      clearSelectedByType(ASSIGNEE_TYPE.ban_kt_ktnb)
      clearSelectedByType(ASSIGNEE_TYPE.ban_dieu_hanh)
      clearSelectedByType(ASSIGNEE_TYPE.hdqt_vimc)
      clearSelectedByType(ASSIGNEE_TYPE.tgd_ptgd)
    }
  }

  const handleSubmit = async value => {
    const submitData = {
      name: 'Tuyến phòng vệ thứ ba',
      doi_tuong_thuc_hien: {
        tong_giam_doc_cac_pho_tong_giam_doc:
          value.tong_giam_doc_cac_pho_tong_giam_doc,
        ban_kt_ktnb: selectUserList.ban_kt_ktnb,
        tgd_ptgd: selectUserList.tgd_ptgd,
      },
      IV_ket_qua_ra_soat_rui_ro: {
        dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai:
          value.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai,
        dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_ban_dh:
          value.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_ban_dh,
        dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_hdqt:
          value.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_hdqt,
        kien_nghi_giai_phap_khac_phuc: value.kien_nghi_giai_phap_khac_phuc,
        kien_nghi_giai_phap_khac_phuc_ban_dh:
          value.kien_nghi_giai_phap_khac_phuc_ban_dh,
        kien_nghi_giai_phap_khac_phuc_hdqt:
          value.kien_nghi_giai_phap_khac_phuc_hdqt,
        ban_kt_ktnb_vimc: selectUserList.ban_kt_ktnb_vimc,
        hdqt_vimc: selectUserList.hdqt_vimc,
        ban_dieu_hanh: selectUserList.ban_dieu_hanh,
        ban_ktnb_vimc_signed:
          checked_ktnb === true ? value.ban_ktnb_vimc_signed : null,
        ban_dieu_hanh_signed:
          checked_bdh === true ? value.ban_dieu_hanh_signed : null,
        hdqt_vimc_signed: checked_hdqt === true ? value.hdqt_vimc_signed : null,
      },
    }
    if (riskDetail?.tiers) {
      if (riskDetail?.tiers[2]?.actions[0]) {
        await updateAction(riskDetail?.tiers[2]?.actions[0]?.code, {
          name: 'Rủi ro nhận diện 3 update',
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[2]?.code,
          risk_type: type,
        })
      } else {
        await createAction({
          ...submitData,
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[2]?.code,
        })
      }
      message.success('Lưu thành công')
      handleCancelModal()
      getRiskDetail()
    } else {
      message.success('Lưu thành công')
      setDataActions({ ...dataActions, tier3: [submitData] })
      handleCancelModal()
    }
  }
  const [radioValue, setRadioValue] = useState()
  const [radioValueDH, setRadioValueDH] = useState()
  const [radioValueQT, setRadioValueQT] = useState()
  useEffect(() => {
    setRadioValue(
      tier3?.IV_ket_qua_ra_soat_rui_ro
        ?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai
    )
    setRadioValueDH(
      tier3?.IV_ket_qua_ra_soat_rui_ro
        ?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_ban_dh
    )
    setRadioValueQT(
      tier3?.IV_ket_qua_ra_soat_rui_ro
        ?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_hdqt
    )
  }, [dataActions])
  const onChange = e => {
    setRadioValue(e.target.value)
  }
  const onChangeDH = e => {
    setRadioValueDH(e.target.value)
  }
  const onChangeQT = e => {
    setRadioValueQT(e.target.value)
  }
  const [checked_ktnb, setChecked_ktnb] = useState(false)
  const [checked_bdh, setChecked_bdh] = useState(false)
  const [checked_hdqt, setChecked_hdqt] = useState(false)
  const [disabled_ktnb, setDisabled_ktnb] = useState(true)
  const [disabled_bdh, setDisabled_bdh] = useState(true)
  const [disabled_hdqt, setDisabled_hdqt] = useState(true)

  const onChangeCheckedKtnb = e => {
    setChecked_ktnb(e.target.checked)
  }
  const onChangeCheckedBdh = e => {
    setChecked_bdh(e.target.checked)
  }
  const onChangeCheckedHdqt = e => {
    setChecked_hdqt(e.target.checked)
  }

  useEffect(() => {
    if (
      dataActions?.tier3[0]?.IV_ket_qua_ra_soat_rui_ro?.ban_ktnb_vimc_signed ===
      '(Đã ký)'
    ) {
      setChecked_ktnb(true)
    }
    if (
      dataActions?.tier3[0]?.IV_ket_qua_ra_soat_rui_ro?.ban_dieu_hanh_signed ===
      '(Đã ký)'
    ) {
      setChecked_bdh(true)
    }
    if (
      dataActions?.tier3[0]?.IV_ket_qua_ra_soat_rui_ro?.hdqt_vimc_signed ===
      '(Đã ký)'
    ) {
      setChecked_hdqt(true)
    }
  }, [dataActions])
  useEffect(() => {
    if (selectUserList.ban_kt_ktnb_vimc.length > 0) {
      setDisabled_ktnb(false)
    } else {
      setChecked_ktnb(false)
      setDisabled_ktnb(true)
    }
  }, [selectUserList.ban_kt_ktnb_vimc.length])
  useEffect(() => {
    if (selectUserList.ban_dieu_hanh.length > 0) {
      setDisabled_bdh(false)
    } else {
      setChecked_bdh(false)
      setDisabled_bdh(true)
    }
  }, [selectUserList.ban_dieu_hanh.length])
  useEffect(() => {
    if (selectUserList.hdqt_vimc.length > 0) {
      setDisabled_hdqt(false)
    } else {
      setChecked_hdqt(false)
      setDisabled_hdqt(true)
    }
  }, [selectUserList.hdqt_vimc.length])
  return (
    <Modal
      width={800}
      style={{ top: 40 }}
      title={'Tạo mới công việc thực hiện'}
      visible={isVisible}
      maskClosable={false}
      onCancel={handleCancelModal}
      destroyOnClose={true}
      afterClose={() => {
        setIsRenderRichText(false)
      }}
      zIndex={500}
      footer={null}>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={handleSubmit}
        preserve={false}>
        <h3>C. Tuyến phòng vệ thứ ba</h3>
        <h3>
          Đối tượng thực hiện:{' '}
          <span style={{ fontWeight: 400 }}>Đồng thời cả 2</span>
        </h3>
        <SelectPeopleWrapper>
          <SelectUserButton
            style={{ marginTop: 16 }}
            title="- Tổng giám đốc, các Phó Tổng giám đốc:"
            tooltipTitle="Chọn người"
            handleOnClick={() => {
              handleVisibleSelectOneUserPopup()
              handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.tgd_ptgd)
            }}
          />
          <SelectListComponentRisk
            userList={selectUserList.tgd_ptgd}
            originDepartmentWithUserList={originDepartmentWithUserList}
          />
        </SelectPeopleWrapper>
        {/* <Form.Item
          name={'ban_kt_ktnb'}
          label={'(2)	Ban KTNB (tham mưu đánh giá độc lập cho HĐQT) :'}>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item> */}
        <SelectPeopleWrapper>
          <SelectUserButton
            style={{ marginTop: 16 }}
            title="- Ban KTNB (tham mưu đánh giá độc lập cho HĐQT):"
            tooltipTitle="Chọn người"
            handleOnClick={() => {
              handleVisibleSelectOneUserPopup()
              handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.ban_kt_ktnb)
            }}
          />
          <SelectListComponentRisk userList={selectUserList.ban_kt_ktnb} />
        </SelectPeopleWrapper>
        <h3>IV. KẾT QUẢ RÀ SOÁT RỦI RO</h3>
        <span>
          1. Đồng ý/không đồng ý với đánh giá rủi ro/xử lý rủi ro ở tầng phòng
          vệ thứ nhất và thứ hai:
        </span>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            name={
              'dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai'
            }>
            <div>
              {/* <SelectPeopleWrapper> */}
              <SelectUserButton
                style={{ marginTop: 16, minWidth: '300px !important' }}
                title={`BAN KTNB ${systemName}:`}
                tooltipTitle="Chọn người"
                handleOnClick={() => {
                  handleVisibleSelectOneUserPopup()
                  handleOpenSelectUserDepartmentPopup(
                    ASSIGNEE_TYPE.ban_kt_ktnb_vimc
                  )
                }}
              />
              {/* <SelectListComponent userList={selectUserList.ban_kt_ktnb_vimc} /> */}
              {/* </SelectPeopleWrapper> */}
              {selectUserList.ban_kt_ktnb_vimc?.map(item => (
                <div style={{ display: 'inline-block' }}>
                  <Radio.Button
                    style={{
                      borderRadius: '35px',
                      display: 'flex',
                      padding: '3px 8px 3px 4px',
                      marginRight: '0',
                      alignItems: 'center',
                      background: '#e6f7ff',
                      color: '#096de0',
                    }}
                    onClick={() => selectPeopleStore.handleRemoveSelect(item)}
                    value={``}>
                    <AvatarHasName
                      imgId={item.image_id}
                      size={22}
                      name={item.full_name}
                      icon={
                        (item.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                          <BankOutlined />
                        )) ||
                        (item.assignee_type === ASSIGNEE_TYPE.GROUP && (
                          <TeamOutlined />
                        ))
                      }
                    />
                    {/* {item.full_name} */}
                  </Radio.Button>

                  <Radio.Group
                    style={{ minWidth: '145px' }}
                    onChange={onChange}
                    value={radioValue}>
                    <Space direction="vertical">
                      <span style={{ marginRight: 16 }}>
                        <Radio
                          value={`${utils.getNameInCapitalize(
                            item.full_name
                          )} - Đồng ý`}>
                          Đồng ý
                        </Radio>
                      </span>
                      <Radio
                        value={`${utils.getNameInCapitalize(
                          item.full_name
                        )} - Không đồng ý`}>
                        Không đồng ý
                      </Radio>
                      {/* <Radio
                      //onClick={() => selectPeopleStore.handleRemoveSelect(item)}
                      value={``}>
                      Không có ý kiến
                    </Radio> */}
                    </Space>
                  </Radio.Group>
                </div>
              ))}
            </div>
          </Form.Item>
          <Form.Item
            name={
              'dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_ban_dh'
            }>
            <div>
              {/* <SelectPeopleWrapper> */}
              <SelectUserButton
                style={{ marginTop: 16, minWidth: '200px !important' }}
                title="BAN ĐIỀU HÀNH:"
                tooltipTitle="Chọn người"
                handleOnClick={() => {
                  handleVisibleSelectOneUserPopup()
                  handleOpenSelectUserDepartmentPopup(
                    ASSIGNEE_TYPE.ban_dieu_hanh
                  )
                }}
              />
              {/* <SelectListComponent userList={selectUserList.ban_dieu_hanh} /> */}

              {/* </SelectPeopleWrapper> */}
              {selectUserList.ban_dieu_hanh?.map(item => (
                <>
                  <Radio.Button
                    style={{
                      borderRadius: '35px',
                      display: 'flex',
                      padding: '3px 8px 3px 4px',
                      marginRight: '0',
                      alignItems: 'center',
                      background: '#e6f7ff',
                      color: '#096de0',
                    }}
                    onClick={() => selectPeopleStore.handleRemoveSelect(item)}
                    value={``}>
                    <AvatarHasName
                      imgId={item.image_id}
                      size={22}
                      name={item.full_name}
                      icon={
                        (item.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                          <BankOutlined />
                        )) ||
                        (item.assignee_type === ASSIGNEE_TYPE.GROUP && (
                          <TeamOutlined />
                        ))
                      }
                    />
                  </Radio.Button>
                  <Radio.Group
                    style={{ minWidth: '130px' }}
                    onChange={onChangeDH}
                    value={radioValueDH}>
                    <Space direction="vertical">
                      <span style={{ marginRight: 16 }}>
                        <Radio
                          value={`${utils.getNameInCapitalize(
                            item.full_name
                          )} - Đồng ý`}>
                          Đồng ý
                        </Radio>
                      </span>
                      <Radio
                        value={`${utils.getNameInCapitalize(
                          item.full_name
                        )} - Không đồng ý`}>
                        Không đồng ý
                      </Radio>
                      {/* <Radio
                      //onClick={() => selectPeopleStore.handleRemoveSelect(item)}
                      value={``}>
                      Không có ý kiến
                    </Radio> */}
                    </Space>
                  </Radio.Group>
                </>
              ))}
            </div>
          </Form.Item>
          <Form.Item
            name={
              'dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_hdqt'
            }>
            <div>
              {/* <SelectPeopleWrapper> */}
              <SelectUserButton
                style={{ marginTop: 16, minWidth: '200px !important' }}
                title={`HĐQT ${systemName} (CT/TV HĐQT):`}
                tooltipTitle="Chọn người"
                handleOnClick={() => {
                  handleVisibleSelectOneUserPopup()
                  handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.hdqt_vimc)
                }}
              />
              {/* <SelectListComponent userList={selectUserList.hdqt_vimc} /> */}
              {/* </SelectPeopleWrapper> */}
              {selectUserList.hdqt_vimc?.map(item => (
                <>
                  <Radio.Button
                    style={{
                      borderRadius: '35px',
                      display: 'flex',
                      padding: '3px 8px 3px 4px',
                      marginRight: '0',
                      alignItems: 'center',
                      background: '#e6f7ff',
                      color: '#096de0',
                    }}
                    onClick={() => selectPeopleStore.handleRemoveSelect(item)}
                    value={``}>
                    <AvatarHasName
                      imgId={item.image_id}
                      size={22}
                      name={item.full_name}
                      icon={
                        (item.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                          <BankOutlined />
                        )) ||
                        (item.assignee_type === ASSIGNEE_TYPE.GROUP && (
                          <TeamOutlined />
                        ))
                      }
                    />
                  </Radio.Button>
                  <Radio.Group
                    style={{ minWidth: '180px' }}
                    onChange={onChangeQT}
                    value={radioValueQT}>
                    <Space direction="vertical">
                      <span style={{ marginRight: 16 }}>
                        <Radio
                          value={`${utils.getNameInCapitalize(
                            item.full_name
                          )} - Đồng ý`}>
                          Đồng ý
                        </Radio>
                      </span>
                      <Radio
                        value={`${utils.getNameInCapitalize(
                          item.full_name
                        )} - Không đồng ý`}>
                        Không đồng ý
                      </Radio>
                    </Space>
                  </Radio.Group>
                </>
              ))}
            </div>
          </Form.Item>
        </div>

        <span>2. Kiến nghị giải pháp khắc phục:</span>
        <Form.Item
          name={'kien_nghi_giai_phap_khac_phuc'}
          label={`2.1	Kiến nghị giải pháp khắc phục BAN KTNB ${systemName}:`}>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item>
        <Form.Item
          name={'kien_nghi_giai_phap_khac_phuc_ban_dh'}
          label={'2.2	Kiến nghị giải pháp khắc phục BAN ĐIỀU HÀNH:'}>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item>
        <Form.Item
          name={'kien_nghi_giai_phap_khac_phuc_hdqt'}
          label={`2.3	Kiến nghị giải pháp khắc phục HĐQT ${systemName} (CT/TV HĐQT):`}>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item>

        <Divider />
        <SelectPeopleWrapper>
          <span>BAN KTNB {systemName}:</span>
          <Form.Item name={'ban_ktnb_vimc_signed'}>
            <Space direction="vertical">
              <Checkbox
                checked={checked_ktnb}
                disabled={disabled_ktnb}
                onChange={e => onChangeCheckedKtnb(e)}
                value={'(Đã ký)'}>
                Xác nhận ký
              </Checkbox>
            </Space>
          </Form.Item>
          <SelectListComponentRisk userList={selectUserList.ban_kt_ktnb_vimc} />
        </SelectPeopleWrapper>
        <SelectPeopleWrapper>
          <span>BAN ĐIỀU HÀNH:</span>
          <Form.Item name={'ban_dieu_hanh_signed'}>
            <Space direction="vertical">
              <Checkbox
                checked={checked_bdh}
                disabled={disabled_bdh}
                onChange={e => onChangeCheckedBdh(e)}
                value={'(Đã ký)'}>
                Xác nhận ký
              </Checkbox>
            </Space>
          </Form.Item>
          <SelectListComponentRisk userList={selectUserList.ban_dieu_hanh} />
        </SelectPeopleWrapper>
        <SelectPeopleWrapper>
          <span>HĐQT {systemName} (CT/TV HĐQT):</span>
          <Form.Item name={'hdqt_vimc_signed'}>
            <Space direction="vertical">
              <Checkbox
                checked={checked_hdqt}
                disabled={disabled_hdqt}
                onChange={e => onChangeCheckedHdqt(e)}
                value={'(Đã ký)'}>
                Xác nhận ký
              </Checkbox>
            </Space>
          </Form.Item>
          <SelectListComponentRisk userList={selectUserList.hdqt_vimc} />
        </SelectPeopleWrapper>

        <FormActionFooter style={{ marginBottom: 0, marginRight: 0 }}>
          <Button icon={<CloseOutlined />} danger onClick={handleCancelModal}>
            Đóng
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            type={'primary'}
            htmlType={'submit'}>
            Lưu thông tin
          </Button>
        </FormActionFooter>
      </Form>
    </Modal>
  )
}

export default inject(
  'riskStore',
  'actionStore',
  'selectPeopleStore'
)(observer(RiskWorkCreatePopup))
