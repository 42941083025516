import { BankOutlined, CloseOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Empty, Popconfirm, Select, Tag, Tooltip, message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { yellowPrimary } from '../../color'
import { ASSIGNEE_TYPE } from '../../constants'
import { AvatarHasName } from '../Common/Avatar'
import EmptyContent from '../EmptyContent'
import SpinnerInlineComponent from '../SpinnerInlineComponent/SpinnerInlineComponent'
import { ModalBody, ModalWrapper } from './SelectPeoplePopupLiveStyled'
import SelectUserDepartmentBoxLive from './SelectUserDepartmentBox'
import { handleFilterSelect } from '../../utils/filterSelect'

const { Option } = Select

const defaultKeys = Array.from(Array(100).keys())

const SelectPeoplePopupLive = props => {
  const {
    id,
    isVisibleSelectPeoplePopup,
    handleSubmitSelectUser,
    handleCancelSelectUser,
    selectPeopleStore,
    onlyOneUser,
    onlySelectOne,
    disableSelectDepartment,
    noLoadSelectData,
    title,
    okText,
    cancelText,
    isOpenCollapse,
    allowChangeCompany,
    handleUpdateEmpty = () => {},
    isUserDisableList = true,
    setCheckSelectOne,
    checkSelectOne,
    companyList,
    assigneePopupType,
    companyCode,
    getDepartmentWithUsersLive,
    selectedListUpdate = [],
    companyUsersList = [],
  } = props

  const { selectPopupName } = selectPeopleStore

  const [selectedList, setSelectedList] = useState([])
  const [userDisableList, setUserDisableList] = useState([])
  const [departmentDisableList, setDepartmentDisableList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [prevUserList, setPrevUserList] = useState([])
  const [prevDepartmentList, setPrevDepartmentList] = useState([])
  const [defaultActiveKey, setDefaultActiveKey] = useState(defaultKeys)
  const [companyCodeCurrent, setCompanyCodeCurrent] = useState(null)
  const [departmentWithUserList, setDepartmentWithUserList] = useState([])
  const [
    originDepartmentWithUserList,
    setOriginDepartmentWithUserList,
  ] = useState([])
  const [selectUserData, setSelectUserData] = useState([])
  const [selectDepartmentData, setSelectDepartmentData] = useState([])

  const showDropdownCompany = allowChangeCompany ?? true

  useEffect(() => {
    setCompanyCodeCurrent(companyCode)
  }, [])

  useEffect(() => {
    setPrevUserList(selectUserData)
    setPrevDepartmentList(selectDepartmentData)
  }, [isVisibleSelectPeoplePopup])

  useEffect(() => {
    if (selectedListUpdate?.length > 0) {
      const hasNullFullName = selectedListUpdate.some(
        item => item.full_name === null || item.full_name === undefined
      )

      if (hasNullFullName && companyUsersList?.length > 0) {
        // tìm full_name của user
        const resultListUser = companyUsersList.flatMap(unit =>
          unit.users
            .filter(userUnitsV2 =>
              selectedListUpdate?.some(
                itemUserList => itemUserList.username === userUnitsV2.user_name
              )
            )
            .map(userUnitsV2 => {
              const matchingUserList = selectedListUpdate.find(
                itemUserList => itemUserList.username === userUnitsV2.user_name
              )
              return {
                code: matchingUserList.code,
                department_code: matchingUserList.department_code,
                full_name: userUnitsV2.full_name || userUnitsV2.name_uppercase,
                risk_tier_approval_code:
                  matchingUserList.risk_tier_approval_code,
                status: matchingUserList.status,
                username: matchingUserList.username,
              }
            })
        )

        setSelectedList(resultListUser)
        setSelectUserData(resultListUser)
      } else {
        setSelectedList(selectedListUpdate)
        setSelectUserData(selectedListUpdate)
      }
    }
  }, [selectedListUpdate])

  useEffect(() => {
    if (companyList)
      setCompanyCodeCurrent(
        companyList?.find(company => company.code == companyCode)
          ? companyCode
          : companyList[0]?.code
      )
  }, [companyList, companyCode])

  useEffect(() => {
    if (noLoadSelectData || !companyCodeCurrent) return
    setIsLoading(true)
    getDepartmentWithUsersLive(companyCodeCurrent)
      .then(res => {
        setDepartmentWithUserList(res.data)
        setOriginDepartmentWithUserList(res.data)
      })
      .catch(err => {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      })
      .finally(setIsLoading(false))
  }, [companyCodeCurrent])

  useEffect(() => {
    setSelectedList([...selectUserData, ...selectDepartmentData])
  }, [selectUserData, selectDepartmentData])

  const handleRemoveSelect = useCallback(
    item => {
      setSelectUserData(
        selectUserData.filter(el => {
          if (item.id) {
            return el.id !== item.id
          } else if (item.value) {
            return el.value !== item.value
          } else if (item.username) {
            return el.username !== item.username
          }
          return true
        })
      )
      setSelectDepartmentData(
        selectDepartmentData.filter(el => {
          if (item.id) {
            return el.id !== item.id
          } else if (item.value) {
            return el.value !== item.value
          } else if (item.username) {
            return el.username !== item.username
          }
          return true
        })
      )
    },
    [selectUserData, selectDepartmentData]
  )

  const onCompanyChange = value => {
    setCompanyCodeCurrent(value)
  }

  const handleSubmit = () => {
    handleSubmitSelectUser(selectedList)
    setDefaultActiveKey([])
    if (checkSelectOne) {
      setCheckSelectOne(false)
    }
  }

  // useEffect(() => {
  //   if (selectedList?.length > 0) {
  //     let updatedSelected = [...selectedListAndType]
  //     const filterUniqueItems = array => {
  //       let typeMap = {}
  //       // lấy item trùng cuối cùng
  //       for (let i = array.length - 1; i >= 0; i--) {
  //         const item = array[i]
  //         if (!typeMap[item.type]) {
  //           typeMap[item.type] = true
  //         } else {
  //           array.splice(i, 1)
  //         }
  //       }

  //       return array
  //     }

  //     updatedSelected = filterUniqueItems(updatedSelected)

  //     setSelectedListAndType(updatedSelected)
  //   }
  // }, [selectedList])

  return (
    <ModalWrapper
      maskClosable={false}
      okText={okText || 'Đồng ý'}
      cancelText={'Hủy bỏ'}
      width={800}
      style={{ top: 20 }}
      zIndex={1000}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          {showDropdownCompany ? (
            <Select
              style={{ width: 521 }}
              notFoundContent={<EmptyContent />}
              onChange={onCompanyChange}
              value={companyCodeCurrent}
              placeholder={'Chọn công ty'}
              allowClear
              showSearch
              filterOption={handleFilterSelect}
              >
              {companyList?.map(item => (
                <Option key={item.code} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          ) : (
            <span>{title || selectPopupName}</span>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 8,
              alignItems: 'center',
            }}>
            {disableSelectDepartment || cancelText ? (
              <Popconfirm
                placement="top"
                title={'Bạn có chắc chắn muốn xóa hết người được chọn không ?'}
                onConfirm={async () => {
                  // selectPeopleStore.setSelectUserData({
                  //   ...selectUserData,
                  //   [assigneePopupType]: [],
                  // })
                  handleUpdateEmpty()
                  // handleCancelSelectUser()
                }}
                okText="Đồng ý"
                cancelText="Không"
                zIndex={1600}>
                <Button
                  danger
                  icon={<WarningOutlined style={{ color: yellowPrimary }} />}>
                  {cancelText || 'Hủy chọn'}
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                zIndex={2000}
                placement="top"
                title={'Bạn có chắc chắn muốn xóa hết người được chọn không ?'}
                onConfirm={() => {
                  // selectPeopleStore.handleRemoveSelectUserDepartment()
                }}
                okText="Đồng ý"
                cancelText="Không">
                <Button
                  danger
                  icon={<WarningOutlined style={{ color: yellowPrimary }} />}>
                  {cancelText || 'Hủy chọn'}
                </Button>
              </Popconfirm>
            )}
            {/* <Button
              type={'primary'}
              onClick={() => {
                JSON.stringify(selectDepartmentData) !==
                  JSON.stringify(prevDepartmentList) ||
                JSON.stringify(selectUserData) !== JSON.stringify(prevUserList)
                  ? handleSubmit()
                  : handleCancelSelectUser()
              }}>
              {okText || 'Đồng ý'}
            </Button> */}
            <Button
              type={'primary'}
              onClick={() => {
                handleSubmit()
              }}>
              {okText || 'Đồng ý'}
            </Button>
            {!cancelText && (
              <div>
                {/* {disableSelectDepartment ? (
                  <Tooltip title={'Đóng'}>
                    <CloseOutlined onClick={handleCancelSelectUser} />
                  </Tooltip>
                ) : ( */}
                <Tooltip title={'Đóng'}>
                  <CloseOutlined
                    onClick={() => {
                      if (checkSelectOne) {
                        setCheckSelectOne(false)
                      }
                      setSelectUserData(prevUserList)
                      setSelectDepartmentData(prevDepartmentList)
                      handleCancelSelectUser()
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      }
      closable={false}
      forceRender={true}
      footer={null}
      visible={isVisibleSelectPeoplePopup}>
      <ModalBody id={id}>
        <div
          style={{
            display: 'flex',
            gap: 4,
            paddingTop: 10,
          }}>
          <div style={{ flexGrow: 1 }}>
            <div className={'modal-body'} style={{ position: 'relative' }}>
              <SelectUserDepartmentBoxLive
                defaultKeys={defaultKeys}
                defaultActiveKey={defaultActiveKey}
                setDefaultActiveKey={setDefaultActiveKey}
                isOpenCollapse={isOpenCollapse}
                isVisibleSelectPeoplePopup={isVisibleSelectPeoplePopup}
                disableSelectDepartment={disableSelectDepartment}
                onlyOneUser={onlyOneUser}
                onlySelectOne={onlySelectOne}
                userDisableList={userDisableList}
                departmentDisableList={departmentDisableList}
                departmentWithUserList={departmentWithUserList}
                originDepartmentWithUserList={originDepartmentWithUserList}
                setDepartmentWithUserList={setDepartmentWithUserList}
                assigneePopupType={assigneePopupType}
                selectUserData={selectUserData}
                selectDepartmentData={selectDepartmentData}
                setSelectUserData={setSelectUserData}
                setSelectDepartmentData={setSelectDepartmentData}
                handleCancelSelectUser={handleCancelSelectUser}
              />
              <SpinnerInlineComponent
                sizeSpin={'small'}
                isLoading={isLoading}
                noBackground
              />
            </div>
          </div>
          <div
            className={'selected-list'}
            style={{
              flex: '0 0 250px',
              maxHeight: 'calc(100vh - 111px)',
              overflowY: 'auto',
              maxWidth: 250,
              backgroundColor: '#fff',
              margin: '-10px 0 -15px 0',
              borderLeft: '1px solid #f0f0f0',
              padding: '15px 10px',
            }}>
            <div
              style={{
                margin: '-15px -10px 10px -10px',
                padding: 15,
                borderBottom: '1px solid #f0f0f0',
                fontWeight: 500,
                backgroundColor: '#e9f7ff',
              }}>
              Danh sách đã chọn:
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 8,
              }}>
              {selectedList.length > 0 ? (
                selectedList.map(el => {
                  return (
                    <Tag
                      className={'tag-selected'}
                      color={'blue'}
                      key={el.id}
                      closable
                      onClose={() => handleRemoveSelect(el)}>
                      <AvatarHasName
                        imgId={el.image_id}
                        size={22}
                        name={el.full_name || el.label}
                        icon={
                          el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                            <BankOutlined />
                          )
                        }
                      />
                    </Tag>
                  )
                })
              ) : (
                <Empty
                  style={{ width: '100%' }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </div>
          </div>
        </div>
      </ModalBody>
    </ModalWrapper>
  )
}

SelectPeoplePopupLive.propTypes = {}

export default inject('selectPeopleStore')(observer(SelectPeoplePopupLive))
