import React, { useEffect, useState } from 'react'
import { RiskHorizontalDisplay } from '../../components/Common'
import RiskActionDetails from '../../components/RiskActionDetails'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import moment from 'moment'
import utils from '../../utils'

const RiskPdf2 = props => {
  const { dataActions, type, riskStore, departmentStore, selectPeopleStore } = props
  const { departmentList } = departmentStore
  const { riskUnitsV2 } = riskStore
  const riskDetail = toJS(props.riskDetail)
  let doi_tuong_thuc_hien = {}
  if (dataActions?.tier1?.length > 0) {
    doi_tuong_thuc_hien = dataActions?.tier1[0]?.doi_tuong_thuc_hien
  }
  const department_name_nddpv_cua_vimc_tai_dntv =
    Array.isArray(riskUnitsV2) &&
    riskUnitsV2?.find(
      e =>
        e.code === doi_tuong_thuc_hien?.department_code_nddpv_cua_vimc_tai_dntv
    )
  const department_name_DVPY_cua_VIMC =
    Array.isArray(riskUnitsV2) &&
    riskUnitsV2?.find(e => e.code === doi_tuong_thuc_hien?.dvpt_cua_vimc)

  const findDepartmentByCode = unit_code => {
    return departmentList?.find(dept => dept.code === unit_code)
  }

  const [departmentWithUserList, setDepartmentWithUserList] = useState([])
  useEffect(() => {
    selectPeopleStore
      .getDepartmentWithUsersLive('CPN7451091748209')
      .then(res => {
        setDepartmentWithUserList(res.data)
      })
  }, [])

  return (
    <div id={'riskDivToPrint'} style={{ fontSize: '10px !important ' }}>
      <RiskHorizontalDisplay
        labels={[
          {
            image: (
              <img
                id="logo"
                style={{ width: '250px' }}
                src={`${window.location.protocol}//${window.location.host}/assets/photos/logo_vimc.png`}
              />
            ),
            style: { margin: '10px 10px', border: '1px solid black' },
          },
          {
            value: (
              <div style={{ textAlign: 'center' }}>
                <h2>Phiếu kiểm soát rủi ro</h2>
                <div>
                  Số:{riskDetail.risk_index < 10 ? `0${riskDetail.risk_index}` : riskDetail.risk_index?.toString()} Ngày{' '}
                  {moment(riskDetail.created_at).format('DD/MM/YYYY')} Giờ{' '}
                  {moment(riskDetail.created_at).format('HH:mm')}
                </div>
              </div>
            ),
            style: { margin: '10px 54px', border: '1px solid black' },
          },
        ]}
        tableStyle={{ margin: '0 0 20px 0', width: '100%' }}
      />
      <RiskHorizontalDisplay
        results={[
          {
            value: (
              <>
                {' '}
                <h5>
                  {utils.checkRichType(
                    type,

                    <span>
                      {utils.getNameInCapitalize(
                        department_name_nddpv_cua_vimc_tai_dntv?.name
                      )}
                    </span>,
                    <span>
                      {utils.getNameInCapitalize(
                        department_name_DVPY_cua_VIMC?.name
                      )}
                    </span>,
                    <span>
                      {
                        findDepartmentByCode(
                          doi_tuong_thuc_hien?.cac_don_vi_thuoc_cq_vp_cua_vimc
                        )?.name
                      }
                    </span>
                  )}
                </h5>
                <h5>A. Tuyến phòng vệ thứ nhất</h5>
                <RiskActionDetails
                  type={type}
                  data={dataActions?.tier1}
                  riskUnitsV2={riskUnitsV2}
                  tier={'1'}
                  isPartOfPdf
                  departmentWithUserMasterList={departmentWithUserList}
                />
              </>
            ),
            style: {
              textAlign: 'flex',
              border: '1px solid black',
              margin: '0 10px',
            },
          },
        ]}
        tableStyle={{ margin: 0 }}
      />
      <RiskHorizontalDisplay
        results={[
          {
            value: (
              <>
                <h5 style={{ marginTop: 16 }}>B. Tuyến phòng vệ thứ hai</h5>
                <RiskActionDetails
                  type={type}
                  data={dataActions?.tier2}
                  tier={'2'}
                  isPartOfPdf
                  departmentWithUserMasterList={departmentWithUserList}
                />
              </>
            ),
            style: {
              textAlign: 'flex',
              border: '1px solid black',
              margin: '0 10px',
            },
          },
        ]}
        tableStyle={{ marginTop: 1000 }}
      />
      <RiskHorizontalDisplay
        results={[
          {
            value: (
              <>
                <h5 style={{ marginTop: 16 }}>C. Tuyến phòng vệ thứ ba</h5>
                <RiskActionDetails
                  data={dataActions?.tier3}
                  tier={'3'}
                  isPartOfPdf
                />
              </>
            ),
            style: {
              textAlign: 'flex',
              border: '1px solid black',
              margin: '0 10px',
            },
          },
        ]}
        tableStyle={{ marginTop: 1000 }}
      />
      <RiskHorizontalDisplay
        results={[
          {
            value: (
              <>
                <RiskActionDetails
                  data={dataActions?.tier4}
                  tier={'4'}
                  isPartOfPdf
                />
              </>
            ),
            style: {
              textAlign: 'flex',
              border: '1px solid black',
              margin: '0 10px',
            },
          },
        ]}
        tableStyle={{ marginTop: 1000 }}
      />
    </div>
  )
}
RiskPdf2.propTypes = {}

export default inject('riskStore', 'departmentStore', 'selectPeopleStore')(observer(RiskPdf2))
