import {
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
  UserOutlined,
  DeleteOutlined,
  BankOutlined,
  TeamOutlined,
  DownloadOutlined,
  UploadOutlined,
  CopyOutlined,
  CheckOutlined,
  FileTextOutlined,

} from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Select,
  InputNumber,
  Modal,
  Steps,
  Typography,
  Card,
  Upload,
  Popconfirm,
  Divider,
  ConfigProvider,
  Tooltip,
  Tag,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { 
  SelectList,
  UploadFileListWrapper, 
  RenderFileList, 
  UploadWorkListWrapper,
} from './TextShoppingCouponStylePage'
import RiskSteps from '../../components/RiskSteps'
import RichEditor from '../../components/RichEditor/RichEditor'
import { ASSIGNEE_TYPE } from '../../constants'
import { blue } from '../../color'
import SelectPeoplePopup from '../../components/SelectPeoplePopup'
import { AvatarHasName } from '../../components/Common/Avatar'
import MissionCreatePopup from '../MissionCreatePage/MissionCreatePopup'
import ModalIncomingDocumentList from '../MissionCreatePage/ModalIncomingDocumentList'
import PopoverWatchTime from '../../components/PopoverWatchTime'
import ModalWorkLists from './ModalWorkLists'
import moment from 'moment'
import validator from '../../validator'
import TinyMCE from '../../components/TinyMCE'
const { Option } = Select
const { Step } = Steps
const { Title, Text, Link } = Typography

const ShoppingCouponCreate = props => {
  const {
    loadingAnimationStore,
    internalDocumentStore,
    selectPeopleStore,
    approvalTemplateStore,
    proposalStore,
    taskStore,
    authenticationStore,
    ShoppingCouponStore,
    onSuccess,
  } = props
  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleRemoveSelect,
    clearSelected,
    clearSelectUserData,
  } = selectPeopleStore

  const allParams = { page: 1, size: 1000 }

  const [current, setCurrent] = useState(0)
  const [current1, setCurrent1] = useState(0)
  const { currentUser } = authenticationStore
  const [currentStepType, setCurrentStepType] = useState('')
  const [followerList, setFollowerList] = useState([])
  const [approvalList, setApprovalList] = useState([])
  const [apraiserList, setApraiserList] = useState([])
  const [apraiser, setApraiser] = useState([])
  const [firstSubApraiserList, setFirstSubApraiserList] = useState([]);
  const [secondSubApraiserList, setSecondSubApraiserList] = useState([]);
  const [thirdSubApraiserList, setThirdSubApraiserList] = useState([]);
  const [fourthSubApraiserList, setFourthSubApraiserList] = useState([]);
  const [fifthSubApraiserList, setFifthSubApraiserList] = useState([]);
  const [sixthSubApraiserList, setSixthSubApraiserList] = useState([]);
  const [seventhSubApraiserList, setSeventhSubApraiserList] = useState([]);
  const [eighthSubApraiserList, setEighthSubApraiserList] = useState([]);
  const [ninthSubApraiserList, setNinthSubApraiserList] = useState([]);
  const [step3Count, setStep3Count] = useState(1)
  const [buyerList, setBuyerList] = useState([])
  const [payList, setPayList] = useState([])
  const EDITOR_REF = useRef()
  const [isCreating, setIsCreating] = useState(false)
  const [isDisableCreatedStep, setIsDisableCreatedStep] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [
    isVisibleModalShopingCoupon,
    setIsVisibleModalShopingCoupon,
  ] = useState(false)
  const [approvalTemplateList, setApprovalTemplateList] = useState(null)
  const [selectedWorkTitles, setSelectedWorkTitles] = useState([])
  const [stepsData, setStepsData] = useState([
    {
      key: 'created',
      userList: currentUser ? [currentUser] : [],
      type: 'created',
      isInitial: true,
    },
    {
      key: 'approval',
      userList: approvalList,
      type: 'approval',
      isInitial: true,
    },
    {
      key: 'apraiser',
      userList: apraiser,
      type: 'apraiser',
      isInitial: true,
    },
  ])
  const [newSteps, setNewSteps] = useState([])
  const [form] = Form.useForm()
  const [formValues, setFormValues] = useState({})
  const [editorContent, setEditorContent] = useState('')
  const [textareaValue, setTextareaValue] = useState('')
  const [currentStep, setCurrentStep] = useState(1)
  const [selectedTasksStep1, setSelectedTasksStep1] = useState([])
  const [selectedTasksStep2, setSelectedTasksStep2] = useState([])
  const [selectedTasksStep3, setSelectedTasksStep3] = useState([])
  const [selectedTasksStep4, setSelectedTasksStep4] = useState([])
  const [selectedTasksStep5, setSelectedTasksStep5] = useState([])
  const [selectedRowKeysStep1, setSelectedRowKeysStep1] = useState([])
  const [selectedRowKeysStep2, setSelectedRowKeysStep2] = useState([])
  const [selectedRowKeysStep3, setSelectedRowKeysStep3] = useState([])
  const [selectedRowKeysStep4, setSelectedRowKeysStep4] = useState([])
  const [selectedRowKeysStep5, setSelectedRowKeysStep5] = useState([])
  const initialSelectedRowKeysSubStep3 = Array.from({ length: 10 }, () => []);
  const initialSelectedTasksSubStep3 = Array.from({ length: 10 }, () => []);
  const [selectedRowKeysSubStep3, setSelectedRowKeysSubStep3] = useState(initialSelectedRowKeysSubStep3);
  const [selectedTasksSubStep3, setSelectedTasksSubStep3] = useState(initialSelectedTasksSubStep3);


  const handleWorkSelection = titles => {
    if (currentStep === 1) {
      setSelectedTasksStep1(titles)
    } else if (currentStep === 2) {
      setSelectedTasksStep2(titles)
    } else if (currentStep === 3) {
      setSelectedTasksStep3(titles)
    } else if (currentStep === 4) {
      setSelectedTasksStep4(titles)
    } else if (currentStep === 5) {
      setSelectedTasksStep5(titles)
    } else {
      const stepIndex = currentStep - 6
      if (stepIndex >= 0 && stepIndex < selectedTasksSubStep3.length) {
        const updatedTasks = [...selectedTasksSubStep3]
        updatedTasks[stepIndex] = titles
        setSelectedTasksSubStep3(updatedTasks)
      }
    }
    setIsVisibleModalShopingCoupon(false)
  }
  const handleOpenModal = step => {
    setCurrentStep(step)
    setIsVisibleModalShopingCoupon(true)
  }

  const handleCloseModal = () => {
    setIsVisibleModalShopingCoupon(false)
  }

  const handleUpload = (step, file) => {
    const fileName = file.name;
    const isDuplicate = (attachments) => {
      return attachments.some(existingFile => existingFile.name === fileName)
    }
    if (step === 2) {
      if (!isDuplicate(ShoppingCouponStore.requestAttachments)) {
        ShoppingCouponStore.setRequestAttachments([
          ...ShoppingCouponStore.requestAttachments,
          file,
        ]);
      }
    } else if (step === 3) {
      if (!isDuplicate(ShoppingCouponStore.approveAttachments)) {
        ShoppingCouponStore.setApproveAttachments([
          ...ShoppingCouponStore.approveAttachments,
          file,
        ]);
      }
    } else if (step === 4) {
      if (!isDuplicate(ShoppingCouponStore.shoppingAttachments)) {
        ShoppingCouponStore.setShoppingAttachments([
          ...ShoppingCouponStore.shoppingAttachments,
          file,
        ]);
      }
    } else if (step === 5) {
      if (!isDuplicate(ShoppingCouponStore.paymentAttachments)) {
        ShoppingCouponStore.setPaymentAttachments([
          ...ShoppingCouponStore.paymentAttachments,
          file,
        ]);
      } 
    } else {
      const stepIndex = step - 6
      if (!ShoppingCouponStore.subApraiserAttachments[stepIndex]) {
        ShoppingCouponStore.subApraiserAttachments[stepIndex] = []
      }
      
      if (!isDuplicate(ShoppingCouponStore.subApraiserAttachments[stepIndex])) {
        ShoppingCouponStore.subApraiserAttachments[stepIndex] = [
          ...ShoppingCouponStore.subApraiserAttachments[stepIndex],
          file,
        ];
      } 
    }
  }

  const handleRemoveUpload = (step, file) => {
    if (step === 2) {
      ShoppingCouponStore.setRequestAttachments(
        ShoppingCouponStore.requestAttachments.filter(
          item => item.uid !== file.uid
        )
      )
    } else if (step === 3) {
      ShoppingCouponStore.setApproveAttachments(
        ShoppingCouponStore.approveAttachments.filter(
          item => item.uid !== file.uid
        )
      )
    } else if (step === 4) {
      ShoppingCouponStore.setShoppingAttachments(
        ShoppingCouponStore.shoppingAttachments.filter(
          item => item.uid !== file.uid
        )
      )
    } else if (step === 5) {
      ShoppingCouponStore.setPaymentAttachments(
        ShoppingCouponStore.paymentAttachments.filter(
          item => item.uid !== file.uid
        )
      )
    } else {
      if (ShoppingCouponStore.subApraiserAttachments[step]) {
        ShoppingCouponStore.setSubApraiserAttachments[step](
          ShoppingCouponStore.subApraiserAttachments[step].filter(
            item => item.uid !== file.uid
          )
        )
      }
    }
  }

  const handleConfirmSelection = (selectedTitles, selectedRowKeys) => {
    if (currentStep === 1) {
      setSelectedTasksStep1(selectedTitles)
      setSelectedRowKeysStep1(selectedRowKeys)
    } else if (currentStep === 2) {
      setSelectedTasksStep2(selectedTitles)
      setSelectedRowKeysStep2(selectedRowKeys)
    } else if (currentStep === 3) {
      setSelectedTasksStep3(selectedTitles)
      setSelectedRowKeysStep3(selectedRowKeys)
    } else if (currentStep === 4) {
      setSelectedTasksStep4(selectedTitles)
      setSelectedRowKeysStep4(selectedRowKeys)
    } else if (currentStep === 5) {
      setSelectedTasksStep5(selectedTitles)
      setSelectedRowKeysStep5(selectedRowKeys)
    } else {
      const stepIndex = currentStep - 6
      if (stepIndex >= 0 && stepIndex < selectedTasksSubStep3.length) {
        const updatedTasks = [...selectedTasksSubStep3]
        const updatedRowKeys = [...selectedRowKeysSubStep3]
        updatedTasks[stepIndex] = selectedTitles
        setSelectedTasksSubStep3(updatedTasks)
        setSelectedRowKeysSubStep3(updatedRowKeys)
      }
    }
    setIsVisibleModalShopingCoupon(false)
  }

  const handleTextAreaChange = e => {
    setTextareaValue(e.target.value)
    ShoppingCouponStore.setDescription(e.target.value)
  }

  useEffect(() => {
    form.setFieldsValue({ 'content-editor': editorContent })
  }, [editorContent, form])

  useEffect(() => {
    const updatedStepsData = [
      {
        key: 'created',
        userList: currentUser ? [currentUser] : [],
        type: 'created',
        isInitial: true,
      },
      {
        key: 'approval',
        userList: approvalList,
        type: 'approval',
        isInitial: true,
      },
      {
        key: 'apraiser',
        userList: apraiser.length > 0 ? apraiser : [],
        type: 'apraiser',
        isInitial: true,
      },
    ];
  
    if (newSteps.length > 0) {
      updatedStepsData.push(...newSteps);
    }
  
    setStepsData(updatedStepsData);
  }, [approvalList, apraiserList, currentUser, newSteps]);

  const onEditorChange = data => {
    form.setFieldsValue({ 'content-editor': data })
    setEditorContent(data)
  }

  const next = async () => {
    try {
      const values = await form.validateFields()
      setFormValues({ ...formValues, ...values })
      setCurrent(current + 1)
    } catch (errorInfo) {
      console.log('Failed:', errorInfo)
    }
  }

  const prev = () => {
    setFormValues({ ...formValues, ...form.getFieldsValue() })
    setCurrent(current - 1)
  }

  const goToStep = async step => {
    try {
      const values = await form.validateFields()
      setFormValues({ ...formValues, ...values })
      setCurrent(step)
    } catch (errorInfo) {
      console.log('Failed:', errorInfo)
    }
  }

  useEffect(() => {
    if (current === 0) {
      form.setFieldsValue(formValues)
    }
    form.setFieldsValue({ 'content-editor': editorContent })
  }, [current, formValues, form])

  const handleCancelCreateMissionPopup = async refesh => {
    setIsVisibleCreateMissionPopup(false)
    if (refesh === true) {
      await getTaskList()
    }
  }

  const getTaskList = async () => {
    try {
      setLoadingList(true)
      await taskStore.getTaskList()
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setLoadingList(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      await clearSelectUserData()
      await setFollowerList([])
      await clearSelected()
      await loadApprovalTemplateList()
      setApprovalTemplateList(approvalTemplateStore.approvalTemplateList)
    })()
  }, [])
  const loadApprovalTemplateList = async () => {
    try {
      await approvalTemplateStore.getApprovalTemplateListSearch(
        'template_type=asset'
      )
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
    }
  }

  useEffect(() => {
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER_PROCUREMENT],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER_PROCUREMENT],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER_PROCUREMENT],
    ])
    setPayList([
      ...selectUserData[ASSIGNEE_TYPE.PAY],
      ...selectDepartmentData[ASSIGNEE_TYPE.PAY],
      ...selectGroupData[ASSIGNEE_TYPE.PAY],
    ])
    setApprovalList([
      ...selectUserData[ASSIGNEE_TYPE.APPROVAL],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPROVAL],
      ...selectGroupData[ASSIGNEE_TYPE.APPROVAL],
    ])
    setBuyerList([
      ...selectUserData[ASSIGNEE_TYPE.BUYER],
      ...selectDepartmentData[ASSIGNEE_TYPE.BUYER],
      ...selectGroupData[ASSIGNEE_TYPE.BUYER],
    ])
    setApraiser([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER],
    ])
    setFirstSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_1],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_1],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_1],
    ])
    setSecondSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_2],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_2],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_2],
    ])
    setThirdSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_3],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_3],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_3],
    ])
    setFourthSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_4],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_4],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_4],
    ])
    setFifthSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_5],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_5],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_5],
    ])
    setSixthSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_6],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_6],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_6],
    ])
    setSeventhSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_7],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_7],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_7],
    ])
    setEighthSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_8],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_8],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_8],
    ])
    setNinthSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_9],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_9],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_9],
    ]) 

}, [selectUserData, selectDepartmentData, selectGroupData]);

useEffect(() => {
  if (apraiser.length > 0) {
    setApraiserList((prev) => {
    const newList = [...prev];
    newList[0] = apraiser;
    return newList;
  })}
  if (firstSubApraiserList.length > 0) {
    setApraiserList((prev) => {
    const newList = [...prev];
    newList[1] = firstSubApraiserList;
    return newList;
  })}
  if (secondSubApraiserList.length > 0) {
    setApraiserList((prev) => {
    const newList = [...prev];
    newList[2] = secondSubApraiserList;
    return newList;
  })}
  if (thirdSubApraiserList.length > 0) {
    setApraiserList((prev) => {
    const newList = [...prev];
    newList[3] = thirdSubApraiserList;
    return newList;
  })}
  if (fourthSubApraiserList.length > 0) {
    setApraiserList((prev) => {
    const newList = [...prev];
    newList[4] = fourthSubApraiserList;
    return newList;
  })}
  if (fifthSubApraiserList.length > 0) {
    setApraiserList((prev) => {
    const newList = [...prev];
    newList[5] = fifthSubApraiserList;
    return newList;
  })}
  if (sixthSubApraiserList.length > 0) {
    setApraiserList((prev) => {
    const newList = [...prev];
    newList[6] = sixthSubApraiserList;
    return newList;
  })}
  if (seventhSubApraiserList.length > 0) {
    setApraiserList((prev) => {
    const newList = [...prev];
    newList[7] = seventhSubApraiserList;
    return newList;
  })}
  if (eighthSubApraiserList.length > 0) {
    setApraiserList((prev) => {
    const newList = [...prev];
    newList[8] = eighthSubApraiserList;
    return newList;
  })}
  if (ninthSubApraiserList.length > 0) {
    setApraiserList((prev) => {
    const newList = [...prev];
    newList[9] = ninthSubApraiserList;
    return newList;
  })}
}, [
  apraiser,
  firstSubApraiserList,
  secondSubApraiserList,
  thirdSubApraiserList,
  fourthSubApraiserList,
  fifthSubApraiserList,
  sixthSubApraiserList,
  seventhSubApraiserList,
  eighthSubApraiserList,
  ninthSubApraiserList
])


  const getApprovalLevelTemplate = async code => {
    try {
      const response = await approvalTemplateStore.getApprovalLevelTemplateByATCode(
        code
      )
      const approvalLevelsChange = response.data.map(
        (approvalLevel, index) => ({
          status: index === 0 ? 'CREATED' : 'WAITING',
          allow_edit: approvalLevel.allow_edit,
          code: approvalLevel.code,
          isDeleted: approvalLevel.isDeleted,
          level: approvalLevel.level,
          name: approvalLevel.name,
          username: approvalLevel.username,
        })
      )

      setApprovalLevels(approvalLevelsChange)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    }
  }

  const handleOpenModalWorkList = async stepType => {
    try {
      setCurrentStepType(stepType)
      loadingAnimationStore.setTableLoading(true)
      setIsVisibleModalShopingCoupon(true)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  useEffect(() => {
    if (apraiserList.length > 1 ) {
      if (apraiserList[step3Count]) {
        if (apraiserList && apraiserList[step3Count]) {
          setIsDisableCreatedStep(false);
        }
        const updateStep = {
          key: `step_3_${step3Count}`,
          userList: apraiserList[step3Count],
          type: `step_3_${step3Count}`,
          isInitial: false,
        }

        const updatedNewSteps = newSteps.map((step, index) =>
          index === step3Count - 1 ? updateStep : step
        );
        setNewSteps(updatedNewSteps);

        const updatedStepsData = stepsData.map(step =>
          step.key === updateStep.key ? updateStep : step
        );
        setStepsData(updatedStepsData);
        setStep3Count(step3Count + 1);
      } else {       
        setStepsData(stepsData);
      }
    } else {
      if (apraiserList && apraiserList[0]) {
        setIsDisableCreatedStep(false)
      } else {
        setIsDisableCreatedStep(true)
      }
    }
  }, [apraiserList])

  const addStep = () => {
    setIsDisableCreatedStep(true)
    const newStep = {
      key: `step_3_${step3Count}`,
      userList: [],
      type: `step_3_${step3Count}`,
      isInitial: false,
    }
    setNewSteps(prevSteps => [...prevSteps, newStep]);
  }

  const removeStep = index => {
    const newStepsData = stepsData.filter((_, i) => i !== index)
    setStepsData(newStepsData)
  }

  const handleInputChange = e => {
    setNewSteps({ ...newSteps, [e.target.name]: e.target.value })
  }

  const renderSelectedTasks = (selectedTasks, step) => {
    const className = step === 4 || step === 5 ? 'pur-pay-works' : 'req-eva-works';
    return selectedTasks.map((title, index) => (
      <div key={index} className={className}>
        <CopyOutlined style={{ marginRight: 8, color: '#999' }} />
        {title.title}
        </div>
    ))
  }

  const renderSelectedFiles = (selectedFiles, step) => {
    return selectedFiles.map((file, index) => (
      <RenderFileList>
        <div key={index} className='file-container'>
          <Row>
            <Col span={22}>
              <FileTextOutlined style={{ marginRight: 8, color: '#999' }} />
              {file.name}
            </Col>
            <Col span={2}>
              <span
              onClick={() => handleRemoveUpload(step, file)}>
                <DeleteOutlined  
                  className='delete-icon'
                  style={{ fontSize: 12, cursor: 'pointer', color: '#999' }}/>
              </span>
            </Col>
          </Row>
        </div>
      </RenderFileList>
    ));
  }

  const loadAllValues = async () => {
    try {
      await ShoppingCouponStore.getAllShoppingCoupon(allParams)
    } catch (err) {
      console.log(err)
    }
  }

  const generateStepDescription = (
    stepType,
    userList,
    handleOpenModalWorkList,
    handleRemoveSelect,
    isInitial,
    index
  ) => {
    const modalMapping = {
      apraiser: 3,
    }
    for (let index = 0; index < selectedTasksSubStep3.length; index++) {
      const key = `step_3_${index + 1}`
      const value = 6 + index
      modalMapping[key] = value
    }
    const modalId = modalMapping[stepType] || 0

    const tasksMapping = {
      apraiser: selectedTasksStep3,
    }
    for (let index = 0; index < selectedTasksSubStep3.length; index++) {
      const key = `step_3_${index + 1}`
      const value = selectedTasksSubStep3[index]
      tasksMapping[key] = value
    }

    const filesMapping = {
      apraiser: ShoppingCouponStore.approveAttachments,
    }
    for (let index = 0; index < selectedTasksSubStep3.length; index++) {
      const key = `step_3_${index + 1}`
      const value = ShoppingCouponStore.subApraiserAttachments[index]
      filesMapping[key] = value
    }

    const currentTasks = tasksMapping[stepType] || [];
    const currentFiles = filesMapping[stepType] || [];
    return (
      <div>
        <Row align="middle" style={{color: 'rgba(0, 0, 0, 0.85)'}}>
          <Col span={1}></Col>
          <Col span={24} style={{ height: 'auto' }}>
            {stepType === 'created' ? (
              <>
                {userList.length > 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      maxHeight: '80px',
                      paddingBottom: '16px',
                    }}>
                    Người tạo:
                    <div style={{ marginLeft: '15px' }}>
                      <Tag
                        color={'#E6F6FF'}
                        style={{
                          borderRadius: '35px',
                          border: `1px solid #91d5ff`,
                          padding: '3px 8px 3px 4px',
                          color: blue,
                          display: 'flex',
                          marginRight: 0,
                          alignItems: 'center',
                        }}>
                        <AvatarHasName
                          imgId={currentUser.imageId}
                          size={22}
                          name={currentUser.name_lowercase}
                        />
                      </Tag>
                    </div>
                  </div>
                ) : (
                  <EmptyText>Không có người theo dõi.</EmptyText>
                )}
                <ul>{renderSelectedTasks(selectedTasksStep1, 1)}</ul>
              </>
            ) : (
              <>
                {stepType === 'approval' && <span style={{color: 'red'}}> * </span>}
                <span>
                  {stepType === 'approval'
                    ? 'Người duyệt yêu cầu: '
                    : 'Người thẩm định: '}
                </span>
                <Space style={{ marginLeft: 20, marginBottom: 10 }}>
                  <Tooltip title="Chọn người dùng" color={blue}>
                    <Button
                      icon={<UserOutlined style={{ color: blue }} />}
                      onClick={() => {
                        const assigneeType = {
                          approval: ASSIGNEE_TYPE.APPROVAL,
                          apraiser: ASSIGNEE_TYPE.APPRAISER,
                          step_3_1: ASSIGNEE_TYPE.APPRAISER_1,
                          step_3_2: ASSIGNEE_TYPE.APPRAISER_2,
                          step_3_3: ASSIGNEE_TYPE.APPRAISER_3,
                          step_3_4: ASSIGNEE_TYPE.APPRAISER_4,
                          step_3_5: ASSIGNEE_TYPE.APPRAISER_5,
                          step_3_6: ASSIGNEE_TYPE.APPRAISER_6,
                          step_3_7: ASSIGNEE_TYPE.APPRAISER_7,
                          step_3_8: ASSIGNEE_TYPE.APPRAISER_8,
                          step_3_9: ASSIGNEE_TYPE.APPRAISER_9,
                        }[stepType];
                      
                        handleOpenSelectUserDepartmentPopup(assigneeType);
                      }}
                    />
                  </Tooltip>
                </Space>
                <SelectList>
                  {stepType === 'approval' ? (
                    <>
                      {approvalList && approvalList.map(el => (
                        <Tag
                          className="tag-selected"
                          // color={'#e6f7ff'}
                          key={el.id}
                          closable
                          onClose={() => handleRemoveSelect(el)}>
                          <AvatarHasName
                            imgId={el.imageId}
                            size={22}
                            name={el.full_name}                                        
                          />
                        </Tag>
                      ))}
                    </>
                  ) : (
                    <>
                      { stepType === 'apraiser' ? (
                      <>
                        {apraiserList[0] && apraiserList[0].map(el => (
                          <Tag
                            className="tag-selected"
                            // color={'#e6f7ff'}
                            key={el.id}
                            closable
                            onClose={() => handleRemoveSelect(el)}>
                            <AvatarHasName
                              imgId={el.imageId}
                              size={22}
                              name={el.full_name}                                        
                            />
                          </Tag>
                        ))}
                      </>
                    ) : (
                      <>
                        {apraiserList[modalId - 5] && apraiserList[modalId - 5].map(el => (
                          <Tag
                            className="tag-selected"
                            // color={'#e6f7ff'}
                            key={el.id}
                            closable
                            onClose={() => handleRemoveSelect(el)}>
                            <AvatarHasName
                              imgId={el.imageId}
                              size={22}
                              name={el.full_name}                                        
                            />
                          </Tag>
                        ))}
                      </>
                    )}
                    </>
                  )}                                  
                </SelectList>
                {stepType === 'approval' ? (
                  <>         
                    <Col style={{ marginLeft: '9px', padding: 0 }}>
                      <span>Công việc liên quan: </span>
                      <Button
                        size="small"
                        onClick={() => handleOpenModal(2)}
                        icon={<CopyOutlined style={{ color: blue }} />}
                        style={{ marginLeft: '20px' }}>
                        Công việc
                      </Button>
                      <div style={{ marginBottom: 10}}></div>
                      {selectedTasksStep2.length === 0 ? (
                        <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                          Chưa có công việc nào
                        </p>
                      ) : (
                        <UploadWorkListWrapper>
                        {renderSelectedTasks(selectedTasksStep2, 2)}
                      </UploadWorkListWrapper>
                      )}    
                    </Col>
                    <div style={{ marginTop: '10px' }}></div>
                    <Col
                      name={`Shopping Documents`}
                      style={{ marginLeft: '9px', padding: 0 }}>
                      <span>Tài liệu đính kèm: </span>
                      <span style={{ marginLeft: '20px' }}></span>                      
                      <Upload
                        onRemove={file => handleRemoveUpload(2, file)}
                        beforeUpload={file => {
                          handleUpload(2, file)
                          return false
                        }}
                        showUploadList={false}>
                        <Button icon={<UploadOutlined />}></Button>
                      </Upload>                    
                      {ShoppingCouponStore.requestAttachments.length === 0 ? (
                        <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                          Chưa có tài liệu nào
                        </p>
                      ) : (
                        <UploadFileListWrapper>
                        {renderSelectedFiles(ShoppingCouponStore.requestAttachments, 2)}
                        </UploadFileListWrapper>
                      )}
                    </Col>                
                  </>
                ) : (
                  <>                   
                    <Col style={{ marginLeft: '0px', padding: 0 }}>
                      <span>Công việc liên quan: </span>
                      <Button
                        size="small"
                        onClick={() => handleOpenModal(modalId)}
                        icon={<CopyOutlined style={{ color: blue }} />}
                        style={{ marginLeft: '20px' }}>
                        Công việc
                      </Button>
                      <br />
                      {currentTasks.length === 0 ? (
                        <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                          Chưa có công việc nào
                        </p>
                      ) : (
                        <UploadWorkListWrapper>
                        {renderSelectedTasks(currentTasks, modalId)}
                      </UploadWorkListWrapper>
                      )}
                    </Col>
                    <div style={{ marginTop: '10px' }}></div>
                    <Col
                      name={`Shopping Documents`}
                      style={{ marginLeft: '0px', padding: 0 }}>
                      <span>Tài liệu đính kèm: </span>
                      <span style={{ marginLeft: '20px' }}></span>
                      <Upload
                        onRemove={file => handleRemoveUpload(modalId, file)}
                        beforeUpload={file => {
                          handleUpload(modalId, file)
                          return false
                        }}
                        showUploadList={false}>
                        <Button icon={<UploadOutlined />}></Button>
                      </Upload>                    
                      {currentFiles.length === 0 ? (
                        <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                          Chưa có tài liệu nào
                        </p>
                      ) : (
                        <UploadFileListWrapper>
                        {renderSelectedFiles(currentFiles, modalId)}
                        </UploadFileListWrapper>
                      )}
                    </Col>                   
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  const mapTasksWithType = (tasks, type) => {
    return tasks.map(task => ({
      code: task.code,
      type,
    }))
  }

  const mapLinkType = (link, type) => {
    return link.map(link => ({
      code: link.uid,
      type,
    }))
  }
  const onFinish = async values => {
    if (formValues.title === undefined || formValues.title.trim() === '') {
      message.error("Vui lòng nhập tiêu đề!")
      return
    }
    if (ShoppingCouponStore.description === '') {
      message.error("Vui lòng nhập nội dung!")
      return
    }
    if (approvalList.length === 0) {
      message.error("Vui lòng chọn người duyệt yêu cầu! ")
      return
    }
    setIsCreating(true)
    const formData = new FormData()
    formData.append('title', formValues.title)
    formData.append('content', ShoppingCouponStore.description)
    formData.append(
      'requestApprover.name',
      approvalList.map(approval => approval.id).join(',')
    )
    formData.append('requestApprover.type', 'REQUEST')
    selectedTasksStep2.forEach((work, workIndex) => {
      formData.append(`requestApprover.works[${workIndex}].workCode`, work.code)
    })
    ShoppingCouponStore.requestAttachments.forEach((file, index) => {
      formData.append(`requestApprover.files[${index}].file`, file)
    })

    apraiserList.forEach((evaluatorList, index) => {
      if (evaluatorList) {
        // Append evaluator details
        formData.append(`evaluatorList[${index}].name`, 
          evaluatorList.map(evaluator => evaluator.id).join(',')
        )
        formData.append(`evaluatorList[${index}].type`, 'APPROVE')

        // Append works for this evaluator if available
        if (index === 0) {
          selectedTasksStep3.forEach((work, workIndex) => {
            formData.append(
              `evaluatorList[0].works[${workIndex}].workCode`,
              work.code
            )
          })
          ShoppingCouponStore.approveAttachments.forEach((file, fileIndex) => {
            formData.append(
              `evaluatorList[${index}].files[${fileIndex}].file`,
              file
            )
          })
        } else {
          if (selectedTasksSubStep3[index - 1].length > 0) {
            selectedTasksSubStep3[index - 1].forEach((work, workIndex) => {
              formData.append(
                `evaluatorList[${index}].works[${workIndex}].workCode`,
                work.code
              )
            })
          }
          if (ShoppingCouponStore.subApraiserAttachments[index - 1] && 
            ShoppingCouponStore.subApraiserAttachments[index - 1].length > 0) {
            ShoppingCouponStore.subApraiserAttachments[index - 1].forEach(
              (file, fileIndex) => {
                formData.append(
                  `evaluatorList[${index}].files[${fileIndex}].file`,
                  file
                )
              }
            )
          }
        }
      }
    })

    // Append followerList array as comma-separated string
    formData.append(
      'followerList',
      followerList.map(follower => follower.id).join(',')
    )
    // Append other approvers
    formData.append(
      'purchaseApprover.name',
      buyerList.map(approval => approval.id).join(',')
    )
    formData.append('purchaseApprover.type', 'BUY')
    selectedTasksStep4.forEach((work, workIndex) => {
      formData.append(
        `purchaseApprover.works[${workIndex}].workCode`,
        work.code
      )
    })
    ShoppingCouponStore.shoppingAttachments.forEach((file, index) => {
      formData.append(`purchaseApprover.files[${index}].file`, file)
    })

    formData.append(
      'paymentApprover.name',
      payList.map(pay => pay.id).join(',')
    )
    formData.append('paymentApprover.type', 'PAY')
    selectedTasksStep5.forEach((work, workIndex) => {
      formData.append(`paymentApprover.works[${workIndex}].workCode`, work.code)
    })
    ShoppingCouponStore.paymentAttachments.forEach((file, index) => {
      formData.append(`paymentApprover.files[${index}].file`, file)
    })

    try {
      await ShoppingCouponStore.createShoppingCoupon(formData)
      props.onCancel()
      message.success('Tạo quy trình mua sắm thành công!')
      await loadAllValues()
      onSuccess()
      await ShoppingCouponStore.getAllShoppingCoupon({
        ...allParams,
      })
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    } finally {
      setIsCreating(false)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        const newStep = {
          key: `newStep_${stepsData.length + 1}`,
          userList: [],
          type: `Apraiser_${stepsData.length + 1}`,
          isInitial: false,
        }
        setStepsData([...stepsData, newStep])
        form.resetFields()
        setIsModalVisible(false)
      })
      .catch(info => {
        console.log('Validate Failed:', info)
      })
  }
  const { selectedRows } = taskStore
  // console.log(JSON.stringify(selectedRows))

  const handleChangeDescription = (value) => {
    ShoppingCouponStore.setDescription(value)
  }
  return (
    <>
      <Form form={form} initialValues={() => {}} onFinish={onFinish}>
        <Steps
          current={current}
          onChange={setCurrent}
          size="medium"
          style={{ marginBottom: 36, padding: '0 150px' }}>
          {['Tạo yêu cầu', 'Mua sắm / Thanh toán'].map((item, index) => (
            <Step key={index} />
          ))}
        </Steps>
        <div style={{ textAlign: 'center', margin: '-20px 0 10px 0px' }}>
          <Title level={5}>
            {current === 0 ? 'Tạo yêu cầu' : 'Mua sắm / Thanh toán'}
          </Title>
        </div>
        <div className="steps-content" style={{ width: '100%' }}>
          {current === 0 ? (
            <Form
              layout="vertical"
              onValuesChange={changedValues =>
                setFormValues({ ...formValues, ...changedValues })
              }>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    label="Tiêu đề"
                    name="title"
                    rules={[
                      { required: true, message: 'Vui lòng nhập tiêu đề' },
                      { max: 500, message: 'Tiêu đề không được dài quá 500 kí tự!' },
                      { validator: validator.validateInputString },
                    ]}>
                    <Input
                      placeholder="Nhập tiêu đề"
                      defaultValue={formValues.title || ''}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Nội dung"
                    name="content-editor"
                    rules={[
                      { required: true, message: 'Vui lòng nhập nội dung' },
                      { validator: validator.validateInputString },
                    ]}>
                    <TinyMCE
                      valueChange={ShoppingCouponStore.description || ''}
                      onChange={handleChangeDescription}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <label
                    style={{
                      display: 'inline-block',
                      margin: '15px 8px 25px 0',
                    }}>
                    Người theo dõi:
                  </label>
                  <Space>
                    <Tooltip title="Chọn người dùng" color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() =>
                          handleOpenSelectUserDepartmentPopup(
                            ASSIGNEE_TYPE.FOLLOWER_PROCUREMENT
                          )
                        }
                      />
                    </Tooltip>
                  </Space>
                  <SelectList>
                    {followerList.map(el => (
                      <Tag
                        className={'tag-selected'}
                        // color={blue}
                        key={el.id}
                        closable
                        onClose={() => handleRemoveSelect(el)}>
                        <AvatarHasName
                          imgId={el.image_id}
                          size={22}
                          name={el.full_name}                          
                        />
                      </Tag>
                    ))}
                  </SelectList>
                </Col>
                <Divider />
                <Col span={24}>
                  <Title level={5}>Quy trình xử lý:</Title>
                  <Form.Item name="steps">
                    <Steps
                      direction="vertical"
                      size="small"
                      current={current1}
                      onChange={setCurrent1}
                      style={{
                        marginBottom: 16,
                        width: '100%',
                        height: 'auto',
                        overflow: 'hidden',
                      }}>
                      {stepsData.map((step, index) => {
                        return (
                          <Step
                            key={step.key}
                            description={generateStepDescription(
                              step.type,
                              step.userList,
                              handleOpenModalWorkList,
                              handleRemoveSelect,
                              step.isInitial,
                              index
                            )}
                          />
                        )
                      })}
                    </Steps>
                  </Form.Item>
                  {step3Count === 10 ? (
                    <div style={{ color: 'red' }}>
                      * Đã đạt mức tối đa 10 người thẩm định
                    </div>
                  ) : (
                    <Button onClick={addStep} icon={<PlusOutlined />} disabled={isDisableCreatedStep}>
                      Thêm cấp duyệt
                    </Button>
                  )}                 
                </Col>
              </Row>
            </Form>
          ) : (
            <Row gutter={24} style={{color: 'rgba(0, 0, 0, 0.85)'}}>
              <Col span={11} style={{marginRight: 10}}>
                <span
                  label=""
                  name="shopping"
                  style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Mua sắm:
                </span>
                <>
                  <Col style={{margin: 0, padding: 0}}>
                    <label
                      style={{
                        display: 'inline-block',
                        margin: '15px 20px 25px 0',
                      }}>
                      Người duyệt:
                    </label>
                    <Space>
                      <Tooltip title="Chọn người dùng" color={blue}>
                        <Button
                          icon={<UserOutlined style={{ color: blue }} />}
                          onClick={() =>
                            handleOpenSelectUserDepartmentPopup(
                              ASSIGNEE_TYPE.BUYER
                            )
                          }
                        />
                      </Tooltip>
                    </Space>
                    <SelectList>
                      {buyerList.map(el => (
                        <Tag
                          className={'tag-selected'}
                          // color={blue}
                          key={el.id}
                          closable
                          onClose={() => handleRemoveSelect(el)}>
                          <AvatarHasName
                            imgId={el.image_id}
                            size={22}
                            name={el.full_name}                          
                          />
                        </Tag>
                      ))}
                    </SelectList>
                   
                    <Col style={{ marginLeft: '0px', padding: 0 }}>
                      <span>Công việc liên quan: </span>
                      <span style={{ marginLeft: '20px' }}></span>
                      <Button
                        size={'small'}
                        onClick={() => handleOpenModal(4)}
                        icon={<CopyOutlined style={{ color: blue }} />}>
                        Công việc
                      </Button>
                      <br />
                      {selectedTasksStep4.length === 0 ? (
                        <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                          Chưa có công việc nào
                        </p>
                      ) : (
                        <UploadWorkListWrapper>
                          {renderSelectedTasks(selectedTasksStep4, 4)}
                        </UploadWorkListWrapper>
                      )}
                    </Col>
                    <div style={{ marginTop: '20px' }}></div>
                    <Col
                      name={`Shopping Documents`}
                      style={{ marginLeft: '0px', padding: 0 }}>
                      <span>Tài liệu đính kèm: </span>
                      <span style={{ marginLeft: '20px' }}></span>
                      <Upload
                        onRemove={file => handleRemoveUpload(4, file)}
                        beforeUpload={file => {
                          handleUpload(4, file)
                          return false
                        }}
                        showUploadList={false}>                 
                        <Button icon={<UploadOutlined />}></Button>                   
                      </Upload>
                      {ShoppingCouponStore.shoppingAttachments.length === 0 ? (
                        <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                          Chưa có tài liệu nào
                        </p>
                      ) : (
                        <UploadFileListWrapper>
                        {renderSelectedFiles(ShoppingCouponStore.shoppingAttachments, 4)}
                        </UploadFileListWrapper>
                      )}
                    </Col>
                  </Col>
                </>
              </Col>
              <Divider type="vertical" style={{ height: '350px' }} />
              <Col span={12}>
                <span
                  label=""
                  name="payment"
                  style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Thanh toán:
                </span>
                <>
                  <Col style={{margin: 0, padding: 0}}>
                    <label
                      style={{
                        display: 'inline-block',
                        margin: '15px 20px 25px 0',
                      }}>
                      Người duyệt:
                    </label>
                    <Space>
                      <Tooltip title="Chọn người dùng" color={blue}>
                        <Button
                          icon={<UserOutlined style={{ color: blue }} />}
                          onClick={() =>
                            handleOpenSelectUserDepartmentPopup(
                              ASSIGNEE_TYPE.PAY
                            )
                          }
                        />
                      </Tooltip>
                    </Space>
                    <SelectList>
                      {payList.map(el => (
                        <Tag
                          className={'tag-selected'}
                          // color={blue}
                          key={el.id}
                          closable
                          onClose={() => handleRemoveSelect(el)}>
                          <AvatarHasName
                            imgId={el.image_id}
                            size={22}
                            name={el.full_name}                            
                          />
                        </Tag>
                      ))}
                    </SelectList>
                    
                    <Col style={{ marginLeft: '0px', padding: 0 }}>
                      <span>Công việc liên quan: </span>
                      <span style={{ marginLeft: '20px' }}></span>
                      <Button
                        size={'small'}
                        onClick={() => handleOpenModal(5)}
                        icon={<CopyOutlined style={{ color: blue }} />}>
                        Công việc
                      </Button>
                      <br />
                      {selectedTasksStep5.length === 0 ? (
                        <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                          Chưa có công việc nào
                        </p>
                      ) : (
                        <UploadWorkListWrapper>
                          {renderSelectedTasks(selectedTasksStep5, 5)}
                        </UploadWorkListWrapper>
                      )}
                    </Col>
                    <div style={{ marginTop: '10px' }}></div>                   
                    <Col
                      name={`Shopping Documents`}
                      style={{ marginLeft: '0px', padding: 0 }}>
                      <span>Tài liệu đính kèm: </span>
                      <span style={{ marginLeft: '20px' }}></span>
                      <Upload
                        onRemove={file => handleRemoveUpload(5, file)}
                        beforeUpload={file => {
                          handleUpload(5, file)
                          return false
                        }}
                        showUploadList={false}>
                        <Button icon={<UploadOutlined />}></Button>
                      </Upload>
                      {ShoppingCouponStore.paymentAttachments.length === 0 ? (
                        <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                          Chưa có tài liệu nào
                        </p>
                      ) : (
                        <UploadFileListWrapper>
                        {renderSelectedFiles(ShoppingCouponStore.paymentAttachments, 5)}
                        </UploadFileListWrapper>
                      )}
                    </Col>
                  </Col>
                </>
              </Col>
            </Row>
          )}
        </div>
        <div className="steps-action"
          style={{
            display: 'flex',
            justifyContent: 'flex-end', // Căn các button về phía bên phải
            width: '100%'
          }}>
          {/* {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {current < 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )} */}

          {current < 1 && (
            <>
              <Button danger type="default" style={{ marginRight: 10 }} onClick={() => props.onCancel()}>
                Hủy bỏ
              </Button>
              <Button type="primary" onClick={() => next()}>
                Tiếp theo
              </Button>
            </>
          )}
          {current === 1 && (
            <>
              <Button danger type="default" style={{ marginRight: 10 }} onClick={() => props.onCancel()}>
                Hủy bỏ
              </Button>
              <Button type="primary" htmlType="submit" loading={isCreating}>
                Tạo mới
              </Button>
            </>           
          )}
          
        </div>
      </Form>
      <SelectPeoplePopup
        onlyOneUser
        noLoadSelectData={false}
        disableSelectDepartment
        isOpenCollapse={true}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        isUserDisableList={false}
      />
      <ModalWorkLists
        isVisibleModalShoppingCoupon={isVisibleModalShopingCoupon}
        handleCloseModal={() => setIsVisibleModalShopingCoupon(false)}
        currentStepType={currentStepType}
        onConfirmSelection={handleConfirmSelection}
        selectedRowKeysStep1={selectedRowKeysStep1}
        selectedRowKeysStep2={selectedRowKeysStep2}
        selectedRowKeysStep3={selectedRowKeysStep3}
        selectedRowKeysSubStep3={selectedRowKeysSubStep3}
        shoppingTasks={selectedRowKeysStep4}
        paymentTasks={selectedRowKeysStep5}
        onWorkSelect={handleWorkSelection}
        currentStep={currentStep}
      />

      <Modal
        title="Add New Step"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Step Name"
            rules={[
              { required: true, message: 'Please input the step name!' },
            ]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
ShoppingCouponCreate.propTypes = {}

export default inject(
  'proposalStore',
  'approvalTemplateStore',
  'selectPeopleStore',
  'internalDocumentStore',
  'loadingAnimationStore',
  'authenticationStore',
  'taskStore',
  'ShoppingCouponStore'
)(observer(ShoppingCouponCreate))
