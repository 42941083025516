import { requests } from './DefaultRequest'

export default {
  getDistributionVotesList: param =>
    requests.get(`/api/v1/proposals/current-user?type=proposal_asset&${param}`),

  getDistributionVotesDetail: code =>
    requests.get(`/api/v1/proposal-asset/${code}`),

  getDistributionVotesAllDetail: code =>
    requests.get(`/api/v1/proposal-asset/detail/${code}`),

  createDistributionVotes: payload =>
    requests.post(`/api/v1/proposal-asset`, payload),

  updateDistributionVotes: (code, payload) =>
    requests.put(`/api/v1/proposal-asset/${code}`, payload),

  deleteDistributionVotes: code =>
    requests.delete(`/api/v1/proposal-asset/${code}`),

  distributionVotesOther: data =>
    requests.post(`/api/v1/proposal-asset/approval`, data),

  getDistributionVotesComment: proposalId =>
    requests.get(`/api/v1/proposal-asset/${proposalId}/comments`),

  deleteDistributionVotesComment: (proposal_code, comment_id) =>
    requests.delete(
      `/api/v1/proposal-asset/${proposal_code}/comments/${comment_id}`
    ),
  createOtherComment: (proposalId, data) =>
    requests.post(`/api/v1/proposal-asset/${proposalId}/comments`, data),
  approveProposal: (proposalId, payload) =>
    requests.put(`/api/v1/proposals/${proposalId}/approve`, payload),
}
