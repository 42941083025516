import React, { useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Button, message, Upload } from 'antd'
import { toJS } from 'mobx'
import utils from '../../utils'
import CommentComponent from '../../components/CommentComponent'
import MentionTextInput from '../../components/MentionTextInput/MentionTextInput'
import {
  FormButtonGroup,
  UploadFileListWrapper,
  UploadWrapper,
} from '../../components/CommentComponent/CommentComponentStyled'
import { SendOutlined, UploadOutlined } from '@ant-design/icons'
import { PAGE_NAME, SORT_TYPE } from '../../constants'
import UploadFileItem from '../../components/Common/FileItemComponent/UploadFileItem'
import { format } from 'path'

const ShoppingCouponPurchaseComment = props => {
  const {
    ShoppingCouponStore,
    fileStore,
    shoppingId,
    type,
    approveCode,
    approveName,
    loadingAnimationStore,
    notificationStore,
    authenticationStore,
  } = props

  const { feedbackPurchaseList } = ShoppingCouponStore
  const { notificationType } = notificationStore
  const { currentUser } = authenticationStore
  const [fileList, setFileList] = useState([])
  const [sort, setSort] = useState(SORT_TYPE.DESCENDING)
  const [commentPurchaseList, setCommentPurchaseList] = useState([])
  const [comment, setComment] = useState('')
  const [isShowDelComments, setIsShowDelComments] = useState(false)
  const [isHiddenAvatar, setIsHiddenAvatar] = useState(false)

  const userName = currentUser.username;  
  const handleOnchangeComment = (
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    setComment(newValue)
  }
  const handleChangeSortComment = sort => {
    setSort(sort)
    const commentPurchaseListReverse = commentPurchaseList.reverse()
    setCommentPurchaseList([...commentPurchaseListReverse])
  }
  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
  }
  const handleChangeFile = info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }

  const handleCreateComment = async (commentData) => {
    try {
      const payload = {
        procurementCode: shoppingId,
        type: type
      }
      const scrollCommentWrapper = document.querySelector('.listCommentWrapper')
      let newcommentPurchaseList = [...commentPurchaseList]
      const uploadFileList = fileList
      ? fileList.map(file => file.originFileObj)
      : []
      const formData = new FormData();
      formData.append(`approveCode`, commentData.approveCode)
      formData.append( `content`, commentData.content)
      uploadFileList.forEach((file, index) => {
        formData.append(`files[${index}].file`, file)
      })
      await ShoppingCouponStore.createFeedback(formData)
      const response = await ShoppingCouponStore.getFeedbackList(payload)
      const newComment = response.data[response.data.length - 1]
      newcommentPurchaseList.push(newComment)
      setCommentPurchaseList(newcommentPurchaseList)
      handleShowDeletedComments(newcommentPurchaseList, isShowDelComments)
      setComment('')
      scrollCommentWrapper.scrollTo(0, scrollCommentWrapper.scrollHeight)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Gửi ý kiến không thành công!')
    }
  }
  const sendComment = useCallback(async () => {
    if (utils.isNullish(comment.trim())) return
    if (comment.length > 5000) {
      return message.error('Ý kiến không vượt quá 5000 ký tự!')
    }
    
    let uploadedFiles = []
    try {
      loadingAnimationStore.setShowSpinInline(true)
      const commentData = {
        approveCode: approveCode,
        content: comment.replace(/\s+/g, ' ').trim(),

      }
      await handleCreateComment(commentData)
      setFileList([])
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }, [fileList, commentPurchaseList, comment])

  const renderCommentForm = userName === approveName ? (
    <div className={'comment-form'}>
      <MentionTextInput
        value={comment}
        readonly={false}
        onChange={handleOnchangeComment}
        placeHolder={'Viết ý kiến...'}
        style={{
          control: {
            width: '100%',
            minHeight: '30px',
            maxHeight: '300px',
            borderRadius: '5px',
            overflowY: 'auto',
          },
          highlighter: {
            overflow: 'hidden',
          },
          input: {
            overflow: 'auto',
            minHeight: '30px',
            maxHeight: '300px',
          },
        }}
      />
      {fileList.length !== 0 && (
        <UploadFileListWrapper>
          {fileList.map(file => (
            <UploadFileItem
              key={file.uid}
              file_id={file.uid}
              file_name={file.name}
              file_type={utils.getExtensionFile(file.name)}
              handleRemoveFileFromUploadList={() =>
                handleRemoveFileFromUploadList(file.uid)
              }
            />
          ))}
        </UploadFileListWrapper>
      )}
      <FormButtonGroup>
        <UploadWrapper>
          <Upload
            style={{ marginLeft: 4, marginRight: 4 }}
            valuePropName={'fileList'}
            fileList={fileList}
            multiple={true}
            onChange={handleChangeFile}
            beforeUpload={() => false}
            showUploadList={false}>
            <Button htmlType={'button'}>
              <UploadOutlined /> Tải file
            </Button>
          </Upload>
        </UploadWrapper>
        <Button onClick={sendComment} type={'primary'} icon={<SendOutlined />}>
          Đăng ý kiến
        </Button>
      </FormButtonGroup>
    </div>
  ) : null

  const handleDeleteComment = async (comment_id, index) => {
    try {
      loadingAnimationStore.setShowSpinInline(true)
      await ShoppingCouponStore.deleteFeedback(comment_id)

      const newcommentPurchaseList = [...commentPurchaseList]

      const delIndex = newcommentPurchaseList.findIndex(
        comment => comment.comment_id === comment_id
      )
      newcommentPurchaseList[delIndex] = {
        ...newcommentPurchaseList[delIndex],
        deleted: true,
      }
      setCommentPurchaseList(newcommentPurchaseList)
      handleShowDeletedComments(newcommentPurchaseList, isShowDelComments)
      message.success('Xóa ý kiến thành công!')
    } catch (err) {
      console.log(err)
      message.error('Xóa ý kiến thất bại!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }
  const handleShowDeletedComments = async (commentPurchaseList, isShow) => {
    if (isShow) {
      const newcommentPurchaseList = []
      commentPurchaseList.forEach(comment => {
        if (comment.isHidden) {
          newcommentPurchaseList.push(...comment.arrDeletedComment)
          comment.isHidden = !comment.isHidden
        } else newcommentPurchaseList.push(comment)
      })
      setCommentPurchaseList(newcommentPurchaseList)
    } else {
      await getFeedbackList()
    }
  }

  const handleToggleShowDelCommentsMenu = () => {
    handleShowDeletedComments(commentPurchaseList, !isShowDelComments)
    setIsShowDelComments(!isShowDelComments)
  }

  useEffect(() => {
    /** Danh sách comment sau khi xóa chứa các comment đã xóa liền nhau gộp thành 1 mảng */

    let commentAfterDeleteList = []
    let key = -1
    feedbackPurchaseList.map((el, i) => {
      if (!el.deleted) {
        commentAfterDeleteList.push(toJS(el));
      }
      if (el.deleted && i > key) {
        const arrDeletedComment = []
        for (let index = i; index < feedbackPurchaseList.length; index++) {
          key = index
          if (feedbackPurchaseList[index].deleted === el.deleted) {
            arrDeletedComment.push(toJS(feedbackPurchaseList[index]))
          }
          if (
            feedbackPurchaseList[index].deleted === !el.deleted ||
            index === feedbackPurchaseList.length - 1
          )
            break
        }
        commentAfterDeleteList.push({
          comment_id: arrDeletedComment[0].comment_id,
          arrDeletedComment: arrDeletedComment,
          isHidden: true,
        })
      } 
      
    })
    setCommentPurchaseList(commentAfterDeleteList)
  }, [feedbackPurchaseList, notificationType])

  const getFeedbackList = async () => {
    try {
      const payload = {
        procurementCode: props.shoppingId,
        type: props.type
      }
      loadingAnimationStore.setShowSpinInline(true)
      const scrollCommentWrapper = document.querySelector('.listCommentWrapper')
      await ShoppingCouponStore.getFeedbackList(payload)
      await scrollCommentWrapper.scrollTo(0, scrollCommentWrapper.scrollHeight)
    } catch (e) {
      console.log(e)
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  useEffect(() => {
    if (!approveCode) return
    ;(async () => {
      await getFeedbackList()
    })()
    return () => ShoppingCouponStore.clearFeedbackList()
  }, [approveCode])

  useEffect(() => {
    if (userName !== approveName) {
      setIsHiddenAvatar(true);
    } else {
      setIsHiddenAvatar(false);
    }
  }, [approveName]);

  return (
    <CommentComponent
      handleToggleShowDelCommentsMenu={handleToggleShowDelCommentsMenu}
      handleDeleteComment={handleDeleteComment}
      isShowDelComments={isShowDelComments}
      commentList={commentPurchaseList}
      handleShowDeletedComment={handleShowDeletedComments}
      renderCommentForm={renderCommentForm}
      handleChangeSortComment={handleChangeSortComment}
      sortComment={sort}
      isHiddenAvatar={isHiddenAvatar}
    />
  )
}

ShoppingCouponPurchaseComment.propTypes = {}

export default inject(
  'ShoppingCouponStore',
  'fileStore',
  'loadingAnimationStore',
  'notificationStore',
  'authenticationStore'
)(observer(ShoppingCouponPurchaseComment))
