import React, { useState } from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { PROPOSAL_STATUS } from '../../../../../constants'
import validator from '../../../../../validator'

const ProposalActionConfirmModal = props => {
  const {
    isModalVisible,
    handleCloseModal,
    confirmModalType,
    proposalStore,
  } = props

  const { defaultApprovalConfigUpdate } = proposalStore

  const { proposalId: proposalId } = useParams()

  const [form] = Form.useForm()

  const [comment, setComment] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = async () => {
    const { approvalLevels } = defaultApprovalConfigUpdate
    const currentStepInfo =
      approvalLevels.find(item => item.status === PROPOSAL_STATUS.PENDING) || {}
    const currentStep = currentStepInfo.level || 0
    let nextStepInfo = {}
    if (currentStep < approvalLevels.length - 1) {
      nextStepInfo = approvalLevels[currentStep + 1]
    }
    if (
      !nextStepInfo.username &&
      confirmModalType === PROPOSAL_STATUS.APPROVAL &&
      nextStepInfo.level
    ) {
      handleCancelModal()
      return message.warning(`Vui lòng chọn ${nextStepInfo.name}!`)
    }

    let messageText = 'Thành công!'
    let status = null
    if (confirmModalType === PROPOSAL_STATUS.APPROVAL) {
      messageText = 'Duyệt yêu cầu thành công!'
      status = PROPOSAL_STATUS.APPROVED
    }
    if (confirmModalType === PROPOSAL_STATUS.REJECT) {
      messageText = 'Bạn đã từ chối yêu cầu này!'
      status = PROPOSAL_STATUS.REJECTED
    }
    const payload = {
      status: status,
      comment: comment,
      next_approval_level: nextStepInfo.allow_edit
        ? {
            code: nextStepInfo.code,
            username: nextStepInfo.username,
          }
        : null,
    }

    try {
      setIsLoading(true)
      await proposalStore.approveProposal(proposalId, payload)

      await proposalStore.getOtherDetail(proposalId)
      handleCancelModal()
      message.success(messageText)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancelModal = () => {
    setComment(null)
    handleCloseModal()
  }

  const handleInputComment = e => {
    setComment(e.target.value)
  }
  return (
    <Modal visible={isModalVisible} footer={null} onCancel={handleCloseModal}>
      <div>
        <div style={{ fontSize: 14, fontWeight: 600, marginBottom: 10 }}>
          {`Bạn có chắc chắn muốn ${
            confirmModalType === PROPOSAL_STATUS.APPROVAL ? 'duyệt' : 'Từ chối'
          } yêu cầu này?`}
        </div>
        {confirmModalType === PROPOSAL_STATUS.REJECT && (
          <Form form={form} onFinish={onFinish} layout={'vertical'}>
            <Form.Item
              name={'comment'}
              label={'Lý do:'}
              rules={[
                { required: true, message: 'Vui lòng nhập lý do!' },
                { validator: validator.validateInputString },
              ]}>
              <Input.TextArea
                onChange={handleInputComment}
                value={comment}
                placeholder={'Nhập lý do...'}
                autoSize={{
                  minRows: 2,
                  maxRows: 6,
                }}
              />
            </Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 10,
              }}>
              <Button onClick={handleCancelModal}>Đóng</Button>
              <Button htmlType={'submit'} type={'danger'}>
                Từ chối yêu cầu
              </Button>
            </div>
          </Form>
        )}
      </div>
      {confirmModalType === PROPOSAL_STATUS.APPROVAL && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 10,
            marginTop: 20,
          }}>
          <Button onClick={handleCancelModal}>Đóng</Button>
          <Button onClick={onFinish} type={'primary'} loading={isLoading}>
            Duyệt yêu cầu
          </Button>
        </div>
      )}
    </Modal>
  )
}

ProposalActionConfirmModal.propTypes = {}

export default inject('proposalStore')(observer(ProposalActionConfirmModal))
