import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Button, Empty, message, Pagination } from 'antd'

import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'
import { AvatarHasName } from '../../components/Common/Avatar'
import {
  EmptyText,
  Text,
  trimOverLengthString,
} from '../../components/Common/CellText'
import { TableBottomPaginationBlock } from '../../components/Common/Table'
import TableComponent from '../../components/Common/TableComponent'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { ACL_ACTION_TYPE, RISK_TYPE } from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout'
import AdvanceFilter from './AdvanceFilter'
import useOpenItem from '../../hooks/useOpenItem'
import RiskUpdatePopup from '../RiskDetailPage/RiskUpdatePopup'
import { AuthenticationRequest } from '../../requests/AuthenticationRequest'
import { DocumentAccessibilityRequest } from '../../requests/DocumentAccessibilityRequest'
import { RiskRequest } from '../../requests/RiskRequest'
import { CompanyRequest } from '../../requests/CompanyRequest'
import { CompanyList } from '../CompanyPage'
import { DeparmentRequest } from '../../requests/DepartmentRequest'

const RiskListPage = props => {
  const { history, loadingAnimationStore, riskStore, selectPeopleStore } = props
  const queryStringParse = queryString.parse(location.search)
  const {
    riskList,
    total_count,
    filterPage,
    filterSize,
    type,
    year,
    unitCode,
    createdAt,
    keyword,
    active,
  } = riskStore
  const {
    isOpen: isVisibleRiskCreatePopup,
    handleOpen: handleVisibleRiskCreatePopup,
    handleClose: handleCloseRiskCreatePopup,
  } = useOpenItem()
  const [isRefreshPage, setIsRefreshPage] = useState(true)
  // check hiển thị phân trang khi load dữ liệu
  const [checkPagination, setCheckPagination] = useState(true)

  const [currentUser, setCurrentUser] = useState([])
  const [docAccess, setDocAccess] = useState([])

  const [riskUnits, setRiskUnits] = useState([])
  const [companyList, seCompanyList] = useState([])
  const [departmentList, setDepartmentList] = useState([])

  useEffect(() => {
    const fetchRiskUnit = async () => {
      const response = await RiskRequest.getRiskUnitsV3()
      setRiskUnits(response.data)
    }
    fetchRiskUnit()
  }, [])

  useEffect(() => {
    const fetchCompanies = async () => {
      const response = await CompanyRequest.getCompanyList()
      seCompanyList(response.data)
    }
    fetchCompanies()
  }, [])

  useEffect(() => {
    const fetchDepartments = async () => {
      const response = await DeparmentRequest.getDepartmentListV2()
      setDepartmentList(response.data)
    }
    fetchDepartments()
  }, [])

  const rUnit = riskUnits?.map(obj => ({
    ...obj,
    code: obj.code.replace(/^RU_/, ''),
  }))

  const mergedData = companyList?.map(item1 => {
    const item2 = rUnit?.find(item => item.code === item1.code)
    return {
      ...item1,
      ...item2,
    }
  })

  const dData = departmentList?.map(item => {
    const { code, name, created_at, updated_at, order_display } = item
    return { code, name, created_at, updated_at, order_display }
  })

  function mergeCompaniesAndDepartments(companies, departments) {
    // Clone the companies array to avoid mutating the original data
    const mData = [...mergedData]

    // Append each department object to the cloned array
    dData?.forEach(department => {
      mData.push(department)
    })

    return mData
  }
  const merge2Data = mergeCompaniesAndDepartments(mergedData, dData)

  const lookupMap = new Map()
  merge2Data?.forEach(item => lookupMap.set(item.code, item))

  // Map dataset2 and add corresponding data from dataset1
  const mappedData = riskList?.map(item2 => {
    const correspondingItem = lookupMap?.get(`${item2.risk_unit_code}`)
    if (correspondingItem) {
      return {
        ...item2,
        name: correspondingItem.name,
        company_code: correspondingItem.company_code,
      }
    } else {
      return { ...item2, name: null, company_code: null }
    }
  })

  /** Columns table */
  const tableColumns = useMemo(
    () => [
      {
        title: 'Đơn vị',
        width: 280,
        render: record =>
          record.title ? (
            <div
              style={{
                fontSize: '12px',
                maxWidth: '100%',
              }}>
              <div
                style={{
                  fontSize: '12px',
                  maxWidth: '100%',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  whiteSpace: 'normal',
                }}>
                {record.name}
              </div>
            </div>
          ) : (
            <EmptyText>Không có tiêu đề.</EmptyText>
          ),
      },
      {
        title: 'Tiêu đề',
        width: 570,
        render: record =>
          record.title ? (
            <div
              style={{
                fontSize: '12px',
                maxWidth: '100%',
              }}>
              <div
                style={{
                  fontSize: '12px',
                  maxWidth: '100%',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  whiteSpace: 'normal',
                }}>
                {record.title}
              </div>
            </div>
          ) : (
            <EmptyText>Không có tiêu đề.</EmptyText>
          ),
      },
      {
        align: 'center',
        width: 180,
        title: 'Ngày tạo',
        render: record => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {moment(record.created_at).format('DD-MM-YYYY')}
          </div>
        ),
      },
      {
        title: 'Người tạo',
        width: 250,
        render: record => (
          <AvatarHasName
            imgId={record?.image}
            name={record.create_user?.name_lowercase}
          />
        ),
      },
    ],
    []
  )

  useEffect(() => {
    async function fetchInitialData() {
      try {
        loadingAnimationStore.showSpinner(true)
        selectPeopleStore.clearSelected()

        const [userResponse, docAccessResponse] = await Promise.all([
          AuthenticationRequest.checkCurrentUser(),
          DocumentAccessibilityRequest.getDocumentAccessibilities(
            'RISK',
            'VIEW_ALL'
          ),
        ])

        setCurrentUser(userResponse.data)
        setDocAccess(docAccessResponse.data)
      } catch (error) {
        message.error(error.vi || 'Đã có lỗi xảy ra!')
        console.error(error)
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    }
    fetchInitialData()
  }, [loadingAnimationStore, selectPeopleStore])

  const loadRiskList = useCallback(async () => {
    const queryParams = {
      page: riskStore.filterPage,
      size: riskStore.filterSize,
      type: riskStore.type,
      year: riskStore.year,
      createdAt: riskStore.createdAt,
      unit_code: riskStore.unitCode,
      keyword: riskStore.keyword,
      active: riskStore.active,
    }
    try {
      const checkUser = docAccess?.map(item => item.assignee.user_name)

      const isCurrentUserInCheckUser = checkUser?.includes(
        currentUser?.username
      )
      if (isCurrentUserInCheckUser) {
        await riskStore.getAllRisks()
      } else {
        await riskStore.getRisks()
      }
      const queryStringStringify = queryString.stringify(queryParams, {
        skipNull: true,
      })
      history.replace(`/risk?${queryStringStringify}`)
    } catch (err) {
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    }
  }, [history, riskStore, currentUser, docAccess])

  useEffect(() => {
    riskStore.setQueryParams(
      'filterPage',
      // @ts-ignore
      queryStringParse.page ? parseInt(queryStringParse.page) : 0
    )
    riskStore.setQueryParams(
      'filterSize',
      // @ts-ignore
      queryStringParse.size ? parseInt(queryStringParse.size) : 10
    )
    riskStore.setQueryParams(
      'type',
      queryStringParse.type ?? RISK_TYPE.DELEGATE
    )
    riskStore.setQueryParams(
      'year',
      // @ts-ignore
      queryStringParse.year ? parseInt(queryStringParse.year) : null
    )
    riskStore.setQueryParams('unitCode', queryStringParse.unit_code)
    riskStore.setQueryParams('createAt', queryStringParse.createdAt)
    riskStore.setQueryParams('keyword', queryStringParse.keyword)
    ;(async () => {
      loadingAnimationStore.setTableLoading(true)
      setCheckPagination(false)

      try {
        await loadRiskList()
        setIsRefreshPage(false)
      } catch (err) {
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
        setCheckPagination(true)
      }
    })()
    return () => {
      riskStore.clearRiskStore()
      riskStore.setQueryParams('active', false)
    }
  }, [])

  useEffect(() => {
    if (isRefreshPage) return
    ;(async () => {
      loadingAnimationStore.setTableLoading(true)
      try {
        await loadRiskList()
      } catch (err) {
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    })()
  }, [
    filterPage,
    filterSize,
    type,
    year,
    unitCode,
    createdAt,
    keyword,
    active,
    isRefreshPage,
    loadingAnimationStore,
    loadRiskList,
    currentUser,
    docAccess,
  ])

  const handleChangePagination = (page, size) => {
    riskStore.setQueryParams('filterPage', page - 1)
    riskStore.setQueryParams('filterSize', size)
  }
  return (
    <DashboardLayout>
      <Helmet>
        <title>Phiếu rủi ro | Danh sách phiếu rủi ro</title>
      </Helmet>
      <PageTitle
        location={location}
        title="Danh sách phiếu rủi ro"
        hiddenGoBack>
        <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__PKS_RUI_RO}>
          <Button
            // onClick={() => history.push(`/risk/create?type=${type}`)}
            onClick={handleVisibleRiskCreatePopup}
            type={'primary'}
            icon={<PlusOutlined />}>
            Tạo mới
          </Button>
        </AccessControlAction>
      </PageTitle>
      <ContentBlockWrapper>
        <AdvanceFilter store={riskStore} storeName="riskStore" />
        <TableComponent
          onRow={record => {
            return {
              onClick: () =>
                history.push(`/risk/view/${record.code}?type=${type}`),
            }
          }}
          rowKey={record => record.code}
          dataSource={mappedData}
          columns={tableColumns}
          pagination={false}
          locale={{
            emptyText:
              checkPagination === true ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={'Không có dữ liệu'}
                />
              ) : (
                <div style={{ opacity: 0.1, height: '150px' }} />
              ),
          }}
          loading={loadingAnimationStore.tableLoading}
        />
        <TableBottomPaginationBlock>
          {checkPagination === true ? (
            <Pagination
              current={filterPage + 1}
              pageSize={filterSize}
              total={total_count}
              onChange={handleChangePagination}
            />
          ) : (
            ''
          )}
        </TableBottomPaginationBlock>
      </ContentBlockWrapper>
      {isVisibleRiskCreatePopup && (
        <RiskUpdatePopup
          type={type}
          handleCancel={handleCloseRiskCreatePopup}
          isVisible={isVisibleRiskCreatePopup}
          // riskStore={riskStore}
          // getRiskDetail={getRiskDetail}
          isCreate
        />
      )}
    </DashboardLayout>
  )
}

RiskListPage.propTypes = {
  proposalStore: PropTypes.object,
}

export default inject(
  'riskStore',
  'loadingAnimationStore',
  'selectPeopleStore'
)(observer(RiskListPage))
