import { action, observable } from 'mobx'
import { ShoppingCouponRequest } from '../requests/ShoppingCouponRequest'
import commandStore from './commandStore'

class ShoppingCouponStore {
  @observable shoppingCouponLists = []
  @observable shoppingCouponDetailList = {
    id: null,
    code: null,
    title: null,
    content: null,
    followerList: [],
    procurementApprover: [],
    paymentApprover: [],
    evaluatorList: [],
    works: [],
    procurementFeedback: null,
    paymentFeedback: null,
    createdDate: null,
    updated_at: null,
    files: [],
  }
  @observable selectedTasksStep1 = []
  @observable selectedTasksStep2 = []
  @observable selectedTasksStep3 = []
  @observable shoppingTasks = []
  @observable apraiserTasks = []
  @observable paymentTasks = []
  @observable requestAttachments = []
  @observable approveAttachments = []
  @observable shoppingAttachments = []
  @observable paymentAttachments = []
  @observable description = ''
  @observable uploadedFiles = []
  @observable evaluationTasks = {}
  @observable dynamicEvaluationSteps = []

  @observable subApraiserAttachments = {}
  @observable shoppingCouponType = " "

  @action addDynamicEvaluationStep() {
    const newStep = {
      key: `step_${this.dynamicEvaluationSteps.length + 1}`,
      type: `thẩm định ${this.dynamicEvaluationSteps.length + 2}`,
      tasks: [],
      approvers: [],
      feedback: null,
      files: [],
      userList: [],
    }
    this.dynamicEvaluationSteps.push(newStep)
  }

  @action removeDynamicEvaluationStep(stepKey) {
    this.dynamicEvaluationSteps = this.dynamicEvaluationSteps.filter(
      step => step.key !== stepKey
    )
  }

  @action updateDynamicEvaluationStepData(stepKey, newData) {
    const step = this.dynamicEvaluationSteps.find(step => step.key === stepKey)
    if (step) {
      step.data = newData
    }
  }

  @action initializeEvaluationStep(stepName) {
    if (!this.evaluationTasks[stepName]) {
      this.evaluationTasks[stepName] = {
        tasks: [],
        approvers: [],
        feedback: null,
        files: [],
        userList: [],
      }
    }
  }

  @action updateDynamicEvaluationTasks(stepName, tasks) {
    const step = this.dynamicEvaluationSteps.find(step => step.key === stepName)
    if (step) {
      step.tasks = tasks
    }
  }

  @action updateDynamicEvaluationFeedback(stepName, feedback) {
    const step = this.dynamicEvaluationSteps.find(step => step.key === stepName)
    if (step) {
      step.feedback = feedback
    }
  }

  @action updateDynamicEvaluationApprovers(stepName, approvers) {
    const step = this.dynamicEvaluationSteps.find(step => step.key === stepName)
    if (step) {
      step.approvers = approvers
    }
  }

  @action updateDynamicEvaluationFiles(stepName, files) {
    const step = this.dynamicEvaluationSteps.find(step => step.key === stepName)
    if (step) {
      step.files = files
    }
  }

  @action updateDynamicEvaluationUserList(stepName, userList) {
    const step = this.dynamicEvaluationSteps.find(step => step.key === stepName)
    if (step) {
      step.userList = userList
    }
  }

  @action setDescription(value) {
    this.description = value
  }

  @action setShoppingCouponType(value) {
    this.shoppingCouponType = value;
  }

  @action setApraiserTask(task) {
    this.apraiserTasks = task
  }

  @action setShoppingTasks(tasks) {
    this.shoppingTasks = tasks
  }

  @action setPaymentTasks(tasks) {
    this.paymentTasks = tasks
  }

  @action setRequestAttachments(attachments) {
    this.requestAttachments = attachments
  }

  @action setApproveAttachments(attachments) {
    this.approveAttachments = attachments
  }

  @action setSubApraiserAttachments(step, attachments) {
    this.subApraiserAttachments[step] = attachments
  }

  @action setShoppingAttachments(attachments) {
    this.shoppingAttachments = attachments
  }

  @action setPaymentAttachments(attachments) {
    this.paymentAttachments = attachments
  }

  @action addUploadedFile(file) {
    this.uploadedFiles.push(file)
  }

  @action removeUploadedFile(file) {
    this.uploadedFiles = this.uploadedFiles.filter(f => f !== file)
  }

  @action setSelectedTasksStep1(tasks) {
    this.selectedTasksStep1 = tasks
  }

  @action setSelectedTasksStep2(tasks) {
    this.selectedTasksStep2 = tasks
  }

  @action setSelectedTasksStep3(tasks) {
    this.selectedTasksStep3 = tasks
  }

  @action getAllDataShoppingCoupon() {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.getShoppingAllCouponList()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getAllShoppingCoupon(payload) {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.getShoppingCouponList(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getAllShoppingCouponV2(payload) {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.getShoppingCouponListV2(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getShoppingCouponDetail = sCode => {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.getShoppingCouponDetail(sCode)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createShoppingCoupon(payload) {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.createShoppingCoupon(payload)
        .then(response => {
          if (response.data.type === 'CREATED') {
            commandStore.incrementPendingShoppingRecord()
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateShoppingCoupon(code, data) {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.updateShoppingCoupon(code, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action changeTypeProcurment(code) {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.changeType(code)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteShoppingCoupon = sCode => {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.deleteShoppingCoupon(sCode)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action rejectShoppingCoupon = sCode => {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.rejectShoppingCoupon(sCode)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateWork(payload, approveCode, procurementCode) {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.updateWork(payload, approveCode, procurementCode)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createFeedback(payload) {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.createFeedback(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @observable feedbackPurchaseList = []
  @observable feedbackPaymentList = []

  @action async getFeedbackList(payload) {
    try {
      const response = await ShoppingCouponRequest.getFeedbackList(payload)
      if (payload.type === 'BUY') {
        this.feedbackPurchaseList = response.data
      } else {
        this.feedbackPaymentList = response.data
      }
      return response
    } catch (error) {
      throw error
    }
  }

  @action clearFeedbackList = () => {
    this.feedbackPurchaseList = []
    this.feedbackPaymentList = []
  }

  @action deleteFeedback = id => {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.deleteFeedback(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteAttachment = code => {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.deleteAttachment(code)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clearFormCreate = () => {
    this.requestAttachments = []
    this.approveAttachments = []
    this.shoppingAttachments = []
    this.paymentAttachments = []
    this.description = ''
    this.subApraiserAttachments = {}
  }

  @action sendNotificationFollower(procurementCode, data) {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.sendNotificationFollower(procurementCode, data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action sendNotification(data) {
    return new Promise((resolve, reject) => {
      ShoppingCouponRequest.sendNotification(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new ShoppingCouponStore()
