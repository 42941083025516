import React, { useEffect, useLayoutEffect, useRef } from 'react'
import useImage from 'use-image'
import { Image, Transformer } from 'react-konva'

const RectangleBox = props => {
  const { shapeProps, isSelected, onChange, urlImg, scale = 1 } = props // scale is the zoom level
  const [image] = useImage(urlImg)
  const shapeRef = useRef()
  const trRef = useRef()

  useEffect(() => {
    if (isSelected) {
      trRef.current.setNode(shapeRef.current)
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected])

  useLayoutEffect(() => {
    shapeRef.current.cache()
  }, [shapeProps, image, isSelected])

  return (
    <>
      <Image
        image={image}
        ref={shapeRef}
        {...shapeProps}
        width={shapeProps.width * scale} // Apply scale factor to width
        height={shapeProps.height * scale} // Apply scale factor to height
        x={shapeProps.x * scale} // Apply scale factor to x position
        y={shapeProps.y * scale} // Apply scale factor to y position
        draggable={true}
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x() / scale, // Revert to original position without scale
            y: e.target.y() / scale,
          })
        }}
        onTransformEnd={e => {
          const node = shapeRef.current
          const scaleX = node.scaleX()
          const scaleY = node.scaleY()
          node.scaleX(1)
          node.scaleY(1)

          onChange({
            ...shapeProps,
            x: node.x() / scale,
            y: node.y() / scale,
            width: Math.max(5, node.width() * scaleX) / scale, // Revert width without scale
            height: Math.max(5, node.height() * scaleY) / scale,
          })
        }}
        opacity={0.8}
      />
      {isSelected && (
        <Transformer
          enabledAnchors={[
            'top-left',
            'top-right',
            'bottom-left',
            'bottom-right',
          ]}
          rotateEnabled={false}
          ref={trRef}
          anchorSize={7}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
    </>
  )
}

export default RectangleBox
