// Other
import { inject, observer } from 'mobx-react'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Link, useHistory, withRouter } from 'react-router-dom'
import { WorkPageWrapper } from './TextShoppingCouponStylePage'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { runInAction } from 'mobx'
// Ant design
import {
  Button,
  Menu,
  message,
  Modal,
  Col,
  Form,
  Input,
  Row,
  Select,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
// Component
import PageTitle from '../../components/PageTitle'

// Other
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'
import ShoppingCouponTable from './ShoppingCouponTable'
import ShoppingCouponCreate from './ShoppingCouponCreate'
import ShoppingCouponStore from '../../stores/ShoppingCouponStore'
import selectPeopleStore from '../../stores/selectPeopleStore'

const ManagementAssetWarehousePage = props => {
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false)
  const [refreshData, setRefreshData] = useState(false)
  const handleToggleCreateModal = bool => event => {
    setIsVisibleCreateModal(bool)
    ShoppingCouponStore.clearFormCreate()
    selectPeopleStore.clearSelected()
  }

  const handleCreateSuccess = () => {
    setRefreshData(true)
    selectPeopleStore.clearSelected()
  }

  return (
    // isVisibleDrawerWork={isVisibleDrawerWork}
    <WorkPageWrapper>
      <DashboardLayout>
        <Helmet>
          <title>Quy trình mua sắm | Công việc</title>
        </Helmet>
        <PageTitle location={props.location}>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__QUY_TRINH_MUA_SAM}>
            <Button
              type={'primary'}
              icon={<PlusOutlined />}
              onClick={handleToggleCreateModal(true)}>
              Tạo mới
            </Button>
          </AccessControlAction>
        </PageTitle>
        <ContentBlockWrapper>
          {/* onSearch={isVisibleCreateModal} */}
          <ShoppingCouponTable
            isVisibleCreateModal={isVisibleCreateModal}
            refreshData={refreshData}
            {...props}
          />
        </ContentBlockWrapper>
      </DashboardLayout>
      <Modal
        width={800}
        title={'Tạo mới quy trình mua sắm'}
        visible={isVisibleCreateModal}
        maskClosable={false}
        onCancel={handleToggleCreateModal(false)}
        footer={null}
        destroyOnClose>
        {isVisibleCreateModal && (
          <ShoppingCouponCreate
            onCancel={handleToggleCreateModal(false)}
            onSuccess={handleCreateSuccess}
          />
        )}
      </Modal>
    </WorkPageWrapper>
  )
}

export default inject(
  'loadingAnimationStore',
  'authenticationStore',
  'notificationStore',
  'selectPeopleStore',
  'managementAssetWarehouseStore'
)(observer(ManagementAssetWarehousePage))
