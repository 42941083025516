import { CloseOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Form,
  Modal,
  Select,
  Space,
  TreeSelect,
  message,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import SelectListComponentRisk from '../../components/Common/SelectListComponentRisk'
import SelectUserButton from '../../components/Common/SelectUserButton'
import RichEditor from '../../components/RichEditor/RichEditor'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import { systemName } from '../../config'
import { ASSIGNEE_TYPE } from '../../constants'
import utils from '../../utils'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import { SelectPeopleWrapper } from '../WorkPage/PopupVote/PopupVoteStyled'

const RiskControlResult = ({
  handleCancel,
  isVisible,
  actionStore,
  riskStore,
  getRiskDetail,
  selectUserList,
  selectPeopleStore,
  handleVisibleSelectOneUserPopup,
  departmentWithUserList,
  type,
}) => {
  const { riskDetail } = riskStore
  const {
    indexDetailAction,
    dataActions,
    setIndexDetailAction,
    setDataActions,
    createAction,
    updateAction,
    deleteAction,
  } = actionStore
  const {
    handleOpenSelectUserDepartmentPopup,
    setSelectUserData,
    selectUserData,
    dataRiskUnitsByType,
  } = selectPeopleStore
  const [form] = Form.useForm()
  const [isRenderRichText, setIsRenderRichText] = useState(false)
  const OPINION_REF = useRef()
  let II_xu_ly_rui_ro = {}

  if (actionStore?.dataActions?.tier1?.length > 0) {
    II_xu_ly_rui_ro = actionStore?.dataActions?.tier1[0]?.II_xu_ly_rui_ro
  }

  const { SHOW_ALL } = TreeSelect

  const treeData_NDDPV = Array.isArray(riskStore.riskUnitsV2)
    ? riskStore.riskUnitsV2.map(item => ({
        title: item.name,
        value: item.code,
        key: item.code,
        children: item.unit_users.map(user => ({
          title: user.full_name,
          value: user.username,
          key: user.username,
        })),
      }))
    : []

  const treeData_DVPT = Array.isArray(riskStore.riskUnitsV2)
    ? riskStore.riskUnitsV2
        .filter(item => item.unit_users.length > 0)
        .map(item => ({
          title: item.name,
          value: item.code,
          key: item.code,
          children: item.unit_users.map(user => ({
            title: user.full_name,
            value: user.username,
            key: user.username,
          })),
        }))
    : []

  const treeData1 = dataRiskUnitsByType?.map(item => ({
    title: item.name,
    value: item.code,
    key: item?.code,
    children: item.unit_users.map(user => ({
      title: user.full_name,
      value: user.user_code,
      key: user?.user_code,
    })),
  }))

  const treeData2 = departmentWithUserList?.map(item => ({
    title: item.name,
    value: item.code,
    key: item?.code,
    children: item.users.map(user => ({
      title: utils.getNameInCapitalize(user.name_uppercase),
      value: user.user_code,
      key: user?.user_code,
    })),
  }))

  const [treeData_CQVP, setTreeData] = useState([])

  useEffect(() => {
    const updatedTreeData = treeData2
      .concat(treeData1)
      .filter(item => item !== undefined)
    setTreeData(updatedTreeData)
  }, [departmentWithUserList])

  const [labelSelect, setLabelSelect] = useState('Ý kiến:')

  const handleTreeSelectChange = value => {
    if (value.length > 0) {
      setLabelSelect('Ý kiến của:')
    } else {
      setLabelSelect('Ý kiến:')
    }
  }

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsRenderRichText(true)
      }, 500)
    } else {
      setIsRenderRichText(false)
    }
  }, [isVisible])

  useEffect(() => {
    const fillData =
      dataActions?.tier4.length > 0 ? dataActions?.tier4[0] : null
    form.setFieldsValue({
      don_vi_thuc_hien_xu_ly_rui_ro:
        dataActions?.tier1.length > 0
          ? dataActions?.tier1[0]?.II_xu_ly_rui_ro.don_vi_thuc_hien_xu_ly_rui_ro
          : null,
    })

    if (!fillData || indexDetailAction?.tier !== '4') {
      return
    }
    setSelectUserData({
      ...selectUserData,
      [ASSIGNEE_TYPE.ban_pc_qtrr_ban_kt_ktnb_vimc]:
        fillData.ban_pc_qtrr_ban_kt_ktnb_vimc,
      [ASSIGNEE_TYPE.ban_dieu_hanh_hdqt_vimc]: fillData.ban_dieu_hanh_hdqt_vimc,
    })
    form.setFieldsValue({
      ket_qua: fillData.ket_qua,
      ban_dieu_hanh_hdqt_vimc_signed: fillData.ban_dieu_hanh_hdqt_vimc_signed,
      ban_pc_qtrr_ban_kt_ktnb_vimc_signed:
        fillData.ban_pc_qtrr_ban_kt_ktnb_vimc_signed,
      nguoi_dang_y_kien: fillData.nguoi_dang_y_kien,
    })
  }, [indexDetailAction])

  const handleCancelModal = () => {
    handleCancel()
  }
  const handleCreateRiskControlResult = async value => {
    const submitData = {
      ...value,
      ban_pc_qtrr_ban_kt_ktnb_vimc: selectUserList.ban_pc_qtrr_ban_kt_ktnb_vimc,
      ban_dieu_hanh_hdqt_vimc: selectUserList.ban_dieu_hanh_hdqt_vimc,
      cac_y_kien_lien_quan: OPINION_REF.current?.editor.getData(),
      nguoi_dang_y_kien: value.nguoi_dang_y_kien,
      ban_dieu_hanh_hdqt_vimc_signed:
        checked_bdh_hd === true ? value.ban_dieu_hanh_hdqt_vimc_signed : null,
      ban_pc_qtrr_ban_kt_ktnb_vimc_signed:
        checked_bdh_hdqt === true
          ? value.ban_pc_qtrr_ban_kt_ktnb_vimc_signed
          : null,
    }
    if (riskDetail?.tiers) {
      if (riskDetail?.tiers[3]?.actions[0]) {
        await updateAction(riskDetail?.tiers[3]?.actions[0]?.code, {
          name: 'Rủi ro nhận diện 4 update',
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[3]?.code,
          risk_type: type,
        })
      } else {
        await createAction({
          ...submitData,
          name: 'ignore',
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[3]?.code,
        })
      }
      message.success('Lưu thành công')
      handleCancelModal()
      getRiskDetail()
    } else {
      if (dataActions.tier4[0]) {
        dataActions.tier4[0] = submitData
        setDataActions({ ...dataActions, tier4: [...dataActions?.tier4] })
      } else {
        setDataActions({
          ...dataActions,
          tier4: [submitData],
        })
      }
      // setDataActions({ ...dataActions, tier4: [submitData] })
      message.success('Lưu thành công')
      handleCancelModal()
    }
  }
  const [checked_bdh_hdqt, setChecked_bdh_hdqt] = useState(false)
  const [checked_bdh_hd, setChecked_bdh_hd] = useState(false)
  const [disabled_bdh_hdqt, setDisabled_bdh_hdqt] = useState(true)
  const [disabled_bdh_hd, setDisabled_bdh_hd] = useState(true)
  useEffect(() => {
    if (
      dataActions?.tier4[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc_signed === '(Đã ký)'
    ) {
      setChecked_bdh_hdqt(true)
    }
    if (dataActions?.tier4[0]?.ban_dieu_hanh_hdqt_vimc_signed === '(Đã ký)') {
      setChecked_bdh_hd(true)
    }
  }, [dataActions])

  const onChangeCheckedBdhHdqt = e => {
    setChecked_bdh_hdqt(e.target.checked)
  }

  const onChangeCheckedBdhHd = e => {
    setChecked_bdh_hd(e.target.checked)
  }
  useEffect(() => {
    if (selectUserList.ban_dieu_hanh_hdqt_vimc.length > 0) {
      setDisabled_bdh_hd(false)
    } else {
      setChecked_bdh_hd(false)
      setDisabled_bdh_hd(true)
    }
  }, [selectUserList.ban_dieu_hanh_hdqt_vimc.length])
  useEffect(() => {
    if (selectUserList.ban_pc_qtrr_ban_kt_ktnb_vimc.length > 0) {
      setDisabled_bdh_hdqt(false)
    } else {
      setChecked_bdh_hdqt(false)
      setDisabled_bdh_hdqt(true)
    }
  }, [selectUserList.ban_pc_qtrr_ban_kt_ktnb_vimc.length])
  return (
    <Modal
      width={800}
      destroyOnClose={true}
      afterClose={() => {
        setIsRenderRichText(false)
      }}
      zIndex={500}
      style={{ top: 40, zIndex: 500 }}
      visible={isVisible}
      maskClosable={false}
      onCancel={() => {
        handleCancelModal()
      }}
      footer={null}>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={handleCreateRiskControlResult}
        preserve={false}>
        <h3>V. Kết quả kiểm soát rủi ro </h3>

        <Form.Item
          name={'ket_qua'}
          label={<h3>1. Kết quả</h3>}
          style={{ width: '50%' }}>
          <Select placeholder={'Chọn kết quả'}>
            <Select.Option value="1">
              a/ Rủi ro ở mức không cần xử lý (theo dõi)
            </Select.Option>
            <Select.Option value="2">b/ Rủi ro đã được xử lý</Select.Option>
            <Select.Option value="3">
              c/ Rủi ro đã xảy ra chưa được xử lý
            </Select.Option>
          </Select>
        </Form.Item>
        <h3>2. Các ý kiến liên quan </h3>
        <Form.Item name={'cac_y_kien_lien_quan'} label={labelSelect}>
          <div>
            {type === 'NDDPV' ? (
              <Form.Item name={'nguoi_dang_y_kien'}>
                <TreeSelect
                  treeData={treeData_NDDPV}
                  style={{ width: '50%', marginBottom: '10px' }}
                  showArrow
                  allowClear
                  showSearch
                  filterTreeNode
                  treeNodeFilterProp={'title'}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_ALL}
                  treeCheckStrictly={true}
                  placeholder={'Chọn người đăng ý kiến'}
                  onChange={handleTreeSelectChange}
                />
              </Form.Item>
            ) : (
              <>
                {type === 'DVPT' ? (
                  <Form.Item name={'nguoi_dang_y_kien'}>
                    <TreeSelect
                      treeData={treeData2}
                      style={{ width: '50%', marginBottom: '10px' }}
                      showArrow
                      allowClear
                      showSearch
                      filterTreeNode
                      treeNodeFilterProp={'title'}
                      treeCheckable={true}
                      showCheckedStrategy={SHOW_ALL}
                      treeCheckStrictly={true}
                      placeholder={'Chọn người đăng ý kiến'}
                      onChange={handleTreeSelectChange}
                    />
                  </Form.Item>
                ) : (
                  <div>
                    <Form.Item name={'nguoi_dang_y_kien'}>
                      <TreeSelect
                        // treeDataSimpleMode
                        treeData={treeData_CQVP}
                        style={{ width: '50%', marginBottom: '10px' }}
                        showArrow
                        allowClear
                        showSearch
                        filterTreeNode
                        treeNodeFilterProp={'title'}
                        treeCheckable={true}
                        showCheckedStrategy={SHOW_ALL}
                        treeCheckStrictly={true}
                        placeholder={'Chọn người đăng ý kiến'}
                        onChange={handleTreeSelectChange}
                      />
                    </Form.Item>
                  </div>
                )}
              </>
            )}
          </div>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={OPINION_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={
                dataActions?.tier4.length > 0
                  ? dataActions?.tier4[0]?.cac_y_kien_lien_quan
                  : null
              }
            />
          )}
          {/* <Input placeholder={'Nhập nội dung'} /> */}
        </Form.Item>
        <Form.Item
          name={'don_vi_thuc_hien_xu_ly_rui_ro'}
          label={'ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO:'}>
          <div
            style={{
              fontStyle: 'italic',
              fontSize: '14px',
              marginLeft: 8,
              margin: '0 20px',
            }}
            className="textValue">
            {II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro__signed}
            {II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro?.map(
              (el, index) => (
                <div key={index}>{el.label}</div>
              )
            )}
          </div>
        </Form.Item>

        <SelectPeopleWrapper>
          {selectUserList?.ban_pc_qtrr_ban_kt_ktnb_vimc?.some(
            item => item.department_code === 'BPC&QTRR'
          ) ? (
            <>
              <p>BAN PC&QTRR:</p>
              <Form.Item name={'ban_pc_qtrr_ban_kt_ktnb_vimc_signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_bdh_hdqt}
                    disabled={disabled_bdh_hdqt}
                    onChange={e => onChangeCheckedBdhHdqt(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <SelectUserButton
                style={{ marginTop: 16 }}
                // title="BAN PC&QTRR:"
                tooltipTitle="Chọn người"
                handleOnClick={() => {
                  handleVisibleSelectOneUserPopup()
                  handleOpenSelectUserDepartmentPopup(
                    ASSIGNEE_TYPE.ban_pc_qtrr_ban_kt_ktnb_vimc
                  )
                }}
              />
            </>
          ) : selectUserList?.ban_pc_qtrr_ban_kt_ktnb_vimc?.some(
              item => item.department_code === 'BKTKTNB'
            ) ? (
            <>
              <p>BAN KTNB {systemName}:</p>
              <Form.Item name={'ban_pc_qtrr_ban_kt_ktnb_vimc_signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_bdh_hdqt}
                    disabled={disabled_bdh_hdqt}
                    onChange={e => onChangeCheckedBdhHdqt(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <SelectUserButton
                style={{ marginTop: 16 }}
                // title="BAN KTNB VIMC:"
                tooltipTitle="Chọn người"
                handleOnClick={() => {
                  handleVisibleSelectOneUserPopup()
                  handleOpenSelectUserDepartmentPopup(
                    ASSIGNEE_TYPE.ban_pc_qtrr_ban_kt_ktnb_vimc
                  )
                }}
              />
            </>
          ) : (
            <>
              <p>BAN PC&QTRR/BAN KTNB {systemName}:</p>
              <Form.Item name={'ban_pc_qtrr_ban_kt_ktnb_vimc_signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_bdh_hdqt}
                    disabled={disabled_bdh_hdqt}
                    onChange={e => onChangeCheckedBdhHdqt(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <SelectUserButton
                style={{ marginTop: 16 }}
                // title="BAN PC&QTRR/BAN KTNB VIMC:"
                tooltipTitle="Chọn người"
                handleOnClick={() => {
                  handleVisibleSelectOneUserPopup()
                  handleOpenSelectUserDepartmentPopup(
                    ASSIGNEE_TYPE.ban_pc_qtrr_ban_kt_ktnb_vimc
                  )
                }}
              />
            </>
          )}
          <SelectListComponentRisk
            userList={selectUserList.ban_pc_qtrr_ban_kt_ktnb_vimc}
          />
        </SelectPeopleWrapper>

        <SelectPeopleWrapper>
          {selectUserList?.ban_dieu_hanh_hdqt_vimc?.some(
            item => item.department_code === 'BDH'
          ) ? (
            <>
              <p>BAN ĐIỀU HÀNH:</p>
              <Form.Item name={'ban_dieu_hanh_hdqt_vimc_signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_bdh_hd}
                    disabled={disabled_bdh_hd}
                    onChange={e => onChangeCheckedBdhHd(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <SelectUserButton
                style={{ marginTop: 16 }}
                // title="BAN ĐIỀU HÀNH:"
                tooltipTitle="Chọn người"
                handleOnClick={() => {
                  handleVisibleSelectOneUserPopup()
                  handleOpenSelectUserDepartmentPopup(
                    ASSIGNEE_TYPE.ban_dieu_hanh_hdqt_vimc
                  )
                }}
              />
            </>
          ) : selectUserList?.ban_dieu_hanh_hdqt_vimc?.some(
              item => item.department_code === 'HDQT'
            ) ? (
            <>
              <p>BAN HĐQT {systemName}:</p>
              <Form.Item name={'ban_dieu_hanh_hdqt_vimc_signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_bdh_hd}
                    disabled={disabled_bdh_hd}
                    onChange={e => onChangeCheckedBdhHd(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <SelectUserButton
                style={{ marginTop: 16 }}
                // title="BAN HĐQT VIMC:"
                tooltipTitle="Chọn người"
                handleOnClick={() => {
                  handleVisibleSelectOneUserPopup()
                  handleOpenSelectUserDepartmentPopup(
                    ASSIGNEE_TYPE.ban_dieu_hanh_hdqt_vimc
                  )
                }}
              />
            </>
          ) : (
            <>
              <p>BAN ĐIỀU HÀNH/HĐQT {systemName}:</p>
              <Form.Item name={'ban_dieu_hanh_hdqt_vimc_signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_bdh_hd}
                    disabled={disabled_bdh_hd}
                    onChange={e => onChangeCheckedBdhHd(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <SelectUserButton
                style={{ marginTop: 16 }}
                // title="BAN ĐIỀU HÀNH/HĐQT VIMC:"
                tooltipTitle="Chọn người"
                handleOnClick={() => {
                  handleVisibleSelectOneUserPopup()
                  handleOpenSelectUserDepartmentPopup(
                    ASSIGNEE_TYPE.ban_dieu_hanh_hdqt_vimc
                  )
                }}
              />
            </>
          )}
          <SelectListComponentRisk
            userList={selectUserList.ban_dieu_hanh_hdqt_vimc}
          />
        </SelectPeopleWrapper>
        <FormActionFooter style={{ marginBottom: 0, marginRight: 0 }}>
          <Button icon={<CloseOutlined />} danger onClick={handleCancelModal}>
            Đóng
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            type={'primary'}
            htmlType={'submit'}>
            Lưu thông tin
          </Button>
        </FormActionFooter>
      </Form>
    </Modal>
  )
}

export default inject(
  'riskStore',
  'actionStore',
  'selectPeopleStore'
)(observer(RiskControlResult))
