import { action, observable, runInAction } from 'mobx'
import { WorkRequest } from '../requests/WorkRequest'
import { ISO_DATE_FORMAT, WORK_STATUS } from '../constants'
import * as moment from 'moment'
import { UserGroupRequest } from '../requests/UserGroupRequest'
import { clone } from 'lodash'
import ManagementAssetWarehouseRequest from '../requests/ManagementAssetWarehouseRequest'
import AssetTypeRequest from '../requests/AssetTypeRequest'
import { apiUrl } from '../config'
import { message } from 'antd'
import axios from 'axios'
import authenticationStore from '../stores/authenticationStore'

class managementAssetWarehouseStore {
  @observable managementAssetWarehouseDetail = []
  @observable managementAssetWarehouseList = []
  @observable managementAssetWarehouseLists = []
  @observable managementAssetWarehouseType = []
  @observable managementAssetTypes = []
  @observable managementAssetWarehouseTypes = []
  @observable managementAssetWarehouseTypesOrigin = []
  @observable managementAssetWarehouseAssetUnits = []
  @observable managementAssetWarehouseAllLists = []
  @observable managementAssetWarehouseHistoryList = []

  // filter
  @observable assetName = null
  @observable description = null
  @observable assetTypeId = undefined
  @observable createdAt = undefined
  @observable pageIndex = 0
  @observable sizeIndex = 10
  @observable totalCount = null
  @observable totalAssetTypes = null

  @action clearFilter = () => {
    this.assetName = null
    this.assetTypeId = undefined
    this.createdAt = undefined
    this.pageIndex = 0
    this.pageSize = 10
    this.totalCount = null
  }
  @action setQueryParams = (param, value) => {
    this[param] = value
  }

  @action setQueryParams1 = (param, value) => {
    this[param] = value
  }
  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }

  @action setManagementAssetWarehousesList = payload => {
    this.authorityIssuedsList = payload
  }

  // @ts-ignore
  @observable managementAssetWarehouseDetail = {
    amount: 0,
    asset_name: null,
    asset_type: {
      description: null,
      id: null,
    },
    tax: null,
    price: null,
    total_money: null,
    // asset_unit: {
    //   description: null,
    //   id: null
    // },
    asset_unit_name: null,
    create_at: null,
    id: null,
    property: null,
  }
  @action clearManagementAssetWarehouseDetail = () => {
    this.managementAssetWarehouseDetail = {
      // @ts-ignore
      amount: 0,
      asset_name: null,
      asset_type: {
        description: null,
        id: null,
      },
      tax: null,
      price: null,
      total_money: null,
      // asset_unit: {
      //   description: null,
      //   id: null
      // },
      asset_unit_name: null,
      create_at: null,
      id: null,
      property: null,
    }
  }
  @action clearManagementAssetWarehousetList = () => {
    this.managementAssetWarehouseList.length = 0
  }

  @action getManagementAssetWarehousetList = param => {
    return new Promise((resolve, reject) => {
      ManagementAssetWarehouseRequest.getManagementAssetWarehouseList(
        `page=${this.pageIndex}&size=${this.sizeIndex}${
          this.assetName ? `&asset_name=${this.assetName}` : ''
        }${this.assetTypeId ? `&asset_type_id=${this.assetTypeId}` : ''}${
          this.createdAt ? `&createdAt=${this.createdAt}` : ''
        }`
      )
        .then(response => {
          this.managementAssetWarehouseList = response.data.data
          this.totalCount = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getManagementAssetWarehousetLists = param => {
    return new Promise((resolve, reject) => {
      ManagementAssetWarehouseRequest.getManagementAssetWarehouseList(
        `page=0&size=50000${
          this.assetName ? `&asset_name=${this.assetName}` : ''
        }${this.assetTypeId ? `&asset_type_id=${this.assetTypeId}` : ''}${
          this.createdAt ? `&createdAt=${this.createdAt}` : ''
        }`
      )
        .then(response => {
          this.managementAssetWarehouseLists = response.data.data
          this.totalCount = response.data.total_count
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action fetchManagementAssetTypeList = async (search = '') => {
    try {
      const appToken = JSON.parse(authenticationStore.appToken).access_token
      const response = await axios.get(
        `${apiUrl}/api/v1/asset-type/list?page=${this.pageIndex}&size=${this.sizeIndex}&description=${search}`,
        {
          headers: {
            Authorization: `Bearer ${appToken}`,
          },
        }
      )
      runInAction(() => {
        this.managementAssetTypes = response.data.content
        this.totalAssetTypes = response.data.totalElements
      })
    } catch (error) {
      console.error(error)
      message.error('Đã có lỗi xảy ra!')
    }
  }

  @action getManagementAssetWarehousetDetail = code => {
    return new Promise((resolve, reject) => {
      ManagementAssetWarehouseRequest.getManagementAssetWarehouseDetail(code)
        .then(response => {
          this.managementAssetWarehouseDetail = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getManagementAssetWarehouseAllList = () => {
    return new Promise((resolve, reject) => {
      ManagementAssetWarehouseRequest.getManagementAssetWarehouseAllList()
        .then(response => {
          this.managementAssetWarehouseAllLists = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createManagementAssetWarehouse = payload => {
    return new Promise((resolve, reject) => {
      ManagementAssetWarehouseRequest.createManagementAssetWarehouse(payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action updateManagementAssetWarehouse = (code, payload) => {
    return new Promise((resolve, reject) => {
      ManagementAssetWarehouseRequest.updateManagementAssetWarehouse(
        code,
        payload
      )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  @action delete = code => {
    return ManagementAssetWarehouseRequest.deleteManagementAssetWarehouse(code)
  }

  // list getAssetTypes
  @action getManagementAssetWarehouseAssetTypes = () => {
    return new Promise((resolve, reject) => {
      ManagementAssetWarehouseRequest.typeManagementAssetWarehouseList(
        `${this.description ? `&description=${this.description}` : ''}`
      )
        .then(response => {
          this.managementAssetWarehouseTypes = response.data
          this.managementAssetWarehouseTypesOrigin = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getAssetTypeById = id => {
    return new Promise((resolve, reject) => {
      AssetTypeRequest.getAssetTypeById(id)
        .then(response => {
          this.managementAssetWarehouseType = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  removeDiacritics(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  @action async searchAssetByName(name) {
    const normalizedSearchTerm = this.removeDiacritics(name.toLowerCase())
    runInAction(() => {
      this.managementAssetWarehouseTypes = this.managementAssetWarehouseTypesOrigin.filter(
        asset =>
          this.removeDiacritics(asset.description.toLowerCase()).includes(
            normalizedSearchTerm
          )
      )
    })
  }

  @action createAssetType = payload => {
    return new Promise((resolve, reject) => {
      AssetTypeRequest.createAssetType(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateAssetType = (id, payload) => {
    return new Promise((resolve, reject) => {
      AssetTypeRequest.updateAssetType(id, payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action deleteAssetType = id => {
    return AssetTypeRequest.deteleAssetType(id)
  }

  // list getAssetTypes
  @action getManagementAssetWarehouseUnit = () => {
    return new Promise((resolve, reject) => {
      ManagementAssetWarehouseRequest.unitManagementAssetWarehouseList()
        .then(response => {
          this.managementAssetWarehouseAssetUnits = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // Tạo lịch sử quản lý kho - tài sản
  @action createManagementAssetHistoryWarehouse = payload => {
    return new Promise((resolve, reject) => {
      ManagementAssetWarehouseRequest.createManagementAssetHistoryWarehouse(
        payload
      )
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action getManagementAssetWareHouseHistoryList = param => {
    return new Promise((resolve, reject) => {
      ManagementAssetWarehouseRequest.getManagementAssetWareHouseHistory(
        `asset_id=${param.assetId}&year=${param.year}`
      )
        .then(response => {
          this.managementAssetWarehouseHistoryList = response.data
          resolve(response)
        })
        .catch(error => {
          console.error('API error:', error)
          reject(error)
        })
    })
  }
}

export default new managementAssetWarehouseStore()
