import ExcelJS from 'exceljs'
import fileDownload from 'js-file-download'
import { toJS } from 'mobx'
import moment from 'moment'

export const exportAssetWarehouse = async record => {
  const sortedData = record.sort((a, b) =>
    a.asset_type.description.localeCompare(b.asset_type.description)
  )

  try {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Quản lý kho')

    worksheet.getColumn('A').width = 10 //STT
    worksheet.getColumn('B').width = 35 // tên văn phòng phẩm
    worksheet.getColumn('C').width = 30 // đặc tính
    worksheet.getColumn('D').width = 30 // đơn vị tính
    worksheet.getColumn('E').width = 10 // thuế
    worksheet.getColumn('F').width = 30 // đơn giá trước thuế
    worksheet.getColumn('G').width = 30 // đơn giá sau thuế
    worksheet.getColumn('H').width = 30 // số lượng trong kho
    worksheet.getColumn('I').width = 30 // ngày tạo

    worksheet.mergeCells('A1:I1')
    worksheet.getCell('A1').value = 'QUẢN LÝ KHO'
    worksheet.getCell('A1').alignment = {
      vertical: 'middle',
      horizontal: 'center',
    }
    worksheet.getCell('A1').font = { size: 14, bold: true }

    worksheet.addRow('')

    const headerName = [
      'STT',
      'TÊN VĂN PHÒNG PHẨM',
      'ĐẶC TÍNH',
      'ĐƠN VỊ TÍNH',
      'THUẾ',
      'ĐƠN GIÁ TRƯỚC THUẾ',
      'ĐƠN GIÁ SAU THUẾ',
      'SỐ LƯỢNG TRONG KHO',
      'NGÀY TẠO',
    ]
    worksheet.addRow(headerName)
    const borderStyle = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    }

    headerName.forEach((_, colIndex) => {
      // @ts-ignore
      worksheet.getCell(3, colIndex + 1).border = borderStyle
      worksheet.getRow(3).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(3).font = { bold: true }
    })

    const formatterNumber = val => {
      if (!val) return '0'
      return `${val}`
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        .replace(/\.(?=\d{0,3}$)/g, '.')
    }

    let currentIndex = 1
    let currentGroup = ''
    let groupTotal = 0

    Object.values(sortedData).forEach((group, index) => {
      // Kiểm tra nhóm hiện tại
      if (currentGroup !== group.asset_type.description) {
        // if (index > 0) {
        //   const totalRow = worksheet.addRow([
        //     '',
        //     `Tổng:`,
        //     '',
        //     '',
        //     '',
        //     '',
        //     formatterNumber(groupTotal.toFixed(2)),
        //   ])
        //   totalRow.eachCell(cell => {
        //     cell.font = { bold: true }
        //   })
        // }

        const groupRow = worksheet.addRow([group.asset_type.description])
        groupRow.eachCell(cell => {
          cell.font = { bold: true }
        })

        currentGroup = group.asset_type.description
        groupTotal = 0
      }

      const data = [
        currentIndex,
        group?.asset_name,
        group?.property ?? '----',
        group?.asset_unit_name,
        group?.tax ? `${group?.tax} %` : '----',
        group?.price ? formatterNumber(Number(group?.price)) : 0,
        group?.total_money ? formatterNumber(Number(group?.total_money)) : 0,
        group?.amount ? formatterNumber(Number(group?.amount)) : 0,
        group?.create_at
          ? moment.utc(group?.create_at).format('DD/MM/YYYY')
          : '----',
      ]
      groupTotal += Number(group.total_money)

      const rowItem = worksheet.addRow(data)
      rowItem.eachCell({ includeEmpty: true }, cell => {
        // @ts-ignore
        cell.border = borderStyle
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
        }
      })

      currentIndex++
    })
    // const totalRows = worksheet.addRow([
    //   '',
    //   `Tổng:`,
    //   '',
    //   '',
    //   '',
    //   '',
    //   formatterNumber(groupTotal.toFixed(2)),
    // ])
    // totalRows.eachCell(cell => {
    //   cell.font = { bold: true }
    // })
    const date = new Date()
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    const filename = `Quan_ly_kho_${day}_${month}_${year}.xlsx`
    const buffer = await workbook.xlsx.writeBuffer()
    fileDownload(buffer, filename)
  } catch (error) {
    console.log('export full list data kho', error)
  }
}
