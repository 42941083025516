import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Dropdown, Menu, message, Modal, Spin } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { blue } from '../../color'
import { TitleContentBox } from '../../components/Common/CellText'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import RichText from '../../components/RichEditor/RichText'
import useOpenItem from '../../hooks/useOpenItem'
import { Container } from '../../layouts/Container/Container'
import DashboardLayout from '../../layouts/DashboardLayout'
import utils from '../../utils'
import { DocumentInfoList } from '../ConnectedCommitteeDocumentIncomingDocDetailPage/ConnectedCommitteeDocumentIncomingDocDetailPageStyled'
import { HeadingButtonWrapper } from '../MissionDetailPage/MissionDetailPageStyled'
import ReportPdf from './ReportPdf'
import { systemName } from '../../config'
import ReportUpdateModal from './ReportUpdateModal'
import ReportDetailUpdateModal from './ReportDetailUpdateModal'
import { RiskDetail } from '../RiskDetailPage/RiskDetailPageStyled'
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'
const { confirm } = Modal
const ReportDetailPage = props => {
  const {
    reportStore,
    riskStore,
    companyStore,
    departmentStore,
    loadingAnimationStore,
    userStore,
    selectPeopleStore,
    authenticationStore,
    history,
    aclStore,
  } = props
  const {
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    isVisibleSelectUserDepartmentPopup,
    setSelectUserData,
    selectUserData,
    departmentWithUserList,
    getDepartmentWithUsers,
    getDepartmentWithUsersLive,
  } = selectPeopleStore
  const { isAccountAdmin, isSuperAdmin } = authenticationStore
  const { reportDetail, getReportDetail, type, deleteReport } = reportStore
  const { reportId } = useParams()
  const { riskUnits, riskUnitsV2 } = riskStore
  const { getCompanyList, companyList } = companyStore
  const { departmentList } = departmentStore
  const [unitData, setUnitData] = useState([])
  const [departData, setDepartData] = useState([])
  useEffect(() => {
    setIsLoading(true)
    riskStore
      .getRiskUnitsV3()
      .then(responseUnit => {
        setUnitData(responseUnit.data)
        return departmentStore.getAllDepartmentListV2()
      })
      .then(responseDepart => {
        setDepartData(responseDepart.data)
      })
      .catch(error => {
        console.log(error)
        message.error(error.vi || 'Đã có lỗi xảy ra!')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const [depattData, setDepattData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDepartmentWithUsers('CPN7451091748209')
        setDepattData(response.data)
      } catch (error) {
        console.log(error)
        message.error(error.vi || 'Đã có lỗi xảy ra!')
      }
    }

    fetchData()
  }, [])
  const [dataRiskUnitsByType, setRiskUnitsByType] = useState([])
  const [dataRiskUnitsByTypeNDDPV, setRiskUnitsByTypeNDDPV] = useState([])

  const getRiskUnitsByTypeList = async type => {
    await riskStore.getRiskUnitsV2(type).then(response => {
      setRiskUnitsByType(response.data)
    })
  }
  const getRiskUnitsByTypeListNDDPV = async type => {
    await riskStore.getRiskUnitsV2(type).then(response => {
      setRiskUnitsByTypeNDDPV(response.data)
    })
  }

  const treeData = Array.isArray(riskUnitsV2)
    ? riskUnitsV2.map(item => ({
        title: item.name || '',
        value: item.code || '',
        key: item.code || '',
        children: Array.isArray(item.unit_users)
          ? item.unit_users.map(user => ({
              title:
                utils && utils.getNameInCapitalize
                  ? utils.getNameInCapitalize(user.full_name)
                  : user.full_name || '',
              value: user.user_code || '',
              key: user.user_code || '',
              position_name: user.position_name || '',
            }))
          : [],
      }))
    : []

  const treeData1 = dataRiskUnitsByType?.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.unit_users.map(user => ({
      title: user.full_name,
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))
  const treeData2 = depattData?.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.users.map(user => ({
      title: utils.getNameInCapitalize(user.name_uppercase),
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))
  const treeData3 = dataRiskUnitsByTypeNDDPV?.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item?.unit_users?.map(user => ({
      title: user.full_name,
      value: user.user_code,
      key: user.user_code,
      position_name: user.position_name,
    })),
  }))

  const treeDataDp = departmentWithUserList.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.users.map(user => ({
      title: utils.getNameInCapitalize(user.name_uppercase),
      value: user.user_code,
      key: user.user_code,
    })),
  }))

  const finalTreeData = [
    ...treeData,
    ...treeData1,
    ...treeData2,
    ...treeData3,
    ...treeDataDp,
  ]

  const getFullName = sign => {
    const user = finalTreeData.map(item => item.children).map(el => el)
    const sortedUsers = user.flat().sort((a, b) => {
      if (a.position_name < b.position_name) return -1
      if (a.position_name > b.position_name) return 1
      if (a.title < b.title) return -1
      if (a.title > b.title) return 1
      return 0
    })

    const fullname = sortedUsers.find(el => el.value === sign)
    return fullname?.title
  }

  const {
    isOpen: isLoading,
    handleOpen: visibleLoading,
    handleClose: cancelLoading,
  } = useOpenItem()
  const getRiskDetail = () => {
    visibleLoading()
    ;(async () => {
      try {
        await getReportDetail(reportId)
        await riskStore.getRiskUnits()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        cancelLoading()
      }
    })()
  }

  const loadReportList = async (page, size, params) => {
    try {
      setIsLoading(true)
      const response = await getReportLists({
        ...params,
        page: page - 1,
        size: size,
        type,
      })
      setTotalReports(response.data.totalPages)
    } finally {
      setIsLoading(false)
    }
  }

  const [filter, setFilter] = useState({
    keyword: '',
    status: true,
  })
  useEffect(() => {
    getRiskDetail()
  }, [reportId])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [editReport, setEditReport] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [isLoading1, setIsLoading] = useState(false)

  const handleEditReport = reportData => {
    setEditReport(reportData)
    setModalVisible(true)
  }

  // Hàm đóng modal và làm mới dữ liệu
  const handleCancel = () => {
    setModalVisible(false)
    setEditReport(null)
  }
  const loadReportDetail = async () => {
    try {
      setIsLoading(true)
      await getReportDetail(reportId)
    } finally {
      setIsLoading(false)
    }
  }

  const showConfirmDelete = () => {
    confirm({
      title: 'Bạn có muốn xóa báo cáo này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteReport(reportDetail.code)
      },
    })
  }
  const handleDeleteReport = async code => {
    try {
      setIsLoading(true)
      await deleteReport(code)

      const params = new URLSearchParams({
        type,
        page: String(1),
        size: String(10),
      })
      history.replace(`/report?${params.toString()}`)

      message.success('Đã xóa!')
    } catch (err) {
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  const menuHandleDocument = (
    <Menu>
      {(isSuperAdmin ||
        isAccountAdmin ||
        authenticationStore.currentUser?.username ===
          reportDetail?.create_user ||
        aclStore.checkAccessControlAction(
          ACL_ACTION_TYPE.update__baocao__PKS_RUI_RO
        )) && (
        <Menu.Item
          key={'update'}
          create_user
          icon={<EditOutlined style={{ color: blue }} />}
          onClick={() => handleEditReport(reportDetail)}>
          <span style={{ color: blue }}>Chỉnh sửa</span>
        </Menu.Item>
      )}
      <Menu.Item
        key="print"
        icon={<PrinterOutlined style={{ color: blue }} />}
        onClick={() => {
          utils.exportPDF(
            'reportDivToPrint',
            reportDetail.title,
            'TimesNewRoman'
          )
        }}>
        <span style={{ color: blue }}>In</span>
      </Menu.Item>
      <AccessControlAction
        aclActionType={ACL_ACTION_TYPE.delete__baocao__PKS_RUI_RO}>
        <Menu.Item danger onClick={showConfirmDelete} icon={<DeleteOutlined />}>
          Xóa
        </Menu.Item>
      </AccessControlAction>
    </Menu>
  )
  function capitalizeEachWord(string) {
    if (typeof string !== 'string' || string.length === 0) return string
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  function uperCaseEachWord(string) {
    if (typeof string !== 'string' || string.length === 0) return string
    return string
      .split(' ')
      .map(word => word.toUpperCase())
      .join(' ')
  }

  function countLetters(name) {
    let letterCount = 0

    // Regex to match Vietnamese characters
    const vietnameseLetters = /[a-zA-Zàáảãạâầấẩẫậăằắẳẵặèéẻẽẹêềếểễệìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵđ]/

    for (const char of String(name?.toLowerCase())) {
      if (vietnameseLetters.test(char)) {
        letterCount++
      }
    }
    return letterCount
  }
  function countWords(name) {
    if (!name) return 0
    return String(name).trim().split(/\s+/).length
  }
  const letterCount = countLetters(getFullName(reportDetail.sign_user))
  const wordCount = countWords(getFullName(reportDetail.sign_user))

  function getPositionSign(positionValue) {
    switch (positionValue) {
      case 'Trưởng ban'.toLowerCase():
        return 55
      case 'Phó Trưởng ban'.toLowerCase():
        return 40
      case 'Giám đốc TT CNTT'.toLowerCase():
        return 30
      case 'Giám đốc chi nhánh'.toLowerCase():
        return 20
      case 'Giám đốc công ty'.toLowerCase():
        return 30
      case 'Phó Giám Đốc TT CNTT'.toLowerCase():
        return 15
      case 'Phó Giám Đốc chi nhánh'.toLowerCase():
        return 0
      case 'Phó Giám Đốc công ty'.toLowerCase():
        return 10
      case 'Chánh Văn phòng cơ quan'.toLowerCase():
        return 0
      case 'Phó chánh Văn phòng cơ quan'.toLowerCase():
        return 0
      default:
        return 0
    }
  }

  function getPositionIsSign(positionValue) {
    switch (positionValue) {
      case 'Trưởng ban'.toLowerCase():
        return 90
      case 'Phó Trưởng ban'.toLowerCase():
        return 90
      case 'Giám Đốc TT CNTT'.toLowerCase():
        return 90
      case 'Giám Đốc chi nhánh'.toLowerCase():
        return 90
      case 'Giám Đốc công ty'.toLowerCase():
        return 90
      case 'Phó Giám Đốc TT CNTT'.toLowerCase():
        return 90
      case 'Phó Giám Đốc chi nhánh'.toLowerCase():
        return 90
      case 'Phó Giám Đốc công ty'.toLowerCase():
        return 90
      case 'Chánh Văn phòng cơ quan'.toLowerCase():
        return 100
      case 'Phó chánh Văn phòng cơ quan'.toLowerCase():
        return 120
      default:
        return 0
    }
  }
  const isPhoChanh =
    String(reportDetail.position_sign).toLowerCase() ===
    'Phó chánh Văn phòng cơ quan'.toLowerCase()

  const isChanhVanPhong =
    String(reportDetail.position_sign).toLowerCase() ===
    'Chánh Văn phòng cơ quan'.toLowerCase()

  const baseMargin = isPhoChanh ? 30 : 0
  const baseMargin2 = isChanhVanPhong ? 10 : 0

  const marginLeftValue = (() => {
    if (wordCount === 4 && letterCount <= 12) return 60
    if (wordCount === 3) {
      if (letterCount >= 15) return 50
      if (letterCount <= 8) return 75
      return 60
    }
    if (letterCount >= 13 && letterCount <= 17 && wordCount <= 3) return 60
    return 30
  })()
  const ReportTable = ({
    bgColor = '',
    color = 'black',
    borderColor = 'black',
    isExportPdf = false,
  }) => (
    <>
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          marginTop: isExportPdf ? 16 : 0,
        }}>
        <thead>
          <tr style={{ background: bgColor, color: color }}>
            <th
              style={{
                paddingTop: 12,
                paddingBottom: 12,
                borderRadius: '10px 0 0 0px',
                width: '5%',
              }}>
              <p>TT</p>
            </th>
            <th
              style={{
                paddingTop: 12,
                paddingBottom: 12,
                width: '40%',
              }}>
              <p style={{ textAlign: 'center', margin: 0 }}>NỘI DUNG BÁO CÁO</p>
            </th>
            <th
              style={{
                paddingTop: 12,
                paddingBottom: 12,
                borderRadius: '0 10px 0 0',
                width: '55%',
              }}>
              <p style={{ textAlign: 'center', margin: 0 }}>
                DIỄN GIẢI NỘI DUNG BÁO CÁO
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                verticalAlign: 'top',
                border: `1px solid ${borderColor}`,
                padding: 20,
              }}>
              <p>1</p>
            </td>
            <td
              style={{
                verticalAlign: 'top',
                border: `1px solid ${borderColor}`,
                padding: 20,
              }}>
              <p>
                Báo cáo kết quả thực hiện công tác Quản trị rủi ro: (Áp dụng các
                nguyên tắc QTRR một cách có hệ thống trong tất cả các hoạt động
                kinh doanh của Đơn vị. Tuân thủ Chính sách, Quy trình quản lý
                rủi ro đã được phê duyệt và các quy định pháp luật khác)
              </p>
              <p>- Những kết quả đạt được</p>
              <p>- Những kết quả chưa đạt được</p>
              <p>- Khó khăn, vướng mắc</p>
              <p>- Kiến nghị</p>
            </td>
            <td
              style={{
                verticalAlign: 'top',
                border: `1px solid ${borderColor}`,
                padding: 20,
              }}>
              <RichText htmlText={reportDetail.section1}></RichText>
            </td>
          </tr>
          <tr>
            <td
              style={{
                verticalAlign: 'top',
                border: `1px solid ${borderColor}`,
                padding: 20,
              }}>
              <p>2</p>
            </td>
            <td
              style={{
                verticalAlign: 'top',
                border: `1px solid ${borderColor}`,
                padding: 20,
              }}>
              <p>Các rủi ro xảy ra trong kỳ báo cáo đã được xử lý</p>
              <p>- Nêu các rủi ro</p>
              <p>- Hậu quả do rủi ro xảy ra</p>
              <p>- Biện pháp đã xử lý, tình hình khắc phục</p>
              <p>- Kiến nghị</p>
            </td>
            <td
              style={{
                verticalAlign: 'top',
                border: `1px solid ${borderColor}`,
                padding: 20,
              }}>
              <RichText htmlText={reportDetail.section2}></RichText>
            </td>
          </tr>
          <tr>
            <td
              style={{
                verticalAlign: 'top',
                border: `1px solid ${borderColor}`,
                padding: 20,
              }}>
              <p>3</p>
            </td>
            <td
              style={{
                verticalAlign: 'top',
                border: `1px solid ${borderColor}`,
                padding: 20,
              }}>
              <p>
                Các rủi ro xảy ra trong kỳ báo cáo chưa được xử lý - Nêu các rủi
                ro
              </p>
              <p>- Nguyên nhân, lý do</p>
              <p>- Các tồn tại, vướng mắc</p>
            </td>
            <td
              style={{
                verticalAlign: 'top',
                border: `1px solid ${borderColor}`,
                padding: 20,
              }}>
              <RichText htmlText={reportDetail.section3}></RichText>
            </td>
          </tr>
          <tr>
            <td
              style={{
                verticalAlign: 'top',
                border: `1px solid ${borderColor}`,
                padding: 20,
              }}>
              <p>4</p>
            </td>
            <td
              style={{
                verticalAlign: 'top',
                border: `1px solid ${borderColor}`,
                padding: 20,
              }}>
              <p>
                Nội dung phát hiện trong kỳ báo cáo: Có phát sinh rủi ro so với
                Nhận diện rủi ro đầu năm
              </p>
              <p>- Nêu rủi ro phát sinh</p>
              <p>- Hậu quả do rủi ro phát sinh gây ra</p>
              <p>- Biện pháp đã xử lý, tình hình khắc phục</p>
              <p>- Giải pháp phòng ngừa</p>
              <p>- Kiến nghị</p>
            </td>
            <td
              style={{
                verticalAlign: 'top',
                border: `1px solid ${borderColor}`,
                padding: 20,
              }}>
              <RichText htmlText={reportDetail.section4}></RichText>
            </td>
          </tr>
        </tbody>
        {isExportPdf && (
          <tfoot style={{ position: 'relative' }}>
            <tr
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}>
              <td
                colSpan="3"
                style={{
                  border: 'none',
                  margin: '20px 0 0 380px',
                  display: 'inline-block',
                }}>
                <div>
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: 20,
                      marginBottom: 0,
                      display: 'inline-block',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        marginLeft: `${getPositionSign(
                          String(reportDetail.position_sign).toLowerCase()
                        )}px`,
                        fontWeight: 'bold',
                        fontSize: 16,
                        display: 'block',
                      }}>
                      {uperCaseEachWord(reportDetail.position_sign)}
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: 13,
                      marginBottom: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        marginTop:
                          reportDetail?.is_sign !== true ? '30px' : '10px',
                        marginBottom:
                          reportDetail?.is_sign !== true ? '30px' : '10px',
                        marginLeft: `${getPositionIsSign(
                          String(reportDetail.position_sign).toLowerCase()
                        )}px`,
                        fontStyle: 'italic',
                      }}>
                      {reportDetail?.is_sign === true ? '(Đã ký)' : ' '}
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      marginBottom: 0,
                    }}>
                    <div
                      style={{
                        fontSize: 14,
                        display: 'block',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        marginLeft: `${
                          marginLeftValue + baseMargin + baseMargin2
                        }px`,
                        alignItems: 'center',
                      }}>
                      {capitalizeEachWord(getFullName(reportDetail.sign_user))}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </>
  )
  useEffect(() => {
    ;(async () => {
      if (!companyList) {
        getCompanyList()
      }
    })()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        await Promise.all([
          getRiskUnitsByTypeListNDDPV('NDDPV'),
          departmentStore.getDepartmentList(),
          getRiskUnitsByTypeList('DVPT'),
        ])
      } catch (error) {
        console.log(error)
        message.error(error.vi || 'Đã có lỗi xảy ra!')
      }
      setIsLoading(false)
    }

    fetchData()
  }, [])

  const finalData2 = [...unitData, ...departData]
  function formatDate(dateString) {
    return dateString?.replace(/-/g, '/')
  }

  const dataUnit = reportDetail.risk_dependent_code?.map(code =>
    companyList.filter(item => item.code === code)
  )
  const dataUnitArray = dataUnit?.flatMap(subArray => subArray)
  const riskUnit = reportDetail.risk_dependent_code
    ?.map((items, index, array) => {
      return (
        <span key={index}>
          {riskUnits.find(item => item.code === items)?.name}
        </span>
      )
    })
    .filter(name => name !== undefined)

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Chi tiết báo cáo | {systemName} chi tiết báo cáo</title>
        </Helmet>
        <PageTitle
          showTitle={true}
          location={props.location}
          title={reportDetail?.title}>
          <HeadingButtonWrapper>
            <Dropdown
              overlayStyle={{ minWidth: 100 }}
              overlay={menuHandleDocument}
              trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined style={{ color: blue }} />
              </span>
            </Dropdown>
          </HeadingButtonWrapper>
        </PageTitle>
        <div style={{ marginBottom: 15 }} />
        <ContentBlockWrapper style={{ position: 'relative', minHeight: 600 }}>
          <Container maxWidth={1000}>
            <Spin tip="Đang tải dữ liệu..." spinning={isLoading}>
              <div style={{ paddingLeft: 16 }}>
                {reportDetail.cqvp_code && reportDetail.cqvp_code.length > 0 && (
                  <h3>
                    <b>Đơn vị thuộc Cơ quan Văn phòng Tổng công ty:</b>{' '}
                    {uperCaseEachWord(
                      departData.find(
                        item => item.code === reportDetail.cqvp_code
                      )?.name
                    )}
                  </h3>
                )}
                {reportDetail.dvpt_code && reportDetail.dvpt_code.length > 0 && (
                  <h3>
                    <b>Đơn vị phụ thuộc VIMC:</b>{' '}
                    {uperCaseEachWord(
                      unitData.find(
                        item => item.code === reportDetail.dvpt_code
                      )?.name
                    )}
                  </h3>
                )}
                {reportDetail.nddpv_code && reportDetail.nddpv_code.length > 0 && (
                  <h3>
                    <b>DNTV của VIMC:</b>{' '}
                    {uperCaseEachWord(
                      unitData.find(
                        item => item.code === reportDetail.nddpv_code
                      )?.name
                    )}
                  </h3>
                )}
                <h3>
                  <b>Ngày nộp báo cáo:</b>{' '}
                  {formatDate(reportDetail.report_submit_date)}
                </h3>
                <h3>
                  <b>Kỳ báo cáo:</b>{' '}
                  {formatDate(reportDetail.report_period_start)}
                  {' - '}
                  {formatDate(reportDetail.report_period_end)}
                </h3>
              </div>

              <ContentBlockWrapper>
                <ReportTable
                  bgColor={blue}
                  color="white"
                  borderColor="#d9d9d9"
                />
              </ContentBlockWrapper>
              <div style={{ height: 10 }}></div>
              {reportDetail && (
                <>
                  <div
                    style={{
                      width: '90%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}>
                      <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {uperCaseEachWord(reportDetail.position_sign)}
                      </div>
                      <p
                        style={{
                          marginTop:
                            reportDetail?.is_sign !== true ? '30px' : '5px',
                          marginBottom:
                            reportDetail?.is_sign !== true ? '30px' : '5px',
                          fontSize: '13px',
                          fontStyle: 'italic',
                        }}>
                        {reportDetail?.sign_user?.length > 0 &&
                          (reportDetail?.is_sign ? '(Đã ký)' : ' ')}
                      </p>
                      <p
                        style={{
                          fontSize: '13px',
                          fontWeight: '600',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        {capitalizeEachWord(
                          getFullName(reportDetail.sign_user)
                        )}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </Spin>
            {/* <h3> */}
            {/* <b>Đơn vị phụ thuộc VIMC:</b>{' '} */}
            {/* {reportDetail.risk_dependent_code
                  ?.map(items => {
                    return riskUnits.find(item => item.code === items)?.name
                  })
                  .filter(name => name !== undefined)
                  .join(', ')} */}
            {/* {riskUnit} */}
            {/* {dataUnitArray && riskUnit ? <>, </> : <></>} */}
            {/* {dataUnitArray?.map((items, index, array) => { */}
            {/* return ( */}
            {/* <span key={index}> */}
            {/* {utils.getNameInCapitalize(items.name)} */}
            {/* {index < array?.length - 1 ? ', ' : ''} */}
            {/* </span> */}
            {/* ) */}
            {/* })} */}
            {/* </h3> */}
          </Container>
        </ContentBlockWrapper>
      </DashboardLayout>{' '}
      <div style={{ display: 'none' }}>
        <ReportPdf
          unit={
            riskUnits.find(item => item.code === reportDetail.risk_unit_code)
              ?.name
          }
          cqvp={
            departData.find(item => item.code === reportDetail.cqvp_code)?.name
          }
          dvpt={
            unitData.find(item => item.code === reportDetail.dvpt_code)?.name
          }
          nddpv={
            unitData.find(item => item.code === reportDetail.nddpv_code)?.name
          }
          report_submit_date={reportDetail.report_submit_date}
          report_period_start={reportDetail.report_period_start}
          report_period_end={reportDetail.report_period_end}
          // riskDetail={riskDetail}
          // dataActions={dataActions}
          // createUser={F
          //   riskDetail?.tiersF
          //     ? utils.getInfoUser(
          //         riskDetail?.tiers[0].approvals[0].peoples[0]?.username,
          //         originDepartmentWithUserList
          //       )
          //     : ''
          // }
          // selectUserList={selectUserList}
        >
          <ReportTable isExportPdf={true} />
        </ReportPdf>
      </div>
      <ReportDetailUpdateModal
        modalVisible={modalVisible}
        onCancel={handleCancel}
        editReport={editReport}
        onRefresh={loadReportDetail}
      />
    </>
  )
}

export default inject(
  'reportStore',
  'riskStore',
  'companyStore',
  'departmentStore',
  'loadingAnimationStore',
  'userStore',
  'selectPeopleStore',
  'authenticationStore',
  'aclStore'
)(observer(ReportDetailPage))
