// Other
import { inject, observer } from 'mobx-react'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Link, useHistory, withRouter } from 'react-router-dom'
import { WorkPageWrapper } from './ManagementAssetWarehouseStylePage'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { runInAction } from 'mobx'
import ManagementAssetWarehouseTable from './ManagementAssetWarehouseTable'
// Ant design
import {
  Button,
  Menu,
  message,
  Modal,
  Col,
  Form,
  Input,
  Row,
  Select,
} from 'antd'
import { PlusOutlined, FileExcelOutlined } from '@ant-design/icons'
import ManagerInventoryTemplateList from '../ManagerInventoryPage/ManagerInventoryTemplateList'
// Component
import PageTitle from '../../components/PageTitle'

// Other
import AccessControlAction from '../../components/AccessControlAction'
import ManagementAssetWarehouseCreate from './ManagementAssetWarehouseCreate'
import { ACL_ACTION_TYPE } from '../../constants'
import { exportAssetWarehouse } from './ExportManagementAssetWarehouseExcel'
import { toJS } from 'mobx'
import { IoStorefrontOutline } from 'react-icons/io5'
import ManagementAssetWarehouseUpdate from './ManagementAssetWarehouseUpdate'

const ManagementAssetWarehousePage = props => {
  const { managementAssetWarehouseStore } = props
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [checkImportStore, setCheckImportStore] = useState(false)

  const handleToggleCreateModal = bool => event => {
    setIsVisibleCreateModal(bool)
  }

  const exportBetRefundExcel = async () => {
    try {
      await managementAssetWarehouseStore.setFilter('pageIndex', 0)
      await managementAssetWarehouseStore.setFilter('sizeIndex', 999999)
      await managementAssetWarehouseStore.getManagementAssetWarehousetList()
      await exportAssetWarehouse(
        managementAssetWarehouseStore.managementAssetWarehouseList
      )
    } catch (error) {
      console.log(error)
    } finally {
      await managementAssetWarehouseStore.setFilter('sizeIndex', 10)
    }
  }

  const handleOpenUpdateModal = id => {
    setCheckImportStore(!checkImportStore)
    setIsVisibleUpdateModal(true)
    setSelectedTemplateId(id)
  }
  const handleCancelUpdateModal = () => {
    setIsVisibleUpdateModal(false)
    setCheckImportStore(!checkImportStore)
  }

  return (
    // isVisibleDrawerWork={isVisibleDrawerWork}
    <WorkPageWrapper>
      <DashboardLayout>
        <Helmet>
          <title>Hành chính | Quản lý kho | Tài sản</title>
        </Helmet>

        <PageTitle location={props.location}>
          <AccessControlAction check={true}>
            <div>
              <Button
                disabled={selectedRowKeys?.length > 0 ? false : true}
                style={{ marginRight: '10px' }}
                type={'primary'}
                icon={
                  <span style={{ marginRight: 8 }}>
                    <IoStorefrontOutline />
                  </span>
                }
                onClick={() => handleOpenUpdateModal(selectedRowKeys[0])}>
                Nhập kho
              </Button>
              <Button
                style={{ marginRight: '10px' }}
                icon={<FileExcelOutlined />}
                onClick={() => exportBetRefundExcel()}>
                Xuất excel (.xlsx)
              </Button>
              <Button
                type={'primary'}
                icon={<PlusOutlined />}
                onClick={handleToggleCreateModal(true)}>
                Tạo mới
              </Button>
            </div>
          </AccessControlAction>
        </PageTitle>
        <ContentBlockWrapper>
          <Menu
            mode="horizontal"
            selectedKeys={[props.location?.pathname]}
            style={{ marginBottom: 16 }}>
            {/* <Menu.Item key="/manager-inventory">
              <Link to={'/manager-inventory'}>Cấp duyệt</Link>
            </Menu.Item> */}
            {/* </AccessControlAction> */}
            <Menu.Item key="/manager-inventory/inventory-asset">
              <Link to={'/manager-inventory/inventory-asset'}>Tài sản</Link>
            </Menu.Item>

            <Menu.Item key="/manager-inventory/manage-inventory-asset">
              <Link to={'/manager-inventory/manage-inventory-asset'}>
                Loại văn phòng phẩm
              </Link>
            </Menu.Item>
          </Menu>

          {/* onSearch={isVisibleCreateModal} */}
          <ManagementAssetWarehouseTable
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        </ContentBlockWrapper>
      </DashboardLayout>
      <Modal
        width={700}
        title={'Tạo mới tài sản'}
        visible={isVisibleCreateModal}
        maskClosable={false}
        onCancel={handleToggleCreateModal(false)}
        footer={null}
        destroyOnClose>
        {isVisibleCreateModal && (
          <ManagementAssetWarehouseCreate
            onCancel={handleToggleCreateModal(false)}
          />
        )}
      </Modal>
      <Modal
        width={700}
        title={'Nhập kho'}
        visible={isVisibleUpdateModal}
        maskClosable={false}
        onCancel={handleCancelUpdateModal}
        footer={null}
        destroyOnClose>
        {isVisibleUpdateModal && (
          <ManagementAssetWarehouseUpdate
            onCancel={handleCancelUpdateModal}
            templateId={selectedTemplateId}
            checkImportStore={checkImportStore}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        )}
      </Modal>
    </WorkPageWrapper>
  )
}

export default inject(
  'managementAssetWarehouseStore',
  'loadingAnimationStore',
  'authenticationStore',
  'notificationStore',
  'selectPeopleStore'
)(observer(ManagementAssetWarehousePage))
