import React from 'react'
import { Select, Tabs } from 'antd'
import { inject, observer } from 'mobx-react'
import { ASSIGNEE_TYPE, PROPOSAL_STATUS } from '../../constants'
import ProposalAdvanceInputFilter from './ProposalAdvanceInputFilter'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

const { TabPane } = Tabs
const { Option } = Select
const ProposalAdvanceFilter = props => {
  const { proposalAdvanceStore } = props
  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)
  const onChangeTab = key => {
    proposalAdvanceStore.setQueryParams('filterPage', 0)
    proposalAdvanceStore.setQueryParams(
      'filterProposalPermission',
      key !== 'ALL' ? key : null
    )
  }

  const handleChangeStatus = tab => {
    proposalAdvanceStore.setQueryParams('filterPage', 0)
    proposalAdvanceStore.setQueryParams('filterProposalStatus', tab)
  }
  return (
    <>
      <Tabs
        defaultActiveKey={queryStringParse.proposal_permission || 'ALL'}
        onChange={onChangeTab}>
        <TabPane tab="Tất cả" key="ALL" />
        <TabPane tab="Đã gửi" key={ASSIGNEE_TYPE.CREATE} />
        <TabPane tab="Xử lý" key={ASSIGNEE_TYPE.HANDLER} />
        <TabPane tab="Theo dõi" key={ASSIGNEE_TYPE.FOLLOWER} />
      </Tabs>
      <div
        style={{
          display: 'flex',
          gap: 10,
          marginBottom: 14,
        }}>
        <ProposalAdvanceInputFilter />
        <Select
          value={proposalAdvanceStore.filterProposalStatus}
          style={{ minWidth: '170px' }}
          name={'proposal_status'}
          placeholder={'Lọc theo trạng thái'}
          allowClear
          onChange={handleChangeStatus}>
          <Option value={PROPOSAL_STATUS.PENDING}>Chờ phê duyệt</Option>
          <Option value={PROPOSAL_STATUS.APPROVAL}>Đã phê duyệt</Option>
          <Option value={PROPOSAL_STATUS.REJECT}>Từ chối</Option>
        </Select>
      </div>
    </>
  )
}

ProposalAdvanceFilter.propTypes = {}

export default inject('proposalAdvanceStore')(observer(ProposalAdvanceFilter))
