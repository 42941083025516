import { Divider } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { systemName } from '../../config'
import utils from '../../utils'
import './RiskActionDetails.css'
import { RiskActionStyle } from './RiskActionDetailsStyled'
import { useLocation } from 'react-router-dom'

const RiskActionDetails3 = ({ data }) => {
  const labelBold = { fontWeight: 600 }
  const textValue = { fontStyle: 'italic', fontSize: '14px', marginLeft: 8 }

  const location = useLocation()
  const type = location?.search?.split('=')[1]

  const styleTable = {
    width: '100%',
    backgroundColor: 'white',
    margin: '16px 0 50px',
  }

  const [dataPtt3, setDataPtt3] = useState(null)
  const [data3, setData3] = useState(null)
  useEffect(() => {
    if (data.length > 0) {
      setDataPtt3(data[0]?.IV_ket_qua_ra_soat_rui_ro)
      setData3(data[0])
    }
  }, [data])

  const [ban_kt_ktnb_vimc_ptd, setBKTV] = useState(null)
  const [ban_dieu_hanh_ptd, setBDH] = useState(null)
  const [hdqt_vimc_ptd, setHDQT] = useState(null)

  useEffect(() => {
    if (dataPtt3?.ban_kt_ktnb_vimc.length > 0) {
      setBKTV(dataPtt3?.ban_kt_ktnb_vimc?.[0])
    }
    if (dataPtt3?.ban_dieu_hanh.length > 0) {
      setBDH(dataPtt3?.ban_dieu_hanh?.[0])
    }
    if (dataPtt3?.hdqt_vimc.length > 0) {
      setHDQT(dataPtt3?.hdqt_vimc?.[0])
    }
  }, [dataPtt3])

  const firstAgree =
    dataPtt3?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai
  const secondAgree =
    dataPtt3?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_ban_dh
  const thirdAgree =
    dataPtt3?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai_hdqt

  const kien_nghi_giai_phap_khac_phuc = dataPtt3?.kien_nghi_giai_phap_khac_phuc
  const ban_kt_ktnb_vimc = dataPtt3?.ban_kt_ktnb_vimc

  const kien_nghi_giai_phap_khac_phuc_ban_dh =
    dataPtt3?.kien_nghi_giai_phap_khac_phuc_ban_dh
  const ban_dieu_hanh = dataPtt3?.ban_dieu_hanh

  const kien_nghi_giai_phap_khac_phuc_hdqt =
    dataPtt3?.kien_nghi_giai_phap_khac_phuc_hdqt

  const hdqt_vimc = dataPtt3?.hdqt_vimc

  const ban_ktnb_vimc_signed = dataPtt3?.ban_ktnb_vimc_signed
  const ban_dieu_hanh_signed = dataPtt3?.ban_dieu_hanh_signed
  const hdqt_vimc_signed = dataPtt3?.hdqt_vimc_signed

  return (
    <RiskActionStyle>
      <h6
        // style={
        //   (isPartOfPdf && {
        //     marginLeft: 24,
        //     fontStyle: 'italic ',
        //     marginTop: 8,
        //   }) ||
        //   {}
        // }
        style={{
          marginLeft: 24,
          fontStyle: 'italic ',
          marginTop: 8,
        }}>
        Đối tượng thực hiện:
      </h6>
      <p style={{ display: 'flex' }}>
        <span>- Tổng giám đốc, các Phó Tổng giám đốc:&nbsp;</span>
        {data3?.doi_tuong_thuc_hien.tgd_ptgd ? (
          <span style={{ ...textValue, ...labelBold }}>
            {data3?.doi_tuong_thuc_hien.tgd_ptgd.map((item, index, array) => {
              const fullName = utils.getNameInCapitalize(item.full_name)
              return (
                <span key={index} style={{ display: 'block' }}>
                  {fullName}
                  <span style={{ margin: '0 3px' }}>-</span>
                  <span>{item.position_name}</span>
                  {index !== array.length - 1 && <span>, </span>}
                </span>
              )
            })}
          </span>
        ) : (
          <span></span>
        )}
      </p>
      <p style={{ display: 'flex' }}>
        <span>
          {/* (2) ĐVPT của VIMC (trong trường hợp ĐVPT thực hiện công việc): */}
          - Ban KTNB (tham mưu đánh giá độc lập cho HĐQT):&nbsp;
        </span>
        {data3?.doi_tuong_thuc_hien.ban_kt_ktnb ? (
          <span style={{ ...textValue, ...labelBold }}>
            {data3?.doi_tuong_thuc_hien?.ban_kt_ktnb.map(
              (item, index, array) => {
                const fullName = utils.getNameInCapitalize(item.full_name)
                return (
                  <span key={index} style={{ display: 'block' }}>
                    {fullName}
                    <span style={{ margin: '0 3px' }}>-</span>
                    <span>{item.position_name}</span>
                    {index !== array.length - 1 && <span>, </span>}
                  </span>
                )
              }
            )}
          </span>
        ) : (
          <span></span>
        )}
      </p>
      <Divider />
      {type === 'DVPT' ? (
        <h6>V. KẾT QUẢ RÀ SOÁT RỦI RO</h6>
      ) : (
        <h6>IV. KẾT QUẢ RÀ SOÁT RỦI RO</h6>
      )}
      <div>
        <h6 style={{ fontSize: '14px' }}>
          {' '}
          1. Đồng ý/không đồng ý với đánh giá rủi ro/xử lý rủi ro ở tầng phòng
          vệ thứ nhất và thứ hai:&nbsp;
        </h6>
        <p style={{ ...textValue, ...labelBold }}>{firstAgree}</p>

        <p style={{ ...textValue, ...labelBold }}>{secondAgree}</p>

        <p style={{ ...textValue, ...labelBold }}>{thirdAgree}</p>
      </div>

      <div>
        <div>
          {/* {isPartOfPdf ? (
            <h6 style={{ fontSize: '14px' }}>
              2. Kiến nghị giải pháp khắc phục:&nbsp;
            </h6>
          ) : (
            <span>2. Kiến nghị giải pháp khắc phục:&nbsp;</span>
          )} */}
          <h6 style={{ fontSize: '14px' }}>
            2. Kiến nghị giải pháp khắc phục:&nbsp;
          </h6>
        </div>
        <p>
          <span>
            2.1 Kiến nghị giải pháp khắc phục BAN KTNB {systemName}:&nbsp;
          </span>
          {kien_nghi_giai_phap_khac_phuc ? (
            <>
              {ban_kt_ktnb_vimc_ptd &&
              kien_nghi_giai_phap_khac_phuc.trim() != '' ? (
                <span style={{ ...textValue, ...labelBold }}>
                  Kiến nghị của ông/bà{' '}
                  {utils.getNameInCapitalize(ban_kt_ktnb_vimc_ptd?.full_name)}:{' '}
                  {kien_nghi_giai_phap_khac_phuc}
                </span>
              ) : (
                <span style={{ ...textValue, ...labelBold }}>
                  {kien_nghi_giai_phap_khac_phuc}
                </span>
              )}
            </>
          ) : (
            <></>
          )}
        </p>

        <p>
          <span>2.2 Kiến nghị giải pháp khắc phục BAN ĐIỀU HÀNH:&nbsp;</span>
          {kien_nghi_giai_phap_khac_phuc_ban_dh ? (
            <>
              {ban_dieu_hanh_ptd &&
              kien_nghi_giai_phap_khac_phuc_ban_dh.trim() != '' ? (
                <span style={{ ...textValue, ...labelBold }}>
                  Kiến nghị của ông/bà{' '}
                  {utils.getNameInCapitalize(ban_dieu_hanh_ptd?.full_name)}:{' '}
                  {kien_nghi_giai_phap_khac_phuc_ban_dh}
                </span>
              ) : (
                <span style={{ ...textValue, ...labelBold }}>
                  {kien_nghi_giai_phap_khac_phuc_ban_dh}
                </span>
              )}
            </>
          ) : (
            <></>
          )}
        </p>

        <p>
          <span>
            2.3 Kiến nghị giải pháp khắc phục HĐQT {systemName} (CT/TV
            HĐQT):&nbsp;
          </span>
          {kien_nghi_giai_phap_khac_phuc_hdqt ? (
            <>
              {' '}
              {hdqt_vimc_ptd &&
              kien_nghi_giai_phap_khac_phuc_hdqt.trim() != '' ? (
                <span style={{ ...textValue, ...labelBold }}>
                  Kiến nghị của ông/bà{' '}
                  {utils.getNameInCapitalize(hdqt_vimc_ptd?.full_name)}:{' '}
                  {kien_nghi_giai_phap_khac_phuc_hdqt}
                </span>
              ) : (
                <span style={{ ...textValue, ...labelBold }}>
                  {kien_nghi_giai_phap_khac_phuc_hdqt}
                </span>
              )}
            </>
          ) : (
            <></>
          )}
        </p>
      </div>

      <table style={styleTable}>
        <tbody>
          <tr style={{ backgroundColor: 'white' }}>
            <td
              style={{
                border: 'none',
                margin: '0 36px ',
                backgroundColor: 'white',
                textAlign: 'center',
              }}>
              <div>
                <b> BAN KTNB {systemName}</b>
                <div>{ban_ktnb_vimc_signed}</div>
                <div>
                  {ban_kt_ktnb_vimc ? (
                    <div>
                      <div
                        style={{
                          fontSize: '13px',
                          marginTop: ban_ktnb_vimc_signed ? '5px' : '65px',
                        }}>
                        {ban_kt_ktnb_vimc_ptd?.position_name}
                      </div>
                      <div>
                        {utils.getNameInCapitalize(
                          ban_kt_ktnb_vimc_ptd?.full_name
                        )}
                      </div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
            </td>
            <td
              style={{
                border: 'none',
                margin: '0 36px ',
                backgroundColor: 'white',
                textAlign: 'center',
              }}>
              <div>
                <b> BAN ĐIỀU HÀNH</b>
                <div>{ban_dieu_hanh_signed}</div>
                <div>
                  {ban_dieu_hanh ? (
                    <div>
                      <div
                        style={{
                          fontSize: '13px',
                          marginTop: ban_dieu_hanh_signed ? '5px' : '65px',
                        }}>
                        {ban_dieu_hanh_ptd?.position_name}
                      </div>
                      <div>
                        {utils.getNameInCapitalize(
                          ban_dieu_hanh_ptd?.full_name
                        )}
                      </div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
            </td>
            <td
              style={{
                border: 'none',
                margin: '0 36px ',
                backgroundColor: 'white',
                textAlign: 'center',
              }}>
              <div>
                <b>
                  HĐQT {systemName}
                  <br />
                  (CT/TV HĐQT)
                </b>
                <div>{hdqt_vimc_signed}</div>
                <div>
                  {hdqt_vimc ? (
                    <div>
                      <div
                        style={{
                          fontSize: '13px',
                          marginTop: hdqt_vimc_signed ? '5px' : '65px',
                        }}>
                        {hdqt_vimc_ptd?.position_name}
                      </div>
                      <div>
                        {utils.getNameInCapitalize(hdqt_vimc_ptd?.full_name)}
                      </div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </RiskActionStyle>
  )
}

export default inject()(observer(RiskActionDetails3))
