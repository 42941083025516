// Ant design
import { DatePicker, message, Select, Tooltip } from 'antd'
import axios from 'axios'
//other
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { useLocation } from 'react-router-dom'
// Components
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  ISO_DATE_FORMAT,
  MM_YYYY,
} from '../../constants'
// Layout
import DashboardLayout from '../../layouts/DashboardLayout'
// Styled component
import {
  ContentBoxItem,
  MisionGroupWrap,
  MissionGroupItem,
  MissionGroupsList,
  MissionWrapper,
} from './MissionGroupPageStyled'

import MissionPageList from '../MissionPage/MissionPageList'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import { TitleContentBox } from '../../components/Common/CellText'
import { DocumentInfoList } from '../Documents/InternalDocumentIncomingDocDetailPage/InternalDocumentIncomingDocDetailPageStyled'
import { systemName } from '../../config'

const { Option } = Select

const reducer = (state, action) => {
  switch (action.type) {
    case ASSIGNEE_TYPE.HANDLER:
      return {
        ...state,
        [ASSIGNEE_TYPE.HANDLER]:
          action.value &&
          `assignee=${encodeURIComponent(action.value)},${
            action.assigneeType
          },${ASSIGNEE_TYPE.HANDLER},${action.fullName || 'unknown'}`,
      }
    default:
      return state
  }
}

const MissionGroupPage = props => {
  const {
    taskStore,
    history,
    loadingAnimationStore,
    selectPeopleStore,
    authenticationStore,
  } = props
  const { currentUser } = authenticationStore
  const location = useLocation()
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })

  const { missionQueryParams } = taskStore

  const [loadingList, setLoadingList] = useState(false)
  const [isLoadingMissionGroup, setIsLoadingMissionGroup] = useState(false)
  const [textLoading, setTextLoading] = useState('Đang tải nhiệm vụ...')
  const [isRefreshPage, setIsRefreshPage] = useState(true)
  const [selectGroup, setSelectGroup] = useState('')
  const [usersFilter, dispatchUserFilter] = useReducer(reducer, {
    [ASSIGNEE_TYPE.HANDLER]:
      queryStringParse.assignee && Array.isArray(queryStringParse.assignee)
        ? queryStringParse.assignee.find(el =>
            el.includes(ASSIGNEE_TYPE.HANDLER)
          )
        : null,
  })
  useEffect(() => {
    if (isRefreshPage) return
    ;(async () => {
      try {
        setIsLoadingMissionGroup(true)
        await taskStore.getTaskListByGroup()
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoadingMissionGroup(false)
      }
    })()
  }, [missionQueryParams.date_by, missionQueryParams.from_date])
  useEffect(() => {
    ;(async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        await taskStore.getTaskLevelList()
        await taskStore.getTaskListByGroup()
        await taskStore.clearUpdateTaskItem()
        selectPeopleStore?.selectGroupData?.PIC?.length > 0 &&
          (await taskStore.getTaskList())
        setIsRefreshPage(false)
        setSelectGroup(Number(selectPeopleStore?.selectGroupData?.PIC[0]?.id))
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      taskStore.clearTaskStoreFilter()
      taskStore.clearTaskList()
      taskStore.clearTaskGroupList()
      selectPeopleStore.clearSelected()
    }
  }, [])

  useEffect(() => {
    if (isRefreshPage || !selectGroup) return
    const source = axios.CancelToken.source()
    ;(async () => {
      await new Promise(resolve => setTimeout(resolve, 500)).then(() => {
        const element = document.getElementById('scroller')
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      })
      try {
        setLoadingList(true)
        await taskStore.getTaskList(source.token)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
        setLoadingList(false)
      } finally {
        setLoadingList(false)
      }
    })()
    return () => {
      source.cancel()
    }
  }, [missionQueryParams])

  const onChangeMonth = value => {
    if (!value) {
      return message.warning('Vui lòng chọn tháng!')
    }
    taskStore.handleChangeMonth(
      value,
      queryStringParse,
      history,
      'mission-group'
    )
  }

  const handleChangeDateBy = value =>
    taskStore.handleChangeDateBy(
      value,
      queryStringParse,
      history,
      'mission-group'
    )
  useEffect(() => {
    if (!currentUser) return
    if (queryStringParse.assignee && Array.isArray(queryStringParse.assignee)) {
      queryStringParse.assignee.forEach(item => {
        const itemInfo = item.split(',')
        const username = itemInfo[0].split('=')[1]
        if (itemInfo.includes(ASSIGNEE_TYPE.GROUP)) {
          selectPeopleStore.setSelectGroupData({
            ...selectPeopleStore.selectGroupData,
            [itemInfo[2]]: [
              {
                assignee_type: ASSIGNEE_TYPE.GROUP,
                full_name: itemInfo[3],
                id: username,
                permission: itemInfo[2],
                department_code: null,
                department_name: null,
                image_id: null,
                position_name: null,
              },
            ],
          })
        }
      })
    }
    if (queryStringParse.PIC === currentUser.username) {
      setActiveTab(ASSIGNEE_TYPE.HANDLER)
      dispatchUserFilter({
        type: 'CHANGE_TAB',
        value: {
          type: ASSIGNEE_TYPE.HANDLER,
          value: currentUser.username,
          fullName: currentUser.name_uppercase,
          assigneeType: ASSIGNEE_TYPE.USER,
        },
      })
      return
    }
  }, [currentUser])

  useEffect(() => {
    taskStore.setMissionQueryParams({
      date_by: queryStringParse.date_by || 'DISPLAY_DATE',
      from_date: !queryStringParse.all_time
        ? queryStringParse.from_date ||
          moment().startOf('month').format(ISO_DATE_FORMAT)
        : null,
      to_date: !queryStringParse.all_time
        ? queryStringParse.to_date ||
          moment().endOf('month').format(ISO_DATE_FORMAT)
        : null,
      assignee: Object.values(usersFilter)
        .filter(item => item)
        .map(item => {
          const assignee = item.split(',')
          if (assignee.length === 3) {
            return assignee.join()
          }
          assignee.pop()
          return assignee.join()
        })
        .join('&'),
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        assignee: Object.values(usersFilter),
        [ASSIGNEE_TYPE.HANDLER]:
          typeof usersFilter[ASSIGNEE_TYPE.HANDLER] === 'string'
            ? usersFilter[ASSIGNEE_TYPE.HANDLER].split(',')[0].substring(9)
            : null,
      },
      {
        skipNull: true,
        arrayFormat: 'index',
      }
    )
    history.replace(`/mission-group?${queryStringStringify}`)
  }, [usersFilter])

  const handleSelectGroup = group => {
    setSelectGroup(group.group_id)
    dispatchUserFilter({
      type: ASSIGNEE_TYPE.HANDLER,
      value: group.group_id,
      fullName: group.full_name,
      assigneeType: ASSIGNEE_TYPE.GROUP,
    })
  }

  const handleNextMonth = (taskCode, type) =>
    taskStore.handleNextMonth(taskCode, type, setLoadingList, setTextLoading)
  return (
    <DashboardLayout>
      <Helmet>
        <title>Nhiệm vụ | {systemName} Nhiệm vụ</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={'Danh sách nhiệm vụ tuần'}
        hiddenGoBack></PageTitle>
      <ContentBlockWrapper>
        <div style={{ display: 'flex', marginBottom: 16 }}>
          <div style={{ flex: '0 0 300px' }}>
            <Select
              style={{ width: 172, marginRight: 8 }}
              value={missionQueryParams.date_by}
              onChange={handleChangeDateBy}>
              <Option value="DISPLAY_DATE">Lọc theo Tháng hiển thị</Option>
              <Option value="CREATED_DATE">Lọc theo Ngày tạo</Option>
            </Select>
            <DatePicker
              style={{ width: 120 }}
              onChange={onChangeMonth}
              defaultValue={
                !queryStringParse.all_time
                  ? (queryStringParse.from_date &&
                      moment(queryStringParse.from_date)) ||
                    moment()
                  : null
              }
              picker="month"
              placeholder={'Chọn tháng'}
              format={MM_YYYY}
            />
          </div>
        </div>
        <MisionGroupWrap>
          <MissionGroupsList>
            {taskStore.taskGroupList.map(group => (
              <MissionGroupItem
                key={group.group_id}
                onClick={() => handleSelectGroup(group)}>
                <ContentBlockWrapper
                  //background
                  isSelectGroup={
                    selectGroup && selectGroup === group.group_id ? true : false
                  }
                  shadow
                  hover>
                  <TitleContentBox>
                    <div className="mission-performer">{group.group_name}</div>
                  </TitleContentBox>
                  <ContentBoxItem>
                    <dt>Hoàn thành</dt>
                    <dd>{group.hoan_thanh}</dd>
                    <dt>Chưa hoàn thành</dt>
                    <dd>{group.chua_hoan_thanh}</dd>
                  </ContentBoxItem>
                </ContentBlockWrapper>
              </MissionGroupItem>
            ))}
          </MissionGroupsList>
          <SpinnerInlineComponent
            sizeSpin={'small'}
            isLoading={isLoadingMissionGroup}
            backGround={'#ffffffc7'}
          />
        </MisionGroupWrap>
        <MissionWrapper id="scroller">
          {selectGroup ? (
            <MissionPageList
              handleNextMonth={handleNextMonth}
              activeTab={'ALL'}
              loadingList={false}
              textLoading={textLoading}
            />
          ) : (
            ''
          )}
          <SpinnerInlineComponent
            sizeSpin={'small'}
            tip="Đang tải dữ liệu..."
            isLoading={loadingList}
            backGround={'#ffffffc7'}
          />
        </MissionWrapper>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

MissionGroupPage.propTypes = {}

export default inject(
  'taskStore',
  'commonStore',
  'loadingAnimationStore',
  'authenticationStore',
  'departmentStore',
  'selectPeopleStore'
)(observer(MissionGroupPage))
