import { CloseOutlined, UserOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  TreeSelect,
  message,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { blue } from '../../color'
import SelectListComponentRisk from '../../components/Common/SelectListComponentRisk'
import SelectPeoplePopup from '../../components/SelectPeoplePopup'
import { systemName } from '../../config'
import { ASSIGNEE_TYPE } from '../../constants'
import utils from '../../utils'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'

const { Option } = Select

const RiskAssessmentCreatePopup = ({
  handleCancel,
  isVisible,
  actionStore,
  riskStore,
  departmentStore,
  selectUserList,
  getRiskDetail,
  selectPeopleStore,
  type,
  riskUnitsV2,
  departmentWithUserList,
}) => {
  const {
    indexDetailAction,
    dataActions,
    setDataActions,
    createAction,
    updateAction,
  } = actionStore
  const { riskDetail } = riskStore

  const {
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    selectUserData,
    setSelectUserData,
  } = selectPeopleStore

  const [form] = Form.useForm()
  const [isRenderRichText, setIsRenderRichText] = useState(false)
  const [dvptList, setDvptList] = useState([])
  const [listChildren, setListChildren] = useState([])
  const { SHOW_ALL } = TreeSelect
  const treeData =
    Array.isArray(riskUnitsV2) &&
    riskUnitsV2?.map(item => ({
      title: item.name,
      value: item.code,
      key: item.code,
      children: item.unit_users.map(user => ({
        title: utils.getNameInCapitalize(user.full_name),
        value: user.user_code,
        key: user.user_code,
      })),
    }))
  const treeDataDp = departmentWithUserList.map(item => ({
    title: item.name,
    value: item.code,
    key: item.code,
    children: item.users.map(user => ({
      title: utils.getNameInCapitalize(user.name_uppercase),
      value: user.user_code,
      key: user.user_code,
    })),
  }))

  const getUnitDVPT = async type => {
    try {
      const response = await riskStore.getRiskUnitsByType(type)
      setDvptList(response.data)
    } catch (error) {
      console.log(error)
      message.error('Đã có lỗi xảy ra')
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        await departmentStore.getDepartmentList()
        getUnitDVPT('DVPT')
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
  }, [])

  useEffect(() => {
    const fillData =
      dataActions?.tier2?.length > 0 ? dataActions?.tier2[0] : null
    if (!fillData || indexDetailAction?.tier !== '2') {
      return
    }
    const BAN_CMNV_VIMC = departmentWithUserList.find(
      depart => depart.name === fillData.III_kiem_soat_rui_ro.phong_ban_nddpv
    )
    setSelectUserData({
      ...selectUserData,
      [ASSIGNEE_TYPE.ban_pc_qtrr_vimc]:
        fillData?.doi_tuong_thuc_hien?.ban_pc_qtrr_vimc,
      [ASSIGNEE_TYPE.ban_cmnv_vimc]:
        fillData?.doi_tuong_thuc_hien?.ban_cmnv_vimc,
      [ASSIGNEE_TYPE.lanh_dao_ban]:
        fillData?.III_kiem_soat_rui_ro?.lanh_dao_ban,
    })
    handldeSetValue(fillData.III_kiem_soat_rui_ro.phong_ban_nddpv)

    form.setFieldsValue({
      ban_pc_qtrr_vimc: fillData.doi_tuong_thuc_hien.ban_pc_qtrr_vimc,
      ban_cmnv_vimc: fillData.doi_tuong_thuc_hien.ban_cmnv_vimc,
      //III_kiem_soat_rui_ro -> doi_voi_cac_loai_rui_ro_da_nhan_dien
      doi_voi_cac_loai_rui_ro_da_nhan_dien__dong_y:
        fillData.III_kiem_soat_rui_ro.doi_voi_cac_loai_rui_ro_da_nhan_dien
          .dong_y,
      doi_voi_cac_loai_rui_ro_da_nhan_dien__khong_dong_y:
        fillData.III_kiem_soat_rui_ro.doi_voi_cac_loai_rui_ro_da_nhan_dien
          .khong_dong_y,

      doi_voi_cac_loai_rui_ro_da_nhan_dien__dieu_chinh_bo_sung:
        fillData.III_kiem_soat_rui_ro.doi_voi_cac_loai_rui_ro_da_nhan_dien
          .dieu_chinh_bo_sung,
      //III_kiem_soat_rui_ro -> doi_voi_danh_gia_muc_rui_ro
      doi_voi_danh_gia_muc_rui_ro__dong_y:
        fillData.III_kiem_soat_rui_ro.doi_voi_danh_gia_muc_rui_ro.dong_y,
      doi_voi_danh_gia_muc_rui_ro__khong_dong_y:
        fillData.III_kiem_soat_rui_ro.doi_voi_danh_gia_muc_rui_ro.khong_dong_y,
      doi_voi_danh_gia_muc_rui_ro__dieu_chinh_bo_sung:
        fillData.III_kiem_soat_rui_ro.doi_voi_danh_gia_muc_rui_ro
          .dieu_chinh_bo_sung,
      //
      loai_rui_ro_da_de_xuat_xu_ly:
        fillData.III_kiem_soat_rui_ro.loai_rui_ro_da_de_xuat_xu_ly,
      danh_gia_bien_phap_xu_ly_da_de_xuat:
        fillData.III_kiem_soat_rui_ro.danh_gia_bien_phap_xu_ly_da_de_xuat,
      thoi_gian_hoan_thanh_cong_viec_duoc_giao:
        fillData.III_kiem_soat_rui_ro.thoi_gian_hoan_thanh_cong_viec_duoc_giao,
      y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc:
        fillData.III_kiem_soat_rui_ro.y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc,
      bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc:
        fillData.III_kiem_soat_rui_ro
          .bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc,
      chuyen_vien_thuc_hien:
        fillData.III_kiem_soat_rui_ro.chuyen_vien_thuc_hien,
      lanh_dao_ban: fillData.III_kiem_soat_rui_ro.lanh_dao_ban,
      lanh_dao_ban_nddpv: fillData.III_kiem_soat_rui_ro.lanh_dao_ban_nddpv,
      lanh_dao_ban_cqvp: fillData.III_kiem_soat_rui_ro.lanh_dao_ban_cqvp,
      lanh_dao_ban_signed: fillData.III_kiem_soat_rui_ro.lanh_dao_ban_signed,
      phong_ban_nddpv: fillData.III_kiem_soat_rui_ro.phong_ban_nddpv,
      chuyen_vien_thuc_hien_nddpv: BAN_CMNV_VIMC?.users
        .filter(item =>
          fillData.III_kiem_soat_rui_ro.chuyen_vien_thuc_hien_nddpv.includes(
            item.user_code
          )
        )
        .map(item => item.user_code),
      chuyen_vien_thuc_hien_nddpv_signed:
        fillData.III_kiem_soat_rui_ro.chuyen_vien_thuc_hien_nddpv_signed,
      lanh_dao_ban_nddpv2: BAN_CMNV_VIMC?.users
        .filter(item =>
          fillData.III_kiem_soat_rui_ro.lanh_dao_ban_nddpv2.includes(
            item.user_code
          )
        )
        .map(item => item.user_code),
    })
  }, [indexDetailAction])
  const handleCancelModal = () => {
    handleCancel()
  }
  const handleSubmit = async value => {
    const submitData = {
      name: 'Tuyến phòng vệ thứ hai',
      doi_tuong_thuc_hien: {
        ban_pc_qtrr_vimc: selectUserList?.ban_pc_qtrr_vimc,
        ban_cmnv_vimc: value.ban_cmnv_vimc,
      },
      III_kiem_soat_rui_ro: {
        doi_voi_cac_loai_rui_ro_da_nhan_dien: {
          dong_y: value.doi_voi_cac_loai_rui_ro_da_nhan_dien__dong_y,
          khong_dong_y:
            value.doi_voi_cac_loai_rui_ro_da_nhan_dien__khong_dong_y,
          dieu_chinh_bo_sung:
            value.doi_voi_cac_loai_rui_ro_da_nhan_dien__dieu_chinh_bo_sung,
        },
        doi_voi_danh_gia_muc_rui_ro: {
          dong_y: value.doi_voi_danh_gia_muc_rui_ro__dong_y,
          khong_dong_y: value.doi_voi_danh_gia_muc_rui_ro__khong_dong_y,
          dieu_chinh_bo_sung:
            value.doi_voi_danh_gia_muc_rui_ro__dieu_chinh_bo_sung,
        },
        loai_rui_ro_da_de_xuat_xu_ly: value.loai_rui_ro_da_de_xuat_xu_ly,
        danh_gia_bien_phap_xu_ly_da_de_xuat:
          value.danh_gia_bien_phap_xu_ly_da_de_xuat,
        thoi_gian_hoan_thanh_cong_viec_duoc_giao:
          value.thoi_gian_hoan_thanh_cong_viec_duoc_giao,
        bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc:
          value.bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc,
        y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc:
          value.y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc,
        chuyen_vien_thuc_hien: value.chuyen_vien_thuc_hien,
        lanh_dao_ban: selectUserList?.lanh_dao_ban,
        lanh_dao_ban_nddpv: value?.lanh_dao_ban_nddpv,
        lanh_dao_ban_cqvp: value.lanh_dao_ban_cqvp,
        lanh_dao_ban_signed:
          checked_ld === true ? value?.lanh_dao_ban_signed : null,
        phong_ban_nddpv: value?.phong_ban_nddpv,
        chuyen_vien_thuc_hien_nddpv: value?.chuyen_vien_thuc_hien_nddpv,
        chuyen_vien_thuc_hien_nddpv_signed:
          checked_cvth === true
            ? value?.chuyen_vien_thuc_hien_nddpv_signed
            : null,
        lanh_dao_ban_nddpv2: value?.lanh_dao_ban_nddpv2,
      },
    }
    if (riskDetail?.tiers) {
      const currentAction = riskDetail?.tiers[1]?.actions[0]
      if (currentAction) {
        await updateAction(currentAction.code, {
          name: 'Rủi ro nhận diện 2 update',
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[1]?.code,
          risk_type: type,
        })
      } else {
        await createAction({
          ...submitData,
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[1]?.code,
        })
      }
      message.success('Lưu thành công')
      handleCancelModal()
      getRiskDetail()
    } else {
      setDataActions({
        ...dataActions,
        tier2: [submitData],
      })
      message.success('Lưu thành công')
      handleCancelModal()
    }
  }

  const valueStyle = { marginLeft: 8 }

  const [radioValueL, setRadioValueL] = useState()
  const [radioValueDG, setRadioValueDG] = useState()
  const [radioValueTG, setRadioValueTG] = useState()
  useEffect(() => {
    setRadioValueL(
      dataActions?.tier2[0]?.III_kiem_soat_rui_ro?.loai_rui_ro_da_de_xuat_xu_ly
    )
    setRadioValueDG(
      dataActions?.tier2[0]?.III_kiem_soat_rui_ro
        ?.danh_gia_bien_phap_xu_ly_da_de_xuat
    )
    setRadioValueTG(
      dataActions?.tier2[0]?.III_kiem_soat_rui_ro
        ?.thoi_gian_hoan_thanh_cong_viec_duoc_giao
    )
  }, [dataActions])
  const onChangeL = e => {
    setRadioValueL(e.target.value)
  }
  const onChangeDG = e => {
    setRadioValueDG(e.target.value)
  }
  const onChangeTG = e => {
    setRadioValueTG(e.target.value)
  }
  const [checked_cvth, setChecked_cvth] = useState(false)
  const [checked_ld, setChecked_ld] = useState(false)

  const [disabled_cvth, setDisabled_cvth] = useState(true)
  const [disabled_ld, setDisabled_ld] = useState(true)

  const handldeSetValue = value => {
    const listDataNew = treeDataDp.find(e => e.title === value)
    if (listDataNew) setListChildren(listDataNew.children)
    if (
      value !== dataActions?.tier2[0]?.III_kiem_soat_rui_ro?.phong_ban_nddpv ||
      value === undefined
    ) {
      form.setFieldsValue({
        chuyen_vien_thuc_hien_nddpv: undefined,
        lanh_dao_ban_nddpv2: undefined,
      })
    }
    // if (value === undefined) {
    //   setDisabled_cvth(true)
    //   setChecked_cvth(false)
    //   setDisabled_ld(true)
    //   setChecked_ld(false)
    // }
  }
  const toggleDisableCvth = e => {
    if (e?.length > 0) {
      setDisabled_cvth(false)
    }
    if (e?.length < 1) {
      setChecked_cvth(false)
      setDisabled_cvth(true)
    }
  }
  const toggleDisableCvthk = value => {
    if (value.target.value.trim() === '') {
      setDisabled_cvth(true)
      setChecked_cvth(false)
    } else {
      setDisabled_cvth(false)
    }
  }
  const onChangeCheckedCvth = e => {
    setChecked_cvth(e.target.checked)
  }

  const toggleDisableLd = e => {
    if (e?.length > 0) {
      setDisabled_ld(false)
    }
    if (e?.length < 1) {
      setChecked_ld(false)
      setDisabled_ld(true)
    }
  }
  useEffect(() => {
    if (selectUserList.lanh_dao_ban.length > 0 && type === 'CQVP') {
      setDisabled_ld(false)
    } else if (selectUserList.lanh_dao_ban.length == 0 && type === 'CQVP') {
      setDisabled_ld(true)
      setChecked_ld(false)
    }
  }, [selectUserList.lanh_dao_ban.length])

  const onChangeCheckedLd = e => {
    setChecked_ld(e.target.checked)
  }

  useEffect(() => {
    if (
      dataActions?.tier2[0]?.III_kiem_soat_rui_ro
        ?.chuyen_vien_thuc_hien_nddpv_signed === '(Đã ký)'
    ) {
      setChecked_cvth(true)
    }
    if (
      dataActions?.tier2[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_signed ===
      '(Đã ký)'
    ) {
      setChecked_ld(true)
    }
  }, [dataActions])
  useEffect(() => {
    if (
      dataActions?.tier2[0]?.III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien_nddpv
        ?.length > 0
    ) {
      setDisabled_cvth(false)
    }
    if (
      dataActions?.tier2[0]?.III_kiem_soat_rui_ro?.chuyen_vien_thuc_hien
        ?.length > 0
    ) {
      setDisabled_cvth(false)
    }
    if (
      dataActions?.tier2[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_nddpv2?.length >
      0
    ) {
      setDisabled_ld(false)
    }
    if (
      dataActions?.tier2[0]?.III_kiem_soat_rui_ro?.lanh_dao_ban_cqvp?.length > 0
    ) {
      setDisabled_ld(false)
    }
  }, [dataActions])
  return (
    <>
      <Modal
        width={800}
        style={{ top: 40, zIndex: 500 }}
        title={'B.	Tuyến phòng vệ thứ hai'}
        visible={isVisible}
        maskClosable={false}
        destroyOnClose={true}
        afterClose={() => {
          setIsRenderRichText(false)
        }}
        onCancel={() => {
          handleCancelModal()
        }}
        footer={null}>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={handleSubmit}
          preserve={false}>
          <h3>Đối tượng thực hiện:</h3>
          {type === 'NDDPV' ? (
            ''
          ) : (
            <>
              <Form.Item>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ margin: 0 }}>
                    - Ban PC&QTRR {systemName} (trong trường hợp các Ban CMNV
                    thực hiện công việc):
                  </p>
                  <Button
                    style={{ marginLeft: '8px' }}
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() => {
                      handleOpenSelectUserDepartmentPopup(
                        ASSIGNEE_TYPE.ban_pc_qtrr_vimc
                      )
                    }}
                  />
                </div>
              </Form.Item>
              <SelectListComponentRisk
                userList={selectUserList.ban_pc_qtrr_vimc}
              />
            </>
          )}

          {/* <Form.Item>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '8px',
              }}>
              <p style={{ margin: 0 }}>
                - Ban CMNV VIMC (trong trường hợp giao NĐDPV, ĐVPT thực hiện
                công việc; các Ban cmnv chủ trì):
              </p>
              <Button
                style={{ marginLeft: '8px' }}
                icon={<UserOutlined style={{ color: blue }} />}
                onClick={() => {
                  handleOpenSelectUserDepartmentPopup(
                    ASSIGNEE_TYPE.ban_cmnv_vimc
                  )
                }}
              />
            </div>
          </Form.Item> */}
          <Form.Item
            label={`- Ban CMNV ${systemName} (trong trường hợp giao NĐDPV, ĐVPT thực hiện
                công việc; các Ban cmnv chủ trì):`}
            name="ban_cmnv_vimc"
            style={{ width: '100%' }}>
            <Select
              showSearch
              // mode="multiple"
              allowClear // Cho phép xóa lựa chọn đã chọn
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Chọn đơn vị"
              style={{ width: '100%' }}>
              {type !== 'DVPT'
                ? Array.isArray(departmentWithUserList) &&
                  departmentWithUserList.map((item, index) => (
                    <Select.Option key={index} value={item.code}>
                      {item.name}
                    </Select.Option>
                  ))
                : Array.isArray(dvptList) &&
                  dvptList.map((item, index) => (
                    <Select.Option key={index} value={item.code}>
                      {item.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
          {/* <SelectListComponent userList={selectUserList.ban_cmnv_vimc} /> */}
          <Divider />

          <h3>III. Kiểm soát rủi ro</h3>
          <p>
            1. Đối với các loại rủi ro đã nhận diện:
            <span style={valueStyle}>
              <Space direction="horizontal">
                <Form.Item
                  name={'doi_voi_cac_loai_rui_ro_da_nhan_dien__dong_y'}>
                  <Radio.Group>
                    <Radio value={'Đồng ý'}>Đồng ý</Radio>
                    <Radio value={'Không đồng ý'}>Không đồng ý</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name={
                    'doi_voi_cac_loai_rui_ro_da_nhan_dien__dieu_chinh_bo_sung'
                  }>
                  <Input placeholder={'Điều chỉnh bổ sung'} />
                </Form.Item>
              </Space>
            </span>
          </p>

          <p>
            2. Đối với đánh giá mức rủi ro:
            <span style={valueStyle}>
              <Space direction="horizontal">
                <Form.Item name={'doi_voi_danh_gia_muc_rui_ro__dong_y'}>
                  <Radio.Group>
                    <Radio value={'Đồng ý'}>Đồng ý</Radio>
                    <Radio value={'Không đồng ý'}>Không đồng ý</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name={'doi_voi_danh_gia_muc_rui_ro__dieu_chinh_bo_sung'}>
                  <Input placeholder={'Điều chỉnh bổ sung'} />
                </Form.Item>
              </Space>
            </span>
          </p>

          <p>
            3. Loại rủi ro đã đề xuất xử lý:
            <span style={valueStyle}>
              <Space direction="horizontal">
                <Form.Item name={'loai_rui_ro_da_de_xuat_xu_ly'}>
                  <Radio.Group value={radioValueL} onChange={onChangeL}>
                    <Radio value={'Đồng ý'}>Đồng ý</Radio>
                    <Radio value={'Không đồng ý'}>Không đồng ý</Radio>
                  </Radio.Group>
                </Form.Item>
              </Space>
            </span>
          </p>

          <p>
            4. Đánh giá biện pháp xử lý đã đề xuất:
            <span style={valueStyle}>
              <Space>
                <Form.Item name={'danh_gia_bien_phap_xu_ly_da_de_xuat'}>
                  <Radio.Group value={radioValueDG} onChange={onChangeDG}>
                    <Radio value={'Tuân thủ'}>Tuân thủ</Radio>
                    <Radio value={'Không tuân thủ'}>Không tuân thủ</Radio>
                  </Radio.Group>
                </Form.Item>
              </Space>
            </span>
          </p>

          <p>
            5. Thời gian hoàn thành công việc được giao:
            <span style={valueStyle}>
              <Space>
                <Form.Item name={'thoi_gian_hoan_thanh_cong_viec_duoc_giao'}>
                  <Radio.Group value={radioValueTG} onChange={onChangeTG}>
                    <Radio value={'Đúng thời hạn'}>Đúng thời hạn</Radio>
                    <Radio value={'Không đúng thời hạn'}>
                      Không đúng thời hạn
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Space>
            </span>
          </p>

          <Form.Item
            name={'bo_phan_neu_y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc'}
            label={`6. Ý kiến khác của Ban CMNV ${systemName}:`}>
            <Select
              showSearch
              allowClear // Cho phép xóa lựa chọn đã chọn
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={'Chọn đơn vị'}>
              {Array.isArray(treeDataDp) &&
                treeDataDp.map((item, index) => (
                  <Select.Option key={index} value={item.title}>
                    {item.title}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item name={'y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>

          <Divider />
          {type === 'NDDPV' ? (
            <>
              <h3>BAN CMNV {systemName}</h3>
              <Form.Item name={'phong_ban_nddpv'} label={'Phòng ban:'}>
                <Select
                  showArrow
                  showSearch
                  mode="single"
                  allowClear // Cho phép xóa lựa chọn đã chọn
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Chọn phòng ban"
                  onChange={handldeSetValue}>
                  {treeDataDp.map((item, index) => (
                    <Select.Option key={index} value={item.title}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name={'chuyen_vien_thuc_hien_nddpv_signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_cvth}
                    disabled={disabled_cvth}
                    onChange={e => onChangeCheckedCvth(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <Form.Item
                name={'chuyen_vien_thuc_hien_nddpv'}
                label={'Chuyên viên thực hiện:'}>
                <Select
                  showArrow
                  showSearch
                  mode="multiple"
                  allowClear // Cho phép xóa lựa chọn đã chọn
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={e => toggleDisableCvth(e)}
                  placeholder="Chọn chuyên viên thực hiện">
                  {listChildren?.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <p>Lãnh đạo Ban:</p>
              <Form.Item name={'lanh_dao_ban_signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_ld}
                    disabled={disabled_ld}
                    onChange={e => onChangeCheckedLd(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <Form.Item name={'lanh_dao_ban_nddpv2'}>
                <Select
                  showArrow
                  showSearch
                  mode="multiple"
                  allowClear // Cho phép xóa lựa chọn đã chọn
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={e => toggleDisableLd(e)}
                  placeholder="Chọn lãnh đạo ban">
                  {listChildren?.map((item, index) => (
                    <Option key={item.key} value={item.value}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          ) : (
            <>
              <h3>
                BAN PC&QTRR {systemName}/ BAN CMNV {systemName}
              </h3>
              <p>Chuyên viên thực hiện:</p>
              <Form.Item name={'chuyen_vien_thuc_hien_nddpv_signed'}>
                <Space direction="vertical">
                  <Checkbox
                    checked={checked_cvth}
                    disabled={disabled_cvth}
                    onChange={e => onChangeCheckedCvth(e)}
                    value={'(Đã ký)'}>
                    Xác nhận ký
                  </Checkbox>
                </Space>
              </Form.Item>
              <Form.Item name={'chuyen_vien_thuc_hien'}>
                <Input
                  onChange={e => toggleDisableCvthk(e)}
                  placeholder={'Nhập chuyên viên thực hiện'}
                />
              </Form.Item>
              {type === 'DVPT' ? (
                <>
                  <p>Lãnh đạo Ban:</p>
                  <Form.Item name={'lanh_dao_ban_signed'}>
                    <Space direction="vertical">
                      <Checkbox
                        checked={checked_ld}
                        disabled={disabled_ld}
                        onChange={e => onChangeCheckedLd(e)}
                        value={'(Đã ký)'}>
                        Xác nhận ký
                      </Checkbox>
                    </Space>
                  </Form.Item>
                  <Form.Item name={'lanh_dao_ban_cqvp'}>
                    <TreeSelect
                      treeData={treeDataDp}
                      style={{ width: '100%' }}
                      showArrow
                      allowClear
                      showSearch
                      filterTreeNode
                      treeNodeFilterProp={'title'}
                      treeCheckable={true}
                      showCheckedStrategy={SHOW_ALL}
                      treeCheckStrictly={true}
                      onChange={e => toggleDisableLd(e)}
                      placeholder={'Chọn lãnh đạo ban'}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <p style={{ margin: 0 }}>Lãnh đạo Ban:</p>
                  <Form.Item name={'lanh_dao_ban_signed'}>
                    <Space direction="vertical">
                      <Checkbox
                        checked={checked_ld}
                        disabled={disabled_ld}
                        onChange={e => onChangeCheckedLd(e)}
                        value={'(Đã ký)'}>
                        Xác nhận ký
                      </Checkbox>
                    </Space>
                  </Form.Item>
                  <Form.Item>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '8px',
                      }}>
                      <Button
                        style={{ marginLeft: '8px' }}
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() => {
                          handleOpenSelectUserDepartmentPopup(
                            ASSIGNEE_TYPE.lanh_dao_ban
                          )
                        }}
                      />
                    </div>
                  </Form.Item>
                </>
              )}
            </>
          )}

          <SelectListComponentRisk userList={selectUserList.lanh_dao_ban} />
          <FormActionFooter style={{ marginBottom: 0, marginRight: 0 }}>
            <Button icon={<CloseOutlined />} danger onClick={handleCancelModal}>
              Đóng
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              type={'primary'}
              htmlType={'submit'}>
              Lưu thông tin
            </Button>
          </FormActionFooter>
        </Form>
        <SelectPeoplePopup
          disableSelectDepartment
          handleCancelSelectUser={handleCancelSelectUserDepartment}
          handleSubmitSelectUser={() =>
            setIsVisibleSelectUserDepartmentPopup(false)
          }
          isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
          isUserDisableList={false}
        />
      </Modal>
    </>
  )
}

export default inject(
  'actionStore',
  'riskStore',
  'selectPeopleStore',
  'departmentStore'
)(observer(RiskAssessmentCreatePopup))
