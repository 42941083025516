import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import {
  Button,
  Checkbox,
  Dropdown,
  List,
  Menu,
  message,
  Spin,
  Tabs,
  Form,
  Input,
} from 'antd'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { apiUrl } from './../../config'
import {
  AvatarItem,
  ContentNotification,
  ControlNotification,
  ListItemLeft,
  ListItemRight,
  ListItemWrapper,
  NotificationListWrapper,
} from './NotificationPageStyled'
import EmptyContent from '../../components/EmptyContent'
import {
  CheckOutlined,
  CloseSquareOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import date_format from '../../date_format'
import InfiniteScroll from 'react-infinite-scroll-component'
import utils from '../../utils'
import { subStringAvatar, WordBreak } from '../../components/Common/CellText'
import { StatusTag } from '../../components/Common/StatusTag'
import { NOTIFICATION_STATUS, TYPE_STATUS } from '../../constants'
import internalMessageStore from '../../stores/internalMessageStore'
import loadingAnimationStore from '../../stores/loadingAnimationStore'
import { systemName } from '../../config'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

const styleIcon = {
  fontSize: '2em',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const NotificationPage = props => {
  const { notificationStore, history } = props
  const [form] = Form.useForm()

  const { notificationListTotalCount } = notificationStore

  const [items, setItems] = useState([])
  const [pageIndex, setPageIndex] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isFilterUnread, setIsFilterUnread] = useState(false)
  const [isLoadingNotification, setIsLoadingNotification] = useState(false)
  const [activeKey, setActiveKey] = useState('1')

  const onlyNewsNotificationParams = utils.getParameterByName(
    'only_news_notification'
  )

  useEffect(() => {
    if (onlyNewsNotificationParams === 'true') {
      setActiveKey('2')
    } else {
      setActiveKey('1')
    }
  }, [onlyNewsNotificationParams])

  useEffect(() => {
    if (notificationStore?.keyword) {
      form.setFieldsValue({
        search: notificationStore.keyword,
      })
      onSearchTitle(notificationStore.keyword)
    }
  }, [])
  /** Xử lý đã đọc thông báo */
  const handleReadNotify = useCallback(
    (id, status, num) => {
      if (status && num === 0) {
        message.warning('Thông báo đã được đọc!')
        return
      }
      if (status) return
      setIsLoadingNotification(true)
      notificationStore
        .readNotification(id)
        .then(() => {
          if (isFilterUnread) {
            const itemsList = items.filter(el => el.id !== id)
            setItems(itemsList)
          } else {
            const itemsList = [...items]
            const indexReadItem = items.findIndex(item => item.id === id)
            const itemReplace = {
              ...items[indexReadItem],
              status: true,
            }
            itemsList.splice(indexReadItem, 1, itemReplace)
            setItems([...itemsList])
          }
          if (onlyNewsNotificationParams === 'false') {
            notificationStore.setUnreadNotificationCount('remove')
          } else {
            notificationStore.setUnreadNewsCount('remove')
          }
          message.success('Thông báo được đánh dấu đã đọc!')
        })
        .finally(() => setIsLoadingNotification(false))
    },
    [items]
  )

  const handleRemoveNotify = () => {
    message.warning('Chức năng đang cập nhật!')
  }

  const menuControlNotification = (id, status) => {
    return (
      <Menu>
        <Menu.Item onClick={() => handleReadNotify(id, status, 0)}>
          <span>
            <CheckOutlined />
            &nbsp; Đánh dấu đã đọc
          </span>
        </Menu.Item>
        <Menu.Item onClick={handleRemoveNotify} danger>
          <span>
            <CloseSquareOutlined />
            &nbsp; Gỡ thông báo này
          </span>
        </Menu.Item>
      </Menu>
    )
  }

  const goToContentNotification = async (type, id) => {
    if (type.slice(0, 5) === 'OTHER') {
      switch (type.slice(0, 5)) {
        case 'OTHER':
          return history.push(`/proposal/other/view/${id}`)
        default:
          return
      }
    } else {
      switch (type) {
        case NOTIFICATION_STATUS.OUTGOING:
          return history.push(`/internal-document/outgoing-document/view/${id}`)
        case NOTIFICATION_STATUS.PROPOSAL_ASSET:
          return history.push(`/distribution-votes/other/view/${id}`)
        case NOTIFICATION_STATUS.INCOMING:
          return history.push(`/internal-document/incoming-document/view/${id}`)
        case NOTIFICATION_STATUS.VBLT_DI:
          return history.push(
            `/connected-document/outgoing-document/view/${id}`
          )
        case NOTIFICATION_STATUS.VBLT_DEN:
          return history.push(
            `/connected-document/incoming-document/view/${id}`
          )
        case NOTIFICATION_STATUS.WORK:
          return history.push(`/works/view/${id}`)
        case NOTIFICATION_STATUS.TASK:
          return history.push(`/mission/view/${id}`)
        case NOTIFICATION_STATUS.PROCUREMENT:
          return history.push(`/shopping-coupon/other/view/${id}`)
        case NOTIFICATION_STATUS.VEHICLE:
        case NOTIFICATION_STATUS.LEAVE:
        case NOTIFICATION_STATUS.LEAVE_ANNUAL:
        case NOTIFICATION_STATUS.LEAVE_ABNORMAL:
        case NOTIFICATION_STATUS.LEAVE_NO_SALARY:
        case NOTIFICATION_STATUS.SICK:
        case NOTIFICATION_STATUS.SICK_OTHER:
        case NOTIFICATION_STATUS.OVERTIME_NORMAL:
        case NOTIFICATION_STATUS.OVERTIME_DAYOFF:
        case NOTIFICATION_STATUS.OVERTIME_HOLIDAY:
        case NOTIFICATION_STATUS.OVERTIME_CHAIRMAN:
        case NOTIFICATION_STATUS.BUSINESS_TRIP:
        case NOTIFICATION_STATUS.EVALUATE_MEMBER:
        case NOTIFICATION_STATUS.EVALUATE_LEADER:
        case NOTIFICATION_STATUS.TIMEKEEPER:
        case NOTIFICATION_STATUS.STATIONERY:
        case NOTIFICATION_STATUS.RETIMEKEEPING:
        case NOTIFICATION_STATUS.MEETING:
          return history.push(`/proposal/other/view/${id}`)
        case NOTIFICATION_STATUS.PROPOSAL_SALARY:
          return history.push(`/proposal/other/view/${id}`)
        case NOTIFICATION_STATUS.CONSULT:
          return history.push(`/administrative/consult/view/${id}`)
        case NOTIFICATION_STATUS.POLICY:
          return history.push(`/administrative/policy/view/${id}`)
        case NOTIFICATION_STATUS.WORK_SCHEDULE:
          return history.push(`/company-work-schedule/view/${id}`)
        case NOTIFICATION_STATUS.GENERAL:
          return history.push(`/utility/general-notifications/view/${id}`)
        case NOTIFICATION_STATUS.NEWS:
          return history.push(`/utility/general-notifications/view/${id}`)
        //phân biệt tin nhắn đến/đi
        case NOTIFICATION_STATUS.INTERNAL_MESSAGE_INCOMING:
        case NOTIFICATION_STATUS.INTERNAL_MESSAGE_INCOMING_REPLY:
          loadingAnimationStore.showSpinner(true)
          let internal_message_user_code = await internalMessageStore.getInternalMessageUserCodeByInternalMessageCode(
            id
          )
          loadingAnimationStore.showSpinner(false)
          return history.push(
            `/utility/internal-message/receive/view/${internal_message_user_code}`
          )
        case NOTIFICATION_STATUS.INTERNAL_MESSAGE_OUTGOING:
        case NOTIFICATION_STATUS.INTERNAL_MESSAGE_OUTGOING_REPLY:
          return history.push(`/utility/internal-message/send/view/${id}`)
        default:
          return
      }
    }
  }

  const handleMarkAsRead = async () => {
    setIsLoadingNotification(true)
    try {
      if (onlyNewsNotificationParams === 'false') {
        await notificationStore.markAllAsRead(onlyNewsNotificationParams)
        await notificationStore.getUnreadNotificationCount()
      } else {
        await notificationStore.markAllAsRead(onlyNewsNotificationParams)
        await notificationStore.getUnreadNewsCount()
      }
      const { data } = await notificationStore.getCurrentUserNotification(
        onlyNewsNotificationParams,
        isFilterUnread
      )
      setItems([...data.data])
      message.success('Đã đọc tất cả thông báo!')
    } catch (err) {
      console.log(err)
      message.error('Đọc thông báo thất bại!')
    } finally {
      setIsLoadingNotification(false)
    }
  }

  const fetchMoreData = () => {
    if (items.length >= notificationListTotalCount) {
      setHasMore(false)
      return
    }
    notificationStore.setFilter('notificationListPageIndex', pageIndex)
    notificationStore.setFilter('notificationListPageSize', 30)

    setPageIndex(pageIndex + 1)
    notificationStore
      .getCurrentUserNotification(onlyNewsNotificationParams)
      .then(res => {
        setItems([...items].concat([...res.data.data]))
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    notificationStore.setFilter('notificationListPageIndex', 0)
    notificationStore.setFilter('notificationListPageSize', 30)
    setHasMore(true)
    ;(async () => {
      setIsLoadingNotification(true)
      try {
        const { data } = await notificationStore.getCurrentUserNotification(
          onlyNewsNotificationParams,
          null
        )
        setIsFilterUnread(false)
        setItems([...data.data])
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoadingNotification(false)
      }
    })()
    return () => {
      notificationStore.clearStore()
      setItems([])
    }
  }, [onlyNewsNotificationParams])

  const handleFilterUnread = async () => {
    setIsFilterUnread(!isFilterUnread)
    try {
      setIsLoadingNotification(true)
      const { data } = await notificationStore.getCurrentUserNotification(
        onlyNewsNotificationParams,
        !isFilterUnread
      )
      setItems(data.data)
    } catch (e) {
      setIsFilterUnread(!isFilterUnread)
      console.log(e)
      message.error(e.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingNotification(false)
    }
  }

  const content = () => {
    return (
      <List
        itemLayout="horizontal"
        loading={{
          tip: 'Đang tải thông báo...',
          spinning: isLoadingNotification,
        }}
        locale={{
          emptyText: <EmptyContent description={'Không có thông báo!'} />,
        }}
        dataSource={items}
        renderItem={item => (
          <div style={{ position: 'relative' }}>
            <List.Item
              onClick={() => {
                handleReadNotify(item.id, item.status, 1)
                goToContentNotification(item.type, item.code)
              }}>
              <ListItemWrapper>
                <ListItemLeft>
                  <AvatarItem
                    src={
                      item.userImage &&
                      `${apiUrl}/api/v1/images/${item.userImage}`
                    }>
                    {subStringAvatar(item.owner)}
                  </AvatarItem>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}>
                    <ContentNotification isRead={item.status}>
                      {WordBreak(item.content.trim(), true)}
                    </ContentNotification>
                    <span
                      style={{
                        color: '#1890FF',
                        marginTop: '4px',
                      }}>
                      {date_format.renderTime(item.time_created)}
                    </span>
                  </div>
                </ListItemLeft>
                <ListItemRight>
                  <div
                    style={{
                      minWidth: '135px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    {StatusTag(TYPE_STATUS.NOTIFICATION, item.type)}
                  </div>
                </ListItemRight>
              </ListItemWrapper>
            </List.Item>
            <ControlNotification className={'control-notification'}>
              <Dropdown
                overlay={() => menuControlNotification(item.id, item.status)}
                placement="bottomLeft"
                arrow>
                <MoreOutlined style={styleIcon} />
              </Dropdown>
            </ControlNotification>
          </div>
        )}
      />
    )
  }

  const handleTabClick = key => {
    form.setFieldsValue({
      search: null,
    })
    notificationStore.clearKeyword()
    if (key === '1') {
      const queryStringParams = queryString.stringify({
        only_news_notification: false,
      })
      history.push(`/notification?${queryStringParams}`)
    } else {
      const queryStringParams = queryString.stringify({
        only_news_notification: true,
      })
      history.push(`/notification?${queryStringParams}`)
    }
  }

  const onSearchTitle = async keyword => {
    try {
      setIsLoadingNotification(true)
      notificationStore.setFilter('keyword', keyword.trim())
      if (!keyword) {
        notificationStore.setFilter('notificationListPageIndex', 0)
        setPageIndex(1)
      }
      const queryStringParams = queryString.stringify({
        only_news_notification: onlyNewsNotificationParams,
        keyword: keyword.trim(),
      })
      const { data } = await notificationStore.getCurrentUserNotification(
        onlyNewsNotificationParams,
        isFilterUnread
      )
      history.replace(`/notification?${queryStringParams}`)
      setItems(data.data)
    } catch (e) {
      message.error(e.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingNotification(false)
    }
  }

  return (
    <DashboardLayout title={'Link People - Link the World'}>
      <Helmet>
        <title>Thông báo | {systemName} Portal</title>
      </Helmet>
      <PageTitle
        location={props.location}
        marginBottom={10}
        title={'Thông Báo'}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            width: '700px',
          }}>
          <div style={{ paddingTop: '10px' }}>
            <Form form={form}>
              <Form.Item name={'search'}>
                <Input.Search
                  allowClear
                  onSearch={value => onSearchTitle(value)}
                  placeholder={'Tìm kiếm theo tiêu đề ...'}
                  style={{ width: '300px' }}
                  maxLength={700}
                />
              </Form.Item>
            </Form>
          </div>
          <Checkbox checked={isFilterUnread} onChange={handleFilterUnread}>
            Hiển thị thông báo chưa đọc
          </Checkbox>
          <Button icon={<CheckOutlined />} onClick={handleMarkAsRead}>
            Đánh dấu tất cả là đã đọc
          </Button>
        </div>
      </PageTitle>
      <ContentBlockWrapper>
        <NotificationListWrapper id={'divScroll'}>
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div className={'center-spin'}>
                {items.length > 30 ? <Spin tip="Đang tải..." /> : ''}
              </div>
            }
            endMessage={null}
            scrollableTarget={'divScroll'}>
            <div style={{ marginLeft: '30px' }}>
              <Tabs activeKey={activeKey} onTabClick={handleTabClick}>
                <Tabs.TabPane tab="Thông báo" key="1">
                  {content()}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Thông báo chung" key="2">
                  {content()}
                </Tabs.TabPane>
              </Tabs>
            </div>
          </InfiniteScroll>
        </NotificationListWrapper>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

NotificationPage.propTypes = {}

export default inject('notificationStore')(observer(NotificationPage))
