import React, { Fragment, memo, useCallback, useEffect, useState } from 'react'
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip,
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import EmptyContent from '../../../components/EmptyContent'
import { useLocation, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { CONFIG_DOCUMENT_TYPE } from '../../../constants'
import queryString from 'query-string'
import validator from './../../../validator'

const { TabPane } = Tabs
const { Option } = Select

const InternalDocumentConfigDocumentBooks = props => {
  const { bookStore, commonStore, history } = props
  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)

  const [formUpdateBookGroup] = Form.useForm()
  const [formCreateBookGroup] = Form.useForm()
  const [formUpdateBook] = Form.useForm()
  const [formCreateBook] = Form.useForm()

  const [isLoading, setIsLoading] = useState(false)

  const [isUpdating, setIsUpdating] = useState(false)

  // Group book
  const [selectedBookGroup, setSelectedBookGroup] = useState(null)

  useEffect(() => {
    if (queryStringParse.config_type === CONFIG_DOCUMENT_TYPE.CONFIG_BOOK) {
      setActiveConfigKey(CONFIG_DOCUMENT_TYPE.CONFIG_BOOK)
    } else {
      setActiveConfigKey(CONFIG_DOCUMENT_TYPE.CONFIG_BOOK_GROUP)
    }
    setIsLoading(true)
    bookStore
      .getBookGroup()
      .catch(error => message.error(error.vi))
      .finally(() => {
        setIsLoading(false)
      })
    return () => {
      bookStore.clearBookGroupList()
      bookStore.clearBookListOfGroup()
    }
  }, [])

  useEffect(() => {
    setFormUpdateBookGroupDetail()
  }, [selectedBookGroup])

  const [showModalUpdateBookGroup, setShowModalUpdateBookGroup] = useState(
    false
  )
  const [showModalCreateBookGroup, setShowModalCreateBookGroup] = useState(
    false
  )

  const tableColumnsBookGroup = [
    {
      title: 'Tên nhóm sổ văn bản',
      width: 90,
      render: record => record.name,
    },
    {
      title: 'Tác vụ',
      align: 'right',
      width: 10,
      render: record => (
        <Fragment>
          <Tooltip title={'Sửa thông tin nhóm sổ văn bản'}>
            <EditOutlined
              className={'anticon-color'}
              onClick={() => handleClickUpdateBookGroup(record)}
            />
          </Tooltip>
          <Divider type={'vertical'} />
          <Tooltip title={'Xoá nhóm sổ văn bản'}>
            <Popconfirm
              onConfirm={() => handleDeleteBookGroup(record.id)}
              okText={'Đồng ý'}
              cancelText={'Không'}
              okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xoá nhóm sổ văn bản này?'}>
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </Fragment>
      ),
    },
  ]

  const handleClickUpdateBookGroup = selectedBookGroup => {
    setSelectedBookGroup(selectedBookGroup)
    setShowModalUpdateBookGroup(true)
    setFormUpdateBookGroupDetail()
  }
  const setFormUpdateBookGroupDetail = () => {
    formUpdateBookGroup.setFieldsValue({
      id: selectedBookGroup && selectedBookGroup.id,
      name:
        selectedBookGroup && selectedBookGroup.name.replace(/\s+/g, ' ').trim(),
      type: selectedBookGroup && selectedBookGroup.type,
    })
  }
  const handleUpdateBookGroup = values => {
    setIsUpdating(true)
    bookStore
      .updateBookGroup(
        values.id,
        values.name.replace(/\s+/g, ' ').trim(),
        values.type
      )
      .then(() => {
        bookStore
          .getBookGroup()
          .then(response => {
            setShowModalUpdateBookGroup(false)
            message.success('Cập nhật nhóm sổ văn bản thành công!')
            return Promise.resolve(response)
          })
          .catch(error => Promise.reject(error))
          .finally(() => setIsUpdating(false))
      })
      .catch(error => {
        message.error(error.vi)
      })
      .finally(() => {
        setIsUpdating(false)
      })
  }

  const handleCreateBookGroup = values => {
    setIsUpdating(true)
    bookStore
      .createBookGroup(values.name.replace(/\s+/g, ' ').trim(), values.type)
      .then(() => {
        setIsUpdating(false)
        message.success('Tạo nhóm sổ văn bản thành công!')
      })
      .catch(error => {
        setIsUpdating(false)
        message.error(error.vi)
      })
      .finally(() => {
        setShowModalCreateBookGroup(false)
        bookStore.getBookGroup()
      })
  }

  const handleDeleteBookGroup = id => {
    setIsLoading(true)
    bookStore
      .deleteBookGroup(id)
      .then(() => {
        message.success('Xóa nhóm sổ văn bản thành công!')
      })
      .catch(error => message.error(error.vi))
      .finally(() => {
        bookStore.getBookGroup()
        setIsLoading(false)
      })
  }

  // Book
  const [selectedBook, setSelectedBook] = useState(null)

  useEffect(() => {
    formUpdateBook.setFieldsValue({
      book_group_id: selectedBook && selectedBook.book_group_id,
      id: selectedBook && selectedBook.id,
      name: selectedBook && selectedBook.name.replace(/\s+/g, ' ').trim(),
      type: selectedBook && selectedBook.type,
    })
  }, [selectedBook])

  const [showModalUpdateBook, setShowModalUpdateBook] = useState(false)
  const [showModalCreateBook, setShowModalCreateBook] = useState(false)

  const tableColumnsBook = [
    {
      title: 'Tên sổ văn bản',
      width: 90,
      render: record => record.name,
    },
    {
      title: 'Tác vụ',
      align: 'right',
      width: 10,
      render: record => (
        <Fragment>
          <Tooltip title={'Sửa thông tin sổ văn bản'}>
            <EditOutlined
              className={'anticon-color'}
              onClick={() => handleClickUpdateBook(record)}
            />
          </Tooltip>
          <Divider type={'vertical'} />
          <Tooltip title={'Xoá sổ văn bản'}>
            <Popconfirm
              onConfirm={() => handleDeleteBook(record.id)}
              okText={'Đồng ý'}
              cancelText={'Không'}
              okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xoá sổ văn bản này?'}>
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </Fragment>
      ),
    },
  ]

  const [selectedBookGroupId, setSelectedBookGroupId] = useState(null)

  const handleChangeBookGroup = id => {
    setIsLoading(true)
    bookStore
      .getBookByBookGroupId(id)
      .then(() => setSelectedBookGroupId(id))
      .catch(error => message.error(error.vi))
      .finally(() => setIsLoading(false))
  }
  const handleClickUpdateBook = selectedBook => {
    setSelectedBook(selectedBook)
    setShowModalUpdateBook(true)
  }
  const handleUpdateBook = values => {
    setIsUpdating(true)
    bookStore
      .updateBook(
        values.book_group_id,
        values.id,
        values.name.replace(/\s+/g, ' ').trim(),
        values.type
      )
      .then(() => {
        setIsUpdating(false)
        setSelectedBookGroupId(values.book_group_id)
        message.success('Cập nhật sổ văn bản thành công!')
      })
      .catch(error => {
        setIsUpdating(false)
        message.error(error.vi)
      })
      .finally(() => {
        setShowModalUpdateBook(false)
        bookStore.getBookByBookGroupId(
          selectedBookGroupId && selectedBookGroupId
        )
      })
  }
  const handleCreateBook = values => {
    setIsUpdating(true)
    bookStore
      .createBook(values.id, values.name.replace(/\s+/g, ' ').trim())
      .then(() => {
        setIsUpdating(false)
        setSelectedBookGroupId(values.id)
        message.success('Tạo sổ văn bản thành công!')
      })
      .catch(error => {
        setIsUpdating(false)
        message.error(error.vi)
      })
      .finally(() => {
        formCreateBook.resetFields()
        setShowModalCreateBook(false)
        bookStore.getBookByBookGroupId(values.id)
      })
  }
  const handleDeleteBook = id => {
    setIsLoading(true)
    bookStore
      .deleteBook(id)
      .then(() => {
        message.success('Xóa sổ văn bản thành công!')
      })
      .catch(error => message.error(error.vi))
      .finally(() => {
        bookStore.getBookByBookGroupId(
          selectedBookGroupId && selectedBookGroupId
        )
        setIsLoading(false)
      })
  }
  const handleCancelCreateBookGroup = () => {
    setShowModalCreateBookGroup(false)
  }
  const handleCancelCreateBook = () => {
    setShowModalCreateBook(false)
  }
  const handleClickCreateBookGroup = () => {
    formCreateBookGroup.resetFields()
    setShowModalCreateBookGroup(true)
  }

  const handleClickCreateBook = () => {
    // formCreateBook.setFieldsValue({
    //   name: null,
    //   id: selectedBookGroupId,
    // })
    formCreateBook.resetFields()
    setShowModalCreateBook(true)
  }
  const handleCancelUpdateBookGroup = useCallback(() => {
    setShowModalUpdateBookGroup(false)
    formUpdateBookGroup.resetFields()
  }, [])

  const BookGroupCreateButton = (
    <Button type={'primary'} onClick={handleClickCreateBookGroup}>
      <PlusCircleOutlined />
      Tạo mới nhóm sổ văn bản
    </Button>
  )
  const BookCreateButton = (
    <Button type={'primary'} onClick={handleClickCreateBook}>
      <PlusCircleOutlined />
      Tạo mới sổ văn bản
    </Button>
  )
  const [tabBarExtraContent, setTabBarExtraContent] = useState(
    BookGroupCreateButton
  )
  const [activeConfigKey, setActiveConfigKey] = useState(
    CONFIG_DOCUMENT_TYPE.CONFIG_BOOK_GROUP
  )

  const changeTab = key => {
    setActiveConfigKey(key)

    let path = '/internal-document/config-document/document-books'

    if (props.historyPath) {
      path = props.historyPath
    }

    history.replace(`${path}?config_type=${key}`)
  }

  useEffect(() => {
    if (activeConfigKey === CONFIG_DOCUMENT_TYPE.CONFIG_BOOK_GROUP) {
      setTabBarExtraContent(BookGroupCreateButton)
    } else {
      setTabBarExtraContent(BookCreateButton)
    }
  }, [activeConfigKey, selectedBookGroupId])

  return (
    <>
      <Tabs
        activeKey={activeConfigKey}
        onChange={changeTab}
        tabBarExtraContent={tabBarExtraContent}
        type="card">
        <TabPane
          tab="Cấu hình nhóm sổ văn bản"
          key={CONFIG_DOCUMENT_TYPE.CONFIG_BOOK_GROUP}>
          <Table
            loading={{
              tip: 'Đang tải...',
              spinning: isLoading,
            }}
            columns={tableColumnsBookGroup}
            dataSource={bookStore.bookGroupList}
            pagination={false}
            rowKey={record => record.id}
          />
          <Modal
            visible={showModalUpdateBookGroup}
            footer={null}
            getContainer={false}
            onCancel={handleCancelUpdateBookGroup}
            title={
              <Fragment>
                <ExclamationCircleOutlined
                  style={{
                    color: commonStore.appTheme.solidColor,
                    marginRight: 10,
                  }}
                />
                Sửa thông tin nhóm sổ văn bản
              </Fragment>
            }>
            <Form
              form={formUpdateBookGroup}
              layout={'vertical'}
              onFinish={handleUpdateBookGroup}>
              <Form.Item style={{ display: 'none' }} label={''} name={'id'}>
                <Input placeholder={'Nhập tên nhóm sổ văn bản'} />
              </Form.Item>
              <Form.Item
                label={'Loại nhóm sổ văn bản'}
                name={'type'}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn loại nhóm sổ văn bản!',
                  },
                ]}>
                <Select placeholder={'Chọn loại nhóm sổ'} showArrow disabled>
                  <Option value={'DI'}>Nhóm sổ đi</Option>
                  <Option value={'DEN'}>Nhóm sổ đến</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={'Tên nhóm sổ văn bản'}
                name={'name'}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập tên nhóm sổ văn bản!',
                  },
                  { validator: validator.validateInputString },
                ]}>
                <Input placeholder={'Nhập tên nhóm sổ văn bản'} />
              </Form.Item>
              <Row justify={'end'}>
                <Space>
                  <Button onClick={() => setShowModalUpdateBookGroup(false)}>
                    Hủy
                  </Button>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    loading={isUpdating}>
                    Cập nhật
                  </Button>
                </Space>
              </Row>
            </Form>
          </Modal>
          <Modal
            visible={showModalCreateBookGroup}
            footer={null}
            getContainer={false}
            onCancel={handleCancelCreateBookGroup}
            title={
              <Fragment>
                <ExclamationCircleOutlined
                  style={{
                    color: commonStore.appTheme.solidColor,
                    marginRight: 10,
                  }}
                />
                Tạo mới nhóm sổ văn bản
              </Fragment>
            }>
            <Form
              form={formCreateBookGroup}
              layout={'vertical'}
              onFinish={handleCreateBookGroup}>
              <Form.Item style={{ display: 'none' }} label={''} name={'id'}>
                <Input placeholder={'Nhập tên nhóm sổ văn bản'} />
              </Form.Item>
              <Form.Item
                label={'Loại nhóm sổ văn bản'}
                name={'type'}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn loại nhóm sổ văn bản!',
                  },
                ]}>
                <Select placeholder={'Chọn loại nhóm sổ'} showArrow>
                  <Option value={'DI'}>Nhóm sổ đi</Option>
                  <Option value={'DEN'}>Nhóm sổ đến</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={'Tên nhóm sổ văn bản'}
                name={'name'}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập tên nhóm sổ văn bản!',
                  },
                  { validator: validator.validateInputString },
                ]}>
                <Input placeholder={'Nhập tên nhóm sổ văn bản'} />
              </Form.Item>
              <Row justify={'end'}>
                <Space>
                  <Button onClick={handleCancelCreateBookGroup}>Hủy</Button>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    loading={isUpdating}>
                    Tạo mới
                  </Button>
                </Space>
              </Row>
            </Form>
          </Modal>
        </TabPane>
        <TabPane
          tab="Cấu hình sổ văn bản"
          key={CONFIG_DOCUMENT_TYPE.CONFIG_BOOK}>
          <Select
            onChange={handleChangeBookGroup}
            notFoundContent={<EmptyContent />}
            value={selectedBookGroupId}
            style={{
              width: '100%',
              marginBottom: '15px',
            }}
            placeholder={'Chọn nhóm sổ văn bản'}>
            {bookStore.bookGroupList &&
              bookStore.bookGroupList.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
          {bookStore.bookListOfGroup && bookStore.bookListOfGroup.length > 0 && (
            <Table
              loading={{
                tip: 'Đang tải...',
                spinning: isLoading,
              }}
              columns={tableColumnsBook}
              dataSource={bookStore.bookListOfGroup}
              pagination={false}
              rowKey={record => record.id}
            />
          )}
          <Modal
            visible={showModalUpdateBook}
            footer={null}
            getContainer={false}
            onCancel={() => {
              setShowModalUpdateBook(false)
              formUpdateBook.setFieldsValue({
                name: selectedBook.name.replace(/\s+/g, ' ').trim(),
              })
            }}
            title={
              <Fragment>
                <ExclamationCircleOutlined
                  style={{
                    color: commonStore.appTheme.solidColor,
                    marginRight: 10,
                  }}
                />
                Sửa thông tin sổ văn bản
              </Fragment>
            }>
            <Form
              form={formUpdateBook}
              layout={'vertical'}
              onFinish={handleUpdateBook}>
              <Form.Item style={{ display: 'none' }} label={''} name={'id'}>
                <Input disabled={true} placeholder={'Nhập tên sổ văn bản'} />
              </Form.Item>

              <Form.Item label={'Nhóm sổ văn bản'} name={'book_group_id'}>
                <Select
                  disabled={true}
                  notFoundContent={<EmptyContent />}
                  style={{
                    width: '100%',
                    marginBottom: '15px',
                  }}
                  placeholder={'Chọn nhóm sổ văn bản'}>
                  {bookStore.bookGroupList &&
                    bookStore.bookGroupList.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={'Tên sổ văn bản'}
                name={'name'}
                rules={[
                  { required: true, message: 'Vui lòng nhập tên sổ văn bản!' },
                  { validator: validator.validateInputString },
                ]}>
                <Input placeholder={'Nhập tên sổ văn bản'} />
              </Form.Item>
              <Row justify={'end'}>
                <Space>
                  <Button
                    onClick={() => {
                      setShowModalUpdateBook(false)
                      formUpdateBook.setFieldsValue({
                        name: selectedBook.name.replace(/\s+/g, ' ').trim(),
                      })
                    }}>
                    Hủy
                  </Button>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    loading={isUpdating}>
                    Cập nhật
                  </Button>
                </Space>
              </Row>
            </Form>
          </Modal>
          <Modal
            visible={showModalCreateBook}
            footer={null}
            getContainer={false}
            onCancel={handleCancelCreateBook}
            title={
              <Fragment>
                <ExclamationCircleOutlined
                  style={{
                    color: commonStore.appTheme.solidColor,
                    marginRight: 10,
                  }}
                />
                Tạo mới sổ văn bản
              </Fragment>
            }>
            <Form
              form={formCreateBook}
              layout={'vertical'}
              onFinish={handleCreateBook}>
              <Form.Item
                label={'Nhóm sổ văn bản'}
                name={'id'}
                rules={[
                  { required: true, message: 'Vui lòng chọn nhóm sổ văn bản!' },
                ]}>
                <Select
                  notFoundContent={<EmptyContent />}
                  placeholder="Chọn nhóm sổ văn bản">
                  {bookStore.bookGroupList &&
                    bookStore.bookGroupList.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={'Tên sổ văn bản'}
                name={'name'}
                rules={[
                  { required: true, message: 'Vui lòng nhập tên sổ văn bản!' },
                  { validator: validator.validateInputString },
                ]}>
                <Input placeholder={'Nhập tên sổ văn bản'} />
              </Form.Item>
              <Row justify={'end'}>
                <Space>
                  <Button onClick={handleCancelCreateBook}>Hủy</Button>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    loading={isUpdating}>
                    Tạo mới
                  </Button>
                </Space>
              </Row>
            </Form>
          </Modal>
        </TabPane>
      </Tabs>
    </>
  )
}

InternalDocumentConfigDocumentBooks.propTypes = {}

export default memo(
  withRouter(
    inject(
      'bookStore',
      'commonStore'
    )(observer(InternalDocumentConfigDocumentBooks))
  )
)
