import { requests } from './DefaultRequest'
import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
const requestBaseUrl = `/api/v1/rui-ro-nhan-dien-phat-sinh`

export default {
  getRiskAriseList: params =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/rui-ro-nhan-dien-phat-sinh`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        type: params?.type,
        keyword: params?.keyword,
        page_num: params?.page,
        page_size: params?.pageSize,
        createdStart: params?.createdStart,
        createdEnd: params?.createdEnd,
      },
    }),

  getRiskAriseTypes: () => requests.get(`/api/v1/rui-ro-nhan-dien-type`),

  getRiskAriseDetail: code => requests.get(`${requestBaseUrl}/${code}`),

  createRiskArise: payload => requests.post(requestBaseUrl, payload),

  updateRiskArise: (code, payload) =>
    requests.put(`${requestBaseUrl}/${code}`, payload),

  delete: id =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/rui-ro-nhan-dien-phat-sinh/${id}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),

  export: filter =>
    requests.getFile({
      url: `${requestBaseUrl}/export`,
      searchs: filter,
      responseType: 'blob',
    }),
}
