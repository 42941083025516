import { requests } from './DefaultRequest'

export default {
  getManagerInventoryList: () =>
    requests.get(`/api/v1/approval_template?template_type=ASSET`),

  getManagerInventoryLists: param => {
    if (param) {
      return requests.get(
        `/api/v1/approval_templates?template_type=ASSET&${param}`
      )
    } else {
      return requests.get(`/api/v1/approval_templates?template_type=ASSET`)
    }
  },

  getManagerInventoryDetail: code =>
    requests.get(`/api/v1/approval_template/${code}`),

  createManagerInventory: payload =>
    requests.post(`/api/v1/approval_template`, payload),

  updateManagerInventory: (code, payload) =>
    requests.put(`/api/v1/approval_template/${code}`, payload),

  deleteManagerInventory: code =>
    requests.delete(`/api/v1/approval_template/${code}`),
}
