import {
  Button,
  Col,
  Form,
  InputNumber,
  Input,
  message,
  Modal,
  Row,
  Space,
  Empty,
  Steps,
  Tooltip,
  Tag,
  Divider,
  Select,
} from 'antd'
import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { ButtonFooter } from '../../WorkPage/PopupWorkForm/PopupWorkFormStyled'
import validator from '../../../validator'
import { AvatarHasName } from '../../../components/Common/Avatar'
import TableComponent from '../../../components/Common/TableComponent'
import { SelectList } from '../../../pages/MissionCreatePage/MissionCreatePageStyled'
import DistributionVotesListSelect from '../DistributionVotesListSelect'
import { blue } from '../../../color'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup'
import { ASSIGNEE_TYPE } from '../../../constants'
import ProposalStepApprove from '../ProposalStepApprove'

const DistributionVotesUpdateDetails = props => {
  const [form] = Form.useForm()

  const [followerList, setFollowerList] = useState([])
  const [approvalLevels, setApprovalLevels] = useState([])
  const [
    selectedApproveTemplateCode,
    setSelectedApproveTemplateCode,
  ] = useState('')
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false)
  const [approvalTemplateList, setApprovalTemplateList] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [warnings, setWarnings] = useState({})
  const {
    DistributionVotesStore,
    selectPeopleStore,
    approvalTemplateStore,
    managementAssetWarehouseStore,
    proposalStore,
    templateId,
    btn,
    check,
  } = props
  const [isCreating, setIsCreating] = useState(false)
  const [dataTable, setDataTable] = useState([])

  const { distributionVotesDetail } = DistributionVotesStore

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleRemoveSelect,
    clearSelected,
    companyCode,
    setSelectUserData,
    setSelectDepartmentData,
    assigneePopupType,
    managerInventoryListLevels,
    clearSelectUserData,
  } = selectPeopleStore

  useEffect(() => {
    ;(async () => {
      if (!check) {
        await clearSelectUserData()
        await setFollowerList([])
      }
      await clearSelected()
      await loadApprovalTemplateList()

      await setApprovalTemplateList(approvalTemplateStore.approvalTemplateList)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!check) {
        await getDistributionVotesDetail(props.templateId)
      }
    })()

    // return () => {
    //   clearSelectUserData('VIEW')
    // }
  }, [props.templateId])

  const getDistributionVotesDetail = async id => {
    try {
      setIsLoading(true)
      await DistributionVotesStore.clearDistributionVotesDetail()
      await managementAssetWarehouseStore.getManagementAssetWarehousetLists()
      await DistributionVotesStore.getDistributionVotesDetail(id)
      // assignee_type: ASSIGNEE_TYPE.USER,
      // full_name: item.name_uppercase || "",
      // id: item.user_name || "",
      // image_id: item.imageId || "",
      // permission: ASSIGNEE_TYPE.FOLLOWER,
      // department_code: item.department.code || "",
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      name: distributionVotesDetail.title,
    })
    const selectedData = managementAssetWarehouseStore.managementAssetWarehouseLists.filter(
      item => {
        return distributionVotesDetail.proposal_asset_details.some(
          dataItem => dataItem.asset_id === item.id
        )
      }
    )
    const selectedDataWithTitle = selectedData.map(item => {
      const foundDetail = distributionVotesDetail.proposal_asset_details.find(
        detail => detail.asset_id === item.id
      )
      if (foundDetail) {
        return {
          ...item,
          amount: foundDetail.amount,
          purpose: foundDetail.purpose,
        }
      } else {
        return {
          ...item,
          amount: 0,
          purpose: '',
        }
      }
    })

    selectPeopleStore.setSelectUserData({
      VIEW: distributionVotesDetail.followers.map(follower => ({
        assignee_type: ASSIGNEE_TYPE.USER,
        full_name: follower?.name_lowercase?.toUpperCase(),
        id: follower?.user_name,
        image_id: follower?.image_id,
        permission: ASSIGNEE_TYPE.FOLLOWER,
      })),
    })
    setDataTable(selectedDataWithTitle)
    setApprovalLevels([...distributionVotesDetail.approval_levels])
  }, [distributionVotesDetail])

  const loadApprovalTemplateList = async () => {
    try {
      await approvalTemplateStore.getApprovalTemplateListSearch(
        'template_type=asset'
      )
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
    }
  }

  useEffect(() => {
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const handleToggleCreateModal = bool => event => {
    setIsVisibleCreateModal(bool)
  }

  const onFinish = async value => {
    setIsCreating(true)
    const removeFollowers = distributionVotesDetail.followers.filter(
      follower => {
        if (!followerList.some(item => item.id === follower.user_name)) {
          return follower
        }
      }
    )

    const newFollowers = followerList.filter(follower => {
      if (
        !distributionVotesDetail.followers.some(
          item => item.user_name === follower.id
        )
      ) {
        return follower
      }
    })
    try {
      let level =
        proposalStore.defaultApprovalConfigUpdate.approvalLevels.length || 0
      if (level === 0) {
        message.error('Chọn cấp duyệt')
        return
      }
      let level1 = dataTable.length || 0
      if (level1 === 0) {
        message.error('Chọn danh sách văn phòng phẩm')
        return
      }

      // Kiểm tra độ dài của purpose
      const invalidPurposes = dataTable.filter(
        item => item.purpose && item.purpose.length > 100
      )
      if (invalidPurposes.length > 0) {
        message.error('Mục đích sử dụng không được vượt quá 100 ký tự')
        setIsCreating(false)
        return
      }

      // Validate that the 'amount' field is not empty
      const invalidItems = dataTable.filter(
        item => item.amount === null || item.amount === undefined
      )
      if (invalidItems.length > 0) {
        // Set warnings for invalid items
        const newWarnings = {}
        invalidItems.forEach(item => {
          newWarnings[item.id] = 'Không bỏ trống!'
        })
        setWarnings(newWarnings)
        setIsCreating(false)
        return
      }
      const approvalLevels = proposalStore.defaultApprovalConfigUpdate.approvalLevels.map(
        (approvalLevel, index) => ({
          status: index === 0 ? 'CREATED' : 'WAITING',
          allow_edit: approvalLevel.allow_edit,
          code: approvalLevel.code,
          isDeleted: approvalLevel.is_deleted,
          level: approvalLevel.level,
          name: approvalLevel.name,
          username: approvalLevel.username,
        })
      )
      const submitValues = {
        title: value.name,
        type: 'proposal_asset',
        proposal_asset_details: dataTable.map(item => ({
          asset_id: item.id,
          amount: item.amount,
          purpose: item.purpose,
        })),
        assignees: {
          remove_items: removeFollowers.map(follower => ({
            assignee_code: follower.user_name,
            assignee_type: 'USER',
            permission: 'VIEW',
          })),
          new_items: newFollowers.map(follower => ({
            assignee_code: follower.id,
            assignee_type: 'USER',
            permission: 'VIEW',
          })),
        },
        approval_levels: approvalLevels.map(item => ({
          allow_edit: item.allow_edit,
          code: item.code,
          name: item.name,
          username: item.username,
        })),
      }

      await DistributionVotesStore.updateDistributionVotes(
        templateId,
        submitValues
      )
      if (check) {
        await DistributionVotesStore.getDistributionVotesDetail(templateId)
        // Xử lý kết quả ở đây
      }
      message.success('Cập nhật thành công')
      props.onCancel()
    } catch (error) {
      console.log(error)
      message.error('Cập nhật thất bại')
      props.onCancel()
    } finally {
      setIsCreating(false)
    }
    setIsCreating(false)
  }

  //Lấy approvalLevels
  const getApprovalLevelTemplate = async code => {
    try {
      const response = await approvalTemplateStore.getApprovalLevelTemplateByATCode(
        code
      )
      const approvalLevelsChange = response.data.map(
        (approvalLevel, index) => ({
          status: index === 0 ? 'CREATED' : 'WAITING',
          allow_edit: approvalLevel.allow_edit,
          code: approvalLevel.code,
          isDeleted: approvalLevel.isDeleted,
          level: approvalLevel.level,
          name: approvalLevel.name,
          username: approvalLevel.username,
        })
      )

      setApprovalLevels(approvalLevelsChange)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    }
  }

  const onChangeApprovalTemplate = async value => {
    setSelectedApproveTemplateCode(value)
    await getApprovalLevelTemplate(value)
  }

  const tableColumns = [
    {
      title: 'STT',
      render: (id, record, index) => {
        ++index
        return index
      },
      width: 10,
    },
    {
      title: 'Tên văn phòng phẩm',
      render: record => <div>{record.asset_name}</div>,
      width: 230,
    },
    {
      title: 'Đặc tính',
      align: 'center',
      render: record => <div>{record?.property}</div>,
      width: 70,
    },
    {
      title: 'Đơn vị tính',
      align: 'center',
      render: record => <div>{record?.asset_unit_name}</div>,
      width: 70,
    },
    {
      title: 'Số lượng đề nghị',
      align: 'center',
      width: 80,
      render: record => (
        <Form.Item
          validateStatus={warnings[record.id] ? 'error' : ''}
          help={warnings[record.id]}
          style={{ marginBottom: 0 }}>
          <InputNumber
            value={record?.amount}
            placeholder="Nhập số lượng"
            parser={value => value?.replace(/\$\s?|(,*)/g, '')}
            onChange={value => {
              const updatedValue = value < 0 ? 0 : value
              const updatedDataT = dataTable.map(item => {
                if (item.id === record.id) {
                  return { ...item, amount: updatedValue }
                }
                return item
              })
              // Bỏ wanring validate
              const updatedWarnings = { ...warnings }
              if (updatedValue !== null && updatedValue !== undefined) {
                delete updatedWarnings[record.id]
              }
              // Cập nhật trạng thái
              setDataTable(updatedDataT)
              setWarnings(updatedWarnings)
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Mục đích sử dụng',
      align: 'center',
      render: record => {
        return (
          <div>
            <Input
              placeholder="Nhập mô tả"
              type="text"
              value={record.purpose}
              onChange={e => {
                const value = e.target.value
                const updatedData = dataTable.map(item => {
                  if (item.id === record.id) {
                    return { ...item, purpose: e.target.value }
                  }
                  return item
                })
                // Cập nhật state
                setDataTable(updatedData)
                if (value?.length > 100) {
                  message.error(
                    'Mục đích sử dụng không được vượt quá 100 ký tự'
                  )
                  return
                }
              }}
            />
            <div
              style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                textAlign: 'center',
              }}>
              {record?.purpose?.length}/100
            </div>
          </div>
        )
      },
      width: 90,
    },
  ]

  const sortDataTable = data => {
    return data.sort((a, b) => {
      let nameA = a.asset_name.toLowerCase()
      let nameB = b.asset_name.toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    })
  }

  return (
    <>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              label={'Nội dung:'}
              name={'name'}
              rules={[
                { required: true, message: 'Vui lòng nhập nội dung' },
                {
                  max: 500,
                  message: 'Nội dung không được dài quá 500 ký tự!',
                },
                { validator: validator.validateInputString },
              ]}>
              <Input placeholder={'Phiếu đề nghị cung cấp văn phòng phẩm'} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
            <h3
              style={{
                marginRight: '10px',
                marginLeft: '0px',
                marginBottom: '0px',
                marginTop: '0px',
              }}>
              Danh sách văn phòng phẩm :{' '}
            </h3>
            <Button onClick={handleToggleCreateModal(true)}>
              <span style={{ color: 'rgb(44, 101, 172)' }}>Chọn</span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TableComponent
              rowKey={record => record.code || record.asset_id}
              // dataSource={managerInventoryStore.managerInventoryList}
              dataSource={sortDataTable(dataTable) || []}
              columns={tableColumns}
              pagination={false}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={'Không có dữ liệu'}
                  />
                ),
              }}
              loading={isLoading}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={12}>
            <label
              style={{ display: 'inline-block', margin: '15px 8px 25px 0' }}>
              Người theo dõi:
            </label>

            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)
                  }
                />
              </Tooltip>
            </Space>

            <SelectList>
              {followerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </SelectList>
          </Col>
        </Row>
        <Divider
          style={{ width: '80%', marginBottom: '30px', fontWeight: 'bold' }}
        />
        <div style={{ display: 'none' }}>
          <p className="custom-marker">Cấp duyệt:</p>
          <Select
            showSearch
            placeholder="Chọn cấp duyệt"
            optionFilterProp="children"
            onChange={onChangeApprovalTemplate}
            filterOption={(input, option) =>
              (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            style={{ width: '300px' }}
            value={selectedApproveTemplateCode}>
            {approvalTemplateList &&
              approvalTemplateList.map(item => (
                <Select.Option key={item.code} value={item.code}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </div>
        <div style={{ display: 'none' }}>
          <ProposalStepApprove
            approvalLevelList={approvalLevels}
            styleCre={'create'}
          />
        </div>
        <ButtonFooter>
          <Space>
            <Button danger onClick={props.onCancel}>
              Hủy bỏ
            </Button>
            <Button type={'primary'} htmlType={'submit'} loading={isCreating}>
              {btn}
            </Button>
          </Space>
        </ButtonFooter>
      </Form>
      <Modal
        width={1200}
        title={'Danh sách VPP'}
        visible={isVisibleCreateModal}
        maskClosable={false}
        onCancel={handleToggleCreateModal(false)}
        footer={null}
        destroyOnClose>
        {isVisibleCreateModal && (
          <DistributionVotesListSelect
            dataTable={dataTable || []}
            onUpdateTable={data => {
              // Merge existing data with the new data
              const updatedData = data.map(newItem => {
                const existingItem = dataTable.find(
                  item => item.id === newItem.id
                )
                return existingItem
                  ? {
                      ...newItem,
                      amount: existingItem.amount,
                      purpose: existingItem.purpose,
                    }
                  : newItem
              })
              setDataTable(updatedData)
            }}
            onCancel={handleToggleCreateModal(false)}
          />
        )}
      </Modal>
      <SelectPeoplePopup
        noLoadSelectData
        disableSelectDepartment
        isOpenCollapse={true}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        isUserDisableList={false}
      />
    </>
  )
}

DistributionVotesUpdateDetails.propTypes = {}

export default inject(
  'proposalStore',
  'managerInventoryStore',
  'DistributionVotesStore',
  'approvalTemplateStore',
  'managementAssetWarehouseStore',
  'selectPeopleStore'
)(observer(DistributionVotesUpdateDetails))
