import { action, observable } from 'mobx'
import { CompanyRequest } from '../requests/CompanyRequest'

class CompanyStore {
  /** Company list */
  @observable companyList = []
  @observable companyDetail = {}
  @observable companyCode = ''
  @action getCompanyList = params => {
    return new Promise((resolve, reject) => {
      CompanyRequest.getCompanyList(params)
        .then(response => {
          this.companyList = response.data
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  @action searchCompanyList = params => {
    return new Promise((resolve, reject) => {
      CompanyRequest.searchCompanyList(params)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  @action getCompanyDetail = code => {
    return new Promise((resolve, reject) => {
      CompanyRequest.getCompanyDetail(code)
        .then(response => {
          this.companyDetail = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getCodeByUsername = username => {
    return new Promise((resolve, reject) => {
      CompanyRequest.getCodeByUsername(username)
        .then(response => {
          this.companyCode = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createCompany = payload => {
    return new Promise((resolve, reject) => {
      CompanyRequest.createCompany(payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action updateCompany = (code, payload) => {
    return new Promise((resolve, reject) => {
      CompanyRequest.updateCompany(code, payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action updateCompanyStatus = (code, payload) => {
    return new Promise((resolve, reject) => {
      CompanyRequest.updateCompanyStatus(code, payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }
  @action updateCompanyType = (code, payload) => {
    return new Promise((resolve, reject) => {
      CompanyRequest.updateCompanyType(code, payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }
  @action deleteCompany = code => {
    return CompanyRequest.deleteCompany(code)
  }

  /** User list of company */
  // TODO: Chờ Luyện update API.
  @observable leaderList = []
  @observable adminList = []
  @observable clericalList = []
  @observable userList = []
  @observable allUserList = []
  @action getUserListWithRole = (role_name, company_code) => {
    return new Promise((resolve, reject) => {
      CompanyRequest.getUserListWithRole(role_name, company_code)
        .then(response => {
          switch (role_name) {
            case 'ADMIN':
              this.adminList = response.data
              break
            case 'USER':
              this.userList = response.data
              break
            case 'CLERICAL':
              this.clericalList = response.data
              break
            case 'LEADER':
              this.leaderList = response.data
              break
            case '':
              this.allUserList = response.data
              break
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Clear store */
  @action clearStore = () => {
    this.companyList.length = 0
  }
  @action clearAllUserList = () => {
    this.allUserList.length = 0
  }
}

export default new CompanyStore()
