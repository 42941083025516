import {
  BankOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  FileTextOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Upload,
  message,
} from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { blue } from '../../../../color'
import { AvatarHasName } from '../../../../components/Common/Avatar'
import FileItem from '../../../../components/Common/FileItemComponent/FileItem'
import NumericInput from '../../../../components/Common/NumericInput'
import RichEditor from '../../../../components/RichEditor/RichEditor'
import SelectPeoplePopup from '../../../../components/SelectPeoplePopup'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_LIST,
  ISO_DATE_FORMAT,
  MODES,
  SHIFTS,
} from '../../../../constants'
import utils from '../../../../utils'
import ApprovalList from '../../LeaveForm/ApproverListModal/Modal'
import {
  FormActionFooter,
  OriginFilesList,
  SelectList,
} from '../../../MissionEditPage/MissionEditPageStyled'
import { submissionMenu } from '../ProposalPage/ProposalPage'
import validator from '../../../../validator'

const { RangePicker } = DatePicker
const { Option } = Select

const ProposalDetailUpdateFollowers = props => {
  const {
    proposalStore,
    selectPeopleStore,
    fileStore,
    proposalType,
    proposalTypeStore,
    name,
  } = props

  const {
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    selectUserData,
    selectDepartmentData,
    selectGroupData,
  } = selectPeopleStore

  const [form] = Form.useForm()

  const CONTENT_REF = useRef()

  const [fileList, setFileList] = useState([])
  const [editorContent, setEditorContent] = useState()
  const [followerList, setFollowerList] = useState([])
  const [applyBieuMau, setApplyBieuMau] = useState(false)

  const { formInputTemplate, otherDetail, formProposalType } = proposalStore
  const [originFiles, setOriginFiles] = useState([])

  const [isUpdating, setIsUpdating] = useState(false)

  const defaultDateRange =
    otherDetail.from_date != null
      ? otherDetail.from_date === ''
        ? []
        : [
            moment(otherDetail.from_date, ISO_DATE_FORMAT),
            moment(otherDetail.to_date, ISO_DATE_FORMAT),
          ]
      : []

  useEffect(() => {
    if (!otherDetail.id) return

    form.setFieldsValue({
      title: otherDetail.title,
      proposal_type: name,
    })

    setEditorContent(otherDetail.reason)

    let followerList = []

    otherDetail.followers.forEach(follower => {
      if (follower?.user_name) {
        followerList.push({
          assignee_type: ASSIGNEE_TYPE.USER,
          full_name: follower.name_lowercase.toUpperCase(),
          id: follower.user_name,
          image_id: follower.image_id,
          permission: ASSIGNEE_TYPE.FOLLOWER,
        })
      }
    })

    if (followerList.length > 0) {
      selectPeopleStore.setSelectUserData({
        ...selectUserData,
        VIEW: followerList,
      })
    }

    setOriginFiles(otherDetail.attachments)
    ;(async () => {
      await proposalStore.getProposalTypeApprovalConfig(
        otherDetail.proposal_type
      )
    })()

    return () => {
      selectPeopleStore.clearSelectUserData('VIEW')
    }
  }, [])

  useEffect(() => {
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const onFinish = async values => {
    const removeFollowers = otherDetail.followers.filter(follower => {
      if (!followerList.some(item => item.id === follower.user_name)) {
        return follower
      }
    })

    const newFollowers = followerList.filter(follower => {
      if (!otherDetail.followers.some(item => item.user_name === follower.id)) {
        return follower
      }
    })

    let proposal = {
      ...values,
      code: otherDetail.proposal_code,

      assignees: {
        remove_items: removeFollowers.map(follower => ({
          assignee_code: follower.user_name,
          assignee_type: 'USER',
          permission: 'VIEW',
        })),
        new_items: newFollowers.map(follower => ({
          assignee_code: follower.id,
          assignee_type: 'USER',
          permission: 'VIEW',
        })),
      },
    }

    try {
      setIsUpdating(true)
      await proposalStore.updateProposalFollowers(proposal)
      await proposalStore.getOtherDetail(proposal.code)
      message.success('Chỉnh sửa thành công!')
      props.onCancel()
    } catch (error) {
      message.error(error.vi || 'Có lỗi xảy ra')
      console.log(error)
    } finally {
      setIsUpdating(false)
    }
  }
  return (
    <>
      <Form
        scrollToFirstError={true}
        form={form}
        name={'phieu-trinh-khac-form'}
        layout={'vertical'}
        onFinish={onFinish}>
        <Row gutter={15}>
          <Col xs={24} lg={12}>
            <label
              style={{ display: 'inline-block', margin: '15px 8px 25px 0' }}>
              Người theo dõi:
            </label>

            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)
                  }
                />
              </Tooltip>
            </Space>

            {followerList.length > 0 && (
              <SelectList>
                {followerList.map(el => (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'}
                    key={el.id}
                    closable
                    onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.full_name}
                      icon={
                        (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                          <BankOutlined />
                        )) ||
                        (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                          <TeamOutlined />
                        ))
                      }
                    />
                  </Tag>
                ))}
              </SelectList>
            )}
          </Col>
        </Row>

        <ApprovalList />
        <SelectPeoplePopup
          disableSelectDepartment
          handleCancelSelectUser={handleCancelSelectUserDepartment}
          handleSubmitSelectUser={() =>
            setIsVisibleSelectUserDepartmentPopup(false)
          }
          isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        />

        <FormActionFooter>
          <Button icon={<CloseOutlined />} danger onClick={props.onCancel}>
            Huỷ bỏ
          </Button>
          <Button
            loading={isUpdating}
            style={{ marginLeft: 10 }}
            icon={<CheckOutlined />}
            type={'primary'}
            htmlType={'submit'}>
            Đồng ý chỉnh sửa
          </Button>
        </FormActionFooter>
      </Form>
    </>
  )
}

ProposalDetailUpdateFollowers.propTypes = {
  onCancel: PropTypes.func.isRequired,
  // code: PropTypes.string.isRequired,
  showSubmissionMenu: PropTypes.func,
}
export default inject(
  'proposalStore',
  'selectPeopleStore',
  'fileStore',
  'proposalTypeStore'
)(observer(ProposalDetailUpdateFollowers))
