import { requests } from './DefaultRequest'

export default {
  getAllAssetTypes: param => {
    if (param) {
      requests.get(`/api/v1/asset-type/list?${param}`)
    } else {
      requests.get(`/api/v1/asset-type/list`)
    }
  },

  getAssetTypeById: id => requests.get(`/api/v1/asset-type/get-by-id/${id}`),

  createAssetType: payload =>
    requests.post('/api/v1/asset-type/create', payload),

  updateAssetType: (id, payload) =>
    requests.put(`/api/v1/asset-type/update/${id}`, payload),

  deteleAssetType: id => requests.delete(`/api/v1/asset-type/delete/${id}`),
}
