import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import PageTitle from '../../../../components/PageTitle'
import ContentBlockWrapper from '../../../../components/ContentBlockWrapper'
import { TableBottomPaginationBlock } from '../../../../components/Common/Table'
import {
  EmptyText,
  Text,
  trimOverLengthString,
} from '../../../../components/Common/CellText'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import {
  Button,
  Checkbox,
  DatePicker,
  Empty,
  Input,
  Pagination,
  Select,
  Space,
  Tabs,
  Tooltip,
} from 'antd'
import { PlusOutlined, CheckOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet/es/Helmet'
import { IssueInfo, SearchBar } from './ProposalPageStyled'
import SubmissionForm from '../../../../components/SubmissionForm'
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  DATE_FORMAT_DEFAULT,
  ISO_DATE_FORMAT,
  DATE_FORMAT_LIST,
  PROPOSAL_STATUS,
  PROPOSAL_TYPE,
  TYPE_STATUS,
} from '../../../../constants'
import { AvatarHasName } from '../../../../components/Common/Avatar'
import { StatusTag } from '../../../../components/Common/StatusTag'
import TableComponent from '../../../../components/Common/TableComponent'
import AccessControlAction from '../../../../components/AccessControlAction'
import moment from 'moment'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
const { TabPane } = Tabs
const { Search } = Input
const { Option, OptGroup } = Select
const { RangePicker } = DatePicker
export const submissionMenu = [
  {
    id_group: '1',
    name_group: 'Hành chính - Nhân sự',
    child: [
      {
        id_child: 'BUSINESS_TRIP',
        name_child: PROPOSAL_TYPE.BUSINESS_TRIP.label,
      },
      { id_child: 'LEAVE', name_child: PROPOSAL_TYPE.LEAVE.label },
      {
        id_child: 'LEAVE_ABNORMAL',
        name_child: PROPOSAL_TYPE.LEAVE_ABNORMAL.label,
      },
      {
        id_child: 'LEAVE_NO_SALARY',
        name_child: PROPOSAL_TYPE.LEAVE_NO_SALARY.label,
      },
      {
        id_child: 'LEAVE_ANNUAL',
        name_child: PROPOSAL_TYPE.LEAVE_ANNUAL.label,
      },
      { id_child: 'SICK', name_child: PROPOSAL_TYPE.SICK.label },
      { id_child: 'SICK_OTHER', name_child: PROPOSAL_TYPE.SICK_OTHER.label },
      {
        id_child: 'OVERTIME_NORMAL',
        name_child: PROPOSAL_TYPE.OVERTIME_NORMAL.label,
      },
      {
        id_child: 'OVERTIME_DAYOFF',
        name_child: PROPOSAL_TYPE.OVERTIME_DAYOFF.label,
      },
      {
        id_child: 'OVERTIME_HOLIDAY',
        name_child: PROPOSAL_TYPE.OVERTIME_HOLIDAY.label,
      },
      // {
      //   id_child: 'OVERTIME_CHAIRMAN',
      //   name_child: PROPOSAL_TYPE.OVERTIME_CHAIRMAN.label,
      // },
      {
        id_child: 'RETIMEKEEPING',
        name_child: PROPOSAL_TYPE.RETIMEKEEPING.label,
      },
    ],
  },
  {
    id_group: '2',
    name_group: 'Hành chính - Văn phòng',
    child: [
      {
        id_child: 'EVALUATE_MEMBER',
        name_child: PROPOSAL_TYPE.EVALUATE_MEMBER.label,
      },
      {
        id_child: 'EVALUATE_LEADER',
        name_child: PROPOSAL_TYPE.EVALUATE_LEADER.label,
      },
      { id_child: 'TIMEKEEPER', name_child: PROPOSAL_TYPE.TIMEKEEPER.label },
      { id_child: 'STATIONERY', name_child: PROPOSAL_TYPE.STATIONERY.label },
      { id_child: 'MEETING', name_child: PROPOSAL_TYPE.MEETING.label },
      { id_child: 'VEHICLE', name_child: PROPOSAL_TYPE.VEHICLE.label },
    ],
  },
]

const ProposalPage = props => {
  const {
    history,
    proposalStore,
    loadingAnimationStore,
    selectPeopleStore,
    proposalTypeStore,
  } = props
  const {
    proposalList,
    totalCountProposal,
    pageIndex,
    pageSize,
    keyword,
    proposalPermission,
    type,
    proposalStatus,
    activeTabProposal,
    createdAt,
    createUser,
    fromDate,
    toDate,
  } = proposalStore

  const { proposal_Status } = history.location.state || {}

  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)
  const [showModal, setShowModal] = useState(false)
  const [keywordSearch, setKeywordSearch] = useState()
  const [createUserSearch, setCreateUserSearch] = useState()
  const [createDateSearch, setCreateDateSearch] = useState('')
  const [status, setStatus] = useState()
  const [types, setType] = useState()
  const [isRefreshPage, setIsRefreshPage] = useState(true)

  const [proposalTypes, setProposalTypes] = useState([])
  const [arrSubmissionMenu, setArrSubmissionMenu] = useState([])
  useEffect(() => {
    ;(async () => {
      const response = await proposalTypeStore.getProposalTypes()
      setProposalTypes(response.data)
      const newProposalTypes = [
        {
          id_group: '3',
          name_group: 'Hành chính - Khác',
          child: response.data
            .filter(item => item.id.includes('OTHER_'))
            .map(item => ({
              id_child: item.id,
              name_child: item.name,
            })),
        },
      ]
      const updatedSubmissionMenu = submissionMenu.concat(newProposalTypes)
      setArrSubmissionMenu(updatedSubmissionMenu)
    })()
  }, [])
  const [markAsReadLoading, setMarkAsReadLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const markAsRead = () => {
    let batchMarkAsRead = []
    selectedRowKeys.forEach(id => {
      batchMarkAsRead.push(proposalStore.getOtherDetail(id))
    })
    setMarkAsReadLoading(true)
    Promise.all(batchMarkAsRead)
      .then(() => {
        // Tất cả các promise đã được giải quyết
        setMarkAsReadLoading(false)
        setSelectedRowKeys([])
        return proposalStore.getProposals() // Trả về promise cho việc gọi proposalStore.getProposals()
      })
      .catch(error => {
        // Xử lý lỗi nếu có
        console.error('Error:', error)
      })
  }

  // useEffect(() => {
  //   proposalStore.setFilter('pageIndex', 0)
  //   proposalStore.setFilter('proposalStatus', proposal_Status)
  // }, [])

  /** Columns table */
  const tableColumns = useMemo(
    () => [
      {
        title: 'Loại',
        width: 150,
        render: record => (
          <Text>
            {/* {showProposalType(record.proposal_type) ? (
              showProposalType(record.proposal_type)
            ) : (
              <></>
            )} */}
            {record.proposal_type_name}
          </Text>
        ),
      },
      {
        title: 'Tiêu đề',
        width: 750,
        render: record =>
          record.title ? (
            <div
              style={{
                fontSize: '12px',
                maxWidth: '100%',
              }}>
              <div
                style={{
                  fontSize: '12px',
                  maxWidth: '100%',
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  whiteSpace: 'normal',
                }}>
                {record.title}
              </div>
            </div>
          ) : (
            <EmptyText>Không có tiêu đề.</EmptyText>
          ),
      },
      {
        title: 'Ngày tạo',
        width: 180,
        render: record => (
          <Text>{moment(record?.created_at).format('DD/MM/YYYY')}</Text>
        ),
      },
      {
        title: 'Người gửi',
        width: 180,
        render: record => (
          <AvatarHasName
            imgId={record?.image}
            name={record.create_user?.name_lowercase}
          />
        ),
      },
      {
        title: 'Trạng thái',
        width: 150,
        align: 'center',
        render: record =>
          record.proposal_type_is_deleted ? (
            <IssueInfo>
              {StatusTag(
                TYPE_STATUS.PROPOSAL_TYPE,
                record.proposal_type_is_deleted
              )}
            </IssueInfo>
          ) : (
            <IssueInfo>
              {StatusTag(TYPE_STATUS.PROPOSAL, record.proposal_status)}
            </IssueInfo>
          ),
      },
    ],
    [proposalTypes]
  )

  useEffect(() => {
    proposalStore.setActiveTabProposal('')
  }, [])

  /** Xử lý Modal khi click vào tạo mới phiếu trình */
  const handleCancel = useCallback(() => {
    setShowModal(false)
    selectPeopleStore.clearSelected()
    proposalStore.clearFormProposalType()
  }, [])

  /** Search, filter phiếu trình */
  const onSearchProposal = useCallback(values => {
    proposalStore.setFilter('pageIndex', 0)
    proposalStore.setFilter('keyword', values && values.trim())
  }, [])
  const handleFilterStatus = useCallback(value => {
    proposalStore.setFilter('pageIndex', 0)
    proposalStore.setFilter('proposalStatus', value)
  }, [])
  const handleFilterProposalType = useCallback(value => {
    const type = value === undefined ? undefined : `type=${value}`
    proposalStore.setFilter('pageIndex', 0)
    proposalStore.setFilter('type', type)
  }, [])
  const handleFilterProposalPermission = useCallback(value => {
    proposalStore.setActiveTabProposal(value)
    proposalStore.setFilter('pageIndex, 0')
    proposalStore.setFilter('proposalPermission', value)
  }, [])
  const handleFilterProposalCreateUser = useCallback(value => {
    proposalStore.setFilter('pageIndex', 0)
    proposalStore.setFilter('createUser', value && value.trim())
  }, [])
  const handleChangePageProposalLeave = useCallback(page => {
    const pageIndex = page - 1
    proposalStore.setFilter('pageIndex', pageIndex)
  }, [])
  const handleFilterProposalCreateAt = useCallback(value => {
    proposalStore.clearCreateUser()
    proposalStore.setFilter('pageIndex', 0)
    proposalStore.setFilter(
      'createdAt',
      value ? moment(value?._d).format('YYYY-MM-DD') : null
    )
  }, [])
  const onChangeDate = useCallback(date => {
    proposalStore.setFilter('pageIndex', 0)
    proposalStore.setFilter(
      'fromDate',
      date ? moment(date[0]).format(ISO_DATE_FORMAT) : null
    )
    proposalStore.setFilter(
      'toDate',
      date ? moment(date[1]).format(ISO_DATE_FORMAT) : null
    )
  }, [])
  const handleChangeUnread = event => {
    let only_unread = event?.target?.checked ? true : false
    proposalStore.setFilter('only_unread', only_unread ? only_unread : null)
  }
  /** Hiển thị danh sách loại phiếu trình (Lọc theo loại phiếu trình) */
  const showSubmissionMenu = useMemo(() => {
    return arrSubmissionMenu?.length >= 3 ? (
      arrSubmissionMenu?.map((unit, index) => {
        return (
          <OptGroup
            label={
              <b
                style={{
                  fontSize: 15,
                  fontWeight: 500,
                  color: '#000',
                }}>
                {unit.name_group}
              </b>
            }
            key={unit.id_group}>
            {unit?.child
              ?.filter(
                item =>
                  item.id_child !== 'OVERTIME_DAYOFF' &&
                  item.id_child !== 'OVERTIME_HOLIDAY' &&
                  item.id_child !== 'LEAVE_NO_SALARY' &&
                  item.id_child !== 'LEAVE_ANNUAL'
              )
              .map(child => {
                return (
                  <Option value={child.id_child} key={child.id_child}>
                    {child.name_child}
                  </Option>
                )
              })}
          </OptGroup>
        )
      })
    ) : (
      <></>
    )
  }, [arrSubmissionMenu])

  /** Show Input Search */
  const headingList = (
    <>
      <SearchBar>
        <Search
          style={{ marginRight: '16px', maxWidth: '350px' }}
          allowClear
          value={keywordSearch}
          onChange={e => setKeywordSearch(e.target.value)}
          onSearch={onSearchProposal}
          placeholder={'Tìm kiếm theo tiêu đề'}
        />
        <Select
          style={{ marginRight: '16px', minWidth: '350px' }}
          name={'type'}
          placeholder={'Lọc theo loại'}
          allowClear
          value={
            // proposalStore.type !== undefined
            //   ? proposalStore.type.slice(5)
            //   : proposalStore.type
            // types?.slice(5)
            proposalStore.type ? proposalStore.type.slice(5) : undefined
          }
          showSearch
          filterOption={(input, option) => {
            const nameChild = option?.children?.toString().toLowerCase() || ''
            const idChild = option?.value?.toString().toLowerCase() || ''
            const searchText = input.toLowerCase()
            return (
              nameChild.includes(searchText) || idChild.includes(searchText)
            )
          }}
          onChange={handleFilterProposalType}>
          {showSubmissionMenu}
        </Select>
        <Select
          // value={proposalStore.proposalStatus}
          value={status}
          style={{ minWidth: '350px', marginRight: '16px' }}
          name={'proposal_status'}
          placeholder={'Lọc theo trạng thái'}
          allowClear
          onChange={handleFilterStatus}>
          <Option value={PROPOSAL_STATUS.PENDING}>Chờ phê duyệt</Option>
          <Option value={PROPOSAL_STATUS.APPROVAL}>Đã phê duyệt</Option>
          <Option value={PROPOSAL_STATUS.REJECT}>Từ chối</Option>
        </Select>
        {/* <Space
          style={{
            width: 130,
            verticalAlign: 'middle',
            display: 'inline-block',
            marginLeft: 10,
          }}>
          <Checkbox
            checked={proposalStore.only_unread}
            onChange={handleChangeUnread}>
            Hiển thị chưa đọc
          </Checkbox>
        </Space> */}
      </SearchBar>
      <SearchBar>
        <DatePicker
          style={{ marginRight: '16px', minWidth: '350px' }}
          allowClear
          value={createDateSearch ? moment(createDateSearch) : null}
          placeholder={'Ngày tạo '}
          format={DATE_FORMAT_DEFAULT}
          onChange={e => handleFilterProposalCreateAt(e)}
        />
        <Search
          style={{ marginRight: '16px', maxWidth: '350px' }}
          allowClear
          value={createUserSearch}
          onChange={e => setCreateUserSearch(e.target.value)}
          onSearch={handleFilterProposalCreateUser}
          placeholder={'Tìm kiếm theo người gửi'}
        />
        <RangePicker
          onChange={onChangeDate}
          allowClear
          defaultValue={
            queryStringParse.from_date && queryStringParse.to_date
              ? [
                  moment(queryStringParse.from_date),
                  moment(queryStringParse.to_date),
                ]
              : null
          }
          format={[DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT]}
          style={{
            marginRight: '16px',
            minWidth: '350px',
          }}
        />
        {/* <div>
          {selectedRowKeys && selectedRowKeys.length > 0 ? (
            <Button
              loading={markAsReadLoading}
              onClick={markAsRead}
              disabled={!selectedRowKeys || selectedRowKeys.length === 0}
              style={{ marginLeft: '10px' }}>
              <CheckOutlined />
              Đánh dấu đã đọc
            </Button>
          ) : (
            <Tooltip placement="top" title={'Chưa chọn đối tượng'}>
              <Button disabled={true} style={{ marginLeft: '10px' }}>
                <CheckOutlined />
                Đánh dấu đã đọc
              </Button>
            </Tooltip>
          )}
        </div> */}
      </SearchBar>
    </>
  )

  /** Tab */
  const tabKeys = [
    '',
    ASSIGNEE_TYPE.CREATE,
    ASSIGNEE_TYPE.HANDLER,
    ASSIGNEE_TYPE.FOLLOWER,
  ]
  const tabName = tabKey => {
    switch (tabKey) {
      case '':
        return 'Tất cả'
      case ASSIGNEE_TYPE.CREATE:
        return 'Đã gửi'
      case ASSIGNEE_TYPE.HANDLER:
        return 'Xử lý'
      case ASSIGNEE_TYPE.FOLLOWER:
        return 'Theo dõi'
      case ASSIGNEE_TYPE.COMBINER:
        return 'Phối hợp'
      default:
        return ''
    }
  }

  useEffect(() => {
    setKeywordSearch(proposalStore.keyword)
  }, [proposalStore.keyword])
  useEffect(() => {
    setStatus(proposalStore.proposalStatus)
  }, [proposalStore.proposalStatus])
  useEffect(() => {
    setType(proposalStore.type)
  }, [proposalStore.type])
  useEffect(() => {
    setCreateUserSearch(proposalStore.createUser)
  }, [proposalStore.createUser])
  useEffect(() => {
    setCreateDateSearch(proposalStore.createdAt)
  }, [proposalStore.createdAt])

  const loadProposalsList = async () => {
    const queryParams = {
      page: proposalStore.pageIndex,
      size: proposalStore.pageSize,
      proposal_permission: proposalStore.proposalPermission,
      keyword: proposalStore.keyword,
      proposal_status: proposalStore.proposalStatus,
      created_at: proposalStore.createdAt,
      type: proposalStore.type,
      create_user: proposalStore.createUser,
      from_date: proposalStore.fromDate,
      to_date: proposalStore.toDate,
    }
    try {
      loadingAnimationStore.setTableLoading(true)
      await proposalStore.getProposals()
      const queryStringStringify = queryString.stringify(queryParams, {
        skipNull: true,
      })
      history.replace(`/proposal?${queryStringStringify}`)
    } catch (error) {
      loadingAnimationStore.setTableLoading(false)
      console.log(error)
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  useEffect(() => {
    proposalStore.setFilter(
      'pageIndex',
      queryStringParse.page ? parseInt(queryStringParse.page) : 0
    )
    proposalStore.setFilter(
      'pageSize',
      queryStringParse.page ? parseInt(queryStringParse.size) : 10
    )
    proposalStore.setFilter(
      'proposalPermission',
      queryStringParse.proposal_permission
    )
    proposalStore.setFilter('keyword', queryStringParse.keyword)
    proposalStore.setFilter('proposalStatus', queryStringParse.proposal_status)
    proposalStore.setFilter('createdAt', queryStringParse.created_at)
    proposalStore.setFilter('type', queryStringParse.type)
    proposalStore.setFilter('createUser', queryStringParse.create_user)
    proposalStore.setFilter('fromDate', queryStringParse.from_date)
    proposalStore.setFilter('toDate', queryStringParse.to_date)
    ;(async () => {
      await loadProposalsList()
      setIsRefreshPage(false)
    })()

    // return () => {
    //   proposalStore.clearFilterProposal()
    // }
  }, [])

  useEffect(() => {
    if (isRefreshPage) return
    ;(async () => {
      await loadProposalsList()
    })()
  }, [
    pageIndex,
    pageSize,
    keyword,
    proposalStatus,
    type,
    proposalPermission,
    createUser,
    createdAt,
    fromDate,
    toDate,
  ])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Hành chính | Danh sách thủ tục hành chính</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title="Danh sách hành chính"
        hiddenGoBack>
        <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__PROPOSAL}>
          <Button
            onClick={() => setShowModal(true)}
            type={'primary'}
            icon={<PlusOutlined />}>
            Tạo mới
          </Button>
        </AccessControlAction>
        {showModal && (
          <SubmissionForm
            onCloseModal={handleCancel}
            handleCancel={handleCancel}
            proposalTypes={proposalTypes}
            showModal={showModal}
            showSubmissionMenu={showSubmissionMenu}
          />
        )}
      </PageTitle>
      <ContentBlockWrapper>
        <Tabs
          defaultActiveKey={queryStringParse.proposal_permission || ''}
          onChange={handleFilterProposalPermission}>
          {tabKeys.map((tabKey, index) => {
            return (
              <TabPane tab={tabName(tabKey)} key={tabKey}>
                {headingList}
                <TableComponent
                  // rowSelection={rowSelection}
                  onRow={record => {
                    return {
                      onClick: () =>
                        history.push(
                          `/proposal/other/view/${record.proposal_code}`
                        ),
                    }
                  }}
                  rowKey={record => record.proposal_code}
                  dataSource={proposalList}
                  columns={tableColumns}
                  pagination={false}
                  locale={{
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={'Không có dữ liệu'}
                      />
                    ),
                  }}
                  loading={loadingAnimationStore.tableLoading}
                />
                <TableBottomPaginationBlock>
                  <Pagination
                    current={pageIndex + 1}
                    pageSize={pageSize}
                    total={totalCountProposal}
                    hideOnSinglePage={true}
                    showSizeChanger={false}
                    onChange={handleChangePageProposalLeave}
                  />
                </TableBottomPaginationBlock>
              </TabPane>
            )
          })}
        </Tabs>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

// ProposalPage.propTypes = {
//   proposalStore: PropTypes.object,
// }

export default inject(
  'proposalStore',
  'loadingAnimationStore',
  'selectPeopleStore',
  'proposalTypeStore'
)(observer(ProposalPage))
