import React, { useCallback, useEffect, useMemo } from 'react'

import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { Dropdown, Menu, Modal, Table, message } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { blue, green, purple9, redPrimary } from '../../../../color'
import ApproverListModal from '../../../../components/ApproverListModal'
import {
  EmptyText,
  TitleContentBox,
} from '../../../../components/Common/CellText'
import FileItem from '../../../../components/Common/FileItemComponent/FileItem'
import { StatusTag } from '../../../../components/Common/StatusTag'
import ContentBlockWrapper from '../../../../components/ContentBlockWrapper'
import PageTitle from '../../../../components/PageTitle'
import PopoverWatchTime from '../../../../components/PopoverWatchTime'
import SpinnerInlineComponent from '../../../../components/SpinnerInlineComponent'
import {
  ACL_ACTION_TYPE,
  DATE_FORMAT_DEFAULT,
  PROPOSAL_STATUS,
  PROPOSAL_TYPE,
  TYPE_STATUS,
  SHIFTS,
  MODES,
} from '../../../../constants'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import { DocumentInfoList } from '../../../../layouts/ThongTinChungLayout/ThongTinChungLayoutStyled'
import loadingAnimationStore from '../../../../stores/loadingAnimationStore'
import utils from '../../../../utils'
import { HeadingButtonWrapper } from '../../../MissionDetailPage/MissionDetailPageStyled'
import ProposalComment from './ProposalComment'
import ProposalDetailAction from './ProposalDetail/ProposalDetailAction'
import ProposalDetailLeavesPage from './ProposalDetail/ProposalDetailLeaves'
import ProposalDetailOtherPage from './ProposalDetail/ProposalDetailOther'
import { AttachmentList } from './ProposalDetail/ProposalDetailStyled'
import ProposalDetailVehiclePage from './ProposalDetail/ProposalDetailVehicle'
import {
  BlockLeft,
  BlockRight,
  ContentWrapper,
  ViewDetailContent,
} from './ProposalDetailPageStyle'
import ProposalDetailUpdate from './ProposalDetailUpdate'
import './index.css'
import ProposalDetailUpdateFollowers from './ProposalDetailUpdateFollowers'

const { confirm } = Modal

const ProposalDetailPage = props => {
  const {
    proposalStore,
    match,
    history,
    notificationStore,
    aclStore,
    commonStore,
    authenticationStore,
    proposalTypeStore,
  } = props
  const { proposalId, typePage } = match.params
  const { otherDetail } = proposalStore
  // Không xóa dòng khai báo aclActionsByUser (dù k dùng)
  const { aclActionsByUser } = aclStore
  const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false)
  const [
    isVisibleUpdateFollowersModalModal,
    setIsVisibleUpdateFollowersModalModal,
  ] = useState(false)
  const { isAccountAdmin } = authenticationStore
  const handleApprovalProposal = async data => {
    loadingAnimationStore.showSpinner(true)
    try {
      await proposalStore.approvalProposalOther(data)
      await proposalStore.getOtherDetail(proposalId)
      await proposalStore.getOtherComment(proposalId)
      if (data.type === PROPOSAL_STATUS.REJECT) {
        return message.success('Hủy thành công!')
      }
      return message.success('Duyệt thành công!')
    } catch (e) {
      console.log(e)
      if (data.type === PROPOSAL_STATUS.REJECT) {
        return message.error('Hủy thất bại!')
      }
      return message.error('Duyệt thất bại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        await proposalStore.getOtherDetail(proposalId)
        await proposalStore.getOtherComment(proposalId)
      } catch (error) {
        await history.push('/proposal')
        console.log(error)
      }
    })()
  }, [])

  const handleDeleteProposal = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await proposalStore.deleteProposalItem(proposalId)
      history.push('/proposal')
      message.success('Xóa thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra, vui lòng thử lại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showConfirmApproval = type => {
    confirm({
      title: `Bạn có muốn ${
        type === PROPOSAL_STATUS.APPROVAL ? 'duyệt' : 'hủy'
      } thông tin này không?`,
      icon:
        type === PROPOSAL_STATUS.APPROVAL ? (
          <CheckOutlined style={{ color: blue }} />
        ) : (
          <StopOutlined style={{ color: 'red' }} />
        ),
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: type === PROPOSAL_STATUS.APPROVAL ? 'primary' : 'danger',
      onOk() {
        handleApprovalProposal({
          proposalCode: proposalId,
          type: type,
        }).then(() => Promise.resolve())
      },
    })
  }

  const showConfirmDelete = () => {
    confirm({
      title: 'Bạn có muốn xóa thông tin này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteProposal()
      },
    })
  }

  useEffect(() => {
    return () => {
      notificationStore.clearNotificationType()
      proposalStore.clearOtherCommentList()
    }
  }, [])

  const handleCancelUpdateModal = () => {
    setIsVisibleUpdateModal(false)
    proposalStore.clearFormProposalType()
  }

  const handleOpenUpdateModal = () => {
    setIsVisibleUpdateModal(true)
  }
  const handleCancelUpdateFollowersModal = () => {
    setIsVisibleUpdateFollowersModalModal(false)
    proposalStore.clearFormProposalType()
  }
  const handleOpenUpdateFollowersModal = () => {
    setIsVisibleUpdateFollowersModalModal(true)
  }

  const checkShowHandleDocument = () => {
    return (
      (otherDetail.proposal_status === PROPOSAL_STATUS.PENDING &&
        otherDetail?.has_no_config_approval_levels) ||
      (otherDetail && !otherDetail?.has_no_config_approval_levels)
    )
  }

  const checkAccessControlUpdateAction = () => {
    return (
      authenticationStore.currentUser?.username ===
      otherDetail.create_user?.user_name
    )
  }
  const checkAccessControlUpdateActionV2 = () => {
    return otherDetail?.approval_levels?.some(
      item => item.username === authenticationStore.currentUser?.username
    )
  }
  const checkHasNoArppovedLevel = () => {
    if (otherDetail.has_no_config_approval_levels) return true

    if (otherDetail.approval_levels)
      return otherDetail.approval_levels
        .slice(1)
        .every(level => level.status !== 'APPROVED')
  }

  const menuHandleDocument = (
    <Menu>
      {otherDetail.proposal_status === PROPOSAL_STATUS.PENDING &&
        otherDetail?.has_no_config_approval_levels && (
          <>
            {aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.approve__PROPOSAL
            ) && (
              <Menu.Item
                onClick={() => showConfirmApproval(PROPOSAL_STATUS.APPROVAL)}
                style={{ color: blue }}
                icon={<CheckOutlined />}>
                Duyệt phiếu
              </Menu.Item>
            )}
            {aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.approve__PROPOSAL
            ) && (
              <Menu.Item
                onClick={() => showConfirmApproval(PROPOSAL_STATUS.REJECT)}
                style={{ color: 'red' }}
                icon={<StopOutlined />}>
                Từ chối
              </Menu.Item>
            )}
          </>
        )}

      {otherDetail &&
        !otherDetail?.has_no_config_approval_levels &&
        (isAccountAdmin || checkAccessControlUpdateActionV2()) && (
          <>
            <Menu.Item
              style={{ color: blue }}
              onClick={handleOpenUpdateModal}
              icon={<EditOutlined />}>
              Chỉnh sửa
            </Menu.Item>
          </>
        )}
      {otherDetail &&
        !otherDetail?.has_no_config_approval_levels &&
        (isAccountAdmin || checkAccessControlUpdateActionV2()) && (
          <>
            <Menu.Item
              style={{ color: blue }}
              onClick={handleOpenUpdateFollowersModal}
              icon={<EditOutlined />}>
              Chỉnh sửa danh sách người theo dõi
            </Menu.Item>
          </>
        )}
      {otherDetail &&
        (aclStore.checkAccessControlAction(ACL_ACTION_TYPE.delete__PROPOSAL) ||
          isAccountAdmin ||
          checkAccessControlUpdateAction()) && (
          <Menu.Item
            danger
            onClick={showConfirmDelete}
            icon={<DeleteOutlined />}>
            Xóa
          </Menu.Item>
        )}
    </Menu>
  )
  const [proposalTypes, setProposalTypes] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const response = await proposalTypeStore.getAllProposalTypes()
      setProposalTypes(response.data)
    }
    fetchData()
  }, [])
  const renderTitlePage = useMemo(() => {
    for (const [value] of Object.entries(PROPOSAL_TYPE)) {
      if (otherDetail.proposal_type === value) {
        return PROPOSAL_TYPE[value].label
      }
    }
    const foundItem = proposalTypes.find(
      item => item.id == otherDetail.proposal_type
    )
    if (foundItem) {
      return foundItem.name
    }
  }, [otherDetail, proposalTypes])

  /** Hiển thị danh sách tài liệu đính kèm */
  const renderFileIcon = file_type => {
    let iconFile = <FileTextOutlined />
    if (file_type === 'pdf') {
      iconFile = <FilePdfOutlined style={{ color: redPrimary }} />
      return iconFile
    }
    if (file_type === 'doc' || file_type === 'docx') {
      iconFile = <FileWordOutlined style={{ color: blue }} />
      return iconFile
    }
    if (
      [
        'jpg',
        'bmp',
        'dib',
        'jpe',
        'jfif',
        'gif',
        'tif',
        'tiff',
        'png',
        'heic',
      ].includes(file_type)
    ) {
      iconFile = <FileImageOutlined style={{ color: purple9 }} />
      return iconFile
    }
    if (file_type === 'rar' || file_type === 'zip') {
      iconFile = <FileZipOutlined style={{ color: '#e67e22' }} />
      return iconFile
    }
    if (file_type === 'xlsx') {
      iconFile = <FileExcelOutlined style={{ color: green }} />
      return iconFile
    }
  }

  const renderAttachmentList = useCallback(() => {
    return otherDetail.attachments?.map(item => {
      return (
        <FileItem
          key={item.file_id}
          file_id={item.file_id}
          file_name={item.file_name}
          file_type={utils.getExtensionFile(item.file_name)}
        />
      )
    })
  }, [commonStore.appTheme, renderFileIcon, otherDetail])

  const [workingDaysList, setWorkingDaysList] = useState([])

  useEffect(() => {
    setWorkingDaysList(otherDetail?.proposalTimeList || [])
  }, [otherDetail?.proposalTimeList])

  const numberOfColumns =
    otherDetail?.proposal_type !== 'BUSINESS_TRIP' &&
    otherDetail?.proposal_type !== 'OVERTIME_NORMAL'
      ? 4
      : 3
  const columnWidth = `${100 / numberOfColumns}%`

  const columns = [
    {
      title: 'Ngày làm việc',
      dataIndex: 'date',
      key: 'date',
      width: columnWidth,
      render: text => <span>{moment(text).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Buổi làm việc',
      dataIndex: 'shift',
      key: 'shift',
      width: columnWidth,
      render: key => {
        let value
        switch (key) {
          case SHIFTS.ALL_DAY.key:
            value = SHIFTS.ALL_DAY.value
            break
          case SHIFTS.MORNING.key:
            value = SHIFTS.MORNING.value
            break
          case SHIFTS.AFTERNOON.key:
            value = SHIFTS.AFTERNOON.value
            break
          case SHIFTS.EMPTY.key:
            value = SHIFTS.EMPTY.value
            break
          default:
        }
        return <span>{value}</span>
      },
    },
    ...(otherDetail?.proposal_type !== 'BUSINESS_TRIP' &&
    otherDetail?.proposal_type !== 'OVERTIME_NORMAL'
      ? [
          {
            title: 'Loại nghỉ phép',
            dataIndex: 'mode',
            key: 'mode',
            width: columnWidth,
            render: key => {
              let value
              switch (key) {
                case MODES.NORMAL.key:
                  value = MODES.NORMAL.value
                  break
                case MODES.SPECIAL.key:
                  value = MODES.SPECIAL.value
                  break
                case MODES.WOMEN.key:
                  value = MODES.WOMEN.value
                  break
                case MODES.CHILD_CARE.key:
                  value = MODES.CHILD_CARE.value
                  break
                default:
              }
              return <span>{value}</span>
            },
          },
        ]
      : []),
    ...(otherDetail?.proposal_type === 'OVERTIME_NORMAL'
      ? [
          {
            title: 'Giờ',
            dataIndex: 'mode_type',
            key: 'mode_type',
            width: '17.5%',
            render: (key, record) => (
              <span style={{ textAlign: 'center' }}>{record.mode_type}</span>
            ),
          },
        ]
      : []),
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      width: columnWidth,
      render: text => (
        <div style={{ maxHeight: '100px', overflowY: 'auto' }}>{text}</div>
      ),
    },
  ]

  const leaveAbnormalColumns = [
    {
      title: 'Ngày làm việc',
      dataIndex: 'date',
      key: 'date',
      width: '17.5%',
      render: text => <span>{moment(text).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Buổi làm việc',
      dataIndex: 'shift',
      key: 'shift',
      width: '17.5%',
      render: key => {
        let value
        switch (key) {
          case SHIFTS.ALL_DAY.key:
            value = SHIFTS.ALL_DAY.value
            break
          case SHIFTS.MORNING.key:
            value = SHIFTS.MORNING.value
            break
          case SHIFTS.AFTERNOON.key:
            value = SHIFTS.AFTERNOON.value
            break
          case SHIFTS.EMPTY.key:
            value = SHIFTS.EMPTY.value
            break
          default:
        }
        return <span>{value}</span>
      },
    },
    {
      title: 'Loại nghỉ phép',
      dataIndex: 'mode',
      key: 'mode',
      width: '17.5%',
      render: key => {
        let value
        switch (key) {
          case MODES.NORMAL.key:
            value = MODES.NORMAL.value
            break
          case MODES.SPECIAL.key:
            value = MODES.SPECIAL.value
            break
          case MODES.WOMEN.key:
            value = MODES.WOMEN.value
            break
          case MODES.CHILD_CARE.key:
            value = MODES.CHILD_CARE.value
            break
          case MODES.WORK_REASON.key:
            value = MODES.WORK_REASON.value
            break
          case MODES.BY_REGIME.key:
            value = MODES.BY_REGIME.value
            break
          default:
        }
        return <span>{value}</span>
      },
    },
    {
      title: 'Loại chế độ (giờ)',
      dataIndex: 'mode_type',
      key: 'mode_type',
      width: '17.5%',
      render: (key, record) => (
        <span style={{ textAlign: 'center' }}>{record.mode_type}</span>
      ),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      width: '30%',
      render: text => (
        <div style={{ maxHeight: '100px', overflowY: 'auto' }}>{text}</div>
      ),
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết hành chính| Hành Chính</title>
      </Helmet>
      <PageTitle
        location={props.location}
        showTitle={true}
        title={otherDetail.title}
        status={StatusTag(TYPE_STATUS.PROPOSAL, otherDetail.proposal_status)}>
        <HeadingButtonWrapper>
          {checkShowHandleDocument() ? (
            <Dropdown overlay={menuHandleDocument} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined style={{ color: blue }} />
              </span>
            </Dropdown>
          ) : (
            <></>
          )}
        </HeadingButtonWrapper>
      </PageTitle>

      {otherDetail && !otherDetail?.has_no_config_approval_levels ? (
        <ProposalDetailAction />
      ) : (
        <></>
      )}

      <ViewDetailContent>
        <ContentWrapper>
          <BlockLeft>
            <ContentBlockWrapper>
              <TitleContentBox>Nội dung</TitleContentBox>
              {typePage === 'vehicle' ? (
                <ProposalDetailVehiclePage proposalId={proposalId} />
              ) : typePage === 'other' ? (
                <ProposalDetailOtherPage proposalId={proposalId} />
              ) : (
                <ProposalDetailLeavesPage proposalId={proposalId} />
              )}
            </ContentBlockWrapper>
          </BlockLeft>
          <BlockRight>
            <ContentBlockWrapper>
              <TitleContentBox>Thông tin chung</TitleContentBox>
              <DocumentInfoList>
                <dt>Loại</dt>
                <dd>{renderTitlePage}</dd>

                <dt>Tài liệu đính kèm</dt>
                <dd>
                  {otherDetail.attachments?.length !== 0 ? (
                    <>
                      <AttachmentList>{renderAttachmentList()}</AttachmentList>
                    </>
                  ) : (
                    <EmptyText>Không có tài liệu đính kèm.</EmptyText>
                  )}
                </dd>

                <dt>Người theo dõi</dt>
                <dd>
                  {proposalStore.otherDetail.followers &&
                  proposalStore.otherDetail.followers.length > 0 ? (
                    <PopoverWatchTime
                      userList={proposalStore.otherDetail.followers.map(
                        item => {
                          return {
                            full_name: item.name_lowercase,
                            id: item.user_name,
                            assignee_type: 'USER',
                            last_date_read: item.last_date_read,
                          }
                        }
                      )}
                      title={'Danh sách người theo dõi'}
                    />
                  ) : (
                    <EmptyText>Không có người theo dõi.</EmptyText>
                  )}
                </dd>
                {/* {otherDetail?.proposal_type === 'LEAVE' && (
                  <>
                    <dt>Loại nghỉ phép</dt>
                    <dd>
                      {otherDetail.type_of_leave === null ||
                      otherDetail.type_of_leave === '' ? (
                        <EmptyText>Không có loại nghỉ phép.</EmptyText>
                      ) : (
                        otherDetail.type_of_leave
                      )}
                    </dd>
                  </>
                )} */}
                {/* {otherDetail?.proposal_type === 'LEAVE_ABNORMAL' && (
                  <>
                    <dt>Lý do</dt>
                    <dd>
                      {otherDetail.reason_for_leaving === null ||
                      otherDetail.reason_for_leaving === '' ? (
                        <EmptyText>Không có lý do.</EmptyText>
                      ) : (
                        otherDetail.reason_for_leaving
                      )}
                    </dd>
                  </>
                )} */}
                <dt>Ngày bắt đầu</dt>
                <dd>
                  {otherDetail.from_date == null ||
                  otherDetail.from_date === '' ? (
                    <EmptyText>Không có ngày bắt đầu.</EmptyText>
                  ) : (
                    moment(otherDetail.from_date).format(DATE_FORMAT_DEFAULT)
                  )}
                </dd>
                <dt>Ngày kết thúc</dt>
                <dd>
                  {otherDetail.to_date == null || otherDetail.to_date === '' ? (
                    <EmptyText>Không có ngày kết thúc.</EmptyText>
                  ) : (
                    moment(otherDetail.to_date).format(DATE_FORMAT_DEFAULT)
                  )}
                </dd>
                {workingDaysList?.length > 0 &&
                  (otherDetail?.proposal_type === 'BUSINESS_TRIP' ||
                    otherDetail?.proposal_type === 'LEAVE' ||
                    otherDetail?.proposal_type === 'RETIMEKEEPING' ||
                    otherDetail?.proposal_type === 'OVERTIME_NORMAL' ||
                    otherDetail?.proposal_type === 'LEAVE_ABNORMAL') && (
                    <BlockRight>
                      <Table
                        rowKey={record => record.id}
                        className="custom-table-working-days-list"
                        columns={
                          otherDetail?.proposal_type === 'LEAVE_ABNORMAL'
                            ? leaveAbnormalColumns
                            : columns
                        }
                        dataSource={workingDaysList}
                        pagination={false}
                      />
                    </BlockRight>
                  )}
                {/* {(otherDetail?.proposal_type ===
                  PROPOSAL_TYPE.OVERTIME_NORMAL.key ||
                  otherDetail?.proposal_type ===
                    PROPOSAL_TYPE.OVERTIME_DAYOFF.key ||
                  otherDetail?.proposal_type ===
                    PROPOSAL_TYPE.OVERTIME_HOLIDAY.key) && (
                  <>
                    <dt>Số giờ làm thêm</dt>
                    <dd>
                      {otherDetail.overtime === null ||
                      otherDetail.overtime === '' ? (
                        <EmptyText>Không có số giờ làm thêm.</EmptyText>
                      ) : (
                        <>{otherDetail.overtime} giờ</>
                      )}
                    </dd>

                    <dt>Số giờ được duyệt</dt>
                    <dd>
                      {otherDetail.approvalOvertime === null ||
                      otherDetail.approvalOvertime === '' ? (
                        <EmptyText>Không có số giờ được duyệt.</EmptyText>
                      ) : (
                        <>{otherDetail.approvalOvertime} giờ</>
                      )}
                    </dd>
                  </>
                )} */}
              </DocumentInfoList>
            </ContentBlockWrapper>

            <div style={{ marginBottom: 15 }} />

            <ContentBlockWrapper style={{ position: 'relative' }}>
              <ProposalComment proposalId={proposalId} />
              <SpinnerInlineComponent sizeSpin={'small'} />
            </ContentBlockWrapper>
          </BlockRight>
        </ContentWrapper>
      </ViewDetailContent>

      <ApproverListModal />

      <Modal
        title={'Chỉnh sửa hành chính'}
        onCancel={handleCancelUpdateModal}
        width={1000}
        footer={null}
        maskClosable={false}
        visible={isVisibleUpdateModal}
        destroyOnClose>
        {isVisibleUpdateModal && (
          <ProposalDetailUpdate
            proposalType={otherDetail.proposal_type}
            onCancel={handleCancelUpdateModal}
            name={renderTitlePage}
            approvalLevelList={otherDetail?.approval_levels[0] || []}
          />
        )}
      </Modal>
      <Modal
        title={'Chỉnh sửa danh sách người theo dõi hành chính'}
        onCancel={handleCancelUpdateFollowersModal}
        width={1000}
        footer={null}
        maskClosable={false}
        visible={isVisibleUpdateFollowersModalModal}
        destroyOnClose>
        {isVisibleUpdateFollowersModalModal && (
          <ProposalDetailUpdateFollowers
            proposalType={otherDetail.proposal_type}
            onCancel={handleCancelUpdateFollowersModal}
            name={renderTitlePage}
            approvalLevelList={otherDetail?.approval_levels[0] || []}
          />
        )}
      </Modal>
    </DashboardLayout>
  )
}

ProposalDetailPage.propTypes = {}

export default inject(
  'proposalStore',
  'notificationStore',
  'aclStore',
  'commonStore',
  'authenticationStore',
  'proposalTypeStore'
)(observer(ProposalDetailPage))
