import React, { useEffect, useState } from 'react'
import { Empty, message, Modal, Select, Form } from 'antd'
import OtherSubmissionForm from './otherSubmissionForm'
import { inject, observer } from 'mobx-react'
import FileDownload from 'js-file-download'
import selectUserStore from '../../stores/selectUserStore'
import { runInAction, toJS } from 'mobx'
import { PROPOSAL_TYPE } from '../../constants'

const SubmissionForm = props => {
  const {
    proposalTypes,
    handleCancel,
    showSubmissionMenu,
    showModal,
    onCloseModal,
    proposalStore,
    loadingAnimationStore,
    approvalTemplateStore,
    contentTemplateStore,
  } = props
  const [form] = Form.useForm()
  const { formProposalType } = proposalStore
  const [proposalTypeById, setProposalTypeById] = useState(null)
  const [initialProposalTypeById, setInitialProposalTypeById] = useState(null)

  const [approvalTemplateList, setApprovalTemplateList] = useState(null)
  useEffect(() => {
    ;(async () => {
      await loadApprovalTemplateList()
      setApprovalTemplateList(approvalTemplateStore.approvalTemplateLists)
    })()
  }, [])
  const loadApprovalTemplateList = async () => {
    try {
      await approvalTemplateStore.getApprovalTemplateLists()
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
    }
  }
  useEffect(() => {
    const defaultProposalType = proposalTypes.find(
      item => item.id === 'BUSINESS_TRIP'
    )
    setProposalTypeById(defaultProposalType)
    setInitialProposalTypeById(defaultProposalType)
  }, [proposalTypes])

  /** Render thông tin khi thay đổi form phiếu trình  */
  const handleChangeFormType = value => {
    const proposalType = proposalTypes.find(item => item.id === value)

    setProposalTypeById(proposalType)
    selectUserStore.clearSelectData()
  }

  /** Xử lý download văn bản mẫu */
  const handleDownloadAttachment = (fileId, fileTitle) => {
    loadingAnimationStore.showSpinner(true)
    proposalStore
      .downloadAttachment(fileId)
      .then(response => {
        loadingAnimationStore.showSpinner(false)
        const blob = new Blob([response.data], {
          type:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        FileDownload(blob, fileTitle)
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  /** Render form phiếu trình tương ứng */
  const renderSubmissionForm = () => {
    if (proposalTypeById?.name) {
      return (
        <OtherSubmissionForm
          onCloseModal={onCloseModal}
          proposalTypeById={proposalTypeById}
          approvalTemplateList={approvalTemplateList}
          handleModalClose={handleModalClose}
          form={form}
          handleDownloadAttachment={handleDownloadAttachment}
        />
      )
    }

    return <Empty description={<span>Bạn chưa chọn loại!</span>} />
  }
  const handleModalClose = () => {
    setProposalTypeById(initialProposalTypeById)
    form.setFieldsValue({
      title: null,
    })
    handleCancel()
  }

  return (
    <Modal
      title={proposalTypeById ? proposalTypeById?.name : 'Tạo mới'}
      visible={showModal}
      onCancel={handleModalClose}
      width={1000}
      maskClosable={false}
      footer={null}>
      <label style={{ fontWeight: 'bold' }}>Loại:</label>
      <Select
        style={{ width: '100%', marginBottom: 30, marginTop: 5 }}
        placeholder="Chọn loại"
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children?.toLowerCase().includes(input.toLowerCase())
        }
        onChange={handleChangeFormType}
        value={proposalTypeById?.id}>
        {showSubmissionMenu}
      </Select>

      {renderSubmissionForm()}
    </Modal>
  )
}

SubmissionForm.propTypes = {}

export default inject(
  'proposalStore',
  'selectUserStore',
  'loadingAnimationStore',
  'contentTemplateStore',
  'approvalTemplateStore'
)(observer(SubmissionForm))
