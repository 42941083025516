import {
  TextWrap,
  TextWrapUnread,
  trimOverlengthStringWork,
} from '../Common/CellText'
import React from 'react'

const UnreadTextWork = props => {
  return props.isUnread ? (
    <TextWrapUnread>
      {trimOverlengthStringWork(props.text, props.width)}
    </TextWrapUnread>
  ) : (
    <TextWrap>{trimOverlengthStringWork(props.text, props.width)}</TextWrap>
  )
}

export default UnreadTextWork
