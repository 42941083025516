import React from 'react'
import { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import './TinyMCEStyled.css'
const TinyMCE = ({ valueChange, onChange }) => {
  const [content, setContent] = useState(valueChange);

  useEffect(() => {
    setContent(valueChange);
  }, [valueChange]);

  const handleEditorChange = (newContent, editor) => {
    setContent(editor.getContent())
    if (onChange) {
      onChange(editor.getContent()) 
    }
  };

  return (
    <div style={{ marginBottom: '24px' }}>
      <Editor
        apiKey={process.env.REACT_APP_TINY_MCE}
        value={content}
        onEditorChange={handleEditorChange}
        init={{
          language: 'vi',
          plugins:
            'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
          toolbar:
            'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
        }}
      />
    </div>
  );
};


export default TinyMCE