import axios from 'axios'
// @ts-ignore
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'
import { requests } from './DefaultRequest'

export const CompanyRequest = {
  getCompanyList: param =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/companies`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { ...param, status: true },
    }),
  searchCompanyList: param =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/companies`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { status: true, ...param },
    }),
  searchCompanyWithUsersList: param =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/companies/users`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: { status: true, ...param },
    }),
  getCompanyDetail: code => requests.get(`/api/v1/companies/${code}`),
  getCodeByUsername: username =>
    requests.get(`/api/v1/companies/code/${username}`),

  createCompany: payload =>
    requests.post(`/api/v1/companies/departments`, payload),

  createDepartmentForCompany: (departmentName, companyCode) =>
    requests.post(`/api/v1/companies/${companyCode}/departments`, {
      name: departmentName,
    }),

  updateCompany: (code, payload) =>
    requests.put(`/api/v1/companies/${code}`, payload),

  updateCompanyStatus: (code, payload) =>
    requests.patch(`/api/v1/companies/${code}`, payload),
  updateCompanyType: (code, payload) =>
    requests.patch(`/api/v1/companies/types/${code}`, payload),
  deleteCompany: code => requests.delete(`/api/v1/companies/${code}`),

  getUserListWithRole: (role_name, company_code) =>
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/companies/${company_code}/users`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      params: {
        code: company_code,
        role_name: role_name,
      },
    }),
}
