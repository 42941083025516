import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, message, Row, Space } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { ButtonFooter } from '../WorkPage/PopupWorkForm/PopupWorkFormStyled'
import validator from '../../validator'

const ManagementAssetWarehouseUnitCreate = props => {
  const [form] = Form.useForm()
  const {
    assetTypeStore,
    AssetTypeStore,
    managementAssetWarehouseStore,
    selectPeopleStore,
    authenticationStore,
  } = props
  const [isCreating, setIsCreating] = useState(false)
  const [duplicateWarning, setDuplicateWarning] = useState('')
  const { managementAssetTypes } = managementAssetWarehouseStore

  const defaultApprovalLevel = {
    level: 0,
    name: 'Bộ phận đề nghị',
    allow_edit: false,
    username: null,
    is_deleted: false,
  }

  useEffect(() => {
    ;(async () => {
      await managementAssetWarehouseStore.getManagementAssetWarehouseAssetTypes()
      await managementAssetWarehouseStore.fetchManagementAssetTypeList()
    })()
  }, [managementAssetWarehouseStore])

  const checkDuplicateAsset = name => {
    if (!name) {
      setDuplicateWarning('Vui lòng nhập tên loại văn phòng phẩm')
    } else if (name.trim() === '') {
      setDuplicateWarning('Vui lòng nhập ký tự, không được bỏ trống')
    } else {
      const normalizedDescription = name?.trim().toLowerCase()

      const existingAssetType = managementAssetWarehouseStore.managementAssetWarehouseTypes?.content?.find(
        type => type.description.toLowerCase() === normalizedDescription
      )
      if (existingAssetType) {
        setDuplicateWarning(`Loại văn phòng phẩm ${name} đã tồn tại`)
      } else {
        setDuplicateWarning('')
      }
    }
  }

  const onAssetNameChange = e => {
    const value = e.target.value
    form.setFieldsValue({ asset_name: value })
    const typeId = form.getFieldValue('asset_type_id')
    checkDuplicateAsset(value, typeId)
  }

  const validateAssetName = async (_, value) => {
    if (!value) {
      return Promise.reject('Vui lòng nhập tên loại văn phòng phẩm')
    }
    if (value.length > 500) {
      return Promise.reject('Tên văn phòng phẩm không được dài quá 500 ký tự')
    }
    if (!/^[A-Za-z0-9].*$/.test(value)) {
      return Promise.reject('Tên loại văn phòng phẩm không đúng')
    }
    const normalizedDescription = value.trim().toLowerCase()
    const existingAssetType = managementAssetWarehouseStore.managementAssetWarehouseTypes?.content?.find(
      type => type.description.toLowerCase() === normalizedDescription
    )
    if (existingAssetType) {
      return Promise.reject(`Loại văn phòng phẩm ${value} đã tồn tại.`)
    }
    return Promise.resolve()
  }

  const onFinish = useCallback(
    async value => {
      setIsCreating(true)
      try {
        const trimmedDescription = value.description.trim()
        const payload = { description: trimmedDescription }
        const normalizedDescription = payload.description.toLowerCase()

        const existingAssetType = managementAssetWarehouseStore.managementAssetWarehouseTypes?.content?.find(
          type => type.description.toLowerCase() === normalizedDescription
        )

        if (existingAssetType) {
          setDuplicateWarning(
            `Loại văn phòng phẩm ${value.description} đã tồn tại`
          )
          setIsCreating(false)
          return
        }
        setDuplicateWarning('')
        await managementAssetWarehouseStore.createAssetType(payload)
        message.success('Thêm mới loại văn phòng phẩm thành công')
        props.onCancel()
        await managementAssetWarehouseStore.getManagementAssetWarehouseAssetTypes()
        await managementAssetWarehouseStore.fetchManagementAssetTypeList()
      } catch (error) {
        console.error(error)
        message.error('Thêm mới thất bại')
        props.onCancel()
      } finally {
        setIsCreating(false)
      }
    },
    [managementAssetWarehouseStore, props.onCancel]
  )

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            label="Loại văn phòng phẩm:"
            name="description"
            validateStatus={duplicateWarning ? 'error' : ''}
            rules={[
              {
                required: true,
                validator: validateAssetName,
              },
              {
                max: 100,
                message: 'Loại văn phòng phẩm không được dài quá 100 ký tự',
              },
            ]}>
            <Input
              placeholder="Nhập tên loại văn phòng phẩm"
              onChange={onAssetNameChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <ButtonFooter>
        <Space>
          <Button icon={<CloseOutlined />} danger onClick={props.onCancel}>
            Đóng
          </Button>
          <Button
            icon={<EditOutlined />}
            type="primary"
            htmlType="submit"
            loading={isCreating}>
            Tạo mới
          </Button>
        </Space>
      </ButtonFooter>
    </Form>
  )
}

export default inject(
  'managementAssetWarehouseStore',
  'selectPeopleStore'
)(observer(ManagementAssetWarehouseUnitCreate))
