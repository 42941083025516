import React, { useState } from 'react'
// Ant design
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Select,
} from 'antd'
// Styled Components
import {
  ButtonWrapper,
  SignatureCanvasWrapper,
} from './SignatureCanvasModalStyled'
// Mobx
import { inject, observer } from 'mobx-react'
// Other
import SignaturePad from 'react-signature-canvas'
import { DIGITAL_SIGN_PROVIDER, DIGITAL_TYPE_SIGN } from '../../../../constants'

const SignatureCanvasModal = props => {
  const {
    isVisibleSignatureCanvasModal,
    handleCancelSignatureCanvas,
    handleCloseCreateSignatureModal,
    handleCreatedSuccess,
    signedDocumentStore,
    loadingAnimationStore,
    fileStore,
  } = props

  const checkCanvasSizeInBytes = canvas => {
    const width = canvas.width
    const height = canvas.height
    const bytesPerPixel = 4 // RGBA format = 4 bytes per pixel

    // Calculate total size in bytes
    const totalBytes = width * height * bytesPerPixel

    return totalBytes
  }

  const [form] = Form.useForm()

  const [sigPad, setSigPad] = useState({})
  const clearCanvas = () => {
    sigPad.clear()
  }

  const handleCreateSignature = async value => {
    if (selectedSignatureCv === '') {
      message.error('Bạn phải nhập loại chữ ký trước khi lưu')
      return 0
    }
    const canvas = sigPad.getTrimmedCanvas()
    if (checkCanvasSizeInBytes(canvas) <= 4) {
      message.error(
        'Chữ ký không được trống, bạn vui lòng ký mẫu trước khi lưu'
      )
      return 0
    }
    const base64Img = sigPad.getTrimmedCanvas().toDataURL('image/png')
    const fetchedImg = await fetch(base64Img)
    const blobImg = await fetchedImg.blob()
    const fileImg = new File([blobImg], 'canvas sign', { type: 'image/png' })
    const formData = new FormData()
    formData.append('file', fileImg, value.signature_name)

    try {
      loadingAnimationStore.showSpinner(true)

      //upload file
      const uploadResponse = await fileStore.uploadFile(formData)
      const fileId = uploadResponse.data.file_id
      const fileName = value.signature_name
      console.log('uploadedFile', fileId)

      //sign
      const data = {
        file_id: fileId,
        file_name: fileName,
        provider: DIGITAL_SIGN_PROVIDER,
        type_sign: DIGITAL_TYPE_SIGN,
        type_signature: selectedSignatureCv,
      }
      const createSignatureBySendFileIdResponse = await signedDocumentStore.createSignatureBySendFileId(
        data
      )

      message.success('Tạo chữ ký thành công')
      handleCreatedSuccess()
    } catch (error) {
      console.log(error)
      message.error(error.vi)
    } finally {
      clearCanvas()
      loadingAnimationStore.showSpinner(false)
      handleCancelSignatureCanvas()
      handleCloseCreateSignatureModal()
    }
  }

  const [selectedSignatureCv, setSelectedSignatureCv] = useState('MAIN_SIGN')

  const handleMenuClick = e => {
    setSelectedSignatureCv(e)
  }

  return (
    <Modal
      title={
        <span style={{ color: '#1088E7', fontWeight: 'bold' }}>CHỮ KÝ TAY</span>
      }
      visible={isVisibleSignatureCanvasModal}
      footer={null}
      onCancel={handleCancelSignatureCanvas}
      width={650}>
      <Form layout="vertical" onFinish={handleCreateSignature} form={form}>
        <Form.Item
          label="Tên mẫu chữ ký"
          name="signature_name"
          rules={[{ required: true, message: 'Hãy nhập tên mẫu chữ ký!' }]}>
          <Input placeholder={'Nhập tên mẫu chữ ký'} />
        </Form.Item>
        <Form.Item
          name="type_signature"
          label="Loại chữ ký"
          rules={[{ required: true, message: 'Hãy nhập loại chữ ký!' }]}
        >
          <Select
            allowClear
            // showSearch
            value={selectedSignatureCv}
            onChange={handleMenuClick}
            defaultValue={''}>
            <Option value="">
              <span style={{ color: 'rgba(0,0,0,0.3)' }}>
                Vui lòng chọn loại chữ ký{' '}
              </span>
            </Option>
            <Option value="FLASH_SIGN">Chữ ký nháy</Option>
            <Option value="MAIN_SIGN">Chữ ký chính</Option>
            <Option value="CIRCLE_SIGN">Dấu mộc</Option>
            <Option value="LOGO">Logo công ty</Option>
          </Select>
        </Form.Item>
        <div style={{ position: 'relative' }}>
          <SignatureCanvasWrapper>
            <SignaturePad
              ref={ref => {
                setSigPad(ref)
              }}
              penColor={'#000'}
              canvasProps={{ width: 600, height: 300, className: 'sigCanvas' }}
            />
          </SignatureCanvasWrapper>
        </div>
        <ButtonWrapper>
          <Button danger ghost onClick={clearCanvas}>
            Xóa
          </Button>
          <Button type={'primary'} htmlType={'submit'}>
            Lưu chữ ký
          </Button>
        </ButtonWrapper>
      </Form>
    </Modal>
  )
}

SignatureCanvasModal.propTypes = {}

export default inject(
  'signedDocumentStore',
  'loadingAnimationStore',
  'fileStore'
)(observer(SignatureCanvasModal))
