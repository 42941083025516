import { Button, Divider } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import './RiskActionDetails.css'
import RiskActionDetails1 from './RiskActionDetails1'
import RiskActionDetails2 from './RiskActionDetails2'
import RiskActionDetails3 from './RiskActionDetails3'
import RiskActionDetails4 from './RiskActionDetails4'

const RiskActionDetails = props => {
  const {
    data,
    tier,
    isPartOfPdf = false,
    openEditPopUp = () => {},
    actionStore,
    updateTierApproval,
    departmentWithUserMasterList,
  } = props

  const { setIndexDetailAction } = actionStore
  let III_kiem_soat_rui_ro = {}

  if (data !== null && data?.length > 0) {
    III_kiem_soat_rui_ro = data[0]?.III_kiem_soat_rui_ro
  }

  const BAN_CMNV_VIMC = departmentWithUserMasterList?.find(
    depart => depart.name === III_kiem_soat_rui_ro?.phong_ban_nddpv
  )
  return (
    <div>
      {updateTierApproval !== undefined ? (
        <>
          {!isPartOfPdf && (
            <>
              <Divider />
              <Button
                style={{
                  // marginTop: 16,
                  // top: '-30px',
                  left: '86%',
                  // transform: 'translate(-50%, 0)',
                  background: '#2c65ac',
                  color: 'white',
                }}
                onClick={() => {
                  setIndexDetailAction({ tier, index: null })

                  openEditPopUp()
                }}>
                Sửa thông tin
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          {!isPartOfPdf && (
            <>
              <Divider />
              <Button
                style={{
                  // marginTop: 16,
                  // top: '-440px',
                  left: '86%',
                  // transform: 'translate(-50%, 0)',
                  background: '#2c65ac',
                  color: 'white',
                }}
                onClick={() => {
                  setIndexDetailAction({ tier, index: null })

                  openEditPopUp()
                }}>
                Điền thông tin
              </Button>
            </>
          )}
        </>
      )}

      {data && tier === '1' ? <RiskActionDetails1 {...props} /> : <></>}
      {data && tier === '2' ? <RiskActionDetails2 {...props} BAN_CMNV_VIMC={BAN_CMNV_VIMC} /> : <></>}
      {data && tier === '3' ? <RiskActionDetails3 {...props} /> : <></>}
      {data && tier === '4' ? <RiskActionDetails4 {...props} /> : <></>}
    </div>
  )
}

export default inject(
  'actionStore',
  'departmentStore',
  'riskStore',
  'selectPeopleStore'
)(observer(RiskActionDetails))
