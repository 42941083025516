import React, { useEffect, useState } from 'react'
import { apiUrl } from '../../config'
import { inject, observer } from 'mobx-react'
import axios from 'axios'
import { Spin } from 'antd'

const FilePreview = ({ fileId, authenticationStore }) => {
  const [fileUrl, setFileUrl] = useState(null)
  const [error, setError] = useState(null)
  const [fileType, setFileType] = useState(null)

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await axios({
          method: 'get',
          url: `${apiUrl}/api/v1/procurement/preview/${fileId}`,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${
              JSON.parse(authenticationStore.appToken).access_token
            }`,
            'Content-Type': 'application/json',
          },
        })

        const mimeType = response.headers['content-type']
        setFileType(mimeType)

        const url = URL.createObjectURL(response.data)
        setFileUrl(url)
      } catch (err) {
        setError(
          err.response?.status === 404 ? 'File not found' : 'Error loading file'
        )
      }
    }

    fetchFile()

    return () => {
      // Clean up the object URL
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl)
      }
    }
  }, [fileId])

  if (error) {
    return <div>Đã có lỗi xảy ra: {error}</div>
  }

  if (!fileUrl) {
    return <Spin tip="Đang tải..." />
  }

  // Render different previews based on file type
  if (fileType?.includes('image')) {
    return (
      <img
        src={fileUrl}
        alt="File Preview"
        style={{ width: '100%', height: 'auto' }}
      />
    )
  }

  if (fileType === 'application/pdf') {
    return (
      <iframe src={fileUrl} width="100%" height="800px" title="PDF Preview" />
    )
  }

  if (
    fileType ===
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    return (
      <iframe
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          fileUrl
        )}`}
        width="100%"
        height="800px"
        title="Word Document Preview"
      />
    )
  }

  return <div>Unsupported file type: {fileType}</div>
}

export default inject('authenticationStore')(observer(FilePreview))
