import {
    BankOutlined,
    CloseOutlined,
    EditOutlined,
    TeamOutlined,
    UserOutlined,
  } from '@ant-design/icons'
  import {
    Button,
    Checkbox,
    Form,
    Input,
    Space,
    Tag,
    Tooltip,
    message,
  } from 'antd'
  import { runInAction } from 'mobx'
  import { inject, observer } from 'mobx-react'
  import React, { useEffect, useState } from 'react'
  import { blue } from '../../color'
  import { AvatarHasName } from '../../components/Common/Avatar'
  import SelectPeoplePopup from '../../components/SelectPeoplePopup'
  import { ASSIGNEE_TYPE } from '../../constants'
  import {
    SelectList,
    SelectTitle,
  } from '../MissionCreatePage/MissionCreatePageStyled'
  import { ButtonFooter } from '../WorkPage/PopupWorkForm/PopupWorkFormStyled'
  import { getInfoUser } from './ManagerInventoryUpdate'
  import validator from '../../validator'
  const ManagerInventorySortableLevelsEdit = props => {
    const [form] = Form.useForm()
    const { selectPeopleStore, managerInventoryStore, level } = props
    const {
      handleOpenSelectUserDepartmentPopup,
      originDepartmentWithUserList,
      handleCancelSelectUserDepartment,
      setIsVisibleSelectUserDepartmentPopup,
      isVisibleSelectUserDepartmentPopup,
      selectUserData,
      selectDepartmentData,
      selectGroupData,
    } = selectPeopleStore
    const [handlerList, setHandlerList] = useState([])
    const [shouldRunOnFinish, setShouldRunOnFinish] = useState(true)
    useEffect(() => {
      setHandlerList([
        ...selectUserData[ASSIGNEE_TYPE.HANDLER],
        ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
        ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
      ])
    }, [selectUserData, selectDepartmentData, selectGroupData])
  
    useEffect(() => {
      form.setFieldsValue({
        title: level?.name.replace(/\s+/g, ' ').trim(),
        allow_edit: level?.allow_edit,
      })
  
      if (level.handler?.username) {
        const handler = getInfoUser(
          level.handler.username,
          originDepartmentWithUserList
        )
  
        selectPeopleStore.setSelectUserData({
          ...selectUserData,
          PIC: [
            {
              assignee_type: ASSIGNEE_TYPE.USER,
              full_name: handler.full_name,
              id: handler.username,
              image_id: handler.image_id,
              permission: ASSIGNEE_TYPE.HANDLER,
            },
          ],
        })
      }
    }, [level])
  
    const onFinish = value => {
      const mapped = managerInventoryStore.managerInventoryListLevels.map(
        item => {
          if (item.code === level.code) {
            let result = {
              ...item,
              name: value.title.replace(/\s+/g, ' ').trim(),
              allow_edit: value.allow_edit,
            }
  
            if (handlerList.length > 0) {
              result.handler = {
                full_name: handlerList[0].full_name,
                username: handlerList[0].id,
              }
            } else {
              result.handler = null
              result.allow_edit = true
            }
  
            return result
          }
  
          return item
        }
      )
      if (shouldRunOnFinish) {
        runInAction(() => {
            managerInventoryStore.managerInventoryListLevels = mapped
        })
        props.onCancel()
      }
    }
  
    return (
      <>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label={'Tên cấp duyệt:'}
            name={'title'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên cấp duyệt' },
              {
                max: 500,
                message: 'Tên cấp duyệt không được dài quá 500 ký tự!',
              },
              { validator: validator.validateInputString },
            ]}>
            <Input placeholder={'Nhập tên cấp duyệt...'} />
          </Form.Item>
  
          <SelectTitle>
            <span>
              <span style={{ marginRight: '10px' }}>Người xử lý:</span>
            </span>
            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.HANDLER)
                  }
                />
              </Tooltip>
            </Space>
          </SelectTitle>
  
          {handlerList.length > 0 && (
            <SelectList>
              {handlerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </SelectList>
          )}
  
          <Form.Item name="allow_edit" valuePropName="checked">
            <Checkbox disabled={handlerList.length === 0}>
              Cho phép người duyệt thay người xử lý
            </Checkbox>
          </Form.Item>
  
          <ButtonFooter>
            <Space>
              <Button icon={<CloseOutlined />} danger onClick={props.onCancel}>
                Đóng
              </Button>
              <Button
                icon={<EditOutlined />}
                type={'primary'}
                htmlType={'submit'}>
                Sửa
              </Button>
            </Space>
          </ButtonFooter>
        </Form>
        <SelectPeoplePopup
          allowChangeCompany={false}
          onlyOneUser
          handleCancelSelectUser={handleCancelSelectUserDepartment}
          handleSubmitSelectUser={() =>
            setIsVisibleSelectUserDepartmentPopup(false)
          }
          isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        />
      </>
    )
  }
  
  export default inject(
    'selectPeopleStore',
    'managerInventoryStore'
  )(observer(ManagerInventorySortableLevelsEdit))
  