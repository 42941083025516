import React, { useCallback, useEffect, useRef, useState } from 'react'
// Component
// Style Component
import {
  SelectList,
  SelectTitle,
} from '../../MissionCreatePage/MissionCreatePageStyled'
import {
  ButtonFooter,
  UploadFileListItem,
  UploadFileListWrapper,
} from './PopupUpdateWorkStyled'
// Ant design
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Upload,
} from 'antd'
import {
  BankOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PaperClipOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
// Other
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_LIST,
  WORK_STATUS,
  ISO_DATE_FORMAT,
} from '../../../constants'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../../components/SelectGroupPopup/SelectGroupPopup'
import { AvatarHasName } from '../../../components/Common/Avatar'
import { blue, yellowPrimary } from '../../../color'
import RichEditor from '../../../components/RichEditor/RichEditor'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import utils from '../../../utils'
import validator from '../../../validator'

const PopupUpdateWork = props => {
  const {
    isVisiblePopupUpdateWork,
    handleCloseUpdateWork,
    selectPeopleStore,
    fileStore,
    workStore,
    loadingAnimationStore,
  } = props

  const { Option } = Select
  const [form] = Form.useForm()

  const { workDetail, workList } = workStore
  const { workTypeList, queryParams } = workStore

  const {
    assignees,
    attachments,
    content,
    due_date,
    start_date,
    title,
    work_type,
  } = workDetail

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    originDepartmentWithUserList,
  } = selectPeopleStore

  const EDITOR_REF = useRef(null)

  const location = useLocation()
  const queryStringParsed = queryString.parse(location.search, {
    arrayFormat: 'index',
  })

  const [fileUploadedList, setFileUploadedList] = useState([])
  const [fileList, setFileList] = useState([])
  const [handlerList, setHandlerList] = useState([])
  const [followerList, setFollowerList] = useState([])
  const userList = assignees
    ? assignees.filter(
        el =>
          el.assignee_type === ASSIGNEE_TYPE.USER &&
          el.permission !== ASSIGNEE_TYPE.CREATE
      )
    : []
  const departmentList = assignees
    ? assignees.filter(el => el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT)
    : []
  const groupList = assignees
    ? assignees.filter(el => el.assignee_type === ASSIGNEE_TYPE.GROUP)
    : []
  useEffect(() => {
    setHandlerList([
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
    ])
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  useEffect(() => {
    if (!isVisiblePopupUpdateWork) return

    if (
      selectUserData[ASSIGNEE_TYPE.HANDLER].length === 0 &&
      selectUserData[ASSIGNEE_TYPE.FOLLOWER].length === 0
    ) {
      selectPeopleStore.setSelectUserData(
        selectPeopleStore.convertSelectDataObj(userList)
      )
    }

    if (
      selectDepartmentData[ASSIGNEE_TYPE.HANDLER].length === 0 &&
      selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER].length === 0
    ) {
      selectPeopleStore.setSelectDepartmentData(
        selectPeopleStore.convertSelectDataObj(departmentList)
      )
    }

    if (
      selectGroupData[ASSIGNEE_TYPE.HANDLER].length === 0 &&
      selectGroupData[ASSIGNEE_TYPE.FOLLOWER].length === 0
    ) {
      selectPeopleStore.setSelectGroupData(
        selectPeopleStore.convertSelectDataObj(groupList)
      )
    }
  }, [originDepartmentWithUserList])

  useEffect(() => {
    form.setFieldsValue({
      work_type: work_type,
      title: title,
      start_date: start_date && moment(start_date),
      due_date: due_date && moment(due_date),
    })
    setFileUploadedList(attachments)
  }, [workDetail])

  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }, [])

  const handleRemoveFileFromUploadList = fileUID => {
    const newFileList = fileList.filter(file => file.uid !== fileUID)
    setFileList(newFileList)
  }

  const onFinish = async value => {
    const code_work = workDetail?.code
    const new_items_assign = []
    const remove_items_assign = []
    const listAssignOrigin = [...userList, ...departmentList, ...groupList].map(
      el => ({
        assignee_code: el.assignee_code,
        assignee_type: el.assignee_type,
        permission: el.permission,
      })
    )
    const listAssignAfter = [...followerList, ...handlerList].map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    if (handlerList.length === 0) {
      return isElectronicWork
        ? message.info('Bạn chưa chọn lãnh đạo ký duyệt!')
        : message.info('Bạn chưa chọn người xử lý!')
    }
    listAssignAfter.forEach(assignAfter => {
      if (
        !listAssignOrigin.some(
          assignOrigin =>
            assignOrigin.assignee_code + assignOrigin.permission ===
            assignAfter.assignee_code + assignAfter.permission
        )
      ) {
        new_items_assign.push({
          assignee_code: assignAfter.assignee_code,
          assignee_type: assignAfter.assignee_type,
          permission: assignAfter.permission,
        })
      }
    })
    listAssignOrigin.forEach(assignOrigin => {
      if (
        !listAssignAfter.some(
          assignAfter =>
            assignAfter.assignee_code + assignAfter.permission ===
            assignOrigin.assignee_code + assignOrigin.permission
        )
      ) {
        remove_items_assign.push({
          assignee_code: assignOrigin.assignee_code,
          assignee_type: assignOrigin.assignee_type,
          permission: assignOrigin.permission,
        })
      }
    })

    const { title, start_date, due_date, work_type } = value
    const uploadFileList = fileList.map(file => file.originFileObj)
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    try {
      loadingAnimationStore.showSpinner(true)
      const response = await Promise.all(batchUploadArr)
      const dataSubmit = {
        title: title,
        start_date: start_date,
        due_date: due_date,
        content: EDITOR_REF.current.editor.getData(),
        attachments: response
          .map(response => response.data.file_id)
          .concat(fileUploadedList.map(file => file.file_id)),
        assignees: {
          new_items: new_items_assign,
          remove_items: remove_items_assign,
        },
        current_assignees: listAssignAfter,
        code: code_work,
        work_type: work_type,
      }
      await workStore.updateWork(code_work, dataSubmit)
      const responseDetail = await workStore.getWorkDetail(code_work)
      const parentId = utils.getParameterByName('parent_id')
      if (parentId) {
        await workStore.getListSubWork(responseDetail.data.code)
      } else {
        const newWorkList = [...workList]
        const indexParentTarget = workList.findIndex(
          item => item.code === code_work
        )
        newWorkList[indexParentTarget] = {
          ...responseDetail.data,
          has_subwork: responseDetail.data?.subwork?.length > 0,
        }
        workStore.setWorkList(newWorkList)
      }
      setFileList([])
      selectPeopleStore.clearSelected()
      handleCloseUpdateWork()
      message.success('Cập nhật công việc thành công!')
    } catch (err) {
      console.log(err)
      message.error('Chỉ người xử lý mới có quyền cập nhật công việc!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const {
    title: titleReset,
    start_date: startDateReset,
    due_date: dueDateReset,
  } = form.getFieldsValue(['title', 'start_date', 'due_date'])

  const handleCancel = () => {
    setFileList([])
    setFileUploadedList(attachments)
    selectPeopleStore.clearSelected()
    form.setFieldsValue({
      title: titleReset,
      start_date:
        startDateReset &&
        moment(moment(startDateReset).format(ISO_DATE_FORMAT)),
      due_date:
        dueDateReset && moment(moment(dueDateReset).format(ISO_DATE_FORMAT)),
    })
    EDITOR_REF.current.editor.setData(EDITOR_REF.current?.props.data)
    handleCloseUpdateWork()
  }

  const isElectronicWork = work_type === WORK_STATUS.ELECTRONIC

  return (
    <Modal
      width={800}
      style={{ top: 40 }}
      title={'Chỉnh sửa công việc'}
      visible={isVisiblePopupUpdateWork}
      maskClosable={false}
      onCancel={handleCancel}
      footer={null}>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Form.Item
          style={{ display: 'none' }}
          label={'Loại công việc'}
          name={'work_type'}
          rules={[{ required: true, message: 'Vui lòng chọn loại công việc' }]}>
          <Select
            className={isElectronicWork && 'ant-disabled'}
            placeholder={'Chọn loại công việc'}>
            {workTypeList.map(workType => (
              <Option
                disabled={isElectronicWork}
                key={workType.id}
                value={workType.id}
                name={workType.name}>
                {workType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={'Tiêu đề:'}
          name={'title'}
          rules={[
            { required: true, message: 'Vui lòng nhập tiêu đề' },
            { validator: validator.validateInputString },
            {
              max: 500,
              message: 'Tiêu đề vượt quá 500 ký tự, vui lòng nhập lại tiêu đề',
            },
          ]}>
          <Input
            className={isElectronicWork && 'ant-disabled'}
            disabled={isElectronicWork}
            placeholder={'Nhập tiêu đề...'}
          />
        </Form.Item>

        <Form.Item label={'Nội dung:'} name={'content'}>
          <RichEditor
            EDITOR_REF={EDITOR_REF}
            placeholder={'Nhập nội dung...'}
            editorContent={content && content}
          />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label={'Ngày bắt đầu:'}
              rules={[
                { required: true, message: 'Vui lòng chọn ngày bắt đầu!' },
              ]}
              name={'start_date'}>
              <DatePicker format={DATE_FORMAT_LIST} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={'Ngày kết thúc:'}
              name={'due_date'}
              rules={[
                { required: false, message: 'Vui lòng chọn ngày kết thúc!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const startDate = getFieldValue('start_date')
                    if (!startDate || !value) {
                      //! Không thực hiện kiểm tra nếu ngày bắt đầu hoặc ngày kết thúc không có giá trị
                      return Promise.resolve()
                    }
                    if (value.isAfter(startDate)) {
                      //! Ngày kết thúc lớn hơn ngày bắt đầu
                      return Promise.resolve()
                    }
                    //! Ngày kết thúc không hợp lệ
                    return Promise.reject(
                      'Ngày kết thúc phải lớn hơn ngày bắt đầu!'
                    )
                  },
                }),
              ]}>
              <DatePicker format={DATE_FORMAT_LIST} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <SelectTitle>
              <span>
                <span
                  style={{
                    color: '#ff4d4f',
                    fontFamily: 'SimSun, sans-serif',
                    marginRight: '-4px',
                  }}>
                  *{' '}
                </span>
                <span style={{ marginRight: '10px' }}>
                  {isElectronicWork ? 'Lãnh đạo ký duyệt:' : 'Người xử lý:'}
                </span>
              </span>
              <Space>
                <Tooltip title="Chọn người dùng" color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() =>
                      handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.HANDLER)
                    }
                  />
                </Tooltip>
                {!isElectronicWork && (
                  <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                    <Button
                      icon={<TeamOutlined />}
                      style={{ color: yellowPrimary }}
                      onClick={() =>
                        handleOpenSelectGroupPopup(ASSIGNEE_TYPE.HANDLER)
                      }
                    />
                  </Tooltip>
                )}
              </Space>
            </SelectTitle>
            <SelectList>
              {handlerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </SelectList>
          </Col>

          <Col span={12}>
            <SelectTitle>
              <span>
                <span style={{ marginRight: '10px' }}>Người theo dõi:</span>
              </span>
              <Space>
                <Tooltip title="Chọn người dùng" color={blue}>
                  <Button
                    icon={<UserOutlined style={{ color: blue }} />}
                    onClick={() =>
                      handleOpenSelectUserDepartmentPopup(
                        ASSIGNEE_TYPE.FOLLOWER
                      )
                    }
                  />
                </Tooltip>
                <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                  <Button
                    icon={<TeamOutlined />}
                    style={{ color: yellowPrimary }}
                    onClick={() =>
                      handleOpenSelectGroupPopup(ASSIGNEE_TYPE.FOLLOWER)
                    }
                  />
                </Tooltip>
              </Space>
            </SelectTitle>
            <SelectList>
              {followerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </SelectList>
          </Col>
        </Row>
        <Form.Item label={'Chọn file đính kèm:'} name={'id_file_dinh_kem'}>
          <Upload
            valuePropName={'fileList'}
            fileList={fileList}
            multiple={true}
            onChange={handleChangeFile}
            beforeUpload={() => false}
            showUploadList={false}>
            <Button htmlType={'button'}>
              <UploadOutlined />
              Chọn files đính kèm
            </Button>
          </Upload>
        </Form.Item>
        {fileList.length !== 0 && (
          <UploadFileListWrapper>
            {fileList.map((file, index) => (
              <UploadFileListItem key={file.uid}>
                <PaperClipOutlined />
                <span>{file.name}</span>
                <Tooltip title={'Xoá tập tin'}>
                  <DeleteOutlined
                    onClick={() => handleRemoveFileFromUploadList(file.uid)}
                  />
                </Tooltip>
              </UploadFileListItem>
            ))}
          </UploadFileListWrapper>
        )}
        {fileUploadedList && fileUploadedList.length !== 0 && (
          <UploadFileListWrapper>
            {fileUploadedList.map(file => (
              <UploadFileListItem key={file.file_id}>
                <PaperClipOutlined />
                <span>{file.file_name}</span>
                <Tooltip title={'Xoá tập tin'}>
                  <DeleteOutlined
                    onClick={() =>
                      setFileUploadedList(
                        fileUploadedList.filter(
                          el => el.file_id !== file.file_id
                        )
                      )
                    }
                  />
                </Tooltip>
              </UploadFileListItem>
            ))}
          </UploadFileListWrapper>
        )}
        <ButtonFooter>
          <Space>
            <Button icon={<CloseOutlined />} danger onClick={handleCancel}>
              Hủy
            </Button>
            <Button
              icon={<EditOutlined />}
              type={'primary'}
              htmlType={'submit'}>
              Cập nhật
            </Button>
          </Space>
        </ButtonFooter>
      </Form>
      <SelectPeoplePopup
        onlyOneUser={
          isElectronicWork
            ? selectPeopleStore.assigneePopupType === ASSIGNEE_TYPE.HANDLER
            : false
        }
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
    </Modal>
  )
}

PopupUpdateWork.propTypes = {}

export default inject(
  'selectPeopleStore',
  'fileStore',
  'workStore',
  'loadingAnimationStore'
)(observer(PopupUpdateWork))
