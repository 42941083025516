import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Modal, Row, Select, Space } from 'antd'
import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import RichEditor from '../../../components/RichEditor/RichEditor'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent'
import commonStore from '../../../stores/commonStore'
import validator from '../../../validator'

const { TextArea } = Input

const RiskAriseUpsertModal = ({
  isVisible,
  handleCancelCreateModal,
  type,
  riskAriseStore,
  loadRiskAriseList,
  handleCancelEditModal,
  risk,
  setEditRisk,
  onFinish,
}) => {
  const { Option } = Select
  const { createRiskArise, updateRiskArise } = riskAriseStore
  const [form] = Form.useForm()
  const CAUSE_REF = useRef()
  const [isRenderRichText, setIsRenderRichText] = useState(false)
  const [riskAriseTypes, setRiskAriseTypes] = useState()

  const loadTypes = async () => {
    try {
      const { data } = await riskAriseStore.getRiskAriseTypes()
      setRiskAriseTypes(data)
    } catch (error) {
      console.log('🚀 error:', error)
      message.error('Đã có lỗi xảy ra')
    }
  }

  useEffect(() => {
    loadTypes()
  }, [])

  useEffect(() => {
    if (!risk) {
      return
    }
    form.setFieldsValue({
      assigned_work: risk.assigned_work,
      time_arises: risk.time_arises,
      identification_risk: risk.identification_risk,
      reason: risk.reason,
      type: risk.type,
    })
  }, [risk])

  const [buttonBlock, setButtonBlock] = useState(false)

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsRenderRichText(true)
      }, 400)
    } else {
      setIsRenderRichText(false)
    }
  }, [isVisible])
  const handleUpsertRisk = async value => {
    setButtonBlock(true)
    const submitData = {
      assigned_work: value.assigned_work.trim(),
      time_arises: value.time_arises.trim(),
      identification_risk: value.identification_risk.trim(),
      // @ts-ignore
      reason: CAUSE_REF.current.editor.getData(),
      type: value.type,
    }
    try {
      risk
        ? await updateRiskArise(risk.id, submitData)
        : await createRiskArise(submitData)
      risk
        ? message.success('Cập nhật thành công')
        : message.success('Tạo mới thành công')
      handleCancelCreateModal()
      setEditRisk(null)
      onFinish()
    } catch {
      message.error('Đã có lỗi xảy ra')
    }
  }

  return (
    <Modal
      visible={isVisible}
      footer={null}
      destroyOnClose={true}
      afterClose={() => {
        setButtonBlock(false)
        setIsRenderRichText(false)
      }}
      width={600}
      onCancel={risk ? handleCancelEditModal : handleCancelCreateModal}
      title={
        <>
          {/* @ts-ignore */}
          <ExclamationCircleOutlined
            style={{
              color: commonStore.appTheme.solidColor,
              marginRight: 10,
            }}
          />
          {risk
            ? 'Cập nhật rủi ro nhận diện phát sinh'
            : 'Tạo mới rủi ro nhận diện phát sinh'}
        </>
      }>
      <Form
        layout={'vertical'}
        onFinish={handleUpsertRisk}
        preserve={false}
        form={form}>
        <Form.Item
          label={'Loại'}
          name={'type'}
          rules={[{ required: true, message: 'Vui lòng chọn !' }]}>
          <Select
            style={{ minWidth: '250px' }}
            placeholder={'Chọn loại'}
            allowClear
            disabled={risk ? true : false}>
            {riskAriseTypes?.length > 0 &&
              riskAriseTypes.map(type => (
                <Option key={type.code} value={type.code}>
                  {type.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={'Công việc được giao'}
          name={'assigned_work'}
          rules={[
            { required: true, message: 'Vui lòng nhập !' },
            { validator: validator.validateInputString },
          ]}>
          <TextArea rows={3} placeholder={'Nhập công việc được giao'} />
        </Form.Item>
        <Form.Item
          label={'Rủi ro nhận diện'}
          name={'identification_risk'}
          rules={[
            { required: true, message: 'Vui lòng nhập !' },
            { validator: validator.validateInputString },
          ]}>
          <TextArea rows={3} placeholder={'Nhập rủi ro nhận diện'} />
        </Form.Item>
        <Form.Item
          label={'Thời gian phát sinh'}
          name={'time_arises'}
          rules={[
            { required: true, message: 'Vui lòng nhập !' },
            { validator: validator.validateInputString },
          ]}>
          <Input placeholder={'Nhập thời gian phát sinh'} />
        </Form.Item>
        <Form.Item label={'Nguyên nhân'} name={'reason'}>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={CAUSE_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={risk?.reason}
            />
          )}
        </Form.Item>
        <Row justify={'end'}>
          <Space>
            <Button
              onClick={risk ? handleCancelEditModal : handleCancelCreateModal}>
              Hủy
            </Button>
            <Button
              disabled={buttonBlock ? true : false}
              type={'primary'}
              htmlType={'submit'}>
              {risk ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default RiskAriseUpsertModal
