import { action, observable } from 'mobx'
import { CLOUD_DOCUMENT } from '../constants'
import { CommandRequest } from '../requests/CommandRequest'
import { vimcTaiLieuUrl } from '../config'
import DistributionVoteStore from './DistributionVotesStore'
import ShoppingCouponStore from './ShoppingCouponStore'

class CommandStore {
  @observable sideMenuCounter = {
    work: 0,
    proposal: 0,
    unread_internal_message: 0,
    unapproved_distribute_vote: 0,
    pending_shoppping: 0,
  }
  @observable dashboardSideMenuCounter = {}
  /** Command list */
  @observable commandList = []

  getSideMenuCounterDashboard = async types => {
    let response = await CommandRequest.getSideMenuCounter(types)
    if (types?.includes('PROPOSAL'))
      this.dashboardSideMenuCounter = {
        ...this.dashboardSideMenuCounter,
        proposal_other: response.data.proposal_other,
      }
    if (types?.includes('INCOMING'))
      this.dashboardSideMenuCounter = {
        ...this.dashboardSideMenuCounter,
        unread_incoming: response.data.unread_incoming,
      }
    if (types?.includes('TASK'))
      this.dashboardSideMenuCounter = {
        ...this.dashboardSideMenuCounter,
        overdue_task: response.data.overdue_task,
      }
    if (types?.includes('OUTGOING'))
      this.dashboardSideMenuCounter = {
        ...this.dashboardSideMenuCounter,
        unread_outgoing: response.data.unread_outgoing,
      }
    if (types?.includes('WORK'))
      this.dashboardSideMenuCounter = {
        ...this.dashboardSideMenuCounter,
        work: response.data.work,
      }
    if (types?.includes('DISTRIBUTE'))
      this.dashboardSideMenuCounter = {
        ...this.dashboardSideMenuCounter,
        unapproved_distribute_vote: response.data.unapproved_distribute_vote,
      }
    if (types?.includes('SHOPPING'))
      this.dashboardSideMenuCounter = {
        ...this.dashboardSideMenuCounter,
        pending_shoppping: response.data.pending_shoppping,
      }
  }

  @action async getSideMenuCounter(types) {
    try {
      await DistributionVoteStore.getDistributionVotesListToCountPending()
      const pendingProposalsCount = DistributionVoteStore.distributionVotesListCount.filter(
        item => item.proposal_status === 'PENDING'
      ).length
      const res = await ShoppingCouponStore.getAllShoppingCouponV2({
        page: 0,
        size: 99999,
      })
      const doneShoppingCount = res.data?.content?.filter(
        item => item.type === 'PAID' || item.type === 'REJECT'
      ).length
      const pendingShoppingCount = res.data?.totalElements - doneShoppingCount
      const response = await CommandRequest.getSideMenuCounter(types)
      response.data.unapproved_distribute_vote = pendingProposalsCount
      response.data.pending_shoppping = pendingShoppingCount
      this.sideMenuCounter = response.data
    } catch (error) {
      console.error('Error fetching side menu counter:', error)
    }
  }

  @action incrementUnapprovedDistributeVote = () => {
    this.sideMenuCounter.unapproved_distribute_vote += 1
  }

  @action incrementPendingShoppingRecord = () => {
    this.sideMenuCounter.pending_shoppping += 1
  }

  @action decrementPendingShoppingRecord = () => {
    if (this.sideMenuCounter.pending_shoppping > 1) {
      this.sideMenuCounter.pending_shoppping -= 1
    } else {
      this.sideMenuCounter.pending_shoppping = 0
    }
    
  }

  @action getCommandList = () => {
    return new Promise((resolve, reject) => {
      CommandRequest.getCommandList()
        .then(response => {
          response.data.forEach(item => {
            if (item.code === CLOUD_DOCUMENT) {
              item.url = vimcTaiLieuUrl
            }
          })

          this.commandList = response.data
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  /** Clear store */
  @action clearStore = () => {
    // this.commandList.length = 0
    this.dashboardSideMenuCounter = {}
  }
}

export default new CommandStore()
