import React from 'react'
import { Col, DatePicker, Input, Row, Select, Space } from 'antd'
import EmptyContent from '../../../components/EmptyContent'
import { inject, observer } from 'mobx-react'
import {
  DATE_FORMAT_DEFAULT,
  ELECTRONIC_DOCUMENT_STATUS,
  ISO_DATE_FORMAT,
} from '../../../constants'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'

const { RangePicker } = DatePicker
const { Option } = Select

const AdvanceFilterBlock = props => {
  const { electronicDocumentStore } = props

  const { queryParamsSignDocument } = electronicDocumentStore

  const location = useLocation()
  const history = useHistory()
  const queryStringParse = queryString.parse(location.search)

  const onSearchTitle = title => {
    electronicDocumentStore.setQueryParamsSignDocument({
      ...queryParamsSignDocument,
      page: 0,
      title: title && title.trim(),
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        page: 0,
        size: 10,
        title: title ? title.trim() : null,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/internal-document/sign-document?${queryStringStringify}`)
  }
  const onChangeStatus = status => {
    electronicDocumentStore.setQueryParamsSignDocument({
      ...queryParamsSignDocument,
      page: 0,
      status: status,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        page: 0,
        size: 10,
        status: status,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/internal-document/sign-document?${queryStringStringify}`)
  }

  const onChangeDate = value => {
    electronicDocumentStore.setQueryParamsSignDocument({
      ...queryParamsSignDocument,
      page: 0,
      from_date: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
      to_date: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        page: 0,
        size: 10,
        from_date: value ? moment(value[0]).format(ISO_DATE_FORMAT) : null,
        to_date: value ? moment(value[1]).format(ISO_DATE_FORMAT) : null,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/internal-document/sign-document?${queryStringStringify}`)
  }

  return (
    <div style={{ marginBottom: 16 }}>
      <Row type={'flex'} gutter={15}>
        <Col xs={24} sm={24} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Tiêu đề</span>
            <Input.Search
              onSearch={value => onSearchTitle(value)}
              placeholder={'Nhập trích yếu văn bản'}
              allowClear
              defaultValue={queryStringParse.title}
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Trạng thái</span>
            <Select
              style={{ width: '100%' }}
              allowClear
              onChange={onChangeStatus}
              defaultValue={queryStringParse.status}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              notFoundContent={<EmptyContent />}
              optionFilterProp={'name'}
              placeholder={'Chọn trạng thái'}>
              <Option
                key={ELECTRONIC_DOCUMENT_STATUS.PENDING}
                value={ELECTRONIC_DOCUMENT_STATUS.PENDING}
                name={ELECTRONIC_DOCUMENT_STATUS.PENDING}>
                Chưa xử lý
              </Option>
              <Option
                key={ELECTRONIC_DOCUMENT_STATUS.VT_APPROVAL}
                value={ELECTRONIC_DOCUMENT_STATUS.VT_APPROVAL}
                name={ELECTRONIC_DOCUMENT_STATUS.VT_APPROVAL}>
                Văn thư duyệt
              </Option>
              <Option
                key={ELECTRONIC_DOCUMENT_STATUS.LEAD_APPROVAL}
                value={ELECTRONIC_DOCUMENT_STATUS.LEAD_APPROVAL}
                name={ELECTRONIC_DOCUMENT_STATUS.LEAD_APPROVAL}>
                Lãnh đạo duyệt
              </Option>
              <Option
                key={ELECTRONIC_DOCUMENT_STATUS.REGIS_NUMBER}
                value={ELECTRONIC_DOCUMENT_STATUS.REGIS_NUMBER}
                name={ELECTRONIC_DOCUMENT_STATUS.REGIS_NUMBER}>
                Cấp số
              </Option>
              <Option
                key={ELECTRONIC_DOCUMENT_STATUS.RELEASE}
                value={ELECTRONIC_DOCUMENT_STATUS.RELEASE}
                name={ELECTRONIC_DOCUMENT_STATUS.RELEASE}>
                Phát hành
              </Option>
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Ngày trình</span>
            <RangePicker
              onChange={onChangeDate}
              defaultValue={
                queryStringParse.from_date && queryStringParse.to_date
                  ? [
                      moment(queryStringParse.from_date),
                      moment(queryStringParse.to_date),
                    ]
                  : null
              }
              style={{ width: '100%' }}
              placeholder={['Từ ngày', 'Đến ngày']}
              format={[DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT]}
            />
          </Space>
        </Col>
      </Row>
    </div>
  )
}

AdvanceFilterBlock.propTypes = {}

export default inject('electronicDocumentStore')(observer(AdvanceFilterBlock))
