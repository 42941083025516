import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Empty,
  message,
  Modal,
  Col,
  Input,
  Row,
  Select,
  DatePicker,
  Pagination,
  Tooltip
} from 'antd'
import { inject, observer } from 'mobx-react'
import TableComponent from '../../components/Common/TableComponent'
import {
  QuestionCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { YYYY_MM_DD, DATE_FORMAT_DEFAULT, TYPE_STATUS } from '../../constants'
import moment from 'moment'
import { TableBottomPaginationBlock } from '../../components/Common/Table'
import ShoppingCouponUpdate from './ShoppingCouponUpdate'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { apiUrl } from '../../config'
import { AvatarHasName } from '../../components/Common/Avatar'
import { StatusTag } from '../../components/Common/StatusTag'
import { SHOPPING_STATUS, SHOPPING_USER_TYPE } from '../../constants'
import { debounce } from 'lodash'
const { Option } = Select

const { confirm } = Modal

const ShoppingCouponTable = props => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false)
  const [shoppingData, setShoppingData] = useState([])
  const [userDetail, setUserDetail] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const [titleFilter, setTitleFilter] = useState('')
  const [createdDateFilter, setCreatedDateFilter] = useState(null)
  const [allUsers, setAllUsers] = useState([])
  const [allUserr, setAllUserr] = useState([])
  const [type, setType] = useState(null)
  const [createUser, setCreateUser] = useState('')
  const [userType, setUserType] = useState(null)
  const {
    selectPeopleStore,
    authenticationStore,
    ShoppingCouponStore,
    userStore,
    loadingAnimationStore,
    refreshData,
  } = props
  const { originDepartmentWithUserList, companyCode } = selectPeopleStore
  const { getAllShoppingCoupon, getAllShoppingCouponV2 } = ShoppingCouponStore
  const fetchShoppingCoupons = useCallback(async () => {
    setIsLoading(true)
    const payload = {
      page: currentPage,
      size: pageSize,
      title: titleFilter,
      createdDate: createdDateFilter
        ? moment(createdDateFilter, 'DD/MM/YYYY').format('DD/MM/YYYY')
        : null,
      type,
      userType,
      createUser,
    }
    try {
      const response = await getAllShoppingCouponV2(payload)
      const total = response.data.totalElements
      const maxPage = Math.ceil(total / pageSize)

      if (currentPage > maxPage) {
        setCurrentPage(0)
      }
      setShoppingData(response.data.content)
      setTotalCount(total)
    } catch (error) {
      message.error('Đã có lỗi xảy ra')
    } finally {
      setIsLoading(false)
    }
  }, [
    currentPage,
    pageSize,
    titleFilter,
    createdDateFilter,
    type,
    userType,
    createUser,
    getAllShoppingCouponV2,
    refreshData,
  ])

  // useEffect(() => {
  //   fetchShoppingCoupons()
  // }, [fetchShoppingCoupons])

  useEffect(() => {
    loadingAnimationStore.setTableLoading(true)
    const fetchUserLists = async () => {
      try {
        const [userList1, userList2] = await Promise.all([
          userStore.getMentionUserList(true),
          userStore.getMentionUserList(),
        ])

        const formattedUsers1 = userList1.data.userDetails.map(user => ({
          id: user.userCode,
          fullName: user.fullName,
          username: user.user_name,
          image: user.image,
        }))

        const formattedUsers2 = userList2.data.userDetails.map(user => ({
          id: user.userCode,
          fullName: user.fullName,
          username: user.user_name,
          image: user.image,
        }))

        setAllUsers(formattedUsers1)
        setAllUserr(formattedUsers2)
      } finally {
        loadingAnimationStore.setTableLoading(false)
      }
    }

    fetchUserLists()

    return () => userStore.clearStore()
  }, [userStore])

  const finalUserData = [...allUsers, ...allUserr]

  shoppingData?.forEach(record => {
    const match = finalUserData.find(
      item => item.username === record.createdUser
    )
    if (match) {
      record.image = match.image
      record.fullName = match.fullName
    }
  })

  const handleOpenUpdateModal = id => {
    setIsVisibleUpdateModal(true)
    setSelectedTemplateId(id)
  }

  const handleCancelUpdateModal = () => {
    setIsVisibleUpdateModal(false)
  }

  const handleDelete = async code => {}

  const showConfirm = (e, code) => {
    e.stopPropagation()
    setIsVisibleUpdateModal(false)
    confirm({
      title: 'Bạn có chắc chắn muốn xóa Tài sản này ?',
      icon: (
        <QuestionCircleOutlined
          style={{
            color: 'red',
          }}
        />
      ),
      okText: 'Xóa',
      cancelText: 'Không',
      okType: 'danger',
      onOk: async () => {
        await handleDelete(code)
      },
    })
  }
  const debouncedCreateUserChange = useCallback(
    debounce(value => {
      setCurrentPage(0)
      setCreateUser(value)
    }, 300),
    []
  )

  const handleCreateUserChange = e => {
    debouncedCreateUserChange(e.target.value)
    setCurrentPage(0)
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    history.push({ search: queryParams.toString() })
  }

  const handleSelectTypeChange = value => {
    setCurrentPage(0)
    setType(value)
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    history.push({ search: queryParams.toString() })
  }

  const handleDateChange = date => {
    setCurrentPage(0)
    setCreatedDateFilter(date)
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    history.push({ search: queryParams.toString() })
  }

  const debouncedTitleChange = useCallback(
    debounce(value => {
      setCurrentPage(0)
      setTitleFilter(value)
    }, 300),
    []
  )

  const handleTitleChange = e => {
    debouncedTitleChange(e.target.value)
    setCurrentPage(0)
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    history.push({ search: queryParams.toString() })
  }

  const handleSelectUserTypeChange = value => {
    setCurrentPage(0)
    setUserType(value)
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    history.push({ search: queryParams.toString() })
  }

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page - 1)
    setPageSize(pageSize)

    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', page)
    history.push({ search: queryParams.toString() })
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const page = queryParams.get('page') ? Number(queryParams.get('page')) : 1

    if (currentPage !== page - 1) {
      setCurrentPage(page - 1)
    } else {
      fetchShoppingCoupons()
    }
  }, [location.search, currentPage, fetchShoppingCoupons])

  const tableColumns = [
    {
      title: 'Tiêu đề ',
      width: 150,
      render: record => (
        <p
          style={{
            marginBottom: '0px',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 'auto',
          }}>
            <div title={record?.title}>
              {record?.title}
            </div>
        </p>
      ),
      onCell: (record, rowIndex) => {
        return {
          onClick: ev => {
            history.push(`/shopping-coupon/other/view/${record.code}`)
          },
        }
      },
    },
    {
      title: 'Nội dung',
      width: 280,
      render: record => (
        <p
          style={{
            marginBottom: '0px',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 'auto',
          }}>
          
          <div dangerouslySetInnerHTML={{ __html: record.content }} />         
        </p>
      ),
      onCell: (record, rowIndex) => {
        return {
          onClick: ev => {
            history.push(`/shopping-coupon/other/view/${record.code}`)
          },
        }
      },
    },
    {
      title: 'Ngày tạo',
      align: 'center',
      width: 50,
      render: record => {
        const date = new Date(record?.createdDate || 0)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        const seconds = String(date.getSeconds()).padStart(2, '0')

        const formattedDate = `${day}/${month}/${year}`
        return (
          <div
            style={{
              marginBottom: '0px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {formattedDate}
          </div>
        )
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: ev => {
            history.push(`/shopping-coupon/other/view/${record.code}`)
          },
        }
      },
    },
    {
      title: () => {
        return (
          <p style={{ marginBottom: '0px'}}>Người tạo</p>
        )
      },
      width: 90,
      // align: 'center',
      render: record => (
        <div
          style={{
            display: 'flex',
            marginBottom: '0px',
            alignItems: 'center',
          }}>
          <AvatarHasName imgId={record?.image} name={record?.fullName} />
        </div>
      ),
      onCell: (record, rowIndex) => {
        return {
          onClick: ev => {
            history.push(`/shopping-coupon/other/view/${record.code}`)
          },
        }
      },
    },
    {
      title: 'Trạng thái',
      width: 70,
      align: 'center',
      render: record => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {StatusTag(TYPE_STATUS.SHOPPING, record.type)}
        </div>
      ),
      onCell: (record, rowIndex) => {
        return {
          onClick: ev => {
            history.push(`/shopping-coupon/other/view/${record.code}`)
          },
        }
      },
    },
  ]

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={6}>
          <Input
            style={{
              width: '100%',
            }}
            showSearch
            placeholder={'Nhập tiêu đề'}
            allowClear
            onChange={handleTitleChange}></Input>
        </Col>
        <Col span={6}>
          <DatePicker
            style={{
              marginRight: '16px',
              minWidth: 200,
            }}
            allowClear
            style={{
              width: '100%',
            }}
            placeholder={'Ngày tạo'}
            format={'DD-MM-YYYY'}
            onChange={handleDateChange}
          />
        </Col>
        <Col span={6}>
          <Input
            style={{
              width: '100%',
            }}
            placeholder={'Nhập người tạo'}
            allowClear
            onChange={handleCreateUserChange}
          />
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={6}>
          <Select
            style={{
              width: '100%',
            }}
            showSearch
            placeholder={'Trạng thái'}
            filterOption={true}
            onChange={handleSelectTypeChange}
            allowClear
            optionFilterProp={'name'}>
            <Option value={SHOPPING_STATUS.CREATED}>Chờ xử lý</Option>
            <Option value={SHOPPING_STATUS.REQUESTED}>Đã duyệt yêu cầu</Option>
            <Option value={SHOPPING_STATUS.APPROVED}>Đã thẩm định</Option>
            <Option value={SHOPPING_STATUS.BOUGHT}>Đã mua</Option>
            <Option value={SHOPPING_STATUS.PAID}>Hoàn thành</Option>
            <Option value={SHOPPING_STATUS.REJECT}>Đã hủy</Option>
          </Select>
        </Col>
        <Col span={6}>
          <Select
            style={{
              width: '100%',
            }}
            showSearch
            placeholder={'Loại'}
            filterOption={true}
            onChange={handleSelectUserTypeChange}
            allowClear
            optionFilterProp={'name'}>
            <Option value={SHOPPING_USER_TYPE.CREATED}>Đã tạo</Option>
            <Option value={SHOPPING_USER_TYPE.PROCESS}>Xử lý</Option>
            <Option value={SHOPPING_USER_TYPE.FOLLOW}>Theo dõi</Option>
          </Select>
        </Col>
      </Row>
      <TableComponent
        rowKey={record => record.code}
        dataSource={shoppingData}
        columns={tableColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={isLoading}
      />
      <TableBottomPaginationBlock>
        <Pagination
          current={currentPage + 1}
          pageSize={pageSize}
          total={totalCount}
          showSizeChanger={false}
          onChange={handlePaginationChange}
        />
      </TableBottomPaginationBlock>
      <Modal
        width={700}
        title={'Chỉnh sửa'}
        visible={isVisibleUpdateModal}
        maskClosable={false}
        onCancel={handleCancelUpdateModal}
        footer={null}
        destroyOnClose>
        {isVisibleUpdateModal && (
          <ShoppingCouponUpdate
            onCancel={handleCancelUpdateModal}
            templateId={selectedTemplateId}
          />
        )}
      </Modal>
    </>
  )
}

export default inject(
  'authenticationStore',
  'selectPeopleStore',
  'ShoppingCouponStore',
  'userStore',
  'loadingAnimationStore'
)(observer(ShoppingCouponTable))
