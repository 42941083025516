import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { IoIosSend } from 'react-icons/io';
import { IoChatbubbles } from 'react-icons/io5';
import { FaRegUser } from 'react-icons/fa';
import chatBotStore from '../../stores/chatBotStore';
const ChatBot = observer(() => {
  const [userMessage, setUserMessage] = useState("");
  const [isChatbotVisible, setIsChatbotVisible] = useState(false); // Add state for visibility
  const chatInputRef = useRef(null);
  const chatboxRef = useRef(null);
  const inputInitHeight = useRef(10);

  useEffect(() => {
    inputInitHeight.current = chatInputRef?.current?.scrollHeight;
  }, []);

  useEffect(() => {
    chatboxRef.current.scrollTo({
      top: chatboxRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [chatBotStore.messages.length]);

  const handleChat = async () => {
    if (!userMessage.trim()) return;

    setUserMessage("");
    chatInputRef.current.style.height = `${inputInitHeight.current}px`;
    chatBotStore.addMessage(userMessage, "outgoing");

    chatBotStore.addMessage("Xin chờ...", "incoming");
    chatboxRef.current.scrollTo({
      top: chatboxRef.current.scrollHeight,
      behavior: "smooth",
    });

    await chatBotStore.handleChat(userMessage);

    chatInputRef.current.style.height = `${inputInitHeight.current}px`;
    chatboxRef.current.scrollTo({
      top: chatboxRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleInput = (e) => {
    setUserMessage(e.target.value);
    chatInputRef.current.style.height = `${inputInitHeight.current}px`;
    chatInputRef.current.style.height = `${e.target.scrollHeight}px`;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey && window.innerWidth > 800) {
      e.preventDefault();
      handleChat();
    }
  };

  const toggleChatbotVisibility = () => {
    setIsChatbotVisible(prev => !prev);
  };

  const closeChatbot = () => {
    setIsChatbotVisible(false);
  };

  return (
    <div>
      <button
        style={{
          position: 'fixed',
          bottom: '30px',
          right: '35px',
          outline: 'none',
          border: 'none',
          height: '50px',
          width: '50px',
          display: 'flex',
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          background: '#2c65ac',
          transition: 'all 0.2s ease'
        }}
        onClick={toggleChatbotVisibility}
      >
        <span style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white'
        }}><IoChatbubbles size={20} /></span>
      </button>
      <div style={{
        position: 'fixed',
        right: '35px',
        bottom: '90px',
        width: '420px',
        background: '#fff',
        borderRadius: '15px',
        overflow: 'hidden',
        opacity: isChatbotVisible ? '1' : '0',
        pointerEvents: isChatbotVisible ? 'auto' : 'none',
        transform: isChatbotVisible ? 'scale(1)' : 'scale(0.5)',
        transformOrigin: 'bottom right',
        boxShadow: '0 0 128px 0 rgba(0,0,0,0.1), 0 32px 64px -48px rgba(0,0,0,0.5)',
        transition: 'all 0.1s ease'
      }}>
        <header style={{
          padding: '16px 0',
          position: 'relative',
          textAlign: 'center',
          color: '#fff',
          background: '#2c65ac',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <h2 style={{ color: 'white', position: 'relative' }}>Chatbot</h2>
          <span style={{
            position: 'absolute',
            right: '15px',
            top: '50%',
            display: 'block',
            cursor: 'pointer',
            transform: 'translateY(-50%)'
          }}
            onClick={closeChatbot}
          >
          </span>
        </header>
        <ul ref={chatboxRef} style={{
          overflowY: 'auto',
          height: '450px',
          padding: '30px 20px 10px'
        }}>
          <li style={{
            display: 'flex',
            listStyle: 'none',
            wordBreak: 'break-word',
            marginBottom: '10px'
          }}>
            <FaRegUser style={{
              marginRight: '10px',
              background: '#2c65ac',
              color: '#fff',
              borderRadius: '50%',
              padding: '5px',
              fontSize: '30px'
            }} />
            <p style={{
              whiteSpace: 'pre-wrap',
              padding: '12px 16px',
              borderRadius: '10px 10px 0 10px',
              maxWidth: '75%',
              color: 'ffff',
              fontSize: '0.95rem',
              background: '#f2f2f2'
            }}>
              Xin chào 👋<br />Tôi có thể giúp gì cho bạn?
            </p>
          </li>
          {chatBotStore.messages.map((msg, index) => (
            <li key={index} style={{
              display: 'flex',
              listStyle: 'none',
              wordBreak: 'break-word',
              margin: msg.className === 'outgoing' ? '20px 0' : '10px 0',
              justifyContent: msg.className === 'outgoing' ? 'flex-end' : 'flex-start'
            }}>
              {msg.className === "incoming" ? <FaRegUser style={{
                marginRight: '10px',
                background: '#2c65ac',
                color: '#fff',
                borderRadius: '50%',
                padding: '5px',
                fontSize: '30px'
              }} /> : null}
              <p style={{
                whiteSpace: 'pre-wrap',
                padding: '12px 16px',
                borderRadius: msg.className === 'incoming' ? '10px 10px 10px 0' : '10px 10px 0 10px',
                maxWidth: '75%',
                color: msg.className === 'incoming' ? '#000' : '#fff',
                fontSize: '0.95rem',
                background: msg.className === 'incoming' ? '#f2f2f2' : '#2c65ac',
                ...(msg.error ? { color: '#721c24', background: '#f8d7da' } : {})
              }}>
                {msg.message}
              </p>
            </li>
          ))}
        </ul>
        <div style={{
          display: 'flex',
          gap: '5px',
          position: 'relative',
          bottom: '0',
          width: '100%',
          background: '#fff',
          padding: '3px 20px',
          borderTop: '1px solid #ddd'
        }}>
          <textarea
            placeholder="Nhập câu hỏi..."
            spellCheck="false"
            required
            value={userMessage}
            onChange={handleInput}
            onKeyDown={handleKeyDown}
            ref={chatInputRef}
            style={{
              height: '55px',
              width: '100%',
              border: 'none',
              outline: 'none',
              resize: 'none',
              maxHeight: '180px',
              padding: '15px 15px 15px 0',
              fontSize: '0.95rem'
            }}
          />
          <span style={{
            alignSelf: 'flex-end',
            color: '#2c65ac',
            cursor: 'pointer',
            height: '55px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.35rem'
          }}
            onClick={handleChat}
          >
            <IoIosSend size={30} />
          </span>
        </div>
      </div>
    </div>
  );
});

export default ChatBot;
