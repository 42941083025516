import { Button, Checkbox, Empty, Popconfirm, Select, Tag, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { ModalBody, ModalWrapper } from './SelectPeopleRiskPopupStyled'
// import SelectUserDepartmentBox from './SelectUserDepartmentBox'
import {
  CaretDownOutlined,
  CaretRightOutlined,
  CloseOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { yellowPrimary } from '../../color'
import { AvatarHasName } from '../Common/Avatar'
import SpinnerInlineComponent from '../SpinnerInlineComponent/SpinnerInlineComponent'

const { Option } = Select

const SelectPeoplePopup = props => {
  const {
    id,
    isVisibleSelectPeopleRiskPopup,
    setIsVisibleSelectPeopleRiskPopup,
    selectPeopleStore,
    disableSelectDepartment,
    okText,
    cancelText,
    handleUpdateEmpty = () => {},
    setCheckSelectOne,
    checkSelectOne,
    riskStore = null,
    riskUnitUserStore = null,
    updateTierApproval,
    type,
    onConfirm,
    confirm,
    setListDepartment,
    selectedListAndType,
    setSelectedListAndType,
  } = props
  const {
    assigneePopupType,
    selectUserData,
    setSelectUserData,
    selectDepartmentData,
    setSelectDepartmentData,
  } = selectPeopleStore

  const [selectedList, setSelectedList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [prevUserList, setPrevUserList] = useState([])
  const [prevDepartmentList, setPrevDepartmentList] = useState([])
  const [maxWidth, setMaxWidth] = useState(0)
  const containerRef = useRef(null)

  let tier1RiskExecutorSelectOptions = []
  if (Array.isArray(riskStore.riskUnitsV2)) {
    tier1RiskExecutorSelectOptions = riskStore.riskUnitsV2.map(item => ({
      title: item.name,
      value: item.code,
      key: item.code,
      children: item.unit_users.map(user => ({
        title: user.full_name,
        value: user.username,
        key: user.username,
        position_name: user.position_name,
      })),
    }))
  }

  const handleRemoveSelect = removedItem => {
    setSelectedList(prevSelectedList =>
      prevSelectedList.filter(item => item.key !== removedItem.key)
    )
    const resultItemUser = selectedListAndType[0].userList?.filter(
      itemSelected => itemSelected.key !== removedItem.key
    )
    if (resultItemUser?.length === 0) {
      setSelectedListAndType([])
    }
  }

  const [visibleItems, setVisibleItems] = useState([])
  const toggleVisible = index => {
    const newVisibleItems = [...visibleItems]
    newVisibleItems[index] = !newVisibleItems[index]
    setVisibleItems(newVisibleItems)
  }
  useEffect(() => {
    setVisibleItems([undefined])
  }, [confirm])

  const handleCheckboxClick = itemChild => {
    setSelectedList(prevSelectedList => {
      const isItemInList = prevSelectedList.some(
        selectedItem => selectedItem.key === itemChild.key
      )

      if (isItemInList) {
        // Item is already in the list, remove it
        return prevSelectedList.filter(
          selectedItem => selectedItem.key !== itemChild.key
        )
      } else {
        // Item is not in the list, add it
        return [...prevSelectedList, itemChild]
      }
    })
  }

  let updateSelectedExecutors
  let selectedTier1RiskExecutors

  switch (confirm) {
    case 'Tier1RiskExecutor':
      updateSelectedExecutors = () => {
        const listUserCodes = selectedList?.map(item => item.value)
        riskUnitUserStore.setSelectedTier1RiskExecutors(listUserCodes)
        setIsVisibleSelectPeopleRiskPopup(false)
        if (
          Array.isArray(listUserCodes) &&
          listUserCodes.length > 0 &&
          onConfirm &&
          updateTierApproval
        ) {
          onConfirm()
        }
      }

      selectedTier1RiskExecutors = riskUnitUserStore.selectedRiskExecutors.tier1.map(
        item => ({
          key: item.username,
          value: item.username,
          title: item.full_name,
          position_name: item.position_name,
        })
      )

      break
    case 'Tier1NDDPVgeneral':
      updateSelectedExecutors = () => {
        const listUserCodes = selectedList?.map(item => item.value)
        riskUnitUserStore.setSelectedTier1NDDPVgeneral(listUserCodes)
        setIsVisibleSelectPeopleRiskPopup(false)
        if (listUserCodes.length > 0 && onConfirm && updateTierApproval) {
          onConfirm()
        }
      }

      selectedTier1RiskExecutors = riskUnitUserStore.selectedTier1NDDPVgeneral.tier1.map(
        item => ({
          key: item.username,
          value: item.username,
          title: item.full_name,
          position_name: item.position_name,
        })
      )
      break
    case 'Tier2RiskExecutor':
      updateSelectedExecutors = () => {
        const listUserCodes = selectedList?.map(item => item.value)
        riskUnitUserStore.setSelectedTier2RiskExecutors(listUserCodes)
        setIsVisibleSelectPeopleRiskPopup(false)
        if (listUserCodes.length > 0 && onConfirm && updateTierApproval) {
          onConfirm()
        }
      }

      selectedTier1RiskExecutors = riskUnitUserStore.selectedRiskExecutors.tier2.map(
        item => ({
          key: item.username,
          value: item.username,
          title: item.full_name,
          position_name: item.position_name,
        })
      )
      break
    case 'NDDPVgeneral':
      updateSelectedExecutors = () => {
        const listUserCodes = selectedList?.map(item => item.value)
        riskUnitUserStore.setSelectedTier2NDDPVgeneral(listUserCodes)
        setIsVisibleSelectPeopleRiskPopup(false)
        if (listUserCodes.length > 0 && onConfirm && updateTierApproval) {
          onConfirm()
        }
      }

      selectedTier1RiskExecutors = riskUnitUserStore.selectedNDDPVgeneral.tier2.map(
        item => ({
          key: item.username,
          value: item.username,
          title: item.full_name,
          position_name: item.position_name,
        })
      )
      break
    case 'Tier3RiskExecutor':
      updateSelectedExecutors = () => {
        const listUserCodes = selectedList?.map(item => item.value)
        riskUnitUserStore.setSelectedTier3RiskExecutors(listUserCodes, type)
        setIsVisibleSelectPeopleRiskPopup(false)
        if (listUserCodes.length > 0 && onConfirm && updateTierApproval) {
          onConfirm()
        }
      }

      selectedTier1RiskExecutors = riskUnitUserStore.selectedRiskExecutors.tier3.map(
        item => ({
          key: item.username,
          value: item.username,
          title: item.full_name,
          position_name: item.position_name,
        })
      )
      break
    case 'Tier1UnitLeader':
      updateSelectedExecutors = () => {
        const listUserCodes = selectedList?.map(item => item.value)
        riskUnitUserStore.setSelectedTier1UnitLeaders(listUserCodes)
        setIsVisibleSelectPeopleRiskPopup(false)
        if (listUserCodes.length > 0 && onConfirm && updateTierApproval) {
          onConfirm()
        }
      }

      selectedTier1RiskExecutors = riskUnitUserStore.selectedUnitLeaders.tier1.map(
        item => ({
          key: item.username,
          value: item.username,
          title: item.full_name,
          position_name: item.position_name,
        })
      )
      break
    case 'Tier2DVPTprofessional':
      updateSelectedExecutors = () => {
        const listUserCodes = selectedList?.map(item => item.value)
        riskUnitUserStore.setSelectedTier2DVPTprofessional(listUserCodes)
        setIsVisibleSelectPeopleRiskPopup(false)
        if (listUserCodes.length > 0 && onConfirm && updateTierApproval) {
          onConfirm()
        }
        const listDepartment = listUserCodes?.map(item =>
          riskStore.riskUnitsV2?.find(el =>
            el.unit_users.find(elss => elss.username === item)
          )
        )
        setListDepartment(() => listDepartment)
      }

      selectedTier1RiskExecutors = riskUnitUserStore.selectedDVPTprofessional.tier2.map(
        item => ({
          key: item.username,
          value: item.username,
          title: item.full_name,
          position_name: item.position_name,
        })
      )
      break
    default:
      // Hành động mặc định khi không có trường hợp nào khớp
      break
  }

  useEffect(() => {
    setSelectedList(selectedTier1RiskExecutors)
  }, [confirm])
  // const [selected, setSelected] = useState([])
  useEffect(() => {
    if (selectedList?.length > 0) {
      let updatedSelected = [...selectedListAndType]

      if (confirm === 'Tier1RiskExecutor') {
        updatedSelected.push({
          type: 'Tier1RiskExecutor',
          userList: selectedList,
        })
      }
      if (confirm === 'Tier1NDDPVgeneral') {
        updatedSelected.push({
          type: 'Tier1NDDPVgeneral',
          userList: selectedList,
        })
      }
      if (confirm === 'Tier2RiskExecutor') {
        updatedSelected.push({
          type: 'Tier2RiskExecutor',
          userList: selectedList,
        })
      }
      if (confirm === 'NDDPVgeneral') {
        updatedSelected.push({
          type: 'NDDPVgeneral',
          userList: selectedList,
        })
      }
      if (confirm === 'Tier3RiskExecutor') {
        updatedSelected.push({
          type: 'Tier3RiskExecutor',
          userList: selectedList,
        })
      }
      if (confirm === 'Tier1UnitLeader') {
        updatedSelected.push({
          type: 'Tier1UnitLeader',
          userList: selectedList,
        })
      }
      if (confirm === 'Tier2DVPTprofessional') {
        updatedSelected.push({
          type: 'Tier2DVPTprofessional',
          userList: selectedList,
        })
      }
      const filterUniqueItems = array => {
        let typeMap = {}
        // lấy item trùng cuối cùng
        for (let i = array.length - 1; i >= 0; i--) {
          const item = array[i]
          if (!typeMap[item.type]) {
            typeMap[item.type] = true
          } else {
            array.splice(i, 1)
          }
        }

        return array
      }

      updatedSelected = filterUniqueItems(updatedSelected)

      setSelectedListAndType(updatedSelected)
    }
  }, [selectedList])

  const handleClear = () => {
    setSelectedList([])
    setSelectedListAndType([])
  }

  useEffect(() => {
    if (containerRef.current) {
      const tags = containerRef.current.getElementsByClassName('tag-selected')

      let maxWidth = 0
      Array.from(tags).forEach(tag => {
        const tagWidth = tag.offsetWidth
        maxWidth = Math.max(maxWidth, tagWidth)
      })

      setMaxWidth(Math.max(maxWidth, 250))
    }
  }, [selectedList])

  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    margin: `-${15}px -${10}px ${10}px -${10}px`,
    padding: 15,
    borderBottom: '1px solid #f0f0f0',
    fontWeight: 500,
    backgroundColor: '#e9f7ff',
    width:
      selectedList?.length > 0
        ? maxWidth === 250
          ? maxWidth - 1
          : maxWidth + 15
        : 'auto',
  }

  return (
    <ModalWrapper
      maskClosable={false}
      okText={okText || 'Đồng ý'}
      cancelText={'Hủy bỏ'}
      width={800}
      style={{ top: 20 }}
      zIndex={1000}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 8,
              alignItems: 'center',
            }}>
            {disableSelectDepartment || cancelText ? (
              <Popconfirm
                placement="top"
                title={'Bạn có chắc chắn muốn xóa hết người được chọn không ?'}
                onConfirm={() => handleClear()}
                okText="Đồng ý"
                cancelText="Không"
                zIndex={1600}>
                <Button
                  danger
                  icon={<WarningOutlined style={{ color: yellowPrimary }} />}>
                  {cancelText || 'Hủy chọn'}
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                zIndex={2000}
                placement="top"
                title={'Bạn có chắc chắn muốn xóa hết người được chọn không ?'}
                onConfirm={() => alert('hello')}
                okText="Đồng ý"
                cancelText="Không">
                <Button
                  danger
                  icon={<WarningOutlined style={{ color: yellowPrimary }} />}>
                  {cancelText || 'Hủy chọn'}
                </Button>
              </Popconfirm>
            )}
            <Button type={'primary'} onClick={() => updateSelectedExecutors()}>
              {okText || 'Đồng ý'}
            </Button>
            {!cancelText && (
              <div>
                <Tooltip title={'Đóng'}>
                  <CloseOutlined
                    onClick={() => {
                      if (checkSelectOne) {
                        setCheckSelectOne(false)
                      }
                      setSelectUserData({
                        ...selectUserData,
                        [assigneePopupType]: prevUserList,
                      })
                      setSelectDepartmentData({
                        ...selectDepartmentData,
                        [assigneePopupType]: prevDepartmentList,
                      })
                      setIsVisibleSelectPeopleRiskPopup(false)
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      }
      closable={false}
      forceRender={true}
      footer={null}
      visible={isVisibleSelectPeopleRiskPopup}>
      <ModalBody id={id}>
        <div
          style={{
            display: 'flex',
            gap: 4,
            paddingTop: 10,
          }}>
          <div style={{ flexGrow: 1 }}>
            <div
              className={'modal-body'}
              style={{
                position: 'relative',
                height: '85vh',
                overflowY: 'auto',
              }}>
              {tier1RiskExecutorSelectOptions?.map((item, index) => (
                <div
                  key={item.key}
                  style={{
                    width: '100%',
                  }}>
                  <Button
                    key={item.key}
                    style={{
                      width: '100%',
                      height: '44px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                    className="ant-dropdown-link"
                    onClick={() => toggleVisible(index)}>
                    {visibleItems[index] ? (
                      <CaretDownOutlined />
                    ) : (
                      <CaretRightOutlined />
                    )}

                    {item.title}
                  </Button>
                  {visibleItems[index] && (
                    <div style={{ border: '1px solid #ccc' }}>
                      {item.children.map(itemChild => {
                        const checked = selectedList.some(
                          selectedItem => selectedItem.key === itemChild.key
                        )
                        return (
                          <Checkbox
                            style={{
                              margin: '15px 0 15px 40px',
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                            key={itemChild.key}
                            checked={checked}
                            onClick={() => handleCheckboxClick(itemChild)}>
                            <div
                              style={{ marginLeft: '5px', marginTop: '5px' }}>
                              <AvatarHasName
                                name={itemChild.title}
                                position={itemChild.position_name}
                              />
                            </div>
                          </Checkbox>
                        )
                      })}
                    </div>
                  )}
                </div>
              ))}
              <SpinnerInlineComponent
                sizeSpin={'small'}
                isLoading={isLoading}
                noBackground
              />
            </div>
          </div>
          <div
            className={'selected-list'}
            style={{
              flex: '0 0 250px',
              maxHeight: 'calc(100vh - 111px)',
              overflowY: 'auto',
              maxWidth: 250,
              backgroundColor: '#fff',
              margin: '-10px 0 -15px 0',
              borderLeft: '1px solid #f0f0f0',
              padding: '15px 10px',
            }}>
            <div
              // @ts-ignore
              style={containerStyle}>
              <span>Danh sách đã chọn:</span>
            </div>
            <div
              ref={containerRef}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 8,
              }}>
              {Array.isArray(selectedList) && selectedList?.length > 0 ? (
                selectedList?.map(itemSelected => {
                  return (
                    <Tag
                      className={'tag-selected'}
                      color={'blue'}
                      key={itemSelected.key}
                      closable
                      onClose={() => handleRemoveSelect(itemSelected)}>
                      <AvatarHasName
                        name={itemSelected.title}
                        position={itemSelected.position_name}
                      />
                    </Tag>
                  )
                })
              ) : (
                <Empty
                  style={{ width: '100%' }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </div>
          </div>
        </div>
      </ModalBody>
    </ModalWrapper>
  )
}

SelectPeoplePopup.propTypes = {}

export default inject(
  'selectPeopleStore',
  'companyStore',
  'authenticationStore',
  'riskStore',
  'riskUnitUserStore'
)(observer(SelectPeoplePopup))
