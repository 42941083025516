export const fetchResponse = (message) => {
  const API_Chat=process.env.REACT_APP_URL_CHATBOT+"/api/v1/ask"
  return fetch(API_Chat, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ question: message }),
    mode: "cors",
  }).then((res) => res.json());
};
