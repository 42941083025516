import { action, observable } from 'mobx'
import { fetchResponse } from '../requests/ChatRequest'
import { response } from 'msw'
class chatBotStore {
  @observable messages = [];

  @action addMessage(message, className) {
    this.messages.push({ message, className });
  }
  @action updateLastMessage(newMessage) {
    if (this.messages.length > 0) {
      this.messages[this.messages.length - 1].message = newMessage;
    }
  }

  @action addErrorMessage() {
    if (this.messages.length > 0) {
      this.messages[this.messages.length - 1].message = "Vui lòng thử lại";
      this.messages[this.messages.length - 1].error = true;
    }
  }

  @action async handleChat(message) {
  
    try {
      const data = await fetchResponse(message);
      this.updateLastMessage(data.answer);
    } catch {
      this.addErrorMessage();
    }
  }
}
export default new chatBotStore();