import React, { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { Button, DatePicker, Empty, message, Typography } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import utils from '../../utils'
import date_utils from '../../date_utils'
import { EmptyText } from '../../components/Common/CellText'
import {
  ACL_ACTION_TYPE,
  DATE_FORMAT_DEFAULT,
  ISO_DATE_FORMAT,
} from '../../constants'
import {
  BodyTableLeft,
  BodyTableRight,
  BodyTableWrapper,
  HeaderTableWrapper,
  ItemTableRight,
} from './CompanyWorkScheduleListPageStyled'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import AccessControlAction from '../../components/AccessControlAction'
import { unset } from 'lodash'
import { Menu } from 'antd'
import { systemName } from '../../config'
import { toJS } from 'mobx'
import FileItem from '../../components/Common/FileItemComponent/FileItem'

const renderTime = value => {
  return `${String(moment(value).hour()).padStart(2, '0')}h${String(
    moment(value).minute()
  ).padStart(2, '0')}`
}
const renderDate = value => {
  return `${String(moment(value).date()).padStart(2, '0')}/${String(
    moment(value).month() + 1
  ).padStart(2, '0')}`
}

const CompanyWorkScheduleListPage = props => {
  const {
    history,
    workScheduleStore,
    authenticationStore,
    companyStore,
  } = props
  const { isPublic } = props
  const [workScheduleList, setWorkScheduledList] = useState([])
  const { currentUser } = authenticationStore
  const { companyList } = companyStore

  const permissionViewList = toJS(workScheduleStore.workSchedulePermissionView)
  const [days, setDays] = useState(date_utils.weekRange(date_utils.current()))

  const tableData = workScheduleList?.map(item => {
    return {
      ...item,
      weekDay: moment(item.start_at).isoWeekday(),
    }
  })
  const convertTableData = [
    {
      weekday: 1,
      workSchedule: [],
    },
    {
      weekday: 2,
      workSchedule: [],
    },
    {
      weekday: 3,
      workSchedule: [],
    },
    {
      weekday: 4,
      workSchedule: [],
    },
    {
      weekday: 5,
      workSchedule: [],
    },
    {
      weekday: 6,
      workSchedule: [],
    },
    {
      weekday: 7,
      workSchedule: [],
    },
  ]

  tableData.forEach(item => {
    convertTableData[item.weekDay - 1].workSchedule.push(item)
  })

  const handleChangeWeek = useCallback(
    date => {
      setDays(date_utils.weekRange(date))
    },
    [days]
  )
  let sum = convertTableData.reduce(function (previousValue, currentValue) {
    return previousValue + currentValue.workSchedule.length
  }, 0)

  const [isLoadingWorkSchedule, setIsLoadingWorkSchedule] = useState(false)
  //Mặc định phân quyền phải có current User's company
  const [selectedKey, setSelectedKey] = useState(currentUser?.company.code)

  useEffect(() => {
    workScheduleStore.getWorkSchedulePermissionView()
  }, [])

  useEffect(() => {
    ; (async () => {
      try {
        setIsLoadingWorkSchedule(true)
        const data = isPublic
          ? await workScheduleStore.getPublicWorkSchedule(
            moment(days[0]).format(ISO_DATE_FORMAT),
            moment(days[6]).format(ISO_DATE_FORMAT)
          )
          : await workScheduleStore.getWorkSchedule(
            moment(days[0]).format(ISO_DATE_FORMAT),
            moment(days[6]).format(ISO_DATE_FORMAT),
            true,
            selectedKey
          )

        setWorkScheduledList(data)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoadingWorkSchedule(false)
      }
    })()
    return () => {
      workScheduleStore.clearWorkSchedule()
    }
  }, [days, selectedKey])

  const headerTable = (
    <HeaderTableWrapper>
      <span className={'date'}>Ngày tháng</span>
      <span className={'element'}>Nội dung công việc</span>
      <span className={'element'}>Chuẩn bị</span>
      <span className={'element'}>Thành viên tham gia</span>
      <span className={'element'}>Địa điểm</span>
      <span className={'element'}>Chủ trì</span>
    </HeaderTableWrapper>
  )

  const checkIsTodayWorkSchedule = date => {
    return (
      moment(date).format(DATE_FORMAT_DEFAULT) ===
      moment().format(DATE_FORMAT_DEFAULT)
    )
  }

  const changeCategory = e => {
    setSelectedKey(e.key)
  }

  const renderMenuItems = () => {
    let currentUserCompanyMenuItem = null
    const menuItems = companyList.map(company => {
      const matchingPermission = permissionViewList.find(
        permission => permission.access_type === company.code
      )

      if (matchingPermission) {
        if (
          company.code === currentUser?.company.code &&
          !currentUserCompanyMenuItem
        ) {
          // MenuItem currentUser's company
          currentUserCompanyMenuItem = (
            <Menu.Item key={company.code}>{company.name}</Menu.Item>
          )
        } else {
          // MenuItem another company
          return <Menu.Item key={company.code}>{company.name}</Menu.Item>
        }
      }
      return null
    })

    if (currentUserCompanyMenuItem) {
      // set currentUser's company to the first of list
      menuItems.unshift(currentUserCompanyMenuItem)
    }

    return menuItems
  }

  const getFirstSelectedKey = () => {
    if (currentUser?.company.code) {
      setSelectedKey(currentUser?.company.code)
    }
  }

  useEffect(() => {
    getFirstSelectedKey()
  }, [companyList])
  const isAccessCreate = selectedKey === currentUser?.company.code

  return (
    <DashboardLayout title={'Link People - Link the World'}>
      <Helmet>
        <title>Lịch cơ quan | {systemName} Portal</title>
      </Helmet>
      <PageTitle location={props.location} title={'Lịch cơ quan'} hiddenGoBack>
        <div>
          <DatePicker
            onChange={handleChangeWeek}
            picker="week"
            placeholder={'Chọn tuần'}
            format={'Tuần w, YYYY'}
            defaultValue={moment()}
            style={{ height: 32, width: 200 }}
          />
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.create__WORK_SCHEDULE}>
            <Button
              style={{ marginLeft: 15 }}
              onClick={() => history.push('/company-work-schedule/create')}
              type={'primary'}
              disabled={!isAccessCreate}>
              <PlusCircleOutlined />
              Tạo sự kiện mới
            </Button>
          </AccessControlAction>
        </div>
      </PageTitle>
      <div>
        <ContentBlockWrapper style={{ minHeight: 600 }}>
          <Menu
            mode="horizontal"
            selectedKeys={[selectedKey]}
            style={{ marginBottom: 16 }}
            onClick={changeCategory}>
            {renderMenuItems()}
          </Menu>
          {headerTable}
          {convertTableData.length > 0 && (
            <div style={{ marginBottom: -20, position: 'relative' }}>
              {convertTableData.map(el => {
                if (el.workSchedule.length === 0) return null
                return (
                  <div
                    key={el.weekday}
                    style={{
                      display: 'flex',
                      marginBottom: 20,
                      border: '1px solid #f0f0f0',
                    }}>
                    <BodyTableLeft
                      onClick={() =>
                        history.push(`/company-work-schedule/view/${el.workSchedule[0].schedule_code}`)
                      }
                      style={
                        checkIsTodayWorkSchedule(el.workSchedule[0].start_at)
                          ? {
                            cursor: 'pointer',
                            backgroundColor: '#cddaf4',
                            borderLeft: '3px solid #2c65ac',
                          }
                          : { borderLeft: '1px solid #CDDAF4' }
                      }
                    >
                      <div>
                        {checkIsTodayWorkSchedule(el.workSchedule[0].start_at)
                          ? 'Hôm nay'
                          : utils.renderWeekday(el.weekday)}
                      </div>
                      <div>{renderDate(el.workSchedule[0].start_at)}</div>
                    </BodyTableLeft>
                    <div
                      style={{
                        flexGrow: 1,
                        backgroundColor: checkIsTodayWorkSchedule(
                          el.workSchedule[0].start_at
                        )
                          ? '#dee9fd'
                          : unset,
                      }}>
                      {el.workSchedule.map(item => {
                        return (
                          <BodyTableWrapper key={item.schedule_code}
                            onClick={() =>
                              history.push(
                                `/company-work-schedule/view/${item.schedule_code}`
                              )
                            }>
                            <BodyTableRight>
                              <ItemTableRight>
                                <strong
                                  style={{
                                    fontWeight: 'bold',
                                    display: 'block',
                                  }}
                                >
                                  {renderTime(item.start_at)}
                                  {item.end_at &&
                                    ` đến ${renderTime(item.end_at)}`}
                                </strong>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  {item.event_notice ? (
                                    utils.removeHtml(item.event_notice)
                                  ) : (
                                    <EmptyText>Không có nội dung</EmptyText>
                                  )}
                                </div>
                                {item.file_ids.map(file => (
                                  <FileItem
                                    key={file.file_id}
                                    file_id={file.file_id}
                                    file_name={file.file_title}
                                    file_type={utils.getExtensionFile(
                                      file.file_title
                                    )}
                                  />
                                ))}
                              </ItemTableRight>
                              <ItemTableRight
                                onClick={() =>
                                  history.push(
                                    `/company-work-schedule/view/${item.schedule_code}`
                                  )
                                }>
                                {item.preparation ? (
                                  item.preparation
                                ) : (
                                  <EmptyText>Không có chuẩn bị.</EmptyText>
                                )}
                              </ItemTableRight>
                              <ItemTableRight
                                onClick={() =>
                                  history.push(
                                    `/company-work-schedule/view/${item.schedule_code}`
                                  )
                                }>
                                {item.attenders ? (
                                  item.attenders
                                ) : (
                                  <EmptyText>
                                    Không có thành viên tham gia.
                                  </EmptyText>
                                )}
                              </ItemTableRight>
                              <ItemTableRight
                                onClick={() =>
                                  history.push(
                                    `/company-work-schedule/view/${item.schedule_code}`
                                  )
                                }>
                                {item.location ? (
                                  item.location
                                ) : (
                                  <EmptyText>Không có địa điểm.</EmptyText>
                                )}
                              </ItemTableRight>
                              <ItemTableRight
                                onClick={() =>
                                  history.push(
                                    `/company-work-schedule/view/${item.schedule_code}`
                                  )
                                }>
                                {item.host ? (
                                  item.host
                                ) : (
                                  <EmptyText>Không có chủ trì.</EmptyText>
                                )}
                              </ItemTableRight>
                            </BodyTableRight>
                          </BodyTableWrapper>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
              <SpinnerInlineComponent
                isLoading={isLoadingWorkSchedule}
                sizeSpin={'small'}
                tip={'Đang tải lịch cơ quan'}
                alignItems
                noBackground
              />
            </div>
          )}
          {sum === 0 && !isLoadingWorkSchedule && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
              style={{ marginTop: 50 }}
            />
          )}
        </ContentBlockWrapper>
      </div>
    </DashboardLayout>
  )
}

export default memo(
  inject(
    'workScheduleStore',
    'authenticationStore',
    'companyStore'
  )(observer(CompanyWorkScheduleListPage))
)
