import React from 'react'
import { RiskHorizontalDisplay } from '../../components/Common'
const ReportPdf = props => {
  const {
    dataActions,
    children,
    unit,
    cqvp,
    dvpt,
    nddpv,
    report_submit_date,
    report_period_start,
    report_period_end,
  } = props

  function extractDateParts(dateString) {
    if (typeof dateString !== 'string' || !dateString.includes('-')) {
      return { day: null, month: null, year: null }
    }
    let [day, month, year] = dateString.split('-')
    return { day, month, year }
  }

  return (
    <div id={'reportDivToPrint'} style={{ fontSize: '10px !important ' }}>
      <RiskHorizontalDisplay
        labels={[
          {
            value: (
              <>
                <div style={{ textAlign: 'center' }}>
                  TỔNG CÔNG TY HÀNG HẢI VIỆT NAM – CTCP
                </div>
                <div style={{ textAlign: 'center' }}>{unit}</div>
              </>
            ),
            style: { margin: '0 ', width: '100%' },
          },
          {
            value: (
              <div style={{ textAlign: 'center', marginLeft: '45px' }}>
                <i>
                  Hà Nội, ngày {extractDateParts(report_submit_date).day} tháng{' '}
                  {extractDateParts(report_submit_date).month} năm{' '}
                  {extractDateParts(report_submit_date).year}
                </i>
              </div>
            ),
            style: { margin: '0 0 0 20px', width: '80%' },
          },
        ]}
      />
      <h4 style={{ textAlign: 'center' }}>KẾT QUẢ CÔNG TÁC QUẢN TRỊ RỦI RO</h4>
      <p style={{ textAlign: 'center' }}>
        <i>
          Kỳ báo cáo {extractDateParts(report_period_start).day}/
          {extractDateParts(report_period_start).month}/
          {extractDateParts(report_period_start).year} -{' '}
          {extractDateParts(report_period_end).day}/
          {extractDateParts(report_period_end).month}/
          {extractDateParts(report_period_end).year}
        </i>
      </p>
      {cqvp && cqvp.length > 0 && (
        <p style={{ textAlign: 'left', textTransform: 'uppercase' }}>
          Đơn vị thuộc Cơ quan Văn phòng Tổng công ty: {cqvp.toUpperCase()}
        </p>
      )}
      {dvpt && dvpt.length > 0 && (
        <p style={{ textAlign: 'left', textTransform: 'uppercase' }}>
          Đơn vị phụ thuộc VIMC: {dvpt.toUpperCase()}
        </p>
      )}
      {nddpv && nddpv.length > 0 && (
        <p style={{ textAlign: 'left', textTransform: 'uppercase' }}>
          DNTV của VIMC: {nddpv.toUpperCase()}
        </p>
      )}
      <div style={{ height: '30px' }}></div>
      <p>{children}</p>
    </div>
  )
}
ReportPdf.propTypes = {}

export default ReportPdf
