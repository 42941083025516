import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { PlusOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons'
import axios from 'axios'
import UnreadTextWork from '../../components/UnreadText/UnreadTextWork'
import utils from '../../utils'
import date_format from '../../date_format'
import TableComponent from '../../components/Common/TableComponent'
import {
  ASSIGNEE_TYPE,
  DATE_FORMAT_SLASH,
  TYPE_STATUS,
  VIEWLOG_DATE,
  WORK_STATUS,
} from '../../constants'
import { grayPrimary } from '../../color'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { ContentBlock } from './TextShoppingCouponStylePage'
import { AvatarHasName } from '../../components/Common/Avatar'
import { EmptyText } from '../../components/Common/CellText'
import PopoverWatchTime from '../../components/PopoverWatchTime'
import useOpenItem from '../../hooks/useOpenItem'
import PopupWorkForm from '../WorkPage/PopupWorkForm'

const ModalWorkLists = props => {
  const {
    internalDocumentStore,
    onClearDocument,
    onSubmitModal,
    handleCloseModal,
    isVisibleModalShoppingCoupon,
    taskStore,
    workStore,
    selectUserStore,
    selectPeopleStore,
    authenticationStore,
    aclStore,
    loadingAnimationStore,
    handleOpenDrawerWork,
    listHandler,
    isUnread,
    currentStepType,
    onConfirmSelection,
    selectedRowKeysStep1,
    selectedRowKeysStep2,
    selectedRowKeysStep3,
    shoppingTasks,
    paymentTasks,
    selectedRowKeysSubStep3,
    onWorkSelect,
    currentStep,
    ShoppingCouponStore,
  } = props
  const location = useLocation()
  const { workList, queryParams, totalWork } = workStore
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const queryStringParse = queryString.parse(location.search)
  const [loadingDocument, setLoadingDocument] = useState(false)
  const [formSearch] = Form.useForm()
  const [steps, setSteps] = useState([
    'Step 1',
    'Step 2',
    'Step 3',
    'Step 4',
    'Step 5',
  ])
  const [current, setCurrent] = useState(0)
  const [listHandlerr, setListHandler] = useState(true)

  const {
    isOpen: isVisiblePopupWorkForm,
    handleOpen: handleOpenPopupWorkForm,
    handleClose: handleClosePopupWorkForm,
  } = useOpenItem()
  const queryStringParsed = queryString.parse(location.search, {
    arrayFormat: 'index',
  })
  const handleResetHandler = () => {
    setListHandler(true)
  }

  useEffect(() => {
    if (currentStep === 1) {
      setSelectedRowKeys(selectedRowKeysStep1)
    } else if (currentStep === 2) {
      setSelectedRowKeys(selectedRowKeysStep2)
    } else if (currentStep === 3) {
      setSelectedRowKeys(selectedRowKeysStep3)
    } else if (currentStep === 4) {
      setSelectedRowKeys(shoppingTasks)
    } else if (currentStep === 5) {
      setSelectedRowKeys(paymentTasks)
    } else {
      setSelectedRowKeys(selectedRowKeysSubStep3[currentStep - 1] || []);
    }
  }, [
    currentStep,
    selectedRowKeysStep1,
    selectedRowKeysStep2,
    selectedRowKeysStep3,
    selectedRowKeysSubStep3,
    shoppingTasks,
    paymentTasks,
  ])

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys,
    onChange: selectedKeys => {
      setSelectedRowKeys(selectedKeys)
      if (currentStep === 1) {
        ShoppingCouponStore.setSelectedTasksStep1(selectedKeys)
      } else if (currentStep === 2) {
        ShoppingCouponStore.setSelectedTasksStep2(selectedKeys)
      } else if (currentStep === 3) {
        ShoppingCouponStore.setSelectedTasksStep3(selectedKeys)
      } else if (currentStep === 4) {
        ShoppingCouponStore.setShoppingTasks(selectedKeys)
      } else if (currentStep === 5) {
        ShoppingCouponStore.setPaymentTasks(selectedKeys)
      }
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  }

  const handleConfirm = () => {
    const selectedWorks = workList.filter(work =>
      selectedRowKeys.includes(work.code)
    )
    const selectedTitles = selectedWorks.map(work => ({
      code: work.code,
      title: work.title,
    }))
    onConfirmSelection(selectedTitles, selectedRowKeys) // Call the callback function with the selected titles and keys
  }

  const handleClearSelected = () => {
    setSelectedRowKeys([])
  }

  const getWorkList = async cancelToken => {
    try {
      loadingAnimationStore.setTableLoading(true)
      const res = await workStore.getWorkList(cancelToken)

      if (res) {
        loadingAnimationStore.setTableLoading(false)
      }
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    }
  }

  useEffect(() => {
    workStore.getWorkList()
  }, [listHandler])

  useEffect(() => {
    setSelectedRowKeys(taskStore[`selectedTasksStep${currentStepType}`] || [])
  }, [currentStepType, taskStore])

  const [isFetchingMore, setIsFetchingMore] = useState(false)

  useEffect(() => {
    const source = axios.CancelToken.source()
      ; (async () => {
        isFetchingMore ? await workStore.getWorkList() : getWorkList(source.token)
        setIsFetchingMore(false)
      })()
    return () => {
      source.cancel()
    }
  }, [workStore.queryParams])

  const updatedBy = (user_name, updated_at) => {
    if (!user_name) return null
    return (
      <div style={{ flex: '0 0 100%', fontSize: 8, marginTop: 2 }}>
        <span>
          Cập nhật bởi <b>{utils.getNameInCapitalize(user_name)}</b> -{' '}
          <span>{date_format.renderTime(updated_at)}</span>
        </span>
      </div>
    )
  }

  const timeColumn = item => (
    <div>
      {' '}
      {item.due_date ? (
        moment(item.due_date).format(DATE_FORMAT_SLASH)
      ) : (
        <i style={{ color: grayPrimary }}>Không rõ</i>
      )}
    </div>
  )
  const checkUnread = item => item.is_read === false

  const CREATOR = 160
  const CREATED_AT = 100
  const HANDLER = 120
  const DEADLINE_TIME = 120
  const SCROLL_WIDTH = 1000
  const TITLE = 500

  const columns = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            paddingLeft: '70px',
          }}>
          Nội dung
        </div>
      ),
      width: TITLE,
      render: item => (
        <UnreadTextWork
          text={
            <div>
              <div
                style={{
                  marginLeft: '70px',
                  fontSize: '12px',
                  maxWidth: '750px',
                }}>
                <div
                  style={{
                    fontSize: '12px',
                    maxWidth: '100%',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                  }}>
                  {item.title}
                </div>
                {updatedBy(item.updated_user, item.updated_at)}
              </div>
            </div>
          }
          isUnread={checkUnread(item)}
          width={'100%'}
        />
      ),
    },
    {
      title: 'Người xử lý',
      width: HANDLER,
      render: item => {
        const handleList = selectUserStore.convertListToDefault(
          item.assignees.filter(user => {
            return (
              user.permission === ASSIGNEE_TYPE.HANDLER &&
              user.name_uppercase != null
            )
          })
        )
        return (
          <UnreadTextWork
            text={
              <div
                className={'assign'}
                style={{ height: '50px' }}
                onClick={e => e.stopPropagation()}>
                {handleList.length > 0 ? (
                  <PopoverWatchTime
                    userList={handleList}
                    title={'Danh sách người xử lý'}
                    isFullViewer={true}
                    compared_date={VIEWLOG_DATE.work_read_until}
                    typeId={item.code}
                    created_at={item.created_at}
                  />
                ) : (
                  <EmptyText>Không có người xử lý.</EmptyText>
                )}
              </div>
            }
            isUnread={checkUnread(item)}
            width={HANDLER}
          />
        )
      },
    },
    {
      title: 'Người tạo',
      width: CREATOR,
      render: item => {
        return (
          <UnreadTextWork
            text={
              <ContentBlock className={'user'}>
                {item.assignees.length > 0 ? (
                  <AvatarHasName
                    imgId={
                      item.assignees.find(
                        user => user.permission === ASSIGNEE_TYPE.CREATE
                      )?.image
                    }
                    name={
                      item.assignees.find(
                        user => user.permission === ASSIGNEE_TYPE.CREATE
                      )?.name_uppercase
                    }
                  />
                ) : (
                  <EmptyText>Không rõ.</EmptyText>
                )}
              </ContentBlock>
            }
            isUnread={checkUnread(item)}
            width={CREATOR}
          />
        )
      },
    },
    {
      title: 'Ngày tạo',
      width: CREATED_AT,
      render: item => {
        return (
          <UnreadTextWork
            text={
              <div>
                {item?.created_at ? (
                  moment(item.created_at).format(DATE_FORMAT_SLASH)
                ) : (
                  <i style={{ color: grayPrimary }}>Không rõ</i>
                )}
              </div>
            }
            isUnread={checkUnread(item)}
            width={CREATED_AT}
          />
        )
      },
    },
    {
      title: 'Hạn hoàn thành',
      width: DEADLINE_TIME,
      render: item => {
        return (
          <UnreadTextWork
            text={
              <div>
                {WORK_STATUS.ALL_OTHER.includes(queryParams.work_type) && (
                  <div>{StatusTag(TYPE_STATUS.WORK, item.work_type)}</div>
                )}
                {timeColumn(item)}
              </div>
            }
            isUnread={checkUnread(item)}
          />
        )
      },
    },
  ]

  const onChangePagination = e => {
    workStore.setQueryParams({
      ...queryParams,
      page: e - 1,
      size: 10,
    })
    workStore
      .getWorkList()
      .finally(() => loadingAnimationStore.setTableLoading(true))
  }

  // const handleTableChange = selectedKeys => {
  //   const selectedTitles = selectedKeys.map(key => {
  //     const task = workList.find(item => item.code === key)
  //     return task ? { code: task.code, title: task.title } : ''
  //   })
  //   taskStore.setSelectedRows(currentStepType, selectedTitles)
  //   setSelectedRowKeys(selectedKeys)
  // }

  const handleSearch = async title => {
    workStore.setQueryParams({
      ...queryParams,
      page: 0,
      title: title ? title.title.trim() : null,
      parent_id: null,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        title: title ? title.title.trim() : null,
        parent_id: null,
      },
      {
        skipNull: true,
      }
    )
    console.log("check queryStringStringify >>>>", queryStringStringify)
    // history.replace(`/works?${queryStringStringify}`)
    // await getWorkList()
  }
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value === '') {
      handleSearch({ title: '' });
    }
  }

  return (
    <Modal
      closable={false}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <span>Danh sách công việc</span>
          <Space>
            <Button
              icon={<PlusOutlined />}
              type="default"
              onClick={() => {
                handleOpenPopupWorkForm()
                handleResetHandler()
              }}>
              Tạo mới
            </Button>
            <Button
              danger
              onClick={handleClearSelected}>
                Huỷ chọn
            </Button>
            <Button key="confirm" type={'primary'} onClick={handleConfirm}>
              Đồng ý
            </Button>
            <Tooltip title={'Đóng'}>
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={() => {
                  formSearch.resetFields()
                  handleClearSelected()
                  handleCloseModal()
                }}
              />
            </Tooltip>
          </Space>
        </div>
      }
      footer={null}
      width={1100}
      visible={isVisibleModalShoppingCoupon}
      style={{ top: 20 }}>
      <Form form={formSearch} layout={'horizontal'} onFinish={handleSearch}>
        <div
          style={{ display: 'flex', gap: 20, justifyContent: 'space-between' }}>
          <Row gutter={[16, 16]} style={{ flexGrow: 1 }}>
            <Col span={24}>
              <Form.Item label={'Tìm kiếm theo nội dung:'} name={'title'}>
                <Input placeholder={'Nhập nội dung công việc'} allowClear onChange={handleInputChange}/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ flex: '0 0 70px' }}>
            <Button
              icon={<SearchOutlined />}
              htmlType={'submit'}
              type={'primary'}>
              Tìm kiếm
            </Button>
          </Form.Item>
        </div>
      </Form>
      <TableComponent
        rowSelection={rowSelection}
        rowKey={record => record.code}
        columns={columns}
        dataSource={workList}
        pagination={false}
        loading={{
          tip: 'Đang tải công việc...',
          spinning: loadingAnimationStore.tableLoading,
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '25px 0',
        }}>
        <Pagination
          onChange={e => onChangePagination(e)}
          hideOnSinglePage={true}
          total={totalWork}
          pageSize={10}
          current={queryParams.page + 1}
          showSizeChanger={false}
          showLessItems
        />
      </div>

      {isVisiblePopupWorkForm && (
        <PopupWorkForm
          queryStringParsed={queryStringParsed}
          isVisiblePopupWorkForm={isVisiblePopupWorkForm}
          handleCloseWorkForm={handleClosePopupWorkForm}
          listHandler={listHandlerr}
          setListHandler={setListHandler}
        />
      )}
    </Modal>
  )
}

export default inject(
  'internalDocumentStore',
  'workStore',
  'commonStore',
  'selectUserStore',
  'taskStore',
  'aclStore',
  'loadingAnimationStore',
  'ShoppingCouponStore'
)(observer(ModalWorkLists))
