import {
  Button,
  Empty,
  message,
  Modal,
  Input,
  Select,
  Space,
  Table,
  Pagination,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import ApprovalTemplateApply from './ApprovalTemplateApply'
import ApprovalTemplateUpdate from './ApprovalTemplateUpdate'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { blue } from '../../../color'
import { TableBottomPaginationBlock } from '../../../components/Common/Table'
import TableComponent from '../../../components/Common/TableComponent'

const { confirm } = Modal
const { Option } = Select

const ApprovalTemplateList = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false)
  const [isVisibleApplyModal, setIsVisibleApplyModal] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [filters, setFilters] = useState({ name: '', type: '' })

  const { selectPeopleStore, authenticationStore } = props
  const { originDepartmentWithUserList, companyCode } = selectPeopleStore

  const { approvalTemplateStore } = props

  const [selectedTemplateId, setSelectedTemplateId] = useState({})

  // useEffect(() => {
  //   loadApprovalTemplateList()
  // }, [filters, currentPage, pageSize])
  useEffect(() => {
    loadApprovalTemplateList()
  }, [])

  useEffect(() => {
    if (companyCode) {
      selectPeopleStore.getDepartmentWithUsers().catch(err => {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      })
    }
  }, [companyCode])

  useEffect(() => {
    if (authenticationStore.currentUser) {
      selectPeopleStore.companyCode = authenticationStore.currentCompanyCode()
    }
  }, [authenticationStore.currentUser])

  const loadApprovalTemplateList = async () => {
    try {
      setIsLoading(true)
      await approvalTemplateStore.getApprovalTemplateLists()
    } finally {
      setIsLoading(false)
    }
  }

  const handleOpenUpdateModal = id => event => {
    setIsVisibleUpdateModal(true)
    setSelectedTemplateId(id)
  }

  const handleCancelUpdateModal = () => {
    setIsVisibleUpdateModal(false)
  }

  const handleCancelApplyModal = () => {
    setIsVisibleApplyModal(false)
  }

  const handleOpenAppyModal = id => event => {
    setIsVisibleApplyModal(true)
    setSelectedTemplateId(id)
  }

  const handleDelete = async code => {
    try {
      setIsLoading(true)
      await approvalTemplateStore.delete(code)
      await loadApprovalTemplateList()
      message.success('Đã xóa!')
    } catch (err) {
      console.log(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  const showConfirm = code => {
    confirm({
      title: 'Bạn có chắc chắn muốn xóa Cấp duyệt này ?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Xóa',
      cancelText: 'Không',
      okType: 'danger',
      onOk: async () => {
        await handleDelete(code)
      },
    })
  }

  const changeTypeName = type => {
    switch (type) {
      case 'asset':
        return 'Văn phòng phẩm'
      case 'proposal':
        return 'Hành chính'
      case 'salary':
        return 'Duyệt lương'
      default:
        return ''
    }
  }

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value })
    setCurrentPage(1)
  }

  const handlePageChange = (page, size) => {
    setCurrentPage(page)
    setPageSize(size)
  }

  const getFilteredData = () => {
    const { name, type } = filters
    return approvalTemplateStore.approvalTemplateLists.filter(item => {
      return (
        (!name || item.name.toLowerCase().includes(name.toLowerCase())) &&
        (!type || (item.templateType && item.templateType === type))
      )
    })
  }

  const paginatedData = getFilteredData().slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  )

  const tableColumns = [
    {
      title: 'Loại',
      render: record => <p>{changeTypeName(record.templateType)}</p>,
    },
    {
      title: 'Tên mẫu',
      render: record => <p>{record.name}</p>,
    },
    {
      title: 'Mô tả',
      width: 450,
      render: record => <p>{record.description}</p>,
    },
    {
      title: 'Thao tác',
      width: 150,
      align: 'center',
      render: record => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Button
            type={'text'}
            style={{ color: blue }}
            onClick={handleOpenUpdateModal(record.code)}>
            Sửa
          </Button>

          {/* <Button
            type={'text'}
            style={{ color: blue }}
            onClick={handleOpenAppyModal(record.code)}>
            Áp dụng
          </Button> */}

          <Button type={'text'} danger onClick={() => showConfirm(record.code)}>
            Xóa
          </Button>
        </div>
      ),
    },
  ]
  return (
    <>
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Tìm kiếm theo tên mẫu"
          allowClear
          value={filters.name}
          style={{ width: 400, marginRight: 16 }}
          onChange={e => handleFilterChange('name', e.target.value)}
        />
        <Select
          placeholder="Lọc theo loại"
          allowClear
          // value={filters.type}
          onChange={value => handleFilterChange('type', value)}
          style={{ width: 400 }}>
          <Option value="asset">Văn phòng phẩm</Option>
          <Option value="proposal">Hành chính</Option>
          <Option value="salary">Duyệt lương</Option>
        </Select>
      </Space>
      <TableComponent
        rowKey={record => record.code}
        dataSource={paginatedData}
        columns={tableColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={isLoading}
      />
      <TableBottomPaginationBlock>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={getFilteredData().length}
          onChange={handlePageChange}
          showSizeChanger
          onShowSizeChange={handlePageChange}
          style={{ marginTop: 16, textAlign: 'right' }}
        />
      </TableBottomPaginationBlock>
      <Modal
        width={1000}
        title={'Chỉnh sửa cấp duyệt'}
        visible={isVisibleUpdateModal}
        maskClosable={false}
        onCancel={handleCancelUpdateModal}
        footer={null}
        destroyOnClose>
        {isVisibleUpdateModal && (
          <ApprovalTemplateUpdate
            onCancel={handleCancelUpdateModal}
            templateId={selectedTemplateId}
          />
        )}
      </Modal>

      <Modal
        width={800}
        title={'Áp dụng mẫu'}
        visible={isVisibleApplyModal}
        maskClosable={false}
        onCancel={handleCancelApplyModal}
        footer={null}
        destroyOnClose>
        {isVisibleApplyModal && (
          <ApprovalTemplateApply
            templateId={selectedTemplateId}
            onCancel={handleCancelApplyModal}
          />
        )}
      </Modal>
    </>
  )
}

export default inject(
  'approvalTemplateStore',
  'authenticationStore',
  'selectPeopleStore'
)(observer(ApprovalTemplateList))
