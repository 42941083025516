import { Button } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import AccessControlAction from '../../../components/AccessControlAction'
import { TitleContentBox } from '../../../components/Common/CellText'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import ProposalStepApprove from '../../../components/SubmissionForm/ProposalStepApprove'
import { PROPOSAL_STATUS, ACL_ACTION_TYPE } from '../../../constants'
import DistributionVotesConfirmModal from './DistributionVotesConfirmModal'

const groupBtnStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 10,
  marginBottom: 10,
}

const DistributionVotesAction = props => {
  const {
    DistributionVotesStore,
    authenticationStore,
    isAllAssetSelected,
    getDiffAssets,
  } = props

  const { currentUser } = authenticationStore
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [confirmModalType, setConfirmModalType] = useState(null)
  const [isShowButtonAction, setIsShowButtonAction] = useState(false)

  useEffect(() => {
    if (!currentUser) return
    const currentStepInfo = DistributionVotesStore.distributionVotesDetail.approval_levels?.find(
      item => item.status === PROPOSAL_STATUS.PENDING
    )
    if (currentUser.username === currentStepInfo?.username) {
      setIsShowButtonAction(true)
    } else {
      setIsShowButtonAction(false)
    }
  }, [DistributionVotesStore.distributionVotesDetail, currentUser])

  const handleCloseModal = useCallback(() => {
    setIsConfirmModalVisible(false)
    setConfirmModalType(null)
  }, [])

  const handleOpenConfirmModal = modalType => {
    setConfirmModalType(modalType)
    setIsConfirmModalVisible(true)
  }

  return (
    <ContentBlockWrapper style={{ marginBottom: 15 }}>
      <TitleContentBox>Tiến trình duyệt</TitleContentBox>
      <ProposalStepApprove
        approvalLevelList={
          DistributionVotesStore.distributionVotesDetail.approval_levels || []
        }
      />
      {isShowButtonAction && (
        <div style={groupBtnStyle}>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.approve__PROPOSAL__ASSET}>
            <Button
              danger
              onClick={() => handleOpenConfirmModal(PROPOSAL_STATUS.REJECT)}>
              Từ chối
            </Button>
          </AccessControlAction>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.approve__PROPOSAL__ASSET}>
            <Button
              type={'primary'}
              onClick={() => handleOpenConfirmModal(PROPOSAL_STATUS.APPROVAL)}>
              Duyệt yêu cầu
            </Button>
          </AccessControlAction>
        </div>
      )}

      <DistributionVotesConfirmModal
        isModalVisible={isConfirmModalVisible}
        handleCloseModal={handleCloseModal}
        confirmModalType={confirmModalType}
        isAllAssetSelected={isAllAssetSelected}
        getDiffAssets={getDiffAssets}
      />
    </ContentBlockWrapper>
  )
}

DistributionVotesAction.propTypes = {}

export default inject(
  'DistributionVotesStore',
  'authenticationStore'
)(observer(DistributionVotesAction))
