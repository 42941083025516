import React from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '../../components/PageTitle'
import DashboardLayout from '../../layouts/DashboardLayout'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useState } from 'react'
import {
  Avatar,
  Button,
  Comment,
  Divider,
  Empty,
  Input,
  Menu,
  message,
  Popconfirm,
  Row,
  Select,
  Switch,
  Tooltip,
} from 'antd'
import { SearchBar } from '../UserAccountManagementPage/UserAcountManagementPageStyled'
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import TableComponent from '../../components/Common/TableComponent'
import queryString from 'query-string'
import AddUserForm from '../../components/AddUserForm'
import { useCallback } from 'react'
import UserAccountEditForm from '../../components/UserAccountEditForm'
import { systemName } from '../../config'

const { Search } = Input
const { Option } = Select

const AdminsPage = props => {
  const history = useHistory()
  const {
    authenticationStore,
    companyStore,
    positionStore,
    userStore,
    departmentStore,
    roleStore,
  } = props
  const { getCompanyList, companyList } = companyStore
  const { getAdminList, userList } = userStore

  const [showAddUser, setShowAddUser] = useState(false)
  const [showUserInfo, setShowUserInfo] = useState(false)

  useEffect(() => {
    if (authenticationStore.isSuperAdmin || !authenticationStore.currentUser) {
      return
    }

    history.replace('/')
    message.error('Bạn không có quyền truy cập trang này!')
  }, [authenticationStore.currentUser])
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })

  const [editRisk, setEditRisk] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState({ type: null })
  const [isRefresh, setIsRefresh] = useState(true)
  const [showCreateModal, setShowCreateModal] = useState(false)
  useEffect(() => {
    ;(async () => {
      await positionStore.getPositionList()
      await roleStore.getRoles()
      if (!companyList) {
        getCompanyList()
      }
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      if (companyList) {
        setFilter({
          type: queryStringParse.type || companyList[0]?.code,
        })
        await loadAdminsList(queryStringParse.type || companyList[0]?.code)
        setIsRefresh(false)
      }
    })()
  }, [JSON.stringify(companyList)])
  useEffect(() => {
    if (isRefresh) return
    const queryStringStringify = queryString.stringify(filter, {
      skipNull: true,
    })
    history.replace(`/admins?${queryStringStringify}`)
    ;(async () => {
      await loadAdminsList(filter.type)
    })()
  }, [filter])

  const loadAdminsList = async params => {
    try {
      setIsLoading(true)
      // await userStore.getUserList()
      await getAdminList(params || filter.type)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeType = type => {
    setFilter({ ...filter, type: type })
  }

  const handleCancelAddUser = useCallback(() => {
    setShowAddUser(false)
  }, [])

  const handleShowCreateModal = () => {
    setShowAddUser(true)
  }
  //
  const handleCancelViewUserInfo = useCallback(() => {
    userStore.clearSelectedUser()
    setShowUserInfo(false)
  }, [userStore])

  const handleViewUserInfo = userInfo => {
    const companyCode = filter.type
    userStore.setSelectedUser(userInfo)
    setIsLoading(true)
    departmentStore
      .getDepartmentListFromCompanyCode(companyCode)
      .then(() => setShowUserInfo(true))
      .catch(error => message.error(error.vi))
      .finally(() => setIsLoading(false))
  }

  const handleUpdateUserStatus = async (userCode, status) => {
    try {
      setIsLoading(true)
      await userStore.updateUserStatus(userCode, status)
      await loadAdminsList()
      message.success(`Đã ${status ? 'kích hoạt' : 'tạm dừng'} người dùng!`)
    } catch (err) {
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }

  const tableColumns = [
    {
      title: 'Họ tên',
      width: '60%',
      key: 'username',
      render: record => (
        <Comment
          author={
            <span style={{ textTransform: 'capitalize' }}>
              {record.name_lowercase}
            </span>
          }
          avatar={
            <Avatar
              size={36}
              style={{ backgroundColor: '#1890ff' }}
              src={
                record?.image && `url("data:image/gif;base64,${record?.image}")`
              }>
              {record?.username.split('')[0].toString().toUpperCase()}
            </Avatar>
          }
        />
      ),
    },
    {
      title: 'Email',
      key: 'email',
      render: record => record?.email,
      width: '20%',
    },
    {
      title: 'Trạng thái',
      key: 'status',
      width: ' 10%',
      render: record => (
        <Switch
          onChange={status => handleUpdateUserStatus(record?.code, status)}
          checked={record?.status}
        />
      ),
    },
    {
      title: 'Tác vụ',
      key: 'action',
      fixed: 'right',
      width: 130,
      render: record => (
        <Button onClick={() => handleViewUserInfo(record)} type="primary">
          <EditOutlined />
          Chỉnh sửa
        </Button>
      ),
    },
    ,
  ]
  return (
    <DashboardLayout isSuperAdminPage>
      <Helmet>
        <title>Quản trị viên | {systemName} Quản trị viên</title>
      </Helmet>
      <PageTitle location={props.location} />
      <ContentBlockWrapper>
        <Row style={{ justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              gap: 10,
              marginBottom: 14,
            }}>
            <Select
              style={{ minWidth: '250px' }}
              name={'proposal_status'}
              placeholder={'Lọc theo loại'}
              allowClear
              value={filter?.type || (companyList && companyList[0]?.code)}
              onChange={value => handleChangeType(value)}>
              {companyList?.length > 0 &&
                companyList.map(type => (
                  <Option key={type.code} value={type.code}>
                    {type.name}
                  </Option>
                ))}
            </Select>
          </div>
          <Button type={'primary'} onClick={handleShowCreateModal}>
            <PlusCircleOutlined />
            Tạo mới
          </Button>
        </Row>
        <TableComponent
          rowKey={record => record.code}
          dataSource={userList}
          columns={tableColumns}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ),
          }}
          loading={isLoading}
        />
        <AddUserForm
          modalVisible={showAddUser}
          onCancel={handleCancelAddUser}
          isAdmin={true}
          companyCode={filter.type}
          loadAdminsList={loadAdminsList}
        />
        <UserAccountEditForm
          modalVisible={showUserInfo}
          onCancel={handleCancelViewUserInfo}
          isAdmin={true}
          loadAdminsList={loadAdminsList}
        />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject(
  'companyStore',
  'positionStore',
  'userStore',
  'authenticationStore',
  'departmentStore',
  'roleStore'
)(observer(AdminsPage))
