import {
  Button,
  Col,
  Form,
  InputNumber,
  Input,
  message,
  Modal,
  Row,
  Space,
  Empty,
  Steps,
  Tooltip,
  Tag,
  Divider,
  Select,
} from 'antd'
import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { ButtonFooter } from '../WorkPage/PopupWorkForm/PopupWorkFormStyled'
import validator from '../../validator'
import { AvatarHasName } from '../../components/Common/Avatar'
import TableComponent from '../../components/Common/TableComponent'
import { SelectList } from '../../pages/MissionCreatePage/MissionCreatePageStyled'
import DistributionVotesListSelect from './DistributionVotesListSelect'
import { blue } from '../../color'
import SelectPeoplePopup from '../../components/SelectPeoplePopup'
import { ASSIGNEE_TYPE } from '../../constants'
import ProposalStepApprove from './ProposalStepApprove'

const DistributionVotesCreate = props => {
  const [form] = Form.useForm()

  const [followerList, setFollowerList] = useState([])
  const [approvalLevels, setApprovalLevels] = useState([])
  const [
    selectedApproveTemplateCode,
    setSelectedApproveTemplateCode,
  ] = useState('')
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false)
  const [approvalTemplateList, setApprovalTemplateList] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [warnings, setWarnings] = useState({})
  const {
    DistributionVotesStore,
    selectPeopleStore,
    approvalTemplateStore,
    proposalStore,
  } = props
  const [isCreating, setIsCreating] = useState(false)
  const [dataTable, setDataTable] = useState([])

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleRemoveSelect,
    clearSelected,
    clearSelectUserData,
  } = selectPeopleStore

  useEffect(() => {
    ;(async () => {
      await clearSelectUserData()
      await setFollowerList([])
      await clearSelected()
      await loadApprovalTemplateList()
      setApprovalTemplateList(approvalTemplateStore.approvalTemplateList)
    })()
  }, [])
  const loadApprovalTemplateList = async () => {
    try {
      await approvalTemplateStore.getApprovalTemplateListSearch(
        'template_type=asset'
      )
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
    }
  }

  useEffect(() => {
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const handleToggleCreateModal = bool => event => {
    setIsVisibleCreateModal(bool)
  }
  const onFinish = async value => {
    setIsCreating(true)
    try {
      let level =
        proposalStore.defaultApprovalConfigUpdate.approvalLevels.length || 0
      let hasWarning = false
      if (level === 0) {
        message.error('Chọn cấp duyệt')
        return
      }
      let level1 = dataTable.length || 0
      if (level1 === 0) {
        message.error('Chọn danh sách văn phòng phẩm')
        return
      }
      proposalStore.defaultApprovalConfigUpdate.approvalLevels.some(item => {
        if (!item?.username) {
          message.error(`Hãy chọn ${item.name}!`)
          setIsCreating(false)
          hasWarning = true
          return true
        }
        return false
      })
      if (hasWarning) return

      // Kiểm tra độ dài của purpose
      const invalidPurposes = dataTable.filter(
        item => item.purpose && item.purpose.length > 100
      )
      if (invalidPurposes.length > 0) {
        message.error('Mục đích sử dụng không được vượt quá 100 ký tự')
        setIsCreating(false)
        return
      }

      const invalidItems = dataTable.filter(
        item => item.amount === null || item.amount === undefined
      )
      if (invalidItems.length > 0) {
        const newWarnings = {}
        invalidItems.forEach(item => {
          newWarnings[item.id] = 'Không bỏ trống!'
        })
        setWarnings(newWarnings)
        setIsCreating(false)
        return
      }

      const approvalLevels = proposalStore.defaultApprovalConfigUpdate.approvalLevels.map(
        (approvalLevel, index) => ({
          status: index === 0 ? 'CREATED' : index === 1 ? 'PENDING' : 'WAITING',
          allow_edit: approvalLevel.allow_edit,
          code: approvalLevel.code,
          isDeleted: approvalLevel.is_deleted,
          level: approvalLevel.level,
          name: approvalLevel.name,
          username: approvalLevel.username,
        })
      )
      const submitValues = {
        title: value.name,
        type: 'proposal_asset',
        proposal_asset_details: dataTable.map(item => ({
          asset_id: item.id,
          amount: item.amount,
          purpose: item.purpose,
        })),
        followers: followerList.map(el => el.id),
        approval_levels: approvalLevels.map(item => ({
          status: item.status,
          allow_edit: item.allow_edit,
          level: item.level,
          code: item.code,
          name: item.name,
          username: item.username,
        })),
      }

      await DistributionVotesStore.createDistributionVotes(submitValues)
      await DistributionVotesStore.getDistributionVotestList('page=0&size=10')
      DistributionVotesStore.setQueryParams('filterPage', 0)
      DistributionVotesStore.setQueryParams('filterSize', 10)
      message.success('Tạo mới thành công')
      await props.onCancel()
    } catch (error) {
      console.log(error)
      message.error('Thêm mới thất bại')
      props.onCancel()
    } finally {
      setIsCreating(false)
    }
    setIsCreating(false)
  }

  //Lấy approvalLevels
  const getApprovalLevelTemplate = async code => {
    try {
      const response = await approvalTemplateStore.getApprovalLevelTemplateByATCode(
        code
      )
      const approvalLevelsChange = response.data.map(
        (approvalLevel, index) => ({
          status: index === 0 ? 'CREATED' : 'WAITING',
          allow_edit: approvalLevel.allow_edit,
          code: approvalLevel.code,
          isDeleted: approvalLevel.isDeleted,
          level: approvalLevel.level,
          name: approvalLevel.name,
          username: approvalLevel.username,
        })
      )

      setApprovalLevels(approvalLevelsChange)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    }
  }

  const onChangeApprovalTemplate = async value => {
    setSelectedApproveTemplateCode(value)
    await getApprovalLevelTemplate(value)
  }

  const tableColumns = [
    {
      title: 'STT',
      render: (id, record, index) => {
        ++index
        return index
      },
      width: 10,
    },
    {
      title: 'Tên văn phòng phẩm',
      render: record => <div>{record.asset_name}</div>,
      width: 230,
    },
    {
      title: 'Đặc tính',
      align: 'center',
      render: record => <div>{record?.property}</div>,
      width: 70,
    },
    {
      title: 'Đơn vị tính',
      align: 'center',
      render: record => <div>{record?.asset_unit_name}</div>,
      width: 70,
    },
    {
      title: 'Số lượng đề nghị',
      align: 'center',
      width: 80,
      render: record => (
        <Form.Item
          validateStatus={warnings[record.id] ? 'error' : ''}
          help={warnings[record.id]}
          style={{ marginBottom: 0 }}>
          <InputNumber
            value={record?.amount}
            placeholder="Nhập số lượng"
            parser={value => value?.replace(/\$\s?|(,*)/g, '')}
            onChange={value => {
              const updatedValue = value < 0 ? 0 : value
              const updatedDataT = dataTable.map(item => {
                if (item.id === record.id) {
                  return { ...item, amount: updatedValue }
                }
                return item
              })
              // Bỏ wanring validate
              const updatedWarnings = { ...warnings }
              if (updatedValue !== null && updatedValue !== undefined) {
                delete updatedWarnings[record.id]
              }
              // Cập nhật trạng thái
              setDataTable(updatedDataT)
              setWarnings(updatedWarnings)
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Mục đích sử dụng',
      align: 'center',
      render: record => (
        <div>
          <Input
            type="text"
            value={record.purpose}
            placeholder="Nhập mô tả"
            onChange={e => {
              const value = e.target.value
              const updatedData = dataTable.map(item => {
                if (item.id === record.id) {
                  return { ...item, purpose: value }
                }
                return item
              })
              setDataTable(updatedData)
              if (value?.length > 100) {
                message.error('Mục đích sử dụng không được vượt quá 100 ký tự')
                return
              }
            }}
          />
          <div
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              textAlign: 'center',
            }}>
            {record?.purpose?.length}/100
          </div>
        </div>
      ),
      width: 90,
    },
  ]

  const sortDataTable = data => {
    return data.sort((a, b) => {
      let nameA = a.asset_name.toLowerCase()
      let nameB = b.asset_name.toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    })
  }

  return (
    <>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              label={'Nội dung:'}
              name={'name'}
              rules={[
                { required: true, message: 'Vui lòng nhập nội dung' },
                {
                  max: 500,
                  message: 'Nội dung không được dài quá 500 ký tự!',
                },
                { validator: validator.validateInputString },
              ]}>
              <Input placeholder={'Phiếu đề nghị cung cấp văn phòng phẩm'} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
            <h3
              style={{
                marginRight: '10px',
                marginLeft: '0px',
                marginBottom: '0px',
                marginTop: '0px',
              }}>
              Danh sách văn phòng phẩm :{' '}
            </h3>
            <Button onClick={handleToggleCreateModal(true)}>
              <span style={{ color: 'rgb(44, 101, 172)' }}>Chọn</span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TableComponent
              rowKey={record => record.code}
              // dataSource={managerInventoryStore.managerInventoryList}
              dataSource={sortDataTable(dataTable) || []}
              columns={tableColumns}
              pagination={false}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={'Không có dữ liệu'}
                  />
                ),
              }}
              loading={isLoading}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={12}>
            <label
              style={{ display: 'inline-block', margin: '15px 8px 25px 0' }}>
              Người theo dõi:
            </label>

            <Space>
              <Tooltip title="Chọn người dùng" color={blue}>
                <Button
                  icon={<UserOutlined style={{ color: blue }} />}
                  onClick={() =>
                    handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)
                  }
                />
              </Tooltip>
            </Space>

            <SelectList>
              {followerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </SelectList>
          </Col>
        </Row>
        <Divider
          style={{ width: '80%', marginBottom: '30px', fontWeight: 'bold' }}
        />
        <div>
          <p className="custom-marker">Cấp duyệt:</p>
          <Select
            showSearch
            placeholder="Chọn cấp duyệt"
            optionFilterProp="children"
            onChange={onChangeApprovalTemplate}
            filterOption={(input, option) =>
              (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            style={{ width: '300px' }}
            value={
              selectedApproveTemplateCode.length > 0
                ? selectedApproveTemplateCode
                : undefined
            }>
            {approvalTemplateList &&
              approvalTemplateList.map(item => (
                <Select.Option key={item.code} value={item.code}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </div>
        <ProposalStepApprove
          approvalLevelList={approvalLevels}
          styleCre={'create'}
        />
        <ButtonFooter>
          <Space>
            <Button danger onClick={props.onCancel}>
              Hủy bỏ
            </Button>
            <Button type={'primary'} htmlType={'submit'} loading={isCreating}>
              Tạo mới
            </Button>
          </Space>
        </ButtonFooter>
      </Form>
      <Modal
        width={1200}
        title={'Danh sách VPP'}
        visible={isVisibleCreateModal}
        maskClosable={false}
        onCancel={handleToggleCreateModal(false)}
        footer={null}
        destroyOnClose>
        {isVisibleCreateModal && (
          <DistributionVotesListSelect
            dataTable={dataTable || []}
            onUpdateTable={data => {
              // Merge existing data with the new data
              const updatedData = data.map(newItem => {
                const existingItem = dataTable.find(
                  item => item.id === newItem.id
                )
                return existingItem
                  ? {
                      ...newItem,
                      amount: existingItem.amount,
                      purpose: existingItem.purpose,
                    }
                  : newItem
              })
              setDataTable(updatedData)
            }}
            onCancel={handleToggleCreateModal(false)}
          />
        )}
      </Modal>
      <SelectPeoplePopup
        noLoadSelectData
        disableSelectDepartment
        isOpenCollapse={true}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        isUserDisableList={false}
      />
    </>
  )
}

DistributionVotesCreate.propTypes = {}

export default inject(
  'proposalStore',
  'DistributionVotesStore',
  'approvalTemplateStore',
  'selectPeopleStore'
)(observer(DistributionVotesCreate))
