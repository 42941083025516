// Other
import { inject, observer } from 'mobx-react'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { WorkPageWrapper } from './TextDistributionProductsStylePage'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import DistributionVotesCreate from './DistributionVotesCreate'
import DistributionVotesList from './DistributionVotesList'

// Ant design
import { Button, Menu, message, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// Component
import PageTitle from '../../components/PageTitle'
import { ACL_ACTION_TYPE } from '../../constants'
// Other
import AccessControlAction from '../../components/AccessControlAction'

const DistributionVotesPage = props => {
  const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false)
  const { DistributionVotesStore } = props

  const handleToggleCreateModal = bool => event => {
    props.managementAssetWarehouseStore.clearManagementAssetWarehouseDetail()
    setIsVisibleCreateModal(bool)
    // DistributionVotesStore.clearFilterProposal()
  }

  return (
    <WorkPageWrapper>
      <DashboardLayout>
        <Helmet>
          <title>Hành chính | Phiếu phân phát văn phòng phẩm</title>
        </Helmet>
        <PageTitle location={props.location}>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.create__PROPOSAL__ASSET}>
            <Button
              type={'primary'}
              icon={<PlusOutlined />}
              onClick={handleToggleCreateModal(true)}>
              Tạo mới
            </Button>
          </AccessControlAction>
        </PageTitle>
        <ContentBlockWrapper>
          <DistributionVotesList
            {...props}
            isVisibleCreateModal={isVisibleCreateModal}
          />
        </ContentBlockWrapper>
      </DashboardLayout>
      <Modal
        width={1000}
        title={'Thêm mới'}
        visible={isVisibleCreateModal}
        maskClosable={false}
        onCancel={handleToggleCreateModal(false)}
        footer={null}
        destroyOnClose>
        {isVisibleCreateModal && (
          <DistributionVotesCreate onCancel={handleToggleCreateModal(false)} />
        )}
      </Modal>
    </WorkPageWrapper>
  )
}

export default inject(
  'DistributionVotesStore',
  'loadingAnimationStore',
  'authenticationStore',
  'notificationStore',
  'selectPeopleStore',
  'managementAssetWarehouseStore'
)(observer(DistributionVotesPage))
