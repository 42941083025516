// Other
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Link, useHistory, withRouter } from 'react-router-dom'
import { 
  SelectList, 
  WorkPageWrapper, 
  UploadWorkListWrapper, 
  RenderFileList, 
} from './TextShoppingCouponStylePage'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { runInAction } from 'mobx'
import { apiUrl } from '../../config'
import useOpenItem from '../../hooks/useOpenItem'
import DrawerWork from '../WorkPage/DrawerWork/DrawerWork'
import PopupWorkForm from '../WorkPage/PopupWorkForm'
import PopupUpdateWork from '../WorkPage/PopupUpdateWork/PopupUpdateWork'
import PopupAddPersonWork from '../WorkPage/PopupAddPersonWork'
import PopupElectronicReleaseForm from '../WorkPage/PopupElectronicReleaseForm'
import { PopupAddPersonVote, PopupEditPersonVote } from '../WorkPage/PopupVote'
import { useParams } from 'react-router-dom'
import ShoppingCouponPurchaseComment from './ShoppingCouponPurchaseComment'
import ShoppingCouponPaymentComment from './ShoppingCouponPaymentComment'
// Ant design
import {
  Form,
  Input,
  Button,
  Steps,
  Card,
  Upload,
  Space,
  Row,
  Col,
  Typography,
  Popconfirm,
  Divider,
  Modal,
  ConfigProvider,
  Drawer,
  DatePicker,
  Layout,
  Select,
  Dropdown,
  Menu,
  Tooltip,
  List,
  Tag,
  message,
} from 'antd'
import {
  BankOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  GroupOutlined,
  HistoryOutlined,
  MoreOutlined,
  PlusOutlined,
  PrinterOutlined,
  TeamOutlined,
  UploadOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
} from '@ant-design/icons'
// Component
import PageTitle from '../../components/PageTitle'

// Other
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE, ASSIGNEE_TYPE, STATUS_DEFAULT } from '../../constants'
import ShoppingCouponTable from './ShoppingCouponTable'
import ShoppingCouponCreate from './ShoppingCouponCreate'
import { blue } from '../../color'
import { HeadingButtonWrapper } from '../WorkPage/DrawerWork/DrawerWorkStyled'
import InternalDocumentComment from '../Documents/InternalDocumentComment'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import { ButtonFooter } from '../WorkPage/PopupWorkForm/PopupWorkFormStyled'
import { AvatarHasName } from '../../components/Common/Avatar'
import PopoverWatchTime from '../../components/PopoverWatchTime'
import { EmptyText } from '../../components/Common/CellText'
import axios from 'axios'
import utils from '../../utils'
import ModalWorkLists from './ModalWorkLists'
import workScheduleStore from '../../stores/workScheduleStore'
import { CheckIcon } from '../WorkPage/WorkStyledPage'
import { grayPrimary, greenPrimary } from '../../color'
import FileItemV2 from '../../components/Common/FileItemComponent/FileItemV2'
import { SHOPPING_STATUS } from '../../constants'
import SelectPeoplePopup from '../../components/SelectPeoplePopup'

const { Step } = Steps
const { Title, Text } = Typography
const { Header, Content, Sider } = Layout
const { TextArea } = Input

const CreateRequestForm = props => {
  const {
    ShoppingCouponStore,
    shoppingId,
    handleChangeType,
    handleDisablePurchaseBrower,
    setIsDisablePurBrower,
    isDisablePurBrower,
    setIsDisablePayBrower,
    isDisablePayBrower,
    handleDisablePaymentBrower,
    handleDisableWorksAndAttachments,
    purWorks,
    setPurWorks,
    payWorks,
    setPayWorks,
    loadingAnimationStore,
    commandStore,
    handleNotification,
    selectPeopleStore,
  } = props
  const {
    shoppingCouponType,
  } = ShoppingCouponStore
  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleRemoveSelect,
    clearSelected,
    clearSelectUserData,
  } = selectPeopleStore
  const [steps, setSteps] = useState([
    { id: 1, name: 'User 1', role: 'Người tạo' },
    { id: 2, name: 'User 2', role: 'Người duyệt yêu cầu' },
    { id: 3, name: 'User 3', role: 'Người thẩm định' },
  ])
  const [current, setCurrent] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [newStep, setNewStep] = useState({ name: '', role: '' })
  const [currentStepType, setCurrentStepType] = useState('')
  const [
    isVisibleModalShopingCoupon,
    setIsVisibleModalShopingCoupon,
  ] = useState(false)

  const purchaseApproverWorkCodes = props.dataShopping?.purchaseApprover?.works.map(
    work => work.workCode
  )
  const paymentApproverWorkCodes = props.dataShopping?.paymentApprover?.works.map(
    work => work.workCode
  )
  const [currentStep, setCurrentStep] = useState(1)
  const [selectedTasksStep4, setSelectedTasksStep4] = useState([])
  const [selectedTasksStep5, setSelectedTasksStep5] = useState([])
  const [selectedRowKeysStep4, setSelectedRowKeysStep4] = useState(
    purchaseApproverWorkCodes
  )
  const [selectedRowKeysStep5, setSelectedRowKeysStep5] = useState(
    paymentApproverWorkCodes
  )
  const [selectedFilesStep4, setSelectedFilesStep4] = useState([])
  const [selectedFilesStep5, setSelectedFilesStep5] = useState([])
  const [purFiles, setPurFiles] = useState([])
  const [payFiles, setPayFiles] = useState([])
  const [data, setData] = useState(null)
  const [purName, setPurName] = useState([])
  const [payName, setPayName] = useState([])
  const [isUpdatePurchase, setIsUpdatePurchase] = useState(false)
  const [isUpdatePayment, setIsUpdatePayment] = useState(false)

  const handleWorkSelection = titles => {
    if (currentStep === 4) {
      setSelectedTasksStep4(titles)
    } else if (currentStep === 5) {
      setSelectedTasksStep5(titles)
    }
    setIsVisibleModalShopingCoupon(false)
  }
  const handleOpenModal = step => {
    setCurrentStep(step)
    setIsVisibleModalShopingCoupon(true)
  }

  const handleConfirmSelection = (selectedTitles, selectedRowKeys) => {
    if (currentStep === 4) {
      setSelectedTasksStep4(selectedTitles)
      setSelectedRowKeysStep4(selectedRowKeys)
    } else if (currentStep === 5) {
      setSelectedTasksStep5(selectedTitles)
      setSelectedRowKeysStep5(selectedRowKeys)
    }
    setIsVisibleModalShopingCoupon(false)
  }

  const handleUpload = (step, file) => {
    const fileName = file.name;
    const isDuplicate = (attachments) => {
      return attachments.some(existingFile => existingFile.file_name === fileName)
    }
    if (step === 4) {
      if(!isDuplicate(purFiles)) {
        setSelectedFilesStep4([...selectedFilesStep4, file])
      }
    } else if (step === 5) {
      if(!isDuplicate(payFiles)) {
        setSelectedFilesStep5([...selectedFilesStep5, file])
      }
    }
  }

  const handleDeletAttachment = async fileCode => {
    try {
      await ShoppingCouponStore.deleteAttachment(fileCode)
      message.success('Xóa file thành công')
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      setPurFiles(response.data.purchaseApprover.files)
      setPayFiles(response.data.paymentApprover.files)
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const response = await ShoppingCouponStore.getShoppingCouponDetail(
          shoppingId
        )
        setData(response.data)
        selectedApprove()
        if (props.userName === data?.purchaseApprover?.name) {
          setIsDisablePurBrower(handleDisablePurchaseBrower(response.data))
        } else {
          setIsDisablePurBrower(true)
        }
        if (props.userName === data?.paymentApprover?.name) {
          setIsDisablePayBrower(handleDisablePaymentBrower(response.data))
        } else {
          setIsDisablePayBrower(true)
        }
      } catch (error) {
        console.error('Error fetching shopping coupon detail:', error)
      }
    })()
  }, [shoppingId])

  useEffect(() => {
    if (data) {
      setPurWorks(data.purchaseApprover?.works)
      setPayWorks(data.paymentApprover?.works)
      setPurFiles(data.purchaseApprover?.files)
      setPayFiles(data.paymentApprover?.files)
    }
  }, [data])

  useEffect(() => {
    setPayName([
      ...selectUserData[ASSIGNEE_TYPE.PAY],
      ...selectDepartmentData[ASSIGNEE_TYPE.PAY],
      ...selectGroupData[ASSIGNEE_TYPE.PAY],
    ])
    setPurName([
      ...selectUserData[ASSIGNEE_TYPE.BUYER],
      ...selectDepartmentData[ASSIGNEE_TYPE.BUYER],
      ...selectGroupData[ASSIGNEE_TYPE.BUYER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const purchaseApprover = props.dataShopping.purchaseApprover
  const paymentApprover = props.dataShopping.paymentApprover

  const onFinishPurName = async () => {
    const formData = new FormData()
    formData.append(`purchaseApprover.code`, purchaseApprover.code)
    formData.append(
      'purchaseApprover.name',
      purName.map(name => name.id).join(',')
    )
    try {
      await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
      message.success('Thay đổi người duyệt mua sắm thành công!')
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      handleNotification(response.data)
      setData(response.data)
      setIsUpdatePurchase(false)
      props.setIsSelectUser(false)
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  const onFinishPurWorks = async () => {
    const formData = new FormData()
    const purWorkLength = purchaseApprover.works.length
    formData.append(`purchaseApprover.code`, purchaseApprover.code)
    selectedTasksStep4.forEach((work, workIndex) => {
      const index = purWorkLength + workIndex
      formData.append(`purchaseApprover.works[${index}].workCode`, work.code)
    })

    try {
      await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      message.success('Cập nhật thành công')
      setPurWorks(response.data.purchaseApprover.works)
      handleChangeType(response.data.type)
      setIsDisablePurBrower(handleDisablePurchaseBrower(response.data))
      ShoppingCouponStore.setShoppingCouponType(response.data.type)
      handleNotification(response.data)
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  const onFinishPurFiles = async () => {
    const formData = new FormData()
    const purFilesLength = purchaseApprover.files.length
    formData.append(`purchaseApprover.code`, purchaseApprover.code)
    selectedFilesStep4.forEach((file, fileIndex) => {
      const index = purFilesLength + fileIndex
      formData.append(`purchaseApprover.files[${index}].file`, file)
    })
    try {
      await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      message.success('Cập nhật thành công')
      setPurFiles(response.data.purchaseApprover.files)
      setSelectedFilesStep4([])
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  const onFinishPayName = async () => {
    const formData = new FormData()
    formData.append(`paymentApprover.code`, paymentApprover.code)
    formData.append(
      'paymentApprover.name',
      payName.map(name => name.id).join(',')
    )
    try {
      await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
      message.success('Thay đổi người duyệt thanh toán thành công!')
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      handleNotification(response.data)
      setData(response.data)
      setIsUpdatePayment(false)
      props.setIsSelectUser(false)
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  const onFinishPayWorks = async () => {
    const formData = new FormData()
    const payWorkLength = paymentApprover.works.length
    formData.append(`paymentApprover.code`, paymentApprover.code)
    selectedTasksStep5.forEach((work, workIndex) => {
      const index = payWorkLength + workIndex
      formData.append(`paymentApprover.works[${index}].workCode`, work.code)
    })
    try {
      await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      message.success('Cập nhật thành công')
      setPayWorks(response.data.paymentApprover.works)
      handleChangeType(response.data.type)
      setIsDisablePayBrower(handleDisablePaymentBrower(response.data))
      ShoppingCouponStore.setShoppingCouponType(response.data.type)
      handleNotification(response.data)
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  const onFinishPayFiles = async () => {
    const formData = new FormData()
    formData.append(`paymentApprover.code`, paymentApprover.code)
    selectedFilesStep5.forEach(file => {
      formData.append(`paymentApprover.files[${payFiles.length}].file`, file)
    })

    try {
      await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      message.success('Cập nhật thành công')
      setPayFiles(response.data.paymentApprover.files)
      setSelectedFilesStep5([])
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    if (purName.length > 0 && isUpdatePurchase && props.isSelectUser) {
      onFinishPurName()
    }
    if (payName.length > 0 && isUpdatePayment && props.isSelectUser) {
      onFinishPayName()
    }
    if (selectedTasksStep4.length > 0) {
      onFinishPurWorks()
    }
    if (selectedFilesStep4.length > 0) {
      onFinishPurFiles()
    }
    if (selectedTasksStep5.length > 0) {
      onFinishPayWorks()
    }
    if (selectedFilesStep5.length > 0) {
      onFinishPayFiles()
    }
    loadingAnimationStore.showSpinner(false)
  }, [
    selectedTasksStep4,
    selectedFilesStep4,
    selectedTasksStep5,
    selectedFilesStep5,
    purName, 
    payName,
  ])

  const handleBrowse = async code => {
    await ShoppingCouponStore.changeTypeProcurment(code)
    message.success('Duyệt yêu cầu thành công')
    const response = await ShoppingCouponStore.getShoppingCouponDetail(
      shoppingId
    )
    handleChangeType(response.data.type)
    handleNotification(response.data)
    ShoppingCouponStore.setShoppingCouponType(response.data.type)
    if (response.data.type === SHOPPING_STATUS.BOUGHT) {
      if (response.data.paymentApprover?.name === props.userName) {
        setIsDisablePayBrower(handleDisablePaymentBrower(response.data))
      } else {
        setIsDisablePayBrower(true)
      }
      setIsDisablePurBrower(true)
    } else {
      props.setIsDisableFinished(true)
    }
  
    if(response.data.type === SHOPPING_STATUS.PAID) {
      commandStore.decrementPendingShoppingRecord()
    }
  }

  const addStep = () => {
    setSteps([...steps, { id: steps.length + 1, ...newStep, isNew: true }])
    setNewStep({ name: '', role: '' })
    setIsModalVisible(false)
  }

  const removeStep = index => {
    if (steps.length > 3 && steps[index].isNew) {
      setSteps(steps.filter((_, i) => i !== index))
    }
  }

  const handleInputChange = e => {
    setNewStep({ ...newStep, [e.target.name]: e.target.value })
  }

  function getUserByUsername(username) {
    const user = props.finalUserData?.find(user => user.username === username)
    return user ? capitalizeFirstLetter(user.fullName) : null
  }

  function capitalizeFirstLetter(string) {
    return string
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  function findTitleByCode(works, data) {
    const workCodes = new Set(works?.map(work => work.workCode))
    const titles = data
      ?.filter(item => workCodes.has(item.code))
      ?.map(item => item.title)
    return titles
  }

  const wData = props.workData

  const selectedApprove = () => {
    let purchaseApproveName = purchaseApprover.name
    if (purchaseApproveName !== '') {
        purName.push({
            assignee_type: ASSIGNEE_TYPE.USER,
            full_name: getUserByUsername(purchaseApproveName),
            id: purchaseApproveName,
            image_id: getUserByUsername(purchaseApproveName),
            permission: ASSIGNEE_TYPE.BUYER,
        })
    }
    
    let paymentApproveName = paymentApprover.name
    if (paymentApproveName !== '') {
        payName.push({
            assignee_type: ASSIGNEE_TYPE.USER,
            full_name: getUserByUsername(paymentApproveName),
            id: paymentApproveName,
            image_id: getUserByUsername(paymentApproveName),
            permission: ASSIGNEE_TYPE.PAY,
        })
    }

    const updatedSelect = {
        ...selectUserData,
        ...(purName.length > 0 && { BUYER: purName }),
        ...(payName.length > 0 && { PAY: payName }), 
    };
    selectPeopleStore.setSelectUserData(updatedSelect);
}
  return (
    <>
      <Form layout="vertical">
        <Row gutter={24}>
          <Col span={1} />
          <Col span={11}>
            <Title level={5}>Mua sắm</Title>
            <Row align="middle" style={{color: 'rgba(0, 0, 0, 0.85)'}}>
              <Col span={22}>
                <div>
                  <span>
                    Người duyệt mua sắm:
                  </span>
                  <Space style={{ marginLeft: 20, marginBottom: 10 }}>
                    {shoppingCouponType && shoppingCouponType !== SHOPPING_STATUS.BOUGHT &&  
                      shoppingCouponType != SHOPPING_STATUS.PAID && (
                        <Tooltip title="Chọn người dùng" color={blue}>
                          <Button
                            icon={<UserOutlined style={{ color: blue }} />}
                            onClick={() => {
                              setIsUpdatePurchase(true)
                              handleOpenSelectUserDepartmentPopup(
                                ASSIGNEE_TYPE.BUYER
                            )
                            }}  
                          />
                        </Tooltip>
                      )}
                  </Space>
                  <SelectList>
                    {purName && purName.map(el => (
                      <Tag
                        className="tag-selected"
                        // color={'#e6f7ff'}
                        key={el.id}
                        onClose={() => handleRemoveSelect(el)}>
                          <AvatarHasName
                            imgId={el.imageId}
                            size={22}
                            name={el.full_name}                                        
                          />
                      </Tag>
                    ))}
                  </SelectList>
                </div>
                <span>Công việc liên quan: </span>
                <span style={{ marginLeft: '20px' }}></span>
                <Button
                  size={'small'}
                  onClick={() => handleOpenModal(4)}
                  icon={<CopyOutlined style={{ color: props.isDisableFinished ||
                    handleDisableWorksAndAttachments(
                      data?.purchaseApprover?.name
                    ) ? '#00000040' : '#2C65AC'}} />}
                  disabled={
                    props.isDisableFinished ||
                    handleDisableWorksAndAttachments(
                      data?.purchaseApprover?.name
                    )
                  }>
                  Công việc
                </Button>
                <br />
                {purWorks.length === 0 ? (
                  <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                    Chưa có công việc nào
                  </p>
                ) : (
                  purWorks.map((work, index) => (
                    <li
                      key={index}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                      onClick={() => {
                        props.onOpenDrawerWork(
                          work.workCode,
                          data?.purchaseApprover?.name
                        )
                        props.setApproveCode(
                          props.dataShopping.purchaseApprover?.code
                        )
                      }}>
                      <div style={{ textAlign: 'center', marginTop: 10, paddingRight: 2}}>
                        {work.status === STATUS_DEFAULT.COMPLETE ? (
                          <CheckCircleFilled
                            style={{ color: greenPrimary, fontSize: 14 }}
                          />
                        ) : (
                          <CheckCircleOutlined
                            style={{ color: '#595959', fontSize: 14 }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          marginLeft: '5px',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            maxWidth: '100%',
                            wordWrap: 'break-word',
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                          }}>
                          <UploadWorkListWrapper>
                            <div className='work-list' title={work.workName}>
                              {work.workName}
                            </div>
                          </UploadWorkListWrapper>                     
                        </div>
                      </div>
                    </li>
                  ))
                )}

                <br />
                <span>Tài liệu đính kèm: </span>
                <span style={{ marginLeft: '20px' }}></span>
                <Upload
                  onRemove={() => {}}
                  beforeUpload={file => {
                    handleUpload(4, file)
                    return false
                  }}
                  showUploadList={false}>
                  <Button
                    icon={<UploadOutlined />}
                    disabled={
                      props.isDisableFinished ||
                      handleDisableWorksAndAttachments(
                        data?.purchaseApprover?.name
                      )
                    }></Button>
                </Upload>
                <div style={{marginTop: 10}}>
                  {purFiles.length === 0 ? (
                    <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                      Chưa có tài liệu nào
                    </p>
                  ) : (
                    purFiles.map((item, index) => (
                      <div key={index}>
                        {props.userName === data?.purchaseApprover?.name ? (
                          <RenderFileList>
                            <div key={index} className='file-container'>
                              <Row>
                                <Col span={22}>
                                  <FileItemV2
                                    key={item.file_id}
                                    file_id={item.fileCode}
                                    file_name={item.file_name}
                                    file_type={utils.getExtensionFile(
                                      item.file_name
                                    )}
                                    file_code={item.fileCode}
                                  />
                                </Col>                               
                                <Col span={2}>
                                  <span onClick={() => handleDeletAttachment(item.fileCode)}>
                                      <DeleteOutlined  
                                        className='delete-icon'
                                        style={{ fontSize: 12, cursor: 'pointer', color: '#999' }}/>
                                  </span>
                                </Col>                               
                              </Row>
                            </div>
                          </RenderFileList>
                        ) : (
                          <div
                            style={{
                              marginTop: '10px'
                            }}>
                            <FileItemV2
                              key={item.file_id}
                              file_id={item.fileCode}
                              file_name={item.file_name}
                              file_type={utils.getExtensionFile(
                                item.file_name
                              )}
                              file_code={item.fileCode}                     
                            />
                          </div>
                        )}
                      </div>
                    ))
                  )}
                </div>
                
              </Col>
            </Row>
            <Row>
              <Col span={16}></Col>
              <Col>
                {!(isDisablePurBrower || props.isDisableFinished) && (
                  <Button
                    type={'primary'}
                    onClick={() => handleBrowse(purchaseApprover.code)}
                  >
                    Duyệt yêu cầu
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={11}>
            <Row align="middle">
              <Col span={24}>
                <ContentBlockWrapper
                  style={{ position: 'relative', border: '1px solid #dcdcdc' }}>
                  <ShoppingCouponPurchaseComment
                    shoppingId={shoppingId}
                    type={'BUY'}
                    approveCode={purchaseApprover.code}
                    approveName={purchaseApprover.name}
                  />
                  <SpinnerInlineComponent sizeSpin={'small'} />
                </ContentBlockWrapper>
              </Col>
            </Row>
          </Col>
          <Col span={1} />
        </Row>
        <Row gutter={24}>
          <Col span={1}></Col>
          <Col span={22}><Divider /></Col>
          <Col span={1}></Col>
        </Row>
        <Row gutter={24}>
          <Col span={1} />
          <Col span={11}>
            <Title level={5}>Thanh toán</Title>
            <Row align="middle" style={{color: 'rgba(0, 0, 0, 0.85)'}}>
              <Col span={21}>
              <div>
                  <span>
                    Người duyệt thanh toán:
                  </span>
                  <Space style={{ marginLeft: 20, marginBottom: 10 }}>
                    {data && data.type != SHOPPING_STATUS.PAID && (
                        <Tooltip title="Chọn người dùng" color={blue}>
                          <Button
                            icon={<UserOutlined style={{ color: blue }} />}
                            onClick={() => {
                              setIsUpdatePayment(true)
                              handleOpenSelectUserDepartmentPopup(
                                ASSIGNEE_TYPE.PAY
                            )}}
                          />
                        </Tooltip>
                      )}
                  </Space>
                  <SelectList>
                    {payName && payName.map(el => (
                      <Tag
                        className="tag-selected"
                        // color={'#e6f7ff'}
                        key={el.id}
                        onClose={() => handleRemoveSelect(el)}>
                          <AvatarHasName
                            imgId={el.imageId}
                            size={22}
                            name={el.full_name}                                        
                          />
                      </Tag>
                    ))}
                  </SelectList>
                </div>
                <span>Công việc liên quan: </span>
                <span style={{ marginLeft: '20px' }}></span>
                <Button
                  size={'small'}
                  onClick={() => handleOpenModal(5)}
                  icon={<CopyOutlined style={{ color: props.isDisableFinished ||
                    handleDisableWorksAndAttachments(
                      data?.paymentApprover?.name
                    ) ? '#00000040' : '#2C65AC'}} />}
                  disabled={
                    props.isDisableFinished ||
                    handleDisableWorksAndAttachments(
                      data?.paymentApprover?.name
                    )
                  }>
                  Công việc
                </Button>
                <br />
                {payWorks.length === 0 ? (
                  <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                    Chưa có công việc nào
                  </p>
                ) : (
                  payWorks.map((work, index) => (
                    <li
                      key={index}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%'
                      }}
                      onClick={() => {
                        props.onOpenDrawerWork(
                          work.workCode,
                          data?.paymentApprover?.name
                        )
                        props.setApproveCode(
                          props.dataShopping.paymentApprover?.code
                        )
                      }}>
                      <div style={{ textAlign: 'center', marginTop: 10, paddingRight: 2}}>
                        {work.status === STATUS_DEFAULT.COMPLETE ? (
                          <CheckCircleFilled
                            style={{ color: greenPrimary, fontSize: 14 }}
                          />
                        ) : (
                          <CheckCircleOutlined
                            style={{ color: '#595959', fontSize: 14 }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          marginLeft: '5px',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            maxWidth: '100%',
                            wordWrap: 'break-word',
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                          }}>
                          <UploadWorkListWrapper>
                            <div className='work-list' title={work.workName}>
                              {work.workName}
                            </div>
                          </UploadWorkListWrapper>
                        </div>
                      </div>
                    </li>
                  ))
                )}
                <br />
                <span>Tài liệu đính kèm: </span>
                <span style={{ marginLeft: '20px' }}></span>
                <Upload
                  onRemove={() => {}}
                  beforeUpload={file => {
                    handleUpload(5, file)
                    return false
                  }}
                  showUploadList={false}>
                  <Button
                    icon={<UploadOutlined />}
                    disabled={
                      props.isDisableFinished ||
                      handleDisableWorksAndAttachments(
                        data?.paymentApprover?.name
                      )
                    }></Button>
                </Upload>
                <div style={{marginTop: 10}}> 
                  {payFiles.length === 0 ? (
                    <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                      Chưa có tài liệu nào
                    </p>
                  ) : (
                    payFiles.map((item, index) => (
                      <div key={index}>
                        {props.userName === data?.paymentApprover?.name ? (
                          <RenderFileList>
                            <div key={index} className='file-container'>
                              <Row>
                                <Col span={22}>
                                  <FileItemV2
                                    key={item.file_id}
                                    file_id={item.fileCode}
                                    file_name={item.file_name}
                                    file_type={utils.getExtensionFile(
                                      item.file_name
                                    )}
                                    file_code={item.fileCode}
                                  />
                                </Col>                               
                                <Col span={2}>
                                  <span onClick={() => handleDeletAttachment(item.fileCode)}>
                                      <DeleteOutlined  
                                        className='delete-icon'
                                        style={{ fontSize: 12, cursor: 'pointer', color: '#999' }}/>
                                  </span>
                                </Col>                               
                              </Row>
                            </div>
                          </RenderFileList>
                        ) : (
                          <div
                            style={{
                              marginTop: '10px'
                            }}>
                            <FileItemV2
                              key={item.file_id}
                              file_id={item.fileCode}
                              file_name={item.file_name}
                              file_type={utils.getExtensionFile(
                                item.file_name
                              )}
                              file_code={item.fileCode}                     
                            />
                          </div>
                        )}
                      </div>
                    ))
                  )}
                </div>              
              </Col>
            </Row>
            <Row>
              <Col span={16}></Col>
              <Col>
                {!(isDisablePayBrower || props.isDisableFinished) && (
                  <Button
                    type={'primary'}
                    onClick={() => handleBrowse(paymentApprover.code)}
                  >
                    Duyệt yêu cầu
                  </Button>
                )}               
              </Col>
            </Row>
          </Col>
          <Col span={11}>
            <Row align="middle">
              <Col span={24}>
                <ContentBlockWrapper
                  style={{ position: 'relative', border: '1px solid #dcdcdc' }}>
                  <ShoppingCouponPaymentComment
                    shoppingId={shoppingId}
                    type={'PAY'}
                    approveCode={paymentApprover.code}
                    approveName={paymentApprover.name}
                  />
                  <SpinnerInlineComponent sizeSpin={'small'} />
                </ContentBlockWrapper>
              </Col>
            </Row>
          </Col>
          <Col span={1} />
        </Row>
      </Form>
      <SelectPeoplePopup
        onlyOneUser
        noLoadSelectData={false}
        disableSelectDepartment
        isOpenCollapse={true}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        isUserDisableList={false}
        setIsSelectUser={props.setIsSelectUser}
      />
      <ModalWorkLists
        isVisibleModalShoppingCoupon={isVisibleModalShopingCoupon}
        handleCloseModal={() => setIsVisibleModalShopingCoupon(false)}
        currentStepType={currentStepType}
        onConfirmSelection={handleConfirmSelection}
        shoppingTasks={selectedRowKeysStep4}
        paymentTasks={selectedRowKeysStep5}
        onWorkSelect={handleWorkSelection}
        currentStep={currentStep}
      />
    </>
  )
}

const PurchasePaymentForm = props => {
  const {
    ShoppingCouponStore,
    shoppingId,
    handleChangeType,
    handleDisableWorksAndAttachments,
    reqWorks,
    setReqWorks,
    evaluatorList,
    setEvaluatorList,
    loadingAnimationStore,
    handleNotification,
    selectPeopleStore,
  } = props
  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleRemoveSelect,
    clearSelected,
    clearSelectUserData,
  } = selectPeopleStore
  const {
    shoppingCouponType,
  } = ShoppingCouponStore

  const [steps, setSteps] = useState([
    { id: 1, name: 'User 1', role: 'Người tạo' },
    { id: 2, name: 'User 2', role: 'Người duyệt yêu cầu' },
    { id: 3, name: 'User 3', role: 'Người thẩm định' },
  ])
  const [form] = Form.useForm()
  const [current, setCurrent] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [newStep, setNewStep] = useState({ name: '', role: '' })
  const [currentStepType, setCurrentStepType] = useState('')
  const [currentStep, setCurrentStep] = useState(1)
  const [
    isVisibleModalShopingCoupon,
    setIsVisibleModalShopingCoupon,
  ] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selectedTasksStep2, setSelectedTasksStep2] = useState([])
  const [selectedTasksStep3, setSelectedTasksStep3] = useState([])
  const [selectedRowKeysStep2, setSelectedRowKeysStep2] = useState([])
  const [selectedRowKeysStep3, setSelectedRowKeysStep3] = useState([])
  const [selectedFilesStep2, setSelectedFilesStep2] = useState([])
  const [selectedFilesStep3, setSelectedFilesStep3] = useState([])
  const evaluatorLength = props.dataShopping.evaluatorList.length - 1
  const initialSelectedRowKeysSubStep3 = props.dataShopping.evaluatorList
    .slice(1)
    .map(evaluator => evaluator.works.map(work => work.workCode))
  const initialSelectedTasksSubStep3 = Array.from(
    { length: evaluatorLength },
    () => []
  )
  const initialSelectedFilesSubStep3 = Array.from(
    { length: evaluatorLength },
    () => []
  )
  const [selectedRowKeysSubStep3, setSelectedRowKeysSubStep3] = useState(
    initialSelectedRowKeysSubStep3
  )
  const [selectedTasksSubStep3, setSelectedTasksSubStep3] = useState(
    initialSelectedTasksSubStep3
  )
  const [selectedFilesSubStep3, setSelectedFilesSubStep3] = useState(
    initialSelectedFilesSubStep3
  )
  const [data, setData] = useState(null)
  const [reqFiles, setReqFiles] = useState([])
  const [reqName, setReqName] = useState([])
  const [apraiser, setApraiser] = useState([])
  const [firstSubApraiserList, setFirstSubApraiserList] = useState([]);
  const [secondSubApraiserList, setSecondSubApraiserList] = useState([]);
  const [thirdSubApraiserList, setThirdSubApraiserList] = useState([]);
  const [fourthSubApraiserList, setFourthSubApraiserList] = useState([]);
  const [fifthSubApraiserList, setFifthSubApraiserList] = useState([]);
  const [sixthSubApraiserList, setSixthSubApraiserList] = useState([]);
  const [seventhSubApraiserList, setSeventhSubApraiserList] = useState([]);
  const [eighthSubApraiserList, setEighthSubApraiserList] = useState([]);
  const [ninthSubApraiserList, setNinthSubApraiserList] = useState([]);
  const [isApprovalNameUpdate, setIsApprovalNameUpdate] = useState(false)
  const [userUpdate, setUserUpdate] = useState('')
  useEffect(() => {
    ;(async () => {
      try {
        const response = await ShoppingCouponStore.getShoppingCouponDetail(
          shoppingId
        )
        setData(response.data)
        selectedApprove()
      } catch (error) {
        console.error('Error fetching shopping coupon detail:', error)
      }
    })()
  }, [shoppingId])

  useEffect(() => {
    if (data) {
      setReqWorks(data.requestApprover?.works || [])
      setReqFiles(data.requestApprover?.files || [])
      setEvaluatorList(data.evaluatorList || [])
    }
  }, [data])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    if (selectedTasksStep2.length > 0) {
      onFinishReqWorks()
    }

    if (selectedFilesStep2.length > 0) {
      onFinishReqFiles()
    }

    if (selectedTasksStep3.length > 0) {
      onFinishEval1stWorks()
    }

    if (selectedFilesStep3.length > 0) {
      onFinishEval1stFiles()
    }

    evaluatorList.forEach((_, index) => {
      if (index < evaluatorList.length - 1) {
        if (selectedTasksSubStep3[index]?.length > 0) {
          onFinishSubEvaWorks()
        }
        if (selectedFilesSubStep3[index]?.length > 0) {
          onFinishSubEvaFiles()
        }
      }
    })
    loadingAnimationStore.showSpinner(false)
  }, [
    selectedTasksStep2,
    selectedFilesStep2,
    selectedTasksStep3,
    selectedFilesStep3,
    selectedTasksSubStep3,
    selectedFilesSubStep3,
  ])

  useEffect(() => {
    if (reqName.length > 0 && userUpdate === 'approval' && props.isSelectUser) {
      onFinishReqName();
    }
  }, [reqName]);

  useEffect(() => {
    if (apraiser.length > 0 && userUpdate === 'apraiser_0' 
      && props.isSelectUser) {
      onFinishEval1stName();
    }
  }, [apraiser]);

  useEffect(() => {
    if (firstSubApraiserList.length > 0 && userUpdate === 'apraiser_1'
      && props.isSelectUser) {
      onFinishSubEvaName(0);  
    }
  }, [firstSubApraiserList]);
  
  useEffect(() => {
    if (secondSubApraiserList.length > 0 && userUpdate === 'apraiser_2'
      && props.isSelectUser) {
      onFinishSubEvaName(1); 
    }
  }, [secondSubApraiserList]);
  
  useEffect(() => {
    if (thirdSubApraiserList.length > 0 && userUpdate === 'apraiser_3'
      && props.isSelectUser) {
      onFinishSubEvaName(2);  
    }
  }, [thirdSubApraiserList]);
  
  useEffect(() => {
    if (fourthSubApraiserList.length > 0 && userUpdate === 'apraiser_4'
      && props.isSelectUser) {
      onFinishSubEvaName(3);
    }
  }, [fourthSubApraiserList]);
  
  useEffect(() => {
    if (fifthSubApraiserList.length > 0 && userUpdate === 'apraiser_5'
      && props.isSelectUser) {
      onFinishSubEvaName(4);
    }
  }, [fifthSubApraiserList]);
  
  useEffect(() => {
    if (sixthSubApraiserList.length > 0 && userUpdate === 'apraiser_6'
      && props.isSelectUser) {
      onFinishSubEvaName(5);
    }
  }, [sixthSubApraiserList]);
  
  useEffect(() => {
    if (seventhSubApraiserList.length > 0 && userUpdate === 'apraiser_7'
      && props.isSelectUser) {
      onFinishSubEvaName(6);
    }
  }, [seventhSubApraiserList]);
  
  useEffect(() => {
    if (eighthSubApraiserList.length > 0 && userUpdate === 'apraiser_8'
      && props.isSelectUser) {
      onFinishSubEvaName(7);
    }
  }, [eighthSubApraiserList]);
  
  useEffect(() => {
    if (ninthSubApraiserList.length > 0 && userUpdate === 'apraiser_9'
      && props.isSelectUser) {
      onFinishSubEvaName(8);
    }
  }, [ninthSubApraiserList]);

  useEffect(() => {
    setReqName([
      ...selectUserData[ASSIGNEE_TYPE.APPROVAL],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPROVAL],
      ...selectGroupData[ASSIGNEE_TYPE.APPROVAL],
    ])

    setApraiser([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER],
    ])
    setFirstSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_1],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_1],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_1],
    ])
    setSecondSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_2],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_2],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_2],
    ])
    setThirdSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_3],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_3],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_3],
    ])
    setFourthSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_4],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_4],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_4],
    ])
    setFifthSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_5],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_5],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_5],
    ])
    setSixthSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_6],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_6],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_6],
    ])
    setSeventhSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_7],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_7],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_7],
    ])
    setEighthSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_8],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_8],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_8],
    ])
    setNinthSubApraiserList([
      ...selectUserData[ASSIGNEE_TYPE.APPRAISER_9],
      ...selectDepartmentData[ASSIGNEE_TYPE.APPRAISER_9],
      ...selectGroupData[ASSIGNEE_TYPE.APPRAISER_9],
    ])

  }, [selectUserData, selectDepartmentData, selectGroupData])

  const selectedApprove = () => {
    const { requestApprover, evaluatorList } = props.dataShopping;
  
    const addAssignee = (list, name, type) => {
      if (name !== '') {
        list.push({
          assignee_type: ASSIGNEE_TYPE.USER,
          full_name: getUserByUsername(name),
          id: name,
          image_id: getUserByUsername(name),
          permission: type,
        });
      }
    }
  
    const reqName = []
    addAssignee(reqName, requestApprover.name, ASSIGNEE_TYPE.APPROVAL);
  
    if (reqName.length > 0) {
      selectPeopleStore.setSelectUserData({
        ...selectUserData,
        APPROVAL: reqName,
      })
    }
  
    const apraiser = [];
    addAssignee(apraiser, evaluatorList[0]?.name, ASSIGNEE_TYPE.APPRAISER);
    
    if (apraiser.length > 0) {
      selectPeopleStore.setSelectUserData({
        ...selectUserData,
        APPRAISER: apraiser,
      })
    }
  
    const evaluatorTypes = [
      ASSIGNEE_TYPE.APPRAISER_1,
      ASSIGNEE_TYPE.APPRAISER_2,
      ASSIGNEE_TYPE.APPRAISER_3,
      ASSIGNEE_TYPE.APPRAISER_4,
      ASSIGNEE_TYPE.APPRAISER_5,
      ASSIGNEE_TYPE.APPRAISER_6,
      ASSIGNEE_TYPE.APPRAISER_7,
      ASSIGNEE_TYPE.APPRAISER_8,
      ASSIGNEE_TYPE.APPRAISER_9
    ]
    const subApraiserList = [
      firstSubApraiserList,
      secondSubApraiserList, 
      thirdSubApraiserList,
      fourthSubApraiserList,
      fifthSubApraiserList,
      sixthSubApraiserList, 
      seventhSubApraiserList,
      eighthSubApraiserList,
      ninthSubApraiserList
    ]
  
    evaluatorTypes.forEach((type, index) => {
      const evaluatorListName = evaluatorList[index + 1]?.name;
      addAssignee(subApraiserList[index], evaluatorListName, type);
  
      if (subApraiserList[index].length > 0) {
        selectPeopleStore.setSelectUserData({
          ...selectUserData,
          [type]: subApraiserList[index],
        })
      }
    })

    const updatedSelect = {
      ...selectUserData,
      ...(reqName.length > 0 && { APPROVAL: reqName }),
      ...(apraiser.length > 0 && { APPRAISER: apraiser }), 
      ...(firstSubApraiserList.length > 0 && {APPRAISER_1: firstSubApraiserList}),
      ...(secondSubApraiserList.length > 0 && {APPRAISER_2: secondSubApraiserList}),
      ...(thirdSubApraiserList.length > 0 && { APPRAISER_3: thirdSubApraiserList }),
      ...(fourthSubApraiserList.length > 0 && { APPRAISER_4: fourthSubApraiserList }),
      ...(fifthSubApraiserList.length > 0 && { APPRAISER_5: fifthSubApraiserList }),
      ...(sixthSubApraiserList.length > 0 && { APPRAISER_6: sixthSubApraiserList }),
      ...(seventhSubApraiserList.length > 0 && { APPRAISER_7: seventhSubApraiserList }),
      ...(eighthSubApraiserList.length > 0 && { APPRAISER_8: eighthSubApraiserList }),
      ...(ninthSubApraiserList.length > 0 && { APPRAISER_9: ninthSubApraiserList })
    }
    selectPeopleStore.setSelectUserData(updatedSelect);
  }

  const handleWorkSelection = titles => {
    if (currentStep === 2) {
      setSelectedTasksStep2(titles)
    } else if (currentStep === 3) {
      setSelectedTasksStep3(titles)
    } else {
      const stepIndex = currentStep - 6
      if (stepIndex >= 0 && stepIndex < selectedTasksSubStep3.length) {
        const updatedTasks = [...selectedTasksSubStep3]
        updatedTasks[stepIndex] = titles
        setSelectedTasksSubStep3(updatedTasks)
      }
    }
    setIsVisibleModalShopingCoupon(false)
  }

  const handleConfirmSelection = (selectedTitles, selectedRowKeys) => {
    if (currentStep === 2) {
      setSelectedTasksStep2(selectedTitles)
      setSelectedRowKeysStep2(selectedRowKeys)
    } else if (currentStep === 3) {
      setSelectedTasksStep3(selectedTitles)
      setSelectedRowKeysStep3(selectedRowKeys)
    } else {
      const stepIndex = currentStep - 6
      if (stepIndex >= 0 && stepIndex < selectedTasksSubStep3.length) {
        const updatedTasks = [...selectedTasksSubStep3]
        const updatedRowKeys = [...selectedRowKeysSubStep3]
        updatedTasks[stepIndex] = selectedTitles
        setSelectedTasksSubStep3(updatedTasks)
        setSelectedRowKeysSubStep3(updatedRowKeys)
      }
    }
    setIsVisibleModalShopingCoupon(false)
  }

  const handleOpenModal = step => {
    setCurrentStep(step)
    setIsVisibleModalShopingCoupon(true)
  }

  const handleUpload = (step, file) => {
    const fileName = file.name;
    const isDuplicate = (attachments) => {
      return attachments.some(existingFile => existingFile.file_name === fileName)
    }
    if (step === 2) {
      if (!isDuplicate(reqFiles)) {
        setSelectedFilesStep2([...selectedFilesStep2, file])
      }
    } else if (step === 3) {
      if (!isDuplicate(evaluatorList[0].files)) {
        setSelectedFilesStep3([...selectedFilesStep3, file])
      }
    } else {
      const stepIndex = step - 6
      if (!isDuplicate(evaluatorList[stepIndex + 1])) {
        const newSelectedFilesSubStep3 = [...selectedFilesSubStep3]
        if (!newSelectedFilesSubStep3[stepIndex]) {
          newSelectedFilesSubStep3[stepIndex] = []
        }
        newSelectedFilesSubStep3[stepIndex] = [
          ...newSelectedFilesSubStep3[stepIndex],
          file,
        ]
        setSelectedFilesSubStep3(newSelectedFilesSubStep3)
      }
    }
  }

  const handleDeletAttachment = async fileCode => {
    try {
      await ShoppingCouponStore.deleteAttachment(fileCode)
      message.success('Xóa file thành công')
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      setReqFiles(response.data.requestApprover.files)
      setEvaluatorList(response.data.evaluatorList)
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key="1">Sửa công việc</Menu.Item>
      <Menu.Item key="2">Thêm người</Menu.Item>
      <Menu.Item key="3">Phát hành điện tử</Menu.Item>
      <Menu.Item key="4">Lấy ý kiến</Menu.Item>
      <Menu.Item key="5">Lịch sử</Menu.Item>
      <Menu.Item key="6">In</Menu.Item>
      <Menu.Item key="7" danger>
        Xóa công việc
      </Menu.Item>
    </Menu>
  )

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const addStep = () => {
    setSteps([...steps, { id: steps.length + 1, ...newStep, isNew: true }])
    setNewStep({ name: '', role: '' })
    setIsModalVisible(false)
  }

  const removeStep = index => {
    if (steps.length > 3 && steps[index].isNew) {
      setSteps(steps.filter((_, i) => i !== index))
    }
  }

  const handleInputChange = e => {
    setNewStep({ ...newStep, [e.target.name]: e.target.value })
  }

  function getUserByUsername(username) {
    const user = props.finalUserData?.find(user => user.username === username)
    return user ? capitalizeFirstLetter(user.fullName) : null
  }

  function capitalizeFirstLetter(string) {
    return string
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const approver = props.dataShopping.requestApprover
  const wData = props.workData

  function findTitleByCode(works, data) {
    const workCodes = new Set(works?.map(work => work.workCode))
    const titles = data
      ?.filter(item => workCodes.has(item.code))
      ?.map(item => item.title)
    return titles
  }

  const onFinishReqName = async () => {
    const formData = new FormData()
    formData.append(`requestApprover.code`, approver.code)
    formData.append(
      'requestApprover.name',
      reqName.map(name => name.id).join(',')
    )
    try {
      await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      setData(response.data)
      handleNotification(response.data)
      message.success("Thay đổi người duyệt yêu cầu thành công")
      props.setIsSelectUser(false)
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  const onFinishReqWorks = async () => {
    const formData = new FormData()
    const reqWorkLength = approver.works.length
    formData.append(`requestApprover.code`, approver.code)
    selectedTasksStep2.forEach((work, workIndex) => {
      const index = reqWorkLength + workIndex
      formData.append(`requestApprover.works[${index}].workCode`, work.code)
    })

    try {
      await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
      message.success('Cập nhật thành công')
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      setReqWorks(response.data.requestApprover.works)
      handleChangeType(response.data.type)
      ShoppingCouponStore.setShoppingCouponType(response.data.type)
      handleNotification(response.data)
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  const onFinishReqFiles = async () => {
    const formData = new FormData()
    const reqFilesLength = approver.files.length
    formData.append(`requestApprover.code`, approver.code)
    selectedFilesStep2.forEach((file, fileIndex) => {
      const index = fileIndex + reqFilesLength
      formData.append(`requestApprover.files[${index}].file`, file)
    })
    try {
      await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
      message.success('Cập nhật thành công')
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      setReqFiles(response.data.requestApprover.files)
      setSelectedFilesStep2([])
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  const onFinishEval1stName = async () => {
    const formData = new FormData()
    formData.append(`evaluatorList[0].code`, evaluatorList[0].code)
    formData.append(
      'evaluatorList[0].name',
      apraiser.map(name => name.id).join(',')
    )
    try {
      await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      setData(response.data)
      handleNotification(response.data)
      message.success("Thay đổi người thẩm định thành công")
      props.setIsSelectUser(false)
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  const onFinishEval1stWorks = async () => {
    const formData = new FormData()
    const evl1stWorkLength = evaluatorList[0].works.length
    formData.append(`evaluatorList[0].code`, evaluatorList[0].code)
    selectedTasksStep3.forEach((work, workIndex) => {
      const index = evl1stWorkLength + workIndex
      formData.append(`evaluatorList[0].works[${index}].workCode`, work.code)
    })

    try {
      await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
      message.success('Cập nhật thành công')
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      setEvaluatorList(response.data.evaluatorList)
      handleChangeType(response.data.type)
      setData(response.data)
      handleNotification(response.data)
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  const onFinishEval1stFiles = async () => {
    const formData = new FormData()
    const eva1stFilesLength = evaluatorList[0].files.length
    formData.append(`evaluatorList[0].code`, evaluatorList[0].code)
    selectedFilesStep3.forEach((file, fileIndex) => {
      const index = eva1stFilesLength + fileIndex
      formData.append(`evaluatorList[0].files[${index}].file`, file)
    })

    try {
      await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
      message.success('Cập nhật thành công')
      const response = await ShoppingCouponStore.getShoppingCouponDetail(
        shoppingId
      )
      setEvaluatorList(response.data.evaluatorList)
      setSelectedFilesStep3([])
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'đã có lỗi xảy ra!!')
    }
  }

  const onFinishSubEvaName = async (index) => {
    const subAppraiserLists = [
      firstSubApraiserList,
      secondSubApraiserList,
      thirdSubApraiserList,
      fourthSubApraiserList,
      fifthSubApraiserList,
      sixthSubApraiserList,
      seventhSubApraiserList,
      eighthSubApraiserList,
      ninthSubApraiserList,
    ];
  
    if (index >= 0 && index < evaluatorList.length - 1) {
      const formData = new FormData();
      const evaluatorIndex = index + 1;
  
      formData.append(`evaluatorList[${evaluatorIndex}].code`, evaluatorList[evaluatorIndex].code);
      formData.append(
        `evaluatorList[${evaluatorIndex}].name`,
        subAppraiserLists[index].map(name => name.id).join(',') // Join the appraiser IDs as a string
      );
  
      try {
        await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData);
        const response = await ShoppingCouponStore.getShoppingCouponDetail(shoppingId);
        
        setData(response.data);
        handleNotification(response.data);
        props.setIsSelectUser(false)
        message.success("Thay đổi người thẩm định thành công");
      } catch (error) {
        console.log(error);
        message.error(error.vi || 'An error occurred!');
      }
    } else {
      console.error('Invalid index:', index);
    }
  }

  const onFinishSubEvaWorks = async () => {
    for (let index = 0; index < evaluatorList.length - 1; index++) {
      if (selectedTasksSubStep3[index].length > 0) {
        const formData = new FormData()
        const evaluatorIndex = index + 1
        const workLength = evaluatorList[evaluatorIndex].works.length
        formData.append(
          `evaluatorList[${evaluatorIndex}].code`,
          evaluatorList[evaluatorIndex].code
        )
        selectedTasksSubStep3[index].forEach((work, workIndex) => {
          const w_index = workLength + workIndex
          formData.append(
            `evaluatorList[${evaluatorIndex}].works[${w_index}].workCode`,
            work.code
          )
        })

        try {
          await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
          message.success('Cập nhật thành công')
          const response = await ShoppingCouponStore.getShoppingCouponDetail(
            shoppingId
          )
          setEvaluatorList(response.data.evaluatorList)
          handleChangeType(response.data.type)
          setData(response.data)
          handleNotification(response.data)
        } catch (error) {
          console.log(error)
          message.error(error.vi || 'đã có lỗi xảy ra!!')
        }
      }
    }
  }

  const onFinishSubEvaFiles = async () => {
    for (let index = 0; index < evaluatorList.length - 1; index++) {
      if (selectedFilesSubStep3[index].length > 0) {
        const formData = new FormData()
        const evaluatorIndex = index + 1
        const filesLength = evaluatorList[evaluatorIndex].files.length
        formData.append(
          `evaluatorList[${evaluatorIndex}].code`,
          evaluatorList[evaluatorIndex].code
        )
        selectedFilesSubStep3[index].forEach((file, fileIndex) => {
          const f_index = filesLength + fileIndex
          formData.append(
            `evaluatorList[${evaluatorIndex}].files[${f_index}].file`,
            file
          )
        })

        try {
          await ShoppingCouponStore.updateShoppingCoupon(shoppingId, formData)
          message.success('Cập nhật thành công')
          const response = await ShoppingCouponStore.getShoppingCouponDetail(
            shoppingId
          )
          setEvaluatorList(response.data.evaluatorList)
          const newSelectedFilesSubStep3 = [...selectedFilesSubStep3]
          newSelectedFilesSubStep3[index] = []
          setSelectedFilesSubStep3(newSelectedFilesSubStep3)
        } catch (error) {
          console.log(error)
          message.error(error.vi || 'đã có lỗi xảy ra!!')
        }
      }
    }
  }

  return (
    <>
      <Form layout="vertical" form={form}>
        <Row gutter={24} style={{ paddingLeft: '-50px' }}>
          <Col span={1}></Col>
          <Col span={12}>
            <Title level={5}>Cấp duyệt:</Title>
            <div layout="vertical" style={{ marginTop: '20px' }}>
              <Row gutter={24}>
                <Col span={24}>
                  <Steps
                    direction="vertical"
                    size="medium"
                    current={current}
                    onChange={setCurrent}
                    style={{ marginBottom: 16, width: '100%' }}>
                    <Step
                      description={
                        <div>
                          <Row align="middle" style={{color: 'rgba(0, 0, 0, 0.85)'}}>
                            <Col span={1}></Col>
                            <Col span={16}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  maxHeight: '80px',
                                  paddingBottom: '16px',
                                }}>
                                Người tạo:
                                <div style={{ marginLeft: '15px' }}>
                                  <Tag
                                    color={'#E6F6FF'}
                                    style={{
                                      borderRadius: '35px',
                                      border: `1px solid #91d5ff`,
                                      padding: '3px 8px 3px 4px',
                                      color: blue,
                                      display: 'flex',
                                      marginRight: 0,
                                      alignItems: 'center',
                                    }}>
                                    <AvatarHasName
                                      size={22}
                                      name={
                                        props.dataShopping.createdUser
                                          ? getUserByUsername(
                                              props.dataShopping.createdUser
                                            )
                                          : ''
                                      }
                                      imgId={
                                        props.dataShopping.createdUser
                                          ? getUserByUsername(
                                              props.dataShopping.createdUser
                                            )
                                          : ''
                                      }
                                    />
                                  </Tag>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      }
                    />
                    <Step
                      description={
                        <div>
                          <Row align="middle" style={{color: 'rgba(0, 0, 0, 0.85)'}}>
                            <Col span={1}></Col>
                            <Col span={22}>
                              <div>
                                <span>
                                  Người duyệt yêu cầu:
                                </span>
                                <Space style={{ marginLeft: 20}}>
                                  {shoppingCouponType && shoppingCouponType === SHOPPING_STATUS.CREATED && (
                                    <Tooltip title="Chọn người dùng" color={blue}>
                                      <Button
                                        icon={<UserOutlined style={{ color: blue }} />}
                                        onClick={() => {
                                          setUserUpdate('approval')
                                          handleOpenSelectUserDepartmentPopup(
                                            ASSIGNEE_TYPE.APPROVAL
                                          )
                                        }}                                          
                                      />
                                    </Tooltip>
                                  )}                                 
                                </Space>
                                <SelectList style={{marginTop: 10}}>
                                  {reqName && reqName.map(el => (
                                    <Tag
                                      className="tag-selected"
                                    // color={'#e6f7ff'}
                                      key={el.id}
                                      onClose={() => handleRemoveSelect(el)}>
                                      <AvatarHasName
                                        imgId={el.imageId}
                                        size={22}
                                        name={el.full_name}                                        
                                      />
                                    </Tag>
                                  ))}
                                </SelectList>
                              </div>
                              <span>Công việc liên quan: </span>
                              <span style={{ marginLeft: '20px' }}></span>
                              <Button
                                size={'small'}
                                onClick={() => {
                                  handleOpenModal(2)
                                }}
                                icon={<CopyOutlined style={{ color: props.isDisableFinished ||
                                  (data && handleDisableWorksAndAttachments(
                                    data.requestApprover.name
                                  )) ? '#00000040' : '#2C65AC'}} />}
                                disabled={
                                  props.isDisableFinished ||
                                  (data && handleDisableWorksAndAttachments(
                                    data.requestApprover.name
                                  ))
                                }>
                                Công việc
                              </Button>
                              <br />
                              {reqWorks.length === 0 ? (
                                <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                                  Chưa có công việc nào
                                </p>
                              ) : (
                                reqWorks.map((work, index) => (
                                  <li
                                    key={index}
                                    style={{
                                      cursor: 'pointer',
                                      display: 'flex',
                                      alignItems: 'center',
                                      width: '100%',
                                    }}
                                    onClick={() => {
                                      props.onOpenDrawerWork(
                                        work.workCode,
                                        data?.requestApprover?.name
                                      )
                                      props.setApproveCode(
                                        props.dataShopping.requestApprover?.code
                                      )
                                    }}>
                                    <div style={{ textAlign: 'center', marginTop: 10, paddingRight: 2}}>
                                      {work.status ===
                                      STATUS_DEFAULT.COMPLETE ? (
                                        <CheckCircleFilled
                                          style={{
                                            color: greenPrimary,
                                            fontSize: 14,
                                          }}
                                        />
                                      ) : (
                                        <CheckCircleOutlined
                                          style={{
                                            color: '#595959',
                                            fontSize: 14,
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: '5px',
                                        width: '100%',
                                      }}>
                                      <div
                                        style={{
                                          maxWidth: '100%',
                                          wordWrap: 'break-word',
                                          wordBreak: 'break-word',
                                          whiteSpace: 'normal',
                                        }}>
                                        <UploadWorkListWrapper>
                                          <div className='work-list' title={work.workName}>
                                            {work.workName}
                                          </div>
                                        </UploadWorkListWrapper>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              )}
                              <br />
                              <span>Tài liệu đính kèm: </span>
                              <span style={{ marginLeft: '20px' }}></span>
                              <Upload
                                onRemove={() => {}}
                                beforeUpload={file => {
                                  handleUpload(2, file)
                                  return false
                                }}
                                showUploadList={false}>
                                <Button
                                  icon={<UploadOutlined />}
                                  disabled={
                                    props.isDisableFinished ||
                                    (data && handleDisableWorksAndAttachments(
                                      data.requestApprover.name
                                    ))
                                  }></Button>
                              </Upload>
                              <div style={{marginTop: 10}}>
                                {reqFiles.length === 0 ? (
                                  <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                                   Chưa có tài liệu nào
                                  </p>
                                ) : (
                                  reqFiles.map((item, index) => (
                                    <div key={index}>
                                      {props.userName === data?.requestApprover?.name ? (
                                        <RenderFileList>
                                          <div key={index} className='file-container'>
                                            <Row>
                                              <Col span={22}>
                                                <FileItemV2
                                                key={item.file_id}
                                                file_id={item.fileCode}
                                                file_name={item.file_name}
                                                file_type={utils.getExtensionFile(
                                                  item.file_name
                                                )}
                                                file_code={item.fileCode}
                                                />
                                              </Col>                               
                                              <Col span={2}>
                                                <span onClick={() => handleDeletAttachment(item.fileCode)}>
                                                  <DeleteOutlined  
                                                    className='delete-icon'
                                                    style={{ fontSize: 12, cursor: 'pointer', color: '#999' }}/>
                                                </span>
                                              </Col>                               
                                            </Row>
                                          </div>
                                        </RenderFileList>
                                      ) : (
                                        <div
                                          style={{
                                            marginTop: '10px'
                                          }}>
                                          <FileItemV2
                                            key={item.file_id}
                                            file_id={item.fileCode}
                                            file_name={item.file_name}
                                            file_type={utils.getExtensionFile(
                                              item.file_name
                                            )}
                                            file_code={item.fileCode}                     
                                          />
                                        </div>                                 
                                      )}
                                    </div>
                                  ))
                                )}
                              </div>
                              
                            </Col>
                          </Row>
                        </div>
                      }
                    />
                    {evaluatorList &&
                      evaluatorList.map((evaluator, index) => {
                        let step
                        if (index === 0) step = 3
                        else step = index + 5                        
                        const appraiserLists = [
                          apraiser,
                          firstSubApraiserList,
                          secondSubApraiserList,
                          thirdSubApraiserList,
                          fourthSubApraiserList,
                          fifthSubApraiserList,
                          sixthSubApraiserList,
                          seventhSubApraiserList,
                          eighthSubApraiserList,
                          ninthSubApraiserList,
                        ];                
                        const currentAppraiser = appraiserLists[index];
                        return (
                          <Step
                            key={index}
                            description={
                              <div>
                                <Row align="middle" style={{color: 'rgba(0, 0, 0, 0.85)'}}>
                                  <Col span={1}></Col>
                                  <Col span={22}>
                                  <div>
                                <span>
                                  Người thẩm định:
                                </span>
                                <Space style={{ marginLeft: 20}}>
                                  {shoppingCouponType && (shoppingCouponType === SHOPPING_STATUS.CREATED || 
                                    shoppingCouponType === SHOPPING_STATUS.REQUESTED) && (
                                    <Tooltip title="Chọn người dùng" color={blue}>
                                      <Button
                                        icon={<UserOutlined style={{ color: blue }} />}
                                        onClick={() => {                                     
                                          setUserUpdate(`apraiser_${index}`)
                                          const assigneeType = [
                                            ASSIGNEE_TYPE.APPRAISER,
                                            ASSIGNEE_TYPE.APPRAISER_1,
                                            ASSIGNEE_TYPE.APPRAISER_2,
                                            ASSIGNEE_TYPE.APPRAISER_3,
                                            ASSIGNEE_TYPE.APPRAISER_4,
                                            ASSIGNEE_TYPE.APPRAISER_5,
                                            ASSIGNEE_TYPE.APPRAISER_6,
                                            ASSIGNEE_TYPE.APPRAISER_7,
                                            ASSIGNEE_TYPE.APPRAISER_8,
                                            ASSIGNEE_TYPE.APPRAISER_9
                                          ]
                                          handleOpenSelectUserDepartmentPopup(assigneeType[index]);
                                        }}          
                                      />
                                    </Tooltip>
                                  )}                                 
                                </Space>
                                <SelectList style={{marginTop: 10}}>
                                  {currentAppraiser && currentAppraiser.map(el => (
                                    <Tag
                                      className="tag-selected"
                                    // color={'#e6f7ff'}
                                      key={el.id}
                                      onClose={() => handleRemoveSelect(el)}>
                                      <AvatarHasName
                                        imgId={el.imageId}
                                        size={22}
                                        name={el.full_name}                                        
                                      />
                                    </Tag>
                                  ))}
                                </SelectList>
                              </div>
                                    <span>Công việc liên quan: </span>
                                    <span style={{ marginLeft: '20px' }}></span>
                                    <Button
                                      size={'small'}
                                      onClick={() => handleOpenModal(step)}
                                      icon={<CopyOutlined style={{ color: props.isDisableFinished ||
                                        handleDisableWorksAndAttachments(
                                          evaluator.name
                                        ) ? '#00000040' : '#2C65AC'}} />}
                                      disabled={
                                        props.isDisableFinished ||
                                        handleDisableWorksAndAttachments(
                                          evaluator.name
                                        )
                                      }>
                                      Công việc
                                    </Button>
                                    <br />
                                    {evaluator &&
                                      (evaluator.works.length === 0 ? (
                                        <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                                          Chưa có công việc nào
                                        </p>
                                      ) : (
                                        evaluator.works.map((work, index) => (
                                          <li
                                            key={index}
                                            style={{
                                              cursor: 'pointer',
                                              display: 'flex',
                                              alignItems: 'center',
                                              width: '100%',
                                            }}
                                            onClick={() => {
                                              props.onOpenDrawerWork(
                                                work.workCode,
                                                evaluator.name
                                              )
                                              props.setApproveCode(
                                                evaluator.code
                                              )
                                            }}>
                                            <div style={{ textAlign: 'center', marginTop: 10, paddingRight: 2}}>
                                              {work.status ===
                                              STATUS_DEFAULT.COMPLETE ? (
                                                <CheckCircleFilled
                                                  style={{
                                                    color: greenPrimary,
                                                    fontSize: 14,
                                                  }}
                                                />
                                              ) : (
                                                <CheckCircleOutlined
                                                  style={{
                                                    color: '#595959',
                                                    fontSize: 14,
                                                  }}
                                                />
                                              )}
                                            </div>
                                            <div
                                              style={{
                                                marginLeft: '5px',
                                                width: '100%',
                                              }}>
                                              <div
                                                style={{
                                                  maxWidth: '100%',
                                                  wordWrap: 'break-word',
                                                  wordBreak: 'break-word',
                                                  whiteSpace: 'normal',
                                                }}>
                                                <UploadWorkListWrapper>
                                                  <div className='work-list' title={work.workName}>
                                                    {work.workName}
                                                  </div>
                                                </UploadWorkListWrapper>
                                              </div>
                                            </div>
                                          </li>
                                        ))
                                      ))}
                                    <br />
                                    <span>Tài liệu đính kèm: </span>
                                    <span style={{ marginLeft: '20px' }}></span>
                                    <Upload
                                      onRemove={() => {}}
                                      beforeUpload={file => {
                                        handleUpload(step, file)
                                        return false
                                      }}
                                      showUploadList={false}>
                                      <Button
                                        icon={<UploadOutlined />}
                                        disabled={
                                          props.isDisableFinished ||
                                          handleDisableWorksAndAttachments(
                                            evaluator.name
                                          )
                                        }></Button>
                                    </Upload>
                                    <div style={{marginTop: 10}}>
                                      {evaluator.files.length === 0 ? (
                                        <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>
                                          Chưa có tài liệu nào
                                        </p>
                                      ) : (
                                        evaluator.files.map((item, index) => (
                                          <div key={index}>
                                            {props.userName === evaluator.name ? (
                                              <RenderFileList>
                                                <div key={index} className='file-container'>
                                                  <Row>
                                                    <Col span={22}>
                                                      <FileItemV2
                                                        key={item.file_id}
                                                        file_id={item.fileCode}
                                                        file_name={item.file_name}
                                                        file_type={utils.getExtensionFile(
                                                          item.file_name
                                                        )}
                                                        file_code={item.fileCode}
                                                      />
                                                    </Col>                               
                                                    <Col span={2}>
                                                      <span onClick={() => handleDeletAttachment(item.fileCode)}>
                                                        <DeleteOutlined  
                                                          className='delete-icon'
                                                          style={{ fontSize: 12, cursor: 'pointer', color: '#999' }}/>
                                                      </span>
                                                    </Col>                               
                                                  </Row>
                                                </div>
                                              </RenderFileList>
                                            ) : (
                                              <div
                                                style={{
                                                  marginTop: '10px'
                                                }}>
                                                <FileItemV2
                                                  key={item.file_id}
                                                  file_id={item.fileCode}
                                                  file_name={item.file_name}
                                                  file_type={utils.getExtensionFile(
                                                    item.file_name
                                                  )}
                                                  file_code={item.fileCode}                     
                                                />
                                              </div>
                                            )}
                                          </div>
                                        ))
                                      )}
                                    </div>                                   
                                  </Col>
                                </Row>
                              </div>
                            }
                          />
                        )
                      })}
                    {/* {steps.map((step, index) => (
                      <Step
                        key={step.id}
                        description={
                          <div key={step.id}>
                            <Row align="middle">
                              <Col span={1}></Col>
                              <Col span={16}>
                                <Text strong>
                                  {step.role} :{' '}
                                  <AvatarHasName name={step.name} />
                                </Text>
                                <br />
                                <span>Công việc liên quan: </span>
                                <span style={{ marginLeft: '20px' }}></span>
                                <Button>Công việc</Button>
                                <br />
                                <span>Danh sách công việc</span>
                              </Col>
                            </Row>
                          </div>
                        }
                      />
                    ))} */}
                  </Steps>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={1}>
            <ConfigProvider
              theme={{
                token: {
                  margin: 30,
                  marginLG: 48,
                  lineWidth: 5,
                  colorSplit: '#8F8F8F',
                },
                components: {
                  Divider: {
                    verticalMarginInline: 16,
                    textPaddingInline: 16,
                    orientationMargin: 0.2,
                  },
                },
              }}>
              <Divider type="vertical" style={{ height: '300px' }} />
            </ConfigProvider>
          </Col>
          <Col span={9}>
            <Title level={5}>Người theo dõi: </Title>
            {props.dataShopping?.followerList &&
            props.dataShopping?.followerList[0] === '' ? (
              <p style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .45)' }}>Không có người theo dõi</p>
            ) : (
              <Text
                style={{
                  display: 'flex',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  marginBottom: '20px',
                }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}>
                  {props.dataShopping?.followerList &&
                    props.dataShopping?.followerList.map(item => (
                      <>
                        <li
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <div
                            style={{
                              padding: '5px 10px',
                              marginRight: '15px',
                              marginBottom: '10px',
                              fontWeight: 'bold',
                              color: '#2C65AC',
                              // color: '#1890ff',
                              border: '1px solid #91D5FF',
                              backgroundColor: '#E6F6FF',
                              borderRadius: '35px',
                            }}>
                            <AvatarHasName
                              size={22}
                              name={getUserByUsername(item)}
                              imgId={getUserByUsername(item)}
                            />
                          </div>
                        </li>
                      </>
                    ))}
                </div>
              </Text>
            )}
            <Title level={5}>Nội dung: </Title>
            <Text>
              {props.dataShopping?.content && (
                <div 
                  style={{ textAlign: 'justify', whiteSpace: 'pre-wrap' }}
                  dangerouslySetInnerHTML={{ __html: props.dataShopping.content }}>
                </div>
              )}
            </Text>
          </Col>
          <Col span={1} />
        </Row>

        {/* <ButtonFooter>
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            onClick={() => setIsModalVisible(true)}>
            Hủy bỏ
          </Button>
          <div style={{ margin: '0 10px' }}></div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsModalVisible(true)}>
            Xác nhận
          </Button>
        </ButtonFooter> */}
      </Form>
      <SelectPeoplePopup
        onlyOneUser
        noLoadSelectData={false}
        disableSelectDepartment
        isOpenCollapse={true}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        isUserDisableList={false}
        setIsSelectUser={props.setIsSelectUser}
      />
      <ModalWorkLists
        isVisibleModalShoppingCoupon={isVisibleModalShopingCoupon}
        handleCloseModal={() => setIsVisibleModalShopingCoupon(false)}
        currentStepType={currentStepType}
        onConfirmSelection={handleConfirmSelection}
        selectedRowKeysStep2={selectedRowKeysStep2}
        selectedRowKeysStep3={selectedRowKeysStep3}
        selectedRowKeysSubStep3={selectedRowKeysSubStep3}
        onWorkSelect={handleWorkSelection}
        currentStep={currentStep}
      />
    </>
  )
}
const ShoppingCouponDetail = props => {
  const {
    ShoppingCouponStore,
    match,
    loadingAnimationStore,
    userStore,
    workStore,
    listHandler,
    notificationStore,
    handleChangeType,
    authenticationStore,
    commandStore,
    selectPeopleStore,
  } = props
  const {
    getShoppingCouponDetail,
    shoppingCouponDetailList,
  } = ShoppingCouponStore
  const {
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
  } = selectPeopleStore

  const [current, setCurrent] = useState(0)
  const [detailShopping, setDetailShopping] = useState(null)
  const [workData, setWorkData] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [allUserr, setAllUserr] = useState([])
  const [code, setCode] = useState(null)
  const [shoppingId, setShopingId] = useState(props.sId)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [isCloseDraweWork, setIsCloseDraweWork] = useState(false)
  const [isDisableFinished, setIsDisableFinished] = useState(false)
  const [isDisablePurBrower, setIsDisablePurBrower] = useState(true)
  const [isDisablePayBrower, setIsDisablePayBrower] = useState(true)
  const [approveCode, setApproveCode] = useState('')
  const [reqWorks, setReqWorks] = useState([])
  const [evaluatorList, setEvaluatorList] = useState([])
  const [purWorks, setPurWorks] = useState([])
  const [payWorks, setPayWorks] = useState([])
  const [isSelectUser, setIsSelectUser] = useState(false)
  const {
    isOpen: isVisibleDrawerWork,
    handleOpen: handleOpenDrawerWork,
    handleClose: handleCloseDrawerWork,
  } = useOpenItem()
  const {
    isOpen: isVisiblePopupUpdateWork,
    handleOpen: handleOpenPopupUpdateWork,
    handleClose: handleClosePopupUpdateWork,
  } = useOpenItem()
  const {
    isOpen: isVisibleAddPersonWork,
    handleOpen: handleOpenAddPersonWork,
    handleClose: handleCloseAddPersonWork,
  } = useOpenItem()
  const {
    isOpen: isVisibleAddPersonVote,
    handleOpen: handleOpenAddPersonVote,
    handleClose: handleCloseAddPersonVote,
  } = useOpenItem()
  const {
    isOpen: isVisiblePopupElectronicRelease,
    handleOpen: handleOpenPopupElectronicRelease,
    handleClose: handleClosePopupElectronicRelease,
  } = useOpenItem()

  const { currentUser } = authenticationStore
  const userName = currentUser?.username

  const onOpenDrawerWork = (code, name) => {
    if (detailShopping?.type === SHOPPING_STATUS.REJECT){
      message.error('Bạn không có quyền truy cập công việc này!')
    } else {
      props.setIsVisiableDrawerWork(true)
      setCode(code)
      fetchDetails(code)
      setIsCloseDraweWork(false)     
    } 
  }

  const code_work = match?.params?.code_work

  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      const response = await getShoppingCouponDetail(props.sId)
      if (response.data === '') {
        message.error('Không tìm thấy phiếu mua sắm')
        history.push('/shopping-coupon')
      } else {
        setDetailShopping(response.data)
        setIsDataLoaded(true)
        setShopingId(props.sId)
        if (response.data.type === SHOPPING_STATUS.PAID || response.data.type === SHOPPING_STATUS.REJECT) {
          setIsDisableFinished(true)
        } else {
          setIsDisableFinished(false)
        }
      }
    })()
  }, [props.sId])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    userStore
      .getMentionUserList(true)
      .then(response => {
        setAllUsers(
          response.data.userDetails.map(user => {
            return {
              id: user.userCode,
              fullName: user.fullName,
              username: user.user_name,
              image: user.image,
            }
          })
        )
      })
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })

    return () => userStore.clearStore()
  }, [userStore])

  useEffect(() => {
    ;(async () => {
      const response = await getShoppingCouponDetail(props.sId)
      setDetailShopping(response.data)
      handleChangeType(response.data.type)
      ShoppingCouponStore.setShoppingCouponType(response.data.type)
      if (response.data.type === SHOPPING_STATUS.PAID || response.data.type === SHOPPING_STATUS.REJECT) {
        setIsDisableFinished(true)
      } else {
        setIsDisableFinished(false)
      }
      handleDisablePurchaseBrower(response.data)

    })()
  }, [isCloseDraweWork])

  useEffect(() => {
    ;(async () => {
      const response = await getShoppingCouponDetail(props.sId)
      setDetailShopping(response.data)
      handleChangeType(response.data.type)
      commandStore.decrementPendingShoppingRecord()
      if (response.data.type === SHOPPING_STATUS.PAID || response.data.type === SHOPPING_STATUS.REJECT) {
        setIsDisableFinished(true)
      } else {
        setIsDisableFinished(false)
      }
      handleDisablePurchaseBrower(response.data)
    })()
  }, [props.isReject])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    userStore
      .getMentionUserList()
      .then(response => {
        setAllUserr(
          response.data.userDetails.map(user => {
            return {
              id: user.userCode,
              fullName: user.fullName,
              username: user.user_name,
              image: user.image,
            }
          })
        )
      })
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
    return () => userStore.clearStore()
  }, [userStore])

  const getWorkList = async cancelToken => {
    try {
      loadingAnimationStore.setTableLoading(true)
      const res = await workStore.getWorkList(cancelToken)

      if (res) {
        loadingAnimationStore.setTableLoading(false)
      }
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    }
  }

  useEffect(() => {
    ;(async () => {
      const response = await workStore.getAllWorkList()
      setWorkData(response.data)
    })()
  }, [listHandler])

  const finalUserData = [...allUsers, ...allUserr]

  const next = () => setCurrent(current + 1)
  const prev = () => setCurrent(current - 1)
  const goToStep = step => setCurrent(step)

  const fetchDetails = async code => {
    if (code) {
      const parentId = utils.getParameterByName('parent_id')
      loadingAnimationStore.setShowSpinInline(true)
      try {
        if (parentId) {
          await workStore.getListSubWork(parentId)
        }
        await workStore.getWorkDetail(code)
        handleOpenDrawerWork()
      } catch (err) {
        history.push(`/shopping-coupon/other/view/${props.sId}`)
        handleCloseDrawerWork()
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra')
      } finally {
        loadingAnimationStore.setShowSpinInline(false)
      }
    }
  }

  const onClosesDrawerWork = async () => {
    setIsCloseDraweWork(true)
    handleCloseDrawerWork()
    history.replace(`/shopping-coupon/other/view/${props.sId}`)
    workStore.clearWorkDetail()
    workStore.getWorkList()
    const payload = {
      workCode: code,
    }
    await ShoppingCouponStore.updateWork(payload, approveCode, props.sId)
    const response = await ShoppingCouponStore.getShoppingCouponDetail(
      props.sId
    )
    setReqWorks(response.data.requestApprover.works)
    setEvaluatorList(response.data.evaluatorList)
    setPurWorks(response.data.purchaseApprover.works)
    setPayWorks(response.data.paymentApprover.works)
    setIsDisablePurBrower(handleDisablePurchaseBrower(response.data))
    setIsDisablePayBrower(handleDisablePaymentBrower(response.data))
    props.setIsVisiableDrawerWork(false)
    handleNotification(response.data)
  }

  const handleDisablePurchaseBrower = currentData => {
    if (currentData?.type === SHOPPING_STATUS.APPROVED) {
      let isCompleteWorks = true
      currentData.purchaseApprover?.works.forEach(work => {
        if (work.status !== 'COMPLETE') {
          isCompleteWorks = false
          return
        }
      })
      if (isCompleteWorks) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  const handleDisablePaymentBrower = currentData => {
    if (currentData?.paymentApprover?.name && currentData?.type === SHOPPING_STATUS.BOUGHT) {
      let isCompleteWorks = true
      currentData.paymentApprover?.works.forEach(work => {
        if (work.status !== 'COMPLETE') {
          isCompleteWorks = false
          return
        }
      })
      if (isCompleteWorks) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  const handleDisableWorksAndAttachments = name => {
    if (userName === name) {
      return false
    } else {
      return true
    }
  }

  const handleNotification = async response => {
    await ShoppingCouponStore.sendNotification(response)
  }

  const steps = [
    {
      title: 'Yêu cầu',
      content: isDataLoaded && (
        <PurchasePaymentForm
          loadingAnimationStore={loadingAnimationStore}
          detailShopping={shoppingCouponDetailList?.files}
          dataShopping={detailShopping}
          finalUserData={finalUserData}
          workData={workData}
          onOpenDrawerWork={onOpenDrawerWork}
          ShoppingCouponStore={ShoppingCouponStore}
          shoppingId={shoppingId}
          isDisableFinished={isDisableFinished}
          handleChangeType={handleChangeType}
          handleDisableWorksAndAttachments={handleDisableWorksAndAttachments}
          setApproveCode={setApproveCode}
          setReqWorks={setReqWorks}
          reqWorks={reqWorks}
          evaluatorList={evaluatorList}
          setEvaluatorList={setEvaluatorList}
          userName={userName}
          handleNotification={handleNotification}
          selectPeopleStore={selectPeopleStore}
          isSelectUser={isSelectUser}
          setIsSelectUser={setIsSelectUser}
        />
      ),
    },
    {
      title: 'Mua sắm / Thanh toán',
      content: isDataLoaded && (
        <CreateRequestForm
          loadingAnimationStore={loadingAnimationStore}
          detailShopping={shoppingCouponDetailList?.files}
          dataShopping={detailShopping}
          finalUserData={finalUserData}
          workData={workData}
          onOpenDrawerWork={onOpenDrawerWork}
          ShoppingCouponStore={ShoppingCouponStore}
          shoppingId={shoppingId}
          isDisableFinished={isDisableFinished}
          handleChangeType={handleChangeType}
          setIsDisableFinished={setIsDisableFinished}
          handleDisablePurchaseBrower={handleDisablePurchaseBrower}
          isDisablePurBrower={isDisablePurBrower}
          setIsDisablePurBrower={setIsDisablePurBrower}
          handleDisablePaymentBrower={handleDisablePaymentBrower}
          isDisablePayBrower={isDisablePayBrower}
          setIsDisablePayBrower={setIsDisablePayBrower}
          handleDisableWorksAndAttachments={handleDisableWorksAndAttachments}
          userName={userName}
          setApproveCode={setApproveCode}
          purWorks={purWorks}
          setPurWorks={setPurWorks}
          payWorks={payWorks}
          setPayWorks={setPayWorks}
          commandStore={commandStore}
          handleNotification={handleNotification}
          selectPeopleStore={selectPeopleStore}         
          isSelectUser={isSelectUser}       
          setIsSelectUser={setIsSelectUser}
        />
      ),
    },
  ]

  return (
    <>
      <Steps
        current={current}
        onChange={setCurrent}
        size="medium"
        style={{ marginBottom: 16, padding: '40px 350px' }}>
        {steps.map((item, index) => (
          <Step key={index} />
        ))}
      </Steps>
      <div style={{ textAlign: 'center', margin: '0px 0px 50px' }}>
        <Title style={{ fontSize: 18 }}>{steps[current].title}</Title>
      </div>
      <div className="steps-content">{steps[current].content}</div>
      <SelectPeoplePopup
        onlyOneUser
        noLoadSelectData={false}
        disableSelectDepartment
        isOpenCollapse={true}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(true)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        isUserDisableList={false}
        setIsSelectUser={setIsSelectUser}
      />
      {isVisibleDrawerWork && (
        <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__WORK}>
          {isVisibleDrawerWork && (
            <DrawerWork
              openPopupUpdateWork={handleOpenPopupUpdateWork}
              openPopupAddPersonWork={handleOpenAddPersonWork}
              openPopupAddPersonVote={handleOpenAddPersonVote}
              openPopupElectronicRelease={handleOpenPopupElectronicRelease}
              onOpenDrawerWork={onOpenDrawerWork}
              onCloseDrawerWork={onClosesDrawerWork}
              isVisibleDrawerWork={isVisibleDrawerWork}
              shoppingId={shoppingId}
              ShoppingCouponStore={ShoppingCouponStore}
              code={code}
              fetchDetails={fetchDetails}
            />
          )}
          {isVisiblePopupUpdateWork && (
            <PopupUpdateWork
              match={match}
              isVisiblePopupUpdateWork={isVisiblePopupUpdateWork}
              handleCloseUpdateWork={handleClosePopupUpdateWork}
            />
          )}
          <PopupAddPersonWork
            isVisibleAddPersonWork={isVisibleAddPersonWork}
            handleCloseAddPersonWork={() => {
              handleCloseAddPersonWork()
            }}
          />
          <PopupElectronicReleaseForm
            isVisiblePopupElectronicRelease={isVisiblePopupElectronicRelease}
            handleCloseElectronicRelease={handleClosePopupElectronicRelease}
          />
          <PopupAddPersonVote
            isVisibleAddPersonVote={isVisibleAddPersonVote}
            handleCloseAddPersonVote={() => {
              handleCloseAddPersonVote()
            }}
          />
        </AccessControlAction>
      )}
    </>
  )
}

export default inject(
  'managementAssetWarehouseStore',
  'loadingAnimationStore',
  'authenticationStore',
  'notificationStore',
  'selectPeopleStore',
  'ShoppingCouponStore',
  'userStore',
  'workStore',
  'commandStore',
)(observer(ShoppingCouponDetail))
