import { CaretRightOutlined, SearchOutlined } from '@ant-design/icons'
import { Checkbox, Collapse, Empty, Input, Tooltip } from 'antd'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { ASSIGNEE_TYPE } from '../../../constants'
import utils from '../../../utils'
import { AvatarHasName } from '../../Common/Avatar'
import { EmptyText } from '../../Common/CellText'
import { BoxWrapper, PanelItem } from './SelectUserDepartmentBoxLiveStyled'

const SelectUserDepartmentBoxLive = props => {
  const {
    defaultKeys,
    defaultActiveKey,
    setDefaultActiveKey,
    departmentDisableList,
    userDisableList,
    onlyOneUser,
    isOpenCollapse,
    onlySelectOne,
    disableSelectDepartment,
    isVisibleSelectPeoplePopup,
    departmentWithUserList,
    originDepartmentWithUserList,
    setDepartmentWithUserList,
    assigneePopupType,
    selectUserData,
    selectDepartmentData,
    setSelectUserData,
    setSelectDepartmentData,
  } = props

  const handleSelectUser = useCallback(
    user => {
      if (!user) return
      if (onlySelectOne) {
        setSelectUserData({
          assignee_type: ASSIGNEE_TYPE.USER,
          full_name: user.name_uppercase,
          id: user.user_name,
          permission: assigneePopupType,
          department_code: user.department_code,
          department_name: user.department_name,
          image_id: user.image_id,
          position_name: user.position_name,
        })
        return
      }

      const departmentOfUser = originDepartmentWithUserList.find(
        el => el.code === user.department_code
      )
      const userList = departmentOfUser.users.map(user => ({
        ...user,
        department_code: departmentOfUser.code,
        department_name: departmentOfUser.name,
      }))
      const isSelectedUser = selectUserData?.some(
        el => el.id === user.user_name
      )
      const isDepartmentOfUserSelected = selectDepartmentData?.some(
        el => el.id === user.department_code
      )
      if (isSelectedUser || isDepartmentOfUserSelected) {
        if (isDepartmentOfUserSelected) {
          setSelectDepartmentData(
            selectDepartmentData?.filter(el => el.id !== user.department_code)
          )
          setSelectUserData(
            userList
              .filter(el => el.user_name !== user.user_name)
              .map(el => ({
                assignee_type: ASSIGNEE_TYPE.USER,
                full_name: el.name_uppercase,
                id: el.user_name,
                permission: assigneePopupType,
                department_code: el.department_code,
                department_name: el.department_name,
                image_id: el.image_id,
                position_name: el.position_name,
              }))
          )
          return
        }
        setSelectUserData(selectUserData.filter(el => el.id !== user.user_name))
        return
      }

      setSelectUserData([
        ...selectUserData,
        {
          assignee_type: ASSIGNEE_TYPE.USER,
          full_name: user.name_uppercase,
          id: user.user_name,
          permission: assigneePopupType,
          department_code: user.department_code,
          department_name: user.department_name,
          image_id: user.image_id,
          position_name: user.position_name,
        },
      ])
    },
    [
      selectUserData,
      selectDepartmentData,
      originDepartmentWithUserList,
      onlySelectOne,
    ]
  )

  const [valueSearch, setValueSearch] = useState('')

  useEffect(() => {
    setValueSearch('')
  }, [isVisibleSelectPeoplePopup])

  const handleSelectDepartment = useCallback(
    department => {
      if (!department) return
      if (onlySelectOne) {
        setSelectDepartmentData([
          {
            assignee_type: ASSIGNEE_TYPE.DEPARTMENT,
            full_name: department.name,
            id: department.code,
            permission: assigneePopupType,
          },
        ])
        return
      }

      const isSelectedDepartment = selectDepartmentData?.some(
        el => el.id === department.code
      )
      if (isSelectedDepartment) {
        setSelectDepartmentData(
          selectDepartmentData?.filter(el => el.id !== department.code)
        )
        setSelectUserData(
          selectUserData.filter(el => el.department_code !== department.code)
        )
        return
      }

      setSelectDepartmentData([
        ...selectDepartmentData,
        {
          assignee_type: ASSIGNEE_TYPE.DEPARTMENT,
          full_name: department.name,
          id: department.code,
          permission: assigneePopupType,
        },
      ])
      setSelectUserData(
        selectUserData.filter(el => el.department_code !== department.code)
      )
    },
    [selectUserData, selectDepartmentData, onlySelectOne]
  )

  const onSearchUser = useCallback(
    event => {
      setDefaultActiveKey(defaultKeys)
      setValueSearch(event.target.value)
      if (event.target.value.trim() === '') {
        setDepartmentWithUserList(originDepartmentWithUserList)
        return
      }
      const filterList = originDepartmentWithUserList
        .map(department => {
          const newUserList = department.users.filter(user =>
            utils
              .nonAccentVietnamese(user.name_uppercase)
              .includes(utils.nonAccentVietnamese(event.target.value))
          )
          return {
            ...department,
            users: newUserList,
          }
        })
        .filter(department => department.users.length > 0)
      setDepartmentWithUserList(filterList)
    },
    [originDepartmentWithUserList, defaultKeys]
  )

  const handleSelectOnlyOneUser = useCallback(
    user => {
      if (!user) return
      const isSelectedUser = selectUserData?.some(
        el => el.id === user.user_name
      )
      if (isSelectedUser) {
        setSelectUserData([])
        return
      }
      setSelectUserData([
        {
          assignee_type: ASSIGNEE_TYPE.USER,
          full_name: user.name_uppercase,
          id: user.user_name,
          permission: assigneePopupType,
          department_code: user.department_code,
          department_name: user.department_name,
          image_id: user.image_id,
          position_name: user.position_name,
        },
      ])
    },
    [selectUserData]
  )

  const onChangeCollapse = keys => {
    setDefaultActiveKey(keys)
  }

  useEffect(() => {
    if (isOpenCollapse) {
      setDefaultActiveKey(defaultKeys)
    } else {
      setDefaultActiveKey([])
    }
  }, [isOpenCollapse, defaultKeys])

  return (
    <BoxWrapper>
      <div className={'search-box'}>
        <Input
          value={valueSearch}
          allowClear
          suffix={<SearchOutlined />}
          placeholder={'Tìm theo tên người dùng'}
          onChange={event => onSearchUser(event)}
          style={{ width: '100%' }}
        />
      </div>
      <div className={'collapse-wrapper'}>
        <Collapse
          onChange={onChangeCollapse}
          activeKey={defaultActiveKey}
          collapsible={'header'}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}>
          {departmentWithUserList.map((department, index) => {
            const checked =
              selectDepartmentData &&
              selectDepartmentData?.some(el => el.id === department.code)
            const disabled = departmentDisableList.includes(department.code)

            return (
              <PanelItem
                checked={checked}
                collapsible
                key={index}
                header={
                  <Tooltip
                    title={
                      disabled && !onlyOneUser
                        ? 'Phòng ban này đã được chọn'
                        : null
                    }>
                    <div
                      onClick={e => {
                        if (onlyOneUser || disableSelectDepartment) return
                        e.stopPropagation()
                        if (disabled) return
                        handleSelectDepartment(department)
                      }}
                      className={`department-title ${
                        checked && 'department-title-checked'
                      } ${
                        ((disabled && !onlyOneUser) ||
                          (disabled && !disableSelectDepartment)) &&
                        'department-title-disabled'
                      }`}>
                      {!onlyOneUser && !disableSelectDepartment && (
                        <Checkbox checked={checked} disabled={disabled} />
                      )}
                      <span className={'department-name'}>
                        {department.name}
                      </span>
                    </div>
                  </Tooltip>
                }>
                {department.users.length > 0 ? (
                  department.users.map(user => {
                    const checked =
                      (selectUserData &&
                        selectUserData?.some(el => el.id === user.user_name)) ||
                      (selectDepartmentData &&
                        selectDepartmentData?.some(
                          el => el.id === department.code
                        ))
                    const disabled = userDisableList.includes(user.user_name)
                    return (
                      <Tooltip
                        key={user.user_name}
                        title={disabled ? 'Người dùng này đã được chọn' : null}>
                        <div
                          className={`user-item ${
                            checked && 'user-item-checked'
                          } ${disabled && 'user-item-disabled'}`}
                          onClick={() => {
                            if (disabled) return
                            if (onlyOneUser) {
                              handleSelectOnlyOneUser({
                                ...user,
                                department_code: department.code,
                                department_name: department.name,
                              })
                              if (!onlySelectOne) {
                                handleSelectUser({
                                  ...user,
                                  department_code: department.code,
                                  department_name: department.name,
                                })
                              }
                              return
                            }
                            handleSelectUser({
                              ...user,
                              department_code: department.code,
                              department_name: department.name,
                            })
                          }}>
                          <Checkbox checked={checked} disabled={disabled} />
                          <div className={'user-item-info'}>
                            <AvatarHasName
                              name={user.name_uppercase}
                              position={user.position_name}
                              imgId={user.image_id}
                            />
                          </div>
                        </div>
                      </Tooltip>
                    )
                  })
                ) : (
                  <div style={{ padding: '10px 0 10px 60px' }}>
                    <EmptyText>Không có người dùng</EmptyText>
                  </div>
                )}
              </PanelItem>
            )
          })}
        </Collapse>
        {departmentWithUserList.length === 0 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          </div>
        )}
      </div>
    </BoxWrapper>
  )
}

SelectUserDepartmentBoxLive.propTypes = {
  disableUserDepartmentList: PropTypes.array,
}

export default observer(SelectUserDepartmentBoxLive)
